import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { cn } from '../../../utils/classUtils';
import ZenButton from '../../Zen/ZenButton';

interface WalletReviewCardProps {
  counter: React.ReactElement;
  label: string;
  value?: string | React.ReactElement;
  onEdit(): void;
  hideBorder?: boolean;
  disableEdit?: boolean;
}

const WalletReviewCard: React.FC<WalletReviewCardProps> = ({
  counter,
  label,
  value,
  onEdit,
  hideBorder = false,
  disableEdit = false,
}) => {
  return (
    <div
      className={cn(
        'w-full flex flex-row items-center py-2.5 border-b border-grey-300',
        { 'border-0': hideBorder },
      )}
    >
      <div className='mr-6'>
        <div className='w-7 h-7 rounded-full bg-grey-200 text-primary-dark font-poppins font-normal flex justify-center items-center'>
          {counter}
        </div>
      </div>

      <div className='grid grid-cols-2 flex-grow'>
        <div className='text-base p-1 space-y-3'>
          <p className='text-gray-500 font-poppins font-light'>{label}</p>
          <p className='text-primary-dark font-inter font-medium break-words'>
            {value ?? 'N/A'}
          </p>
        </div>
      </div>

      {!disableEdit && (
        <div className='flex-grow flex justify-end items-center'>
          <ZenButton
            label='Edit'
            variant='primary-link'
            className='!font-poppins !font-medium'
            onClick={onEdit}
            LeftIconComponent={
              <FontAwesomeIcon icon={faPen} className='text-primary-blue' />
            }
          />
        </div>
      )}
    </div>
  );
};

export default WalletReviewCard;
