import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flatten, uniq, values } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../constants/TableConstants';
import { AgentControllerApi as ArrakisAgentControllerApi } from '../../openapi/arrakis';
import {
  AgentControllerApi,
  AgentResponse,
  ApplicationResponse,
  ApproveApplicationRequestAgentPlansConfigEnum,
  FutureAgentPlanAgentPlansConfigEnum,
  SponsorInfo,
  UserResponseAgentStatusEnum,
} from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { approveApplicationById } from '../../slices/ApplicationSlice';
import { fetchBoards } from '../../slices/BoardSlice';
import { fetchLiteMLSDetails } from '../../slices/MLSSlice';
import { getTeamDetailOverview } from '../../slices/TeamSlice';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import {
  AdminRoles,
  AppDispatch,
  AsyncSelectOption,
  ISelectOption,
  RootState,
  YesNoType,
} from '../../types';
import { getAllEnabledPlans } from '../../utils/applicationUtils';
import { hasRequiredAdminActiveRole } from '../../utils/AuthUtils';
import { TEAMMATE_LIMIT_REACHED } from '../../utils/ErrorUtils';
import {
  getArrakisConfiguration,
  getYentaConfiguration,
} from '../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import { officeTableFetchData, searchForAgents } from '../../utils/TableUtils';
import AvatarPill from '../AvatarPill';
import ConfirmationModal from '../ConfirmationModal';
import GeminiFeatureFlagButton from '../GeminiFeatureFlagButton';
import ZenControlledAsyncSelectInput from '../Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledDatePickerInput from '../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledRadioInput from '../Zen/Input/ZenControlledRadioInput';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenApplicationModal from './ZenApplicationModal';

interface ApplicationApproveModalProps {
  application: ApplicationResponse;
  isOpen: boolean;
  agentName?: string;
  teamId?: string;
  onClose(): void;
}

interface FormData {
  mls: ISelectOption[];
  board: ISelectOption[];
  office: ISelectOption[];
  sponsorAgent: ISelectOption;
  sponsors: ISelectOption[];
  capDeferredAgent: YesNoType;
  taxDeferredMonths: string;
  agentPlansConfig: ISelectOption<ApproveApplicationRequestAgentPlansConfigEnum>;
  hasTemporaryCommissionPlan: YesNoType;
  futureAgentPlan: {
    agentPlansConfig: ISelectOption<FutureAgentPlanAgentPlansConfigEnum>;
    agentPlansConfigStartDate: string;
  };
}

const ZenApplicationApproveModal: React.FC<ApplicationApproveModalProps> = ({
  isOpen,
  onClose,
  application,
  agentName,
  teamId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [isTeammateLimitReached, setIsTeammateLimitReached] = useState(false);
  const flattenBoards = flatten(
    (application?.doesBusinessInExtended || [])?.map(
      (val) => val?.boards || [],
    ),
  );
  const { teamDetailOverview } = useSelector((state: RootState) => state.team);
  const keyMakerCurrentUser = useSelector(
    (state: RootState) => state.auth.keymakerCurrentUser,
  );

  const flattenMls = flatten(
    (application?.doesBusinessInExtended || [])?.map((val) => val?.mlses || []),
  );

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      mls:
        uniq(
          flattenMls?.filter((v) => !!v && v !== 'I do not belong to a MLS.'),
        )?.map((val: string) => {
          return {
            value: val,
            label: val,
          };
        }) ?? [],
      board:
        uniq(
          flattenBoards?.filter(
            (v) => !!v && v !== 'I do not belong to a Board.',
          ),
        )?.map((val: string) => {
          return {
            value: val,
            label: val,
          };
        }) ?? [],
      hasTemporaryCommissionPlan: YesNoType.NO,
      futureAgentPlan: {
        agentPlansConfigStartDate: DateTime.local()
          .plus({ year: 1 })
          .toISODate(),
      },
    },
  });

  const [mls, board] = watch(['mls', 'board']);

  const getMLS = useCallback(async () => {
    const mlsListPromise = (mls || [])
      ?.filter((m) => !!m?.value)
      ?.map(
        async (m) =>
          await dispatch(
            fetchLiteMLSDetails(0, DEFAULT_PAGE_SIZE, 'ASC', m?.value),
          ),
      );
    const mlsList = await Promise.all(mlsListPromise);
    const options = (mlsList || [])
      ?.filter((mls) => !!mls?.results?.length)
      ?.map((mls) => ({
        label: mls?.results?.[0]?.name!,
        value: mls?.results?.[0]?.id!,
      }));
    setValue('mls', options);
  }, [dispatch, mls, setValue]);

  useEffect(() => {
    getMLS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!teamId) {
      dispatch(getTeamDetailOverview(teamId));
    }
  }, [dispatch, teamId]);

  const getBoard = useCallback(async () => {
    const boardListPromise = (board || [])
      ?.filter((b) => !!b?.value)
      ?.map(
        async (b) =>
          await dispatch(
            fetchBoards({
              page: 0,
              pageSize: 20,
              sortBy: { name: 'asc' },
              filter: {
                name: b?.value,
              },
            }),
          ),
      );
    const boardList = await Promise.all(boardListPromise);
    const options = (boardList || [])
      ?.filter((board) => !!board?.results?.length)
      ?.map((board) => ({
        label: board?.results?.[0]?.name!,
        value: board?.results?.[0]?.id!,
      }));
    setValue('board', options);
  }, [board, dispatch, setValue]);

  useEffect(() => {
    getBoard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [currentAgent, setCurrentAgent] = useState<AgentResponse | undefined>();

  const plans = getAllEnabledPlans();

  const onApprove = async (data: FormData) => {
    const mlsId = data.mls.map((mls: { value: any }) => mls.value);
    const boardId = data.board.map((board: { value: any }) => board.value);
    const officeId = data.office.map((office: { value: any }) => office.value);
    const sponsorAgents: SponsorInfo[] = data.sponsors?.map(
      (agent: ISelectOption) => ({
        sponsorCode: agent.value,
      }),
    );

    const res = await dispatch(
      approveApplicationById(application.id!, {
        agentId: application.agentId!,
        mlsIds: mlsId,
        boardIds: boardId,
        officeIds: officeId,
        sponsors: sponsorAgents?.length ? sponsorAgents : undefined,
        sponsorCode: data.sponsorAgent?.value ?? undefined,
        agentPlansConfig: data.agentPlansConfig?.value,
        futureAgentPlan:
          data?.hasTemporaryCommissionPlan === YesNoType.YES
            ? {
                agentPlansConfig: data?.futureAgentPlan?.agentPlansConfig
                  ?.value!,
                agentPlansConfigStartDate: data?.futureAgentPlan
                  ?.agentPlansConfigStartDate!,
              }
            : undefined,
      }),
    );

    const isTeammateLimitReached = res === TEAMMATE_LIMIT_REACHED;
    const isApplicationApproved = !isTeammateLimitReached && !!res;

    if (isTeammateLimitReached) {
      setIsTeammateLimitReached(true);
    } else if (isApplicationApproved) {
      try {
        await new ArrakisAgentControllerApi(
          getArrakisConfiguration(),
        ).getAgentByYentaId(application.agentId!);
      } catch (e) {
        ErrorService.notify('Unable to fetch arrakis agent by yentaId', e, {
          agent: { id: application.agentId },
        });
      }
    }

    if (data.capDeferredAgent === YesNoType.YES && isApplicationApproved) {
      try {
        await new AgentControllerApi(getYentaConfiguration()).setDeferredCap(
          currentAgent?.id!,
          parseInt(data.taxDeferredMonths, 10),
        );
      } catch (e) {
        ErrorService.notify('Unable to set cap deferred', e, {
          agent: {
            id: application.agentId,
            numberOfMonths: parseInt(data.taxDeferredMonths, 10),
          },
        });
      }
    }

    onClose();
  };

  useEffect(() => {
    async function fetchAgent(id: string) {
      try {
        const { data } = await new AgentControllerApi(
          getYentaConfiguration(),
        ).getAgentById(id);
        setCurrentAgent(data);
      } catch (e) {
        ErrorService.notify('Error fetching real agent', e);
        dispatch(
          showErrorToast(
            'We had a problem fetching the real agent',
            'Please try again in a few moments.',
          ),
        );
      }
    }

    if (application.agentId) {
      fetchAgent(application.agentId);
    } else {
      setCurrentAgent(undefined);
    }
  }, [dispatch, application]);

  const askForCapDeferredMonth = watch('capDeferredAgent') === YesNoType.YES;

  const hasTemporaryCommissionPlan =
    watch('hasTemporaryCommissionPlan') === YesNoType.YES;

  const fetchSponsors = async (search: string, page?: number) => {
    try {
      const { data } = await searchForAgents({
        page,
        search,
        filterBy: {
          agentStatus: [
            UserResponseAgentStatusEnum.Active,
            UserResponseAgentStatusEnum.Candidate,
          ],
        },
      });

      const options: AsyncSelectOption[] = data.map((resp) => ({
        value: `${resp.sponsorCode}`,
        label: `${resp.firstName} ${resp.lastName} (${resp.emailAddress})`,
      }));

      return options;
    } catch (e) {
      ErrorService.notify(
        'Unable to search for agents in application approval modal',
        e,
        {
          search: {
            term: search,
            page,
          },
        },
      );
      dispatch(
        showErrorToast(
          'An unexpected error occurred.',
          'We were unable to search for an agent. Please try again in a few moments or contact support.',
        ),
      );
    }

    return [];
  };

  const isOnboardingRoleOnly = hasRequiredAdminActiveRole(
    keyMakerCurrentUser?.activeRoles || [],
    [AdminRoles.ONBOARDING],
  );

  return (
    <>
      <ZenApplicationModal
        isOpen={isOpen}
        title='Approve Application'
        subtitle={`Are you sure you want to approve application of ${application?.firstName} ${application?.lastName}?`}
        onClose={onClose}
        handleSubmit={handleSubmit(onApprove)}
        isSubmitting={isSubmitting}
      >
        <div className='lg:max-h-[480px] h-auto scrollbar overflow-y-auto overflow-x-hidden pr-2 flex flex-col gap-4 lg:mb-4'>
          <ZenControlledAsyncSelectInput<FormData, 'mls'>
            control={control}
            name='mls'
            isMulti
            label='MLSs to Join'
            fetchData={async (search, page) => {
              const res = await dispatch(
                fetchLiteMLSDetails(page, DEFAULT_PAGE_SIZE, 'ASC', search),
              );
              return (res?.results || []).map((resp) => ({
                value: `${resp?.id}`,
                label: `${resp?.name}`,
              }));
            }}
            rules={{
              required: 'Required',
            }}
          />
          <ZenControlledAsyncSelectInput<FormData, 'board'>
            control={control}
            name='board'
            isMulti
            label='Boards to Join'
            fetchData={async (search, page) => {
              const res = await dispatch(
                fetchBoards({
                  search,
                  page: page ?? 0,
                  pageSize: 20,
                  sortBy: { name: 'asc' },
                }),
              );

              const options: AsyncSelectOption[] = (res?.results || []).map(
                (resp) => ({
                  value: `${resp?.id}`,
                  label: `${resp?.name}`,
                }),
              );

              return options;
            }}
            rules={{
              required: 'Required',
            }}
          />
          <ZenControlledAsyncSelectInput<FormData, 'office'>
            control={control}
            name='office'
            isMulti
            label='Offices to Join'
            fetchData={async (search, page = 0) => {
              try {
                const { data } = await officeTableFetchData({
                  page,
                  pageSize: 20,
                  search,
                  sortBy: { name: 'asc' },
                });
                const activeOffices = data.filter((office) => office.active);
                const options: AsyncSelectOption[] = activeOffices.map(
                  (resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.name}`,
                  }),
                );

                return options;
              } catch (e) {
                ErrorService.notify(
                  'Unable to search for offices in application approve modal',
                  e,
                  {
                    search: {
                      term: search,
                      page,
                    },
                  },
                );
                dispatch(
                  showErrorToast(
                    'An unexpected error occurred.',
                    'We were unable to search for an office. Please try again in a few moments or contact support.',
                  ),
                );
              }

              return [];
            }}
            rules={{
              required: 'Required',
            }}
          />
          <div className='flex flex-col p-3 rounded-lg bg-grey-100 space-y-4'>
            <ZenControlledSelectInput<FormData, 'agentPlansConfig'>
              name='agentPlansConfig'
              control={control}
              label='Commission Plan'
              placeholder='Choose Commission Plan Name'
              options={[
                ...(plans || []).map((plan) => ({
                  value: plan,
                  label: capitalizeEnum(plan),
                })),
              ]}
              rules={{
                required: 'Please choose Commission Plan',
              }}
              styles={{
                menuList(base) {
                  return {
                    ...base,
                    maxHeight: '200px',
                  };
                },
              }}
            />
            <ZenControlledRadioInput<FormData, 'hasTemporaryCommissionPlan'>
              name='hasTemporaryCommissionPlan'
              rules={{
                required: 'Please choose one',
              }}
              options={[
                {
                  label: 'Permanent',
                  value: YesNoType.NO,
                },
                {
                  label: 'Temporary',
                  value: YesNoType.YES,
                },
              ]}
              control={control}
              inlineOptions
              disabled={!isOnboardingRoleOnly}
            />
            {hasTemporaryCommissionPlan && (
              <>
                <ZenControlledSelectInput<
                  FormData,
                  'futureAgentPlan.agentPlansConfig'
                >
                  name='futureAgentPlan.agentPlansConfig'
                  control={control}
                  placeholder='Select Future Plan Membership'
                  shouldUnregister={false}
                  options={[
                    {
                      label: 'Select Future Plan Membership',
                      value: '',
                      disabled: true,
                    },
                    ...values(FutureAgentPlanAgentPlansConfigEnum).map(
                      (plan) => ({
                        value: plan,
                        label: capitalizeEnum(plan),
                      }),
                    ),
                  ]}
                  rules={{
                    required: 'Please choose future plan membership name',
                  }}
                  styles={{
                    menuList(base) {
                      return {
                        ...base,
                        maxHeight: '200px',
                      };
                    },
                  }}
                />

                <ZenControlledDatePickerInput
                  control={control}
                  label='Select Temporary Plan End Date'
                  name='futureAgentPlan.agentPlansConfigStartDate'
                  icon={
                    <FontAwesomeIcon
                      className='text-primary-blue'
                      icon={faCalendar}
                    />
                  }
                  datePickerConfig={{
                    minDate: DateTime.local().plus({ day: 1 }).toJSDate(),
                  }}
                  placeholder='MM/DD/YYYY'
                  shouldUnregister={false}
                />
              </>
            )}
          </div>
          <div className='font-inter text-base font-medium'>
            <span>Current Sponsor(s):</span>
            {!!currentAgent?.sponsors?.length ? (
              <div className='flex flex-row flex-wrap items-start space-x-2 mt-1'>
                {currentAgent?.sponsors
                  ?.filter((s) => !s.real)
                  .map((sponsor) => (
                    <a
                      href={`/people/${sponsor.sponsor?.id!}`}
                      target='_blank'
                      rel='noreferrer'
                      key={sponsor.sponsor?.id}
                    >
                      <AvatarPill
                        text={sponsor.sponsor?.fullName!}
                        imageUrl={sponsor.sponsor?.avatar!}
                      />
                    </a>
                  ))}
              </div>
            ) : (
              <span className='ml-2 font-semibold'>N/A</span>
            )}
          </div>

          <ZenControlledAsyncSelectInput<FormData, 'sponsors'>
            control={control}
            name='sponsors'
            isMulti
            placeholder='Select'
            label='Override Sponsor'
            rules={{
              validate: (value) => {
                return value && value?.length > 2
                  ? 'Maximum 2 agents can be selected'
                  : undefined;
              },
            }}
            fetchData={fetchSponsors}
            menuPosition='fixed'
            maxMenuHeight={200}
          />
          <ZenControlledRadioInput<FormData, 'capDeferredAgent'>
            name='capDeferredAgent'
            label='Is this agent cap deferred?'
            rules={{
              required: 'Please choose one',
            }}
            options={[
              {
                label: 'Yes',
                value: YesNoType.YES,
              },
              {
                label: 'No',
                value: YesNoType.NO,
              },
            ]}
            control={control}
            inlineOptions
          />
          {askForCapDeferredMonth && (
            <ZenControlledTextInput<FormData, 'taxDeferredMonths'>
              type='number'
              control={control}
              label='Please enter the number of months that are tax deferred'
              name='taxDeferredMonths'
              placeholder='Enter number of months. Eg: 5'
              rules={{
                required: 'Please enter the number of months',
              }}
            />
          )}
        </div>
        <div className='flex flex-row justify-end space-x-2 lg:py-0 py-4'>
          <GeminiFeatureFlagButton
            label='Cancel'
            zenVariant='primary-outline'
            geminiVariant='primary-outline'
            onClick={onClose}
          />
          <GeminiFeatureFlagButton
            label='Approve'
            onClick={handleSubmit(onApprove)}
            isSubmitting={isSubmitting}
          />
        </div>
      </ZenApplicationModal>
      <ConfirmationModal
        title='Api Error'
        isOpen={isTeammateLimitReached}
        onClose={() => setIsTeammateLimitReached(false)}
        variant='error'
        size='large'
      >
        <div className='pr-10'>
          <p className='mb-4'>
            The team that this agent wants to join has reached the maximum
            number of teammates, so the agent cannot be approved and added at
            this time.
          </p>

          <p className='mb-3'>
            To add this agent to the team, follow these steps:
          </p>
          <ul className='list-decimal ml-4 space-y-2'>
            <li>
              Go to the Team Dashboard for {teamDetailOverview?.name}:
              <br />
              <a
                className='text-zen-primary-8 break-words'
                target='_blank'
                rel='noopener noreferrer'
                href={`https://bolt.playrealbrokerage.com/teams/${teamId}`}
              >
                https://bolt.playrealbrokerage.com/teams/{teamId}
              </a>
            </li>
            <li>
              Click on &quot;Edit Team&quot; and change the Team Config &gt;
              Maximum Teammates to allow more teammates
            </li>
            <li>
              Return to the In Progress Applications to approve {agentName}
              <br />
              <a
                className='text-zen-primary-8 break-words'
                target='_blank'
                rel='noopener noreferrer'
                href={`https://bolt.playrealbrokerage.com/applications/${application.id}`}
              >
                {`https://bolt.playrealbrokerage.com/applications/${application.id}`}
              </a>
            </li>
          </ul>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default ZenApplicationApproveModal;
