import {
  faAddressBook,
  faBuilding,
  faBuildingColumns,
  faCalendar,
  faClipboardList,
  faCloudQuestion,
  faCreditCard,
  faDiagramProject,
  faFileCertificate,
  faFileContract,
  faFileLines,
  faHandshake,
  faHandsHoldingDollar,
  faInbox,
  faList,
  faUsers,
  faWallet,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactComponent as RealTitleIcon } from '../../../assets/icons/real-title-icon.svg';
import { PARTNER_REDIRECT_URL } from '../../../constants/MarketingCenterLinksConstants';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { FeatureFlagTypeEnum, RootState } from '../../../types';
import { isCanadianUser } from '../../../utils/AgentHelper';
import { getInboxLink } from '../../../utils/UrlUtils';
import SwitchToGeminiToggle from '../../Gemini/SwitchToGeminiToggle';
import SystemTime from '../../SystemTime';
import ZenSideBarItem from '../../ZenSideBarItem';
import ZenSideBarMenu from '../../ZenSideBarMenu';
import TipaltiSidebarMenuItem from '../TipaltiSidebarMenuItem';
import UserRealAcademySidebarMenuItem from '../UserRealAcademySidebarMenuItem';
import RealMarketingSidebarMenuItem from '../ZenMenu/RealMarketingSidebarMenuItem';
import RealSignatureSidebarMenuItem from '../ZenMenu/RealSignatureSidebarMenuItem';
import OneRealImpactSidebarMenuItem from '../ZenMenu/OneRealImpactSidebarMenuItem';
import ZenNewBadge from '../ZenMenu/ZenNewBadge';
import ZenCronJobsStatusIcon from '../ZenMenu/ZenCronJobsStatusIcon';
import ZenUnreadCountPill from '../ZenMenu/ZenUnreadCountPill';

interface NeoAdminMenuProps {
  isCollapsed: boolean;
}

const NeoAdminMenu: React.FC<NeoAdminMenuProps> = ({ isCollapsed }) => {
  const { userDetail, isLeoExpert, isLegal } = useSelector(
    (state: RootState) => state.auth,
  );
  const location = useLocation();

  const isJointVentureFeatureFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.REAL_TITLE,
  );

  const isIcaAgreementFeatureFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.ICA_AGREEMENTS,
  );

  const isWalletFeatureEnabled = useFeatureFlag(FeatureFlagTypeEnum.WALLET);
  const isCanadian = isCanadianUser(userDetail?.accountCountry!);
  const isLeoQuestionManagerEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.LEO_QUESTION_MANAGER,
  );
  const isReferralCentralFeatureEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.REFERRAL_CENTRAL,
  );
  const isNeoLeoEnabled = useFeatureFlag(FeatureFlagTypeEnum.NEO_LEO);

  return (
    <div>
      {!isCollapsed && <SystemTime />}
      <ZenSideBarItem
        collapsed={isCollapsed}
        icon={
          isCollapsed ? (
            <ZenUnreadCountPill isCollapsed={isCollapsed} />
          ) : (
            <FontAwesomeIcon icon={faInbox} />
          )
        }
        label='Inbox'
        hideActive
        linkTo={getInboxLink(location.search)}
        target='_blank'
        BadgeComponent={<ZenUnreadCountPill />}
      />
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={faClipboardList} />}
        label='Transactions'
        linkTo='/transactions'
        collapsed={isCollapsed}
      />
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={faList} />}
        label='Listings'
        linkTo='/listings'
        collapsed={isCollapsed}
      />
      {isWalletFeatureEnabled && (
        <ZenSideBarItem
          icon={<FontAwesomeIcon icon={faWallet} />}
          label='Wallet'
          linkTo='/wallet'
          collapsed={isCollapsed}
          showNewBadge={isNeoLeoEnabled}
          BadgeComponent={isNeoLeoEnabled ? <ZenNewBadge /> : undefined}
        />
      )}
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={faBuilding} />}
        label='Offices'
        linkTo='/offices'
        collapsed={isCollapsed}
      />
      <ZenSideBarMenu
        collapsed={isCollapsed}
        label='People & Teams'
        icon={<FontAwesomeIcon icon={faUsers} />}
      >
        <ZenSideBarItem label='Applications' linkTo='/applications' isSubTab />
        <ZenSideBarItem label='People' linkTo='/people' isSubTab />
        <ZenSideBarItem label='Teams' linkTo='/teams' isSubTab />
      </ZenSideBarMenu>
      <RealSignatureSidebarMenuItem isCollapsed={isCollapsed} />
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={faHandsHoldingDollar} />}
        label='WealthPlan'
        linkTo='/wealthplan'
        collapsed={isCollapsed}
        target='_blank'
        BadgeComponent={!isCollapsed ? <ZenNewBadge /> : undefined}
      />
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={faAddressBook} />}
        label='Directory'
        linkTo='/directory'
        collapsed={isCollapsed}
      />
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={faHandshake} />}
        label='Partners'
        linkTo={PARTNER_REDIRECT_URL}
        collapsed={isCollapsed}
        target='_blank'
        BadgeComponent={!isCollapsed ? <ZenNewBadge /> : undefined}
        isExternal
      />
      <RealMarketingSidebarMenuItem isCollapsed={isCollapsed} />
      {!isCanadian && (
        <React.Fragment>
          <ZenSideBarItem
            icon={<FontAwesomeIcon icon={faBuildingColumns} />}
            label='One Real Mortgage'
            linkTo='/mortgages'
            collapsed={isCollapsed}
          />
          <ZenSideBarItem
            icon={<RealTitleIcon />}
            label='One Real Title'
            linkTo='/real-title'
            collapsed={isCollapsed}
          />
        </React.Fragment>
      )}
      <UserRealAcademySidebarMenuItem isCollapsed={isCollapsed} />
      <OneRealImpactSidebarMenuItem isCollapsed={isCollapsed} />
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={faFileContract} />}
        label='Reports'
        linkTo='/reports'
        collapsed={isCollapsed}
      />
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={faCalendar} />}
        label='Events'
        linkTo='/my-events'
        collapsed={isCollapsed}
      />

      {isReferralCentralFeatureEnabled && (
        <ZenSideBarItem
          icon={<FontAwesomeIcon icon={faList} />}
          label='Referral Central'
          linkTo='/referrals'
          collapsed={isCollapsed}
        />
      )}
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={faFileLines} />}
        label='Invoices'
        linkTo='/invoices'
        collapsed={isCollapsed}
      />
      <ZenSideBarItem
        icon={<FontAwesomeIcon icon={faCreditCard} />}
        label='Outgoing Payments'
        linkTo='/outgoing-payments'
        collapsed={isCollapsed}
      />
      <ZenSideBarMenu
        icon={<FontAwesomeIcon icon={faFileCertificate} />}
        collapsed={isCollapsed}
        label='Boards & MLS'
      >
        <ZenSideBarItem label='MLS' linkTo='/mls' isSubTab />
        <ZenSideBarItem label='Boards' linkTo='/boards' isSubTab />
      </ZenSideBarMenu>

      <ZenSideBarItem
        icon={
          isCollapsed ? (
            <ZenCronJobsStatusIcon isCollapsed={isCollapsed} />
          ) : (
            <FontAwesomeIcon icon={faCalendar} />
          )
        }
        collapsed={isCollapsed}
        label='Jobs'
        linkTo='/jobs'
        BadgeComponent={<ZenCronJobsStatusIcon />}
      />

      {isLeoQuestionManagerEnabled && isLeoExpert && (
        <ZenSideBarItem
          icon={<FontAwesomeIcon icon={faCloudQuestion} />}
          label='LQM'
          linkTo='/leo-question-manager'
          collapsed={isCollapsed}
        />
      )}

      <ZenSideBarMenu
        icon={
          <FontAwesomeIcon
            icon={faDiagramProject}
            className='text-base pt-1.5'
          />
        }
        collapsed={isCollapsed}
        label='Departments'
      >
        <ZenSideBarItem label='Engineering' linkTo='/engineering' isSubTab />
        <ZenSideBarItem label='Finance' linkTo='/finance' isSubTab />
        <ZenSideBarItem label='Calendars' linkTo='/calendars' isSubTab />
        <ZenSideBarItem label='Onboarding' linkTo='/onboarding' isSubTab />
        <ZenSideBarItem label='Super Admin' linkTo='/superadmin' isSubTab />
        <ZenSideBarItem label='Support' linkTo='/support' isSubTab />
        <ZenSideBarItem label='Operations' linkTo='/operations' isSubTab />
        {isJointVentureFeatureFlagEnabled ? (
          <ZenSideBarItem
            label='One Real Title'
            linkTo='/real-title/joint-ventures'
            isSubTab
          />
        ) : null}
        {isIcaAgreementFeatureFlagEnabled && isLegal ? (
          <ZenSideBarItem label='Legal' linkTo='/legal' isSubTab />
        ) : null}
      </ZenSideBarMenu>
      <TipaltiSidebarMenuItem isCollapsed={isCollapsed} />
      <SwitchToGeminiToggle collapsed={isCollapsed} />
    </div>
  );
};

export default NeoAdminMenu;
