import React from 'react';
import { useSelector } from 'react-redux';
import { ItemResponse } from '../../../openapi/sherlock';
import { RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import ErrorCountCell from '../../table/Cells/ErrorCountCell';
import LeoAnimatedOrbLoader from '../Leo/LeoAnimatedOrbLoader';

export enum StatusEnum {
  PROCESSED = 'PROCESSED',
  PROCESSING = 'PROCESSING',
}

interface ChecklistDocumentErrorDetectionCellProps {
  checklistItem: ItemResponse;
  documentId?: string;
  dropboxId?: string;
  onClick?: () => void;
  isDocumentTable?: boolean;
}

const ChecklistDocumentErrorDetectionCell: React.FC<ChecklistDocumentErrorDetectionCellProps> = ({
  checklistItem,
  documentId,
  dropboxId,
  onClick,
  isDocumentTable = false,
}) => {
  const {
    leo: { checklistItemDocumentErrorResponseById },
  } = useSelector((state: RootState) => state);

  const documentCount = checklistItem?.fileReferences?.references?.length;
  const checklistItemDocumentErrors =
    checklistItemDocumentErrorResponseById?.[checklistItem?.id!];

  // If there are no documents or errors, return null
  if (
    !documentCount || // No documents
    !checklistItemDocumentErrors || // No errors
    (documentId && !checklistItemDocumentErrors?.[documentId]) // Document errors not found
  ) {
    return null;
  }

  // Get the total number of errors in a file
  const getFileErrorsCount = (fileId: string) => {
    const fileErrors = checklistItemDocumentErrors?.[fileId];
    return (
      fileErrors?.result?.detected_errors?.reduce(
        (total: any, error: any) => total + (error?.page_numbers?.length || 0),
        0,
      ) || 0
    );
  };

  const isAllFilesAnalysed = documentId
    ? checklistItemDocumentErrors?.[documentId]?.status === StatusEnum.PROCESSED
    : checklistItem?.fileReferences?.references?.every(
        (file) =>
          !checklistItemDocumentErrors?.[file?.fileId!] ||
          checklistItemDocumentErrors?.[file?.fileId!]?.status ===
            StatusEnum.PROCESSED,
      );

  const totalErrorCount = documentId
    ? getFileErrorsCount(documentId)
    : checklistItem?.fileReferences?.references?.reduce(
        (total, file) => total + getFileErrorsCount(file?.fileId!),
        0,
      ) || 0;

  return (
    <div
      className={cn({
        'relative w-9 h-9 shadow-[0px_0px_12px_0px_#AEAEAE70] rounded-tl-full rounded-tr-full rounded-bl-full rounded-br-lg bg-white flex items-center justify-center': !isDocumentTable,
      })}
    >
      {isAllFilesAnalysed ? (
        <ErrorCountCell
          size='sm'
          count={totalErrorCount}
          checklistItem={checklistItem}
          documentId={documentId}
          dropboxId={dropboxId}
          onClick={totalErrorCount > 0 ? onClick : undefined}
        />
      ) : (
        <LeoAnimatedOrbLoader />
      )}
    </div>
  );
};

export default ChecklistDocumentErrorDetectionCell;
