import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../Zen/Leo/LeoIndex.css';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { motion } from 'framer-motion';
import { createChatSession, saveResetLeo } from '../../slices/LeoSlice';
import { AppDispatch, RootState } from '../../types';
import { ChatFormData } from '../Zen/Leo/Chat/LeoChatIndex';
import SessionRetry from '../Zen/Leo/Chat/SessionRetry';
import useShowLeoTrigger from '../Zen/Leo/useShowLeoTrigger';
import NeoLeoChat from './Chat/NeoLeoChat';
import NeoLeoHeader from './NeoLeoHeader';
import NeoLeoChatFooter from './NeoLeoChatFooter';
import NeoLeoIntro from './NeoLeoIntro';
import NeoLeoHistoryIndex from './NeoLeoHistoryIndex';

interface NeoLeoIndexProps {}

const NeoLeoIndex: React.FC<NeoLeoIndexProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    leo: { showIntro, session, isSessionError },
  } = useSelector((state: RootState) => state);
  const { agentId } = useShowLeoTrigger();
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [showAllNotifications, setShowAllNotifications] = useState(false);
  const formControls = useForm<ChatFormData>({
    defaultValues: {},
    mode: 'onChange',
  });

  const startNewSession = () => {
    dispatch(saveResetLeo());
    dispatch(createChatSession(agentId!));
  };

  useEffect(() => {
    if (agentId) {
      dispatch(createChatSession(agentId));
    }
  }, [agentId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(saveResetLeo());
    };
  }, [dispatch]);

  if (!session?.id && isSessionError) {
    return <SessionRetry />;
  }

  return (
    <motion.div
      className='flex-grow flex flex-col h-full'
      layout
      transition={{ duration: 0.1 }}
      aria-label='leo-chat-index'
    >
      <FormProvider {...formControls}>
        <form className='flex-grow flex flex-col h-full'>
          <div className='flex-grow flex flex-col h-full'>
            <NeoLeoHeader
              toggleHistory={setShowHistory}
              startNewSession={startNewSession}
            />
            {showHistory ? (
              <NeoLeoHistoryIndex hideHistory={() => setShowHistory(false)} />
            ) : showIntro ? (
              <NeoLeoIntro
                showAllNotifications={showAllNotifications}
                setShowAllNotifications={setShowAllNotifications}
              />
            ) : (
              <NeoLeoChat isShowingHistory={showHistory} />
            )}
            {!showAllNotifications && <NeoLeoChatFooter isIntro={showIntro} />}
          </div>
        </form>
      </FormProvider>
    </motion.div>
  );
};

export default NeoLeoIndex;
