import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import {
  faCircle,
  faCircleExclamation,
  faFileLines,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { resetLeoErrorDetectionModalData } from '../../../slices/LeoSlice';
import { RootState } from '../../../types';
import { getFileInformation } from '../../../utils/FileUtils';
import LeoAnimatedOrb from './LeoAnimatedOrb';

export interface LeoErrorDetectionModalProps {}

const LeoErrorDetectionModal: React.FC<LeoErrorDetectionModalProps> = () => {
  const dispatch = useDispatch();
  const {
    leo: { checklistErrorDetectionModalData },
    dropbox: { dropboxById },
  } = useSelector((state: RootState) => state);

  const onClose = useCallback(() => {
    dispatch(resetLeoErrorDetectionModalData());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      onClose();
    };
  }, [onClose]);

  if (!checklistErrorDetectionModalData) {
    return null;
  }

  const dropboxFile = dropboxById?.[
    checklistErrorDetectionModalData?.dropboxId!
  ]?.files?.find((file) => file?.id === checklistErrorDetectionModalData?.id);

  const pageErrors = checklistErrorDetectionModalData?.result?.detected_errors?.reduce(
    (acc: any, item: any) => {
      item?.page_numbers?.forEach((page: number) => {
        if (!acc[page]) acc[page] = [];
        acc[page].push({
          ...item,
          page_numbers: page,
        });
      });
      return acc;
    },
    {},
  );

  const totalErrorPages = Object.keys(pageErrors)?.length;

  const totalErrors = Object.keys(pageErrors)?.reduce(
    (acc: number, page) => acc + pageErrors?.[page]?.length,
    0,
  );

  return (
    <div
      className='fixed right-0 bottom-0 m-4 animate-slide-in-right z-50'
      title='leo-error-detection-modal'
    >
      <div className='w-[450px] max-w-md rounded-3xl font-inter'>
        <div className='bg-[#282932] rounded-t-3xl'>
          <div className='flex items-center justify-between p-4'>
            <div className='flex items-center space-x-2'>
              <LeoAnimatedOrb size='md' />
              <div className='text-base leading-[22px] font-medium text-white'>
                Leo
              </div>
            </div>
            <FontAwesomeIcon
              icon={faXmark}
              className='text-white cursor-pointer text-xl px-2'
              onClick={onClose}
            />
          </div>
          <div className='flex items-center justify-between px-4 pt-1 pb-2'>
            <div className='flex items-center space-x-2'>
              <div className='bg-[#FFFFFF]/[6%]  rounded-lg w-11 h-11 flex items-center justify-center'>
                <FontAwesomeIcon icon={faFilePdf} className='text-grey-200' />
              </div>
              <div className='w-[280px]'>
                <div className='flex text-white font-medium'>
                  <div className='truncate'>
                    {getFileInformation(dropboxFile?.filename || '')?.basename}
                  </div>
                  <div>
                    .
                    {getFileInformation(dropboxFile?.filename || '')?.extension}
                  </div>
                </div>
                <div className='text-grey-400 text-sm'>
                  Total {totalErrorPages} {pluralize('page', totalErrorPages)}
                </div>
              </div>
            </div>
            <div className='flex items-center border border-red-500 bg-[#171821] text-red-500 rounded-lg px-2.5 py-1.5 space-x-2'>
              <FontAwesomeIcon icon={faCircleExclamation} size='sm' />
              <div className='text-sm font-medium'>{totalErrors}</div>
            </div>
          </div>
        </div>

        {/* Page Errors */}
        <div className='space-y-2 bg-[#0D0F1C] p-4 max-h-96 scrollbar overflow-y-auto rounded-b-3xl'>
          {Object.keys(pageErrors)?.map((pageErrorKey) => (
            <div key={pageErrorKey} className='bg-[#171821] p-3 rounded-lg'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center gap-2'>
                  <FontAwesomeIcon
                    icon={faFileLines}
                    className='text-grey-400'
                  />
                  <p className='font-medium text-white'>Page {pageErrorKey}</p>
                </div>
                <div className='text-red-500 bg-[#282932] font-medium text-sm rounded-full h-7 w-7 flex justify-center items-center'>
                  {pageErrors?.[pageErrorKey]?.length}
                </div>
              </div>
              <div className='space-y-1 mt-1'>
                {pageErrors?.[pageErrorKey]?.map((error: any) => (
                  <div
                    key={error?.error_message}
                    className='flex items-start text-sm font-light text-grey-100'
                  >
                    <FontAwesomeIcon
                      icon={faCircle}
                      className='text-red-500 cursor-pointer text-[6px] mr-2 ml-5 mt-1.5'
                    />
                    {error?.error_message}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LeoErrorDetectionModal;
