import { faEye } from '@fortawesome/pro-light-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { ReadReceiptDto } from '../../../../openapi/yada';
import { RootState } from '../../../../types';
import GeminiAgentInfoRow, {
  GeminiReadReceiptProps,
} from './GeminiAgentInfoRow';

interface GeminiReadReceiptsListProps {
  readReceipts?: ReadReceiptDto[];
}

const GeminiReadReceiptsList: React.FC<GeminiReadReceiptsListProps> = ({
  readReceipts,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    userIds: { agentByKeymakerId },
  } = useSelector((state: RootState) => state);

  const users: GeminiReadReceiptProps[] = (readReceipts || [])?.map((item) => ({
    imageUrl: agentByKeymakerId[item?.readerId]?.avatar,
    firstName: agentByKeymakerId[item?.readerId]?.firstName,
    lastName: agentByKeymakerId[item?.readerId]?.lastName,
    id: agentByKeymakerId[item?.readerId]?.id,
    keymakerId: agentByKeymakerId[item.readerId]?.keymakerId,
    readAt: item?.readAt,
  }));

  if (!users?.length) {
    return null;
  }

  return (
    <div onMouseLeave={() => setIsOpen(false)}>
      <Popover
        isOpen={isOpen}
        positions={['left', 'right']}
        onClickOutside={() => setIsOpen(false)}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor='white'
            arrowSize={10}
            arrowStyle={{
              zIndex: 1,
            }}
            className='popover-arrow-container'
            arrowClassName='popover-arrow'
          >
            <div className='bg-white rounded-[14px] space-y-6 py-4 shadow-2xl z-30'>
              <div className='flex flex-row justify-between items-center px-4'>
                <div className='flex flex-row items-center space-x-1'>
                  <FontAwesomeIcon
                    icon={faEye}
                    size='sm'
                    className='text-primary-dark'
                  />
                  <div className='font-inter font-medium text-base leading-[22px] text-primary-dark'>
                    Viewed by
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  size='lg'
                  className='text-regent-400 cursor-pointer'
                  onClick={() => setIsOpen(false)}
                />
              </div>
              <div className='h-full max-h-64 scrollbar overflow-y-auto px-4'>
                {users?.map((user) => (
                  <GeminiAgentInfoRow key={user?.id} user={user} />
                ))}
              </div>
            </div>
          </ArrowContainer>
        )}
      >
        <div
          onMouseEnter={() => setIsOpen(true)}
          data-testid='read-receipts'
          className='flex flex-row items-center space-x-1 cursor-pointer'
        >
          <FontAwesomeIcon icon={faEye} size='sm' className='text-regent-600' />
          <span className='font-inter font-normal text-sm text-regent-600'>
            {users?.length}
          </span>
        </div>
      </Popover>
    </div>
  );
};

export default GeminiReadReceiptsList;
