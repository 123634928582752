import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faTrash, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  AdministrativeAreaRequest,
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaResponseStateOrProvinceEnum,
  AgentControllerApi,
  AgentResponse,
  LicenseRequest,
  LicenseResponse,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { fetchAgentDetail } from '../../../slices/AgentSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { EmailSupportType } from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { openEmailClientForSupportEmail } from '../../../utils/SupportUtils';
import { FILE_VALIDATIONS } from '../../../utils/Validations';
import AdminOnly from '../../auth/AdminOnly';
import ControlledToggleInputV7 from '../../ControlledToggleInputV7';
import { SwitchVariant } from '../../CustomSwitch';
import GeminiFeatureFlagButton from '../../GeminiFeatureFlagButton';
import IconButton from '../../IconButton';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledHTMLSelectInput from '../../Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledImageUpload from '../../Zen/Input/ZenControlledImageUploadInput';
import ZenControlledStateOrProvinceInput from '../../Zen/Input/ZenControlledStateOrProvinceInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenSimpleConfirmationModal from '../../Zen/Modal/ZenSimpleConfirmationModal';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import { AgentLicenseImageWithId } from './ZenAgentLicenseDetail';

interface ZenEditAgentLicenseProps {
  isOpen: boolean;
  onClose(): void;
  license?: LicenseResponse;
  licenseImages: AgentLicenseImageWithId[];
  agent: AgentResponse;
  readOnly: boolean;
}

interface FormData extends LicenseRequest {
  licenseImage: string;
}

const ZenEditAgentLicense: React.FC<ZenEditAgentLicenseProps> = ({
  agent,
  isOpen,
  license,
  licenseImages,
  onClose,
  readOnly,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      ...license,
      active: license?.active || false,
      knownComplaints: license?.knownComplaints || false,
      administrativeAreaRequest: {
        ...((license?.administrativeArea! as unknown) as AdministrativeAreaRequest),
      },
    },
  });

  const licenseImageUrl = licenseImages.find((item) => item.id === license?.id!)
    ?.data.licenseImageUrl;

  const onSubmit = async (data: FormData) => {
    const mainLicenses = agent!.licenses!.filter(
      (item) =>
        item.administrativeArea?.stateOrProvince !==
        license?.administrativeArea?.stateOrProvince,
    );
    if (
      mainLicenses.find(
        (item) =>
          item.administrativeArea?.stateOrProvince ===
          ((data.administrativeAreaRequest
            .stateOrProvince as unknown) as AdministrativeAreaResponseStateOrProvinceEnum),
      )
    ) {
      return setError('administrativeAreaRequest.stateOrProvince', {
        message: 'Only one license per state is allowed',
      });
    }

    const licenseData = {
      licenseType: data.licenseType,
      number: data.number,
      expirationDate: data.expirationDate,
      administrativeAreaRequest: data.administrativeAreaRequest,
      active: data.active,
      knownComplaints: data.knownComplaints,
    };

    try {
      if (!license) {
        await new AgentControllerApi(
          getYentaConfiguration(),
        ).createAgentLicense(
          agent.id!,
          licenseData,
          (data.licenseImage as unknown) as File,
        );
      } else {
        await new AgentControllerApi(
          getYentaConfiguration(),
        ).updateAgentLicenseById(
          agent.id!,
          license?.id!,
          licenseData,
          (data.licenseImage as unknown) as File,
        );
      }
      await dispatch(fetchAgentDetail(agent?.id!));
      onClose();
      if (license) {
        dispatch(showSuccessToast('License updated successfully.'));
      } else {
        dispatch(showSuccessToast('License added successfully.'));
      }
    } catch (e) {
      ErrorService.notify('Error updating license', e, {
        data: { ...data },
      });
      if (license) {
        dispatch(showErrorToast('Error updating the License'));
      } else {
        dispatch(showErrorToast('Error adding the License'));
      }
    }
  };

  const onDelete = async () => {
    setDeleting(true);

    try {
      await new AgentControllerApi(
        getYentaConfiguration(),
      ).deleteAgentLicenseById(agent?.id!, license?.id!);
      await dispatch(fetchAgentDetail(agent?.id!));
      dispatch(showSuccessToast('License deleted successfully.'));
    } catch (e) {
      ErrorService.notify('Error deleting license', e, {
        data: { license },
      });

      dispatch(showErrorToast('Error deleting the License'));
    } finally {
      setDeleting(false);
      onClose();
    }
  };
  return (
    <ZenSidebarModal
      title={
        !license ? 'Add License' : readOnly ? 'View License' : 'Edit License'
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col justify-between min-h-full '
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-4'>
          {readOnly && (
            <div className='flex flex-row items-center justify-end'>
              <IconButton
                label='Contact Support'
                variant='no-outline'
                textStyle='font-zen-body font-semibold text-primary-blue'
                onClick={() =>
                  openEmailClientForSupportEmail(
                    EmailSupportType.MY_LICENSES,
                    agent!,
                  )
                }
                buttonStyle='hover:bg-primary-blue hover:bg-opacity-10'
              />
            </div>
          )}
          <div>
            <ZenControlledHTMLSelectInput
              label='Country'
              control={control}
              options={[
                {
                  label: 'Choose Country',
                  value: '',
                },
                ...values(AdministrativeAreaRequestCountryEnum).map(
                  (state) => ({
                    value: state,
                    label: capitalizeEnum(state),
                  }),
                ),
              ]}
              rules={{ required: 'Please select a country' }}
              placeholder='Select Country'
              name={`administrativeAreaRequest.country` as const}
              shouldUnregister={false}
              readOnly={readOnly}
              isRequired
            />
          </div>
          <div className='mt-4'>
            <ZenControlledStateOrProvinceInput
              control={control}
              selectedCountry={watch(
                `administrativeAreaRequest.country` as const,
              )}
              name={`administrativeAreaRequest.stateOrProvince` as const}
              setValue={setValue}
              shouldUnregister={false}
              rules={{ required: 'Please select a State/Province' }}
              readOnly={readOnly}
              isRequired
            />
          </div>
          <div className='mt-4'>
            <ZenControlledTextInput
              name='number'
              control={control}
              label='License Number'
              placeholder='E.g. 12345678'
              shouldUnregister={false}
              rules={{ required: 'Required' }}
              readOnly={readOnly}
              isRequired
            />
          </div>
          <div className='mt-4'>
            <ZenControlledDatePickerInput
              control={control}
              label='Expiration Date'
              name='expirationDate'
              icon={
                <FontAwesomeIcon
                  className='text-primary-blue mt-0.5 ml-0.5'
                  icon={faCalendar}
                  size='lg'
                />
              }
              datePickerConfig={{
                minDate: DateTime.local().plus({ day: 1 }).toJSDate(),
              }}
              placeholder='MM/DD/YYYY'
              shouldUnregister={false}
              rules={{ required: 'Required' }}
              readOnly={readOnly}
              isRequired
            />
          </div>
          {licenseImageUrl && (
            <div className='mt-4'>
              <p className='my-2'>Latest License Image</p>
              <div className='border border-dashed p-2'>
                <img
                  className='object-cover w-1/2 h-auto mx-auto'
                  src={licenseImageUrl}
                  alt='avatar'
                />
              </div>
            </div>
          )}
          {!readOnly && (
            <div className='mt-4'>
              <ZenControlledImageUpload
                name='licenseImage'
                control={control}
                shouldUnregister
                label='Real Estate License Image'
                infoToolTipText='If you do not receive a license card, you may use a screenshot of your licensing entities website showing your name, license number and expiration date.'
                uploadText='Upload License'
                rules={{
                  ...FILE_VALIDATIONS,
                }}
              />
            </div>
          )}
          <div className='mt-8 mb-10 border rounded-xl border-gray-300 divide-y'>
            <div className='flex flex-row items-center p-6'>
              <ControlledToggleInputV7
                name='active'
                control={control}
                label='Active License?'
                variant={SwitchVariant.zen}
                leftIcon={
                  <FontAwesomeIcon
                    icon={faXmark}
                    className='text-primary-blue'
                  />
                }
                rightIcon={
                  <FontAwesomeIcon
                    icon={faCheck}
                    className='text-primary-blue'
                  />
                }
                readOnly={readOnly}
              />
            </div>
            <div className='flex flex-row items-center p-6'>
              <ControlledToggleInputV7
                name='knownComplaints'
                control={control}
                label='Known Complaints?'
                variant={SwitchVariant.zen}
                leftIcon={
                  <FontAwesomeIcon
                    icon={faXmark}
                    className='text-primary-blue'
                  />
                }
                rightIcon={
                  <FontAwesomeIcon
                    icon={faCheck}
                    className='text-primary-blue'
                  />
                }
                readOnly={readOnly}
              />
            </div>
          </div>
        </div>
        {license && (
          <AdminOnly>
            <div className='bottom-[76px] px-4 py-2 bg-white border-gray-200'>
              <IconButton
                variant='none'
                label='Delete this license'
                buttonStyle='hover:!bg-error hover:!bg-opacity-10'
                textStyle='!text-error'
                leftIcon={
                  <FontAwesomeIcon
                    className='text-zen-danger -mt-0.5'
                    icon={faTrash}
                  />
                }
                onClick={() => setOpen(true)}
              />
            </div>
          </AdminOnly>
        )}

        <AdminOnly>
          <div className='sticky bottom-0 p-4 bg-white border-t border-gray-200 space'>
            <div className='flex items-center space-x-3'>
              <div className='flex-1'>
                <GeminiFeatureFlagButton
                  zenVariant='primary-outline'
                  geminiVariant='primary-outline'
                  onClick={() => onClose()}
                  label='Cancel'
                  isFullWidth
                />
              </div>
              <div className='flex-1'>
                <GeminiFeatureFlagButton
                  type='submit'
                  label='Save'
                  isFullWidth
                  isSubmitting={isSubmitting}
                  isDisabled={isSubmitting}
                />
              </div>
            </div>
          </div>
        </AdminOnly>

        {license && (
          <ZenSimpleConfirmationModal
            isOpen={open}
            onClose={() => setOpen(false)}
            variant='danger'
            title='Delete License'
            subtitle={`Are you sure you want to delete the license of ${capitalizeEnum(
              license?.administrativeArea?.stateOrProvince!,
            )}`}
            isSubmitting={isDeleting}
            onConfirm={() => onDelete()}
          />
        )}
      </form>
    </ZenSidebarModal>
  );
};

export default ZenEditAgentLicense;
