import { faArrowUpRight } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import switchToGeminiImage from '../../../assets/img/gemini/switch-to-gemini.svg';
import { cn } from '../../../utils/classUtils';
import GeminiButton from '../GeminiButton';

interface SwitchToGeminiModalProps {
  modalOpen: boolean;
  onClose: () => void;
  handleSubmit: () => void;
}

const SwitchToGeminiModal: React.FC<SwitchToGeminiModalProps> = ({
  modalOpen,
  onClose,
  handleSubmit,
}) => {
  if (!modalOpen) {
    return null;
  }

  return (
    <div
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window !ml-0'
      aria-label='switch-to-gemini-modal'
    >
      <div className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40'>
        <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
          <div
            className={cn(
              'sm:w-[537px] bg-white rounded-xl shadow-zen-web p-2 z-10 pb-6 sm:pb-9',
            )}
          >
            <div className='mb-10 sm:mb-7'>
              <img
                src={switchToGeminiImage}
                alt='switch-to-gemini'
                className='w-full object-contain'
              />
            </div>
            <div className='text-center font-poppins text-base sm:text-xl font-semibold'>
              You are now being redirected to the <br /> new look & feel of
              reZEN
            </div>
            <div className='flex justify-center mt-9 sm:mt-6'>
              <div className='flex justify-center gap-x-3 sm:w-4/6 w-full'>
                <div className='flex-1'>
                  <GeminiButton
                    isFullWidth
                    label='Cancel'
                    variant='dark-outline'
                    onClick={onClose}
                  />
                </div>
                <div className='flex-1'>
                  <GeminiButton
                    isFullWidth
                    label='Take Me There'
                    variant='primary'
                    onClick={() => {
                      handleSubmit();
                      onClose();
                    }}
                    rightIcon={faArrowUpRight}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchToGeminiModal;
