import { FC } from 'react';
import { useSelector } from 'react-redux';
import NeoLeoThinking from '../../assets/img/leo/NeoLeoThinking.mp4';
import { RootState } from '../../types';
import NeoLeoNotificationsContainer from './Notifications/NeoLeoNotificationsContainer';

interface NeoLeoIntroProps {
  showAllNotifications: boolean;
  setShowAllNotifications: (show: boolean) => void;
}

const NeoLeoIntro: FC<NeoLeoIntroProps> = ({
  showAllNotifications,
  setShowAllNotifications,
}) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  if (showAllNotifications) {
    return (
      <div className='font-inter bg-primary-navy w-full h-full overflow-hidden flex flex-col scrollbar-hide'>
        <div className='h-full overflow-auto scrollbar-hide pb-4 px-4'>
          <NeoLeoNotificationsContainer
            showAllNotifications={showAllNotifications}
            setShowAllNotifications={setShowAllNotifications}
          />
        </div>
      </div>
    );
  }

  return (
    <div className='font-inter bg-primary-navy w-full h-full overflow-hidden flex flex-col scrollbar-hide'>
      <div className='h-full flex flex-col flex-grow overflow-auto scrollbar-hide pb-4'>
        <div className='px-6 mb-10'>
          <video
            src={NeoLeoThinking}
            autoPlay
            loop
            muted
            className='w-24 h-auto mt-8 mb-6 rounded-full'
          />
          <p className='font-poppins text-white font-normal text-3xl mb-1'>
            Hi {userDetail?.firstName}
          </p>
          <p className='font-poppins text-[#828CB3] font-normal text-3xl'>
            How can I help you today?
          </p>
        </div>
        <div className='px-4'>
          <NeoLeoNotificationsContainer
            showAllNotifications={showAllNotifications}
            setShowAllNotifications={setShowAllNotifications}
          />
        </div>
      </div>
    </div>
  );
};

export default NeoLeoIntro;
