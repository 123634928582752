import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { FeatureFlagTypeEnum } from '../../../types';
import WalletRepayment from './WalletRepayment';
import WalletOutgoingPayments from './WalletOutgoingPayments';
import ZenAddMoneyTransfersToClosedTransaction from './ZenAddMoneyTransfersToClosedTransaction';
import ZenBankAccounts from './ZenBankAccounts';
import ZenDeleteEscrowDepositOrInstallment from './ZenDeleteEscrowDepositOrInstallment';
import ZenRevShare from './ZenRevShare';
import ZenShareWorks from './ZenShareWorks';
import ZenShareWorksReleases from './ZenShareWorksReleases';

interface ZenFinanceComponentProps {}

const ZenFinanceComponent: React.FC<ZenFinanceComponentProps> = () => {
  const isWalletFeatureEnabled = useFeatureFlag(FeatureFlagTypeEnum.WALLET);

  return (
    <div className='lg:w-4/5 w-full mx-auto p-10'>
      <div className='font-primary-medium font-bold text-center md:text-6xl text-4xl'>
        Finance
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 my-10'>
        <ZenShareWorks />
        <ZenRevShare />
        <ZenBankAccounts />
        <ZenAddMoneyTransfersToClosedTransaction />
        <ZenDeleteEscrowDepositOrInstallment />
        <ZenShareWorksReleases />
        {isWalletFeatureEnabled && <WalletOutgoingPayments />}
        {isWalletFeatureEnabled && <WalletRepayment />}
      </div>
    </div>
  );
};

export default ZenFinanceComponent;
