import { useState } from 'react';
import { useSelector } from 'react-redux';
import CreditOfferGradient from '../../../assets/img/wallet/credit-offer-gradient.png';
import { EligibleCreditOfferingDto } from '../../../openapi/wallet';
import { useApplyForLending } from '../../../query/wallet/useWallet';
import { RootState } from '../../../types';
import { formatMoneyValue } from '../../../utils/CurrencyUtils';
import ZenButton from '../../Zen/ZenButton';
import WalletAgreementModal from '../WalletAgreementModal';
import WalletOfferAcceptedModal from './WalletOfferAcceptedModal';
import WalletOfferDetailsModal from './WalletOfferDetailsModal';

interface WalletCreditOfferProps {
  offer: EligibleCreditOfferingDto;
}

const WalletCreditOffer: React.FC<WalletCreditOfferProps> = ({ offer }) => {
  const firstName = useSelector(
    (state: RootState) => state.auth.userDetail?.firstName,
  );

  const [showOfferDetails, setShowOfferDetails] = useState<boolean>();
  const [showAgreement, setShowAgreement] = useState<boolean>();
  const [showOfferAccepted, setShowOfferAccepted] = useState<boolean>();

  const { mutate: applyForLending } = useApplyForLending();

  const onEverythingSigned = () => {
    setShowAgreement(false);
    setShowOfferAccepted(true);

    if (offer?.creditOffering?.id) {
      applyForLending({ creditOfferingId: offer?.creditOffering?.id });
    }
  };

  const Card: React.FC<{ title: string; subtitle: string }> = ({
    title,
    subtitle,
  }) => (
    <div className='p-3 rounded-xl bg-white bg-opacity-30 shadow-lg backdrop-blur-[8.2px] border border-white border-opacity-30 flex-1'>
      <p className='font-inter font-medium text-base text-primary-dark'>
        {title}
      </p>
      <p className='font-inter font-light text-sm text-primary-dark'>
        {subtitle}
      </p>
    </div>
  );

  return (
    <div
      className='mb-7 flex flex-col items-center flex-shrink-0 rounded-xl shadow-lg p-6'
      style={{
        backgroundImage: `url(${CreditOfferGradient})`,
        backgroundSize: 'cover',
      }}
    >
      <div className='w-full flex flex-col items-start gap-6'>
        <div className='flex flex-col gap-2'>
          <p className='font-inter font-medium text-sm text-white'>
            Congrats! {firstName}
          </p>
          <p className='font-poppins font-semibold text-4xl text-white'>
            {formatMoneyValue(offer?.creditLimit)} Credit <br />
            is ready for you!
          </p>
        </div>

        <div className='flex flex-col items-center self-stretch gap-2'>
          <ZenButton
            label={`Claim Your ${formatMoneyValue(offer?.creditLimit)} Now!`}
            onClick={() => setShowOfferDetails(true)}
            className='!font-inter text-base bg-primary-dark border-0 py-3'
            isFullWidth
          />
          <p className='font-inter font-normal text-sm text-white'>
            Takes only 2 mins, No impact to your credit score
          </p>
        </div>
      </div>

      <div className='flex flex-col xl:flex-row gap-3 mt-8'>
        <Card title='Instant funds in your Wallet' subtitle='No Delays!' />
        <Card title='Pre-approved' subtitle='No collateral needed' />
        <Card title='No hidden fees' subtitle='No Surprises' />
      </div>

      {showOfferDetails && (
        <WalletOfferDetailsModal
          offer={offer}
          isOpen={offer && showOfferDetails}
          onClose={() => setShowOfferDetails(false)}
          onAgree={() => {
            setShowOfferDetails(false);
            setShowAgreement(true);
          }}
        />
      )}

      {showAgreement && (
        <WalletAgreementModal
          offer={offer?.creditOffering}
          onClose={() => setShowAgreement(undefined)}
          onEverythingSigned={onEverythingSigned}
        />
      )}

      {showOfferAccepted && (
        <WalletOfferAcceptedModal onClose={() => setShowOfferAccepted(false)} />
      )}
    </div>
  );
};

export default WalletCreditOffer;
