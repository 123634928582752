import { faCheck, faCopy } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { cn } from '../../utils/classUtils';

interface CopyCallNumberProps {
  label?: string;
  value: string;
}

const CopyCallNumber: React.FC<CopyCallNumberProps> = ({
  value,
  label = 'Copy to Clipboard',
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    if (navigator.clipboard && window.isSecureContext)
      navigator.clipboard.writeText(value);
    else {
      // text area method
      let textArea = document.createElement('textarea');
      textArea.value = value;
      // make the textarea out of viewport
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      new Promise<void>((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className='flex flex-col items-center my-1 space-x-2'>
      <button
        className='flex items-center text-rezen-blue-600 space-x-1 p-0.5 focus:outline-none'
        onClick={onCopyText}
        type='button'
      >
        <FontAwesomeIcon icon={faCopy} />
        <p>{label}</p>
      </button>
      <div
        className={cn(
          'invisible flex items-center justify-center ml-1 space-x-1',
          isCopied && 'visible',
        )}
      >
        <FontAwesomeIcon icon={faCheck} fontSize={14} />
        <p className='text-xs'>Copied</p>
      </div>
    </div>
  );
};

export default CopyCallNumber;
