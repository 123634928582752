import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  faAddressBook,
  faHandshake,
  faWallet,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PARTNER_REDIRECT_URL } from '../../../constants/MarketingCenterLinksConstants';
import useAgentRedirect from '../../../hooks/useAgentRedirect';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { fetchInstantPaymentsSummary } from '../../../slices/InstantPaymentSlice';
import { AppDispatch, RootState, FeatureFlagTypeEnum } from '../../../types';
import { isCanadianUser } from '../../../utils/AgentHelper';
import { showInstantPayments } from '../../../utils/InstantPaymentHelper';
import { getCombinedMembershipsTeam } from '../../../utils/TeamHelper';
import { getInboxLink } from '../../../utils/UrlUtils';
import { ReactComponent as RealTitleIcon } from '../../../assets/icons/real-title-icon.svg';
import SwitchToGeminiToggle from '../../Gemini/SwitchToGeminiToggle';
import ZenSidebarItem from '../../ZenSideBarItem';
import TipaltiSidebarMenuItem from '../TipaltiSidebarMenuItem';
import UserOfficesSidebarMenuItem from '../UserOfficesSidebarMenuItem';
import UserRealAcademySidebarMenuItem from '../UserRealAcademySidebarMenuItem';
import RealMarketingSidebarMenuItem from '../ZenMenu/RealMarketingSidebarMenuItem';
import RealSignatureSidebarMenuItem from '../ZenMenu/RealSignatureSidebarMenuItem';
import ZenNewBadge from '../ZenMenu/ZenNewBadge';
import ZenUserTeamsSidebarMenuItem from '../ZenMenu/ZenUserTeamsSidebarMenuItem';
import ZenUnreadCountPill from '../ZenMenu/ZenUnreadCountPill';
import OneRealImpactSidebarMenuItem from '../ZenMenu/OneRealImpactSidebarMenuItem';

interface NeoUserMenuProps {
  isCollapsed: boolean;
}

const NeoUserMenu: React.FC<NeoUserMenuProps> = ({ isCollapsed }) => {
  useAgentRedirect();
  const dispatch = useDispatch<AppDispatch>();
  const {
    auth: { userDetail },
    instantPayment: {
      instantPaymentsSummaryResponse: { data: instantPaymentsSummary },
    },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(fetchInstantPaymentsSummary(userDetail?.id!, false));
  }, [dispatch, userDetail?.id]);

  const location = useLocation();

  const isCanadian = isCanadianUser(userDetail?.accountCountry!);
  const allTeams = getCombinedMembershipsTeam(userDetail!);

  const isWalletFeatureEnabled = useFeatureFlag(FeatureFlagTypeEnum.WALLET);
  const isReferralCentralFeatureEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.REFERRAL_CENTRAL,
  );

  return (
    <div>
      <ZenSidebarItem
        icon={<FontAwesomeIcon icon={solid('house')} />}
        label='My Dashboard'
        linkTo={`/people/${userDetail?.id}`}
        collapsed={isCollapsed}
      />
      <ZenSidebarItem
        collapsed={isCollapsed}
        icon={
          isCollapsed ? (
            <ZenUnreadCountPill isCollapsed={isCollapsed} />
          ) : (
            <FontAwesomeIcon icon={light('inbox')} />
          )
        }
        label='Inbox'
        hideActive
        linkTo={getInboxLink(location.search)}
        target='_blank'
        BadgeComponent={<ZenUnreadCountPill />}
      />
      <ZenSidebarItem
        icon={<FontAwesomeIcon icon={light('clipboard-list')} />}
        label='My Transactions'
        linkTo='/transactions'
        collapsed={isCollapsed}
      />
      <ZenSidebarItem
        icon={<FontAwesomeIcon icon={light('list')} />}
        label='My Listings'
        linkTo='/listings'
        collapsed={isCollapsed}
      />
      {!isCanadian && isWalletFeatureEnabled && (
        <ZenSidebarItem
          collapsed={isCollapsed}
          icon={<FontAwesomeIcon icon={faWallet} />}
          label='Wallet'
          hideActive
          linkTo='/wallet'
          BadgeComponent={!isCollapsed ? <ZenNewBadge /> : undefined}
          showNewBadge
        />
      )}
      <UserOfficesSidebarMenuItem
        offices={userDetail?.offices || []}
        isCollapsed={isCollapsed}
      />
      <ZenUserTeamsSidebarMenuItem
        teamMemberships={allTeams}
        isCollapsed={isCollapsed}
      />
      <RealSignatureSidebarMenuItem isCollapsed={isCollapsed} />
      <ZenSidebarItem
        icon={<FontAwesomeIcon icon={light('hands-holding-dollar')} />}
        label='WealthPlan'
        linkTo='/wealthplan'
        collapsed={isCollapsed}
        target='_blank'
        BadgeComponent={!isCollapsed ? <ZenNewBadge /> : undefined}
      />
      <ZenSidebarItem
        icon={<FontAwesomeIcon icon={faAddressBook} />}
        label='Directory'
        linkTo='/directory'
        collapsed={isCollapsed}
      />
      <ZenSidebarItem
        icon={<FontAwesomeIcon icon={faHandshake} />}
        label='Partners'
        linkTo={PARTNER_REDIRECT_URL}
        collapsed={isCollapsed}
        target='_blank'
        BadgeComponent={!isCollapsed ? <ZenNewBadge /> : undefined}
        isExternal
      />
      <RealMarketingSidebarMenuItem isCollapsed={isCollapsed} />
      {!isCanadian && (
        <React.Fragment>
          <ZenSidebarItem
            icon={<FontAwesomeIcon icon={light('building-columns')} />}
            label='One Real Mortgage'
            linkTo='/mortgages'
            collapsed={isCollapsed}
          />
          <ZenSidebarItem
            icon={<RealTitleIcon />}
            label='One Real Title'
            linkTo='/real-title'
            collapsed={isCollapsed}
          />
        </React.Fragment>
      )}
      <UserRealAcademySidebarMenuItem isCollapsed={isCollapsed} />
      <OneRealImpactSidebarMenuItem isCollapsed={isCollapsed} />
      <ZenSidebarItem
        icon={<FontAwesomeIcon icon={light('file-contract')} />}
        label='Reports'
        linkTo='/reports'
        collapsed={isCollapsed}
      />
      <ZenSidebarItem
        icon={<FontAwesomeIcon icon={light('calendar')} />}
        label='Events'
        linkTo='/my-events'
        collapsed={isCollapsed}
      />
      <TipaltiSidebarMenuItem isCollapsed={isCollapsed} />
      {showInstantPayments(instantPaymentsSummary) && (
        <ZenSidebarItem
          icon={<FontAwesomeIcon icon={light('credit-card')} />}
          label='Instant Payments'
          linkTo='/instant-payments'
          collapsed={isCollapsed}
        />
      )}
      {isReferralCentralFeatureEnabled && (
        <ZenSidebarItem
          icon={<FontAwesomeIcon icon={light('list')} />}
          label='Referral Central'
          linkTo='/referrals'
          collapsed={isCollapsed}
        />
      )}
      <ZenSidebarItem
        icon={<FontAwesomeIcon icon={light('link')} />}
        label='Quick Links'
        linkTo='/resources/links'
        collapsed={isCollapsed}
      />
      <SwitchToGeminiToggle collapsed={isCollapsed} />
    </div>
  );
};

export default NeoUserMenu;
