import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableColumns } from '@fortawesome/pro-regular-svg-icons';
import { RealStockDtoProviderEnum } from '../../../openapi/wallet';
import { useFetchDepositAccount } from '../../../query/wallet/useWallet';
import useFetchWalletPartnershipsAndToken from '../useFetchWalletPartnershipsAndToken';
import { getPendingRevenueSharePaymentsOverview } from '../../../slices/RevenueSlice';
import { AppDispatch, RootState } from '../../../types';
import {
  formatMoneyValue,
  numberWithCommas,
} from '../../../utils/CurrencyUtils';
import DefaultLoader from '../../DefaultLoader';
import ZenResourceContainer from '../../Zen/ZenResourceContainer';
import ZenDefaultEmpty from '../../Zen/ZenDefaultEmpty';
import WalletTotalValueCard from './WalletTotalValueCard';

interface WalletTotalValueProps {
  debitAccountId?: string;
}

const WalletTotalValue: React.FC<WalletTotalValueProps> = ({
  debitAccountId,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const agentId = useSelector((state: RootState) => state.auth.userDetail?.id);
  const {
    pendingPaymentsOverview,
    pendingPaymentsLoading,
    pendingPaymentsError,
  } = useSelector((state: RootState) => state.revenue);

  const { wallet, account, unitToken } = useFetchWalletPartnershipsAndToken(
    debitAccountId,
  );

  const {
    data: depositAccount,
    isLoading: depositAccLoading,
    errorCode: depositAccError,
  } = useFetchDepositAccount(account?.externalId, unitToken?.token);

  const stocks = useMemo(() => {
    return wallet?.realStocks?.find(
      (stock) => stock.provider === RealStockDtoProviderEnum.Shareworks,
    );
  }, [wallet?.realStocks]);

  const totalStocks =
    (stocks?.vestedShareValue?.amount ?? 0) +
    (stocks?.unvestedShareValue?.amount ?? 0);

  const availableBalance = depositAccount?.data?.attributes?.available
    ? depositAccount?.data?.attributes?.available / 100 // cents to dollars - debit account balance
    : 0;

  const totalWalletValue =
    totalStocks +
    (pendingPaymentsOverview?.amount?.amount ?? 0) +
    availableBalance;

  // only consider depositAccount states if user has debit account
  const isLoading =
    pendingPaymentsLoading || !!(debitAccountId && depositAccLoading);
  const errorCode =
    pendingPaymentsError || (debitAccountId ? depositAccError : undefined);

  const cashBalanceAmount = '$'.concat(
    numberWithCommas(parseFloat(String(availableBalance)).toFixed(2)),
  );
  const totalStocksAmount = '$'.concat(
    numberWithCommas(parseFloat(String(totalStocks)).toFixed(2)),
  );
  const revShareAmount = formatMoneyValue(pendingPaymentsOverview?.amount, 2);

  useEffect(() => {
    if (agentId) {
      dispatch(getPendingRevenueSharePaymentsOverview(agentId));
    }
  }, [agentId, dispatch]);

  return (
    <ZenResourceContainer
      resourceName='value'
      emptyMessage='Total wallet value unavailable'
      loading={isLoading}
      isEmpty={isEmpty(wallet)}
      errorCode={errorCode}
      LoaderComponent={
        <div className='mb-7 p-6 rounded-lg bg-primary-navy'>
          <DefaultLoader />
        </div>
      }
      EmptyComponent={
        <div className='mb-7 p-6 rounded-lg bg-primary-navy'>
          <ZenDefaultEmpty
            message='Total wallet value is empty'
            icon={
              <FontAwesomeIcon
                icon={faTableColumns}
                className='text-3xl text-grey-400'
              />
            }
          />
        </div>
      }
    >
      {debitAccountId ? (
        <div className='p-6 gap-6 flex flex-col mb-7 bg-primary-navy text-white rounded-lg'>
          {/* Total Wallet Value Section */}
          <div className='flex flex-col justify-center items-center'>
            <p className='font-inter text-lg font-medium'>Total Wallet Value</p>
            <p className='font-inter font-bold text-[40px]'>
              $
              {numberWithCommas(
                parseFloat(String(totalWalletValue)).toFixed(2),
              )}
            </p>
          </div>
          <div className='flex gap-4 justify-between flex-col xl:flex-row'>
            <WalletTotalValueCard
              label='Cash'
              amount={cashBalanceAmount}
              linkProps={{
                to: `/wallet/dashboard/debit/${debitAccountId}`,
              }}
            >
              {depositAccount?.data?.attributes?.updatedAt ? (
                <p className='font-inter text-sm font-medium'>
                  <span className='text-regent-400'>Last updated </span>
                  <span>
                    {DateTime.fromISO(
                      depositAccount?.data?.attributes?.updatedAt,
                    ).toFormat('LLL dd, HH:mm')}
                  </span>
                </p>
              ) : null}
            </WalletTotalValueCard>
            <WalletTotalValueCard
              label='Stock'
              amount={totalStocksAmount}
              linkProps={{
                to: { pathname: 'https://www.shareworks.com' },
                target: '_blank',
              }}
            >
              <p className='font-inter text-sm font-medium'>
                <span className='text-regent-400'>Vested </span>
                <span>{formatMoneyValue(stocks?.vestedShareValue, 2)}</span>
                <span className='text-regent-600'> | </span>
                <span className='text-regent-400'>Unvested </span>
                <span>{formatMoneyValue(stocks?.unvestedShareValue, 2)}</span>
              </p>
            </WalletTotalValueCard>

            <WalletTotalValueCard
              label='RevShare'
              amount={revShareAmount}
              linkProps={{
                to: `/people/${agentId}/revshare/insights`,
              }}
            >
              <p className='font-inter text-sm italic font-medium text-regent-400'>
                Pending
              </p>
            </WalletTotalValueCard>
          </div>
        </div>
      ) : (
        <div className='p-6 gap-6 flex flex-col xl:flex-row mb-7 bg-primary-navy text-white rounded-lg'>
          {/* Total Wallet Value Section */}
          <div className='flex-1'>
            <p className='font-inter text-lg font-medium'>Total Wallet Value</p>
            <p className='font-inter font-bold text-[40px]'>
              $
              {numberWithCommas(
                parseFloat(String(totalWalletValue)).toFixed(2),
              )}
            </p>
          </div>

          <WalletTotalValueCard
            label='Stock'
            amount={totalStocksAmount}
            linkProps={{
              to: { pathname: 'https://www.shareworks.com' },
              target: '_blank',
            }}
          >
            <p className='font-inter text-sm font-medium'>
              <span className='text-regent-400'>Vested </span>
              <span>{formatMoneyValue(stocks?.vestedShareValue, 2)}</span>
              <span className='text-regent-600'> | </span>
              <span className='text-regent-400'>Unvested </span>
              <span>{formatMoneyValue(stocks?.unvestedShareValue, 2)}</span>
            </p>
          </WalletTotalValueCard>

          <WalletTotalValueCard
            label='RevShare'
            amount={revShareAmount}
            linkProps={{
              to: `/people/${agentId}/revshare/insights`,
            }}
          >
            <p className='font-inter text-sm italic font-medium text-regent-400'>
              Pending
            </p>
          </WalletTotalValueCard>
        </div>
      )}
    </ZenResourceContainer>
  );
};

export default WalletTotalValue;
