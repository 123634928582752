import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faMoneyBillTransfer } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flatten, uniq } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CellProps, Column } from 'react-table';
import {
  BankAccountDto,
  MoneyTransferResponse,
  MoneyValue,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { fetchAgentsInfo } from '../../../slices/UserIdsSlice';
import { RootState } from '../../../types';
import { getFullName } from '../../../utils/AgentHelper';
import { displayAmount } from '../../../utils/CurrencyUtils';
import HoverItem from '../../HoverItem';
import IconButton from '../../IconButton';
import MoneyTransferAccountCell from '../../table/Cells/MoneyTransferAccountCell';
import ZenFixedDataResourceIndexContainer from '../../Zen/Containers/ZenFixedDataResourceIndexContainer';
import ZenPill from '../../Zen/ZenPill';
import ZenAddOrEditMoneyTransferSidebarModal from './ZenAddOrEditMoneyTransferSidebarModal';
import ZenMoneyTransferConfirmationModal from './ZenMoneyTransferConfirmationModal';

interface MoneyTransfersTableProps {
  transaction: TransactionResponse;
  moneyTransfers: MoneyTransferResponse[];
  bankAccounts: BankAccountDto[];
}

const ZenMoneyTransfersTable: React.FC<MoneyTransfersTableProps> = ({
  transaction,
  moneyTransfers,
  bankAccounts,
}) => {
  const dispatch = useDispatch();
  const [addMoneyTransfer, setAddMoneyTransfer] = useState<boolean>(false);
  const [moneyTransfer, setMoneyTransfer] = useState<MoneyTransferResponse>();
  const [
    moneyTransferConfirm,
    setMoneyTransferConfirm,
  ] = useState<MoneyTransferResponse>();

  const {
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  const columns: Array<Column<MoneyTransferResponse>> = useMemo(() => {
    const cols: Array<Column<MoneyTransferResponse>> = [
      {
        Header: `Amount (${transaction?.currency})`,
        accessor: (d) => d['amount']?.amount,
        Cell: ({
          row: { original },
        }: CellProps<MoneyTransferResponse, MoneyValue>) =>
          displayAmount(original.amount, { hideCurrency: true }),
      },
      {
        Header: 'FROM Account',
        accessor: 'fromBankAccountId',
        Cell: ({ row: { original } }) => {
          const bankAccount = bankAccounts.find(
            (bank) => bank.id === original.fromBankAccountId,
          );

          if (bankAccount) {
            return <MoneyTransferAccountCell bankAccount={bankAccount} />;
          } else {
            return <span>N/A</span>;
          }
        },
        disableSortBy: true,
      },
      {
        Header: 'TO Account',
        accessor: 'toBankAccountId',
        Cell: ({ row: { original } }) => {
          const bankAccount = bankAccounts.find(
            (bank) => bank.id === original.toBankAccountId,
          );

          if (bankAccount) {
            return <MoneyTransferAccountCell bankAccount={bankAccount} />;
          } else {
            return <span>N/A</span>;
          }
        },
        disableSortBy: true,
      },
      {
        Header: 'Reason',
        accessor: 'reason',
        Cell: ({ value }) => <div className='max-w-xs'>{value}</div>,
        disableSortBy: true,
      },
      {
        Header: 'Transfer Date',
        accessor: 'transferredAt',
        Cell: ({ value }) => value,
      },
      {
        Header: 'Added By',
        accessor: 'transferredBy',
        Cell: ({ value }) => {
          return getFullName(agentById[value!]);
        },
        disableSortBy: true,
      },
      {
        Header: 'Confirmed By',
        accessor: 'confirmedBy',
        Cell: ({ row: { original } }) =>
          original.confirmedBy ? (
            <div className='flex flex-col space-y-0.5'>
              <p>{getFullName(agentById[original.confirmedBy])}</p>
              <p>{`on ${DateTime.fromMillis(original.confirmedAt!).toFormat(
                'LL/dd/yyyy',
              )}`}</p>
            </div>
          ) : (
            <p className='font-primary-regular text-base text-gray-400'>
              Not Confirmed
            </p>
          ),
        disableSortBy: true,
      },
    ];

    cols.push({
      Header: 'Actions',
      id: 'actions',
      Cell: ({
        row: { original },
      }: CellProps<MoneyTransferResponse, MoneyTransferResponse>) => (
        <div className='flex items-center space-x-5'>
          {original.confirmedAt && (
            <ZenPill
              title='Confirmed'
              variant='success'
              leftIcon={
                <FontAwesomeIcon
                  icon={regular('thumbs-up')}
                  className='pr-1'
                  size='lg'
                />
              }
              border={false}
              textStyle='py-1'
            />
          )}
          {!original.confirmedAt && (
            <HoverItem
              onClick={() => setMoneyTransferConfirm(original)}
              label='Confirm'
              icon={
                <FontAwesomeIcon
                  icon={regular('check')}
                  title='confirm'
                  size='2xl'
                  className='text-green-600 px-0.5'
                />
              }
            />
          )}
          {!original.confirmedAt && (
            <HoverItem
              onClick={() => setMoneyTransfer(original)}
              label='Edit'
              icon={
                <FontAwesomeIcon
                  icon={regular('pencil')}
                  title='edit'
                  size='xl'
                  className='text-zen-dark-9 p-1'
                />
              }
            />
          )}
        </div>
      ),
      disableSortBy: true,
    });

    return cols;
  }, [agentById, bankAccounts, transaction]);

  const agentIds = useMemo(
    () =>
      uniq(
        flatten(
          moneyTransfers.map((transfer) => [
            transfer.confirmedBy!,
            transfer.transferredBy!,
          ]),
        ),
      ),
    [moneyTransfers],
  );

  useEffect(() => {
    if (agentIds && agentIds.length > 0) {
      dispatch(fetchAgentsInfo(agentIds));
    }
  }, [agentIds, dispatch]);

  return (
    <div className=''>
      <div className='flex flex-col md:flex-row md:justify-between md:items-center space-y-3 md:space-y-0 py-3'>
        <p className='font-semibold text-xl text-zen-dark-9'>Money Transfers</p>
        <div className='flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0'>
          <IconButton
            label='Add a Transfer'
            buttonStyle='border-primary-blue ring-primary-blue hover:bg-primary-blue hover:bg-opacity-10 !px-2 !py-1'
            textStyle='!text-primary-blue'
            variant='outline'
            leftIcon={
              <FontAwesomeIcon
                icon={faMoneyBillTransfer}
                className='text-primary-blue'
              />
            }
            onClick={() => {
              setAddMoneyTransfer(true);
              setMoneyTransfer(undefined);
            }}
          />
        </div>
      </div>
      <div>
        <ZenFixedDataResourceIndexContainer<MoneyTransferResponse>
          key={moneyTransfers.length}
          columns={columns}
          data={moneyTransfers}
          resourceName='Money Transfer'
          hidePagination
          hideFilters
        />
      </div>
      {(!!moneyTransfer || addMoneyTransfer) && (
        <ZenAddOrEditMoneyTransferSidebarModal
          isOpen={!!moneyTransfer || addMoneyTransfer}
          onClose={() => {
            setAddMoneyTransfer(false);
            setMoneyTransfer(undefined);
          }}
          transaction={transaction!}
          moneyTransfer={moneyTransfer}
          bankAccounts={bankAccounts}
        />
      )}
      <ZenMoneyTransferConfirmationModal
        isOpen={!!moneyTransferConfirm}
        onClose={() => setMoneyTransferConfirm(undefined)}
        transaction={transaction!}
        moneyTransfer={moneyTransferConfirm!}
        bankAccounts={bankAccounts}
      />
    </div>
  );
};

export default ZenMoneyTransfersTable;
