import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import { getPageTitleForTransaction } from '../../../utils/TransactionUtils';
import ZenRoute from '../ZenRoute';
import { RezenObjectTypeEnum } from '../../../openapi/yada';
import useTransactionMentions from '../../../hooks/useTransactionMentions';
import { getAllRealParticipantsFromTransaction } from '../../../utils/AgentHelper';
import {
  TransactionResponse,
  TransactionControllerApi,
} from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { cn } from '../../../utils/classUtils';
import ZenChecklistContainer from './ZenChecklistContainer';

interface ZenTransactionChecklistProps {}

const ZenTransactionChecklist: React.FC<ZenTransactionChecklistProps> = () => {
  const transactionDetail = useSelector(
    (state: RootState) => state.transaction.transactionDetailResponse.data,
  );

  const listingChecklistId = transactionDetail?.listingTransaction
    ?.checklistId!;

  const { getMentions, callerGroupId } = useTransactionMentions();
  const [listingDetail, setListingDetail] = useState<
    TransactionResponse | undefined
  >(undefined);

  const fetchListing = async (listingId: string) => {
    try {
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).getTransaction(listingId);

      setListingDetail(data);
    } catch (e) {
      ErrorService.notify('Unable to fetch the listing transaction', e, {
        agent: { listingId: listingId },
      });
    }
  };

  useEffect(() => {
    if (transactionDetail?.listingTransaction?.id) {
      fetchListing(transactionDetail?.listingTransaction?.id);
    }
  }, [transactionDetail?.listingTransaction?.id]);

  return (
    <ZenRoute
      title={`${getPageTitleForTransaction(transactionDetail)} Checklist - ${
        transactionDetail?.address?.oneLine
      }`}
    >
      <div className={cn(listingChecklistId && 'pt-5')}>
        <ZenChecklistContainer
          checklistId={transactionDetail?.checklistId!}
          title={listingChecklistId ? 'Transaction Checklist' : ''}
          containerType={RezenObjectTypeEnum.Transaction}
          containerId={transactionDetail?.id!}
          dropboxId={transactionDetail?.dropboxId!}
          getMentions={getMentions}
          callerGroupId={callerGroupId}
          assigneeList={getAllRealParticipantsFromTransaction(
            transactionDetail!,
          )}
        />
        {listingChecklistId && listingDetail?.id && (
          <ZenChecklistContainer
            checklistId={listingChecklistId!}
            title='Listing Checklist'
            containerType={RezenObjectTypeEnum.Transaction}
            containerId={listingDetail?.id!}
            dropboxId={listingDetail?.dropboxId!}
            secondaryDropboxId={transactionDetail?.dropboxId!}
            getMentions={getMentions}
            callerGroupId={callerGroupId}
            assigneeList={getAllRealParticipantsFromTransaction(listingDetail!)}
          />
        )}
      </div>
    </ZenRoute>
  );
};

export default ZenTransactionChecklist;
