import { faHandsHoldingHeart } from '@fortawesome/pro-light-svg-icons';
import { faHandsHoldingDollar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ONE_REAL_IMPACT_URL } from '../../../constants/MarketingCenterLinksConstants';
import { useGemini } from '../../../hooks/useGemini';
import { cn } from '../../../utils/classUtils';
import Hover from '../../Hover';
import useShowLeoTrigger from '../../Zen/Leo/useShowLeoTrigger';

interface OneRealImpactSidebarMenuItemProps {
  isCollapsed: boolean;
}

const OneRealImpactSidebarMenuItem: React.FC<OneRealImpactSidebarMenuItemProps> = ({
  isCollapsed,
}) => {
  const isGeminiEnabled = useGemini();
  const { showNeoLeoPanel } = useShowLeoTrigger();

  return (
    <Hover
      config={{
        placement: 'right',
        trigger: isCollapsed ? 'hover' : null,
      }}
      hoverComponent={
        <p className='px-2 whitespace-pre font-zen-body text-sm'>
          One Real Impact
        </p>
      }
      variant='dark'
    >
      <a
        className={cn(
          'flex flex-row items-center text-primary-light group z-0 rounded-lg my-2',
          isGeminiEnabled ? 'hover:bg-regent-900' : 'hover:bg-zen-dark-22',
          isCollapsed ? 'justify-center py-2.5' : 'justify-start px-2 py-1',
          {
            'w-10 h-10 rounded-xl py-2': showNeoLeoPanel,
          },
        )}
        href={ONE_REAL_IMPACT_URL}
        target='_blank'
        rel='noreferrer'
        title='one-real-impact'
      >
        <span
          className={cn(
            'h-4 w-4 flex items-center justify-center',
            isCollapsed ? 'text-xl' : 'text-sm',
            isGeminiEnabled
              ? 'group-hover:text-aqua'
              : 'group-hover:text-zen-primary-6',
          )}
        >
          <FontAwesomeIcon
            icon={showNeoLeoPanel ? faHandsHoldingHeart : faHandsHoldingDollar}
          />
        </span>
        {!isCollapsed && (
          <span className='p-1 items-center pl-2'>
            <div
              className={cn(
                'flex flex-row items-center space-x-2',
                isGeminiEnabled ? 'font-zen-body-2' : 'font-zen-body',
              )}
            >
              <span className='items-center text-sm'>One Real Impact</span>
            </div>
          </span>
        )}
      </a>
    </Hover>
  );
};

export default OneRealImpactSidebarMenuItem;
