import React from 'react';

interface GeminiTooltipProps {
  title: string;
  arrow: boolean;
  children: React.ReactNode;
}

const GeminiTooltip: React.FC<GeminiTooltipProps> = ({
  title,
  arrow,
  children,
}) => {
  return (
    <div className='relative group'>
      {children}
      <div className='absolute z-10 hidden group-hover:block left-1/2 top-full mt-2 -translate-x-1/2'>
        <div className='bg-gray-500 bg-opacity-75 text-white p-2 rounded text-[9px] break-words capitalize relative min-w-[300px] max-w-[350px] whitespace-normal'>
          {title}
          {arrow && (
            <span className='absolute top-[-6px] left-1/2 transform -translate-x-1/2 w-0 h-0 border-x-[6px] border-x-transparent border-b-[6px] border-b-gray-500 border-opacity-75' />
          )}
        </div>
      </div>
    </div>
  );
};

export default GeminiTooltip;
