import {
  faAddressCard,
  faCalendar,
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { last } from 'lodash';
import { DateTime } from 'luxon';
import useHideBrokerNumberFromAgent from '../../../hooks/useHideBrokerNumbersFromAgent';
import { AgentResponse } from '../../../openapi/yenta';
import { getYentaImageUrl } from '../../../utils/ImgUtils';
import {
  formatAddressObject,
  formatPhoneNumber,
} from '../../../utils/StringUtils';
import SelfOrAdminOnly from '../../auth/SelfOrAdminOnly';
import ProfileImage from '../../ProfileImage';

interface ZenPublicProfileDetailCardProps {
  agentInfo: AgentResponse;
}

const ZenPublicProfileDetailCard: React.FC<ZenPublicProfileDetailCardProps> = ({
  agentInfo,
}) => {
  const hideBrokerNumber = useHideBrokerNumberFromAgent(agentInfo.type!);

  return (
    <div className='md:absolute bg-white rounded-[30px] -mt-28 md:m-9 p-10 shadow-side-popup space-y-5 md:w-[350px] md:top-7 w-full z-20'>
      <div className='border-8 rounded-full border-zen-dark-12 w-min mx-auto'>
        <ProfileImage
          width={220}
          imageUrl={getYentaImageUrl(agentInfo?.avatar!)}
        />
      </div>

      <div className='space-y-3'>
        <p className='text-[22px] font-zen-body text-zen-dark-9 font-semibold'>
          {agentInfo?.fullName}
        </p>
        <SelfOrAdminOnly agentId={agentInfo?.id}>
          <div className='grid grid-cols-12 items-start space-x-1 mb-2'>
            <div className='col-span-1 flex items-center'>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className='text-lg text-zen-dark-9 mt-1'
              />
            </div>
            <div className='col-span-11'>
              <p className='text-zen-dark-9 font-zen-body text-base font-normal'>
                {!!agentInfo?.addresses?.length
                  ? formatAddressObject(last(agentInfo?.addresses)!)
                  : 'N/A'}
              </p>
            </div>
          </div>
        </SelfOrAdminOnly>
        <div className='grid grid-cols-12 items-center space-x-1 mb-2'>
          <div className='col-span-1 flex items-center'>
            <FontAwesomeIcon
              icon={faEnvelope}
              size='1x'
              className='text-zen-dark-9 mt-1'
            />
          </div>
          <div className='col-span-11'>
            <p className='text-zen-dark-9 font-zen-body text-base font-normal'>
              {agentInfo?.emailAddress}
            </p>
          </div>
        </div>
        {!hideBrokerNumber && (
          <div className='grid grid-cols-12 items-center space-x-1 mb-2'>
            <div className='col-span-1 flex items-center'>
              <FontAwesomeIcon
                icon={faPhone}
                size='1x'
                className='text-zen-dark-9'
              />
            </div>
            <div className='col-span-11'>
              <p className='text-zen-dark-9 font-zen-body text-base font-normal'>
                {formatPhoneNumber(agentInfo?.phoneNumber) || 'N/A'}
              </p>
            </div>
          </div>
        )}
        <div className='grid grid-cols-12 items-center space-x-1 mb-2'>
          <div className='col-span-1 flex items-center'>
            <FontAwesomeIcon
              icon={faAddressCard}
              size='1x'
              className='text-zen-dark-9'
            />
          </div>
          <div className='col-span-11'>
            <p className='text-zen-dark-9 font-zen-body text-base font-normal'>
              License No:{' '}
              {!!agentInfo?.licenses?.length
                ? last(agentInfo?.licenses)?.number
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className='grid grid-cols-12 items-center space-x-1 mb-2'>
          <div className='col-span-1 flex items-center'>
            <FontAwesomeIcon
              icon={faCalendar}
              className='text-lg text-zen-dark-9'
            />
          </div>
          <div className='col-span-11'>
            <p className='text-zen-dark-9 font-zen-body text-base font-normal'>
              Exp:{' '}
              {!!agentInfo?.licenses?.length
                ? DateTime.fromISO(
                    `${last(agentInfo?.licenses)?.expirationDate}`,
                  ).toFormat('LLLL dd, yyyy')
                : 'N/A'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenPublicProfileDetailCard;
