import axios from 'axios';
import { isEmpty } from 'lodash';
import {
  BankAccountApplicationsApi,
  BankingPartnershipApi,
  BankingPartnershipsDto,
  CreateCashAdvance,
  CreateCreditAccountApplication,
  CreateCustomerToken,
  CreateDebitAccountApplication,
  CreateInternalRepayment,
  CreateRealLendingAccount,
  CustomerTokenApi,
  CustomerTokenDto,
  EligibleOfferingsDto,
  InternalAccountApi,
  InternalAccountApplicationsApi,
  InternalAccountsDto,
  LendingAccountApi,
  OfferingApi,
  RepaymentsApi,
  WalletApi,
  WalletDto,
} from '../../openapi/wallet';
import {
  AgreementResponse,
  AgreementSigningRequest,
  UserAgreementControllerApi,
} from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import {
  getWalletConfiguration,
  getYentaConfiguration,
} from '../../utils/OpenapiConfigurationUtils';
import { queryKeys } from '../base/queryKeys';
import { useBaseMutation } from '../base/useBaseMutation';
import { useSimpleQuery } from '../base/useSimpleQuery';

export const CASH_ADVANCE_ERROR_MESSAGE =
  'Transaction failed. Please try again later.';

export const useWallet = () => {
  const fetchWalletDetails = async () => {
    const { data } = await new WalletApi(
      getWalletConfiguration(),
    ).getMyWallet();

    return data;
  };

  const queryResult = useSimpleQuery<WalletDto>({
    queryKey: queryKeys.wallet.details.queryKey,
    queryFn: fetchWalletDetails,
    options: {
      toastErrorMessage: 'Failed to fetch wallet!',
      logErrorMessage: 'Failed to fetch wallet!',
    },
  });

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading || queryResult.isFetching,
    errorCode: queryResult.error
      ? ErrorService.getErrorCode(queryResult.error)
      : undefined,
  };
};

export const useFetchWalletByUserId = (userId: string) => {
  const fetchWalletByUserId = async () => {
    const { data } = await new WalletApi(
      getWalletConfiguration(),
    ).getWalletByUserId(userId);

    return data;
  };

  const queryResult = useSimpleQuery<WalletDto>({
    queryKey: queryKeys.wallet.agentWallet(userId).queryKey,
    queryFn: fetchWalletByUserId,
    options: {
      enabled: !!userId,
      toastErrorMessage: 'Failed to fetch wallet by id!',
      logErrorMessage: 'Failed to fetch wallet by id!',
    },
  });

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading || queryResult.isFetching,
    errorCode: queryResult.error
      ? ErrorService.getErrorCode(queryResult.error)
      : undefined,
  };
};

export const useCreateInternalRepayment = () => {
  const createInternalRepayment = async (
    internalRepayment: CreateInternalRepayment,
  ) => {
    const { data } = await new RepaymentsApi(
      getWalletConfiguration(),
    ).createInternalRepayment(internalRepayment);

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.wallet._def,
    mutationFn: createInternalRepayment,
    successMessage: 'Repayment successfully created!',
  });
};

export const useFetchBankingPartnership = (
  isPartneredWithUnit: boolean = true,
) => {
  const fetchBankingPartnership = async () => {
    const { data } = await new BankingPartnershipApi(
      getWalletConfiguration(),
    ).getMyBankingPartnerships();

    return data;
  };

  const queryResult = useSimpleQuery<BankingPartnershipsDto>({
    queryKey: queryKeys.wallet.bankingPartnership.queryKey,
    queryFn: fetchBankingPartnership,
    options: {
      toastErrorMessage: 'Failed to fetch banking partnership!',
      logErrorMessage: 'Failed to fetch banking partnership!',
      enabled: isPartneredWithUnit,
    },
  });

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading || queryResult.isFetching,
    errorCode: queryResult.error
      ? ErrorService.getErrorCode(queryResult.error)
      : undefined,
  };
};

export const useFetchEligibleOfferings = () => {
  const fetchCreditDetails = async () => {
    const { data } = await new OfferingApi(
      getWalletConfiguration(),
    ).getEligibleOfferings();

    return data;
  };

  const queryResult = useSimpleQuery<EligibleOfferingsDto>({
    queryKey: queryKeys.wallet._def,
    queryFn: fetchCreditDetails,
    options: {
      toastErrorMessage: 'Failed to fetch offerings!',
      logErrorMessage: 'Failed to fetch offerings!',
    },
  });

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading || queryResult.isFetching,
    errorCode: queryResult.error
      ? ErrorService.getErrorCode(queryResult.error)
      : undefined,
  };
};

export const useDebitApplication = () => {
  const createDebitApplication = async (
    application: CreateDebitAccountApplication,
  ) => {
    const { data } = await new BankAccountApplicationsApi(
      getWalletConfiguration(),
    ).createDebitAccountApplication(application);

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.wallet._def,
    mutationFn: createDebitApplication,
  });
};

export const useCreditApplication = () => {
  const createCreditApplication = async (
    application: CreateCreditAccountApplication,
  ) => {
    const { data } = await new BankAccountApplicationsApi(
      getWalletConfiguration(),
    ).createCreditAccountApplication(application);

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.wallet._def,
    mutationFn: createCreditApplication,
  });
};

export const useCreateToken = (createCustomerToken: CreateCustomerToken) => {
  const createToken = async () => {
    const { data } = await new CustomerTokenApi(
      getWalletConfiguration(),
    ).createReadOnlyToken(createCustomerToken);

    return data;
  };

  const queryResult = useSimpleQuery<CustomerTokenDto>({
    queryKey: queryKeys.wallet.token.queryKey,
    queryFn: createToken,
    options: {
      toastErrorMessage: 'Failed to create customer token!',
      logErrorMessage: 'Failed to create customer token!',
      enabled: !!createCustomerToken.bankingPartnershipId,
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  });

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading || queryResult.isFetching,
    errorCode: queryResult.error
      ? ErrorService.getErrorCode(queryResult.error)
      : undefined,
  };
};

export const useFetchUnsignedAgreements = (
  userId?: string,
  agreementDefinitionIds?: string[],
) => {
  const fetchUnsignedAgreements = async () => {
    const { data } = await new UserAgreementControllerApi(
      getYentaConfiguration(),
    ).getUnsignedAgreementsByDefinitions(userId!, agreementDefinitionIds!);

    return data;
  };

  const queryResult = useSimpleQuery<Array<AgreementResponse>>({
    queryKey: queryKeys.wallet.agreement(agreementDefinitionIds || []).queryKey,
    queryFn: fetchUnsignedAgreements,
    options: {
      enabled: !!userId && !isEmpty(agreementDefinitionIds),
      toastErrorMessage: 'Failed to fetch unsigned agreements!',
      logErrorMessage: 'Failed to fetch unsigned agreements!',
    },
  });

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading || queryResult.isFetching,
    errorCode: queryResult.error
      ? ErrorService.getErrorCode(queryResult.error)
      : undefined,
    refetch: queryResult.refetch,
  };
};

export const useSignAgreement = () => {
  const sign = async (agreementSigningRequest: AgreementSigningRequest) => {
    const { data } = await new UserAgreementControllerApi(
      getYentaConfiguration(),
    ).signAgreement(agreementSigningRequest);

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.wallet.signAgreement.queryKey,
    mutationFn: sign,
  });
};

export type signAmendmentRequest = { agreementId: string; amendmentId: string };

export const useSignAmendment = () => {
  const sign = async (signAmendmentRequest: signAmendmentRequest) => {
    const { data } = await new UserAgreementControllerApi(
      getYentaConfiguration(),
    ).signAgreementAmendment(
      signAmendmentRequest.agreementId,
      signAmendmentRequest.amendmentId,
    );

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.wallet.signAgreement.queryKey,
    mutationFn: sign,
  });
};

export const useApplyForLending = () => {
  const applyForLending = async (
    createLendingAccRequest: CreateRealLendingAccount,
  ) => {
    const { data } = await new InternalAccountApplicationsApi(
      getWalletConfiguration(),
    ).applyForRealLendingAccount({ ...createLendingAccRequest, dueDay: 1 }); // TODO: Remove hardcoded dueDay after BE makes it optional

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.wallet.details.queryKey,
    mutationFn: applyForLending,
    successMessage: 'Successfully applied for credit offer!',
  });
};

export const useCreateCashAdvance = () => {
  const createCashAdvance = async (createCashAdvance: CreateCashAdvance) => {
    const { data } = await new LendingAccountApi(
      getWalletConfiguration(),
    ).createCashAdvance(createCashAdvance);

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.wallet.internalAccounts.queryKey,
    mutationFn: createCashAdvance,
    errorMessage: CASH_ADVANCE_ERROR_MESSAGE,
  });
};

export const useFetchInternalAccounts = () => {
  const fetchInternalAccounts = async () => {
    const { data } = await new InternalAccountApi(
      getWalletConfiguration(),
    ).getMyInternalAccounts();

    return data;
  };

  const queryResult = useSimpleQuery<InternalAccountsDto>({
    queryKey: queryKeys.wallet.internalAccounts.queryKey,
    queryFn: fetchInternalAccounts,
    options: {
      toastErrorMessage: 'Failed to fetch internal accounts!',
      logErrorMessage: 'Failed to fetch internal accounts!',
    },
  });

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading,
    errorCode: queryResult.error
      ? ErrorService.getErrorCode(queryResult.error)
      : undefined,
  };
};

export const useFetchDepositAccount = (accountId?: string, token?: string) => {
  const fetchDepositAccount = async () => {
    // This will not work in prod. Update the URL when RV2-32263 is merged.
    const { data } = await axios.get(
      `https://api.s.unit.sh/accounts/${accountId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return data;
  };

  const queryResult = useSimpleQuery({
    queryKey: queryKeys.wallet.depositAccounts.queryKey,
    queryFn: fetchDepositAccount,
    options: {
      toastErrorMessage: 'Failed to fetch deposit account!',
      logErrorMessage: 'Failed to fetch deposit account!',
      enabled: !!accountId && !!token,
    },
  });

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading || queryResult.isFetching,
    errorCode: queryResult.error
      ? ErrorService.getErrorCode(queryResult.error)
      : undefined,
  };
};
