import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { TransactionCoordinatorAccessEnum } from '../../types';
import { cn } from '../../utils/classUtils';
import { Button } from '../commons/Button';
import ZenAvatar from '../Zen/ZenAvatar';
import { TCInfo } from './TCInfoSection';
import TCTransactionsAccessCard, {
  TransactionCoordinatorFromData,
} from './TCTransactionsAccessCard';

interface TCAcceptOrRejectCardProps {
  control: Control<TransactionCoordinatorFromData, object>;
  setValue: UseFormSetValue<TransactionCoordinatorFromData>;
  selectedAccessType: TransactionCoordinatorAccessEnum;
  selectedTC: TCInfo | undefined;
  setSelectedTC: React.Dispatch<React.SetStateAction<TCInfo | undefined>>;
  tcInfo?: TCInfo;
  handleReject?: () => void;
  handleNext?: () => void;
}

const TCAcceptOrRejectCard: FC<TCAcceptOrRejectCardProps> = ({
  control,
  setValue,
  tcInfo,
  selectedAccessType,
  selectedTC,
  setSelectedTC,
  handleNext,
  handleReject,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const isTcSelected = !isEmpty(selectedTC);
  const showTeamName = tcInfo?.teamName ? true : false;

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    setSelectedTC(isOpen ? tcInfo : undefined);
  }, [isOpen, setSelectedTC, tcInfo]);

  return (
    <div className='w-full font-inter p-4'>
      <div className='flex flex-col sm:flex-row sm:items-center w-full gap-x-12 gap-y-6'>
        <div className='flex items-center gap-2 flex-1'>
          <ZenAvatar name={tcInfo?.name!} imageUrl={tcInfo?.avatar} size='lg' />
          <div
            className={cn({
              'w-2/3': !showTeamName,
            })}
          >
            <p className='text-primary-dark font-normal line-clamp-1 text-sm sm:text-base'>
              {tcInfo?.name}
            </p>
            <p className='text-grey-600 font-light text-xs sm:text-sm overflow-hidden text-ellipsis'>
              {showTeamName ? (
                <span className='line-clamp-1'>
                  <span>Invitation sent by </span>
                  <span className='font-medium text-primary-dark'>
                    {tcInfo?.name}
                  </span>
                  <span> from </span>
                  <span className='font-medium text-primary-dark'>
                    {tcInfo?.teamName}
                  </span>
                </span>
              ) : (
                <span>{tcInfo?.email}</span>
              )}
            </p>
          </div>
        </div>
        {!isOpen && (
          <div className='flex gap-3 ml-auto'>
            <Button
              variant='link'
              color='black'
              onClick={handleReject}
              disabled={isTcSelected}
            >
              Reject
            </Button>
            <Button
              variant='outline'
              color='grey'
              onClick={toggle}
              disabled={isTcSelected}
            >
              Accept
            </Button>
          </div>
        )}
      </div>
      {isOpen && (
        <div className='mt-4'>
          <TCTransactionsAccessCard
            control={control}
            setValue={setValue}
            selectedAccessType={selectedAccessType}
          />
          <div className='flex justify-end gap-5 mt-3'>
            <Button variant='link' color='black' onClick={toggle}>
              Cancel
            </Button>
            <Button variant='filled' color='blue' onClick={handleNext}>
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TCAcceptOrRejectCard;
