import { useDispatch, useSelector } from 'react-redux';
import { ItemResponse } from '../../../openapi/sherlock';
import { saveLeoErrorDetectionModalData } from '../../../slices/LeoSlice';
import { RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';

type size = 'sm' | 'md' | 'lg';

export interface ErrorCountCellProps {
  checklistItem?: ItemResponse;
  count: number;
  size?: size;
  documentId?: string;
  dropboxId?: string;
  onClick?: () => void;
}

const ErrorCountCell: React.FC<ErrorCountCellProps> = ({
  checklistItem,
  count,
  size = 'md',
  documentId,
  dropboxId,
  onClick,
}) => {
  const dispatch = useDispatch();
  const {
    leo: { checklistItemDocumentErrorResponseById },
  } = useSelector((state: RootState) => state);

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (count > 0 && !!documentId) {
      dispatch(
        saveLeoErrorDetectionModalData({
          ...checklistItemDocumentErrorResponseById?.[checklistItem?.id!]?.[
            documentId!
          ]!,
          dropboxId,
        }),
      );
    }
  };

  const sizeToClassNameMap: { [type in size]: string } = {
    sm: 'h-7 w-7',
    md: 'h-8 w-8',
    lg: 'h-9 w-9',
  };

  return (
    <div
      className={cn(
        'rounded-full font-inter text-white font-medium flex justify-center items-center',
        count > 0 ? 'bg-red-600 cursor-pointer' : 'bg-green-600',
        sizeToClassNameMap[size],
      )}
      onClick={() => {
        handleClick();
      }}
      data-testid={`${count}-errors`}
    >
      {count}
    </div>
  );
};

export default ErrorCountCell;
