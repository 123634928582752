import { DateTime } from 'luxon';
import JasonLopezSignature from '../../assets/img/jason-lopez-signature.jpeg';
import TamirsSignatureImg from '../../assets/img/tamirs-signature.png';
import DanPehlkeSignatureImg from '../../assets/img/dan-pehlke-signature.png';
import {
  AddressStateEnum,
  CommissionDocumentPayeeResponseTransactionParticipantRoleEnum,
  CommissionDocumentResponse,
  MoneyValue,
  TransactionResponse,
} from '../../openapi/arrakis';
import {
  getCdaAdditionalFees,
  isCdaListingSideOrDualRepresentation,
} from '../../utils/CdaUtils';
import {
  getCDATaxLineItem,
  getDocumentIdentifier,
  isRealParticipantForCDA,
} from '../../utils/CommissionDocumentHelper';
import { displayAmount } from '../../utils/CurrencyUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import FormLayout from '../FormLayout';
import HighlightedDiv from '../HighlightedDiv';
import ItemInfo from '../ItemInfo';
import RebatesSection from '../RebatesSection';
import SlimDataRowCapitalized from '../SlimDataRowCapitalized';

interface UnitedStatesCDAProps {
  cdaFormDetail: CommissionDocumentResponse;
  transaction: TransactionResponse;
  isPdf?: boolean;
}

const UnitedStatesCDA: React.FC<UnitedStatesCDAProps> = ({
  cdaFormDetail,
  transaction,
  isPdf = false,
}) => {
  let payees = cdaFormDetail.payees || [];

  if (cdaFormDetail.expectedPaymentToReal?.amount! < 0) {
    payees = payees.filter((p) => {
      return (
        p.transactionParticipantRole !==
        CommissionDocumentPayeeResponseTransactionParticipantRoleEnum.Real
      );
    });
  }

  const totalGrossCommission = cdaFormDetail?.totalGrossCommission as MoneyValue;
  const isTxListingSideOrDual = isCdaListingSideOrDualRepresentation(
    cdaFormDetail,
  );
  const additionalFees = getCdaAdditionalFees(cdaFormDetail);

  const agentState = transaction.address?.state;

  return (
    <FormLayout
      title={cdaFormDetail.documentTitle!}
      companyName={cdaFormDetail.companyName!}
      companyAddress={cdaFormDetail.officeAddress!}
      identifier={getDocumentIdentifier(cdaFormDetail)}
      revision={cdaFormDetail.revision}
      createdAt={cdaFormDetail.createdAt}
      isPdf={isPdf}
    >
      <div className='grid grid-cols-1 lg:grid-cols-2 print:grid-cols-2 print:text-xs'>
        <div>
          <SlimDataRowCapitalized
            name='Reference Number'
            value={cdaFormDetail.transactionCode}
          />
          <SlimDataRowCapitalized
            name='Agent Name'
            value={cdaFormDetail.transactionOwner?.name}
          />
          <SlimDataRowCapitalized
            name='Property Address'
            value={cdaFormDetail.propertyAddress}
          />
          <SlimDataRowCapitalized
            name='Representing'
            value={cdaFormDetail.representing}
          />
          <SlimDataRowCapitalized
            name='Buyer'
            value={cdaFormDetail.buyerName}
          />
          <SlimDataRowCapitalized
            name='Seller'
            value={cdaFormDetail.sellerName}
          />
        </div>
        <div>
          <SlimDataRowCapitalized
            name='Anticipated closing date'
            value={
              cdaFormDetail.anticipatedClosingDate
                ? DateTime.fromISO(
                    cdaFormDetail.anticipatedClosingDate,
                  ).toFormat('LL-dd-yyyy')
                : 'N/A'
            }
          />
          <SlimDataRowCapitalized
            name='Sales Price'
            value={displayAmount(cdaFormDetail.price!)}
          />
          <SlimDataRowCapitalized
            name='Total Gross Commission'
            value={displayAmount(totalGrossCommission!)}
          />
          {cdaFormDetail.saleCommissionAmount && (
            <SlimDataRowCapitalized
              name='Buyer Brokerage Commission'
              value={displayAmount(cdaFormDetail.saleCommissionAmount)}
            />
          )}
          {isTxListingSideOrDual && cdaFormDetail.listingCommissionAmount && (
            <SlimDataRowCapitalized
              name='Seller Brokerage Commission'
              value={displayAmount(cdaFormDetail.listingCommissionAmount)}
            />
          )}
          {!!cdaFormDetail.buyerRebateTotal?.amount && (
            <SlimDataRowCapitalized
              name='Rebate to Buyer'
              value={displayAmount(cdaFormDetail.buyerRebateTotal)}
            />
          )}
          {!!cdaFormDetail.sellerRebateTotal?.amount && (
            <SlimDataRowCapitalized
              name='Rebate to Seller'
              value={displayAmount(cdaFormDetail.sellerRebateTotal)}
            />
          )}
          {!!cdaFormDetail.additionalCommissionTotalAmount && (
            <SlimDataRowCapitalized
              name='Additional Commission'
              value={displayAmount(
                cdaFormDetail.additionalCommissionTotalAmount,
              )}
            />
          )}
          {!!cdaFormDetail.reimbursementTotalAmount && (
            <SlimDataRowCapitalized
              name='Reimbursements'
              value={displayAmount(cdaFormDetail.reimbursementTotalAmount)}
            />
          )}
        </div>
      </div>
      <div className='border-gray-600 border-separate rounded table-auto w-full my-4'>
        <div className='space-y-3'>
          {payees.map((payee) => (
            <section key={payee.fullName} className='break-inside-avoid'>
              <div className='border rounded p-2'>
                <div className='flex lg:flex-row print:flex-row flex-col'>
                  <div className='flex-col print:flex-col'>
                    <HighlightedDiv>
                      <div className='flex items-center flex-grow'>
                        <p className='text-lg print:text-md font-primary-medium mr-2 print:whitespace-nowrap whitespace-nowrap'>
                          {payee.fullName}
                        </p>
                        {isRealParticipantForCDA(payee) ? (
                          <span className='print:text-md text-lg ml-2'>
                            {cdaFormDetail.expectedPaymentToReal
                              ? displayAmount(
                                  cdaFormDetail.expectedPaymentToReal,
                                )
                              : 'N/A'}
                          </span>
                        ) : (
                          <span className='print:text-md text-lg ml-2'>
                            {payee.amount ? displayAmount(payee.amount) : 'N/A'}
                          </span>
                        )}
                      </div>
                    </HighlightedDiv>
                    <div>
                      <p className='text-lg'>
                        {capitalizeEnum(payee.transactionParticipantRole!)}
                      </p>
                    </div>
                    {!!payee.taxes?.length && (
                      <p>{getCDATaxLineItem(payee, cdaFormDetail)}</p>
                    )}
                  </div>
                  <div className='flex flex-grow lg:flex-row flex-col print:flex-row lg:space-x-10 print:justify-between space-y-4 lg:space-y-0 print:space-y-0 mt-2 text-xs lg:px-8 print:px-8'>
                    {payee.emailAddress && (
                      <ItemInfo title='Email:' value={payee.emailAddress} />
                    )}
                    {payee.phoneNumber && (
                      <ItemInfo title='Phone:' value={payee.phoneNumber} />
                    )}
                    {payee.licenseNumber && (
                      <ItemInfo title='License:' value={payee.licenseNumber} />
                    )}
                  </div>
                </div>
                {payee.paymentInstructions && (
                  <div>
                    <div className='border-t mb-2' />
                    <p className='text-xs uppercase font-primary-bold tracking-wider text-gray-700 mb-1 mt-3 print:text-xs'>
                      Payment Instructions:
                    </p>
                    <div className='rounded pb-2'>
                      <p className='whitespace-pre-wrap text-sm print:text-xs'>
                        {payee.paymentInstructions}
                      </p>
                    </div>
                    {!!payee.bankAccountNumber && (
                      <div className='border-t'>
                        <div className='flex flex-col lg:flex-row print:flex-row space-y-4 lg:space-y-0 print:space-y-0 justify-between pt-1 print:text-xs whitespace-pre-wrap text-sm'>
                          <ItemInfo title='Bank:' value={payee.bankName!} />
                          <ItemInfo
                            title='Payable To:'
                            value={cdaFormDetail!.companyName!}
                          />
                          <ItemInfo
                            title='Routing Number (for wires):'
                            value={payee.bankRoutingNumber!}
                          />
                          <ItemInfo
                            title='Account Number:'
                            value={payee.bankAccountNumber!}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {!!payee.wiringInstructions && (
                  <div className='mt-2 rounded whitespace-pre-wrap text-sm print:text-xs'>
                    {payee.wiringInstructions}
                  </div>
                )}

                {!!payee.additionalInstructions && (
                  <div>
                    <p className='text-xs uppercase font-primary-bold tracking-wider text-gray-700 mb-1 mt-3 print:text-xs'>
                      Additional Instructions:
                    </p>
                    <div className='p-3 border rounded whitespace-pre-wrap text-sm print:text-xs'>
                      {payee.additionalInstructions}
                    </div>
                  </div>
                )}
              </div>
            </section>
          ))}
        </div>
      </div>
      <RebatesSection additionalFees={additionalFees} />
      <div className='mt-2'>
        {cdaFormDetail.comments && (
          <p className='text-lg print:text-base'>
            <span className='medium mr-1'>Comments:</span>
            {cdaFormDetail.comments}
          </p>
        )}
        <p className='text-lg print:text-base mt-2'>
          <span className='medium mr-1'>Date Approved:</span>
          {cdaFormDetail.approvedOn
            ? DateTime.fromISO(cdaFormDetail.approvedOn).toFormat('LL-dd-yyyy')
            : 'N/A'}
        </p>
      </div>
      <div className='flex flex-row space-x-20'>
        <div className='mt-4'>
          <p className='text-lg print:text-base'>
            Approved By: Tamir Poleg, CEO
          </p>
          <img
            src={TamirsSignatureImg}
            alt="Tamir's Signature"
            className='h-10 mb-5'
          />
        </div>
        {agentState === AddressStateEnum.California && (
          <div className='mt-4'>
            <p className='text-lg print:text-base'>Jason Lopez, CA Broker</p>
            <img
              src={JasonLopezSignature}
              alt="Brokers's Signature"
              className='h-10 mb-5'
            />
          </div>
        )}
        {agentState === AddressStateEnum.Washington && (
          <div className='mt-4'>
            <p className='text-lg print:text-base'>Dan Pehlke, WA Broker</p>
            <img
              src={DanPehlkeSignatureImg}
              alt="WA Brokers's Signature"
              className='h-10 mb-5'
            />
          </div>
        )}
      </div>
    </FormLayout>
  );
};

export default UnitedStatesCDA;
