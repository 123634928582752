import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import { OfficeControllerApi, OfficeGroupResponse } from '../../openapi/yenta';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { DEFAULT_CACHE_TIME, DEFAULT_STALE_TIME } from '../base/config';
import { queryKeys } from '../base/queryKeys';
import { useSimpleQuery } from '../base/useSimpleQuery';

export const useFetchOfficeVisibleGroupsById = (officeId: string) => {
  return useSimpleQuery({
    queryKey: queryKeys.office.visibleGroups(officeId).queryKey,
    queryFn: async () => {
      const { data } = await new OfficeControllerApi(
        getYentaConfiguration(),
      ).getVisibleOfficeGroups(officeId);
      return data;
    },
    options: {
      enabled: !!officeId,
      logErrorMessage: 'Failed to fetch visible office groups',
      toastErrorMessage: 'Failed to fetch visible office groups',
    },
  });
};

export const useFetchMultipleOfficeVisibleGroupsByIds = (
  officeIds: string[],
) => {
  const queryResults = useQueries({
    queries:
      officeIds.map((id) => ({
        queryKey: queryKeys.office.visibleGroups(id).queryKey,
        queryFn: async () => {
          const { data } = await new OfficeControllerApi(
            getYentaConfiguration(),
          ).getVisibleOfficeGroups(id);
          return { ...data, officeId: id };
        },
        enabled: !!officeIds.length,
        cacheTime: DEFAULT_CACHE_TIME,
        staleTime: DEFAULT_STALE_TIME,
      })) ?? [],
  });

  const officeGroupsById = useMemo(() => {
    return queryResults.reduce((acc, qr) => {
      if (qr.data?.officeId) {
        acc[qr.data.officeId] = qr.data;
      }
      return acc;
    }, {} as Record<string, OfficeGroupResponse & { officeId: string }>);
  }, [queryResults]);

  const isLoading = queryResults.some((qr) => qr.isLoading);

  return {
    isLoading,
    data: officeGroupsById,
  };
};
