import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import GridCard from '../components/GridCard';
import HighlightedText from '../components/HighlightedText';
// import ScanQRCode from '../components/ScanQRCode';
import ResourceContainer from '../components/ResourceContainer';
import { AsyncResponse } from '../types';
import { displayAmount } from '../utils/CurrencyUtils';
import FormLayout from '../components/FormLayout';
import PagedPrint from '../components/PagedPrint';
import {
  CommissionDocumentPayeeResponseTransactionParticipantRoleEnum,
  CommissionDocumentResponse,
} from '../openapi/arrakis';
import DivContainer from '../components/DivContainer';
import { getDocumentIdentifier } from '../utils/CommissionDocumentHelper';
import { constructBillTo } from '../utils/InvoiceHelper';
import ExcludeAgent from '../components/auth/ExcludeAgent';
import FormPaymentParticipantsSection from '../components/FormPaymentParticipantsSection';
import { capitalizeEnum } from '../utils/StringUtils';
import { SUPPORT_EMAIL_ID } from '../constants/SupportConstants';

interface InvoiceFormProps {
  asyncResponse: AsyncResponse<CommissionDocumentResponse>;
  isPdf: boolean;
}

const InvoiceForm: React.FC<InvoiceFormProps> = ({
  asyncResponse: { data: invoiceData, loading, error },
  isPdf,
}) => {
  const tableHeaderStyle =
    'py-1.5 px-3 border w-1/2 uppercase font-primary-regular text-sm print:text-xs';
  const tableDataStyle = 'border py-1.5 px-3 print:text-xs';

  const realPayee =
    invoiceData?.payees &&
    invoiceData.payees!.filter(
      (payee) =>
        payee.transactionParticipantRole ===
        CommissionDocumentPayeeResponseTransactionParticipantRoleEnum.Real,
    )[0];

  const Container = isPdf ? PagedPrint : DivContainer;
  const isDataEmpty = isEmpty(invoiceData);

  return (
    <ResourceContainer
      loading={loading}
      errorCode={error}
      isEmpty={isDataEmpty}
      resourceName='Invoice'
    >
      <Container>
        {!isDataEmpty && invoiceData && (
          <div>
            <FormLayout
              title='Invoice'
              companyName={invoiceData.companyName!}
              companyAddress={invoiceData.officeAddress!}
              identifier={getDocumentIdentifier(invoiceData)}
            >
              <div className='pb-20'>
                <div className='flex flex-col justify-between md:flex-row print:flex-row'>
                  <div>
                    <p className='text-2xl print:text-lg text-mineshaft font-primary-medium'>
                      Real Broker, LLC
                    </p>
                    <p className='text-mineshaft print:text-xs'>
                      39899 Balentine Dr, Ste 200 Newark, CA 94560
                    </p>
                    <div className='flex flex-col md:flex-row print:flex-row'>
                      <p className='pr-5 text-mineshaft print:text-xs'>
                        Tel: +1 646-859-2368
                      </p>
                      <p className='text-mineshaft print:text-xs'>
                        {`Email: ${SUPPORT_EMAIL_ID}`}
                      </p>
                    </div>
                  </div>
                  <div className='pt-5 md:pt-0 print:pt-0'>
                    <div className='flex flex-row justify-between space-x-5'>
                      <p className='text-gray-500 print:text-xs'>
                        Invoice Number
                      </p>
                      <p className='print:text-xs'>
                        {getDocumentIdentifier(invoiceData)}
                      </p>
                    </div>
                    <div className='flex flex-row justify-between space-x-5'>
                      <p className='text-gray-500 print:text-xs'>
                        Date of Issue
                      </p>
                      <p className='print:text-xs'>
                        {DateTime.fromMillis(
                          invoiceData.createdAt!,
                        ).toLocaleString(DateTime.DATE_FULL)}
                      </p>
                    </div>
                    <div className='flex flex-row justify-between space-x-5'>
                      <p className='text-gray-500 print:text-xs'>Date Due</p>
                      <p className='print:text-xs'>
                        {DateTime.fromISO(
                          invoiceData.anticipatedClosingDate!,
                        ).toLocaleString(DateTime.DATE_FULL)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='grid grid-flow-row grid-cols-1 gap-5 py-5 md:grid-cols-2 lg:grid-cols-3 print:grid-cols-3 print:text-xs'>
                  <GridCard
                    items={[
                      {
                        name: 'Bill to',
                        value: constructBillTo(invoiceData.payer!),
                      },
                    ]}
                  />
                </div>
                <HighlightedText
                  text={`${displayAmount(
                    invoiceData?.invoice?.netPayout,
                  )} on ${DateTime.fromISO(
                    invoiceData.anticipatedClosingDate!,
                  ).toLocaleString(DateTime.DATE_FULL)}`}
                  textClassName='text-3xl print:text-lg font-primary-regular'
                />
                <div className='py-5'>
                  <div className='flex flex-row space-x-2'>
                    <p className='text-gray-500 print:text-xs'>
                      Property address:
                    </p>
                    <p className='print:text-xs'>
                      {invoiceData.propertyAddress}
                    </p>
                  </div>
                  {/*<div className='flex flex-row space-x-2'>*/}
                  {/*  <p className='text-gray-500 print:text-xs'>Tenant:</p>*/}
                  {/*  <p className='print:text-xs'>{invoiceData.tenantName}</p>*/}
                  {/*</div>*/}
                  {/*<div className='flex flex-row space-x-2'>*/}
                  {/*  <p className='text-gray-500 print:text-xs'>Monthly Rent:</p>*/}
                  {/*  <p className='print:text-xs'>*/}
                  {/*    {displayAmount(invoiceData.monthlyRent, 0)}*/}
                  {/*  </p>*/}
                  {/*</div>*/}
                  {/*<div className='flex flex-row space-x-2'>*/}
                  {/*  <p className='text-gray-500 print:text-xs'>Move In Date:</p>*/}
                  {/*  <p>*/}
                  {/*    {DateTime.fromISO(invoiceData.moveInAt).toLocaleString()}*/}
                  {/*  </p>*/}
                  {/*</div>*/}
                </div>
                <table className='w-full border border-collapse table-fixed'>
                  <tbody className='border'>
                    <tr className='border'>
                      <th
                        className={classNames(
                          'w-1/4 md:w-1/2 print:w-1/2',
                          tableHeaderStyle,
                        )}
                        align='left'
                      >
                        Description
                      </th>
                      <th
                        className={classNames('w-1/6', tableHeaderStyle)}
                        align='center'
                      />
                      <th
                        className={classNames('w-1/6', tableHeaderStyle)}
                        align='right'
                      >
                        Amount
                      </th>
                    </tr>

                    {/*{paymentRows}*/}

                    <tr className='break-inside-avoid'>
                      <td className={tableDataStyle}>Real Broker</td>
                      <td className={tableDataStyle} align='center' />
                      <td className={tableDataStyle} align='right'>
                        {displayAmount(invoiceData?.invoice?.grossCommission)}
                      </td>
                    </tr>
                    {invoiceData?.invoice?.taxes?.map((taxItem) => (
                      <tr className='break-inside-avoid' key={taxItem.type}>
                        <td className={tableDataStyle}>
                          {taxItem.type
                            ? capitalizeEnum(taxItem.type) + ' Tax'
                            : 'N/A'}
                        </td>
                        <td className={tableDataStyle} align='center' />
                        <td className={tableDataStyle} align='right'>
                          {displayAmount(taxItem.amount)}
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td className={tableDataStyle} />
                      <td className={tableDataStyle}>Subtotal</td>
                      <td className={tableDataStyle} align='right'>
                        {displayAmount(invoiceData?.invoice?.netPayout)}
                      </td>
                    </tr>
                    <tr>
                      <td className={tableDataStyle} />
                      <td className={tableDataStyle}>Amount Due</td>
                      <td className={tableDataStyle} align='right'>
                        {displayAmount(invoiceData?.invoice?.netPayout)}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className='py-2 border-b border-dashed'>
                  <p className='italic text-gray-500 print:text-xs'>
                    Thank you for being our valued customer.
                  </p>
                </div>
                <div className='break-inside-avoid'>
                  <p className='mt-5 print:text-xs'>
                    Pay {displayAmount(invoiceData?.invoice?.netPayout)} with
                    ACH or wire transfer
                  </p>
                  <div className='grid grid-cols-1 mt-3 mb-5 md:grid-cols-2 lg:grid-cols-3 print:grid-cols-3 print:text-xs'>
                    <GridCard
                      key={realPayee!.id}
                      items={[
                        {
                          name: 'Bank',
                          value: realPayee!.bankName!,
                        },
                        {
                          name: 'Routing Number',
                          value: realPayee!.bankRoutingNumber!,
                        },
                        {
                          name: 'Account Number',
                          value: realPayee!.bankAccountNumber!,
                        },
                        {
                          name: 'Payable To',
                          value: invoiceData!.companyName!,
                        },
                      ]}
                    />
                    {/*<div className='flex justify-end col-span-1 md:col-span-1 lg:col-span-2 print:col-span-2'>*/}
                    {/*  <div className='w-24'>*/}
                    {/*    <ScanQRCode*/}
                    {/*      label='scan'*/}
                    {/*      imageUrl='https://chart.googleapis.com/chart?cht=qr&chl=https%3A%2F%2Fwww.joinreal.com%2Fabout&chs=180x180&choe=UTF-8&chld=L|2'*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </FormLayout>
            {!isPdf && (
              <ExcludeAgent>
                <FormPaymentParticipantsSection
                  paymentParticipants={invoiceData?.paymentParticipants || []}
                  doubleEnderCommissionInfo={
                    invoiceData.doubleEnderCommissionInfo
                  }
                />
              </ExcludeAgent>
            )}
          </div>
        )}
      </Container>
    </ResourceContainer>
  );
};

export default InvoiceForm;
