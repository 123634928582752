import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  faArrowDownToLine,
  faArrowRotateLeft,
  faCheck,
  faPlus,
  faScissors,
} from '@fortawesome/pro-regular-svg-icons';
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compact, uniq } from 'lodash';
import qs from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CellProps,
  Column,
  FilterValue,
  Filters,
  IdType,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import EmptyTray from '../../../assets/img/EmptyTray.png';
import ActivateCommissionDocumentConfirmationModal from '../../../components/CommissionDocument/ActivateCommissionDocumentConfirmationModal';
import DefaultLoader from '../../../components/DefaultLoader';
import IconButton from '../../../components/IconButton';
import ResourceContainer from '../../../components/ResourceContainer';
import AuthorizationContainer from '../../../components/auth/AuthorizationContainer';
import useAgentsInfo from '../../../hooks/useAgentsInfo';
import useChecklistItemCommentsCount from '../../../hooks/useChecklistItemCommentsCount';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useFetchAsyncMappingResponse } from '../../../hooks/useFetchAsyncMappingResponse';
import useLatestCommentsForChecklistIds from '../../../hooks/useLatestCommentsForChecklistIds';
import { usePollApi } from '../../../hooks/usePollApi';
import { FileResponse } from '../../../openapi/dropbox';
import {
  ChecklistResponse,
  FileReferenceInfo,
  ItemDtoRequiredForEnum,
  ItemDtoStatusEnum,
  ItemRequest,
  ItemResponse,
  ItemResponseStatusEnum,
} from '../../../openapi/sherlock';
import { RezenObjectTypeEnum } from '../../../openapi/yada';
import ErrorService from '../../../services/ErrorService';
import {
  addFileReferencesToChecklistItem,
  downloadChecklistDocument,
  fetchCheckLists,
  fetchChecklistItemById,
  updateChecklistItem,
} from '../../../slices/CheckListSlice';
import {
  downloadAndUploadFilesToDropbox,
  downloadDropboxFileAndUploadToChecklistItem,
  downloadDropboxFileByVersion,
  fetchDropbox,
} from '../../../slices/DropboxSlice';
import { getChecklistItemDocumentErrors } from '../../../slices/LeoSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import {
  AppDispatch,
  FeatureFlagTypeEnum,
  ISelectOption,
  Mapping,
  RootState,
  YesNoType,
} from '../../../types';
import {
  checklistMultiSelectFilter,
  getChecklistRequiredForDisplayName,
  getUniqueLabelOptionForChecklist,
  selectIsChecklistV2,
} from '../../../utils/ChecklistUtils';
import { getDropboxFilesFromFileReferences } from '../../../utils/DropboxUtil';
import { downloadSelectedFiles } from '../../../utils/FileUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import {
  FIXED_TABLE_FILTER_TYPES,
  getPriorityWiseSortedData,
} from '../../../utils/TableUtils';
import { cn } from '../../../utils/classUtils';
import Hover from '../../Hover';
import MortgageAddChecklistTask from '../../Mortgage/MortgageAddChecklistTask';
import TableRowActionBar from '../../TableRowActionBar';
import ExcludeAgent from '../../auth/ExcludeAgent';
import ZenManageFileCabinetSidebarModal from '../../dropbox/ZenManageFileCabinetSidebarModal';
import useZenAllowSelection from '../../table/plugins/useZenAllowSelection';
import { MentionSource } from '../../transactions/Comments/ZenCommentSection';
import ZenControlledMultiSelectInput from '../Input/ZenControlledMultiSelectInput';
import LeoErrorDetectionModal from '../Leo/LeoErrorDetectionModal';
import ZenChecklistItemCommentsCell from '../Table/Cell/ZenChecklistItemCommentsCell';
import ZenResourceTable from '../Table/ZenResourceTable';
import ZenChecklistLabel from '../Transaction/ZenChecklistLabel';
import ZenButton from '../ZenButton';
import ChecklistDocumentErrorDetectionCell, {
  StatusEnum,
} from './ChecklistDocumentErrorDetectionCell';
import ZenAddCheckListItemForm, {
  NOT_ASSIGNED_VALUE,
} from './ZenAddCheckListItemForm';
import ZenChecklistFileNameCell from './ZenChecklistFileNameCell';
import ZenChecklistItemDocumentUploadCell from './ZenChecklistItemDocumentUploadCell';
import ZenChecklistItemRequiredToggle from './ZenChecklistItemRequiredToggle';
import ZenChecklistSearchFilterInput, {
  FormData as ChecklistSearchFilterFormData,
} from './ZenChecklistSearchFilterInput';

interface ZenChecklistContainerProps {
  checklistId: string;
  title?: string;
  getPublicUserInfo?: boolean;
  containerType: RezenObjectTypeEnum;
  containerId: string;
  dropboxId: string;
  secondaryDropboxId?: string;
  callerGroupId?: string;
  assigneeList?: ISelectOption[];
  getMentions?(searchTerm?: string): MentionSource[];
}
interface FormData {
  labels: ISelectOption[];
}

const checklistStatusPriority = [
  ItemResponseStatusEnum.Pending,
  ItemResponseStatusEnum.RevisionRequested,
  ItemResponseStatusEnum.Accepted,
  ItemResponseStatusEnum.NotStarted,
  'NO_STATUS',
];

const ZenChecklistContainer: React.FC<ZenChecklistContainerProps> = ({
  checklistId,
  title,
  getPublicUserInfo = false,
  containerType,
  containerId,
  dropboxId,
  secondaryDropboxId,
  getMentions,
  callerGroupId,
  assigneeList,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { checklistItemId } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const isBroker = useSelector((state: RootState) => state.auth.isBroker),
    isAdmin = useSelector((state: RootState) => state.auth.isAdmin),
    userDetail = useSelector((state: RootState) => state.auth.userDetail),
    hasMortgagePermission = useSelector(
      (state: RootState) => state.auth.hasMortgagePermission,
    ),
    checklist = useSelector(
      (state: RootState) => state.checklist.checklistsById[checklistId],
    ),
    isChecklistV2 = useSelector((state: RootState) =>
      selectIsChecklistV2(state, checklistId),
    ),
    dropboxById = useSelector((state: RootState) => state.dropbox.dropboxById),
    loadingById = useSelector((state: RootState) => state.dropbox.loadingById),
    checklistItemDocumentErrorResponseById = useSelector(
      (state: RootState) => state.leo.checklistItemDocumentErrorResponseById,
    );

  const [activateCdaId, setActivateCdaId] = useState<string | undefined>(
    undefined,
  );
  const [editingChecklistItemId, setEditingChecklistItemId] = useState<
    string | null
  >(null);
  const [isAddingChecklistItem, setIsAddingChecklistItem] = useState<boolean>(
    false,
  );
  const [isDownloadingDocument, setIsDownloadingDocument] = useState<
    Mapping<boolean>
  >({});
  const [isMarkingAsAccepted, setIsMarkingAsAccepted] = useState<
    Mapping<boolean>
  >({});
  const [isActionBarLoading, setIsActionBarLoading] = useState({
    download: false,
    archive: false,
  });
  const [
    isMarkingAsRevisionRequested,
    setIsMarkingAsRevisionRequested,
  ] = useState<Mapping<boolean>>({});
  const [
    currentChecklistSortPriority,
    setCurrentChecklistSortPriority,
  ] = useState<number[]>([]);
  const [isStatusSortedDesc, setIsStatusSortedDesc] = useState<boolean>(false);
  const [isItemUrgent, setIsItemUrgent] = useState<Mapping<boolean>>({});
  const queryParams = new URLSearchParams(location.search);
  const isBorrowerContainer = containerType === RezenObjectTypeEnum.Borrower;
  const isDropboxFilesTrashingEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.ARCHIVE_CHECKLIST_DROPBOX_FILES,
  );
  const automatedBrokerReviewEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.AUTOMATED_BROKER_REVIEW,
  );

  const getCheckList = useCallback(() => {
    if (checklistId) {
      dispatch(fetchCheckLists(checklistId));
    }
  }, [checklistId, dispatch]);

  const checklistAsyncResponse = useFetchAsyncMappingResponse<ChecklistResponse>(
    {
      name: 'checklist',
      fetch: getCheckList,
      response: checklist!,
    },
  );
  const checklistDetails = checklistAsyncResponse?.data;
  const filterChecklist = useMemo(() => {
    const checklist = (checklistDetails?.items || [])?.filter(
      (item) => !item?.hidden,
    );

    return checklist;
  }, [checklistDetails?.items]);

  const { control, watch } = useForm<FormData>({
    defaultValues: { labels: [] },
  });

  const [attachingToItem, setAttachingToItem] = useState<ItemResponse>();

  const agentIds = filterChecklist
    ?.map((item) => item.assignee!)
    .filter((assignee) => !!assignee);

  const markedAgentIds = filterChecklist
    ?.map((item) => item?.markedCompletedBy!)
    .filter((markedAgent) => !!markedAgent);

  useAgentsInfo([...agentIds, ...markedAgentIds], getPublicUserInfo);

  const checklistItemIds = useMemo(
    () => checklistDetails?.items?.map((item) => item?.id!),
    [checklistDetails?.items],
  );
  const canFetchErrors =
    automatedBrokerReviewEnabled && isChecklistV2 && !!checklistItemIds?.length;

  const isAnyFileProcessing = useMemo(
    () =>
      (checklistDetails?.items || []).some((item) =>
        item.fileReferences?.references?.some((file) => {
          const checklistError =
            checklistItemDocumentErrorResponseById[item.id!];
          return (
            checklistError?.[file?.fileId!]?.status === StatusEnum.PROCESSING
          );
        }),
      ),
    [checklistDetails?.items, checklistItemDocumentErrorResponseById],
  );

  const fetchChecklistErrors = useCallback(() => {
    if (canFetchErrors && !!checklistItemIds?.length) {
      dispatch(getChecklistItemDocumentErrors(checklistItemIds));
    }
  }, [canFetchErrors, checklistItemIds, dispatch]);

  // Fetch checklist errors & poll every 1 second until all errors are processed
  usePollApi(
    fetchChecklistErrors,
    isAnyFileProcessing,
    // polling every 3 seconds
    3000,
  );

  const handleOnAttach = async (
    files: FileResponse[],
    uploadAndAttach?: boolean,
  ) => {
    if (isChecklistV2) {
      if (!!uploadAndAttach) {
        const data = await dispatch(
          downloadAndUploadFilesToDropbox(files, dropboxId, userDetail?.id!),
        );
        files = compact(data);
      }
      const dropboxFileIds: FileReferenceInfo[] = files.map((file) => ({
        fileId: file?.id!,
        filename: file?.filename!,
      }));
      const data = await dispatch(
        addFileReferencesToChecklistItem(
          checklistId,
          attachingToItem?.id!,
          dropboxFileIds,
        ),
      );
      if (data) {
        setAttachingToItem(undefined);
        await dispatch(
          fetchChecklistItemById(checklistId, attachingToItem?.id!),
        );
      }
    } else {
      const uploadedStatuses = await Promise.all(
        files.map((file) =>
          dispatch(
            downloadDropboxFileAndUploadToChecklistItem(
              file,
              attachingToItem?.id!,
              containerId,
              userDetail?.id!,
            ),
          ),
        ),
      );

      const isAllFilesUploaded = uploadedStatuses.every((status) => status);

      if (isAllFilesUploaded) {
        setAttachingToItem(undefined);

        await dispatch(
          fetchChecklistItemById(checklistId, attachingToItem?.id!),
        );
        dispatch(showSuccessToast('File is attached successfully!'));
      }
    }
  };

  const isChecklistItemAvailble = !!filterChecklist?.find(
    (checklist) => checklist?.id === checklistItemId,
  );

  useEffect(() => {
    if (checklistItemId && isChecklistItemAvailble) {
      setEditingChecklistItemId(checklistItemId as string);
    }
  }, [checklistItemId, isChecklistItemAvailble]);

  const handleMarkAsAccepted = useCallback(
    async (item: ItemResponse) => {
      setIsMarkingAsAccepted((map) => ({
        ...map,
        [item.id!]: true,
      }));
      await dispatch(
        updateChecklistItem(
          checklistId,
          item.id!,
          {
            item: {
              ...item,
              name: item.name!,
              status: ItemDtoStatusEnum.Accepted,
              requiredFor: (item.requiredFor as unknown) as ItemDtoRequiredForEnum,
            },
          },
          false,
        ),
      );
      setIsMarkingAsAccepted((map) => ({
        ...map,
        [item.id!]: false,
      }));
    },
    [checklistId, dispatch],
  );

  const handleMarkAsRevisionRequested = useCallback(
    async (item: ItemResponse) => {
      setIsMarkingAsRevisionRequested((map) => ({
        ...map,
        [item.id!]: true,
      }));
      await dispatch(
        updateChecklistItem(
          checklistId,
          item.id!,
          {
            item: {
              ...item,
              name: item.name!,
              status: ItemDtoStatusEnum.RevisionRequested,
              requiredFor: (item.requiredFor as unknown) as ItemDtoRequiredForEnum,
            },
          },
          false,
        ),
      );
      setIsMarkingAsRevisionRequested((map) => ({
        ...map,
        [item.id!]: false,
      }));
    },
    [checklistId, dispatch],
  );

  const handleActionBarDownloadDocuments = useCallback(
    async (items: ItemResponse[]) => {
      try {
        const referenceFiles = items
          .map((item) =>
            getDropboxFilesFromFileReferences(
              item?.fileReferences?.references!,
              dropboxById!,
              dropboxId!,
            ),
          )
          .flat();

        await downloadSelectedFiles(referenceFiles, 'checklist-documents');
      } catch (e) {
        ErrorService.notify('Unable to download the files', e, {
          items: items,
        });
        dispatch(
          showErrorToast(
            'We had a problem downloading the documents',
            'Please try again in a few moments.',
          ),
        );
      }
    },
    [dispatch, dropboxById, dropboxId],
  );

  const handleDownloadDocument = useCallback(
    async (item: ItemResponse, referenceFiles: FileResponse[]) => {
      setIsDownloadingDocument((map) => ({ ...map, [item.id!]: true }));
      if (isChecklistV2) {
        await Promise.all(
          referenceFiles?.map((fileReference) =>
            dispatch(
              downloadDropboxFileByVersion(
                fileReference?.id!,
                fileReference?.currentVersion?.id!,
              ),
            ),
          ),
        );
      } else {
        await Promise.all(
          (item.documents || []).map((document) =>
            dispatch(downloadChecklistDocument(document.currentVersion?.id!)),
          ),
        );
      }
      setIsDownloadingDocument((map) => ({ ...map, [item.id!]: false }));
    },
    [dispatch, isChecklistV2],
  );

  const handleIsUrgent = useCallback(
    async (item: ItemResponse) => {
      setIsItemUrgent((map) => ({
        ...map,
        [item.id!]: true,
      }));
      const checklistItemReq: ItemRequest = {
        item: {
          ...(item || {}),
          position: item?.position,
          name: item?.name!,
          status: (item?.status as unknown) as ItemDtoStatusEnum,
          urgent: !item?.urgent,
          assignee: item?.assignee,
          required: item?.required,
          dueDate: item?.dueDate,
          labels: item?.labels,
          requiredFor: (item?.requiredFor as unknown) as ItemDtoRequiredForEnum,
        },
      };
      await dispatch(
        updateChecklistItem(checklistId, item.id!, checklistItemReq, false),
      );
      setTimeout(() => {
        setIsItemUrgent((map) => ({
          ...map,
          [item.id!]: false,
        }));
      }, 1000);
    },
    [checklistId, dispatch],
  );

  const fetchAllAssociatedDropbox = useCallback(async () => {
    const checklistTemplateReferences = filterChecklist
      ?.map((ele) => ele?.templateReferences?.references!)
      .flat();
    const templateReferenceDropboxIds = checklistTemplateReferences?.map(
      (ele) => ele.dropboxId!,
    );
    const uniqueIds = uniq(
      [...templateReferenceDropboxIds, dropboxId].filter((id) => !!id),
    );
    if (uniqueIds.length > 0) {
      await Promise.all(
        uniqueIds.map((fileCabinetId) => dispatch(fetchDropbox(fileCabinetId))),
      );
    }
  }, [filterChecklist, dropboxId, dispatch]);

  useEffect(() => {
    if (isChecklistV2) {
      fetchAllAssociatedDropbox();
    }
  }, [fetchAllAssociatedDropbox, isChecklistV2]);

  const columns: Array<Column<ItemResponse>> = useMemo(() => {
    const checklistColumns: Array<Column<ItemResponse>> = [
      {
        Header: '#',
        accessor: 'position',
        Cell: ({ value }) => (
          <div className='text-gray-900 cursor-pointer'>{value}.</div>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ row: { original } }) => (
          <div
            onClick={() => setEditingChecklistItemId(original.id!)}
            className='py-6'
            data-testid={`view-button-${original.id!}`}
          >
            <IconButton
              buttonStyle='border border-primary-blue bg-primary-blue rounded-lg px-1'
              variant='none'
              leftIcon={
                <FontAwesomeIcon
                  icon={faEye}
                  className='text-lg text-white m-1'
                  aria-label='view'
                />
              }
            />
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'fileReferences',
        Cell: ({ row: { original } }) => (
          <ChecklistDocumentErrorDetectionCell
            checklistItem={original}
            dropboxId={dropboxId}
            onClick={() => setEditingChecklistItemId(original.id!)}
            isDocumentTable={false}
          />
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Checklist Item',
        accessor: 'name',
        Cell: ({ value, row: { original } }) => (
          <ZenChecklistFileNameCell
            name={original.position + '. ' + value!}
            labels={original.labels?.map((l) => l.text!) || []}
            urgent={original.urgent || false}
            onFlagClick={() => handleIsUrgent(original)}
            isItemUrgent={isItemUrgent[original.id!]!}
            onClick={() => setEditingChecklistItemId(original.id!)}
          />
        ),
        sortType: (row1, row2) =>
          getPriorityWiseSortedData(
            row1.values.position + ' ' + row1.values.name,
            row2.values.position + ' ' + row2.values.name,
            [],
          ),
      },
      {
        Header: 'Required',
        accessor: 'required',
        id: 'required',
        Cell: ({ row: { original } }: CellProps<ItemResponse, boolean>) => (
          <div className='flex row justify-center items-center'>
            <div className='w-20'>
              <ZenChecklistItemRequiredToggle
                checklistId={checklistId}
                checklistItem={original}
              />
            </div>
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value, row: { original } }) => (
          <div
            className='cursor-pointer'
            onClick={() => setEditingChecklistItemId(original.id!)}
          >
            <ZenChecklistLabel
              status={value}
              isRequired={original?.required!}
              containerType={containerType}
              isAssigned={
                !!original?.assignee &&
                original?.assignee !== NOT_ASSIGNED_VALUE
              }
            />
          </div>
        ),
        filter: 'multiSelectFilter',
        sortType: (row1, row2) =>
          getPriorityWiseSortedData(
            row1.values.position,
            row2.values.position,
            currentChecklistSortPriority,
          ),
      },
      {
        Header: 'Required For',
        accessor: 'requiredFor',
        Cell: ({ value, row: { original } }) => (
          <div className='font-normal text-center'>
            {getChecklistRequiredForDisplayName(
              value!,
              original.required!,
              userDetail?.accountCountry!,
            ) || <FontAwesomeIcon icon={regular('dash')} title='dash' />}
          </div>
        ),
        filter: 'multiSelectFilter',
        sortType: (row1, row2) =>
          getPriorityWiseSortedData(
            row1.values.position,
            row2.values.position,
            currentChecklistSortPriority,
          ),
      },
      {
        Header: 'Urgent',
        accessor: 'urgent',
        Cell: ({ row: { original } }: CellProps<ItemResponse, boolean>) => (
          <div className='flex row justify-center items-center'>
            <span className='text-sm font-zen-body font-normal cursor-default pr-5'>
              {original.urgent
                ? capitalizeEnum(YesNoType.YES)
                : capitalizeEnum(YesNoType.NO)}
            </span>
          </div>
        ),
      },
      {
        Header: 'Due Date',
        accessor: 'dueDate',
      },
      {
        Header: 'Comments',
        accessor: 'id',
        id: 'comments',
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <ZenChecklistItemCommentsCell
            key={original.id}
            itemResponse={original}
            containerType={containerType}
            containerId={containerId}
            getMentions={getMentions}
            callerGroupId={callerGroupId}
          />
        ),
      },
      {
        Header: '',
        accessor: (d) => d['documents']?.map((d) => d.createdAt),
        id: 'documents',
        filter: 'multiDateFilter',
        disableSortBy: true,
        Cell: ({ row: { original } }: CellProps<ItemResponse, string>) => {
          const dropboxFiles = getDropboxFilesFromFileReferences(
            original?.fileReferences?.references!,
            dropboxById!,
            dropboxId!,
          );
          return (
            <div className='flex items-center text-dark'>
              <ZenChecklistItemDocumentUploadCell
                commentId={original.id!}
                docs={original.documents!}
                dropboxDocs={dropboxFiles!}
                item={original}
                checklistId={checklistId}
                containerId={containerId}
                dropboxId={dropboxId}
                onClickFileCabinet={() => setAttachingToItem(original)}
                openEditChecklistItemForm={() =>
                  setEditingChecklistItemId(original.id!)
                }
              />
            </div>
          );
        },
      },
      {
        Header: 'Labels',
        accessor: (d) => d['labels']?.map((label) => label.text),
        id: 'labels',
        filter: 'multiSelectFilter',
      },
      {
        Header: '',
        accessor: 'id',
        id: 'actions',
        disableSortBy: true,
        Cell: ({ row: { original, isSelected } }) => {
          const dropboxFiles = getDropboxFilesFromFileReferences(
            original?.fileReferences?.references!,
            dropboxById!,
            dropboxId!,
          );
          return (
            <div
              className={cn('w-40 grid grid-cols-4 gap-3', {
                'pointer-events-none opacity-30 cursor-not-allowed': isSelected,
              })}
            >
              {((!isChecklistV2 && !!original.documents?.length) ||
                (isChecklistV2 && !!dropboxFiles?.length)) && (
                <div>
                  <ResourceContainer
                    loading={isDownloadingDocument[original.id!]!}
                    isEmpty={false}
                    resourceName='download-item'
                    LoaderComponent={
                      <div className='py-2 pl-2.5 pr-0.5'>
                        <DefaultLoader noPadding iconSize='small' />
                      </div>
                    }
                  >
                    <Hover
                      hoverComponent={
                        <div className='text-zen-dark-12 font-zen-body text-base px-3 whitespace-pre'>
                          Download All Docs
                        </div>
                      }
                      config={{ trigger: 'hover', placement: 'top-start' }}
                    >
                      <div
                        className='bg-zen-light-gray-2 hover:bg-zen-dark-4 rounded-full min-w-[35px] min-h-[35px] w-[35px] h-[35px] flex items-center justify-center cursor-pointer'
                        onClick={() =>
                          handleDownloadDocument(original, dropboxFiles)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faArrowDownToLine}
                          className='text-lg text-zen-dark-9'
                          aria-label='download-all-documents'
                        />
                      </div>
                    </Hover>
                  </ResourceContainer>
                </div>
              )}
              <ExcludeAgent>
                {original.status !== ItemResponseStatusEnum.Accepted && (
                  <div>
                    <ResourceContainer
                      loading={isMarkingAsAccepted[original.id!]!}
                      isEmpty={false}
                      resourceName='download-item'
                      LoaderComponent={
                        <div className='py-2 pl-2.5 pr-0.5'>
                          <DefaultLoader noPadding iconSize='small' />
                        </div>
                      }
                    >
                      <Hover
                        hoverComponent={
                          <div className='text-zen-dark-12 font-zen-body text-base px-3 whitespace-pre'>
                            Mark as Accepted
                          </div>
                        }
                        config={{ trigger: 'hover', placement: 'top-start' }}
                      >
                        <div
                          className='bg-zen-light-gray-2 hover:bg-zen-dark-4 rounded-full min-w-[35px] min-h-[35px] w-[35px] h-[35px] flex items-center justify-center cursor-pointer'
                          onClick={() => handleMarkAsAccepted(original)}
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            className='text-lg text-green-600'
                            aria-label='mark-as-accepted'
                          />
                        </div>
                      </Hover>
                    </ResourceContainer>
                  </div>
                )}
              </ExcludeAgent>
              <ExcludeAgent>
                {original.status !==
                  ItemResponseStatusEnum.RevisionRequested && (
                  <div>
                    <ResourceContainer
                      loading={isMarkingAsRevisionRequested[original.id!]!}
                      isEmpty={false}
                      resourceName='download-item'
                      LoaderComponent={
                        <div className='py-2 pl-2.5 pr-0.5'>
                          <DefaultLoader noPadding iconSize='small' />
                        </div>
                      }
                    >
                      <Hover
                        hoverComponent={
                          <div className='text-zen-dark-12 font-zen-body text-base px-3 whitespace-pre'>
                            Revision Requested
                          </div>
                        }
                        config={{ trigger: 'hover', placement: 'top-start' }}
                      >
                        <div
                          className='bg-zen-light-gray-2 hover:bg-zen-dark-4 rounded-full min-w-[35px] min-h-[35px] w-[35px] h-[35px] flex items-center justify-center cursor-pointer'
                          onClick={() =>
                            handleMarkAsRevisionRequested(original)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faArrowRotateLeft}
                            className='text-lg text-zen-danger'
                            aria-label='mark-as-revision-requested'
                          />
                        </div>
                      </Hover>
                    </ResourceContainer>
                  </div>
                )}
              </ExcludeAgent>
            </div>
          );
        },
      },
    ];

    return checklistColumns;
  }, [
    isItemUrgent,
    handleIsUrgent,
    currentChecklistSortPriority,
    userDetail?.accountCountry,
    containerType,
    containerId,
    getMentions,
    callerGroupId,
    dropboxById,
    dropboxId,
    checklistId,
    isChecklistV2,
    isDownloadingDocument,
    isMarkingAsAccepted,
    isMarkingAsRevisionRequested,
    handleDownloadDocument,
    handleMarkAsAccepted,
    handleMarkAsRevisionRequested,
  ]);

  const labelOptions: ISelectOption[] = getUniqueLabelOptionForChecklist(
    filterChecklist,
  );

  const labelsToFilter = watch('labels');
  const nextChecklistItemPosition =
    Math.max(...filterChecklist?.map((item) => item.position || 0), 0) + 1;

  const hiddenColumns = ['labels', 'urgent', 'dueDate', 'position'];
  if (!(isAdmin || isBroker || hasMortgagePermission)) {
    hiddenColumns.push('required');
  }
  if (!automatedBrokerReviewEnabled) {
    hiddenColumns.push('fileReferences');
  }
  if (isBorrowerContainer) {
    hiddenColumns.push('requiredFor');
  }

  const tableInstanceV1 = useTable(
    {
      columns,
      data: filterChecklist || [],
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      initialState: {
        pageIndex: 0,
        pageSize: 1,
        sortBy: [{ id: 'status', desc: false }],
        hiddenColumns: hiddenColumns,
      },
      filterTypes: {
        ...FIXED_TABLE_FILTER_TYPES,
        multiSelectFilter: checklistMultiSelectFilter,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  const tableInstanceV2 = useTable(
    {
      columns,
      data: filterChecklist,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      initialState: {
        pageIndex: 0,
        pageSize: 1,
        sortBy: [{ id: 'status', desc: false }],
        hiddenColumns: hiddenColumns,
      },
      filterTypes: FIXED_TABLE_FILTER_TYPES,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    useZenAllowSelection,
  );

  const tableInstance =
    isChecklistV2 && isDropboxFilesTrashingEnabled
      ? tableInstanceV2
      : tableInstanceV1;

  const handleOnFilter = (data: ChecklistSearchFilterFormData) => {
    const filter: Filters<ItemResponse> = [];

    if (data.required) {
      filter.push({
        id: 'required',
        value: data.required.length > 0,
      });
    }

    if (data.urgent) {
      filter.push({
        id: 'urgent',
        value: data.urgent.length > 0,
      });
    }

    if (data.status) {
      filter.push({
        id: 'status',
        value: data.status.map((status) => status.value),
      });
    }
    if (data.requiredFor) {
      filter.push({
        id: 'requiredFor',
        value: data.requiredFor.map((requiredFor) => requiredFor.value),
      });
    }

    tableInstance.setAllFilters(filter);
  };

  const updateFilterForID = useCallback(
    (id: IdType<ItemResponse>, value: FilterValue) => {
      const filteredValue = tableInstance.state.filters.filter(
        (f) => f.id !== id,
      );

      if (!!value) {
        filteredValue.push({
          id,
          value: value,
        });
      }

      tableInstance.setAllFilters(filteredValue);
    },
    [tableInstance],
  );

  const editingChecklistItem = filterChecklist?.find(
    (item) => item.id === editingChecklistItemId,
  );

  useEffect(() => {
    if (
      currentChecklistSortPriority?.length === 0 ||
      tableInstance?.columns[5]?.isSortedDesc !== isStatusSortedDesc
    ) {
      let sortedChecklistDetails = filterChecklist?.map((item) => {
        const isOptionalItem =
          !item?.required && item?.status === ItemResponseStatusEnum.NotStarted;

        return {
          ...item,
          status: isOptionalItem
            ? ('NO_STATUS' as ItemResponseStatusEnum)
            : item?.status,
        };
      });

      sortedChecklistDetails.sort((a, b) =>
        getPriorityWiseSortedData(
          a?.status,
          b?.status,
          checklistStatusPriority,
        ),
      );
      let checklistSortPriority: Array<number> = sortedChecklistDetails?.map(
        (detail: ItemResponse) => detail?.position || 0,
      );

      setCurrentChecklistSortPriority(checklistSortPriority);
      setIsStatusSortedDesc(tableInstance?.columns[5]?.isSortedDesc || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistDetails, tableInstance?.columns[5]?.isSortedDesc]);

  useEffect(() => {
    updateFilterForID(
      'labels',
      labelsToFilter.map((label) => label.value),
    );
  }, [labelsToFilter, updateFilterForID]);

  useEffect(() => {
    tableInstance?.setPageSize(
      !!filterChecklist?.length ? filterChecklist?.length : 1,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterChecklist?.length]);

  useLatestCommentsForChecklistIds(checklistDetails);
  useChecklistItemCommentsCount(checklistDetails);

  const isDropboxLoading = Object.values(loadingById).some(
    (loading) => loading,
  );
  const isLoading =
    checklistAsyncResponse.loading || (isChecklistV2 && isDropboxLoading);

  if (isLoading) {
    return <DefaultLoader />;
  }

  return (
    <div className='md:px-4 -mx-4 md:mx-0 text-dark pb-48'>
      {title && (
        <div>
          <p className='text-xl mt-5'>{title}</p>
        </div>
      )}
      <div className='my-5 flex flex-col space-y-2 md:space-y-0 space-x-0 md:space-x-3 md:flex-row items-center'>
        <div className='flex flex-col items-center md:flex-grow space-y-3 md:flex-row md:space-y-0 md:space-x-5 w-full md:w-auto'>
          <ZenChecklistSearchFilterInput
            onFilter={handleOnFilter}
            onSearch={(search) => updateFilterForID('name', search)}
            searchTitles={filterChecklist?.map((f) => f.name!)}
          />
          <div className='w-[350px] z-10'>
            <ZenControlledMultiSelectInput<FormData, 'labels'>
              name='labels'
              control={control}
              placeholderLabel='Labels:'
              placeholder='Add label'
              options={labelOptions}
            />
          </div>
        </div>
        <div className='flex space-x-3 px-4 md:px-0'>
          {containerType === RezenObjectTypeEnum.Transaction && (
            <ExcludeAgent>
              <ZenButton
                label='Power Audit'
                type='button'
                variant='primary-outline'
                LeftIconComponent={
                  <FontAwesomeIcon
                    icon={regular('bolt')}
                    className='text-primary-blue -mb-px'
                    fontSize={14}
                  />
                }
                onClick={() => {
                  history.push(`/transactions/${containerId}/power-audit`);
                }}
              />
            </ExcludeAgent>
          )}
          <ZenButton
            label='Split & Attach PDF'
            type='button'
            variant='primary-outline'
            LeftIconComponent={
              <FontAwesomeIcon
                icon={faScissors}
                className='text-sm text-primary-blue'
              />
            }
            onClick={() => {
              history.push(
                `/${containerType}/${containerId}/checklist/${checklistId}/dropbox/${dropboxId}/split-and-attach-pdf?backUrl=${location.pathname}`,
              );
            }}
          />
          <ZenButton
            label={isBorrowerContainer ? 'New Task' : 'New Item'}
            type='button'
            variant='primary-outline'
            LeftIconComponent={
              isBorrowerContainer ? (
                <FontAwesomeIcon icon={faPlus} />
              ) : (
                <FontAwesomeIcon icon={faPlus} className='text-primary-blue' />
              )
            }
            onClick={() => setIsAddingChecklistItem(true)}
          />
        </div>
      </div>

      {isChecklistV2 &&
        isDropboxFilesTrashingEnabled &&
        !!tableInstance.selectedFlatRows.length && (
          <div className='my-2'>
            <TableRowActionBar
              items={tableInstance.selectedFlatRows}
              handleCancel={() => tableInstance.toggleAllRowsSelected(false)}
              actionBarButtons={[
                {
                  hoverText: 'Download All Docs',
                  icon: (
                    <FontAwesomeIcon
                      icon={faArrowDownToLine}
                      className='text-lg text-zen-dark-9'
                      aria-label='download-all-selected-documents'
                    />
                  ),
                  onClick: async (checklistItems: ItemResponse[]) => {
                    setIsActionBarLoading((prevState) => {
                      return { ...prevState, download: true };
                    });
                    await handleActionBarDownloadDocuments(checklistItems);
                    setIsActionBarLoading((prevState) => {
                      return { ...prevState, download: false };
                    });
                  },
                  isLoading: isActionBarLoading?.download,
                },
              ]}
            />
          </div>
        )}

      <AuthorizationContainer asyncResponse={checklistAsyncResponse}>
        <ZenResourceTable<ItemResponse>
          {...tableInstance}
          resourceName='Checklist'
          emptyIconComponent={
            <img src={EmptyTray} alt='EmptyTrayImage' className='h-20' />
          }
          hidePagination
          totalCount={filterChecklist?.length || 0}
          checklistId={checklistId}
          dropboxId={dropboxId}
          isDropzone
        />
      </AuthorizationContainer>

      <ActivateCommissionDocumentConfirmationModal
        isOpen={!!activateCdaId}
        onClose={() => setActivateCdaId(undefined)}
        commissionDocumentId={activateCdaId}
      />

      {((isAddingChecklistItem && !isBorrowerContainer) ||
        !!editingChecklistItemId) && (
        <ZenAddCheckListItemForm
          isOpen
          onClose={() => {
            setIsAddingChecklistItem(false);
            setEditingChecklistItemId(null);
            if (queryParams?.has('checklistItemId')) {
              queryParams?.delete('checklistItemId');
              history?.replace({
                search: queryParams?.toString(),
              });
            }
          }}
          labelOptions={labelOptions}
          checklistId={checklistId}
          checklistItem={editingChecklistItem}
          nextChecklistItemPosition={nextChecklistItemPosition}
          dropboxId={dropboxId}
          secondaryDropboxId={secondaryDropboxId}
          containerType={containerType}
          containerId={containerId}
          assigneeList={assigneeList}
          callerGroupId={callerGroupId}
          getMentions={getMentions}
        />
      )}

      {isAddingChecklistItem && isBorrowerContainer && (
        <MortgageAddChecklistTask
          isOpen
          onClose={() => {
            setIsAddingChecklistItem(false);
            setEditingChecklistItemId(null);
            if (queryParams?.has('checklistItemId')) {
              queryParams?.delete('checklistItemId');
              history?.replace({
                search: queryParams?.toString(),
              });
            }
          }}
          assigneeList={assigneeList}
        />
      )}

      {!!attachingToItem && !!dropboxId && (
        <ZenManageFileCabinetSidebarModal
          isOpen
          onClose={() => setAttachingToItem(undefined)}
          dropboxId={dropboxId}
          secondaryDropboxId={secondaryDropboxId}
          attach={handleOnAttach}
          isMultiple
        />
      )}
      <LeoErrorDetectionModal />
    </div>
  );
};

export default ZenChecklistContainer;
