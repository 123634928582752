import { createSelector } from '@reduxjs/toolkit';
import {
  EarningsDetails,
  MoneyValue,
  MoneyValueCurrencyEnum,
  ProspectiveAgentInfoCountryEnum,
} from '../../openapi/plutus';
import { AgentResponseAccountCountryEnum } from '../../openapi/yenta';
import { AnalyticsEventEnum, RootState } from '../../types';
import WealthPlanPopover from '../Zen/WealthPlanPopover';
import { Earning, EarningType } from './WealthManagementTypes';
import { selectIsWealthPlanReferral } from './wealthPlanSelectors';

export const SHARRANS_PLAYBOOK_URL =
  'https://www.real.academy/course/2024-business-plan-workshop';
export const REAL_ACADEMY_URL = '/learnworlds/sso';
export const SALES_ACCELERATOR_URL =
  'https://www.real.academy/course/sales-accelerator';
export const ATTRACTION_ACCELERATOR_URL =
  'https://www.real.academy/course/attraction-accelerator';

export const acceleratorUrlToEarningTypeMap: Record<EarningType, string> = {
  [Earning.COMMISSION_INCOME]: SALES_ACCELERATOR_URL,
  [Earning.PRE_CAP_SPP]: SALES_ACCELERATOR_URL,
  [Earning.STOCK_AWARD_FOR_CAPPING]: SALES_ACCELERATOR_URL,
  [Earning.POST_CAP_SPP]: SALES_ACCELERATOR_URL,
  [Earning.ELITE_CULTURAL_AWARD]: SALES_ACCELERATOR_URL,
  [Earning.ELITE_PRODUCTION_AWARD]: SALES_ACCELERATOR_URL,
  [Earning.REVENUE_SHARE_INCOME]: ATTRACTION_ACCELERATOR_URL,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]: ATTRACTION_ACCELERATOR_URL,
};

export const acceleratorCardTitleToEarningTypeMap: Record<
  EarningType,
  string
> = {
  [Earning.COMMISSION_INCOME]: 'Go to Sales Accelerator',
  [Earning.PRE_CAP_SPP]: 'Go to Sales Accelerator',
  [Earning.STOCK_AWARD_FOR_CAPPING]: 'Go to Sales Accelerator',
  [Earning.POST_CAP_SPP]: 'Go to Sales Accelerator',
  [Earning.ELITE_CULTURAL_AWARD]: 'Go to Sales Accelerator',
  [Earning.ELITE_PRODUCTION_AWARD]: 'Go to Sales Accelerator',
  [Earning.REVENUE_SHARE_INCOME]: 'Go to Attraction Accelerator',
  [Earning.STOCK_AWARD_FOR_ATTRACTING]: 'Go to Attraction Accelerator',
};

export const ABOUT_REAL_URL = 'https://www.onereal.com/pages/join-real';

type EarningDetailKeys = keyof EarningsDetails;

const earningTypeToEarningDetailKeyMap: Record<
  EarningType,
  EarningDetailKeys
> = {
  [Earning.COMMISSION_INCOME]: 'individualCommission',
  [Earning.PRE_CAP_SPP]: 'combinedPreCapShareValue',
  [Earning.STOCK_AWARD_FOR_CAPPING]: 'cappingAwardValue',
  [Earning.POST_CAP_SPP]: 'combinedPostCapShareValue',
  [Earning.ELITE_CULTURAL_AWARD]: 'culturalAwardValue',
  [Earning.ELITE_PRODUCTION_AWARD]: 'eliteAgentProductionAwardValue',
  [Earning.REVENUE_SHARE_INCOME]: 'revShareEarnings',
  [Earning.STOCK_AWARD_FOR_ATTRACTING]: 'attractingAwardValue',
};

const isMoneyValue = (value: any): value is MoneyValue => {
  return 'amount' in (value ?? {}) && 'currency' in (value ?? {});
};

const selectedToNumber = (
  selected: number | MoneyValue | MoneyValue[] | undefined,
): number => {
  if (Array.isArray(selected)) {
    return selected.reduce((acc, curr) => {
      return acc + (isMoneyValue(curr) ? curr?.amount ?? 0 : curr ?? 0);
    }, 0);
  }
  if (isMoneyValue(selected)) {
    return selected?.amount ?? 0;
  }
  return selected ?? 0;
};

export const selectTerminalValue = createSelector(
  [
    (state: RootState) => state.wealthManagement.earningsOverviewResponse.data,
    (_: RootState, earningType: EarningType) => earningType,
  ],
  (data, earningsType) => {
    const terminalYearProjection =
      data?.netEarningsPlusProjections[
        earningTypeToEarningDetailKeyMap[earningsType]
      ];

    return selectedToNumber(terminalYearProjection);
  },
);

export const selectCurrentProgressPercent = createSelector(
  [
    (state: RootState) => state.wealthManagement.earningsOverviewResponse.data,
    selectTerminalValue,
    (_: RootState, earningType: EarningType) => earningType,
  ],
  (state, terminalValue, earningsType) => {
    let earningsToDate =
      state?.earningsToDate[earningTypeToEarningDetailKeyMap[earningsType]];

    let numerator = selectedToNumber(earningsToDate);

    return numerator / (terminalValue === 0 ? 1 : terminalValue);
  },
);

export const selectProjectedEndOfCycleEarningPercent = createSelector(
  [
    (state: RootState) => state.wealthManagement.earningsOverviewResponse.data,
    selectTerminalValue,
    (_: RootState, earningType: EarningType) => earningType,
  ],
  (state, terminalValue, earningsType) => {
    const earningsToDate =
      state?.earningsToDate[earningTypeToEarningDetailKeyMap[earningsType]];
    let projectedOneYearEarning =
      state?.oneYearProjections[earningTypeToEarningDetailKeyMap[earningsType]];
    let numerator =
      selectedToNumber(projectedOneYearEarning) +
      selectedToNumber(earningsToDate);

    return numerator / (terminalValue === 0 ? 1 : terminalValue);
  },
);

export const selectAgentCurrency = createSelector(
  (state: RootState) => state.auth.userDetail,
  (state: RootState) => state.wealthManagement,
  selectIsWealthPlanReferral,
  (userDetail, wealthManagement, isWealthPlanReferral) => {
    if (isWealthPlanReferral) {
      return wealthManagement.referralInfo.data?.country ===
        ProspectiveAgentInfoCountryEnum.UnitedStates
        ? MoneyValueCurrencyEnum.Usd
        : MoneyValueCurrencyEnum.Cad;
    }
    if (
      userDetail?.accountCountry ===
      AgentResponseAccountCountryEnum.UnitedStates
    ) {
      return MoneyValueCurrencyEnum.Usd;
    }
    return MoneyValueCurrencyEnum.Cad;
  },
);

export const dateDifference = (first: Date, second: Date): number => {
  return Math.round(
    (second.getTime() - first.getTime()) / (1000 * 60 * 60 * 24),
  );
};

interface WealthPlanTooltipProps {
  analyticsEvent?: AnalyticsEventEnum;
  title?: string;
  arrow?: boolean;
}

export const WealthPlanTooltip: React.FC<
  React.PropsWithChildren<WealthPlanTooltipProps>
> = ({ analyticsEvent, children, title }) => {
  return (
    <WealthPlanPopover title={title as string} analyticsEvent={analyticsEvent}>
      {children}
    </WealthPlanPopover>
  );
};
