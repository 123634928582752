import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDownToSquare,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import RealLogo from '../../assets/img/new-rezen-black-logo.svg';
import { useLogout } from '../../hooks/useLogout';
import ZenConfirmationModal from './Modal/ZenConfirmationModal';
import ZenButton, { ZenButtonVariant } from './ZenButton';

export interface ZenAgentOnboardingLayoutProps {
  variant?: ZenButtonVariant;
  title: string;
  hideLogout?: boolean;
  hideClose?: boolean;
  onClose?(): void;
  modalTitle?: string;
  modalSubtitle?: string;
  modalSubmitText?: string;
  modalCancelText?: string;
  hideCancelButton?: boolean;
  RightHeaderComponent?: React.ReactNode;
  isLast?: boolean;
}

const ZenAgentOnboardingLayout: React.FC<ZenAgentOnboardingLayoutProps> = ({
  variant = 'danger',
  title,
  hideLogout = false,
  hideClose = false,
  hideCancelButton = false,
  onClose,
  children,
  modalTitle = 'Close and discard all edits?',
  modalSubtitle = "Changes won't be saved and you'll go back to the start.",
  modalSubmitText = 'Yes',
  modalCancelText = 'No',
  RightHeaderComponent,
  isLast,
}) => {
  const logout = useLogout();
  const [cancel, setCancel] = useState<boolean>(false);
  const history = useHistory();

  return (
    <div className='fixed top-0 right-0 bottom-0 left-0 w-full h-full bg-white scrollbar overflow-auto z-10'>
      <div className='flex flex-col w-full h-full'>
        <header className='p-4 border-b border-coolGray-300 flex items-center justify-between'>
          <div className='flex items-center'>
            <img src={RealLogo} className='h-7' alt='Logo' />
            <div className='h-7 border-l border-coolGray-300 mx-6' />
            <h1
              className={
                isLast
                  ? 'font-inter text-[22px] text-primary-dark'
                  : 'font-zen-body text-xl text-zen-dark-9'
              }
            >
              {title}
            </h1>
          </div>

          {RightHeaderComponent}

          {!hideClose && (
            <ZenButton
              label='Close'
              variant='secondary-gray-outline'
              LeftIconComponent={
                <FontAwesomeIcon icon={faXmark} className='mx-1' size='lg' />
              }
              type='button'
              onClick={() => setCancel(true)}
            />
          )}
        </header>

        <div className='flex-grow flex flex-col'>
          <div className='h-full'>{children}</div>
        </div>

        {!hideLogout && (
          <div className='p-4 lg:p-6'>
            <ZenButton
              label='Logout'
              variant='secondary-gray-outline'
              LeftIconComponent={
                <FontAwesomeIcon
                  icon={faArrowDownToSquare}
                  className='-rotate-90 mr-1 text-[18px]'
                />
              }
              type='button'
              onClick={async () => {
                await logout();
                history.push('/');
              }}
            />
          </div>
        )}
      </div>
      <ZenConfirmationModal
        variant={variant}
        title={modalTitle}
        subtitle={modalSubtitle}
        isOpen={cancel}
        onClose={() => setCancel(false)}
        confirmButtonText={modalSubmitText}
        cancelButtonText={modalCancelText}
        onConfirm={onClose}
        hideCancelButton={hideCancelButton}
        hideIcon
      />
    </div>
  );
};

export default ZenAgentOnboardingLayout;
