import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookFormTextInput } from '../commons/hookFormInputs/HookFormTextInput';
import GeminiConfirmationModal from './GeminiConfirmationModal';

interface RevokeInviteConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  revokeInvite: () => void;
  tcName: string;
}

const confirmationSchema = z.object({
  confirmation: z.string().refine((val) => val === 'CONFIRM', {
    message: 'Please use Capital letters',
  }),
});

const RevokeInviteConfirmationModal: React.FC<RevokeInviteConfirmationModalProps> = ({
  isOpen,
  onClose,
  revokeInvite,
  tcName,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const { control, watch, handleSubmit, setError, clearErrors } = useForm();
  const confirmText = watch('confirmation');

  const handleConfirm = () => {
    revokeInvite();
    onClose();
  };

  const validateConfirmation = debounce((value: string) => {
    try {
      confirmationSchema.parse({ confirmation: value });
      clearErrors('confirmation');
      setIsDisabled(false);
    } catch (e) {
      setError('confirmation', {
        type: 'manual',
        message: 'Missing the word CONFIRM/not using Capital letters.',
      });
      setIsDisabled(true);
    }
  }, 300);

  useEffect(() => {
    if (confirmText !== undefined) {
      validateConfirmation(confirmText);
    }
  }, [confirmText, validateConfirmation]);

  return (
    <GeminiConfirmationModal
      title='Are you sure you want to revoke the Invite?'
      isOpen={isOpen}
      hideIcon
      size='fixed'
      onConfirm={revokeInvite}
      onClose={onClose}
      confirmButtonText='Revoke'
      cancelButtonText='Cancel'
      confirmButtonVariant='revoke'
      isDisabled={isDisabled}
    >
      <div className='flex flex-col gap-4 items-center justify-center font-inter pt-6 pb-2 text-center px-6'>
        <div className='flex flex-col items-center justify-center gap-4'>
          <p className='font-light text-base text-primary-dark'>
            This will revoke your invitation to&nbsp;
            <span className='font-medium'>{tcName}.</span>
            <br />
            They will not be notified of this update. Please communicate this to
            them directly.
          </p>
        </div>
        <div className='flex flex-col items-center gap-4 justify-center w-2/3'>
          <form
            onSubmit={handleSubmit(handleConfirm)}
            className='flex flex-col gap-2'
          >
            <span className='text-primary-dark font-light text-sm'>
              Please type “CONFIRM” to remove
            </span>
            <HookFormTextInput
              control={control}
              name='confirmation'
              placeholder='CONFIRM'
            />
          </form>
          <p className='text-grey-500 text-sm font-light'>
            We will email Real Support on your behalf notifying them of this
            change.
          </p>
        </div>
      </div>
    </GeminiConfirmationModal>
  );
};

export default RevokeInviteConfirmationModal;
