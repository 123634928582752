import { find, findLast, isEqual, isNil, omit, values } from 'lodash';
import { DateTime } from 'luxon';
import { AddressStateEnum } from '../openapi/arrakis';
import {
  Address,
  AddressCountryEnum,
  AddressResponse,
  AddressResponseStateOrProvinceEnum,
  AgentResponse,
  CreatePaymentMethodPurposeEnum,
  CreatePaymentMethodTypeEnum,
  CreateTaxFormW8BENCountryOfIncorporationEnum,
  CreateTaxFormW8ECICountryOfIncorporationEnum,
  CreateTaxFormW8ECITaxEntityTypeEnum,
  CreateTaxFormW9TaxClassificationEnum,
  MsdxVendorDto,
  NationalIdentification,
  NationalIdentificationTypeEnum,
  PayableInfoResponse,
  PaymentMethodResponsePurposeEnum,
  PaymentMethodsResponse,
  TaxFormCanadaResponse,
  TaxFormResponseTypeEnum,
  TaxFormsResponse,
  TaxFormW8BENResponse,
  TaxFormW8ECIResponse,
  TaxFormW9Response,
  UpdatePaymentMethodPurposeEnum,
} from '../openapi/yenta';
import { MSDynamicOnboardingFormData } from '../routes/MSDynamicsOnboardingRoute';
import { AgentAddressTypeEnum, YesNoType } from '../types';
import { getISelectOptionDefaultValue } from './FormUtils';
import { capitalizeEnum } from './StringUtils';

export const getMSDynamicsDefaultFormValues = (
  authUserDetail: AgentResponse | null,
  paymentMethodsResponse?: PaymentMethodsResponse | undefined,
  taxForm?: TaxFormsResponse | undefined,
): MSDynamicOnboardingFormData => {
  const address = findLast(
    authUserDetail?.addresses,
    (el) => el.type !== AgentAddressTypeEnum.MAILING,
  );
  const mailingAddress = findLast(
    authUserDetail?.addresses,
    (el) => el.type === AgentAddressTypeEnum.MAILING,
  );
  const taxForms = taxForm?.taxForms || [];
  const w9: TaxFormW9Response | undefined = findLast(
    taxForms,
    (item) => item.type === TaxFormResponseTypeEnum.W9,
  );
  const w8ECI: TaxFormW8ECIResponse | undefined = findLast(
    taxForms,
    (item) => item.type === TaxFormResponseTypeEnum.W8Eci,
  ) as TaxFormW8ECIResponse | undefined;
  const w8BEN: TaxFormW8BENResponse | undefined = findLast(
    taxForms,
    (item) => item.type === TaxFormResponseTypeEnum.W8Ben,
  ) as TaxFormW8BENResponse | undefined;
  const canadaTax: TaxFormCanadaResponse | undefined = findLast(
    taxForms,
    (item) => item.type === TaxFormResponseTypeEnum.Canada,
  );
  const canadaTaxPayerName = canadaTax?.taxpayerName?.split(' ');

  const bankInfoPlaceholder = [
    {
      bankId: '',
      type: ('' as unknown) as CreatePaymentMethodTypeEnum,
      nickname: '',
      routingNumber: '',
      accountNumber: '',
      confirmAccountNumber: '',
      purpose: undefined,
      bankName: '',
    },
  ];

  return {
    bankInfo: paymentMethodsResponse?.paymentMethods?.length
      ? paymentMethodsResponse.paymentMethods.map((bank) => ({
          bankId: bank?.id,
          type: (bank?.type! as unknown) as CreatePaymentMethodTypeEnum,
          nickname: bank?.nickname!,
          usRoutingNumber: bank?.usRoutingNumber!,
          usConfirmRoutingNumber: bank?.usRoutingNumber!,
          canadaRoutingNumber: bank?.canadaRoutingNumber!,
          accountNumber: bank?.accountNumber!,
          confirmAccountNumber: bank?.accountNumber!,
          purpose: getISelectOptionDefaultValue(
            (bank?.purpose as unknown) as CreatePaymentMethodPurposeEnum,
            getBankPurposeLabel(bank?.purpose!),
          )!,
          bankName: bank?.bankName!,
        }))
      : bankInfoPlaceholder,
    firstName: authUserDetail?.firstName!,
    middleName: authUserDetail?.middleName!,
    lastName: authUserDetail?.lastName!,
    emailAddress: authUserDetail?.personalEmailAddress!,
    phoneNumber: authUserDetail?.phoneNumber!,
    country: (address?.country as unknown) as AddressCountryEnum,
    city: address?.city!,
    stateOrProvince: (address?.stateOrProvince as unknown) as AddressStateEnum,
    streetAddress1: address?.streetAddress1!,
    streetAddress2: address?.streetAddress2!,
    zipOrPostalCode: address?.zipOrPostalCode!,
    mailCity: mailingAddress?.city!,
    mailStateOrProvince:
      ((mailingAddress?.stateOrProvince as unknown) as AddressStateEnum) ?? '',
    mailStreetAddress1: mailingAddress?.streetAddress1!,
    mailStreetAddress2: mailingAddress?.streetAddress2!,
    mailZipOrPostalCode: mailingAddress?.zipOrPostalCode!,
    mailCountry: ((mailingAddress?.country ??
      address?.country) as unknown) as AddressCountryEnum,
    mailAddressSameAsContact:
      isNil(mailingAddress) ||
      isEqual(
        getAddressWithoutType(address),
        getAddressWithoutType(mailingAddress),
      )
        ? [YesNoType.YES]
        : undefined,
    w9: {
      id: w9?.id,
      taxPayer: w9?.name!,
      disregardedEntityName: w9?.disregardedEntityName,
      usTaxIdNumber: findSsnOrEinId(w9?.nationalIds)?.id!,
      usTaxIdType: findSsnOrEinId(w9?.nationalIds)?.type!,
      taxClassification: getISelectOptionDefaultValue(
        (w9?.taxClassification as unknown) as CreateTaxFormW9TaxClassificationEnum,
      )!,
      addressLine: w9?.residenceAddress?.streetAddress1!,
      city: w9?.residenceAddress?.city!,
      state:
        ((w9?.residenceAddress
          ?.stateOrProvince as unknown) as AddressResponseStateOrProvinceEnum) ??
        '',
      zipCode: w9?.residenceAddress?.zipOrPostalCode!,
      signature: w9?.typedSignature!,
      signDate: getDate(w9?.signedAt),
      electronicDelivery:
        isNil(w9?.electronicDelivery) || w9?.electronicDelivery
          ? [YesNoType.YES]
          : undefined,
    },
    w8ECI: {
      id: w8ECI?.id,
      taxPayer: w8ECI?.name!,
      countryOfIncorporation: getISelectOptionDefaultValue(
        (w8ECI?.countryOfIncorporation as unknown) as CreateTaxFormW8ECICountryOfIncorporationEnum,
      )!,
      typeOfEntity: getISelectOptionDefaultValue(
        (w8ECI?.taxEntityType as unknown) as CreateTaxFormW8ECITaxEntityTypeEnum,
      )!,
      disregardedEntityName: w8ECI?.disregardedEntityName,
      usTaxIdNumber: findSsnOrEinId(w8ECI?.nationalIds)?.id!,
      usTaxIdType: findSsnOrEinId(w8ECI?.nationalIds)?.type!,
      foreignTaxId: w8ECI?.foreignTaxId!,
      addressLine: w8ECI?.residenceAddress?.streetAddress1!,
      city: w8ECI?.residenceAddress?.city!,
      state:
        ((w8ECI?.residenceAddress
          ?.stateOrProvince! as unknown) as AddressStateEnum) ?? '',
      country:
        ((w8ECI?.residenceAddress?.country as unknown) as AddressCountryEnum) ??
        AddressCountryEnum.UnitedStates,
      zipCode: w8ECI?.residenceAddress?.zipOrPostalCode!,
      businessAddressSameAsPermanent:
        w8ECI?.residenceAddress &&
        isEqual(
          getAddressWithoutType(w8ECI?.residenceAddress),
          getAddressWithoutType(w8ECI?.businessAddress),
        )
          ? [YesNoType.YES]
          : undefined,
      businessAddressLine: w8ECI?.businessAddress?.streetAddress1!,
      businessCity: w8ECI?.businessAddress?.city!,
      businessState:
        ((w8ECI?.businessAddress
          ?.stateOrProvince as unknown) as AddressStateEnum) ?? '',
      businessCountry:
        ((w8ECI?.businessAddress?.country as unknown) as AddressCountryEnum) ??
        AddressCountryEnum.UnitedStates,
      businessZipCode: w8ECI?.businessAddress?.zipOrPostalCode!,
      specifyIncome: w8ECI?.specifyIncome,
      signature: w8ECI?.typedSignature!,
      signDate: getDate(w8ECI?.signedAt),
    },
    w8BEN: {
      id: w8BEN?.id,
      taxPayer: w8BEN?.name!,
      countryOfCitizenship: getISelectOptionDefaultValue(
        (w8BEN?.countryOfIncorporation as unknown) as CreateTaxFormW8BENCountryOfIncorporationEnum,
      )!,
      usTaxIdNumber: findNationalId(
        w8BEN?.nationalIds,
        NationalIdentificationTypeEnum.Ssn,
      )?.id!,
      usTaxIdType: findNationalId(
        w8BEN?.nationalIds,
        NationalIdentificationTypeEnum.Ssn,
      )?.type!,
      foreignTaxId: w8BEN?.foreignTaxId!,
      addressLine: w8BEN?.residenceAddress?.streetAddress1!,
      city: w8BEN?.residenceAddress?.city!,
      state:
        ((w8BEN?.residenceAddress
          ?.stateOrProvince as unknown) as AddressStateEnum) ?? '',
      country:
        ((w8BEN?.residenceAddress?.country as unknown) as AddressCountryEnum) ??
        AddressCountryEnum.UnitedStates,
      zipCode: w8BEN?.residenceAddress?.zipOrPostalCode!,
      mailingAddressSameAsPermanent:
        w8BEN?.residenceAddress &&
        isEqual(
          getAddressWithoutType(w8BEN.residenceAddress),
          getAddressWithoutType(w8BEN.mailingAddress),
        )
          ? [YesNoType.YES]
          : undefined,
      mailingAddressLine: w8BEN?.mailingAddress?.streetAddress1!,
      mailingCity: w8BEN?.mailingAddress?.city!,
      mailingState:
        ((w8BEN?.mailingAddress
          ?.stateOrProvince as unknown) as AddressStateEnum) ?? '',
      mailingCountry:
        ((w8BEN?.mailingAddress?.country as unknown) as AddressCountryEnum) ??
        AddressCountryEnum.UnitedStates,
      mailingZipCode: w8BEN?.mailingAddress?.zipOrPostalCode!,
      signature: w8BEN?.typedSignature!,
      signDate: getDate(w8BEN?.signedAt),
    },
    canadaTaxes: {
      id: canadaTax?.id,
      taxPayerFirstname: canadaTaxPayerName?.[0]!,
      taxPayerSurname: canadaTaxPayerName?.slice(1)?.join(' ')!,
      taxpayerBusinessName: canadaTax?.businessName!,
      taxpayerBusinessNumber: findNationalId(
        canadaTax?.nationalIds,
        NationalIdentificationTypeEnum.Bn,
      )?.id!,
      gstId: findNationalId(
        canadaTax?.nationalIds,
        NationalIdentificationTypeEnum.GstId,
      )?.id!,
      hstId: findNationalId(
        canadaTax?.nationalIds,
        NationalIdentificationTypeEnum.HstId,
      )?.id!,
      sinId: findNationalId(
        canadaTax?.nationalIds,
        NationalIdentificationTypeEnum.Sin,
      )?.id!,
      addressLine1: canadaTax?.residenceAddress?.streetAddress1!,
      addressLine2: canadaTax?.residenceAddress?.streetAddress2!,
      city: canadaTax?.residenceAddress?.city!,
      provinceOrRegion: (canadaTax?.residenceAddress
        ?.stateOrProvince as unknown) as AddressStateEnum,
      zipCode: canadaTax?.residenceAddress?.zipOrPostalCode!,
      country:
        canadaTax?.residenceAddress?.country! ?? AddressCountryEnum.Canada,
      signature: canadaTax?.typedSignature!,
      signDate: getDate(canadaTax?.signedAt),
      incorporated: canadaTax?.businessName !== canadaTax?.taxpayerName,
    },
    accountCountry: authUserDetail?.accountCountry!,
  };
};

export const getBankPurposeLabel = (
  purpose:
    | CreatePaymentMethodPurposeEnum
    | UpdatePaymentMethodPurposeEnum
    | PaymentMethodResponsePurposeEnum,
): string => {
  if (!purpose) {
    return purpose;
  }
  if (purpose === CreatePaymentMethodPurposeEnum.Transaction) {
    return 'Commission Payments Only';
  }
  if (purpose === CreatePaymentMethodPurposeEnum.Revshare) {
    return 'Revenue Share Payments Only';
  }
  if (purpose === CreatePaymentMethodPurposeEnum.Any) {
    return 'All Payments';
  }
  return capitalizeEnum(purpose);
};

export function findNationalId(
  ids: NationalIdentification[] = [],
  type: NationalIdentificationTypeEnum,
): NationalIdentification | undefined {
  return find(ids, (id) => id.type === type);
}

export function findSsnOrEinId(
  ids: NationalIdentification[] = [],
): NationalIdentification | undefined {
  return (
    findNationalId(ids, NationalIdentificationTypeEnum.Ssn) ||
    findNationalId(ids, NationalIdentificationTypeEnum.Ein)
  );
}

export function getAddressWithoutType(item?: Address | AddressResponse) {
  return item ? omit(item, ['type']) : undefined;
}

export function getDate(date?: number): string {
  if (!date) {
    return DateTime.now().toISODate();
  }
  return DateTime.fromMillis(date).toISODate();
}

export function getBankInfoInvalidLengthErrMessage(
  bankInfos: MSDynamicOnboardingFormData['bankInfo'],
): string | undefined {
  const hasAllPaymentAccount = bankInfos.find(
    (b) => b.purpose?.value === CreatePaymentMethodPurposeEnum.Any,
  );
  if (!hasAllPaymentAccount && bankInfos.length < 2) {
    const currentAccountTypes = bankInfos.map((b) => b.purpose?.value);
    const accountTypes = values(CreatePaymentMethodPurposeEnum);
    const remainingAccountsTypes = accountTypes.filter(
      (acc) =>
        acc !== CreatePaymentMethodPurposeEnum.Any &&
        !currentAccountTypes.includes(acc),
    );
    const errTypesMessage = remainingAccountsTypes
      .map((el) => getBankPurposeLabel(el))
      .join(', ');
    return `Need to add bank account for ${errTypesMessage}`;
  }
  return undefined;
}

export enum MSDynamicStatusEnum {
  NOT_STARTED = 'Not Started',
  CONNECTED = 'Connected',
  INVALID = 'Invalid',
}

export const isMsdxVendorPresent = (
  msdxVendors: MsdxVendorDto[] | undefined,
) => {
  return !!msdxVendors?.length && msdxVendors?.every((vendor) => !!vendor?.no);
};

export const getMSDynamicsStatus = (payableInfo?: PayableInfoResponse) => {
  if (!isMsdxVendorPresent(payableInfo?.msdxVendors)) {
    return MSDynamicStatusEnum.NOT_STARTED;
  }

  if (payableInfo?.payable) {
    return MSDynamicStatusEnum.CONNECTED;
  }

  return MSDynamicStatusEnum.INVALID;
};
