import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faCircleDot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import { useDisclosure } from '@mantine/hooks';
import { ReactComponent as HandShakeIcon } from '../../../assets/img/zen/transactionHeader/handshake_light.svg';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import {
  ParticipantValue,
  ParticipantValueRoleEnum,
  PaymentParticipantValue,
  TransactionResponse,
  UpdateParticipantRequest,
  UpdateParticipantRequestParticipantRoleEnum,
} from '../../../openapi/arrakis';
import { AgentInfoStatusEnum } from '../../../openapi/yenta';
import AnalyticsService from '../../../services/AnalyticsService';
import { showSuccessToast } from '../../../slices/ToastNotificationSlice';
import {
  toggleTaxWithheld,
  updateInstantPaymentEligibility,
  updateTransactionParticipant,
} from '../../../slices/TransactionSlice';
import {
  AnalyticsEventEnum,
  FeatureFlagTypeEnum,
  RootState,
} from '../../../types';
import {
  getParticipantName,
  isAgentParticipant,
} from '../../../utils/ParticipantHelper';
import { formatPhoneNumber } from '../../../utils/StringUtils';
import {
  isCanadaTransaction,
  participantRoleDisplayName,
} from '../../../utils/TransactionHelper';
import { getPrefixTextForParticipantUserPill } from '../../../utils/TransactionUtils';
import AdminOnly from '../../auth/AdminOnly';
import BrokerOnly from '../../auth/BrokerOnly';
import FeatureFlagEnabledOnly from '../../FeatureFlagEnabledOnly';
import GeminiPill from '../../Gemini/GeminiPill';
import Hover from '../../Hover';
import IconText from '../../IconText';
import ResourceContainer from '../../ResourceContainer';
import CallModal from '../../Roar/CallModal';
import ZenSimpleConfirmationModal from '../Modal/ZenSimpleConfirmationModal';
import ZenCopyToClipboard from '../ZenCopyToClipBoard';
import ZenUserPill from '../ZenUserPill';
import ZenIconToggleRow from './ZenIconToggleRow';

export interface TogglableFieldConfig {
  value: boolean;
  onToggle: (value: boolean) => void;
  label?: string;
  tooltip?: string;
  icon?: React.ReactElement;
}

export interface ZenParticipantCardProps {
  transaction?: TransactionResponse;
  participant: PaymentParticipantValue | ParticipantValue;
  isAdmin: boolean;
  onEdit(): void;
  onDelete(): void;
  isReadonly?: boolean;
  transactionOwner?: boolean;
  hideView?: boolean;
  isPaymentDetailsHidden?: boolean;
}

const ZenParticipantCard: React.FC<ZenParticipantCardProps> = ({
  transaction,
  participant,
  children,
  onEdit,
  onDelete,
  isReadonly,
  //   transactionOwner,
  isAdmin,
  hideView,
  isPaymentDetailsHidden,
}) => {
  const dispatch = useDispatch();

  const [isFlagsShown, setIsFlagsShown] = useState(false);
  const [opened, callModalActions] = useDisclosure(false);
  const toggleParticipant = async (
    flag: keyof UpdateParticipantRequest,
    value: boolean,
  ) => {
    await dispatch(
      updateTransactionParticipant(participant.id!, transaction?.id!, {
        paidByReal: !!participant.paidByReal,
        passThrough: !!participant.passThrough,
        participantRole: (participant.role as unknown) as UpdateParticipantRequestParticipantRoleEnum,
        paymentInstructions: participant.paymentInstructions,
        address: participant.address,
        payer: !!participant.commissionDocumentPayer,
        commissionDocumentRecipient: !!participant.commissionDocumentRecipient,
        firstName: participant.firstName,
        lastName: participant.lastName,
        phoneNumber: participant.phoneNumber,
        emailAddress: participant.emailAddress,
        paidViaBusinessEntity: participant.paidViaBusinessEntity,
        notes: participant.notes,
        personalDeal:
          'personalDeal' in participant ? !!participant.personalDeal : false,
        [flag]: value,
      }),
    );
    dispatch(showSuccessToast('Participant Updated!'));
  };

  const updateInstantCommissionToggle = async (value: boolean) => {
    await dispatch(
      updateInstantPaymentEligibility(transaction?.id!, participant?.id!, {
        instantPaymentEligible: value,
      }),
    );
  };

  const DEFAULT_ADMIN_TOGGLES: TogglableFieldConfig[] = [
    {
      value: !!participant['paidByReal'],
      onToggle: (toggle: boolean) => toggleParticipant('paidByReal', toggle),
      label: 'Is Single Check?',
      icon: (
        <FontAwesomeIcon
          icon={light('money-check-pen')}
          fontSize={18}
          className={classNames(
            isFlagsShown ? 'text-primary-blue' : 'text-zen-dark-7',
          )}
          aria-label='MoneyCheckPenIcon'
        />
      ),
    },
    {
      value: !!participant['passThrough'],
      onToggle: (toggle: boolean) => toggleParticipant('passThrough', toggle),
      label: 'Pass Through Deal?',
      icon: (
        <FontAwesomeIcon
          icon={light('arrow-turn-down-right')}
          fontSize={18}
          className={classNames(
            isFlagsShown ? 'text-primary-blue' : 'text-zen-dark-7',
          )}
          aria-label='ArrowTurnDownRightIcon'
        />
      ),
    },
  ];

  const [
    isPersonalDealModalOpen,
    setIsPersonalDealModalOpen,
  ] = useState<boolean>(false);
  const {
    transaction: { transactionPermissions },
    userIds: { agentById },
    auth: { isBroker },
  } = useSelector((state: RootState) => state);

  const user = agentById[participant.yentaId!];
  const phoneNumber = user?.phoneNumber || participant.phoneNumber;

  const currentParticipantPermission = transactionPermissions?.participantPermissions?.find(
    (p) => p.participantId === participant?.id,
  );
  const isEligible = currentParticipantPermission?.callerAllowedToAssignInstantPaymentEligibility!;
  const DEFAULT_TOGGLES: TogglableFieldConfig[] = [];

  const instantPaymentEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.INSTANT_PAYMENTS_TOGGLE,
  );

  const internalReferralFeatureEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.INTERNAL_REFERRAL,
  );

  const isInternalReferralParticipant = !!(participant as PaymentParticipantValue)
    ?.internalReferral;

  if (!isInternalReferralParticipant) {
    DEFAULT_TOGGLES.push({
      value: !!participant['commissionDocumentRecipient'],
      onToggle: (toggle: boolean) =>
        toggleParticipant('commissionDocumentRecipient', toggle),
      label: isCanadaTransaction(transaction!)
        ? 'Receives Trade Record Sheet?'
        : 'Receives Invoice / Commission Document?',
      icon: (
        <FontAwesomeIcon
          icon={light('file')}
          fontSize={18}
          className={classNames(
            isFlagsShown ? 'text-primary-blue' : 'text-zen-dark-7',
          )}
          aria-label='FileIcon'
        />
      ),
    });
  }

  if (isEligible && instantPaymentEnabled) {
    DEFAULT_TOGGLES.push({
      value: !!participant['instantPaymentEligible'],
      onToggle: (toggle: boolean) => updateInstantCommissionToggle(toggle),
      label: 'Eligible For Instant Payment',
      icon: (
        <FontAwesomeIcon
          icon={light('bolt')}
          fontSize={18}
          className={classNames(
            isFlagsShown ? 'text-primary-blue' : 'text-zen-dark-7',
          )}
          aria-label='BoltIcon'
        />
      ),
    });
  }

  const TogglableFieldConfig = isAdmin
    ? [...DEFAULT_TOGGLES, ...DEFAULT_ADMIN_TOGGLES]
    : DEFAULT_TOGGLES;

  if ('personalDeal' in participant && !isInternalReferralParticipant) {
    TogglableFieldConfig.unshift({
      value: !!participant['personalDeal'],
      onToggle: async (toggle: boolean) => {
        setIsPersonalDealModalOpen(toggle);
        if (!toggle) {
          await toggleParticipant('personalDeal', false);
        }
      },
      label: 'Personal Deal?',
      icon: (
        <HandShakeIcon
          width={22}
          height={22}
          className={classNames(
            isFlagsShown ? 'text-primary-blue' : 'text-zen-dark-7',
          )}
          aria-label='HandShakeIcon'
        />
      ),
    });
  }

  if (isAdmin && 'taxWithheld' in participant) {
    TogglableFieldConfig.push({
      value: !!participant['taxWithheld'],
      onToggle: async (toggle: boolean) => {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.TAX_COLLECTION_TOGGLE_TAX_WITHHELD_ON_PAYMENT_PARTICIPANT,
          {
            transaction: {
              id: transaction?.id,
              participant: { id: participant?.id, taxWithheld: toggle },
            },
          },
        );
        await dispatch(
          toggleTaxWithheld(transaction?.id!, participant?.id!, {
            taxWithheld: toggle,
          }),
        );
      },
      label: 'Tax Withheld?',
      icon: (
        <FontAwesomeIcon
          icon={light('percent')}
          fontSize={18}
          className={classNames(
            isFlagsShown ? 'text-primary-blue' : 'text-zen-dark-7',
          )}
          aria-label='PercentageIcon'
        />
      ),
    });
  }

  const [isToggling, setIsToggling] = useState<Record<number, boolean>>({});

  const changeToggleLoading = (index: number, loading: boolean) => {
    const modifiedIsToggling = { ...isToggling };
    modifiedIsToggling[index] = loading;
    setIsToggling(modifiedIsToggling);
  };

  const participantName = getParticipantName(participant);
  const isAgentInActive =
    agentById[participant.yentaId!]?.status === AgentInfoStatusEnum.Inactive;

  const isRoarEnabled = useFeatureFlag(FeatureFlagTypeEnum.ROAR);

  return (
    <section
      className={classNames('flex flex-row bg-grey-100 rounded-[10px]', {
        'h-full': isPaymentDetailsHidden,
      })}
      title='participant-card'
    >
      <div
        className='flex flex-col relative overflow-hidden border zen-dark-4 rounded-[10px] w-full bg-white'
        id='participantCard'
      >
        <div className='flex items-center px-3 pt-3 justify-between flex-wrap'>
          <div className='flex items-center flex-wrap mb-3'>
            {isAgentParticipant(participant.type!) && participant?.yentaId ? (
              <Link to={`/people/${participant?.yentaId!}`}>
                <ZenUserPill
                  name={participantName || 'N/A'}
                  backgroundVariant='background'
                  prefixText={getPrefixTextForParticipantUserPill(
                    internalReferralFeatureEnabled,
                    !!transaction?.referringTransactionId,
                    participant,
                  )}
                  imageUrl={agentById[participant?.yentaId!]?.avatar || ''}
                />
              </Link>
            ) : (
              <ZenUserPill
                name={participantName || 'N/A'}
                backgroundVariant='background'
                prefixText={getPrefixTextForParticipantUserPill(
                  internalReferralFeatureEnabled,
                  !!transaction?.referringTransactionId,
                  participant,
                )}
                imageUrl={agentById[participant?.yentaId!]?.avatar || ''}
              />
            )}

            <p className='font-zen-body font-semibold  text-xs leading-5 text-zen-dark-9'>
              {participant.role
                ? participantRoleDisplayName(participant.role)
                : 'N/A'}
            </p>
          </div>
          <div className='flex items-center space-x-4 mb-3'>
            {isAgentInActive && (
              <GeminiPill
                icon={faCircleDot}
                label='Inactive'
                containerClassNames='bg-red-100 text-red-600'
              />
            )}
            {phoneNumber && (
              <Hover
                hoverComponent={
                  <div className='flex items-center text-zen-dark-12 pl-1'>
                    {formatPhoneNumber(phoneNumber)}
                    <ZenCopyToClipboard
                      label=''
                      value={formatPhoneNumber(phoneNumber) || ''}
                      variant='primary'
                    />
                  </div>
                }
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <FontAwesomeIcon
                  icon={regular('phone')}
                  className='cursor-pointer text-primary-blue'
                  aria-label='participant-phone'
                  onClick={() => {
                    if (isRoarEnabled && isBroker) {
                      callModalActions.open();
                    }
                  }}
                />
              </Hover>
            )}

            {participant.emailAddress && (
              <Hover
                hoverComponent={
                  <div className='flex items-center text-zen-dark-12'>
                    {participant.emailAddress}
                    <ZenCopyToClipboard
                      label=''
                      value={participant.emailAddress!}
                      variant='primary'
                    />
                  </div>
                }
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <FontAwesomeIcon
                  icon={regular('envelope')}
                  className='cursor-pointer text-primary-blue'
                  aria-label='participant-email'
                />
              </Hover>
            )}
          </div>
        </div>

        <div className='border-b-[1px] border-b-zen-dark-4 mb-3' />

        {children}
        <div className='absolute bottom-0 right-0 left-0'>
          {!isPaymentDetailsHidden && (
            <div>
              {!isReadonly ? (
                <div className='border-t-[1px] border-gray-200 border-collapse w-full flex justify-center items-center'>
                  <div
                    className='border-r flex justify-center w-full p-2 cursor-pointer text-zen-dark-7'
                    onClick={onEdit}
                  >
                    <IconText
                      icon={
                        <FontAwesomeIcon
                          icon={regular('pencil')}
                          size='sm'
                          className=' pt-1'
                        />
                      }
                      text='Edit'
                      textStyles='pl-1.5'
                    />
                  </div>
                  <div
                    className='border-r flex justify-center w-full p-2 cursor-pointer text-zen-dark-7'
                    onClick={() => setIsFlagsShown(!isFlagsShown)}
                  >
                    <IconText
                      icon={
                        isFlagsShown ? (
                          <FontAwesomeIcon
                            icon={regular('toggle-off')}
                            size='sm'
                            className='pr-0.5 pt-1'
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={regular('toggle-on')}
                            size='sm'
                            className='pr-0.5 pt-1'
                          />
                        )
                      }
                      text='Toggle'
                    />
                  </div>
                  <div
                    className={classNames(
                      'flex justify-center w-full p-2  text-zen-dark-7',
                      isAdmin ||
                        participant.role !== ParticipantValueRoleEnum.TeamLeader
                        ? 'cursor-pointer'
                        : 'opacity-60 hover:opacity-60',
                    )}
                    onClick={onDelete}
                  >
                    <IconText
                      icon={
                        <FontAwesomeIcon
                          icon={regular('trash-can')}
                          size='sm'
                          className='pt-1'
                        />
                      }
                      text='Delete'
                      textStyles='pl-1.5'
                    />
                  </div>
                </div>
              ) : (
                !hideView && (
                  <div className='border-t-[1px] border-gray-200 border-collapse w-full flex justify-center items-center'>
                    <div
                      className='flex justify-center w-full p-2 cursor-pointer text-zen-dark-7'
                      onClick={onEdit}
                    >
                      <IconText
                        icon={
                          <FontAwesomeIcon
                            icon={faEye}
                            color='action'
                            style={{ marginTop: '2px' }}
                          />
                        }
                        text='View'
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
        {isFlagsShown && (
          <div
            className='absolute z-10 top-0 right-0 bottom-11 left-0 bg-black bg-opacity-50 flex flex-col justify-end'
            data-testid='toggle-modal'
          >
            <div className='animate-slide-in-up scrollbar overflow-y-auto pt-2'>
              <div className='flex justify-end bg-white rounded-t-3xl'>
                <button
                  type='button'
                  className='focus:outline-none'
                  onClick={() => setIsFlagsShown(false)}
                >
                  <IoMdCloseCircle
                    size={24}
                    className='text-zen-dark-10 mr-3 mt-2'
                    title='Close'
                  />
                </button>
              </div>

              <div className='bg-white divide-y font-zen-body text-zen-dark-9 text-sm'>
                <ResourceContainer
                  isEmpty={!TogglableFieldConfig?.length}
                  loading={false}
                  resourceName='toggle'
                >
                  {TogglableFieldConfig.map(
                    ({ value, onToggle, label, tooltip, icon }, index) => (
                      <div key={label} className='py-2'>
                        <ZenIconToggleRow
                          value={value}
                          icon={icon}
                          onChange={async (toggle) => {
                            changeToggleLoading(index, true);
                            await onToggle(toggle);
                            changeToggleLoading(index, false);
                          }}
                          title={label}
                          tooltip={tooltip}
                          readOnly={isReadonly}
                          loading={isToggling[index]}
                          isCollapsible={false}
                        />
                      </div>
                    ),
                  )}
                </ResourceContainer>
              </div>
            </div>
          </div>
        )}
      </div>
      {!isFlagsShown && (
        <AdminOnly>
          <div
            className='my-5 flex flex-col justify-around'
            id='quick-toggle-view'
          >
            {TogglableFieldConfig.map(
              ({ onToggle, value, icon, label, tooltip }, index) => (
                <div key={label}>
                  <ZenIconToggleRow
                    icon={icon}
                    title={label}
                    value={value}
                    onChange={async (toggle) => {
                      changeToggleLoading(index, true);
                      await onToggle(toggle);
                      changeToggleLoading(index, false);
                    }}
                    loading={isToggling[index]}
                    readOnly={isReadonly}
                    tooltip={tooltip}
                  />
                </div>
              ),
            )}
          </div>
        </AdminOnly>
      )}
      <ZenSimpleConfirmationModal
        title='Personal Deal?'
        subtitle='By selecting personal deal you acknowledge that you or your spouse are listed on the title of the property being sold/purchase or own the Entity (LLC, Trust, Corp) that owns the property being sold or purchased'
        isOpen={isPersonalDealModalOpen}
        variant='info'
        isSubmitting={false}
        onConfirm={async () => {
          setIsPersonalDealModalOpen(false);
          changeToggleLoading(0, true);
          await toggleParticipant('personalDeal', isPersonalDealModalOpen);
          changeToggleLoading(0, false);
        }}
        onClose={() => setIsPersonalDealModalOpen(false)}
      />
      <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.ROAR}>
        <BrokerOnly>
          <CallModal
            participant={participant}
            onClose={callModalActions.close}
            opened={opened}
          />
        </BrokerOnly>
      </FeatureFlagEnabledOnly>
    </section>
  );
};

export default ZenParticipantCard;
