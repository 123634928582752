import React, { Fragment } from 'react';
import { cn } from '../../../utils/classUtils';

export interface GeminiTabsV2Tab {
  name: string;
  icon?: React.ReactElement;
  TabComponent: React.FC | React.ReactNode;
}

interface GeminiTabsV2Props {
  tabs: GeminiTabsV2Tab[];
  selected?: string;
  onChange(name: string): void;
}

const GeminiTabsV2: React.FC<GeminiTabsV2Props> = ({
  tabs,
  selected,
  onChange,
}) => {
  const tabSelected = tabs.find((tab) => tab.name === selected);

  if (!tabs?.length) {
    return null;
  }

  return (
    <div className='flex flex-col w-full h-full'>
      <div className='rounded w-full overflow-x-auto py-1.5 mt-4 px-2 bg-regent-300 font-zen-body font-medium'>
        <div className='grid grid-cols-5 grid-flow-row gap-4 justify-center items-center'>
          {tabs?.map((tab) => {
            const activeTab = tab?.name === selected;

            return (
              <div
                key={tab?.name}
                onClick={() => onChange(tab?.name)}
                className={cn('text-center cursor-pointer', {
                  'rounded border bg-primary-light items-center border-regent-600 py-1 px-4': activeTab,
                })}
              >
                {!!tab?.icon && (
                  <div
                    className={cn(
                      'text-xs self-center font-light',
                      activeTab ? 'text-primary-dark' : 'text-grey-500',
                    )}
                  >
                    {tab?.icon}
                  </div>
                )}
                <div
                  className={cn(
                    'font-inter text-base whitespace-nowrap',
                    activeTab
                      ? 'text-primary-dark font-medium'
                      : 'text-grey-500 font-normal',
                  )}
                >
                  {tab?.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {!!tabSelected && (
        <Fragment key={tabSelected?.name}>{tabSelected?.TabComponent}</Fragment>
      )}
    </div>
  );
};

export default GeminiTabsV2;
