import { DateTime } from 'luxon';
import { Column } from 'react-table';
import {
  PaymentSourceTypeEnum,
  RezenPaymentActivityApi,
  RezenPaymentActivityDto,
  RezenPaymentActivityDtoTypeEnum,
} from '../../../openapi/wallet';
import { SearchParam } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { formatMoneyValue } from '../../../utils/CurrencyUtils';
import { getWalletConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { getFormattedFilterDates } from '../../../utils/TableUtils';
import DateColumnFilter from '../../table/Filters/DateColumnFilter';
import WalletPaymentSourceColumnFilter from '../../table/Filters/WalletPaymentSourceColumnFilter';
import WalletSelectActivityTypeColumnFilter from '../../table/Filters/WalletSelectActivityTypeColumnFilter';
import ZenResourceIndexContainer from '../../Zen/Containers/ZenResourceIndexContainer';

interface WalletActivityTableProps {}

const WalletActivityTable: React.FC<WalletActivityTableProps> = () => {
  const columns: Array<Column<RezenPaymentActivityDto>> = [
    {
      Header: 'Description',
      accessor: 'bankName',
      Cell: ({ value, row }) => {
        const createdAt = row?.original?.createdAt;
        const paymentSource = capitalizeEnum(
          row?.original?.paymentSource?.type ?? '',
        );

        return (
          <div className='flex items-center'>
            <div className='py-3 px-2'>
              <p className='font-inter font-normal text-sm text-primary-dark mb-1'>
                {`${value ? value + ' -' : ''}  ${paymentSource}`}
              </p>
              {createdAt && (
                <p className='font-inter font-light text-sm text-regent-600'>
                  {DateTime.fromMillis(createdAt).toFormat('MMM dd, yyyy')}
                </p>
              )}
            </div>
          </div>
        );
      },
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ value }) => {
        return (
          <p className='font-inter font-normal text-sm text-primary-dark'>
            {capitalizeEnum(value!) || 'N/A'}
          </p>
        );
      },
      Filter: WalletSelectActivityTypeColumnFilter,
      disableSortBy: true,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value, row }) => {
        const type = row?.original?.type;

        return (
          <p
            className={cn('font-inter font-medium text-sm text-right', {
              'text-green-600': type === RezenPaymentActivityDtoTypeEnum.Credit,
              'text-primary-dark':
                type === RezenPaymentActivityDtoTypeEnum.Debit,
            })}
          >
            {formatMoneyValue(value) || 'N/A'}
          </p>
        );
      },
      disableSortBy: true,
      disableFilters: true,
      headerContentClassName: 'justify-end',
    },
    {
      Header: 'Payment Source',
      accessor: 'paymentSource',
      Cell: () => null,
      Filter: WalletPaymentSourceColumnFilter,
      disableSortBy: true,
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: () => null,
      Filter: DateColumnFilter,
      disableSortBy: true,
    },
  ];

  return (
    <div className='p-6 rounded-xl shadow-pop-over bg-white'>
      <ZenResourceIndexContainer<RezenPaymentActivityDto>
        header='Real Wallet Activity'
        resourceName='activity'
        columns={columns}
        fetchData={async (req, cancelToken) => {
          const [
            ,
            createdAtOnOrAfter,
            createdAtOnOrBefore,
          ] = getFormattedFilterDates(
            (req.filter?.createdAt || []) as SearchParam[],
          );

          const { data } = await new RezenPaymentActivityApi(
            getWalletConfiguration(),
          ).searchMyRezenPaymentActivities(
            req.page,
            req.pageSize,
            undefined,
            undefined,
            createdAtOnOrAfter,
            createdAtOnOrBefore,
            undefined,
            (req.filter?.type as SearchParam)
              ?.value as RezenPaymentActivityDtoTypeEnum,
            (req.filter?.paymentSource as SearchParam)
              ?.values as PaymentSourceTypeEnum[],
            { cancelToken },
          );

          return {
            data: data?.paymentActivities ?? [],
            total: data?.totalCount ?? 0,
          };
        }}
        hiddenColumns={['createdAt', 'paymentSource']}
        stickyHeader
        standalone={false}
      />
    </div>
  );
};

export default WalletActivityTable;
