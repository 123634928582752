import {
  SegmentedControl as MSegmentedControl,
  SegmentedControlProps as MSegmentedControlProps,
} from '@mantine/core';
import { cn } from '../../utils/classUtils';
import { combineDefaultClassnamesWithCustomClassnames } from './utils';

type Size = 'sm' | 'md' | 'lg';
const sizeStyles: Record<Size, MSegmentedControlProps['classNames']> = {
  sm: {
    root: '!gap-1',
    label: '!text-sm',
    indicator: '!py-1',
  },
  md: {
    root: '!gap-2',
    label: '!text-base',
    indicator: '!py-2',
  },
  lg: {
    root: '!gap-3',
    label: '!text-lg',
    indicator: '!py-3',
  },
};

type SegmentedControlProps = Omit<MSegmentedControlProps, 'size'> & {
  size?: Size;
  fullWidth?: boolean;
};

export const SegmentedControl: React.FC<SegmentedControlProps> = (props) => {
  return (
    <MSegmentedControl
      {...props}
      classNames={combineDefaultClassnamesWithCustomClassnames(
        {
          root: cn(
            '!rounded-lg !items-center !gap-3',
            props.fullWidth && '!w-full',
          ),
          label: '!font-inter !font-light',
          indicator: '!bg-primary-light !border-regent-600 !border !rounded-lg',
          control: '!border-l-0',
        },
        sizeStyles[props.size ?? 'md'],
      )}
    />
  );
};
