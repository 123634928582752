import { faCircleXmark } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CreditOfferingDto, DebitOfferingDto } from '../../openapi/wallet';
import {
  useFetchUnsignedAgreements,
  useSignAgreement,
  useSignAmendment,
} from '../../query/wallet/useWallet';
import { RootState, SignatureWidgetModeEnum } from '../../types';
import { getUnsignedAgreements } from '../../utils/WalletUtils';
import SignatureWidget from '../SignatureWidget/SignatureWidget';

interface WalletAgreementModalProps {
  offer?: DebitOfferingDto | CreditOfferingDto;
  agreementDefinitionIds?: string[];
  onClose(): void;
  onEverythingSigned?(): void;
}

const WalletAgreementModal: React.FC<WalletAgreementModalProps> = ({
  agreementDefinitionIds,
  onClose,
  offer,
  onEverythingSigned,
}) => {
  const userId = useSelector((state: RootState) => state.auth.userDetail?.id);

  const {
    mutate: signAgreement,
    isSuccess: agreementSuccess,
  } = useSignAgreement();
  const {
    mutate: signAmendment,
    isSuccess: amendmentSuccess,
  } = useSignAmendment();

  const {
    data: unsignedAgreements,
    refetch: refetchUnsignedAgreements,
    isLoading,
  } = useFetchUnsignedAgreements(
    userId,
    offer ? [offer?.agreementDefinitionId!] : agreementDefinitionIds,
  );

  const { agreement, amendment, signedAgreement } = getUnsignedAgreements(
    unsignedAgreements,
  );

  const handleSignDocument = useCallback(async () => {
    if (agreement) {
      signAgreement({
        userId: userId!,
        agreementId: agreement?.agreementId!,
      });
    }

    if (amendment) {
      signAmendment({
        agreementId: signedAgreement?.agreementId!,
        amendmentId: amendment?.agreementAmendmentId!,
      });
    }
  }, [
    agreement,
    amendment,
    signAgreement,
    signAmendment,
    signedAgreement?.agreementId,
    userId,
  ]);

  // refetch unsigned agreements every time an agreement/amendment is signed
  useEffect(() => {
    if (agreementSuccess || amendmentSuccess) {
      refetchUnsignedAgreements();
    }
  }, [agreementSuccess, amendmentSuccess, refetchUnsignedAgreements]);

  // when everything is signed
  useEffect(() => {
    if (unsignedAgreements?.length === 0) {
      onEverythingSigned ? onEverythingSigned() : onClose();
    }
  }, [unsignedAgreements, onEverythingSigned, onClose]);

  // return if no agreement to sign
  if (!offer?.agreementDefinitionId && !offer?.agreementDefinitionId) {
    return null;
  }

  return (
    <div className='fixed inset-0 z-50 w-full h-full-window'>
      <div
        className='absolute inset-0 z-0 bg-primary-dark bg-opacity-40'
        role='button'
        onClick={onClose}
      />

      <div className='flex items-center justify-center w-full h-full'>
        <div className='w-11/12 md:w-4/5 lg:w-2/3 bg-primary-light rounded-lg z-10'>
          {/* Title Bar */}
          <div className='px-4 py-2.5 flex items-center bg-rezen-blue-100 rounded-t-lg border-rezen-blue-100'>
            <p className='flex-grow text-center font-inter font-medium text-base ml-2'>
              Legal Privacy
            </p>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className='text-xl text-primary-light cursor-pointer close-icon'
              // @ts-ignore
              style={{ '--fa-secondary-color': '#7B7A7A' }}
              onClick={onClose}
            />
          </div>

          {/* Note */}
          <p className='w-full py-2.5 px-1 font-inter text-base text-center text-primary-dark bg-zen-light-gray-2'>
            Before you can continue, you must accept the following terms.
          </p>

          {/* Signature Widget */}
          <div className='h-[550px] bg-primary-light overflow-y-hidden rounded-b-lg'>
            <SignatureWidget
              token={
                agreement ? agreement?.signeeToken! : amendment?.signeeToken!
              }
              mode={SignatureWidgetModeEnum.SIGN}
              onDocumentSigned={handleSignDocument}
              onDocumentAlreadySigned={handleSignDocument}
              showMobileTooltip
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletAgreementModal;
