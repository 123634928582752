import { values } from 'lodash';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import ZenControlledSelectInput from '../components/Zen/Input/ZenControlledSelectInput';
import ZenControlledTextAreaInput from '../components/Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenSidebarModalActionFooterV2 from '../components/Zen/Modal/ZenSidebarModalActionFooterV2';
import ZenSidebarModal from '../components/Zen/ZenSidebarModal';
import {
  OutgoingPaymentResponse,
  OutgoingPaymentsControllerApi,
  OutgoingPaymentWithBankInfoResponse,
  SearchOutgoingPaymentResponse,
  UpdateOutgoingPaymentRequestPaymentStatusEnum,
} from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import { ISelectOption } from '../types';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../utils/StringUtils';

interface ZenEditOutgoingPaymentFormSidebarModalProps {
  isOpen: boolean;
  onClose(reload?: boolean): void;
  outgoingPayment:
    | OutgoingPaymentResponse
    | OutgoingPaymentWithBankInfoResponse
    | SearchOutgoingPaymentResponse;
}

interface FormData {
  confirmationCode: string;
  paymentStatus: ISelectOption;
  payerNote: string;
}

const ZenEditOutgoingPaymentFormSidebarModal: React.FC<ZenEditOutgoingPaymentFormSidebarModalProps> = ({
  isOpen,
  onClose,
  outgoingPayment,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      payerNote: outgoingPayment?.payerNote,
      paymentStatus: outgoingPayment?.status
        ? {
            label: capitalizeEnum(outgoingPayment?.status),
            value: outgoingPayment?.status,
          }
        : undefined,
      confirmationCode: outgoingPayment?.confirmationCode,
    },
  });

  const onSubmit = async (values: FormData) => {
    try {
      await new OutgoingPaymentsControllerApi(
        getArrakisConfiguration(),
      ).updateOutgoingPayment(outgoingPayment.id!, {
        paymentStatus: values.paymentStatus
          ?.value as UpdateOutgoingPaymentRequestPaymentStatusEnum,
        confirmationCode: values.confirmationCode,
        payerNote: values.payerNote,
      });
      onClose(true);
      dispatch(showSuccessToast('Outgoing payment saved successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error saving outgoing payment', e, {
        outgoingPayment: outgoingPayment!,
      });
      dispatch(
        showErrorToast(
          'We had a problem updating outgoing payments',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <ZenSidebarModal
      title={`Edit (${outgoingPayment?.transactionCode})`}
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-4'>
          <ZenControlledTextInput<FormData, 'confirmationCode'>
            control={control}
            label='Confirmation Code'
            placeholder='Enter Confirmation Code'
            name='confirmationCode'
            rules={{
              required: 'Required',
            }}
            isRequired
          />
          <div className='mt-5'>
            <ZenControlledSelectInput<FormData, `paymentStatus`>
              control={control}
              label='Status'
              name='paymentStatus'
              options={values(
                UpdateOutgoingPaymentRequestPaymentStatusEnum,
              ).map((status) => ({
                label: capitalizeEnum(status),
                value: status,
              }))}
              rules={{
                required: 'Required',
              }}
              isRequired
            />
          </div>
          <div className='pb-20 mt-5'>
            <ZenControlledTextAreaInput<FormData, 'payerNote'>
              control={control}
              label='Note'
              placeholder='Note for reference...'
              rows={4}
              name='payerNote'
            />
          </div>
        </div>
        <ZenSidebarModalActionFooterV2
          isSubmitting={isSubmitting}
          isDisabled={isSubmitting}
          onClose={onClose}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenEditOutgoingPaymentFormSidebarModal;
