import { faMessage, faPhone } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDisclosure } from '@mantine/hooks';
import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { MemberIdTypeEnum } from '../../../openapi/yada';
import { AgentResponse } from '../../../openapi/yenta';
import { useFetchMultipleOfficeVisibleGroupsByIds } from '../../../query/office/useOffice';
import { useCreateConversation } from '../../../query/roar/useRoar';
import { FeatureFlagTypeEnum, RootState } from '../../../types';
import { getConversationLink } from '../../../utils/ConversationUtils';
import { isBrokerTeam } from '../../../utils/OfficeUtils';
import BrokerOnly from '../../auth/BrokerOnly';
import { Button } from '../../commons/Button';
import CallModalStepper from '../../Conversation/CallModalStepper';
import FeatureFlagEnabledOnly from '../../FeatureFlagEnabledOnly';
import ConversationSelectOfficeModal from '../../Menu/BrokerQueue/ConversationSelectOfficeModal';

type ZenAgentSecondaryHeaderProps = {
  agentId: string;
  agentDetail: AgentResponse;
};

const ZenAgentSecondaryHeader: React.FC<ZenAgentSecondaryHeaderProps> = ({
  agentId,
  agentDetail,
}) => {
  const [callModalOpened, callModalActions] = useDisclosure();
  const [officeModalOpened, officeModalActions] = useDisclosure();
  const [openConversation, setOpenConversation] = useState(false);
  const officeIdRef = useRef<string>('');

  const userDetails = useSelector((state: RootState) => state.auth.userDetail);

  const { mutate, isLoading } = useCreateConversation();

  const officeIds = useMemo(
    () => userDetails?.offices?.map((office) => office.id!) ?? [],
    [userDetails?.offices],
  );

  const {
    isLoading: isLoadingOfficeGroups,
    data: officeGroups,
  } = useFetchMultipleOfficeVisibleGroupsByIds(officeIds);

  const groupId = officeGroups?.[officeIdRef.current]?.groups?.find(
    isBrokerTeam,
  )?.id;

  const handleGetConversation = (cb: (id: string) => void) => {
    const _groupId = officeGroups?.[officeIdRef.current]?.groups?.find(
      isBrokerTeam,
    )?.id;

    mutate(
      {
        members: [
          {
            value: agentDetail?.id!,
            type: MemberIdTypeEnum.User,
          },
          {
            value: _groupId!,
            type: MemberIdTypeEnum.Group,
          },
        ],
      },
      {
        onSuccess: (data) => {
          cb(data.id);
        },
      },
    );
  };

  const isNotSelf = userDetails?.id !== agentId;

  return (
    <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.ROAR_FULL_RELEASE_BOLT}>
      {isNotSelf && (
        <div className='px-4 mb-4'>
          <div className='flex items-center space-x-3'>
            <BrokerOnly>
              <Button
                variant='outline'
                color='grey'
                className='border-regent-400 !border !text-primary-dark px-7 disabled:opacity-50'
                disabled={isLoading || isLoadingOfficeGroups}
                leftIcon={
                  <FontAwesomeIcon
                    icon={faPhone}
                    fontSize={12}
                    className='mr-0.5'
                  />
                }
                onClick={() => {
                  setOpenConversation(false);
                  officeModalActions.open();
                }}
              >
                Call
              </Button>
              <Button
                variant='outline'
                color='grey'
                className='border-regent-400 !border !text-primary-dark px-7 disabled:opacity-50'
                disabled={isLoading || isLoadingOfficeGroups}
                leftIcon={
                  <FontAwesomeIcon
                    icon={faMessage}
                    fontSize={12}
                    className='mr-0.5'
                  />
                }
                onClick={() => {
                  setOpenConversation(true);
                  officeModalActions.open();
                }}
              >
                Message
              </Button>
            </BrokerOnly>
          </div>
          <ConversationSelectOfficeModal
            opened={officeModalOpened}
            onClose={officeModalActions.close}
            offices={userDetails?.offices}
            onChange={(id) => {
              officeIdRef.current = id;
            }}
            isLoading={isLoading || isLoadingOfficeGroups}
            onConfirm={() => {
              if (openConversation) {
                handleGetConversation((id) => {
                  window.open(getConversationLink(id), '_blank');
                });
              } else {
                callModalActions.open();
              }
              officeModalActions.close();
            }}
          />
          <CallModalStepper
            opened={callModalOpened}
            onClose={callModalActions.close}
            groupId={groupId!}
            callAgent
            agentInfo={agentDetail}
            officeIds={officeIds}
            isFetchingConversation={isLoading}
            getConversation={handleGetConversation}
          />
        </div>
      )}
    </FeatureFlagEnabledOnly>
  );
};

export default ZenAgentSecondaryHeader;
