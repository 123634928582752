import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { capitalizeEnum } from '../../../utils/StringUtils';
import Hover from '../../Hover';
import ZenAvatar from '../ZenAvatar';

interface ZenAgentsAvatarListProps {
  avatarList: string[];
  represents?: string;
}

const ZenAgentsAvatarList: React.FC<ZenAgentsAvatarListProps> = ({
  avatarList,
  represents,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getColorClassName = (index: number): string => {
    if (index === 0) {
      return 'bg-zen-blue-1 text-white';
    }
    if (index === 1) {
      return 'bg-zen-blue-2 text-white';
    }
    return 'bg-zen-blue-4 text-white';
  };

  return (
    <div className='flex items-center' onMouseLeave={() => setIsOpen(false)}>
      {avatarList?.slice(0, 3).map((avatar, index) => {
        return index < 2 ? (
          <div
            className={classNames('border rounded-full', {
              '-ml-1.5': index !== 0,
            })}
            key={[avatar, index].join(',')}
          >
            <Hover
              config={{ placement: 'top', trigger: 'hover' }}
              hoverComponent={<span className='px-1'>{avatar}</span>}
            >
              <ZenAvatar
                name={avatar}
                size='sm'
                showRing
                colorClassName={getColorClassName(index)}
              />
            </Hover>
          </div>
        ) : (
          <div className='-ml-1.5 z-0' key={[avatar, index].join(',')}>
            <Popover
              isOpen={isOpen}
              positions={['top']}
              onClickOutside={() => setIsOpen(false)}
              content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                  position={position}
                  childRect={childRect}
                  popoverRect={popoverRect}
                  arrowColor='white'
                  arrowSize={10}
                  arrowStyle={{
                    bottom: 1,
                    zIndex: 1,
                  }}
                  className='popover-arrow-container'
                  arrowClassName='popover-arrow'
                >
                  <div className='w-[210px] border rounded-[20px] border-gray-300 bg-white divide-y-2 space-y-2 px-4 py-2 text-dark shadow-2xl h-72'>
                    <div className='flex items-center justify-between'>
                      <div className='text-base pt-2'>Agents</div>
                      <div
                        onClick={() => setIsOpen(false)}
                        className='bg-zen-dark-10 rounded-full w-5 h-5 flex items-center justify-center text-white'
                      >
                        <FontAwesomeIcon icon={faXmark} size='sm' />
                      </div>
                    </div>
                    <div className='py-4 space-y-4'>
                      <div className='flex flex-col'>
                        <div className='text-zen-dark-6 text-sm'>
                          Representation
                        </div>
                        <div className='text-zen-dark-9 text-base'>
                          {represents ? capitalizeEnum(represents) : '--'}
                        </div>
                      </div>
                      <div className='flex flex-col'>
                        <div className='text-zen-dark-6 pb-0.5 text-sm'>
                          Agents
                        </div>
                        <div className='text-dark h-24 scrollbar overflow-y-auto overflow-x-hidden'>
                          {avatarList.length > 0
                            ? avatarList.map((avatar, index) => (
                                <div
                                  className='mb-2 flex items-center'
                                  key={[avatar, index].join(',')}
                                >
                                  <ZenAvatar
                                    name={avatar}
                                    size='xs'
                                    colorClassName={getColorClassName(index)}
                                  />
                                  <div className='leading-tight text-zen-dark-9 text-sm ml-2'>
                                    {avatar}
                                  </div>
                                </div>
                              ))
                            : '--'}
                        </div>
                      </div>
                    </div>
                  </div>
                </ArrowContainer>
              )}
            >
              <span
                className={classNames(
                  'inline-flex items-center justify-center rounded-full h-8 w-8 ring-2 ring-white bg-red-500',
                  getColorClassName(index),
                )}
                onMouseEnter={() => setIsOpen(true)}
              >
                <span className={classNames('font-primary-medium leading-1')}>
                  +{avatarList.length - 2}
                </span>
              </span>
            </Popover>
          </div>
        );
      })}
    </div>
  );
};

export default ZenAgentsAvatarList;
