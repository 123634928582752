import { faPhoneArrowUpRight } from '@fortawesome/pro-light-svg-icons';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import {
  ParticipantValue,
  PaymentParticipantValue,
} from '../../openapi/arrakis';
import {
  CreateVoiceCallRequestContainerTypeEnum,
  CreateVoiceCallRequestTargetTypeEnum,
} from '../../openapi/yada';
import { RootState } from '../../types';
import { getFullName } from '../../utils/AgentHelper';
import { cn } from '../../utils/classUtils';
import { isBrokerTeam } from '../../utils/OfficeUtils';
import Avatar from '../Avatar';
import { Modal } from '../commons/Modal';
import CallModalForm from './CallModalForm';

interface CallModalProps {
  onClose(): void;
  participant?: ParticipantValue | PaymentParticipantValue | undefined;
  opened: boolean;
}

const CallModal: React.FC<CallModalProps> = ({
  onClose,
  participant,
  opened,
}) => {
  const isBroker = useSelector((state: RootState) => state.auth.isBroker);
  const participantInfo = useSelector(
    (state: RootState) => state.userIds.agentById[participant?.yentaId!],
  );
  const transactionId = useSelector(
    (state: RootState) => state.transaction.transactionDetailResponse.data?.id,
  )!;
  const commentsMentionUsers = useSelector(
    (state: RootState) => state.transaction.commentsMentionUsers,
  );
  const groupId = commentsMentionUsers?.mentionableGroups?.find(isBrokerTeam)
    ?.groupId!;
  const participantName = isBroker ? getFullName(participant) : 'Broker Team';
  const phoneNumber = participantInfo?.phoneNumber ?? participant?.phoneNumber;

  return (
    <Modal
      size='585px'
      opened={opened}
      onClose={onClose}
      aria-label='agent-call-modal'
    >
      <Modal.Header
        color='white'
        className='absolute w-full bg-transparent z-10'
      />
      <Modal.Content className={cn('pt-8 px-8', isBroker && 'pb-0')}>
        <div className='w-full space-y-1 flex items-center flex-col'>
          <div
            className={cn(
              'relative',
              !isBroker &&
                'bg-[#DBE9FF] w-10 h-10 rounded-full flex items-center justify-center',
            )}
          >
            <FontAwesomeIcon
              icon={faPhoneArrowUpRight}
              className='p-1 border absolute -right-1 -top-1 bg-white text-rezen-blue-600 rounded-full'
              fontSize={9}
            />
            {isBroker ? (
              <Avatar
                name={participantName!}
                size='xs'
                imageUrl={participantInfo?.avatar}
              />
            ) : (
              <FontAwesomeIcon
                icon={faUsers}
                size='sm'
                className='text-rezen-blue-600'
              />
            )}
          </div>
          <div className='text-grey-600 text-sm font-normal'>
            {participantName}
          </div>
        </div>
        <CallModalForm
          phoneNumber={phoneNumber}
          voiceCallPayload={{
            containerId: transactionId,
            containerType: CreateVoiceCallRequestContainerTypeEnum.Transaction,
            targetId: isBroker ? participant?.yentaId! : groupId,
            targetType: isBroker
              ? CreateVoiceCallRequestTargetTypeEnum.User
              : CreateVoiceCallRequestTargetTypeEnum.Group,
          }}
        />
      </Modal.Content>
    </Modal>
  );
};

export default CallModal;
