import { ReactComponent as TriggerIcon } from '../../../assets/icons/zen/autoToggle/trigger.svg';

const EmptyTriggerPlaceholder: React.FC = () => {
  return (
    <div className='py-10 flex flex-col items-center border border-zen-dark-4 rounded-xl'>
      <TriggerIcon width={30} height={30} className='text-zen-dark-6' />
      <p className='mt-2 font-zen-body text-base text-zen-dark-6'>
        No triggers created yet
      </p>
    </div>
  );
};

export default EmptyTriggerPlaceholder;
