import { z } from 'zod';
import { ALPHA_NUMERIC_REGEX_NO_SPACE, NAME_REGEX } from '../utils/StringUtils';
import { AuthControllerApi } from '../openapi/keymaker';
import { getKeymakerConfiguration } from '../utils/OpenapiConfigurationUtils';
import { SignUpRequestCountryEnum } from '../openapi/yenta';
import { PASSWORD_VALIDATIONS } from '../utils/zodValidations';

export const registerFormSchema = z
  .object({
    firstName: z
      .string({ message: 'First name is required' })
      .trim()
      .min(2, { message: 'Must be at least 2 characters' })
      .regex(RegExp(NAME_REGEX), {
        message: 'Please enter a valid first name',
      }),
    lastName: z
      .string({ message: 'Last name is required' })
      .trim()
      .min(2, { message: 'Must be at least 2 characters' })
      .regex(RegExp(NAME_REGEX), { message: 'Please enter a valid last name' }),
    username: z
      .string({ message: 'Username is required' })
      .trim()
      .min(2, { message: 'Username must be at least 2 characters' })
      .max(15, { message: 'Username must be fewer than 15 characters' })
      .regex(ALPHA_NUMERIC_REGEX_NO_SPACE, {
        message: 'Username can only have letters and numbers',
      })
      .refine(
        async (value) => {
          try {
            const { data: username } = await new AuthControllerApi(
              getKeymakerConfiguration(),
            ).checkUsernameAvailability(value);
            return username.available;
          } catch (e) {
            return false;
          }
        },
        { message: 'Username is already taken' },
      ),
    emailAddress: z
      .string({ message: 'Email is required' })
      .email({ message: 'Invalid email address' })
      .refine(
        async (value) => {
          try {
            const { data: email } = await new AuthControllerApi(
              getKeymakerConfiguration(),
            ).checkEmailAvailability(value);

            return email.available;
          } catch (e) {
            return false;
          }
        },
        { message: 'Email is already taken' },
      ),
    country: z.nativeEnum(SignUpRequestCountryEnum, {
      message: 'Please select a country',
    }),
    password: PASSWORD_VALIDATIONS,
    confirmPassword: z
      .string({ message: 'Please re-enter your password' })
      .trim()
      .min(1, { message: 'Please re-enter your password' }),
    terms: z
      .boolean({ message: 'Please select the checkbox' })
      .refine((value) => value === true, {
        message: 'Please select the checkbox',
      }),
    permission: z.boolean().optional(),
  })
  .refine(({ password, confirmPassword }) => password === confirmPassword, {
    path: ['confirmPassword'],
    message: 'Passwords do not match',
  });

export type RegisterForm = z.infer<typeof registerFormSchema>;
