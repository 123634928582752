import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { EnumMap } from '../../types';
import { cn } from '../../utils/classUtils';
import { Button } from '../commons/Button';

export type GeminiConfirmationModalSizeVariantType =
  | 'default'
  | 'small'
  | 'large'
  | 'extraLarge'
  | 'fixed';

export type ConfirmVariant = 'remove' | 'submit' | 'revoke';

export interface GeminiConfirmationModalProps {
  title?: string;
  isOpen: boolean;
  subtitle?: string | React.ReactElement;
  hideIcon?: boolean;
  size?: GeminiConfirmationModalSizeVariantType;
  onClose?(info: { isX: boolean }): void;
  onConfirm?(): void;
  cancelButtonText?: string;
  confirmButtonText?: string;
  hideFooterButtons?: boolean;
  hideCancelButton?: boolean;
  containerClassNames?: string;
  titleClassName?: string;
  onIconClose?(): void;
  confirmButtonVariant?: ConfirmVariant;
  isDisabled?: boolean;
}

const GeminiConfirmationModal: React.FC<GeminiConfirmationModalProps> = ({
  title,
  isOpen,
  subtitle,
  onClose,
  hideIcon = false,
  size = 'default',
  onConfirm,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  hideFooterButtons = false,
  hideCancelButton = false,
  containerClassNames,
  titleClassName,
  onIconClose,
  children,
  confirmButtonVariant = 'submit',
  isDisabled,
}) => {
  const confirmButtonVariantMap: EnumMap<ConfirmVariant, 'red' | 'blue'> = {
    remove: 'red',
    submit: 'blue',
    revoke: 'red',
  };

  const sizeVariant: EnumMap<GeminiConfirmationModalSizeVariantType, string> = {
    default: 'md:w-1/2 lg:w-1/4',
    large: 'md:w-3/5 lg:w-1/3',
    small: 'md:w-2/5 lg:w-1/5',
    extraLarge: 'md:w-4/5 lg:w-6/12',
    fixed: 'w-[509px]',
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-primary-dark bg-opacity-40'
        role='button'
        onClick={() => onClose?.({ isX: true })}
      />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
        <div
          className={cn(
            'w-full bg-white rounded-xl z-10 shadow-zen-web',
            containerClassNames || sizeVariant[size],
          )}
          aria-label='gemini-confirmation-modal'
        >
          <div
            className={cn(
              'flex flex-row items-center bg-white rounded-t-xl border-gray-200',
              { 'border-b': title },
            )}
          >
            {!hideIcon && <div className='w-9 mx-2' />}

            <div className='flex flex-grow flex-shrink flex-col items-center justify-center gap-y-2 my-3'>
              {title && (
                <p
                  className={cn(
                    'text-base text-center font-inter font-medium text-primary-dark',
                    titleClassName,
                  )}
                >
                  {title}
                </p>
              )}
            </div>

            {!hideIcon && (
              <div className='w-6 h-6 flex items-center justify-center bg-zen-dark-10 rounded-full mx-2 my-3'>
                <FontAwesomeIcon
                  icon={faXmark}
                  className='text-primary-light cursor-pointer'
                  onClick={() =>
                    onIconClose ? onIconClose() : onClose?.({ isX: true })
                  }
                />
              </div>
            )}
          </div>

          <div className='w-full'>
            {subtitle && (
              <p className='text-base text-center text-gray-600 p-4'>
                {subtitle}
              </p>
            )}
            {children}
            {!hideFooterButtons && (
              <div className='flex flex-row justify-center gap-2.5 px-6 py-4'>
                {!hideCancelButton && (
                  <div className='w-[174px]'>
                    <Button
                      fullWidth
                      variant='outline'
                      color='black'
                      onClick={() => onClose?.({ isX: false })}
                    >
                      {cancelButtonText}
                    </Button>
                  </div>
                )}
                <div className='w-[174px]'>
                  <Button
                    fullWidth
                    variant='filled'
                    color={confirmButtonVariantMap[confirmButtonVariant]}
                    onClick={onConfirm}
                    disabled={isDisabled}
                  >
                    {confirmButtonText}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeminiConfirmationModal;
