import { useEffect } from 'react';
import Logger from '../utils/Logger';

/**
 * Custom hook for polling an API at a specified interval.
 *
 * @param {() => void} fn - The function to be called for API polling.
 * @param {boolean} isEnabled - Flag indicating whether the polling is enabled or disabled.
 * @param {number} interval - The interval (in milliseconds) at which the API should be polled.
 * @returns {void}
 */
export const usePollApi = (
  fn: () => void,
  isEnabled: boolean,
  interval: number = 1000,
) => {
  useEffect(() => {
    Logger.debug(
      `polling: pollingEnabled=${isEnabled}, pollingInterval=${interval}`,
    );
    fn();

    if (!isEnabled) {
      return;
    }

    const intervalId = setInterval(fn, interval);

    return () => {
      Logger.debug('polling: clearing interval');
      clearInterval(intervalId);
    };
  }, [interval, fn, isEnabled]);
};
