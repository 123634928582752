import { Column } from 'react-table';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { useState } from 'react';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import GeminiTooltip from '../components/Gemini/GeminiTooltip';
import ZenRoute from '../components/Zen/ZenRoute';
import {
  AgentWealthManagementApi,
  ProspectiveAgentWealthPlanDto,
} from '../openapi/plutus';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import { getWealthManagementConfiguration } from '../utils/OpenapiConfigurationUtils';
import { EnumMap, RootState } from '../types';
import { formatPhoneNumber } from '../utils/StringUtils';
import ZenResourceContainer from '../components/Zen/ZenResourceContainer';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenStatusPill from '../components/Zen/ZenStatusPill';
import { WealthPlanNotification } from '../components/WeathManagement/WealthPlanReferral/WealthPlanNotification';
import { WealthPlanDashboardDateCount } from '../components/WeathManagement/WealthPlanDashboardDateCount';

interface WealthPlanCRMColumns extends ProspectiveAgentWealthPlanDto {
  openEmail?: undefined; // This is needed so the open email column can be sortable (because it is based off of openEmailAt)
}

export const columns: Array<Column<WealthPlanCRMColumns>> = [
  {
    Header: 'First Name',
    accessor: 'firstName',
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
    Cell: ({ value }) => {
      if (!value) {
        return <span className='font-zen-body font-normal'>-</span>;
      }
      return (
        <div className='font-zen-body text-dark font-normal'>
          {formatPhoneNumber(value)}
        </div>
      );
    },
  },
  {
    Header: 'Email Address',
    accessor: 'emailAddress',
    Cell: ({ value }) => {
      if (!value) {
        return <span className='font-zen-body font-normal'>-</span>;
      }
      return <div className='font-zen-body text-dark font-normal'>{value}</div>;
    },
  },
  {
    Header: 'Brokerage',
    accessor: 'brokerageName',
    Cell: ({ value }) => {
      if (!value) {
        return <span className='font-zen-body font-normal'>-</span>;
      }
      return <div className='font-zen-body text-dark font-normal'>{value}</div>;
    },
  },
  {
    Header: (
      <GeminiTooltip
        title='"Open Email" indicates whether the external agent has opened the email.'
        arrow
      >
        <div>
          Open Email
          <FontAwesomeIcon icon={faCircleInfo} className='ml-1' />
        </div>
      </GeminiTooltip>
    ),
    accessor: 'openEmail',
    Cell: ({ row }: any) => {
      return (
        <ZenStatusPill
          text={row?.original?.openEmailAt! !== null ? 'Yes' : 'No'}
          variant={row?.original?.openEmailAt! !== null ? 'success' : 'danger'}
          icon={
            row?.original?.openEmailAt! !== null ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <FontAwesomeIcon icon={faXmark} />
            )
          }
        />
      );
    },
  },
  {
    Header: (
      <GeminiTooltip
        title='"Open Email On" indicates the first time the external agent opened the email.'
        arrow
      >
        <div className='flex items-center'>
          Open Email On
          <FontAwesomeIcon icon={faCircleInfo} className='ml-1' />
        </div>
      </GeminiTooltip>
    ),
    accessor: 'openEmailAt',
    Cell: ({ value, row }) => {
      if (!value) {
        return <span className='font-zen-body font-normal'>-</span>;
      }

      const date = DateTime.fromMillis(value);
      const count = row.original.emailOpenedCount ?? 0;

      return (
        <WealthPlanDashboardDateCount
          date={date}
          count={count}
          label='Opened'
        />
      );
    },
  },
  {
    Header: (
      <GeminiTooltip
        title='"View WealthPlan" indicates the most recent date when the external agent viewed their WealthPlan in a browser.'
        arrow
      >
        <div>
          View WealthPlan
          <FontAwesomeIcon icon={faCircleInfo} className='ml-1' />
        </div>
      </GeminiTooltip>
    ),
    accessor: 'wealthPlanLoadedAt',
    Cell: ({ value, row }) => {
      if (!value) {
        return <span className='font-zen-body font-normal'>-</span>;
      }

      const date = DateTime.fromMillis(value);
      const count = row.original.planOpenedCount ?? 0;

      return (
        <WealthPlanDashboardDateCount
          date={date}
          count={count}
          label='Viewed'
        />
      );
    },
  },
  {
    Header: (
      <GeminiTooltip
        title='"Edit WealthPlan" indicates the first time when the external agent edited their WealthPlan.'
        arrow
      >
        <div>
          Edit WealthPlan
          <FontAwesomeIcon icon={faCircleInfo} className='ml-1' />
        </div>
      </GeminiTooltip>
    ),
    accessor: 'lastUpdatedAt',
    Cell: ({ value, row }) => {
      if (!value) {
        return <span className='font-zen-body font-normal'>-</span>;
      }

      const date = DateTime.fromMillis(value);
      const count = row.original.planEditedCount ?? 0;

      return (
        <WealthPlanDashboardDateCount
          date={date}
          count={count}
          label='Edited'
        />
      );
    },
  },
  {
    Header: 'Email Sent',
    accessor: 'emailSentTimeAt',
    Cell: ({ value }) => {
      if (!value) {
        return <span className='font-zen-body font-normal'>-</span>;
      }

      const date = DateTime.fromMillis(value);

      return (
        <div className='font-normal text-sm font-zen-body'>
          <p> {date.toFormat('LL/dd/yyyy')}</p>
          <p className='text-zen-dark-6'>{date.toFormat('hh:mm a')}</p>
        </div>
      );
    },
  },
];

const WealthPlanSortByTypeEnum: EnumMap<
  string,
  | 'FIRST_NAME'
  | 'LAST_NAME'
  | 'PHONE_NUMBER'
  | 'BROKERAGE_NAME'
  | 'EMAIL_ADDRESS'
  | 'OPEN_EMAIL_AT'
  | 'WEALTH_PLAN_LOADED_AT'
  | 'MOST_RECENT_OPEN_DATE_AT'
  | 'EMAIL_SENT_TIME_AT'
> = {
  firstName: 'FIRST_NAME',
  lastName: 'LAST_NAME',
  phoneNumber: 'PHONE_NUMBER',
  brokerageName: 'BROKERAGE_NAME',
  emailAddress: 'EMAIL_ADDRESS',
  openEmailAt: 'OPEN_EMAIL_AT',
  openEmail: 'OPEN_EMAIL_AT',
  wealthPlanLoadedAt: 'WEALTH_PLAN_LOADED_AT',
  mostRecentOpenDateAt: 'MOST_RECENT_OPEN_DATE_AT',
  emailSentTimeAt: 'EMAIL_SENT_TIME_AT',
};

const WealthPlanSortDirectionTypeEnum: EnumMap<string, 'ASC' | 'DESC'> = {
  asc: 'ASC',
  desc: 'DESC',
};

interface WealthPlanCRMProps {}

const WealthPlanCRMDashboard: React.FC<WealthPlanCRMProps> = () => {
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <ZenRoute title='WealthPlan Attraction Dashboard'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'WealthPlan', url: '/wealthplan' },
          {
            title: 'WealthPlan Attraction Dashboard',
            url: '/wealthplan/referral/dashboard',
          },
        ]}
        RightComponent={
          <div className='ml-auto'>
            <FontAwesomeIcon
              className='text-gray-700 hover:cursor-pointer hover:animate-wiggle'
              onClick={() => setIsOpen(true)}
              size='xl'
              icon={faBell}
              aria-label='notification_bell'
              data-testid='notification_bell'
            />
          </div>
        }
      >
        <div className='p-4 pt-5 h-full'>
          <ZenResourceContainer
            isEmpty={false}
            loading={false}
            resourceName='WealthPlan Attraction Dashboard'
          >
            <ZenResourceIndexContainer
              columns={columns}
              header='WealthPlan Attraction Dashboard'
              resourceName='wealthPlan'
              initialSort={{ emailSentTimeAt: 'desc' }}
              pageSize={20}
              fetchData={async (req) => {
                const sortKey = Object.keys(req.sortBy || {})[0];
                const sortType = Object.values(req.sortBy || {})[0];
                const { data } = await new AgentWealthManagementApi(
                  await getWealthManagementConfiguration(),
                ).listReferrals(
                  userDetail?.id!,
                  req.page,
                  req.pageSize,
                  WealthPlanSortByTypeEnum[sortKey!],
                  WealthPlanSortDirectionTypeEnum[sortType!],
                );
                return {
                  data: data.pageOfRecords || [],
                  total: data.pageOfRecords?.length || 0,
                };
              }}
              hideFilters
            />
          </ZenResourceContainer>
        </div>
        <WealthPlanNotification
          isOpen={isOpen}
          close={() => setIsOpen(false)}
        />
      </ZenPageLayout>
    </ZenRoute>
  );
};
export default WealthPlanCRMDashboard;
