import ZenResourceContainer from '../../Zen/ZenResourceContainer';
import useFetchWalletPartnershipsAndToken from '../useFetchWalletPartnershipsAndToken';

interface WalletDebitCardProps {
  debitAccountId?: string;
}

const WalletDebitCard: React.FC<WalletDebitCardProps> = ({
  debitAccountId,
}) => {
  const { isLoading, account, unitToken } = useFetchWalletPartnershipsAndToken(
    debitAccountId,
  );

  const cardId = account?.cards?.[0]?.externalId;

  if (!cardId) {
    return null;
  }

  return (
    <div className='rounded-xl bg-white shadow-pop-over py-4'>
      <ZenResourceContainer
        loading={isLoading}
        isEmpty={!unitToken?.token}
        resourceName='card'
      >
        {/* @ts-ignore */}
        <unit-elements-card
          card-id={cardId}
          customer-token={unitToken?.token}
          theme='https://ui.s.unit.sh/resources/4127/themes/737cf4f4-dec2-40f4-a947-d63b0d512d7c.json'
        />
      </ZenResourceContainer>
    </div>
  );
};

export default WalletDebitCard;
