import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { OfficePreviewResponse } from '../../../openapi/yenta';
import { Button } from '../../commons/Button';
import { Modal } from '../../commons/Modal';
import { Select } from '../../commons/Select';

type ConversationSelectOfficeModalProps = {
  opened: boolean;
  offices?: OfficePreviewResponse[];
  isLoading: boolean;
  onClose(): void;
  onChange(officeId: string): void;
  onConfirm(): void;
};

const ConversationSelectOfficeModal: React.FC<ConversationSelectOfficeModalProps> = ({
  opened,
  offices,
  isLoading,
  onClose,
  onConfirm,
  onChange,
}) => {
  const [officeId, setOfficeId] = useState<string>('');

  const options = useMemo(
    () =>
      offices?.map((office) => ({
        label: office.name!,
        value: office.id!,
      })) ?? [],
    [offices],
  );

  const handleClose = useCallback(() => {
    setOfficeId('');
    onClose();
  }, [onClose]);

  // TODO: refactor - quick fix for the case when there is only one office
  useEffect(() => {
    if (opened && options.length === 1) {
      onChange(options[0].value);
      onConfirm();
      handleClose();
    }
  }, [onChange, handleClose, onConfirm, opened, options]);

  return (
    <Modal onClose={handleClose} opened={opened}>
      <Modal.Header>
        <p className='text-center'>Select office</p>
      </Modal.Header>
      <Modal.Content>
        <Select
          data={options}
          onChange={(value) => {
            onChange(value!);
            setOfficeId(value!);
          }}
          value={officeId}
          placeholder='Select office'
        />
      </Modal.Content>
      <Modal.Footer>
        <Button
          disabled={isLoading || !officeId}
          loading={isLoading}
          className='px-10'
          onClick={() => {
            onConfirm();
            setOfficeId('');
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConversationSelectOfficeModal;
