import { useHistory } from 'react-router-dom';
import { faCloudExclamation } from '@fortawesome/pro-solid-svg-icons';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';

interface ZenFeatureFlagsProps {}

const KafkaRetryDashBoard: React.FC<ZenFeatureFlagsProps> = () => {
  const history = useHistory();

  return (
    <div>
      <ZenResourceLinkCard
        title='Kafka Retry Dashboard'
        onClick={() => history.push('/kafka-dashboard/consumer')}
        icon={faCloudExclamation}
        iconSize='2x'
      />
    </div>
  );
};

export default KafkaRetryDashBoard;
