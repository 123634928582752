import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNumber } from 'lodash';
import {
  EligibleCreditOfferingDto,
  FeeStructureDtoTypeEnum,
} from '../../../openapi/wallet';
import { formatMoneyValue } from '../../../utils/CurrencyUtils';
import Popper from '../../Popper';
import ZenButton from '../../Zen/ZenButton';

interface WalletOfferDetailsModalProps {
  offer: EligibleCreditOfferingDto;
  isOpen: boolean;
  onAgree(): void;
  onClose(): void;
}

const WalletOfferDetailsModal: React.FC<WalletOfferDetailsModalProps> = ({
  offer,
  isOpen,
  onAgree,
  onClose,
}) => {
  if (!isOpen || !offer?.creditOffering) {
    return null;
  }

  const { creditLimit } = offer;
  const { apr, gracePeriodDays, minPaymentAmount, fees } = offer.creditOffering;

  const fixRateFee = fees?.find(
    (fee) => fee.type === FeeStructureDtoTypeEnum.Fixed,
  )?.rate;

  return (
    <div className='fixed inset-0 z-50 flex justify-center items-center overflow-y-auto'>
      <div
        className='absolute inset-0 bg-primary-dark bg-opacity-40'
        role='button'
        onClick={onClose}
      />

      <div className='relative flex flex-col w-11/12 max-w-2xl bg-white rounded-lg overflow-y-auto max-h-[95%]'>
        {/* Header */}
        <div className='w-full px-6 py-4 flex justify-between items-center bg-rezen-blue-100'>
          <p className='font-inter font-medium text-base text-primary-dark'>
            Offer Details
          </p>
          <FontAwesomeIcon
            icon={faCircleXmark}
            className='text-xl text-regent-400 cursor-pointer'
            onClick={onClose}
            size='xl'
          />
        </div>

        {/* Content */}
        <div className='flex flex-col gap-6 p-6'>
          <div className='flex flex-col items-start gap-4'>
            <p className='font-inter font-medium text-2xl'>You’re Approved!</p>
            <div className='flex items-center gap-8'>
              <div className='flex flex-col gap-2'>
                <p className='font-inter font-normal text-regent-600 text-base'>
                  Credit Limit
                </p>
                <p className='font-inter font-semibold text-xl text-primary-dark'>
                  {formatMoneyValue(creditLimit)}
                </p>
              </div>
            </div>
            <p className='font-inter font-light text-lg text-primary-dark'>
              You have been approved for a credit limit of{' '}
              <span className='font-inter font-semibold text-lg'>
                {formatMoneyValue(creditLimit)}
              </span>
              {'. '}
              Accept the offer to begin requesting funds.
            </p>
          </div>

          <p className='-mb-2 font-inter text-base text-primary-dark'>
            Review Offer
          </p>

          {/* Offer Details */}
          <div className='flex flex-col gap-4 p-4 rounded-xl bg-regent-200'>
            <div className='flex justify-between pb-2 items-center border-b border-regent-400'>
              <p className='font-inter font-normal text-base text-regent-600'>
                Grace Period
              </p>
              <p className='font-inter font-light text-base text-primary-dark'>
                {isNumber(gracePeriodDays) ? `${gracePeriodDays} days` : 'N/A'}
              </p>
            </div>
            <div className='flex justify-between pb-2 items-center border-b border-regent-400'>
              <p className='font-inter font-normal text-base text-regent-600'>
                Statement Generation
              </p>
              <p className='font-inter font-light text-base text-primary-dark'>
                Monthly
              </p>
            </div>
            <div className='flex justify-between pb-2 items-center border-b border-regent-400'>
              <div className='flex items-center gap-2'>
                <p className='font-inter font-normal text-base text-regent-600'>
                  APR
                </p>

                <Popper
                  popperComponent={
                    <div className='font-primary-regular text-sm text-white px-2 max-w-[260px]'>
                      Annual Percentage Rate
                    </div>
                  }
                  config={{ placement: 'top' }}
                >
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className='-mb-0.5 text-base text-regent-600 cursor-pointer'
                  />
                </Popper>
              </div>
              <p className='font-inter font-light text-base text-right text-primary-dark'>
                {isNumber(apr) ? `${apr}%` : 'N/A'}
              </p>
            </div>
            <div className='flex justify-between pb-2 items-center border-b border-regent-400'>
              <p className='font-inter font-normal text-base text-regent-600'>
                Minimum Payment Due
              </p>
              <p className='font-inter font-light text-base text-right text-primary-dark'>
                {isNumber(minPaymentAmount) ? `${minPaymentAmount}%` : 'N/A'}
              </p>
            </div>
            <div className='flex justify-between items-center'>
              <p className='font-inter font-normal text-base text-regent-600'>
                Late Payment Fee
              </p>
              <p className='font-inter font-light text-base text-right text-primary-dark'>
                {isNumber(fixRateFee) ? `$${fixRateFee}` : 'N/A'}
              </p>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className='flex p-6 justify-between items-center border-t border-regent-300 bg-white'>
          <ZenButton
            label='Agree And Continue'
            variant='primary'
            className='w-full h-11 font-inter bg-rezen-blue-600 border-rezen-blue-600 font-normal text-base'
            onClick={onAgree}
          />
        </div>
      </div>
    </div>
  );
};

export default WalletOfferDetailsModal;
