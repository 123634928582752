import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import useAgentsInfo from '../../hooks/useAgentsInfo';
import { TransactionResponse } from '../../openapi/arrakis';
import { RootState } from '../../types';
import BrokerOnly from '../auth/BrokerOnly';
import ExcludeBroker from '../auth/ExcludeBroker';
import { Button } from '../commons/Button';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import CallModal from './CallModal';
import ParticipantResourceTable from './ParticipantsResourceTable';

interface MultipleAgentCallonTransactionProps {
  transaction: TransactionResponse;
}
const MultipleAgentCallonTransaction: React.FC<MultipleAgentCallonTransactionProps> = () => {
  const [isOpen, updateIsOpen] = useState(false);
  const [opened, callModalActions] = useDisclosure(false);

  const {
    transaction: {
      transactionDetailResponse: { data },
    },
    auth: { userDetail, isAdmin, isBroker },
  } = useSelector((state: RootState) => state);

  const isAgent = !isAdmin && !isBroker;
  const paymentParticipants = useMemo(
    () => data?.paymentParticipants?.filter((p) => p.yentaId),
    [data?.paymentParticipants],
  );
  const otherParticipants = useMemo(
    () => data?.otherParticipants?.filter((p) => p.yentaId),
    [data?.otherParticipants],
  );
  const showCallBtn = useMemo(() => {
    if (!isAdmin) {
      return true;
    }
    return (
      data?.transactionOwner?.id === userDetail?.id ||
      !!paymentParticipants?.filter((p) => p.yentaId === userDetail?.id).length
    );
  }, [
    data?.transactionOwner?.id,
    isAdmin,
    paymentParticipants,
    userDetail?.id,
  ]);

  const agentIds = useMemo(() => {
    const ids: string[] = [];
    paymentParticipants?.forEach((p) => {
      ids.push(p.yentaId!);
    });
    otherParticipants?.forEach((p) => {
      ids.push(p.yentaId!);
    });
    return ids;
  }, [otherParticipants, paymentParticipants]);

  useAgentsInfo(agentIds);

  return (
    <div>
      {showCallBtn && (
        <Button
          variant='outline'
          className='px-4 rounded-lg'
          onClick={() => {
            updateIsOpen(true);
            callModalActions.open();
          }}
        >
          <FontAwesomeIcon icon={faPhone} />{' '}
          {isAgent ? 'Call Broker Team' : 'Call'}
        </Button>
      )}
      <ExcludeBroker>
        <CallModal onClose={callModalActions.close} opened={opened} />
      </ExcludeBroker>
      {isOpen && (
        <BrokerOnly>
          <ZenSidebarModal
            isOpen
            onClose={() => updateIsOpen(false)}
            title='Call Agents'
          >
            <div className='flex flex-col justify-between h-full'>
              <div className='my-2'>
                {!isEmpty(paymentParticipants) && (
                  <>
                    <span className='block text-zen-dark-12 text-sm px-4 py-2 bg-regent-200'>
                      PAYMENT PARTICIPANTS ({paymentParticipants?.length})
                    </span>
                    <ParticipantResourceTable
                      participants={paymentParticipants!}
                    />
                  </>
                )}

                {!isEmpty(otherParticipants) && (
                  <>
                    <span className='block text-zen-dark-12 text-xs px-4 py-2 bg-regent-200'>
                      OTHER PARTICIPANTS ({otherParticipants?.length})
                    </span>
                    <ParticipantResourceTable
                      participants={otherParticipants!}
                    />
                  </>
                )}
              </div>
              <div className='h-18 sticky bg-white bottom-0 w-full border-zen-light-gray-2 border flex justify-end p-3.5'>
                <Button
                  onClick={() => updateIsOpen(false)}
                  variant='outline'
                  className='px-4 rounded-lg'
                >
                  Cancel
                </Button>
              </div>
            </div>
          </ZenSidebarModal>
        </BrokerOnly>
      )}
    </div>
  );
};

export default MultipleAgentCallonTransaction;
