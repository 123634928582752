import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { values } from 'lodash';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DottedBackground from '../assets/icons/dottedBackground.svg';
import TamirPoleg from '../assets/img/tamirPoleg.png';
import { HookFormCheckboxInput } from '../components/commons/hookFormInputs/HookFormCheckboxInput';
import { HookFormPasswordInput } from '../components/commons/hookFormInputs/HookFormPasswordInput';
import { HookFormSelectInput } from '../components/commons/hookFormInputs/HookFormSelectInput';
import { HookFormTextInput } from '../components/commons/hookFormInputs/HookFormTextInput';
import IconButton from '../components/IconButton';
import LanyardCard from '../components/LanyardCard';
import ZenSimpleModal from '../components/Zen/Modal/ZenSimpleModal';
import ZenAlert from '../components/Zen/ZenAlert';
import ZenButton from '../components/Zen/ZenButton';
import ZenRoute from '../components/Zen/ZenRoute';
import config from '../config';
import {
  PRIVACY_POLICY_URL,
  TAMIRS_VIDEO_URL,
  TERMS_OF_SERVICE_URL,
} from '../constants/AncillaryConstants';
import useQueryParams from '../hooks/useQueryParams';
import { LoginRequest } from '../openapi/keymaker';
import {
  ApplicationControllerApi,
  CreateApplicationRequest,
  CreateApplicationRequestApplicationTypeEnum,
  SignUpRequestCountryEnum,
  UserControllerApi,
} from '../openapi/yenta';
import { useInvitation } from '../query/team/useInvitation';
import ErrorService from '../services/ErrorService';
import { fetchAuthUserDetail, loginUserOrThrow } from '../slices/AuthSlice';
import { AppDispatch } from '../types';
import { getErrorMessage } from '../utils/ErrorUtils';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../utils/StringUtils';
import { isInvitationExpired } from '../utils/TeamHelper';
import { InvitationEnum } from './JoinByInvitationRoute';
import { RegisterForm, registerFormSchema } from './MantineRegisterRoute.types';

interface Query {
  sponsorCode?: string;
  sponsorName?: string;
  loi?: string;
  invitationId?: string;
  invitationType?: InvitationEnum;
  firstName: string;
  lastName: string;
  email: string;
  team: string;
  country: SignUpRequestCountryEnum;
}

const ZenRegisterRoute: React.FC = () => {
  const history = useHistory();
  const {
    sponsorCode,
    sponsorName,
    loi,
    invitationId,
    invitationType,
    country,
    email,
    firstName,
    lastName,
    team,
  } = useQueryParams<Query>();
  const [serverError, setServerError] = useState<string>();
  const dispatch = useDispatch<AppDispatch>();
  const { refetch } = useInvitation({
    fnArgs: [invitationId!, invitationType!],
    options: { enabled: false },
  });

  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
  } = useForm<RegisterForm>({
    defaultValues: {
      country: country ?? null,
      emailAddress: email ?? null,
      firstName: firstName ?? null,
      lastName: lastName ?? null,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    resolver: zodResolver(registerFormSchema, {}, { mode: 'async' }),
  });

  const enteredEmailAddress = watch('emailAddress');
  const [playVideo, setPlayVideo] = useState<boolean>(false);

  const [
    isEmailAlreadyRegistered,
    setIsEmailAlreadyRegistered,
  ] = useState<boolean>(false);
  const isLoginModalOpen = !!invitationId && isEmailAlreadyRegistered;

  useEffect(() => {
    const isEmailTaken =
      errors.emailAddress?.message === 'Email is already taken';
    setIsEmailAlreadyRegistered(isEmailTaken);
  }, [errors.emailAddress?.message]);

  const onSubmit = async (values: RegisterForm) => {
    if (invitationId && invitationType) {
      const { data: invitation } = await refetch();

      if (isInvitationExpired(invitation)) {
        setServerError(
          `Team invitation ID ${invitationId} has been revoked. Please contact your team leader/admin for a new invitation link.`,
        );

        return;
      }
    }

    try {
      await new UserControllerApi(getYentaConfiguration()).signup({
        username: values.username,
        firstName: values.firstName,
        lastName: values.lastName,
        emailAddress: values.emailAddress,
        country: values.country as SignUpRequestCountryEnum,
        password: values.password,
      });

      (window as any).gtag('event', 'conversion', {
        send_to: `${config.gaTrackingId}/9fPnCMamhvMCEJDk_ZQD`,
      });

      const loginRequest: LoginRequest = {
        password: values.password,
        usernameOrEmail: values.emailAddress,
      };

      await dispatch(loginUserOrThrow(loginRequest));

      const createApplicationReq: CreateApplicationRequest = {
        applicationType: loi
          ? CreateApplicationRequestApplicationTypeEnum.LetterOfIntent
          : CreateApplicationRequestApplicationTypeEnum.Regular,
      };

      if (invitationId) {
        if (invitationType === InvitationEnum.EMAIL) {
          createApplicationReq.teamInvitationId = invitationId;
        } else if (invitationType === InvitationEnum.LINK) {
          createApplicationReq.genericTeamInvitationId = invitationId;
        }
      }

      await new ApplicationControllerApi(
        getYentaConfiguration(),
      ).createApplication(createApplicationReq);

      dispatch(fetchAuthUserDetail());

      history.push(
        `/onboarding/application-form?${qs.stringify({
          sponsorCode,
          sponsorName,
          loi,
        })}`,
      );
    } catch (e) {
      setServerError(
        e?.response?.data?.['com.real.commons.apierror.ApiError']?.message ??
          getErrorMessage(ErrorService.getErrorCode(e)),
      );
    }
  };

  return (
    <ZenRoute title='Registration'>
      <div className='grid grid-flow-row grid-cols-1 lg:grid-flow-col lg:grid-cols-2'>
        <div className='flex items-center justify-center min-h-screen max-h-max px-2 bg-gray-100 pt-36 lg:pt-10 lg:pb-0 pb-4'>
          <LanyardCard>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='bg-gray-100'>
                <div className='p-5 bg-white'>
                  <p className='text-xl font-zen-body font-semibold text-black md:mt-0 mt-4'>
                    Please fill out the form below to get started.
                  </p>
                  {serverError && (
                    <div className='mt-3'>
                      <ZenAlert
                        text={serverError}
                        variant='error'
                        icon={
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            className='text-lg'
                          />
                        }
                      />
                    </div>
                  )}
                  <div className='flex flex-row mt-2 space-x-4'>
                    <HookFormTextInput
                      control={control}
                      name='firstName'
                      label='First Name'
                      placeholder='Jane'
                      withAsterisk
                    />
                    <HookFormTextInput
                      control={control}
                      name='lastName'
                      label='Last Name'
                      placeholder='Doe'
                      withAsterisk
                    />
                  </div>
                  <div className='mt-2'>
                    <HookFormTextInput
                      control={control}
                      name='username'
                      label='Username'
                      placeholder='jane123'
                      withAsterisk
                    />
                  </div>
                  <div className='mt-2'>
                    <HookFormTextInput
                      name='emailAddress'
                      control={control}
                      label={
                        <span>
                          Email{' '}
                          <span className='text-grey-400 text-xs'>
                            (Use an email you can access after joining Real)
                          </span>
                        </span>
                      }
                      placeholder='jane@example.com'
                      withAsterisk
                    />
                  </div>
                  <div className='mt-2'>
                    <HookFormSelectInput
                      control={control}
                      name='country'
                      label='Country'
                      placeholder='Select your country'
                      data={values(SignUpRequestCountryEnum).map((country) => ({
                        value: country,
                        label: capitalizeEnum(country),
                      }))}
                      disabled={!!country}
                      withAsterisk
                    />
                  </div>
                  <div className='mt-2'>
                    <HookFormPasswordInput
                      control={control}
                      name='password'
                      label='Password'
                      placeholder='Please enter your password'
                      withAsterisk
                    />
                  </div>
                  <div className='mt-2'>
                    <HookFormPasswordInput
                      control={control}
                      name='confirmPassword'
                      label='Password Confirmation'
                      placeholder='Please re-enter your password'
                      withAsterisk
                    />
                  </div>
                  <div className='mt-4'>
                    <HookFormCheckboxInput
                      control={control}
                      name='terms'
                      label={
                        <small>
                          By checking this box, I agree to the{' '}
                          <a
                            className='underline font-primary-medium'
                            target='_blank'
                            rel='noreferrer'
                            href={TERMS_OF_SERVICE_URL}
                          >
                            Terms of Service
                          </a>{' '}
                          and{' '}
                          <a
                            className='underline font-primary-medium'
                            target='_blank'
                            rel='noreferrer'
                            href={PRIVACY_POLICY_URL}
                          >
                            Privacy Policy
                          </a>
                          .
                        </small>
                      }
                      size='sm'
                      aria-label='terms'
                      classNames={{ icon: 'border-0' }}
                    />
                  </div>
                  <div className='mt-2'>
                    <HookFormCheckboxInput
                      control={control}
                      name='permission'
                      label={
                        <small>
                          By checking this box, I consent by electronic
                          signature to receive calls and text messages from Real
                          Broker, LLC and its affiliates made by any means or
                          technology for informational, marketing, or any other
                          purposes at the phone number I provided above. I
                          understand that my consent to such calls and text
                          messages is not required to use the services of Real
                          Broker, LLC.
                        </small>
                      }
                      size='sm'
                      aria-label='permission'
                      classNames={{ icon: 'border-0' }}
                    />
                  </div>
                  <div className='mt-5'>
                    <ZenButton
                      label='Create Account'
                      type='submit'
                      isSubmitting={isSubmitting}
                      isDisabled={isSubmitting}
                      isFullWidth
                      onClick={() => {
                        if (serverError) {
                          clearErrors();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </LanyardCard>
        </div>
        <div className='lg:flex items-center justify-center hidden h-3/4'>
          <div className='flex flex-col'>
            <div className='z-10 flex flex-col mt-10'>
              <p className='text-4xl font-zen-body font-bold'>
                Hear from CEO Tamir Poleg
              </p>
              {playVideo ? (
                <div className='self-center'>
                  <video
                    className='pt-5'
                    src={TAMIRS_VIDEO_URL}
                    controls
                    autoPlay
                    muted
                    width={345}
                    height={217}
                  />
                </div>
              ) : (
                <div className='self-center'>
                  <img
                    className='pt-5 shadow-2xl'
                    src={TamirPoleg}
                    alt='tamirPoleg'
                  />
                  <div className='flex flex-row items-center ml-2 -mt-12 space-x-2'>
                    <FontAwesomeIcon
                      icon={faCirclePlay}
                      className='text-white mr-0.5'
                      size='2x'
                    />
                    <IconButton
                      label='Play'
                      variant='pill'
                      onClick={() => setPlayVideo(true)}
                      buttonStyle='bg-primary-blue'
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='fixed z-0 self-center'>
              <img src={DottedBackground} alt='lanyard' />
            </div>
          </div>
        </div>
      </div>
      <ZenSimpleModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsEmailAlreadyRegistered(false)}
        title='Email already exists'
      >
        <div className='p-10 flex justify-center'>
          <div className='w-full max-w-sm'>
            <p className='text-center text-lg mb-5'>
              It looks like you already have an account. Click the below button
              to login and join <strong>&quot;{team}&quot;</strong> team.
            </p>
            <ZenButton
              label='Login'
              isFullWidth
              onClick={() => {
                history.push(
                  `/login?${qs.stringify({
                    redirectTo: `/join/${invitationType}/${invitationId}`,
                    email: enteredEmailAddress,
                  })}`,
                );
              }}
            />
          </div>
        </div>
      </ZenSimpleModal>
    </ZenRoute>
  );
};

export default ZenRegisterRoute;
