import {
  faBuildings,
  faCircleDollar,
} from '@fortawesome/pro-regular-svg-icons';
import { faCalendarDays } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BankIcon from '../../../assets/img/bank_icon.png';
import useHideBrokerNumberFromAgent from '../../../hooks/useHideBrokerNumbersFromAgent';
import { RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import {
  capitalizeEnum,
  formatAddressObject,
  formatPhoneNumber,
} from '../../../utils/StringUtils';
import AdminOnly from '../../auth/AdminOnly';
import LabelValueDisplayCard from '../../office/LabelValueDisplayCard';
import OfficeGroupCard from '../../office/OfficeGroupCard';
import ProfileDisplayCard from '../../office/ProfileDisplayCard';
import StateOrProvinceIconCell from '../../table/Cells/StateOrProvinceIconCell';
import ZenOfficeTransactionTypeCell from '../../table/Cells/ZenOfficeTransactionTypeCell';
import OfficeTemplates from '../../transactions/CheckListAndRoadToSuccessManage/OfficeTemplates';
import ZenAgentListContainer from '../ZenAgentListContainer';
import ZenIconText from '../ZenIconText';
import ZenInformationCard from '../ZenInformationCard';
import ZenRoute from '../ZenRoute';

interface ZenOfficeDetailIndexRouteProps {
  officeId: string;
}

const ZenOfficeDetailIndexRoute: React.FC<ZenOfficeDetailIndexRouteProps> = ({
  officeId,
}) => {
  const history = useHistory();

  const {
    office: { officeDetail, officeGroupsById },
    mainMenu: { isCollapsed },
  } = useSelector((state: RootState) => state);

  const hideDesignatedBrokerNumber = useHideBrokerNumberFromAgent(
    officeDetail?.designatedBroker?.type!,
  );
  const hideManagingBrokerNumber = useHideBrokerNumberFromAgent(
    officeDetail?.managingBroker?.type!,
  );

  return (
    <ZenRoute title='Office Details'>
      <div className='w-full px-4 lg:pl-0 lg:pr-6 py-6 space-y-4'>
        <div
          className={cn('grid grid-cols-1 2xl:grid-cols-3 col-span-1 gap-2', {
            'lg:grid-cols-2': isCollapsed,
          })}
        >
          <div className='p-3 border border-zen-dark-4 rounded-[10px]'>
            <LabelValueDisplayCard
              label='Transaction Type'
              value={
                <ZenOfficeTransactionTypeCell
                  transactionType={officeDetail?.transactionType!}
                />
              }
              leftIcon={
                <FontAwesomeIcon
                  icon={faCircleDollar}
                  className='text-zen-dark-8'
                />
              }
              noPadding
            />
          </div>

          <div className='p-3 border border-zen-dark-4 rounded-[10px]'>
            <LabelValueDisplayCard
              label='Brokerage License ID'
              value={
                <div className='flex flex-col space-y-2'>
                  <p className='font-zen-body font-normal text-base text-zen-dark-9'>
                    {officeDetail?.brokerageLicenseNumber || 'N/A'}
                  </p>
                  <ZenIconText
                    text={`Expires: ${
                      !!officeDetail?.licenseExpirationDate
                        ? DateTime.fromISO(
                            officeDetail?.licenseExpirationDate,
                          ).toFormat('LL/dd/yyyy')
                        : 'N/A'
                    }`}
                    icon={
                      <FontAwesomeIcon
                        icon={faCalendarDays}
                        className='text-zen-dark-7'
                      />
                    }
                    variant='small'
                    textClassName='font-medium text-xs text-zen-dark-7'
                  />
                </div>
              }
              leftIcon={
                <FontAwesomeIcon
                  icon={faBuildings}
                  className='text-zen-dark-8'
                />
              }
              noPadding
            />
          </div>
          <div className='p-3 border border-zen-dark-4 rounded-[10px]'>
            <LabelValueDisplayCard
              label='Payment System'
              value={capitalizeEnum(officeDetail?.paymentSystem!)}
              leftIcon={
                <FontAwesomeIcon
                  icon={faCircleDollar}
                  className='text-zen-dark-8'
                />
              }
              noPadding
            />
          </div>
        </div>
        <div
          className={cn('grid 2xl:grid-cols-2 grid-cols-1 gap-4', {
            'lg:grid-cols-2': isCollapsed,
          })}
        >
          {!!officeGroupsById[officeId]?.groups?.length &&
            officeGroupsById[officeId]?.groups?.map((group) => {
              return (
                <OfficeGroupCard
                  key={group?.groupName}
                  group={group}
                  officeId={officeId}
                  officeState={
                    officeDetail?.administrativeArea?.stateOrProvince!
                  }
                />
              );
            })}
          <ProfileDisplayCard
            title='Designated Broker'
            name={officeDetail?.designatedBroker?.fullName}
            imageUrl={officeDetail?.designatedBroker?.avatar}
            emailAddress={officeDetail?.designatedBroker?.emailAddress}
            phoneNumber={
              !hideDesignatedBrokerNumber
                ? formatPhoneNumber(
                    officeDetail?.designatedBroker?.phoneNumber,
                  )!
                : undefined
            }
            profileLink={() =>
              history.push(`/people/${officeDetail?.designatedBroker?.id}`)
            }
          />
          {officeDetail?.managingBroker && (
            <ProfileDisplayCard
              title='Manager'
              name={officeDetail?.managingBroker?.fullName}
              imageUrl={officeDetail?.managingBroker?.avatar}
              emailAddress={officeDetail?.managingBroker?.emailAddress}
              phoneNumber={
                !hideManagingBrokerNumber
                  ? formatPhoneNumber(
                      officeDetail?.managingBroker?.phoneNumber,
                    )!
                  : undefined
              }
              profileLink={() =>
                history.push(`/people/${officeDetail?.managingBroker?.id}`)
              }
            />
          )}
        </div>
        <ZenInformationCard title='Banking Information' titleIcon={BankIcon}>
          <div className='grid md:grid-cols-2 grid-cols-1'>
            <LabelValueDisplayCard
              label='Bank Name'
              value={officeDetail?.bankAccount?.bankName! ?? 'N/A'}
              border='right'
            />
            <LabelValueDisplayCard
              label='Account Number'
              value={officeDetail?.bankAccount?.accountNumber ?? 'N/A'}
            />
          </div>
          <div className='grid md:grid-cols-2 grid-cols-1 border-y'>
            <LabelValueDisplayCard
              label='Routing Number (For ACH)'
              value={officeDetail?.bankAccount?.bankRoutingNumber! ?? 'N/A'}
              border='right'
            />
            <LabelValueDisplayCard
              label='Routing Number (For Wires)'
              value={
                (officeDetail?.bankAccount?.wireRoutingNumber! ||
                  officeDetail!.bankAccount!.bankRoutingNumber!) ??
                'N/A'
              }
            />
          </div>
          <div className='grid md:grid-cols-2 grid-cols-1'>
            <LabelValueDisplayCard
              label='Payable To'
              value={officeDetail?.company?.name ?? 'N/A'}
            />
          </div>
        </ZenInformationCard>
        {officeDetail?.trustAccount && officeDetail?.acceptsEscrow && (
          <ZenInformationCard title='Trust Account' titleIcon={BankIcon}>
            <div className='grid md:grid-cols-2 grid-cols-1'>
              <LabelValueDisplayCard
                label='Bank Name'
                value={officeDetail?.trustAccount?.bankName! ?? 'N/A'}
                border='right'
              />
              <LabelValueDisplayCard
                label='Account Number'
                value={officeDetail?.trustAccount?.accountNumber! ?? 'N/A'}
              />
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1 border-y'>
              <LabelValueDisplayCard
                label='Routing Number (For ACH)'
                value={officeDetail?.trustAccount?.bankRoutingNumber! ?? 'N/A'}
                border='right'
              />
              <LabelValueDisplayCard
                label='Routing Number (For Wires)'
                value={
                  (officeDetail?.trustAccount?.wireRoutingNumber! ||
                    officeDetail?.trustAccount?.bankRoutingNumber!) ??
                  'N/A'
                }
              />
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1'>
              <LabelValueDisplayCard
                label='Payable To'
                value={officeDetail?.company?.name ?? 'N/A'}
              />
            </div>
          </ZenInformationCard>
        )}
        {officeDetail?.company && (
          <ZenInformationCard title='Company Details' titleIcon={BankIcon}>
            <div className='grid md:grid-cols-2 grid-cols-1'>
              <LabelValueDisplayCard
                label='Name'
                value={officeDetail?.company?.name! ?? 'N/A'}
                border='right'
              />
              <LabelValueDisplayCard
                label='EIN'
                value={officeDetail?.company?.ein! ?? 'N/A'}
              />
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1 border-y'>
              <LabelValueDisplayCard
                label='Address'
                value={
                  formatAddressObject(officeDetail?.company?.address!) ?? 'N/A'
                }
                border='right'
              />
              <LabelValueDisplayCard
                label='State / Province'
                value={
                  <StateOrProvinceIconCell
                    state={
                      officeDetail?.company?.administrativeArea
                        ?.stateOrProvince!
                    }
                  />
                }
              />
            </div>
          </ZenInformationCard>
        )}
        <AdminOnly>
          <ZenAgentListContainer officeId={[officeId]} />
        </AdminOnly>
        <AdminOnly>
          <OfficeTemplates officeId={officeId} />
        </AdminOnly>
      </div>
    </ZenRoute>
  );
};

export default ZenOfficeDetailIndexRoute;
