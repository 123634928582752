import ZenResourceContainer from '../../Zen/ZenResourceContainer';
import useFetchWalletPartnershipsAndToken from '../useFetchWalletPartnershipsAndToken';

interface WalletAccountDetailsProps {
  debitAccountId?: string;
}

const WalletAccountDetails: React.FC<WalletAccountDetailsProps> = ({
  debitAccountId,
}) => {
  const { isLoading, account, unitToken } = useFetchWalletPartnershipsAndToken(
    debitAccountId,
  );

  return (
    <div className='mb-7 bg-white rounded-xl shadow-pop-over'>
      <ZenResourceContainer
        loading={isLoading}
        isEmpty={!unitToken?.token}
        resourceName='account'
      >
        {/* @ts-ignore */}
        <unit-elements-account
          account-id={account?.externalId}
          customer-token={unitToken?.token}
          hide-selection-menu-button
          theme='https://ui.s.unit.sh/resources/4127/themes/44b13553-aa88-4925-b039-017ac5399e65.json'
        />
      </ZenResourceContainer>
    </div>
  );
};

export default WalletAccountDetails;
