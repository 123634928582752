import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CellProps, Column, Row } from 'react-table';
import { ReactComponent as ZenCopyIcon } from '../../assets/icons/zen/zen-copy.svg';
import {
  TransactionResponse,
  TransactionResponseCountryEnum,
} from '../../openapi/arrakis';
import { createTransactionBuilder } from '../../slices/TransactionBuilderSlice';
import { saveTransactionPaginatedResultsByIdWithFilters } from '../../slices/TransactionSlice';
import { AppDispatch } from '../../types';
import { isSmScreen } from '../../utils/BrowserUtils';
import { displayAmount } from '../../utils/CurrencyUtils';
import { getTransactionSystemActualClosingDate } from '../../utils/TransactionHelper';
import {
  getPaginatedResults,
  getSearchableTransactions,
} from '../../utils/TransactionUtils';
import ZenBooleanButton from '../Agent/V2/ZenBooleanButton';
import CopyToClipboardHover from '../CopyToClipboardHover';
import ZenResourceIndexContainer from '../Zen/Containers/ZenResourceIndexContainer';
import ZenAddressCell from '../Zen/Table/Cell/ZenAddressCell';
import ZenDateCell from '../Zen/Table/Cell/ZenDateCell';
import ZenTransactionTypeCell from '../Zen/Table/Cell/ZenTransactionTypeCell';
import ZenTransactionStatus from '../Zen/Transaction/ZenTransactionStatus';
import ZenButton from '../Zen/ZenButton';
import ZenRoute from '../Zen/ZenRoute';
import NullableTextCell from '../table/Cells/NullableTextCell';
import ZenViewActionButtonCell from '../table/Cells/ZenViewActionButtonCell';
import AddressStateEnumSelectColumnFilter from '../table/Filters/AddressStateEnumSelectColumnFilter';
import DateColumnFilter from '../table/Filters/DateColumnFilter';
import NumberColumnFilter from '../table/Filters/NumberColumnFilter';
import TextColumnFilter from '../table/Filters/TextColumnFilter';
import TransactionCompliantToggleColumnFilter from '../table/Filters/TransactionCompliantToggleColumnFilter';
import TransactionStatusSelectColumnFilter from '../table/Filters/TransactionStatusSelectColumnFilter';
import TransactionTypeSelectColumnFilter from '../table/Filters/TransactionTypeSelectColumnFilter';

interface ZenTransactionResourceTableProps {
  search?: string;
  officeIds?: string[];
}

export const columns: Array<Column<TransactionResponse>> = [
  {
    Header: 'Transaction Code',
    accessor: 'code',
    Cell: ({ value, row: { original } }) => (
      <div className='inline-flex items-center space-x-2 border border-zen-dark-4 rounded-lg px-2'>
        <Link to={`/transactions/${original.id}`}>
          <div className='text-sm whitespace-nowrap'>{value}</div>
        </Link>
        <CopyToClipboardHover
          value={value!}
          copyIcon={<ZenCopyIcon className='text-zen-dark-9' />}
          copyToltipLabel='Copy Code'
        />
      </div>
    ),
    cardColSize: 7,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Type',
    accessor: 'transactionType',
    Cell: ({ value }) => <ZenTransactionTypeCell dealType={value!} />,
    Filter: TransactionTypeSelectColumnFilter,
    cardColSize: 5,
  },
  {
    Header: 'Address',
    accessor: 'address',
    id: 'street',
    Cell: ({ value }) => (
      <div className='w-56'>
        <ZenAddressCell address={value} />
      </div>
    ),
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Price',
    accessor: 'price',
    Cell: ({ value }) => (
      <div>
        {displayAmount(value, { hideCurrency: true })}
        <div className='text-sm	text-zen-gray-4'>{value?.currency}</div>
      </div>
    ),
    Filter: NumberColumnFilter,
    disableFilters: true,
  },
  {
    Header: 'Representing',
    accessor: (d) => d['transactionOwner']?.represents,
    Cell: ({ value }: CellProps<TransactionResponse, string>) => (
      <NullableTextCell text={value} />
    ),
    disableFilters: true,
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: 'Status',
    accessor: 'lifecycleState',
    Cell: ({ value, row: { original } }) => (
      <ZenTransactionStatus
        status={value?.state!}
        country={
          (original.address
            ?.country as unknown) as TransactionResponseCountryEnum
        }
        transactionType={original.transactionType}
      />
    ),
    Filter: TransactionStatusSelectColumnFilter,
  },
  {
    Header: (
      <span className='text-left w-[90px] whitespace-normal'>
        Estimated Closing Date
      </span>
    ),
    accessor: 'skySlopeEscrowClosingDate',
    Cell: ({ value }) => <ZenDateCell date={value!} />,
    Filter: DateColumnFilter,
    cardColSize: 6,
  },
  {
    Header: (
      <span className='text-left w-[90px] whitespace-normal'>
        System Closing Date
      </span>
    ),
    accessor: 'rezenClosedAt',
    Cell: ({ row: { original } }: CellProps<TransactionResponse>) => (
      <NullableTextCell
        text={getTransactionSystemActualClosingDate(original)}
      />
    ),
    Filter: DateColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Closing Date',
    accessor: 'skySlopeActualClosingDate',
    Cell: ({ value }) => value,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'City',
    accessor: 'address',
    id: 'city',
    Cell: ({ value }) => value?.city,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'State / Province',
    accessor: 'address',
    id: 'state',
    Cell: ({ value }) => value?.state,
    Filter: AddressStateEnumSelectColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Postal Code',
    accessor: 'address',
    id: 'zip',
    Cell: ({ value }) => value?.zip,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Marked Compliant',
    accessor: 'compliantAt',
    Cell: ({ value }) => (
      <div className='mt-2 md:mt-0'>
        <ZenBooleanButton value={!!value} />
      </div>
    ),
    Filter: TransactionCompliantToggleColumnFilter,
    disableSortBy: true,
  },
];

export const columnsWithAction: Array<Column<TransactionResponse>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<TransactionResponse> }) => (
      <Link to={`/transactions/${row.original.id}`}>
        <ZenViewActionButtonCell />
      </Link>
    ),
  },
  ...columns,
];

const ZenTransactionResourceTable: React.FC<ZenTransactionResourceTableProps> = ({
  search,
  officeIds,
}) => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  return (
    <ZenRoute title='My Transaction'>
      <div className='px-4 py-0 mt-5'>
        <ZenResourceIndexContainer<TransactionResponse>
          header='Transactions'
          columns={columnsWithAction}
          search={search}
          RightComponent={
            <div className='flex flex-row flex-grow justify-between space-x-3'>
              <ZenButton
                label='Create Referral'
                variant='primary-outline'
                onClick={() => history.push('/transaction/referral')}
              />
              <ZenButton
                label='Create Transaction'
                onClick={async () => {
                  const transactionBuilderId = await dispatch(
                    createTransactionBuilder(),
                  );
                  if (transactionBuilderId) {
                    history.push(`/transaction/create/${transactionBuilderId}`);
                  }
                }}
              />
            </div>
          }
          resourceName='Transactions'
          hiddenColumns={[
            'id',
            'zip',
            'city',
            'state',
            'street2',
            'skySlopeActualClosingDate',
          ]}
          fetchData={async (req, cancelToken) => {
            const res = await getSearchableTransactions(req, false, officeIds, {
              cancelToken,
            });

            dispatch(
              saveTransactionPaginatedResultsByIdWithFilters({
                hasNext: res.hasNext,
                totalCount: res.total,
                results: getPaginatedResults(
                  res.pageNumber!,
                  res.pageSize!,
                  res.data,
                ),
                filters: req,
                officeIds,
              }),
            );

            return res;
          }}
          showSearch={isSmScreen()}
          initialSort={{ skySlopeEscrowClosingDate: 'asc' }}
          stickyHeader
        />
      </div>
    </ZenRoute>
  );
};

export default ZenTransactionResourceTable;
