import {
  faCalendar,
  faHandHoldingDollar,
  faMoneyBill,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import {
  AgentResponseAccountCountryEnum,
  TeamResponse,
  TeamResponseCommissionDocumentApproverEnum,
} from '../../../openapi/yenta';
import { EnumMap, RootState, YesNoType } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { isTeamTypeEligibleForInstantPayments } from '../../../utils/TeamHelper';
import { getInstantCommissionName } from '../../../utils/TransactionHelper';
import AdminOnly from '../../auth/AdminOnly';
import ZenPill, { ZenPillVariant } from '../ZenPill';
import ZenYesNoButton from '../ZenYesNoButton';
import ZenTeamDetailHeaderInfoRow from './ZenTeamDetailHeaderInfoRow';
import ZenTeamsCodeCell from './ZenTeamsCodeCell';
import ZenTeamsType from './ZenTeamsType';

interface ZenTeamDetailHeaderSectionProps {
  teamDetailOverview: TeamResponse;
  country: AgentResponseAccountCountryEnum;
  totalMemberCount?: number;
}

const commisionApproverEnumMapVariant: EnumMap<
  TeamResponseCommissionDocumentApproverEnum,
  ZenPillVariant
> = {
  [TeamResponseCommissionDocumentApproverEnum.AnyTeamMembers]: 'success-dark',
  [TeamResponseCommissionDocumentApproverEnum.TeamLeader]: 'secondary-purple',
  [TeamResponseCommissionDocumentApproverEnum.TeamLeaderAndTeamAdmins]:
    'newestblue',
};

const ZenTeamDetailHeaderSection: React.FC<ZenTeamDetailHeaderSectionProps> = ({
  teamDetailOverview,
  country,
  totalMemberCount,
}) => {
  const { isAdmin } = useSelector((state: RootState) => state.auth);

  return (
    <header className='bg-zen-dark-13 p-2 md:p-4 lg:p-10 mt-2 lg:mt-6 mb-2 mx-4 rounded-2xl'>
      <div className='text-white'>
        <div
          className={classNames(
            'lg:px-3 text-white min-w-full',
            isAdmin ? 'space-y-2' : 'space-y-4',
          )}
        >
          <div className='flex flex-col lg:flex-row lg:text-left justify-between min-w-full lg:space-x-5 space-y-4 lg:space-y-0'>
            <div
              className={classNames(
                isAdmin
                  ? 'pt-2 lg:flex lg:space-x-6 space-y-2 lg:space-y-0'
                  : 'space-y-2',
              )}
            >
              <div
                className={classNames(
                  isAdmin
                    ? 'text-[25px] font-zen-body font-bold'
                    : 'text-[32px] font-zen-body font-bold',
                )}
              >
                {teamDetailOverview.name}
              </div>
              <AdminOnly>
                <div>
                  <ZenTeamsCodeCell
                    team={teamDetailOverview}
                    variant='secondary'
                  />
                </div>
              </AdminOnly>
            </div>
            <div>
              <ZenTeamsType type={teamDetailOverview.type!} isPill={false} />
            </div>
          </div>
          <div className='flex md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-4 lg:space-x-20 py-4 md:py-0'>
            <div
              className={classNames(
                isAdmin
                  ? 'space-y-4'
                  : 'flex flex-col md:flex-row md:items-center md:space-x-20 space-y-4 md:space-y-0',
              )}
            >
              <ZenTeamDetailHeaderInfoRow
                label={`${totalMemberCount} ${pluralize(
                  'Member',
                  totalMemberCount,
                )}`}
                leftIcon={
                  <FontAwesomeIcon
                    className='text-white'
                    icon={faUser}
                    size='xs'
                  />
                }
              />
              <ZenTeamDetailHeaderInfoRow
                label='Created On:'
                subLabel={DateTime.fromMillis(
                  teamDetailOverview.createdAt!,
                ).toFormat('MMMM dd, yyyy')}
                leftIcon={
                  <FontAwesomeIcon
                    className='text-white'
                    icon={faCalendar}
                    size='xs'
                  />
                }
              />
            </div>
            <AdminOnly>
              <div className='space-y-4'>
                {isTeamTypeEligibleForInstantPayments(
                  teamDetailOverview.type!,
                ) && (
                  <ZenTeamDetailHeaderInfoRow
                    label='Eligible For Instant Payments?'
                    leftIcon={
                      <FontAwesomeIcon
                        className='text-white'
                        icon={faHandHoldingDollar}
                        size='xs'
                      />
                    }
                    rightIcon={
                      <ZenYesNoButton
                        value={
                          teamDetailOverview.instantPaymentEligible!
                            ? YesNoType.YES
                            : YesNoType.NO
                        }
                      />
                    }
                  />
                )}
                <ZenTeamDetailHeaderInfoRow
                  label={`Eligible for ${getInstantCommissionName(country)}?`}
                  leftIcon={
                    <FontAwesomeIcon
                      className='text-white'
                      icon={faMoneyBill}
                      size='xs'
                    />
                  }
                  rightIcon={
                    <ZenYesNoButton
                      value={
                        teamDetailOverview.paidAtClosing!
                          ? YesNoType.YES
                          : YesNoType.NO
                      }
                    />
                  }
                />

                <ZenTeamDetailHeaderInfoRow
                  label='Commission Document Approver'
                  leftIcon={
                    <FontAwesomeIcon
                      className='text-white'
                      icon={faUser}
                      size='xs'
                    />
                  }
                  rightIcon={
                    <ZenPill
                      title={capitalizeEnum(
                        teamDetailOverview.commissionDocumentApprover!,
                      )}
                      textStyle='text-sm'
                      variant={
                        commisionApproverEnumMapVariant[
                          teamDetailOverview.commissionDocumentApprover!
                        ]
                      }
                    />
                  }
                />
              </div>
            </AdminOnly>
          </div>
        </div>
      </div>
    </header>
  );
};

export default ZenTeamDetailHeaderSection;
