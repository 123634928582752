import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MemberId, MemberIdTypeEnum } from '../../../openapi/yada';
import { useFetchMultipleOfficeVisibleGroupsByIds } from '../../../query/office/useOffice';
import { useCreateConversation } from '../../../query/roar/useRoar';
import { RootState } from '../../../types';
import { getConversationLink } from '../../../utils/ConversationUtils';
import { isBrokerTeam } from '../../../utils/OfficeUtils';
import { STATE_OR_PROVINCE_ABBREVIATIONS } from '../../../utils/StateUtils';
import ResourceContainer from '../../ResourceContainer';
import ZenAvatarStack from '../../Zen/ZenAvatarStack';

type CreateConversationSidebarAgentViewProps = {
  onClose: () => void;
  onCreate(id: string): void;
};

const CreateConversationSidebarAgentView: React.FC<CreateConversationSidebarAgentViewProps> = ({
  onClose,
  onCreate,
}) => {
  const history = useHistory();

  const userDetails = useSelector((state: RootState) => state.auth.userDetail);

  const { isLoading: isCreatingConversation, mutate } = useCreateConversation();

  const createConversation = (memberId: MemberId) => {
    mutate(
      {
        members: [
          { value: userDetails?.id!, type: MemberIdTypeEnum.User },
          memberId,
        ],
      },
      {
        onSuccess: (data) => {
          onClose();
          onCreate(data.id);
          history.push(getConversationLink(data.id));
        },
      },
    );
  };

  const officeIds = useMemo(() => {
    return userDetails?.offices?.map((office) => office.id!) ?? [];
  }, [userDetails?.offices]);

  const {
    isLoading,
    data: officeGroupsById,
  } = useFetchMultipleOfficeVisibleGroupsByIds(officeIds);

  return (
    <ResourceContainer
      loading={isLoading}
      isEmpty={false}
      resourceName='office'
    >
      <div className='flex flex-col space-y-2 h-[calc(100vh-82px)] overflow-y-auto'>
        {userDetails?.offices?.map((office) => {
          const groups = officeGroupsById[office.id!];
          const brokerTeams = groups?.groups?.filter(isBrokerTeam) ?? [];

          return (
            <div
              key={office.name}
              className='flex items-center space-x-1 hover:bg-regent-200 py-4 px-2 rounded-md'
            >
              <p className='flex-shrink font-inter text-[15px] font-medium text-primary-dark line-clamp-1'>
                {
                  STATE_OR_PROVINCE_ABBREVIATIONS[
                    office.administrativeArea?.stateOrProvince!
                  ]
                }{' '}
                -
              </p>
              {brokerTeams.map((group) => {
                return (
                  <button
                    key={group.groupName}
                    disabled={isCreatingConversation}
                    onClick={() =>
                      createConversation({
                        value: group.id!,
                        type: MemberIdTypeEnum.Group,
                      })
                    }
                    className='flex-grow flex items-center space-x-2 justify-between'
                  >
                    <p className='whitespace-wrap font-inter text-primary-dark font-normal'>
                      {group.groupName}
                    </p>
                    <ZenAvatarStack
                      size='xs'
                      avatars={(group.groupMembers ?? []).map((bt) => ({
                        name: bt.user?.fullName!,
                        avatar: bt.user?.avatar,
                        showPlaceholder: isEmpty(bt.user?.fullName),
                      }))}
                    />
                  </button>
                );
              })}
            </div>
          );
        })}
      </div>
    </ResourceContainer>
  );
};

export default CreateConversationSidebarAgentView;
