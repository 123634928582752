import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  AuthorizedPersonDtoTypeEnum,
  LoansAuthorizedUsersApi,
} from '../../openapi/atlantis';
import ErrorService from '../../services/ErrorService';
import { updateLoanRealEstateAgent } from '../../slices/MortgageSlice';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { AppDispatch, ISelectOption, RootState } from '../../types';
import { getFullName } from '../../utils/AgentHelper';
import { getAuthorizedPerson } from '../../utils/MortgageUtils';
import { getAtlantisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import { searchForAllRegisteredAgents } from '../../utils/TableUtils';
import ZenControlledAsyncSelectInput from '../Zen/Input/ZenControlledAsyncSelectInput';
import useAssignAuthorizedPersons from './hooks/useAssignAuthorizedPersons';

export interface MortgageDetailsRouteHeaderAuthorizedPersonsProps {}

interface FormData {
  realEstateAgent?: ISelectOption;
  loanOfficer?: ISelectOption;
  loanOfficerAssistant?: ISelectOption;
  processor?: ISelectOption;
  realOriginateLoanOfficer?: ISelectOption;
}

const MortgageDetailsRouteHeaderAuthorizedPersons: React.FC<MortgageDetailsRouteHeaderAuthorizedPersonsProps> = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    mortgage: {
      loanResponse: { data: loan },
    },
    auth: { isMortgageAdmin, isAdmin, isROMA, isLoanOfficer },
  } = useSelector((state: RootState) => state);
  const { control, watch } = useForm<FormData>({
    defaultValues: {
      realEstateAgent: loan?.agent
        ? (({
            value: loan?.agent?.id!,
            label: loan?.agent?.fullName,
          } as unknown) as ISelectOption)
        : undefined,
      loanOfficer: getAuthorizedPerson(
        loan?.authorizedPersons,
        AuthorizedPersonDtoTypeEnum.LoanOfficer,
      ),
      loanOfficerAssistant: getAuthorizedPerson(
        loan?.authorizedPersons,
        AuthorizedPersonDtoTypeEnum.LoanOfficerAssistant,
      ),
      processor: getAuthorizedPerson(
        loan?.authorizedPersons,
        AuthorizedPersonDtoTypeEnum.LoanProcessor,
      ),
      realOriginateLoanOfficer: getAuthorizedPerson(
        loan?.authorizedPersons,
        AuthorizedPersonDtoTypeEnum.RealOriginateLoanOfficer,
      ),
    },
  });

  const isReadOnly = !(isAdmin || isMortgageAdmin);

  const realEstateAgent = watch('realEstateAgent');

  //this is a custom hook that is used to assign authorized persons to the loan
  useAssignAuthorizedPersons(loan!, control);

  useEffect(() => {
    const isAgentValid = realEstateAgent?.value !== loan?.agent?.id;

    if (isAgentValid) {
      dispatch(updateLoanRealEstateAgent(loan?.id!, realEstateAgent?.value!));
    }
  }, [dispatch, loan, realEstateAgent]);

  const fetchRoles = async (
    type: AuthorizedPersonDtoTypeEnum[],
    search: string,
    page: number | undefined,
  ) => {
    if (page !== 0) {
      return [];
    }

    try {
      const { data } = await new LoansAuthorizedUsersApi(
        getAtlantisConfiguration(),
      ).getAllAuthorizedPersons(type);

      const persons = (data || []).map((person) => ({
        value: person.id,
        label: getFullName(person) || 'N/A',
      }));

      return persons.filter((option) =>
        option.label.toLowerCase().includes(search.toLowerCase()),
      );
    } catch (e) {
      ErrorService.notify(`Unable to search for ${capitalizeEnum(type[0])}`, e);
      dispatch(
        showErrorToast(
          'An unexpected error occurred.',
          `We were unable to search for ${capitalizeEnum(
            type[0],
          )}. Please try again in a few moments or contact support.`,
        ),
      );
    }

    return [];
  };

  return (
    <div className='grid grid-cols-1 md:grid-cols-5 sm:grid-cols-3 gap-4 mb-8'>
      <div>
        <ZenControlledAsyncSelectInput<FormData, 'loanOfficer'>
          control={control}
          name='loanOfficer'
          placeholder='Search'
          label='Loan Officer'
          menuPosition='fixed'
          variant='transparent'
          disabled={isReadOnly}
          readOnly={isReadOnly}
          startAdornment={
            <div className='flex w-full h-full items-center justify-center pl-2'>
              <FontAwesomeIcon
                className='text-primary-blue'
                icon={regular('magnifying-glass')}
              />
            </div>
          }
          fetchData={async (search, page) => {
            return fetchRoles(
              [AuthorizedPersonDtoTypeEnum.LoanOfficer],
              search,
              page,
            );
          }}
        />
      </div>
      <div>
        <ZenControlledAsyncSelectInput<FormData, 'loanOfficerAssistant'>
          control={control}
          name='loanOfficerAssistant'
          placeholder='Search'
          label='Loan Officer Assistant'
          menuPosition='fixed'
          variant='transparent'
          disabled={!isLoanOfficer && !isMortgageAdmin}
          readOnly={!isLoanOfficer && !isMortgageAdmin}
          isClearable={isLoanOfficer || isMortgageAdmin}
          endAdornment={<div />}
          startAdornment={
            <div className='flex w-full h-full items-center justify-center pl-2'>
              <FontAwesomeIcon
                className='text-primary-blue'
                icon={regular('magnifying-glass')}
              />
            </div>
          }
          fetchData={async (search, page) => {
            return fetchRoles(
              [AuthorizedPersonDtoTypeEnum.LoanOfficerAssistant],
              search,
              page,
            );
          }}
        />
      </div>
      <div>
        <ZenControlledAsyncSelectInput<FormData, 'processor'>
          control={control}
          name='processor'
          placeholder='Search'
          label='Processor'
          menuPosition='fixed'
          variant='transparent'
          disabled={isReadOnly}
          readOnly={isReadOnly}
          isClearable={!isReadOnly}
          endAdornment={<div />}
          startAdornment={
            <div className='flex w-full h-full items-center justify-center pl-2'>
              <FontAwesomeIcon
                className='text-primary-blue'
                icon={regular('magnifying-glass')}
              />
            </div>
          }
          fetchData={async (search, page) => {
            return fetchRoles(
              [AuthorizedPersonDtoTypeEnum.LoanProcessor],
              search,
              page,
            );
          }}
        />
      </div>
      <div>
        <ZenControlledAsyncSelectInput<FormData, 'realOriginateLoanOfficer'>
          control={control}
          name='realOriginateLoanOfficer'
          placeholder='Search'
          label='Real Originate Mortgage Advisor'
          menuPosition='fixed'
          variant='transparent'
          disabled={isROMA}
          readOnly={isROMA}
          isClearable={!isROMA}
          endAdornment={<div />}
          startAdornment={
            <div className='flex w-full h-full items-center justify-center pl-2'>
              <FontAwesomeIcon
                className='text-primary-blue'
                icon={regular('magnifying-glass')}
              />
            </div>
          }
          fetchData={async (search, page) => {
            return fetchRoles(
              [AuthorizedPersonDtoTypeEnum.RealOriginateLoanOfficer],
              search,
              page,
            );
          }}
        />
      </div>
      <div>
        <ZenControlledAsyncSelectInput<FormData, 'realEstateAgent'>
          control={control}
          name='realEstateAgent'
          placeholder='Search'
          label='Real Estate Agent'
          menuPosition='fixed'
          variant='transparent'
          startAdornment={
            <div className='flex w-full h-full items-center justify-center pl-2'>
              <FontAwesomeIcon
                className='text-primary-blue'
                icon={regular('magnifying-glass')}
              />
            </div>
          }
          readOnly={isROMA}
          disabled={isROMA}
          fetchData={async (search, page) => {
            const agents = await dispatch(
              searchForAllRegisteredAgents(page, search),
            );

            const filteredAgents = (agents || []).filter((agent) => {
              return (
                agent?.firstName
                  ?.toLowerCase()
                  .includes(search?.toLowerCase()) ||
                agent?.lastName?.toLowerCase().includes(search?.toLowerCase())
              );
            });

            return filteredAgents?.map((agent) => ({
              value: agent?.id!,
              label: agent?.fullName ?? 'N/A',
            }));
          }}
        />
      </div>
    </div>
  );
};

export default MortgageDetailsRouteHeaderAuthorizedPersons;
