import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { RezenObject } from '../../../../openapi/arrakis';
import { capitalizeEnum } from '../../../../utils/StringUtils';

interface GeminiChecklistTagProps {
  refersTo: RezenObject;
}

const GeminiChecklistTag: React.FC<GeminiChecklistTagProps> = ({
  refersTo,
}) => {
  const history = useHistory();

  const checklistTitle = `${capitalizeEnum(refersTo.type)}: ${
    refersTo.metadata?.name
  }`;

  return (
    <div className='flex items-center rounded-md border border-regent-300 bg-regent-200 p-2 pr-2 gap-1.5 w-full'>
      <FontAwesomeIcon
        icon={faEye}
        size='sm'
        onClick={() => {
          history.push(`checklist?checklistItemId=${refersTo.id}`);
        }}
        className='text-primary-blue cursor-pointer'
      />
      <p className='font-inter text-sm font-normal break-words'>
        {checklistTitle}
      </p>
    </div>
  );
};

export default GeminiChecklistTag;
