import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { flatten } from 'lodash';
import { useMemo } from 'react';
import {
  InboxControllerApi,
  MessageCountRes,
  RezenObjectTypeEnum,
} from '../../openapi/yada';
import ErrorService from '../../services/ErrorService';
import { getYadaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { DEFAULT_CACHE_TIME, DEFAULT_STALE_TIME } from '../base/config';
import { queryKeys } from '../base/queryKeys';
import { useBaseMutation } from '../base/useBaseMutation';
import { QueryOptions, useSimpleQuery } from '../base/useSimpleQuery';

type InboxFilterKey = 'filter' | 'searchText' | 'targetType' | 'pageSize';

type TargetTypes = Parameters<
  typeof InboxControllerApi.prototype.getMessagesForUser
>[3];

export const useFetchInboxMessages = (
  userId: string,
  type: RezenObjectTypeEnum,
  filters: Partial<Record<InboxFilterKey, string | TargetTypes | number>>,
  options?: {
    enabled?: boolean;
    refetchOnWindowFocus?: boolean | 'always';
    staleTime?: number;
    cacheTime?: number;
  },
) => {
  const queryResult = useInfiniteQuery({
    queryKey: queryKeys.inbox.messages(type, userId, filters).queryKey,
    queryFn: async ({ pageParam }) => {
      const { data } = await new InboxControllerApi(
        getYadaConfiguration(),
      ).getMessagesForUser(
        userId,
        filters.filter as 'ALL' | 'UNREAD' | 'WITH_UPLOADS',
        filters.searchText as string,
        filters.targetType as TargetTypes,
        (filters.pageSize as number) ?? 20,
        pageParam,
      );
      return data;
    },
    enabled: !!userId,
    cacheTime: DEFAULT_CACHE_TIME,
    staleTime: DEFAULT_STALE_TIME,
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    onError(error: Error) {
      ErrorService.notify('Failed to fetch the inbox messages', error);
    },
    ...options,
  });

  const flattenedData = useMemo(() => {
    return flatten(queryResult.data?.pages.map((page) => page.messages));
  }, [queryResult.data?.pages]);

  return {
    data: flattenedData,
    isFetching: queryResult.isFetching,
    isInitialLoading: queryResult.isInitialLoading,
    isLoading: queryResult.isLoading,
    hasNextPage: queryResult.hasNextPage,
    fetchNextPage: queryResult.fetchNextPage,
    isFetchingNextPage: queryResult.isFetchingNextPage,
  };
};

export const useMarkMessageAsRead = (
  userId: string,
  targetType: RezenObjectTypeEnum,
) => {
  const queryClient = useQueryClient();

  return useBaseMutation({
    queryKey: queryKeys.inbox.messages(targetType, userId).queryKey,
    mutationFn: async (payload: {
      userId: string;
      targetType?: RezenObjectTypeEnum;
      targetIds?: string[];
    }) => {
      const targetIds = payload.targetIds;
      if (targetIds?.length && targetIds.length > 1) {
        await Promise.all(
          targetIds.map((targetId) =>
            new InboxControllerApi(getYadaConfiguration()).markMessageAsRead(
              payload.userId,
              payload.targetType,
              targetId,
            ),
          ),
        );
      } else {
        await new InboxControllerApi(getYadaConfiguration()).markMessageAsRead(
          payload.userId,
          payload.targetType,
          targetIds?.[0],
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        queryKeys.inbox.count(targetType, userId).queryKey,
      );
      queryClient.invalidateQueries(
        queryKeys.inbox
          .messages(RezenObjectTypeEnum.Conversation, userId)
          .queryKey.slice(0, 2), // removing searchParams otherwise this doesn't work
      );
    },
  });
};

export const useMarkMessageAsUnread = (
  userId: string,
  targetType: RezenObjectTypeEnum,
) => {
  const queryClient = useQueryClient();

  return useBaseMutation({
    queryKey: queryKeys.inbox.messages._def,
    mutationFn: async (payload: {
      userId: string;
      targetType?: RezenObjectTypeEnum;
      targetIds?: string[];
    }) => {
      const targetIds = payload.targetIds;
      if (targetIds?.length && targetIds.length > 1) {
        await Promise.all(
          targetIds.map((targetId) =>
            new InboxControllerApi(getYadaConfiguration()).markMessageAsUnread(
              payload.userId,
              payload.targetType,
              targetId,
            ),
          ),
        );
      } else {
        await new InboxControllerApi(
          getYadaConfiguration(),
        ).markMessageAsUnread(
          payload.userId,
          payload.targetType,
          targetIds?.[0],
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        queryKeys.inbox.count(targetType, userId).queryKey,
      );
      queryClient.invalidateQueries(
        queryKeys.inbox
          .messages(RezenObjectTypeEnum.Conversation, userId)
          .queryKey.slice(0, 2), // removing searchParams otherwise this doesn't work
      );
    },
  });
};

export const useFetchInboxCount = (
  userId: string,
  targetType: RezenObjectTypeEnum,
  options?: QueryOptions<MessageCountRes>,
) => {
  return useSimpleQuery({
    queryKey: queryKeys.inbox.count(targetType, userId).queryKey,
    queryFn: async () => {
      const { data } = await new InboxControllerApi(
        getYadaConfiguration(),
      ).getUnreadMessageCountForUser(userId, [targetType]);
      return data;
    },
    options: {
      toastErrorMessage: 'Failed to fetch the inbox count: ' + targetType,
      logErrorMessage: 'Failed to fetch the inbox count: ' + targetType,
      skipShowToastOnError: true,
      ...options,
    },
  });
};
