import React from 'react';
import DebitCard from '../../assets/img/wallet/debit-card.svg';
import { WalletDtoTerminalApplicationsInner } from '../../openapi/wallet';
import { cn } from '../../utils/classUtils';
import ZenButton from '../Zen/ZenButton';
import WalletCardStatusPill from './WalletCardStatusPill';

interface WalletDebitOfferingCardProps {
  onSignUpClick?: () => void;
  application?: WalletDtoTerminalApplicationsInner;
}

const WalletDebitOfferingCard: React.FC<WalletDebitOfferingCardProps> = ({
  onSignUpClick,
  application,
}) => {
  return (
    <div className='flex justify-between items-center p-3 md:w-[650px] border rounded-xl border-grey-300'>
      <div className='ml-3'>
        <p
          className={cn('font-inter font-medium text-lg text-primary-dark', {
            'text-regent-600': application,
          })}
        >
          Real Business Checking Account
        </p>

        <p
          className={cn(
            'mt-2 font-inter font-light text-sm text-primary-dark',
            { 'text-regent-600': application },
          )}
        >
          Automatic cashback offers. No monthly fee. No credit check
        </p>

        {application && (
          <div className='mt-4 flex justify-start gap-4 flex-col sm:flex-row sm:items-center'>
            <WalletCardStatusPill application={application} />
          </div>
        )}

        {onSignUpClick && (
          <ZenButton
            label='Sign up'
            className='w-24 h-10 mt-4 px-4 border-2 rounded-lg bg-white text-rezen-blue-600 font-inter font-medium text-base'
            onClick={onSignUpClick}
          />
        )}
      </div>

      <img
        src={DebitCard}
        alt='debit-card'
        className={cn('w-24', { 'opacity-30': application })}
      />
    </div>
  );
};

export default WalletDebitOfferingCard;
