import { AxiosRequestConfig } from 'axios';
import { get, isArray, isEmpty, isNumber, isObject, keys, map } from 'lodash';
import { DateTime } from 'luxon';
import { FilterValue, IdType, Row, TableState } from 'react-table';
import { ZenTableSelectionOption } from '../components/Zen/Containers/ZenResourceIndexContainer';
import {
  InitialSort,
  TableResourceFilter,
  TableSelectionOption,
} from '../containers/ResourceIndexContainer';
import {
  AgentCreditControllerApi,
  CommissionDocumentControllerApi,
  CommissionDocumentResponse,
  CreditResponse,
  InvoiceControllerApi,
  InvoiceResponse,
  InvoiceResponseStatusEnum,
  OutgoingPaymentsControllerApi,
  SearchOutgoingPaymentResponse,
} from '../openapi/arrakis';
import { ChecklistDefinitionApi } from '../openapi/sherlock';
import {
  AgentControllerApi,
  AgentDocumentResponse,
  AgentResponse,
  AgentResponseAgentStatusEnum,
  OfficeControllerApi,
  OfficeResponse,
  TeamControllerApi,
  TeamResponse,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import {
  ActiveInactiveType,
  AppThunk,
  DateFilterType,
  EnumMap,
  FilterColumnsToProcess,
  IPaginateReq,
  ISelectOption,
  Mapping,
  NumberFilterType,
  RowsSelectionAction,
  SearchActiveAgentCountryType,
  SearchActiveAgentSortByType,
  SearchActiveAgentSortDirectionType,
  SearchActiveAgentStateProvinceType,
  SearchAgentBoardIdType,
  SearchAgentCountryType,
  SearchAgentMLSIdType,
  SearchAgentOfficeIdType,
  SearchAgentSortByType,
  SearchAgentSortDirectionType,
  SearchAgentStateOrProvinceType,
  SearchAgentStatusType,
  SearchOfficesSortByType,
  SearchOfficesSortDirectionType,
  SearchOfficesTransactionType,
  SearchParam,
  SearchParamOperatorEnum,
  SearchTeamSortByType,
  SearchTeamSortDirectionType,
  SearchTeamStatusType,
  SearchTeamType,
  ZenInvoiceTableSortByType,
  ZenInvoiceTableSortDirectionType,
  ZenRowsSelectionAction,
} from '../types';
import {
  getArrakisConfiguration,
  getSherlockConfiguration,
  getYentaConfiguration,
} from './OpenapiConfigurationUtils';
import { capitalizeEnum } from './StringUtils';

export const PROCESS_FILTER_COLUMN = 'PROCESS_FILTER_COLUMN';

export type IFilter = {
  [id in string]: FilterValue;
};

interface SearchAgentFilters {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  id?: string;
  country?: SearchAgentCountryType;
  agentStatus?: SearchAgentStatusType;
  stateOrProvince?: SearchAgentStateOrProvinceType;
}

interface SearchAgentsRequest {
  page?: number;
  pageSize?: number;
  search?: string;
  sortDirection?: SearchAgentSortDirectionType;
  sortBy?: SearchAgentSortByType;
  filterBy?: SearchAgentFilters;
  officeIds?: SearchAgentOfficeIdType;
  mlsId?: SearchAgentMLSIdType;
  boardId?: SearchAgentBoardIdType;
  nonReportable?: boolean;
}

const processDatesFilter = (value: FilterValue, name: string): IFilter => {
  const config: {
    [type in DateFilterType]: { [x: string]: Array<SearchParam> };
  } = {
    [DateFilterType.inTheLast]: {
      [name]: [
        {
          value: DateTime.local()
            .minus({
              [value['duration_type'] || 'day']: value['duration'] || 0,
            })
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Gte,
        },
      ],
    },
    [DateFilterType.between]: {
      [name]: [
        {
          value: DateTime.fromISO(value['start_date'])
            .startOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Gte,
        },
        {
          value: DateTime.fromISO(value['end_date'])
            .endOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Lte,
        },
      ],
    },
    [DateFilterType.equals]: {
      [name]: [
        {
          value: DateTime.fromISO(value['start_date'])
            .startOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Gte,
        },
        {
          value: DateTime.fromISO(value['start_date'])
            .endOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Lte,
        },
      ],
    },
    [DateFilterType.isAfter]: {
      [name]: [
        {
          value: DateTime.fromISO(value['start_date'])
            .endOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Gt,
        },
      ],
    },
    [DateFilterType.isAfterOrOn]: {
      [name]: [
        {
          value: DateTime.fromISO(value['start_date'])
            .startOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Gte,
        },
      ],
    },
    [DateFilterType.isBefore]: {
      [name]: [
        {
          value: DateTime.fromISO(value['start_date'])
            .startOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Lt,
        },
      ],
    },
    [DateFilterType.isBeforeOrOn]: {
      [name]: [
        {
          value: DateTime.fromISO(value['start_date'])
            .endOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Lte,
        },
      ],
    },
  };

  return config[value['type'] as DateFilterType];
};

const processEnumsFilter = (value: FilterValue, name: string): IFilter => {
  return {
    [name]: {
      value: value,
      column: name,
      operator: SearchParamOperatorEnum.Eq,
    },
  };
};

const processMultiEnumsFilter = (
  selectedValues: ISelectOption[],
  name: string,
): IFilter => {
  const values = selectedValues.map((selected) => selected.value);

  return {
    [name]: {
      values,
      column: name,
      operator: SearchParamOperatorEnum.In,
    },
  };
};

const processStrictCaseFilter = (value: FilterValue, name: string): IFilter => {
  return {
    [name]: {
      value: value,
      column: name,
      operator: SearchParamOperatorEnum.Eq,
      stringStrictCase: true,
    },
  };
};

const processBooleanFilter = (value: FilterValue, name: string): IFilter => {
  return {
    [name]: {
      value: value,
      column: name,
      operator: SearchParamOperatorEnum.Eq,
    },
  };
};

const processBooleanNullableFilter = (
  value: FilterValue,
  name: string,
): IFilter => {
  return {
    [name]: {
      column: name,
      operator: !!value
        ? SearchParamOperatorEnum.IsNotNull
        : SearchParamOperatorEnum.IsNull,
      value: !!value,
    },
  };
};

const processNumbersFilter = (value: FilterValue, name: string): IFilter => {
  const config: {
    [type in NumberFilterType]: { [x: string]: Array<SearchParam> };
  } = {
    [NumberFilterType.equals]: {
      [name]: [
        {
          value: value['start_number'],
          column: name,
          operator: SearchParamOperatorEnum.Eq,
        },
      ],
    },
    [NumberFilterType.between]: {
      [name]: [
        {
          value: value['start_number'],
          column: name,
          operator: SearchParamOperatorEnum.Gte,
        },
        {
          value: value['end_number'],
          column: name,
          operator: SearchParamOperatorEnum.Lte,
        },
      ],
    },
    [NumberFilterType.greaterThan]: {
      [name]: [
        {
          value: value['start_number'],
          column: name,
          operator: SearchParamOperatorEnum.Gt,
        },
      ],
    },
    [NumberFilterType.greaterThanOrEqual]: {
      [name]: [
        {
          value: value['start_number'],
          column: name,
          operator: SearchParamOperatorEnum.Gte,
        },
      ],
    },
    [NumberFilterType.lessThan]: {
      [name]: [
        {
          value: value['start_number'],
          column: name,
          operator: SearchParamOperatorEnum.Lt,
        },
      ],
    },
    [NumberFilterType.lessThanOrEqual]: {
      [name]: [
        {
          value: value['start_number'],
          column: name,
          operator: SearchParamOperatorEnum.Lte,
        },
      ],
    },
  };

  return config[value['type'] as NumberFilterType];
};

export const getProcessedFilterData = (
  value: FilterValue,
  type: FilterColumnsToProcess,
): Array<IFilter> => {
  if (type === FilterColumnsToProcess.DATE) {
    return map(value, (value: FilterValue, name: string) =>
      processDatesFilter(value, name),
    );
  }

  if (type === FilterColumnsToProcess.ENUM) {
    return map(value, (value: FilterValue, name: string) =>
      processEnumsFilter(value, name),
    );
  }

  if (type === FilterColumnsToProcess.MULTI_ENUM) {
    return map(value, (value: FilterValue, name: string) =>
      processMultiEnumsFilter(value, name),
    );
  }

  if (type === FilterColumnsToProcess.STRICT_CASE) {
    return map(value, (value: FilterValue, name: string) =>
      processStrictCaseFilter(value, name),
    );
  }

  if (type === FilterColumnsToProcess.BOOLEAN) {
    return map(value, (value: FilterValue, name: string) =>
      processBooleanFilter(value, name),
    );
  }

  if (type === FilterColumnsToProcess.BOOLEAN_NULLABLE) {
    return map(value, (value: FilterValue, name: string) =>
      processBooleanNullableFilter(value, name),
    );
  }

  return map(value, (value: FilterValue, name: string) =>
    processNumbersFilter(value, name),
  );
};

export const mapToStateFilter = <D extends object>(
  filter?: TableResourceFilter<D>,
) =>
  map(filter, (value, id) => ({
    id,
    value,
  }));

export const mapToStateSortBy = <D extends object>(
  initialSort?: InitialSort<D>,
) =>
  map(initialSort, (value, id) => ({
    id,
    desc: value === 'desc',
  }));

export const getPageCount = (total: number, pageSize: number) =>
  Math.ceil(total / pageSize);

export const getJoinedColumnFilter = (
  filter: FilterValue,
  joinColumnsMap: { [column: string]: string },
) => {
  const joinTableEndIndex = filter.column.indexOf(':');
  const isJoinTableNamePresent = joinTableEndIndex !== -1;

  if (isJoinTableNamePresent) {
    const columnName = filter.column.slice(joinTableEndIndex + 1);
    const joinTableName = filter.column.slice(0, joinTableEndIndex);
    return { ...filter, column: columnName, joinColumn: joinTableName };
  }

  if (!!joinColumnsMap[filter.column]) {
    return { ...filter, joinColumn: joinColumnsMap[filter.column] };
  }

  return filter;
};

export const getPriorityWiseSortedData = (
  value1: string | number,
  value2: string | number,
  priority: (string | number)[],
) => {
  let rowValue1 = priority.indexOf(value1);
  let rowValue2 = priority.indexOf(value2);
  if (rowValue1 < rowValue2) {
    return -1;
  } else if (rowValue1 > rowValue2) {
    return 1;
  }
  return 0;
};

export const InvoiceSortDirectionTypeEnum: EnumMap<
  string,
  ZenInvoiceTableSortDirectionType
> = {
  asc: 'ASC',
  desc: 'DESC',
};

export const InvoiceSortByTypeEnum: EnumMap<
  string,
  ZenInvoiceTableSortByType
> = {
  firstName: ['FIRST_NAME', 'LAST_NAME'],
  transactionCode: ['TRANSACTION_CODE'],
  invoiceNumber: ['INVOICE_NUMBER'],
  invoicedAmount: ['AMOUNT'],
  status: ['STATUS'],
  company: ['COMPANY'],
  paymentSystemId: ['PAYMENT_SYSTEM_ID'],
  id: ['ID'],
};

export const getInvoiceTableFetchData = async (
  req: IPaginateReq<InvoiceResponse>,
  axiosOptions?: AxiosRequestConfig,
) => {
  const sortKey = Object.keys(req.sortBy || {})[0];
  const sortType = Object.values(req.sortBy || {})[0];
  const [
    invoiceAmount,
    invoiceAmountLTE,
    invoiceAmountGTE,
  ] = getComparableNumbers((req.filter?.invoicedAmount as SearchParam[]) || []);
  const { data } = await new InvoiceControllerApi(
    getArrakisConfiguration(),
  ).getInvoices(
    req.page,
    req.pageSize,
    InvoiceSortDirectionTypeEnum[sortType!],
    InvoiceSortByTypeEnum[sortKey!],
    req?.search,
    req.filter?.firstName as string,
    req.filter?.lastName as string,
    req.filter?.transactionCode as string,
    req.filter?.invoiceNumber as string,
    req.filter?.company as string,
    req.filter?.paymentSystemId as string,
    undefined,
    (req.filter?.id as SearchParam)?.value,
    invoiceAmount,
    invoiceAmountGTE,
    invoiceAmountLTE,
    (req.filter?.status as SearchParam)?.values as InvoiceResponseStatusEnum[],
    undefined,
    axiosOptions,
  );

  return {
    data: data?.results || [],
    total: data?.totalCount || 0,
  };
};

const TeamSortDirectionTypeEnum: EnumMap<
  string,
  SearchTeamSortDirectionType
> = {
  asc: 'ASC',
  desc: 'DESC',
};

export const TeamSortByTypeEnum: EnumMap<string, SearchTeamSortByType> = {
  id: ['ID'],
  name: ['NAME'],
  status: ['STATUS'],
  teamType: ['TEAM_TYPE'],
  leaderName: ['LEADER_NAME'],
  createdAt: ['CREATED_AT'],
};

export const teamSearchRequest = async (
  req: IPaginateReq<TeamResponse>,
  axiosOptions?: AxiosRequestConfig,
) => {
  const sortKey = Object.keys(req.sortBy || {})[0];
  const sortType = Object.values(req.sortBy || {})[0];
  const [createdAt, createdAtEnd] = getStartAndEndDate(
    (req.filter?.createdAt || []) as SearchParam[],
  );
  const { data } = await new TeamControllerApi(getYentaConfiguration()).search(
    req.page,
    req.pageSize ?? 20,
    TeamSortDirectionTypeEnum[sortType],
    TeamSortByTypeEnum[sortKey!],
    (req.filter?.id as SearchParam)?.value,
    req.filter?.name as string,
    req?.search,
    (req.filter?.status as SearchParam)?.value as SearchTeamStatusType,
    createdAt,
    createdAtEnd,
    (req.filter?.type as SearchParam)?.values?.[0] as SearchTeamType,
    axiosOptions,
  );

  return {
    data: data?.results || [],
    total: data?.totalCount || 0,
  };
};

interface SearchAgentsResponse {
  data: Array<AgentResponse>;
  total: number;
}

export const searchForAgents = async (
  params: SearchAgentsRequest,
): Promise<SearchAgentsResponse> => {
  const sortKey = Object.keys(params.sortBy || {})[0];
  const sortType = Object.values(params.sortBy || {})[0];

  const { data } = await new AgentControllerApi(
    await getYentaConfiguration(),
  ).searchAgents(
    params.page,
    params.pageSize || 50,
    SortDirectionTypeEnum[sortType!],
    AgentsSortByTypeEnum[sortKey!],
    params.search,
    params.filterBy?.firstName,
    params.filterBy?.lastName,
    params.filterBy?.emailAddress,
    params.filterBy?.phoneNumber,
    params.nonReportable,
    params.filterBy?.id,
    params.filterBy?.agentStatus!,
    params.filterBy?.country,
    params.filterBy?.stateOrProvince,
    params.officeIds,
    params.mlsId,
    params.boardId,
  );

  return {
    data: data?.results || [],
    total: data?.totalCount || 0,
  };
};

export const searchForAllRegisteredAgents = (
  page: number = 0,
  searchText: string | undefined,
): AppThunk<Promise<AgentResponse[]>> => async (dispatch) => {
  try {
    const { data } = await agentsTableFetchData({
      search: searchText,
      page,
      pageSize: 20,
      filter: {
        agentStatus: enumFilter(
          'agentStatus',
          AgentResponseAgentStatusEnum.Active,
        ),
      },
    });

    return data || [];
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem searching for active agents.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to search for active agents', e);
    return [];
  }
};

export const hydrateSearchRow = <T,>(columns: any[], row: any[]): T => {
  const model: any = {};

  row.forEach((value: any, index) => {
    model[columns[index]] = value?.value ?? value;
  });

  return model;
};

export const enumFilter = (name: string, value: string): SearchParam => {
  return {
    column: name,
    value,
    operator: SearchParamOperatorEnum.Eq,
  };
};

export const getSelectOptionsForEnums = (
  enumObj: any,
  transformSelectOptions: boolean = true,
): ISelectOption[] => {
  return keys(enumObj).map((key) => ({
    label: transformSelectOptions ? capitalizeEnum(enumObj[key]) : enumObj[key],
    value: enumObj[key],
  }));
};

export const getPaginateReqFromState = <D extends object>(
  state: TableState<D>,
): IPaginateReq<D> => ({
  page: state.pageIndex,
  pageSize: state.pageSize,
  search: state.globalFilter || undefined,
  filter: Object.assign(
    {},
    ...map(state.filters, ({ id, value }) => ({ [id]: value })),
  ),
  sortBy: Object.assign(
    {},
    ...map(state.sortBy, ({ id, desc }) => ({
      [id]: desc ? 'desc' : 'asc',
    })),
  ),
});

export const replaceWithTemplateText = (
  content: string,
  pattern: Mapping<string>,
) => {
  const regex = new RegExp(Object.keys(pattern).join('|'), 'gi');
  return content.replace(regex, (matched) => pattern[matched]!);
};

const getSelectionOptionReplacedWithTemplateText = <D extends object>(
  selectionRows: D[],
  option: TableSelectionOption<D>,
): RowsSelectionAction<D> => {
  const selection: RowsSelectionAction<D> = {
    label: `${option.label} (${selectionRows.length})`,
    onAction: option.onAction,
  };

  if (option.confirm) {
    selection.confirm =
      typeof option.confirm === 'function'
        ? option.confirm(selectionRows)
        : option.confirm;
  }

  if (option.isActionButtonEnabled) {
    selection.disabled = !option.isActionButtonEnabled(selectionRows);
  }

  return selection;
};

const getZenSelectionOptionReplacedWithTemplateText = <D extends object>(
  selectionRows: D[],
  option: ZenTableSelectionOption<D>,
): ZenRowsSelectionAction<D> => {
  const selection: ZenRowsSelectionAction<D> = {
    label: option.hideCount
      ? option.label
      : `${option.label} (${selectionRows.length})`,
    onAction: option.onAction,
    variant: option.variant,
  };

  if (option.confirm) {
    selection.confirm =
      typeof option.confirm === 'function'
        ? option.confirm(selectionRows)
        : option.confirm;
  }

  if (option.isActionButtonEnabled) {
    selection.disabled = !option.isActionButtonEnabled(selectionRows);
  }

  return selection;
};

export const getSelectionOptions = <D extends object>(
  selectionRows: D[],
  selectionOptions: TableSelectionOption<D>[],
): RowsSelectionAction<D>[] => {
  return selectionRows.length
    ? selectionOptions.map((option) =>
        getSelectionOptionReplacedWithTemplateText(selectionRows, option),
      )
    : [];
};

export const getZenSelectionOptions = <D extends object>(
  selectionRows: D[],
  selectionOptions: ZenTableSelectionOption<D>[],
): ZenRowsSelectionAction<D>[] => {
  return selectionRows.length
    ? selectionOptions.map((option) =>
        getZenSelectionOptionReplacedWithTemplateText(selectionRows, option),
      )
    : [];
};

export const fetchLatestCommissionDocumentsForTransaction = async (
  transactionId: string,
): Promise<CommissionDocumentResponse[]> => {
  const { data } = await new CommissionDocumentControllerApi(
    getArrakisConfiguration(),
  ).getAllCommissionDocumentsByTransactionId(transactionId);

  return data;
};

export const customFixedFilter = <D extends object>(
  rows: Row<D>[],
  [columnId]: IdType<D>[],
  filterValue: FilterValue,
): Row<D>[] => {
  if (!filterValue) return rows;

  if ((isObject(filterValue) as unknown) as SearchParam) {
    if (filterValue.operator === SearchParamOperatorEnum.In) {
      return rows.filter((row) =>
        filterValue.values.some(
          (value: FilterValue[]) => value === get(row.values, columnId),
        ),
      );
    }

    if (filterValue.operator === SearchParamOperatorEnum.Eq) {
      return rows.filter(
        (row) => filterValue.value === get(row.values, columnId),
      );
    }

    if (filterValue.operator === SearchParamOperatorEnum.Like) {
      return rows.filter((row) =>
        get(row.values, columnId).includes(filterValue.value),
      );
    }
  }

  if (isArray(filterValue)) {
    let filteredRows = [...rows];

    filterValue.forEach((value: SearchParam) => {
      filteredRows = filteredRows.filter((row) => {
        const fieldValue = get(row.values, columnId);
        const compareValue = !isNumber(fieldValue)
          ? DateTime.fromISO(fieldValue).toMillis()
          : (fieldValue as number);

        if (value.operator === SearchParamOperatorEnum.Gt) {
          return compareValue > ((value.value! as unknown) as number);
        } else if (value.operator === SearchParamOperatorEnum.Gte) {
          return compareValue >= ((value.value! as unknown) as number);
        } else if (value.operator === SearchParamOperatorEnum.Lt) {
          return compareValue < ((value.value! as unknown) as number);
        } else if (value.operator === SearchParamOperatorEnum.Lte) {
          return compareValue <= ((value.value! as unknown) as number);
        }

        return compareValue === ((value.value! as unknown) as number);
      });
    });

    return filteredRows;
  }

  return rows.filter((row) => get(row.values, columnId) === filterValue);
};

export const FIXED_TABLE_FILTER_TYPES = {
  numberFilter: (
    rows: any[],
    [id]: IdType<AgentDocumentResponse>,
    filterValue: FilterValue,
  ) => {
    return rows.filter((row) => {
      if (filterValue[0] && filterValue[1]) {
        return (
          row.values[id] - filterValue[0] >= 0 &&
          row.values[id] - filterValue[1] <= 0
        );
      }

      if (filterValue[0]) {
        return row.values[id] - filterValue[0] >= 0;
      }

      if (filterValue[1]) {
        return row.values[id] - filterValue[1] <= 0;
      }

      return true;
    });
  },
  dateFilter: (
    rows: any[],
    [id]: IdType<AgentDocumentResponse>,
    filterValue: FilterValue,
  ) => {
    const diffInDays = (d1: number, d2: string) =>
      DateTime.fromMillis(d1)
        .startOf('day')
        .diff(DateTime.fromISO(d2).startOf('day'), ['day']).days;

    return rows.filter((row) => {
      if (filterValue[0] && filterValue[1]) {
        return (
          diffInDays(row.values[id], filterValue[0]) >= 0 &&
          diffInDays(row.values[id], filterValue[1]) <= 0
        );
      }

      if (filterValue[0]) {
        return diffInDays(row.values[id], filterValue[0]) >= 0;
      }

      if (filterValue[1]) {
        return diffInDays(row.values[id], filterValue[1]) <= 0;
      }

      return true;
    });
  },
  multiDateFilter: (
    rows: any[],
    [id]: IdType<AgentDocumentResponse>,
    filterValue: FilterValue,
  ) => {
    const diffInDays = (d1: number, d2: string) =>
      DateTime.fromMillis(d1)
        .startOf('day')
        .diff(DateTime.fromISO(d2).startOf('day'), ['day']).days;

    return rows.filter((row) => {
      if (filterValue[0] && filterValue[1]) {
        return row.values[id]?.some(
          (fieldValue: number) =>
            diffInDays(fieldValue, filterValue[0]) >= 0 &&
            diffInDays(fieldValue, filterValue[1]) <= 0,
        );
      }

      if (filterValue[0]) {
        return row.values[id]?.some(
          (fieldValue: number) => diffInDays(fieldValue, filterValue[0]) >= 0,
        );
      }

      if (filterValue[1]) {
        return row.values[id]?.some(
          (fieldValue: number) => diffInDays(fieldValue, filterValue[1]) <= 0,
        );
      }

      return true;
    });
  },
  multiSelectFilter: (
    rows: any[],
    [id]: IdType<AgentDocumentResponse>,
    filterValue: FilterValue,
  ) => {
    if (!filterValue?.length) return rows;

    return rows.filter((row) => {
      if (isArray(row.values[id])) {
        return row.values[id]?.some((value: FilterValue) =>
          filterValue?.includes(value),
        );
      }

      return filterValue.includes(row.values[id]);
    });
  },
};

export const getStartAndEndDate = (
  dates: SearchParam[],
): [string | undefined, string | undefined] => {
  let startDate, endDate;

  if (!dates?.length) {
    return [undefined, undefined];
  }

  dates.forEach((date) => {
    if (date.operator === SearchParamOperatorEnum.Eq) {
      startDate = DateTime.fromMillis(parseInt(date.value!)).toFormat(
        'yyyy-LL-dd',
      );
      endDate = DateTime.fromMillis(parseInt(date.value!))
        .plus({ day: 1 })
        .toFormat('yyyy-LL-dd');
    } else if (date.operator === SearchParamOperatorEnum.Lte) {
      endDate = DateTime.fromMillis(parseInt(date.value!))
        .plus({ day: 1 })
        .toFormat('yyyy-LL-dd');
    } else if (date.operator === SearchParamOperatorEnum.Gte) {
      startDate = DateTime.fromMillis(parseInt(date.value!)).toFormat(
        'yyyy-LL-dd',
      );
    } else if (date.operator === SearchParamOperatorEnum.Lt) {
      endDate = DateTime.fromMillis(parseInt(date.value!)).toFormat(
        'yyyy-LL-dd',
      );
    } else if (date.operator === SearchParamOperatorEnum.Gt) {
      startDate = DateTime.fromMillis(parseInt(date.value!))
        .plus({ day: 1 })
        .toFormat('yyyy-LL-dd');
    }
  });

  if (!startDate) {
    startDate = '2000-01-01';
  }

  if (!endDate) {
    endDate = DateTime.local().plus({ day: 1 }).toFormat('yyyy-LL-dd');
  }

  return [startDate, endDate];
};

export const getFormattedFilterDates = (
  dates: SearchParam[],
): [string | undefined, string | undefined, string | undefined] => {
  if (!dates?.length) {
    return [undefined, undefined, undefined];
  }

  let startDate: string | undefined;
  let endDate: string | undefined;

  dates.forEach((date) => {
    const parsedValue = parseInt(date.value!);

    switch (date.operator) {
      case SearchParamOperatorEnum.Lte:
        endDate = DateTime.fromMillis(parsedValue).toFormat('yyyy-LL-dd');
        break;

      case SearchParamOperatorEnum.Gte:
        startDate = DateTime.fromMillis(parsedValue).toFormat('yyyy-LL-dd');
        break;

      case SearchParamOperatorEnum.Lt:
        endDate = DateTime.fromMillis(parsedValue)
          .minus({ day: 1 })
          .toFormat('yyyy-LL-dd');
        break;

      case SearchParamOperatorEnum.Gt:
        startDate = DateTime.fromMillis(parsedValue)
          .plus({ day: 1 })
          .toFormat('yyyy-LL-dd');
        break;
    }
  });

  if (startDate === endDate) {
    return [startDate, undefined, undefined];
  } else {
    return [undefined, startDate, endDate];
  }
};

export const getComparableNumbers = (
  numbers: SearchParam[],
): [number | undefined, number | undefined, number | undefined] => {
  let amount: number | undefined,
    lessThanNumber: number | undefined,
    greaterThanNumber: number | undefined;

  if (!numbers?.length) {
    return [undefined, undefined, undefined];
  }

  numbers.forEach((number) => {
    if (number.operator === SearchParamOperatorEnum.Eq) {
      amount = parseFloat(number?.value!);
    } else if (number.operator === SearchParamOperatorEnum.Lte) {
      lessThanNumber = parseFloat(number?.value!);
    } else if (number.operator === SearchParamOperatorEnum.Gte) {
      greaterThanNumber = parseFloat(number?.value!);
    } else if (number.operator === SearchParamOperatorEnum.Lt) {
      lessThanNumber = parseFloat(number?.value!) - 1;
    } else if (number.operator === SearchParamOperatorEnum.Gt) {
      greaterThanNumber = parseFloat(number?.value!) + 1;
    }
  });

  return [amount, lessThanNumber, greaterThanNumber];
};

export const isLastGroupMember = (row: any, rows: any[], index: number) => {
  if (
    index < rows.length - 1 &&
    row.original.metadata.originId ===
      rows[index + 1].original.metadata.originId
  ) {
    return true;
  }
  return false;
};

export const AgentsSortByTypeEnum: EnumMap<string, SearchAgentSortByType> = {
  id: ['ID'],
  firstName: ['FIRST_NAME'],
  lastName: ['LAST_NAME'],
  emailAddress: ['EMAIL_ADDRESS'],
  agentStatus: ['STATUS'],
  createdAt: ['CREATED_AT'],
  anniversaryDate: ['ANNIVERSARY_DATE'],
  accountCountry: ['ACCOUNT_COUNTRY'],
};

export const SortDirectionTypeEnum: EnumMap<
  string,
  SearchAgentSortDirectionType
> = {
  asc: 'ASC',
  desc: 'DESC',
};

export const ChecklistDefinitionApiSortKeyEnum: EnumMap<string, 'NAME'[]> = {
  name: ['NAME'],
};

export const ChecklistDefinitionApiSortTypeEnum: EnumMap<
  string,
  'ASC' | 'DESC'
> = {
  asc: 'ASC',
  desc: 'DESC',
};

export const fetchSearchableChecklistItems = async <D extends object>(
  req: IPaginateReq<D>,
) => {
  const sortKey = Object.keys(req.sortBy || {})[0];
  const sortType = Object.values(req.sortBy || 'asc')[0];
  const { data } = await new ChecklistDefinitionApi(
    getSherlockConfiguration(),
  ).search(
    ChecklistDefinitionApiSortKeyEnum[sortKey],
    ChecklistDefinitionApiSortTypeEnum[sortType!],
    req.page,
    req.pageSize,
    req.search,
  );

  return {
    data: data?.results || [],
    total: data?.totalCount || 0,
  };
};

interface SearchAgentsTableRequest extends AgentResponse {
  stateOrProvince?: SearchAgentStateOrProvinceType;
}

export const OfficesSortByTypeEnum: EnumMap<string, SearchOfficesSortByType> = {
  id: ['ID'],
  name: ['NAME'],
  transactionType: ['TRANSACTION_TYPE'],
  active: ['ACTIVE'],
};

const OfficesSortDirectionTypeEnum: EnumMap<
  string,
  SearchOfficesSortDirectionType
> = {
  asc: 'ASC',
  desc: 'DESC',
};

export const agentsTableFetchData = async (
  req: IPaginateReq<SearchAgentsTableRequest>,
  officeId?: string[] | undefined,
  mlsId?: string[] | undefined,
  boardId?: string[] | undefined,
  divisionIds?: string[] | undefined,
  axiosOptions?: AxiosRequestConfig,
) => {
  const sortKey = Object.keys(req.sortBy || {})[0];
  const sortType = Object.values(req.sortBy || {})[0];

  const { data } = await new AgentControllerApi(
    getYentaConfiguration(),
  ).searchAgents(
    req.page,
    req.pageSize,
    SortDirectionTypeEnum[sortType!],
    AgentsSortByTypeEnum[sortKey!],
    req.search,
    req.filter?.firstName as string,
    req.filter?.lastName as string,
    req.filter?.emailAddress as string,
    req.filter?.phoneNumber as string,
    req.filter?.nonReportable as boolean, // @ts-ignore
    req.filter?.id?.value as string,
    [
      // @ts-ignore
      req.filter?.agentStatus?.value as SearchAgentStatusType,
    ],
    [
      //@ts-ignore
      req.filter?.accountCountry?.value,
    ] as SearchAgentCountryType,
    req.filter?.stateOrProvince?.values as SearchAgentStateOrProvinceType,
    officeId,
    mlsId,
    boardId,
    divisionIds,
    axiosOptions,
  );

  return {
    data: data?.results || [],
    total: data?.totalCount || 0,
  };
};
export const searchActiveAgents = async (
  page: number = 1,
  country: SearchActiveAgentCountryType,
  pageSize: number = 20,
  name: string | any,
  stateOrProvince?: SearchActiveAgentStateProvinceType,
  sortDirection: SearchActiveAgentSortDirectionType = 'ASC',
  nonReportable: undefined | boolean[] = undefined,
) => {
  const sortByArray: SearchActiveAgentSortByType = ['FIRST_NAME', 'LAST_NAME'];
  const { data } = await new AgentControllerApi(
    getYentaConfiguration(),
  ).searchActiveAgents(
    page,
    pageSize,
    sortDirection,
    sortByArray,
    name,
    nonReportable,
    country,
    stateOrProvince,
  );

  return {
    data: data?.results || [],
  };
};

export const officeTableFetchData = async (
  req: IPaginateReq<OfficeResponse>,
  ids: string[] = [],
  axiosOptions?: AxiosRequestConfig,
) => {
  const sortKey = Object.keys(req.sortBy || {})[0];
  const sortType = Object.values(req.sortBy || {})[0];
  const officeStatus = (req?.filter?.active as SearchParam)?.value;
  const isActive = !!officeStatus
    ? officeStatus === ActiveInactiveType.ACTIVE
    : undefined;

  const { data } = await new OfficeControllerApi(
    getYentaConfiguration(),
  ).searchOffices(
    req.page,
    req.pageSize,
    OfficesSortDirectionTypeEnum[sortType!],
    OfficesSortByTypeEnum[sortKey!],
    req.search,
    req.filter?.name as string,
    !isEmpty(ids) ? ids : [(req.filter?.id as SearchParam)?.value!],
    req.filter?.phoneNumber as string,
    (req.filter?.transactionType as SearchOfficesTransactionType)
      ?.values as SearchOfficesTransactionType,
    undefined,
    undefined,
    undefined,
    isActive,
    undefined,
    axiosOptions,
  );

  return {
    data: data?.results || [],
    total: data?.totalCount || 0,
  };
};

export const delay = async (ms: number) => {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), ms));
};

export type SearchAgentCreditsParams = Parameters<
  typeof AgentCreditControllerApi.prototype.searchAgentCredits
>;

export const AgentCreditSortDirectionTypeEnum: EnumMap<
  string,
  SearchAgentCreditsParams[1]
> = {
  asc: 'ASC',
  desc: 'DESC',
};

export const AgentCreditSortByTypeEnum: EnumMap<
  string,
  SearchAgentCreditsParams[0]
> = {
  title: ['TITLE'],
  type: ['TYPE'],
  amount: ['AMOUNT'],
  issuedOn: ['ISSUED_ON'],
  issuerNote: ['ISSUER_NOTE'],
};

export const fetchAgentCredits = async (
  req: IPaginateReq<CreditResponse>,
  agentId: string,
  axiosOptions?: AxiosRequestConfig,
) => {
  const sortKey = Object.keys(req.sortBy || {})[0];
  const sortType = Object.values(req.sortBy || {})[0];
  const { data } = await new AgentCreditControllerApi(
    getArrakisConfiguration(),
  ).searchAgentCredits(
    AgentCreditSortByTypeEnum[sortKey!],
    AgentCreditSortDirectionTypeEnum[sortType!],
    req.page,
    req.pageSize,
    agentId,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    axiosOptions,
  );
  return data;
};

export const OutgoingPaymentSortByTypeEnum: EnumMap<
  string,
  Parameters<
    typeof OutgoingPaymentsControllerApi.prototype.searchOutgoingPayments
  >[0]
> = {
  company: ['COMPANY'],
  transactionCode: ['TRANSACTION_CODE'],
  amount: ['AMOUNT'],
  paymentSystemId: ['PAYMENT_SYSTEM_ID'],
  confirmationCode: ['CONFIRMATION_CODE'],
  firstName: ['FIRST_NAME'],
  lastName: ['LAST_NAME'],
  displayLine1: ['TRANSACTION_ADDRESS'],
  paidAt: ['PAID_AT'],
  actualPaidOn: ['PAID_ON'],
  paymentSystem: ['PAYMENT_SYSTEM'],
  status: ['STATUS'],
};

const OutgoingPaymentSortDirectionTypeEnum: EnumMap<
  string,
  Parameters<
    typeof OutgoingPaymentsControllerApi.prototype.searchOutgoingPayments
  >[1]
> = {
  asc: 'ASC',
  desc: 'DESC',
};

export const fetchAllOutgoingPayments = async (
  req: IPaginateReq<SearchOutgoingPaymentResponse>,
  agentId?: string,
  officeIds?: string[] | undefined,
  axiosOptions?: AxiosRequestConfig,
) => {
  const sortKey = Object.keys(req.sortBy || {})[0];
  const sortType = Object.values(req.sortBy || {})[0];

  const [
    paidOn,
    paidOnGreaterThanOrEqualTo,
    paidOnLessThanOrEqualTo,
  ] = getFormattedFilterDates(
    (req.filter?.actualPaidOn! as unknown) as SearchParam[],
  );

  const [
    paidAt,
    paidAtGreaterThanOrEqualTo,
    paidAtLessThanOrEqualTo,
  ] = getFormattedFilterDates(
    (req.filter?.paidAt! as unknown) as SearchParam[],
  );

  const paidAtWithTime = paidAt
    ? DateTime.fromFormat(paidAt, 'yyyy-LL-dd').toISO()
    : undefined;

  const { data } = await new OutgoingPaymentsControllerApi(
    getArrakisConfiguration(),
  ).searchOutgoingPayments(
    OutgoingPaymentSortByTypeEnum[sortKey!],
    OutgoingPaymentSortDirectionTypeEnum[sortType!],
    req.page,
    req.pageSize,
    req?.filter?.company as string,
    req?.filter?.transactionCode as string,
    req?.filter?.paymentSystemId as string,
    req?.filter?.confirmationCode as string,
    req?.filter?.firstName as string,
    req?.filter?.lastName as string,
    req?.filter?.displayLine1 as string,
    // @ts-ignore
    paidAtWithTime,
    paidAtGreaterThanOrEqualTo,
    paidAtLessThanOrEqualTo,
    paidOn,
    paidOnGreaterThanOrEqualTo,
    paidOnLessThanOrEqualTo,
    undefined,
    undefined,
    undefined,
    req?.filter?.amount as number,
    undefined,
    undefined,
    undefined,
    (req?.filter?.paymentSystem as SearchParam)?.values as Parameters<
      typeof OutgoingPaymentsControllerApi.prototype.searchOutgoingPayments
    >[24],
    (req?.filter?.status as SearchParam)?.values as Parameters<
      typeof OutgoingPaymentsControllerApi.prototype.searchOutgoingPayments
    >[25],
    agentId,
    officeIds,
    axiosOptions,
  );

  return data;
};
