import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';
import { ContextualPrompt } from '../../utils/LeoUtils';
import { ChatFormData } from '../Zen/Leo/Chat/LeoChatIndex';
import NeoLeoChatInput from './Chat/NeoLeoChatInput';
import NeoLeoChatPrompts from './NeoLeoChatPrompts';

interface NeoLeoChatFooterProps {
  isIntro?: boolean;
}

const NeoLeoChatFooter: React.FC<NeoLeoChatFooterProps> = ({
  isIntro = false,
}) => {
  const { msgContext, selectedItems } = useSelector(
    (state: RootState) => state.leo,
  );
  const { watch } = useFormContext<ChatFormData>();
  const [showPrompts, setShowPrompts] = useState<boolean>(true);
  const [promptSuggestions, setPromptSuggestions] = useState<
    ContextualPrompt[]
  >();
  const askLeoText = watch('askLeoText');

  useEffect(() => {
    const prompts = msgContext?.details[0]?.prompts;
    const singleSelectionPrompts =
      !!prompts && !!prompts?.single_selection
        ? prompts?.single_selection?.filter((el) => !!el)
        : [];
    const multiSelectionPrompts =
      !!prompts && !!prompts?.multi_selection
        ? prompts?.multi_selection?.filter((el) => !!el)!
        : [];
    const responseContext = msgContext?.details[0]?.type || '';
    if (selectedItems.length > 1 && multiSelectionPrompts.length) {
      setPromptSuggestions(
        multiSelectionPrompts.map((p) => ({
          prompt: p!,
          category: responseContext,
          title: p!,
        })),
      );
      return;
    }
    if (selectedItems.length === 1 && singleSelectionPrompts.length) {
      setPromptSuggestions(
        singleSelectionPrompts.map((p) => ({
          prompt: p!,
          category: responseContext,
          title: p!,
        })),
      );
      return;
    }
    setPromptSuggestions(undefined);
  }, [msgContext, selectedItems.length]);

  return (
    <div className='bg-regent-900 mx-2 mb-2 px-3 pb-3 rounded-2xl border border-[#2D355C] relative pt-1'>
      {showPrompts && !askLeoText && (
        <div className='pt-2.5'>
          <p className='uppercase rounded-full px-2 bg-gradient-to-r from-aqua to-rezen-blue-600 absolute -top-2 left-1/2 -translate-x-1/2 text-[9px] font-inter font-bold text-center'>
            leo’s Suggestions
          </p>
          <NeoLeoChatPrompts
            onSelect={() => setShowPrompts(false)}
            promptSuggestions={promptSuggestions}
            contextType={msgContext?.details[0]?.type}
          />
        </div>
      )}
      <NeoLeoChatInput
        isIntro={isIntro}
        askLeoText={askLeoText}
        showPrompts={showPrompts}
        setShowPrompts={setShowPrompts}
      />
    </div>
  );
};

export default NeoLeoChatFooter;
