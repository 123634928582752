import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-light-svg-icons';
import {
  faCaretUp,
  faThumbsDown as faThumbsDownDisliked,
  faThumbsUp as faThumbsUpLiked,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { CreateOrUpdateVoiceFeedbackRequestRatingEnum } from '../../../../openapi/yada';
import { useUpdateTranscriptFeedback } from '../../../../query/roar/useRoar';
import { Button } from '../../../commons/Button';
import { Modal } from '../../../commons/Modal';
import ZenControlledTextAreaInput from '../../../Zen/Input/ZenControlledTextAreaInput';

interface FeedbackForm {
  feedback: string;
}

interface VoiceCallFeedbackSidebarProps {
  voiceCallId: string;
  opened: boolean;
  liked?: boolean;
  feedbackText?: string;
  onChange(value: boolean): void;
  onClose(): void;
  openReviewModal(): void;
}

const VoiceCallFeedbackSidebar: React.FC<VoiceCallFeedbackSidebarProps> = ({
  voiceCallId,
  opened,
  liked,
  feedbackText,
  onChange,
  onClose,
  openReviewModal,
}) => {
  const { control, handleSubmit, reset } = useForm<FeedbackForm>({
    defaultValues: { feedback: feedbackText ?? '' },
  });

  useEffect(() => {
    if (opened) {
      reset({
        feedback: feedbackText ?? '',
      });
    }
  }, [opened, feedbackText, reset]);

  const {
    mutate: submitFeedback,
    isLoading: fetchingFeedback,
  } = useUpdateTranscriptFeedback(voiceCallId);

  const rating = liked
    ? CreateOrUpdateVoiceFeedbackRequestRatingEnum.Liked
    : CreateOrUpdateVoiceFeedbackRequestRatingEnum.Disliked;

  const onSubmit = (data: FeedbackForm) => {
    submitFeedback(
      { rating, feedback: data.feedback },
      {
        onSuccess: () => {
          onClose();
          openReviewModal();
        },
      },
    );
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size='552px'
      aria-label='feedback-modal'
    >
      <Modal.Header className='text-center bg-white border-b'>
        <span className='font-semibold text-xl font-inter'>Give Feedback</span>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content className='px-10 py-7'>
          <div className='flex w-full justify-between pb-3.5 items-center'>
            <div className='text-lg text-primary-dark font-medium font-inter'>
              Transcription was accurate
            </div>
            <div className='flex gap-6 justify-center items-center mr-1'>
              <div className='flex flex-col relative'>
                <button
                  type='button'
                  onClick={() => onChange(true)}
                  aria-label='like'
                >
                  <FontAwesomeIcon
                    icon={liked ? faThumbsUpLiked : faThumbsUp}
                    className={
                      liked ? 'text-rezen-blue-600' : 'text-primary-dark'
                    }
                    fontSize={20}
                  />
                </button>
                {liked && (
                  <FontAwesomeIcon
                    icon={faCaretUp}
                    className='absolute -bottom-6 text-rezen-light-blue-100 opacity-50'
                    size='lg'
                  />
                )}
              </div>
              <div className='flex flex-col relative'>
                <button
                  type='button'
                  onClick={() => onChange(false)}
                  aria-label='dislike'
                >
                  <FontAwesomeIcon
                    icon={!liked ? faThumbsDownDisliked : faThumbsDown}
                    className={
                      !liked ? 'text-rezen-blue-600' : 'text-primary-dark'
                    }
                    fontSize={20}
                  />
                </button>
                {!liked && (
                  <FontAwesomeIcon
                    icon={faCaretUp}
                    className='absolute -bottom-6 text-rezen-light-blue-100 opacity-50'
                    size='lg'
                  />
                )}
              </div>
            </div>
          </div>
          <div className='bg-rezen-light-blue-100 text-primary-dark bg-opacity-50 p-4 flex flex-col justify-center items-center rounded-md text-sm'>
            <div className='font-inter font-medium'>
              We&apos;re delighted as you
            </div>
            <div className='font-inter font-light mt-1'>
              Share your experience with us!
            </div>
          </div>
          <div className='mt-7 w-full'>
            <div className='pb-2 text-lg text-primary-dark font-medium font-inter'>
              Please provide feedback{' '}
              <span className='text-grey-400 font-normal'>(optional)</span>
            </div>
            <ZenControlledTextAreaInput
              rows={4}
              name='feedback'
              control={control}
              placeholder='Please provide us with any specifics on the accuracy of the transcription or summary and how we could improve.'
              className='placeholder:text-grey-500 !font-light !leading-6 !px-4 !py-2.5 !font-inter !text-base !resize-none'
            />
          </div>
        </Modal.Content>
        <Modal.Footer className='px-10 py-6'>
          <Button
            className='w-full bg-rezen-blue-600 border-0'
            loading={fetchingFeedback}
            disabled={fetchingFeedback}
            type='submit'
          >
            Submit
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default VoiceCallFeedbackSidebar;
