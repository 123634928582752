import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faUser } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  FlexTeamDto,
  FlexTeamDtoCdaApproverEnum,
  FlexTeamConfigDtoPermittedTransactionEditorsEnum,
} from '../../../../openapi/yenta';
import AdminOnly from '../../../auth/AdminOnly';
import { EnumMap, RootState } from '../../../../types';
import ZenPill, { ZenPillVariant } from '../../ZenPill';
import ZenTeamsType from '../ZenTeamsType';
import ZenTeamsCodeCell from '../ZenTeamsCodeCell';
import ZenTeamDetailHeaderInfoRow from '../ZenTeamDetailHeaderInfoRow';
import { capitalizeEnum } from '../../../../utils/StringUtils';

interface ZenProTeamDetailHeaderSectionProps {
  teamDetailOverview: FlexTeamDto;
  totalMemberCount?: number;
}

const commissionApproverEnumMapVariant: EnumMap<
  FlexTeamDtoCdaApproverEnum,
  ZenPillVariant
> = {
  [FlexTeamDtoCdaApproverEnum.AnyTeamMembers]: 'success-dark',
  [FlexTeamDtoCdaApproverEnum.TeamLeader]: 'newestblue',
  [FlexTeamDtoCdaApproverEnum.TeamLeaderAndTeamAdmins]: 'newestblue',
};

const permittedTransactionEditorEnumMapVariant: EnumMap<
  FlexTeamConfigDtoPermittedTransactionEditorsEnum,
  ZenPillVariant
> = {
  [FlexTeamConfigDtoPermittedTransactionEditorsEnum.AnyTeamMembers]:
    'success-dark',
  [FlexTeamConfigDtoPermittedTransactionEditorsEnum.TeamLeader]: 'newestblue',
  [FlexTeamConfigDtoPermittedTransactionEditorsEnum.TeamLeaderAndTeamAdmins]:
    'newestblue',
};

const ZenProTeamDetailHeaderSection: React.FC<ZenProTeamDetailHeaderSectionProps> = ({
  teamDetailOverview,
  totalMemberCount,
}) => {
  const { isAdmin } = useSelector((state: RootState) => state.auth);
  const isCommissionDocumentApproverAvailable = !!teamDetailOverview?.computedCdaApprover;
  const isPermittedTransactionEditorAvailable = !!teamDetailOverview?.computedPermittedTransactionEditors;

  return (
    <header
      data-testid='pro-team-detail-header'
      className='bg-zen-dark-13 p-2 md:p-4 lg:p-10 mt-2 lg:mt-6 mb-2 mx-4 rounded-2xl'
    >
      <div className='text-white'>
        <div
          className={classNames(
            'lg:px-3 text-white min-w-full',
            isAdmin ? 'space-y-2' : 'space-y-4',
          )}
        >
          <div className='flex flex-col lg:flex-row lg:text-left justify-between min-w-full lg:space-x-5 space-y-4 lg:space-y-0'>
            <div
              className={classNames(
                isAdmin
                  ? 'pt-2 lg:flex lg:space-x-6 space-y-2 lg:space-y-0'
                  : 'space-y-2',
              )}
            >
              <div
                className={classNames(
                  isAdmin
                    ? 'text-[25px] font-zen-body font-bold'
                    : 'text-[32px] font-zen-body font-bold',
                )}
              >
                {teamDetailOverview.name}
              </div>
              <AdminOnly>
                <div>
                  <ZenTeamsCodeCell
                    team={teamDetailOverview}
                    variant='secondary'
                  />
                </div>
              </AdminOnly>
            </div>
            <div>
              <ZenTeamsType
                type={teamDetailOverview.config?.teamType!}
                isPill={false}
              />
            </div>
          </div>
          <div className='flex md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-4 lg:space-x-20 py-4 md:py-0'>
            <div
              className={classNames(
                isAdmin
                  ? 'space-y-4'
                  : 'flex flex-col md:flex-row md:items-center md:space-x-20 space-y-4 md:space-y-0',
              )}
            >
              <ZenTeamDetailHeaderInfoRow
                label={`${totalMemberCount} ${pluralize(
                  'Member',
                  totalMemberCount,
                )}`}
                leftIcon={
                  <FontAwesomeIcon
                    className='text-white'
                    icon={faUser}
                    size='xs'
                  />
                }
              />
              <ZenTeamDetailHeaderInfoRow
                label='Created On:'
                subLabel={DateTime.fromMillis(
                  teamDetailOverview.createdAt!,
                ).toFormat('MMMM dd, yyyy')}
                leftIcon={
                  <FontAwesomeIcon
                    className='text-white'
                    icon={faCalendar}
                    size='xs'
                  />
                }
              />
            </div>
            <AdminOnly>
              <div className='flex flex-col space-y-4'>
                <div className='space-y-4' data-testid='cda-info-row'>
                  <ZenTeamDetailHeaderInfoRow
                    label='Commission Document Approver'
                    leftIcon={
                      <FontAwesomeIcon
                        className='text-white'
                        icon={faUser}
                        size='xs'
                      />
                    }
                    rightIcon={
                      <ZenPill
                        title={
                          isCommissionDocumentApproverAvailable
                            ? capitalizeEnum(
                                teamDetailOverview?.computedCdaApprover!,
                              )
                            : 'N/A'
                        }
                        textStyle='text-sm'
                        variant={
                          isCommissionDocumentApproverAvailable
                            ? commissionApproverEnumMapVariant[
                                teamDetailOverview?.computedCdaApprover!
                              ]
                            : 'gray'
                        }
                      />
                    }
                  />
                </div>
                <div className='space-y-4' data-testid='cda-info-row'>
                  <ZenTeamDetailHeaderInfoRow
                    label='Permitted Transaction Editors'
                    leftIcon={
                      <FontAwesomeIcon
                        className='text-white'
                        icon={faUser}
                        size='xs'
                      />
                    }
                    rightIcon={
                      <ZenPill
                        title={
                          isPermittedTransactionEditorAvailable
                            ? capitalizeEnum(
                                teamDetailOverview?.computedPermittedTransactionEditors!,
                              )
                            : 'N/A'
                        }
                        textStyle='text-sm'
                        variant={
                          isPermittedTransactionEditorAvailable
                            ? permittedTransactionEditorEnumMapVariant[
                                teamDetailOverview?.computedPermittedTransactionEditors!
                              ]
                            : 'gray'
                        }
                      />
                    }
                  />
                </div>
              </div>
            </AdminOnly>
          </div>
        </div>
      </div>
    </header>
  );
};

export default ZenProTeamDetailHeaderSection;
