import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PulseGif from '../../../../assets/img/roar/pulse-gif.gif';
import useAgentsInfo from '../../../../hooks/useAgentsInfo';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import {
  CommentDto,
  MentionBlock,
  VoiceCallBlock,
} from '../../../../openapi/yada';
import { setRecordingDataById } from '../../../../slices/TransactionSlice';
import { AppDispatch, FeatureFlagTypeEnum, RootState } from '../../../../types';
import { getFullName } from '../../../../utils/AgentHelper';
import { cn } from '../../../../utils/classUtils';
import {
  canDeleteVoiceRecording,
  isCallCancelled,
  isCallerSameAsUser,
  isTranscriptAvailable,
  isVoiceCall,
} from '../../../../utils/RoarUtils';
import ExcludeMortgagerUserRole from '../../../auth/ExcludeMortgagerUserRole';
import FeatureFlagEnabledOnly from '../../../FeatureFlagEnabledOnly';
import GeminiAvatar from '../../../Gemini/GeminiAvatar';
import VoiceCallDisclaimer from '../../../transactions/Comments/Roar/VoiceCallDisclaimer';
import VoiceCallHeader from '../../../transactions/Comments/Roar/VoiceCallHeader';
import VoiceCallPlayer from '../../../transactions/Comments/Roar/VoiceCallPlayer';
import VoiceCallTranscription from '../../../transactions/Comments/Roar/VoiceCallTranscription';
import ZenCommentReactions from '../../../transactions/Comments/ZenCommentReactions';
import { ZenCommentSectionContext } from '../../../transactions/Comments/ZenCommentSectionLayout';

type VoiceCallCardProps = {
  comment: CommentDto;
  fullName?: string;
  avatarUrl?: string;
  recordingBlock: VoiceCallBlock;
  mentionBlock: MentionBlock;
  footerElement: React.ReactElement;
  onDeleteOptionClick?: () => void;
};

const VoiceCallCard: React.FC<VoiceCallCardProps> = ({
  comment,
  fullName,
  avatarUrl,
  recordingBlock,
  mentionBlock,
  footerElement,
  onDeleteOptionClick,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [
    isReactionsPopperVisible,
    setIsReactionsPopperVisible,
  ] = useState<boolean>(false);

  const agentById = useSelector((state: RootState) => state.userIds.agentById);
  const isAdmin = useSelector((state: RootState) => state.auth.isAdmin);
  const isBroker = useSelector((state: RootState) => state.auth.isBroker);
  const userDetails = useSelector((state: RootState) => state.auth.userDetail);

  // @ts-expect-error voiceCallId has incorrect type
  const recordingId: string = recordingBlock.voiceCallId;

  const speakerIds = useMemo(() => {
    const speakers = recordingBlock.recordingTranscription?.speakers || [];
    const arr = [];
    for (const speaker of speakers) {
      if (speaker.userId) {
        arr.push(speaker.userId);
      }
    }
    return arr;
  }, [recordingBlock?.recordingTranscription?.speakers]);

  const agentIds = useMemo(() => {
    const arr: string[] = [];
    if (recordingBlock.callerId) {
      arr.push(recordingBlock.callerId);
    }
    if (recordingBlock.calleeId) {
      arr.push(recordingBlock.calleeId);
    }
    if (arr.length < 2) {
      arr.push(mentionBlock.userId!);
    }
    return arr;
  }, [mentionBlock.userId, recordingBlock.calleeId, recordingBlock.callerId]);

  const userIds = useMemo(() => [...agentIds, ...speakerIds], [
    agentIds,
    speakerIds,
  ]);

  const agents = useMemo(() => {
    return agentIds.map((agentId) => {
      const agentInfo = agentById[agentId];
      const mentioned = agentId === mentionBlock?.userId;
      const name = mentioned ? mentionBlock?.name : getFullName(agentInfo);

      return {
        name: name || '',
        firstName: agentInfo?.firstName,
        lastName: agentInfo?.lastName,
        avatar: agentInfo?.avatar,
        showPlaceHolder: !name,
      };
    });
  }, [agentById, agentIds, mentionBlock.name, mentionBlock?.userId]);

  const { loading } = useAgentsInfo(userIds);

  const callCancelled = isCallCancelled(recordingBlock);

  const {
    setVoiceCallTranscriptionData,
    setEditVoiceCallSpeakers,
  } = useContext(ZenCommentSectionContext);

  const showTranscriptionDetails = useCallback(() => {
    setVoiceCallTranscriptionData({
      recordingId,
    });
  }, [recordingId, setVoiceCallTranscriptionData]);

  useEffect(() => {
    const transcription = recordingBlock.recordingTranscription;

    dispatch(
      setRecordingDataById({
        id: recordingId,
        recordingBlock,
        mentionBlock,
      }),
    );

    if (
      isBroker &&
      !isEmpty(transcription?.transcript?.segments) &&
      isEmpty(transcription?.speakers)
    ) {
      setEditVoiceCallSpeakers({ recordingId });
    }
  }, [
    dispatch,
    isBroker,
    mentionBlock,
    recordingBlock,
    recordingId,
    setEditVoiceCallSpeakers,
  ]);

  const allowActions =
    canDeleteVoiceRecording(recordingBlock, userDetails) || isAdmin;

  const isAdminOrBroker = isAdmin || isBroker;

  const callerSameAsUser = isCallerSameAsUser(recordingBlock, userDetails);

  const isRoarFullRelease = useFeatureFlag(
    FeatureFlagTypeEnum.ROAR_FULL_RELEASE_BOLT,
  );

  return (
    <div
      className={cn(
        'mb-4 flex flex-col items-start space-y-1',
        callerSameAsUser && 'items-end',
      )}
      onMouseEnter={() => isRoarFullRelease && setIsHovered(true)}
      onMouseLeave={() => isRoarFullRelease && setIsHovered(false)}
    >
      <div className='flex items-start w-full'>
        <div className='w-full flex flex-col gap-y-1 mb-4'>
          <div
            className={cn('flex flex-row items-end space-x-2', {
              'flex-row-reverse space-x-reverse': callerSameAsUser,
            })}
          >
            <div className={cn(callerSameAsUser ? 'mt-1' : '-mt-1')}>
              {callCancelled ? (
                <GeminiAvatar name={fullName!} size='xs' imageUrl={avatarUrl} />
              ) : (
                <img src={PulseGif} width={35} height={35} alt='pulse' />
              )}
            </div>
            <div className='w-full max-w-xl relative'>
              {!callCancelled && !isAdminOrBroker && <VoiceCallDisclaimer />}
              <div
                className={cn(
                  'bg-white font-inter py-4 px-3 border border-regent-300 rounded-xl space-y-4 w-full',
                  allowActions && 'pb-2',
                  callCancelled || isAdminOrBroker ? 'mt-0.5' : '-mt-2',
                )}
              >
                <VoiceCallHeader
                  recordingBlock={recordingBlock}
                  mentionBlock={mentionBlock}
                  isLoading={loading}
                  agents={agents}
                />
                {!callCancelled && (
                  <>
                    {!!recordingBlock.recordingUrl && (
                      <VoiceCallPlayer
                        id={recordingBlock.voiceCallId!}
                        url={recordingBlock.recordingUrl!}
                        duration={recordingBlock.recordingDurationSecs!}
                      />
                    )}
                    <VoiceCallTranscription
                      recordingBlock={recordingBlock}
                      showTranscriptionDetails={showTranscriptionDetails}
                    />
                  </>
                )}
                <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.ROAR}>
                  {allowActions && (
                    <div
                      className='!mt-1 flex justify-end'
                      aria-label='comment-options'
                    >
                      <button
                        data-testid='deleteIcon'
                        className='cursor-pointer text-primary-dark'
                        onClick={onDeleteOptionClick}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          size='xs'
                          className='cursor-pointer'
                        />
                      </button>
                    </div>
                  )}
                </FeatureFlagEnabledOnly>
              </div>
              <ExcludeMortgagerUserRole>
                <FeatureFlagEnabledOnly
                  flag={FeatureFlagTypeEnum.ROAR_FULL_RELEASE_BOLT}
                >
                  <div
                    onMouseEnter={() => setIsReactionsPopperVisible(true)}
                    onMouseLeave={() => setIsReactionsPopperVisible(false)}
                  >
                    <div
                      className={cn(
                        'absolute -bottom-4',
                        callerSameAsUser ? 'left-5' : 'right-5',
                        !callerSameAsUser && allowActions && 'right-8',
                        !callerSameAsUser &&
                          isVoiceCall(recordingBlock) &&
                          isTranscriptAvailable(recordingBlock) &&
                          '-bottom-2',
                      )}
                    >
                      <ZenCommentReactions
                        comment={comment}
                        isHovered={isHovered}
                        isReactionsPopperVisible={isReactionsPopperVisible}
                      />
                    </div>
                  </div>
                </FeatureFlagEnabledOnly>
              </ExcludeMortgagerUserRole>
            </div>
          </div>
          <div className='w-full'>{footerElement}</div>
        </div>
      </div>
    </div>
  );
};

export default VoiceCallCard;
