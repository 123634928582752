import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import PageLayout from '../components/PageLayout';
import NullableTextCell from '../components/table/Cells/NullableTextCell';
import ZenOutgoingPaymentStatusCell from '../components/table/Cells/ZenOutgoingPaymentStatusCell';
import ZenOutgoingPaymentSystemCell from '../components/table/Cells/ZenOutgoingPaymentSystemCell';
import ZenViewActionButtonCell from '../components/table/Cells/ZenViewActionButtonCell';
import NumberColumnFilter from '../components/table/Filters/NumberColumnFilter';
import OutgoingPaymentPaymentSystemSelectColumnFilter from '../components/table/Filters/OutgoingPaymentPaymentSystemSelectColumnFilter';
import OutgoingPaymentStatusSelectColumnFilter from '../components/table/Filters/OutgoingPaymentStatusSelectColumnFilter';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenRoute from '../components/Zen/ZenRoute';
import {
  OutgoingPaymentsControllerApi,
  SearchOutgoingPaymentResponse,
  SearchOutgoingPaymentResponseStatusEnum,
} from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import { displayAmount } from '../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { fetchAllOutgoingPayments } from '../utils/TableUtils';

export const columns: Array<Column<SearchOutgoingPaymentResponse>> = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    id: 'firstName',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    id: 'lastName',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Payment System',
    accessor: 'paymentSystem',
    Filter: OutgoingPaymentPaymentSystemSelectColumnFilter,
    Cell: ({ value }) => (
      <div className='md:w-max pt-1 md:pt-0'>
        <ZenOutgoingPaymentSystemCell type={value!} />
      </div>
    ),
  },
  {
    Header: 'Payment System Id',
    accessor: 'paymentSystemId',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Amount Paid',
    accessor: 'amount',
    Cell: ({ value }) => displayAmount(value),
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <div className='md:w-max pt-1 md:pt-0'>
        <ZenOutgoingPaymentStatusCell type={value!} />
      </div>
    ),
    Filter: OutgoingPaymentStatusSelectColumnFilter,
    cardColSize: 6,
    disableSortBy: true,
  },
  {
    Header: 'Payer Note',
    accessor: 'payerNote',
    disableSortBy: true,
    disableFilters: true,
  },
];

const WalletOutgoingPaymentsRoute: React.FC = () => {
  const dispatch = useDispatch();

  const handleApproveOutgoingPayments = async (
    payments: SearchOutgoingPaymentResponse[],
  ) => {
    const paymentIds = payments?.map((payment) => payment?.id!);

    try {
      await new OutgoingPaymentsControllerApi(
        getArrakisConfiguration(),
      ).approvePayments({
        outgoingPaymentIds: paymentIds,
      });
      dispatch(
        showSuccessToast('Outgoing Payments are approved successfully.'),
      );
    } catch (e) {
      ErrorService.notify('Unable to bulk approve outgoing payments', e, {
        paymentIds,
      });
      dispatch(showApiErrorModal(e));
      dispatch(showErrorToast('Unable to approve outgoing payments.'));
    }
  };

  const columnsWithAction: Array<Column<SearchOutgoingPaymentResponse>> = [
    {
      Header: '',
      accessor: 'id',
      Filter: TextColumnFilter,
      Cell: ({
        row: { original },
      }: CellProps<SearchOutgoingPaymentResponse>) => (
        <div className='md:w-max'>
          {original.yentaUserId ? (
            <Link to={`/people/${original.yentaUserId}`}>
              <ZenViewActionButtonCell />
            </Link>
          ) : (
            <span />
          )}
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
    },
    ...columns,
  ];

  return (
    <ZenRoute title='Wallet Outgoing Payments'>
      <PageLayout
        path={[
          { title: 'Finance', url: '/finance' },
          {
            title: 'Wallet Outgoing Payments',
            url: '/wallet-outgoing-payments',
          },
        ]}
      >
        <div className='px-4 py-3 lg:py-5'>
          <ZenResourceIndexContainer<SearchOutgoingPaymentResponse>
            header='Wallet Outgoing Payments'
            columns={columnsWithAction}
            resourceName='payment'
            initialSort={{ paidAt: 'desc' }}
            allowSelection
            stickyHeader
            fetchData={async (req, cancelToken) => {
              const data = await fetchAllOutgoingPayments(
                {
                  ...req,
                  filter: {
                    ...req.filter,
                    status: {
                      values: ['PENDING_APPROVAL'],
                      column: 'status',
                    },
                  },
                },
                undefined,
                undefined,
                {
                  cancelToken,
                },
              );

              return {
                data: data?.results || [],
                total: data?.totalCount || 0,
              };
            }}
            selectionOptions={[
              {
                label: 'Approve Payments',
                confirm: (payments) => ({
                  title: 'Approve Payments',
                  description: `Are you sure you would like to approve these ${payments.length} payments? This action cannot be undone.`,
                  modalType: 'success',
                  primaryActionTitle: 'Approve',
                }),
                onAction: handleApproveOutgoingPayments,
                isActionButtonEnabled: (payments) => {
                  return payments.every(
                    ({ status }) =>
                      status ===
                      SearchOutgoingPaymentResponseStatusEnum.PendingApproval,
                  );
                },
              },
            ]}
          />
        </div>
      </PageLayout>
    </ZenRoute>
  );
};

export default WalletOutgoingPaymentsRoute;
