import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { faTag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flatten, last, map, uniq, values } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as TriggerIcon } from '../../../assets/icons/zen/autoToggle/trigger.svg';
import {
  ChecklistDefinitionDto,
  ItemDefinitionDto,
  ItemDefinitionDtoDefaultAssigneeEnum,
  ItemDefinitionDtoRequiredForEnum,
  TemplateReferenceDefinitionDto,
  UpdateItemDefinitionRequest,
  UpdateItemDefinitionRequestDefaultAssigneeEnum,
  UpdateItemDefinitionRequestRequiredForEnum,
} from '../../../openapi/sherlock';
import {
  addCheckListDefinitionItem,
  addLabelForChecklistItem,
  fetchChecklistDefinition,
  removeLabelForChecklistItem,
  updateCheckListDefinitionItem,
  uploadCheckListDefinitionDocument,
} from '../../../slices/ChecklistDefinitionSlice';
import {
  AppDispatch,
  ISelectOption,
  RootState,
  YesNoType,
} from '../../../types';
import Logger from '../../../utils/Logger';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenControlledMultiSelectCreatableInput from '../../Zen/Input/ZenControlledMultiSelectCreatableInput';
import ZenControlledMultiSelectInput from '../../Zen/Input/ZenControlledMultiSelectInput';
import ZenControlledRadioInput from '../../Zen/Input/ZenControlledRadioInput';
import ZenControlledSelectInput from '../../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextAreaInput from '../../Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../../Zen/Input/ZenControlledToggleInput';
import ZenCounterControlledInput from '../../Zen/Input/ZenCounterControlledInput';
import ZenButton from '../../Zen/ZenButton';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';
import TemplateItemSidebarHeader from './TemplateItemSidebarHeader';

interface AddTemplateItemProps {
  isOpen: boolean;
  onClose(): void;
  checklistItem?: ItemDefinitionDto;
  checkList: ChecklistDefinitionDto;
  setItemId: React.Dispatch<React.SetStateAction<string | undefined>>;
  readonly?: boolean;
}

interface FormData {
  itemName: string;
  isRequired: boolean;
  assignee: ISelectOption;
  dueDays: number;
  labels: ISelectOption[];
  description: string;
  document: File[];
  requiredFor: ISelectOption;
  hidden: YesNoType;
  fileReferences: ISelectOption[];
}

const NOT_ASSIGNED_VALUE = 'not-assigned';
const NOT_ASSIGNED_OPTION = {
  label: 'Not Assigned',
  value: NOT_ASSIGNED_VALUE,
};

const AddTemplateItem: React.FC<AddTemplateItemProps> = ({
  isOpen,
  onClose,
  checklistItem,
  checkList,
  setItemId,
  readonly = false,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    checkListDefinition: { checklistDefinitionById },
    dropbox: { globalDropbox },
  } = useSelector((state: RootState) => state);
  const checklist = checklistDefinitionById[checkList?.id!]?.data!;
  const allAvailableLabels = map(
    uniq(flatten((checklist.items || []).map((item) => item.labels || []))),
    (label) => ({ label, value: label }),
  );
  const labels = (checklistItem?.labels || []).map((label) => ({
    label: capitalizeEnum(label),
    value: label!,
  }));
  const dropboxFiles = (globalDropbox?.files || []).map((file) => ({
    label: file?.filename!,
    value: file?.id!,
  }));
  const existingFiles = globalDropbox?.files?.filter((file) =>
    checklistItem?.templateReferences?.references?.find(
      (ref) => ref?.fileId === file?.id,
    ),
  );

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      assignee: checklistItem?.defaultAssignee
        ? {
            label: capitalizeEnum(checklistItem?.defaultAssignee || ''),
            value: checklistItem?.defaultAssignee,
          }
        : NOT_ASSIGNED_OPTION,
      isRequired: checklistItem?.required,
      description: checklistItem?.description,
      itemName: checklistItem?.name,
      labels,
      requiredFor: checklistItem?.requiredFor
        ? {
            label: capitalizeEnum(checklistItem?.requiredFor || ''),
            value: checklistItem?.requiredFor,
          }
        : undefined,
      hidden: checklistItem?.hidden ? YesNoType.YES : YesNoType.NO,
      fileReferences: existingFiles?.map((file) => ({
        label: file?.filename!,
        value: file?.id!,
      })),
    },
  });

  const onSubmit = async (formData: FormData) => {
    const req: UpdateItemDefinitionRequest = {
      name: formData?.itemName,
      required: formData?.isRequired,
      daysUntilDue: formData?.dueDays,
      description: formData?.description,
      defaultAssignee: (formData?.assignee?.value === NOT_ASSIGNED_VALUE
        ? null
        : formData?.assignee
            ?.value) as UpdateItemDefinitionRequestDefaultAssigneeEnum,
      labels: formData?.labels?.map((label) => label?.value),
      requiredFor:
        (formData?.requiredFor
          ?.value as UpdateItemDefinitionRequestRequiredForEnum) ?? null,
      hidden: formData.hidden === YesNoType.YES,
      templateReferences: {
        references: formData?.fileReferences?.map(
          (fileId) =>
            ({
              fileId: fileId.value,
              dropboxId: globalDropbox?.id,
            } as TemplateReferenceDefinitionDto),
        ),
      },
    };

    let checklistItemId = checklistItem?.id!;

    if (checklistItem) {
      await dispatch(
        updateCheckListDefinitionItem(checkList?.id!, checklistItem?.id!, {
          ...req,
          position: checklistItem?.position,
          documents: checklistItem?.documents,
        }),
      );
    } else {
      const res = await dispatch(
        addCheckListDefinitionItem(checkList?.id!, [req as ItemDefinitionDto]),
      );
      if (res) {
        checklistItemId = last(res)?.id!;
      }
    }
    onClose();
    const formDataLabels = (formData?.labels || []).map(
      (label) => label?.value,
    );

    const labelsToRemove = labels
      .map((label) => label.value)
      .filter((label) => !formDataLabels.includes(label));
    Logger.debug('[Removing labels]:', labelsToRemove);

    if (labelsToRemove.length > 0 && checklistItem) {
      await Promise.all(
        labelsToRemove.map((label) =>
          dispatch(removeLabelForChecklistItem(checklistItem?.id!, label)),
        ),
      );
    }

    const labelsToAdd = formDataLabels.filter(
      (label) => !labels.find((l) => l.value === label),
    );

    if (labelsToAdd.length > 0) {
      await Promise.all(
        labelsToAdd.map((label) =>
          dispatch(addLabelForChecklistItem(checklistItemId, label)),
        ),
      );
    }

    await dispatch(fetchChecklistDefinition(checkList?.id!));
  };

  const [isRequired, doc] = watch(['isRequired', 'document']);

  const uploadDocument = useCallback(
    async (doc: any) => {
      await dispatch(
        uploadCheckListDefinitionDocument(
          checkList?.id!,
          checklistItem?.id!,
          doc,
        ),
      );
    },
    [checkList?.id, checklistItem?.id, dispatch],
  );

  useEffect(() => {
    if (doc) {
      const allDocs = Array.from(doc);
      if (allDocs?.length > 0) {
        allDocs.forEach((f) => uploadDocument(f));
      }
    }
  }, [doc, uploadDocument]);

  return (
    <ZenSidebarModal
      isOpen={isOpen}
      onClose={onClose}
      customHeader={
        <TemplateItemSidebarHeader
          checkListName={checklistItem ? checklistItem?.name : checkList?.name}
          isRequired={isRequired}
          onClose={onClose}
        />
      }
    >
      <form
        className='flex flex-col h-full px-4 pt-8'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='flex-grow pb-32'>
          <div className='flex flex-row items-center'>
            <div className='flex flex-grow flex-shrink'>
              <ZenControlledTextInput<FormData, 'itemName'>
                name='itemName'
                control={control}
                label='Type Item Name'
                isRequired
                placeholder='Item Name'
                rules={{ required: 'Item name is required' }}
                readOnly={readonly}
              />
            </div>
            <div className='justify-end self-end ml-4 mb-1'>
              <ZenControlledToggleInput<FormData, 'isRequired'>
                name='isRequired'
                control={control}
                label='Required'
                readOnly={readonly}
              />
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-x-3 md:gap-x-6 gap-y-4 md:gap-y-0 mt-5'>
            <ZenControlledSelectInput<FormData, 'assignee'>
              name='assignee'
              label='Assignee'
              control={control}
              placeholder='Select Assignee'
              options={[
                NOT_ASSIGNED_OPTION,
                ...values(ItemDefinitionDtoDefaultAssigneeEnum).map(
                  (assignee) => ({
                    label: capitalizeEnum(assignee),
                    value: assignee,
                  }),
                ),
              ]}
              startAdornment={
                <FontAwesomeIcon
                  icon={faUser}
                  className='text-primary-blue mr-1 ml-2'
                  size='sm'
                />
              }
              readOnly={readonly}
            />
            <ZenCounterControlledInput<FormData, 'dueDays'>
              name='dueDays'
              control={control}
              counterLabel='days'
              label='Days Due'
              initialCount={checklistItem?.daysUntilDue! || 0}
              disabled={readonly}
            />
          </div>
          <div className='col-span-1 mt-5'>
            <ZenControlledSelectInput<FormData, 'requiredFor'>
              name='requiredFor'
              label='Required For'
              control={control}
              placeholder='Required For'
              options={values(ItemDefinitionDtoRequiredForEnum).map(
                (requiredFor) => ({
                  label: capitalizeEnum(requiredFor),
                  value: requiredFor,
                }),
              )}
              readOnly={readonly}
              isClearable
            />
          </div>
          <div className='mt-5'>
            <ZenControlledMultiSelectCreatableInput<FormData, 'labels'>
              name='labels'
              control={control}
              label='Add Label(s)'
              placeholder='Type Label'
              options={allAvailableLabels}
              customOptionIcon={
                <FontAwesomeIcon icon={faTag} className='mr-2' />
              }
              readOnly={readonly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledTextAreaInput<FormData, 'description'>
              name='description'
              control={control}
              label='Description'
              placeholder='Add Description'
              className='font-zen-body text-base text-dark font-normal'
              rows={5}
              noResize
              readOnly={readonly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledMultiSelectInput<FormData, `fileReferences`>
              name='fileReferences'
              control={control}
              label='Files References'
              placeholder='Select File References'
              options={dropboxFiles}
              readOnly={readonly}
            />
          </div>
          <div className='mt-5'>
            <ZenControlledRadioInput<FormData, 'hidden'>
              name='hidden'
              control={control}
              label='Hide Template Item?'
              options={[
                {
                  label: 'Yes',
                  value: YesNoType.YES,
                },
                {
                  label: 'No',
                  value: YesNoType.NO,
                },
              ]}
              disabled={readonly}
              inlineOptions
            />
          </div>
        </div>
        {!readonly && (
          <div className='absolute bottom-0 left-0 right-0 bg-white flex flex-row justify-end space-x-4 items-center w-full py-4 md:px-8 px-4 border border-gray-200'>
            {!!checklistItem && (
              <div className='flex flex-grow flex-shrink'>
                <ZenButton
                  type='button'
                  label={`Triggers (${
                    checklistItem?.triggerDefinitions?.length || 0
                  })`}
                  variant='primary-link'
                  LeftIconComponent={
                    <TriggerIcon
                      width={20}
                      height={20}
                      className='text-primary-blue'
                    />
                  }
                  onClick={() => setItemId(checklistItem?.id!)}
                />
              </div>
            )}
            <div className='w-40'>
              <ZenButton
                type='button'
                label='Cancel'
                variant='primary-outline'
                onClick={onClose}
                isFullWidth
              />
            </div>
            <div className='w-40'>
              <ZenButton
                label='Save'
                type='submit'
                variant='primary'
                isSubmitting={isSubmitting}
                isDisabled={isSubmitting}
                isFullWidth
              />
            </div>
          </div>
        )}
      </form>
    </ZenSidebarModal>
  );
};

export default AddTemplateItem;
