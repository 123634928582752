import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keys, values } from 'lodash';
import { useState } from 'react';
import { PaginatedResultsByIdWithFiltersResponse } from '../types';
import { cn } from '../utils/classUtils';

type LoadingState = 'left' | 'right' | 'none';

export interface GenericSearchResponse<T> {
  pageNumber?: number;
  pageSize?: number;
  data?: Array<T>;
  total?: number;
  hasNext?: boolean;
}

interface Props<T> {
  title: string;
  itemId?: string;
  items?: PaginatedResultsByIdWithFiltersResponse<T>;
  apiCall: (
    isNext: boolean,
    items: PaginatedResultsByIdWithFiltersResponse<T>,
  ) => Promise<GenericSearchResponse<T>>;
  dispatchCall: (
    res: GenericSearchResponse<T>,
    items: PaginatedResultsByIdWithFiltersResponse<T>,
  ) => void;
  handleNavigate: (itemId: string) => void;
}

const ZenDetailPagePrevAndNextPaginator = <T extends { id?: string }>({
  title,
  itemId,
  items,
  apiCall,
  dispatchCall,
  handleNavigate,
}: Props<T>): JSX.Element | null => {
  const [isLoading, setIsLoading] = useState<LoadingState>('none');

  const isLeftLoading = isLoading === 'left';
  const isRightLoading = isLoading === 'right';

  if (!itemId || !items) return null;

  const { hasNext, results, filters, totalCount } = items || {};

  const findIndex = values(results).indexOf(itemId);

  if (findIndex === -1) {
    return null;
  }

  const currentIndex = Number(keys(results)[findIndex]);

  const isFirstIndex = findIndex === 0 && filters?.page === 0;
  const isLastIndex = findIndex === keys(results).length - 1 && !hasNext;

  const handlePrevOrNext = async (isNext: boolean) => {
    const isLastItem =
      (isNext && findIndex === keys(results).length - 1) ||
      (!isNext && findIndex === 0);

    if (isLastItem) {
      setIsLoading(isNext ? 'right' : 'left');
      const response = await apiCall(isNext, items);
      dispatchCall(response, items);
      setIsLoading('none');

      const nextItem = isNext
        ? response.data?.[0]?.id!
        : response.data?.[response.data.length - 1]?.id!;
      handleNavigate(nextItem);
    } else {
      const indexNumber = currentIndex + (isNext ? 1 : -1);
      handleNavigate(results[indexNumber]);
    }
  };

  return (
    <div
      className='px-[20px] py-[13px] flex items-center justify-between'
      data-testid='zen-detail-page-prev-and-next-paginator'
    >
      <div className='text-lg leading-[26px] font-poppins font-medium'>
        {title}
      </div>
      <div className='flex items-center font-inter font-normal text-sm leading-[16px]'>
        <span>
          {title} {currentIndex} of {totalCount}
        </span>
        <div className='flex items-center gap-[16px] ml-[16px]'>
          <button
            title='previous'
            className={cn(
              'h-9 w-9 rounded-full flex items-center justify-center',
              isFirstIndex && 'cursor-not-allowed',
              isFirstIndex ? 'bg-grey-100' : 'bg-regent-300',
            )}
            onClick={() => handlePrevOrNext(false)}
            disabled={isFirstIndex || isLeftLoading}
          >
            {isLeftLoading ? (
              <FontAwesomeIcon
                icon={faArrowsRotate}
                className='animate-spin text-lg'
              />
            ) : (
              <FontAwesomeIcon
                icon={faChevronLeft}
                className={cn(
                  isFirstIndex ? 'text-grey-400' : 'text-primary-dark',
                )}
              />
            )}
          </button>
          <button
            title='next'
            className={cn(
              'h-9 w-9 rounded-full flex items-center justify-center',
              isLastIndex && 'cursor-not-allowed',
              isLastIndex ? 'bg-grey-100' : 'bg-regent-300',
            )}
            onClick={() => handlePrevOrNext(true)}
            disabled={isLastIndex || isRightLoading}
          >
            {isRightLoading ? (
              <FontAwesomeIcon
                icon={faArrowsRotate}
                className='animate-spin text-lg'
              />
            ) : (
              <FontAwesomeIcon
                icon={faChevronRight}
                className={cn(
                  isLastIndex ? 'text-grey-400' : 'text-primary-dark',
                )}
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ZenDetailPagePrevAndNextPaginator;
