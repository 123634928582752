import React, { useMemo } from 'react';
import { Remarkable } from 'remarkable';
import { linkify } from 'remarkable/linkify';
import { useGemini } from '../../hooks/useGemini';
import { EnumMap } from '../../types';
import { cn } from '../../utils/classUtils';

export type ZenAlertVariantType =
  | 'success'
  | 'info'
  | 'warning'
  | 'error'
  | 'default';

interface ZenAlertProps {
  text: string;
  variant: ZenAlertVariantType;
  icon?: JSX.Element;
  className?: string;
}

// Initialize Remarkable with linkify plugin
const md = new Remarkable().use(linkify);
md.set({
  html: true,
  breaks: true,
  linkTarget: '_blank',
});

const ZenAlert: React.FC<ZenAlertProps> = ({
  text,
  variant,
  className,
  icon,
}) => {
  const alertVariantToClassNameMap: EnumMap<ZenAlertVariantType, string> = {
    success: 'bg-green-600 bg-opacity-10 text-success',
    info: 'bg-primary-blue bg-opacity-10 text-primary-blue',
    warning: 'bg-zen-warning bg-opacity-10 text-zen-warning',
    error: 'bg-error bg-opacity-10 text-error',
    default: 'bg-gray-500',
  };

  const parsedMarkdown = useMemo(() => md.render(text), [text]);

  const isGeminiEnabled = useGemini();

  return (
    <div
      className={cn(
        'text-white rounded-[10px] px-6 py-2',
        alertVariantToClassNameMap[variant],
        className,
      )}
    >
      <div className='flex items-center'>
        {icon}
        <p
          id='alert'
          className={cn(
            'ml-3 pt-0.5 break-words',
            isGeminiEnabled ? 'font-inter' : 'font-primary-regular',
          )}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: parsedMarkdown }}
        />
      </div>
    </div>
  );
};

export default ZenAlert;
