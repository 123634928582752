import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import {
  faAdd,
  faArrowUpArrowDown,
  faArrowUpRightFromSquare,
  faTrashCan,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keys, values } from 'lodash';
import { DateTime } from 'luxon';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ZenBooleanButton from '../components/Agent/V2/ZenBooleanButton';
import AdminOnly from '../components/auth/AdminOnly';
import IconButton from '../components/IconButton';
import ZenSidebarModalActionFooter from '../components/SidebarModal/ZenSideBarModalActionFooter';
import ZenControlledDatePickerInput from '../components/Zen/Input/ZenControlledDatePickerInput';
import ZenControlledFileUploadInput from '../components/Zen/Input/ZenControlledFileUploadInput';
import ZenControlledGoogleAutocompleteSearchInput, {
  GooglePlaceLocationType,
} from '../components/Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledMultiSelectInput from '../components/Zen/Input/ZenControlledMultiSelectInput';
import ZenControlledPhoneNumberInput from '../components/Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledRadioInput from '../components/Zen/Input/ZenControlledRadioInput';
import ZenControlledSelectInput from '../components/Zen/Input/ZenControlledSelectInput';
import ZenControlledStateOrProvinceInput from '../components/Zen/Input/ZenControlledStateOrProvince';
import ZenControlledTextAreaInput from '../components/Zen/Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../components/Zen/Input/ZenControlledToggleInput';
import ZenControlledWebsiteURLTextInput from '../components/Zen/Input/ZenControlledWebsiteURLTextInput';
import ZenAccordion from '../components/Zen/ZenAccordion';
import ZenCopyToClipboard from '../components/Zen/ZenCopyToClipBoard';
import ZenSidebarModal from '../components/Zen/ZenSidebarModal';
import config from '../config';
import { ADDRESS_PLACEHOLDER } from '../constants/PlaceholderConstants';
import { AddressResponseCountryEnum } from '../openapi/bff';
import {
  GlobalNotificationPreferencesDtoChannelsEnum,
  UpdateNotificationPreferencesDtoAddChannelsEnum,
  UpdateNotificationPreferencesDtoRemoveChannelsEnum,
  UserControllerApi,
} from '../openapi/hermes';
import {
  AddressRequest,
  AddressRequestCountryEnum,
  AddressRequestStateOrProvinceEnum,
  AdministrativeAreaResponseCountryEnum,
  AgentControllerApi,
  AgentResponse,
  AgentUpdatePlansRequestPlansConfigEnum,
  AgentUpdateRequest,
  AgentUpdateRequestCompetenciesEnum,
  FutureAgentPlanAgentPlansConfigEnum,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import {
  fetchAgentDetail,
  fetchDriverLicense,
  getGlobalNotificationStatus,
  saveDetail,
  stockOpIn,
  stockOpOut,
  updateAgentBusinessEmail,
  updateAgentDetail,
  updateGlobalNotificationSetting,
  uploadDriverLicense,
} from '../slices/AgentSlice';
import { showApiErrorModal } from '../slices/ErrorSlice';
import {
  showErrorToast,
  showErrorToastForErrorCode,
} from '../slices/ToastNotificationSlice';
import {
  fetchWealthPlanCommsSettings,
  updateWealthPlanCommsSettings,
} from '../slices/WealthManagementSlice';
import {
  AdminRoles,
  AgentAddressTypeEnum,
  AppDispatch,
  ISelectOption,
  LanguageType,
  RootState,
  YesNoType,
} from '../types';
import { hasError, isSlugAvailable } from '../utils/AgentHelper';
import { compareWealthPlanCommsSettings } from '../utils/AgentUtils';
import { getUpdateAgentDetailObject } from '../utils/ApiObjectUtils';
import {
  hasRequiredAdminActiveRole,
  parseRealUsername,
} from '../utils/AuthUtils';
import { cn } from '../utils/classUtils';
import { getISelectOptionDefaultValue } from '../utils/FormUtils';
import {
  getHermesConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import {
  capitalizeEnum,
  getFileNameFromUrl,
  WHITESPACE_REGEX,
} from '../utils/StringUtils';
import { getAddressFromAddressComponent } from '../utils/TransactionHelper';
import { parseUrl } from '../utils/UrlUtils';
import {
  EMAIL_VALIDATIONS,
  FILE_VALIDATIONS,
  getPostalCodeValidation,
  GOOGLE_AUTO_COMPLETE_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
  URL_VALIDATIONS,
} from '../utils/Validations';

interface ModifiedAddressRequest extends Omit<AddressRequest, 'country'> {
  country: ISelectOption<
    AddressRequestCountryEnum | AddressResponseCountryEnum
  >;
  autoComplete: GooglePlaceLocationType;
  isAutoComplete: boolean | undefined;
}

interface ZenProfileEditFormProps {
  isOpen: boolean;
  onClose(): void;
  agent: AgentResponse;
}

interface FormData
  extends Omit<
    AgentUpdateRequest,
    | 'languages'
    | 'receivesPaymentEmailNotifications'
    | 'receivesPaymentPushNotifications'
    | 'receivesTransactionEmailNotifications'
    | 'receivesTransactionPushNotifications'
    | 'addressRequest'
    | 'addressRequests'
  > {
  joinRealEmail: string;
  sponsorLink: string;
  tipaltiId: string;
  businessEmailAddress: string;
  languages: ISelectOption[];
  receivesPaymentEmailNotifications: YesNoType;
  receivesPaymentPushNotifications: YesNoType;
  receivesTransactionEmailNotifications: YesNoType;
  receivesTransactionPushNotifications: YesNoType;
  receivesWealthPlanEmailNotifications: YesNoType;
  receivesWealthPlanPushNotifications: YesNoType;
  nonReportable: YesNoType;
  stripeAccountId: string;
  awardsBanned: boolean;
  revshareBan?: string;
  addressRequest: Omit<AddressRequest, 'country'>;
  addressRequests: Array<ModifiedAddressRequest>;
  type: string;
  driverLicense: string;
  agentPlansConfig: ISelectOption;
  needsMentorship: boolean;
  hasTemporaryCommissionPlan: YesNoType;
  futureAgentPlan: {
    agentPlansConfig: ISelectOption<FutureAgentPlanAgentPlansConfigEnum>;
    agentPlansConfigStartDate: string;
  };
}

const ZenProfileEditForm: React.FC<ZenProfileEditFormProps> = ({
  isOpen,
  onClose,
  agent,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const {
    auth: { isAdmin, userDetail, keymakerCurrentUser },
    agentDetail: { notificationSetting },
    wealthManagement: { commsSettings },
  } = useSelector((state: RootState) => state);

  const hasPlanChangeAccess = hasRequiredAdminActiveRole(
    keymakerCurrentUser?.activeRoles || [],
    [AdminRoles.ONBOARDING, AdminRoles.SUPPORT2],
  );

  const isOnboardingRoleOnly = hasRequiredAdminActiveRole(
    keymakerCurrentUser?.activeRoles || [],
    [AdminRoles.ONBOARDING],
  );

  const yesNoOptions: ISelectOption[] = [
    { label: 'Yes', value: YesNoType.YES },
    { label: 'No', value: YesNoType.NO },
  ];

  const index = agent.joinRealEmail?.indexOf('@');
  const joinRealEmail = agent.joinRealEmail?.substring(0, index);
  const planMembershipName = agent?.planMembership?.commissionPlan?.name;

  const referralLink = `${config.reactAppHostUrl}/register?${qs.stringify({
    sponsorCode: agent.sponsorCode,
    sponsorName: `${agent.firstName} ${agent.lastName}`,
  })}`;

  const getChannelType = () => {
    const emailNotifications = watch('receivesTransactionEmailNotifications');
    const pushNotifications = watch('receivesTransactionPushNotifications');

    let addChannels = [];
    let removeChannels = [];

    if (emailNotifications === YesNoType.YES) {
      addChannels.push(UpdateNotificationPreferencesDtoAddChannelsEnum.Email);
    } else {
      removeChannels.push(
        UpdateNotificationPreferencesDtoRemoveChannelsEnum.Email,
      );
    }

    if (pushNotifications === YesNoType.YES) {
      addChannels.push(UpdateNotificationPreferencesDtoAddChannelsEnum.Push);
    } else {
      removeChannels.push(
        UpdateNotificationPreferencesDtoRemoveChannelsEnum.Push,
      );
    }

    return { addChannels, removeChannels };
  };

  const fetchNotificationsStatus = useCallback(async () => {
    const realUsername = parseRealUsername(agent?.joinRealEmail);
    if (!!realUsername) {
      await dispatch(getGlobalNotificationStatus(realUsername));
      dispatch(fetchWealthPlanCommsSettings());
    }
  }, [dispatch, agent]);

  useEffect(() => {
    fetchNotificationsStatus();
  }, [dispatch, fetchNotificationsStatus]);

  const defaultValues = {
    firstName: agent.firstName,
    middleName: agent.middleName,
    lastName: agent.lastName,
    displayName: agent.displayName,
    birthDate: agent.birthDate,
    addressRequests: agent.addresses?.map((address) => ({
      type: address?.type as string,
      city: address?.city || '',
      zipOrPostalCode: address?.zipOrPostalCode || '',
      streetAddress1: address?.streetAddress1 || '',
      streetAddress2: address?.streetAddress2 || '',
      stateOrProvince: (address?.stateOrProvince as unknown) as AddressRequestStateOrProvinceEnum,
      country: getISelectOptionDefaultValue(address?.country),
      autoComplete: {
        formatted_address: address?.oneLine || '',
        address_components: [],
        geometry: {
          location: {
            lat: undefined,
            lng: undefined,
          },
        },
        place_id: '',
      },
    })),
    personalPhoneNumber: agent.personalPhoneNumber,
    businessEmailAddress: agent.emailAddress,
    competencies: agent.competencies
      ? ((agent.competencies.map((competencies) => ({
          value: competencies,
          label: capitalizeEnum(competencies),
        })) as unknown) as AgentUpdateRequestCompetenciesEnum[])
      : undefined,
    googleBusinessProfileURL: agent.googleBusinessProfileURL,
    sponsorLink: referralLink,
    tipaltiId: agent.tipaltiId,
    joinRealEmail,
    instagramURL: agent.instagramURL,
    languages: agent.languages?.map((language) => ({
      label: capitalizeEnum(language),
      value: language,
    })),
    phoneNumber: agent.phoneNumber,
    slug: agent.slug,
    title: agent.title,
    optInToEquityPlan: !!agent.planMembership?.optInToEquityPlan
      ? ((YesNoType.YES as unknown) as boolean)
      : ((YesNoType.NO as unknown) as boolean),
    zillowURL: agent.zillowURL,
    yelpURL: agent.yelpURL,
    youtubeURL: agent.youtubeURL,
    personalWebsiteURL: agent.personalWebsiteURL,
    linkedInURL: agent.linkedInURL,
    serviceAreaZipcodes: agent.serviceAreaZipcodes,
    facebookURL: agent.facebookURL,
    about: agent.about,
    receivesTransactionEmailNotifications: notificationSetting?.channels?.includes(
      GlobalNotificationPreferencesDtoChannelsEnum.Email,
    )
      ? YesNoType.YES
      : YesNoType.NO,
    receivesTransactionPushNotifications: notificationSetting?.channels?.includes(
      GlobalNotificationPreferencesDtoChannelsEnum.Push,
    )
      ? YesNoType.YES
      : YesNoType.NO,
    receivesWealthPlanEmailNotifications: commsSettings.data?.enableEmails
      ? YesNoType.YES
      : YesNoType.NO,
    receivesWealthPlanPushNotifications: commsSettings?.data
      ?.enableNotifications
      ? YesNoType.YES
      : YesNoType.NO,
    twitterURL: agent.twitterURL,
    nonReportable: !!agent.nonReportable ? YesNoType.YES : YesNoType.NO,
    stripeAccountId: agent.stripeCustomerId,
    awardsBanned: agent.awardsBanned,
    revshareBan: !!agent.revshareBan
      ? DateTime.fromMillis(agent.revshareBan).toISO()
      : undefined,
    needsMentorship: agent.needsMentorship || false,
    agentPlansConfig: {
      label: !!planMembershipName ? capitalizeEnum(planMembershipName) : '',
      value: !!planMembershipName
        ? planMembershipName?.replace('_STANDARD', '')
        : '',
    },
    hasTemporaryCommissionPlan: !!agent?.nextAgentPlansConfig
      ? YesNoType.YES
      : YesNoType.NO,
    futureAgentPlan: {
      agentPlansConfig: !!agent?.nextAgentPlansConfig
        ? {
            label: capitalizeEnum(agent?.nextAgentPlansConfig),
            value: (agent?.nextAgentPlansConfig as unknown) as FutureAgentPlanAgentPlansConfigEnum,
          }
        : undefined,
      agentPlansConfigStartDate:
        agent?.temporaryPlanExpirationDate ??
        DateTime.local().plus({ year: 1 }).toISODate(),
    },
  };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    defaultValues,
    mode: 'onChange',
  });

  const [driverLicenseUrl, setDriverLicenseUrl] = useState<
    string | undefined
  >();

  const hasTemporaryCommissionPlan =
    watch('hasTemporaryCommissionPlan') === YesNoType.YES;

  const getDriverLicense = useCallback(async () => {
    const data = await dispatch(fetchDriverLicense(agent?.id!));
    setDriverLicenseUrl(data);
  }, [agent?.id, dispatch]);

  useEffect(() => {
    getDriverLicense();
  }, [getDriverLicense]);

  const driverLicenseName =
    driverLicenseUrl && getFileNameFromUrl(driverLicenseUrl) !== 'null'
      ? getFileNameFromUrl(driverLicenseUrl)
      : '';

  useEffect(() => {
    if (!!notificationSetting) {
      setValue(
        'receivesTransactionEmailNotifications',
        notificationSetting?.channels?.includes(
          GlobalNotificationPreferencesDtoChannelsEnum.Email,
        )
          ? YesNoType.YES
          : YesNoType.NO,
      );
      setValue(
        'receivesTransactionPushNotifications',
        notificationSetting?.channels?.includes(
          GlobalNotificationPreferencesDtoChannelsEnum.Push,
        )
          ? YesNoType.YES
          : YesNoType.NO,
      );
    }
    if (!!commsSettings) {
      setValue(
        'receivesWealthPlanEmailNotifications',
        commsSettings?.data?.enableEmails ? YesNoType.YES : YesNoType.NO,
      );
      setValue(
        'receivesWealthPlanPushNotifications',
        commsSettings?.data?.enableNotifications ? YesNoType.YES : YesNoType.NO,
      );
    }
  }, [commsSettings, notificationSetting, setValue]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addressRequests',
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const updateStockOptIn = (value: YesNoType) => {
    if (value === YesNoType.YES) {
      dispatch(stockOpIn(agent?.id!));
    } else {
      dispatch(stockOpOut(agent?.id!));
    }
  };

  const onSubmit = async (data: FormData) => {
    const { addChannels, removeChannels } = getChannelType();

    if (data.driverLicense) {
      await dispatch(uploadDriverLicense(agent?.id!, data.driverLicense[0]));
    }

    const wealthPlanNotifications = {
      enableEmails:
        data?.receivesWealthPlanEmailNotifications === YesNoType.YES
          ? true
          : false,
      enableNotifications:
        data?.receivesWealthPlanPushNotifications === YesNoType.YES
          ? true
          : false,
    };

    const iswealthPlanNotificationsChanged = compareWealthPlanCommsSettings(
      commsSettings.data!,
      wealthPlanNotifications,
    );

    if (iswealthPlanNotificationsChanged) {
      dispatch(updateWealthPlanCommsSettings(wealthPlanNotifications));
    }

    await dispatch(
      updateAgentDetail(
        agent.id!,
        getUpdateAgentDetailObject(
          agent,
          {
            ...data,
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            displayName: data.displayName,
            phoneNumber: data.phoneNumber,
            googleBusinessProfileURL: parseUrl(data.googleBusinessProfileURL!)!,
            instagramURL: parseUrl(data.instagramURL!)!,
            twitterURL: parseUrl(data.twitterURL!)!,
            serviceAreaZipcodes: data.serviceAreaZipcodes,
            languages: values(data.languages).map((language) => language.value),
            personalEmailAddress: data.personalEmailAddress,
            personalPhoneNumber: data.personalPhoneNumber,
            competencies: (data.competencies || [])?.map(
              (competencies: any) => competencies.value,
            ),
            slug: data.slug,
            about: data.about,
            receivesTransactionEmailNotifications:
              data.receivesTransactionEmailNotifications === YesNoType.YES,
            receivesTransactionPushNotifications:
              data.receivesTransactionPushNotifications === YesNoType.YES,
            sponsorLink: referralLink,
            tipaltiId: data.tipaltiId,
            addressRequests: data.addressRequests?.map((addr) => ({
              type: addr.type,
              country:
                !addr.isAutoComplete || !addr.autoComplete.place_id
                  ? addr.country?.value
                  : getAddressFromAddressComponent(
                      addr.autoComplete?.address_components!,
                    )?.country,
              streetAddress1:
                !addr.isAutoComplete || !addr.autoComplete.place_id
                  ? addr.streetAddress1!
                  : getAddressFromAddressComponent(
                      addr.autoComplete?.address_components!,
                    )?.streetAddress1,
              streetAddress2:
                !addr.isAutoComplete || !addr.autoComplete.place_id
                  ? addr.streetAddress2!
                  : getAddressFromAddressComponent(
                      addr.autoComplete?.address_components!,
                    )?.streetAddress2,
              city:
                !addr.isAutoComplete || !addr.autoComplete.place_id
                  ? addr.city!
                  : getAddressFromAddressComponent(
                      addr.autoComplete?.address_components!,
                    )?.city,
              stateOrProvince:
                !addr.isAutoComplete || !addr.autoComplete.place_id
                  ? addr.stateOrProvince!
                  : getAddressFromAddressComponent(
                      addr.autoComplete?.address_components!,
                    )?.stateOrProvince,
              zipOrPostalCode:
                !addr.isAutoComplete || !addr.autoComplete.place_id
                  ? addr.zipOrPostalCode!
                  : getAddressFromAddressComponent(
                      addr.autoComplete?.address_components!,
                    )?.zipOrPostalCode,
            })),
            title: data.title,
            zillowURL: parseUrl(data.zillowURL!)!,
            yelpURL: parseUrl(data.yelpURL!)!,
            youtubeURL: parseUrl(data.youtubeURL!)!,
            linkedInURL: parseUrl(data.linkedInURL!)!,
            facebookURL: parseUrl(data.facebookURL!)!,
            personalWebsiteURL: parseUrl(data.personalWebsiteURL!)!,
          },
          { omitAddressRequest: true, includeAddressRequests: true },
        ),
      ),
    );

    // update business email address
    if (data.businessEmailAddress !== agent.emailAddress) {
      await dispatch(
        updateAgentBusinessEmail(agent.id!, {
          newBusinessEmailRequest: data.businessEmailAddress,
        }),
      );
    }

    const newJoinRealEmail = `${data.joinRealEmail.trim()}@${
      config.joinRealEmailDomain
    }`;

    if (!!data?.joinRealEmail && agent.joinRealEmail !== newJoinRealEmail) {
      // update onereal email
      try {
        const { data } = await new AgentControllerApi(
          getYentaConfiguration(),
        ).updateJoinRealEmail(agent.id!, {
          newJoinRealEmail,
        });
        dispatch(saveDetail({ name: 'detail', loading: false, data }));
      } catch (e) {
        dispatch(showApiErrorModal(e));
        ErrorService.notify('Unable to update the onereal email', e, {
          agent: { id: agent.id },
        });
        dispatch(
          showErrorToast(
            'We had a problem updating the onereal email',
            'Please try again in a few moments.',
          ),
        );
      }
    }

    // update notification settings
    if (!!data.joinRealEmail) {
      dispatch(
        updateGlobalNotificationSetting(data.joinRealEmail, {
          addChannels,
          removeChannels,
        }),
      );
    }

    if (isAdmin) {
      if (defaultValues.optInToEquityPlan !== data.optInToEquityPlan)
        updateStockOptIn((data.optInToEquityPlan as unknown) as YesNoType);

      try {
        await new AgentControllerApi(
          getYentaConfiguration(),
        ).updateAgentByIdAdmin1(agent.id!, {
          tipaltiId: data.tipaltiId,
          nonReportable: data.nonReportable === YesNoType.YES,
          stripeCustomerId: data.stripeAccountId,
          awardsBanned: data.awardsBanned,
          revshareBan: !!data.revshareBan
            ? DateTime.fromISO(data.revshareBan).toMillis()
            : ((null as unknown) as undefined),
        });
      } catch (e) {
        ErrorService.notify('Unable to update agent detail', e, {
          agent: { agentId: agent.id },
        });
        dispatch(
          showErrorToastForErrorCode(
            'We had a problem updating the agents detail.',
            ErrorService.getErrorCode(e),
          ),
        );
      }

      if (hasPlanChangeAccess) {
        try {
          await new AgentControllerApi(getYentaConfiguration()).updatePlans(
            agent?.id!,
            {
              plansConfig: data?.agentPlansConfig
                ?.value as AgentUpdatePlansRequestPlansConfigEnum,
              futureAgentPlan:
                data?.hasTemporaryCommissionPlan === YesNoType.YES
                  ? {
                      agentPlansConfig: data?.futureAgentPlan?.agentPlansConfig
                        ?.value!,
                      agentPlansConfigStartDate: data?.futureAgentPlan
                        ?.agentPlansConfigStartDate!,
                    }
                  : undefined,
            },
          );
        } catch (e) {
          ErrorService.notify('Unable to update agent plan', e, {
            agent: { agentId: agent.id },
          });
          dispatch(showApiErrorModal(e));
        }
      }

      if (data.needsMentorship !== agent.needsMentorship) {
        try {
          await new AgentControllerApi(
            getYentaConfiguration(),
          ).updateNeedsMentorship(agent.id!, {
            needsMentorship: data.needsMentorship,
          });
        } catch (e) {
          ErrorService.notify('Unable to update Mentorship', e, {
            agent: { agentId: agent.id },
          });
          dispatch(showApiErrorModal(e));
        }
      }
    }

    await dispatch(fetchAgentDetail(agent.id!, false));

    onClose();
  };

  const isAddAnotherAddressDisabled = fields.length >= 3;

  return (
    <ZenSidebarModal
      title='Update Profile'
      isOpen={isOpen}
      onClose={() => onClose()}
      subtitle='Includes general, professional info, and integrations with 3rd party systems'
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='edit-profile-form'
      >
        <div className='pb-[100px]'>
          <ZenAccordion
            title='Basic Information'
            canToggle={
              !hasError(errors, ['firstName', 'lastName', 'birthDate'])
            }
            isOpenDefault
          >
            <>
              <div className='grid grid-cols-2 px-4 py-4 gap-x-3 gap-y-4'>
                <AdminOnly>
                  <div>
                    <ZenControlledTextInput<FormData, 'firstName'>
                      control={control}
                      label='First Name'
                      name='firstName'
                      placeholder='E.g. John'
                      shouldUnregister={false}
                      rules={{ required: 'First Name is required' }}
                      isRequired
                    />
                  </div>
                  <div>
                    <ZenControlledTextInput<FormData, 'middleName'>
                      control={control}
                      label='Middle Name'
                      name='middleName'
                      shouldUnregister={false}
                      placeholder='E.g. Elias'
                    />
                  </div>
                  <div>
                    <ZenControlledTextInput<FormData, 'lastName'>
                      control={control}
                      label='Last Name'
                      name='lastName'
                      shouldUnregister={false}
                      placeholder='E.g. Doe'
                      rules={{ required: 'Last Name is required' }}
                      isRequired
                    />
                  </div>
                </AdminOnly>

                <div>
                  <ZenControlledTextInput<FormData, 'displayName'>
                    control={control}
                    label='Display Name'
                    name='displayName'
                    placeholder='E.g. John'
                    shouldUnregister={false}
                    rules={{ required: 'Display Name is required' }}
                    isRequired
                  />
                </div>

                <div>
                  <ZenControlledDatePickerInput<FormData, 'birthDate'>
                    name='birthDate'
                    control={control}
                    label='Birthday Date'
                    shouldUnregister={false}
                    placeholder='E.g. 10/26'
                    rules={{
                      required: 'Required',
                    }}
                    isRequired
                  />
                </div>
                <div>
                  <ZenControlledPhoneNumberInput<
                    FormData,
                    'personalPhoneNumber'
                  >
                    control={control}
                    label='Personal Phone Number'
                    name='personalPhoneNumber'
                    placeholder='+1 (702) 123-4567'
                    defaultValue={agent.phoneNumber}
                    rules={{
                      ...PHONE_NUMBER_VALIDATIONS,
                    }}
                    shouldUnregister={false}
                  />
                </div>
              </div>
              <div className='p-4'>
                <ZenControlledFileUploadInput<FormData, 'driverLicense'>
                  name='driverLicense'
                  control={control}
                  label="Driver's License Image"
                  placeholder={driverLicenseName || 'E.g. Driver License.pdf'}
                  accept='application/pdf, image/*'
                  rightAction={
                    driverLicenseName
                      ? {
                          text: 'View License Image',
                          onClick() {
                            window.open(driverLicenseUrl, '_blank');
                          },
                        }
                      : undefined
                  }
                  rules={{
                    ...FILE_VALIDATIONS,
                  }}
                />
              </div>
            </>
          </ZenAccordion>

          <ZenAccordion
            title='Addresses'
            canToggle={!hasError(errors, ['addressRequests'])}
            isOpenDefault
          >
            <div className='px-4 py-4 space-y-4'>
              {fields.map((field, index) => {
                const [countrySelected, isAutoComplete, addresses] = watch([
                  `addressRequests.${index}.country`,
                  `addressRequests.${index}.isAutoComplete`,
                  'addressRequests',
                ]);
                const showDeleteBtn = index !== 0;
                const otherAddressTypes = addresses
                  ?.filter((_, i) => i !== index)
                  .map((f) => f.type);

                return (
                  <div key={field.id} className='space-y-4'>
                    <div className='flex flex-row items-start justify-between'>
                      <ZenControlledRadioInput<
                        FormData,
                        `addressRequests.${number}.type`
                      >
                        control={control}
                        label='Address Type'
                        name={`addressRequests.${index}.type`}
                        shouldUnregister={false}
                        options={values(AgentAddressTypeEnum).map((type) => ({
                          value: type,
                          label: capitalizeEnum(type),
                        }))}
                        rules={{
                          required: 'Required',
                          validate: (val) => {
                            if (val && otherAddressTypes?.includes(val)) {
                              return "Can't have multiple addresses of the same type";
                            }
                            return undefined;
                          },
                        }}
                        inlineOptions
                        isRequired
                      />
                      <div className={cn(showDeleteBtn && `mr-8`)}>
                        <IconButton
                          label={
                            !isAutoComplete || isAutoComplete === undefined
                              ? 'Search Address'
                              : 'Enter address manually'
                          }
                          variant='none'
                          buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
                          leftIcon={
                            <FontAwesomeIcon
                              icon={faArrowUpArrowDown}
                              className='mx-1'
                            />
                          }
                          onClick={() => {
                            setValue(
                              `addressRequests.${index}.isAutoComplete`,
                              !isAutoComplete,
                            );
                          }}
                        />
                      </div>
                      {showDeleteBtn && (
                        <button className='mt-2 mr-3'>
                          <FontAwesomeIcon
                            onClick={() => remove(index)}
                            icon={faTrashCan}
                            className='text-zen-danger'
                          />
                        </button>
                      )}
                    </div>

                    {isAutoComplete ? (
                      <ZenControlledGoogleAutocompleteSearchInput<
                        FormData,
                        `addressRequests.${number}.autoComplete`
                      >
                        control={control}
                        shouldUnregister={false}
                        name={`addressRequests.${index}.autoComplete`}
                        label='Search Address'
                        subLabel='(Source by Google)'
                        placeholder='E.g. 1st St. New York, NY 10010'
                        rules={{
                          ...GOOGLE_AUTO_COMPLETE_VALIDATIONS,
                        }}
                        isRequired
                      />
                    ) : (
                      <>
                        {' '}
                        <div className='w-1/2'>
                          <ZenControlledSelectInput<
                            FormData,
                            `addressRequests.${number}.country`
                          >
                            name={`addressRequests.${index}.country`}
                            control={control}
                            label='Country'
                            shouldUnregister={false}
                            placeholder='Country'
                            options={values(
                              AdministrativeAreaResponseCountryEnum,
                            ).map((state) => ({
                              value: state,
                              label: capitalizeEnum(state),
                            }))}
                            rules={{
                              required: 'Please select country',
                            }}
                            isRequired
                          />
                        </div>
                        <div>
                          <ZenControlledTextInput<
                            FormData,
                            `addressRequests.${number}.streetAddress1`
                          >
                            control={control}
                            label='Street Address Line 1'
                            name={`addressRequests.${index}.streetAddress1`}
                            shouldUnregister={false}
                            placeholder={ADDRESS_PLACEHOLDER}
                            rules={{
                              required: 'Required',
                            }}
                            isRequired
                          />
                        </div>
                        <div>
                          <ZenControlledTextInput<
                            FormData,
                            `addressRequests.${number}.streetAddress2`
                          >
                            control={control}
                            label='Street Address Line 2'
                            name={`addressRequests.${index}.streetAddress2`}
                            shouldUnregister={false}
                            placeholder={ADDRESS_PLACEHOLDER}
                          />
                        </div>
                        <div>
                          <ZenControlledTextInput<
                            FormData,
                            `addressRequests.${number}.city`
                          >
                            control={control}
                            label='City'
                            name={`addressRequests.${index}.city`}
                            shouldUnregister={false}
                            placeholder='City'
                            rules={{
                              required: 'Required',
                            }}
                            isRequired
                          />
                        </div>
                        <div>
                          <ZenControlledStateOrProvinceInput<
                            FormData,
                            `addressRequests.${number}.stateOrProvince`
                          >
                            name={`addressRequests.${index}.stateOrProvince`}
                            control={control}
                            shouldUnregister={false}
                            setValue={setValue}
                            rules={{ required: 'Required' }}
                            selectedCountry={
                              countrySelected?.value as AddressRequestCountryEnum
                            }
                            isRequired
                          />
                        </div>
                        <div>
                          <ZenControlledTextInput<
                            FormData,
                            `addressRequests.${number}.zipOrPostalCode`
                          >
                            control={control}
                            label='Postal Code'
                            name={`addressRequests.${index}.zipOrPostalCode`}
                            shouldUnregister={false}
                            placeholder='Postal Code'
                            rules={{
                              required: 'Required',
                              ...getPostalCodeValidation(
                                countrySelected?.value as AddressRequestCountryEnum,
                              ),
                            }}
                            isRequired
                          />
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
              <button
                className='flex items-center space-x-2'
                disabled={isAddAnotherAddressDisabled}
                type='button'
                onClick={() =>
                  append({
                    country: getISelectOptionDefaultValue(
                      (userDetail?.accountCountry as unknown) as AddressRequestCountryEnum,
                    ),
                    type: undefined,
                    city: undefined,
                    stateOrProvince: undefined,
                    streetAddress1: undefined,
                    streetAddress2: undefined,
                    zipOrPostalCode: undefined,
                  })
                }
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  className={cn(
                    'text-base',
                    isAddAnotherAddressDisabled
                      ? 'text-grey-300 cursor-not-allowed'
                      : 'text-primary-blue',
                  )}
                />
                <p
                  className={cn(
                    'text-sm font-zen-body font-semibold',
                    isAddAnotherAddressDisabled
                      ? 'text-grey-300 cursor-not-allowed'
                      : 'text-primary-blue',
                  )}
                >
                  Add another address
                </p>
              </button>
            </div>
          </ZenAccordion>

          <ZenAccordion
            title='Professional/Business Information'
            canToggle={!hasError(errors, ['competencies', 'phoneNumber'])}
            isOpenDefault
          >
            <div className='px-4 py-4 space-y-4'>
              <div>
                <ZenControlledTextInput<FormData, 'title'>
                  control={control}
                  label='Title'
                  name='title'
                  shouldUnregister={false}
                  placeholder='Eg. Realtor'
                />
              </div>
              <div className='flex flex-row space-x-3'>
                <div className='flex-1'>
                  <ZenControlledPhoneNumberInput<FormData, 'phoneNumber'>
                    control={control}
                    label='Business Phone Number'
                    name='phoneNumber'
                    placeholder='+1 (702) 123-4567'
                    rules={{
                      required: 'Please enter the phone number',
                      ...PHONE_NUMBER_VALIDATIONS,
                    }}
                    shouldUnregister={false}
                    isRequired
                  />
                </div>
                <div className='flex-1'>
                  <ZenControlledTextInput<FormData, 'businessEmailAddress'>
                    type='email'
                    control={control}
                    label='Business Email Address'
                    shouldUnregister={false}
                    name='businessEmailAddress'
                    placeholder='Eg. johndoe@business.com'
                    rules={{
                      ...EMAIL_VALIDATIONS,
                      required: 'Required',
                      validate: async (value) => {
                        if (agent.emailAddress !== value) {
                          try {
                            const {
                              data: emailAddress,
                            } = await new UserControllerApi(
                              getHermesConfiguration(),
                            ).validateEmail(value);
                            return !emailAddress.available
                              ? emailAddress?.reason
                              : undefined;
                          } catch (e) {
                            ErrorService.notify(
                              'Unable to verify if the business email is valid or not',
                              e,
                              {
                                agent: { id: agent.id },
                              },
                            );
                            return "Couldn't verify the email. Please try again later.";
                          }
                        }
                        return;
                      },
                    }}
                    isRequired
                  />
                </div>
              </div>
              <div>
                <ZenControlledMultiSelectInput<FormData, 'competencies'>
                  name='competencies'
                  control={control}
                  label='Works with'
                  shouldUnregister={false}
                  placeholder='Select Competencies'
                  options={values(AgentUpdateRequestCompetenciesEnum).map(
                    (competencies) => ({
                      value: competencies,
                      label: capitalizeEnum(competencies),
                    }),
                  )}
                  closeMenuOnSelect={false}
                />
              </div>
              <div>
                <ZenControlledTextAreaInput<FormData, 'serviceAreaZipcodes'>
                  control={control}
                  label='Service area postal codes'
                  shouldUnregister={false}
                  placeholder='Eg. 25323,23453'
                  name='serviceAreaZipcodes'
                  rows={3}
                />
              </div>
              <div>
                <ZenControlledMultiSelectInput<FormData, 'languages'>
                  control={control}
                  name='languages'
                  label='Languages'
                  shouldUnregister={false}
                  placeholder='Please select languages'
                  options={keys(LanguageType).map((language) => ({
                    label: capitalizeEnum(language),
                    value: language,
                  }))}
                  closeMenuOnSelect={false}
                />
              </div>
              <div>
                <ZenControlledTextAreaInput<FormData, 'about'>
                  control={control}
                  label='Bio'
                  name='about'
                  shouldUnregister={false}
                  placeholder='Biographical profile to share with clients'
                  rows={2}
                />
              </div>
            </div>
          </ZenAccordion>
          <ZenAccordion
            title='Real Related Information'
            canToggle={!hasError(errors, ['joinRealEmail'])}
            isOpenDefault
          >
            <div className='px-4 py-4 space-y-4'>
              <AdminOnly>
                <div>
                  <ZenControlledRadioInput<FormData, 'optInToEquityPlan'>
                    name='optInToEquityPlan'
                    control={control}
                    label='Stock Opt-In'
                    shouldUnregister={false}
                    options={[
                      { label: 'Yes', value: YesNoType.YES },
                      { label: 'No', value: YesNoType.NO },
                    ]}
                    inlineOptions
                  />
                </div>
              </AdminOnly>
              <div className='flex items-end space-x-2'>
                <div className='flex-1'>
                  <ZenControlledWebsiteURLTextInput<FormData, 'slug'>
                    name='slug'
                    label='Agent Website Slug'
                    leftWebsiteSegment='www.onereal.com/'
                    shouldUnregister={false}
                    control={control}
                    rules={{
                      validate: (slug) =>
                        !!slug && agent.slug !== slug
                          ? isSlugAvailable(slug)
                          : undefined,
                    }}
                  />
                </div>
                <div className='w-40 text-sm'>
                  {agent.hasAgentWebsite ? (
                    <button
                      onClick={() => {
                        onClose();
                        history.push(`/people/${agent?.id}/agent-website`);
                      }}
                      className='inline-block pr-2 pb-2 text-center rounded focus:outline-none min-w-max text-primary-blue'
                    >
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> Update
                      your website
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        onClose();
                        history.push(
                          `/people/${agent?.id}/agent-website-onboarding`,
                        );
                      }}
                      className='inline-block pr-2 pb-2 text-center rounded focus:outline-none min-w-max text-primary-blue'
                    >
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> Create
                      your website
                    </button>
                  )}
                </div>
              </div>

              <div className='flex items-end space-x-2'>
                <div className='flex-1'>
                  <ZenControlledTextInput<FormData, 'joinRealEmail'>
                    name='joinRealEmail'
                    control={control}
                    label='JoinReal Email'
                    shouldUnregister={false}
                    type='text'
                    endAdornment={
                      <span className='flex items-center h-full px-4 bg-grey-100'>
                        <p>@{config.joinRealEmailDomain}</p>
                      </span>
                    }
                    rules={{
                      required: 'Required',
                      validate: async (value) => {
                        if (value.includes('@')) {
                          return "Don't include @ symbol and the domain name";
                        }

                        if (WHITESPACE_REGEX.test(value?.trim())) {
                          return 'Cannot contain any whitespace.';
                        }

                        const newJoinRealEmail = `${value.trim()}@${
                          config.joinRealEmailDomain
                        }`;

                        if (agent.joinRealEmail !== newJoinRealEmail) {
                          try {
                            const {
                              data: emailAddress,
                            } = await new UserControllerApi(
                              getHermesConfiguration(),
                            ).isJoinRealEmailAvailable(newJoinRealEmail);
                            return !emailAddress.available
                              ? 'Email is already taken'
                              : undefined;
                          } catch (e) {
                            ErrorService.notify(
                              'Unable to verify if the onereal email is valid or not',
                              e,
                              {
                                agent: { id: agent.id },
                              },
                            );
                            return "Couldn't verify the email. Please try again later.";
                          }
                        }

                        return undefined;
                      },
                    }}
                    isRequired
                  />
                </div>
                <div className='w-40 text-sm'>
                  <ZenCopyToClipboard
                    label='Copy Email'
                    value={agent.joinRealEmail!}
                  />
                </div>
              </div>
              <div className='flex items-end space-x-2'>
                <div className='flex-1'>
                  <ZenControlledTextInput<FormData, 'sponsorLink'>
                    control={control}
                    label='Sponsor Link'
                    name='sponsorLink'
                    shouldUnregister={false}
                    placeholder='arden-greer'
                    readOnly
                  />
                </div>
                <div className='w-40 text-sm'>
                  <ZenCopyToClipboard label='Copy Link' value={referralLink} />
                </div>
              </div>
              <AdminOnly>
                <div>
                  <ZenControlledTextInput<FormData, 'tipaltiId'>
                    control={control}
                    label='Tipalti ID'
                    name='tipaltiId'
                    shouldUnregister={false}
                    placeholder='Not Connected'
                  />
                </div>
                <div className='flex items-center space-x-3'>
                  <p>Tipalti Integrated?</p>
                  <ZenBooleanButton value={agent.tipaltiConfirmed!} />
                </div>
              </AdminOnly>
            </div>
          </ZenAccordion>
          <ZenAccordion
            title='Social Media Links'
            canToggle={
              !hasError(errors, [
                'facebookURL',
                'twitterURL',
                'instagramURL',
                'youtubeURL',
                'linkedInURL',
                'zillowURL',
                'yelpURL',
                'googleBusinessProfileURL',
              ])
            }
            isOpenDefault
          >
            <div className='px-4 py-4 space-y-4'>
              <div>
                <ZenControlledTextInput<FormData, 'personalWebsiteURL'>
                  control={control}
                  label='Personal Website'
                  name='personalWebsiteURL'
                  shouldUnregister={false}
                  placeholder='E.g. mywebsite.com'
                  rules={{ ...URL_VALIDATIONS }}
                />
              </div>
              <div>
                <ZenControlledTextInput<FormData, 'facebookURL'>
                  control={control}
                  label='Facebook Link'
                  name='facebookURL'
                  shouldUnregister={false}
                  placeholder='E.g. facebook.com/myprofilename'
                  rules={{ ...URL_VALIDATIONS }}
                />
              </div>
              <div>
                <ZenControlledTextInput<FormData, 'twitterURL'>
                  control={control}
                  label='Twitter Link'
                  name='twitterURL'
                  shouldUnregister={false}
                  placeholder='E.g. twitter.com/myprofilename'
                  rules={{ ...URL_VALIDATIONS }}
                />
              </div>
              <div>
                <ZenControlledTextInput<FormData, 'instagramURL'>
                  control={control}
                  label='Instagram Link'
                  name='instagramURL'
                  shouldUnregister={false}
                  placeholder='E.g. instagran.com/myprofilename'
                  rules={{ ...URL_VALIDATIONS }}
                />
              </div>
              <div>
                <ZenControlledTextInput<FormData, 'youtubeURL'>
                  control={control}
                  label='Youtube Link'
                  name='youtubeURL'
                  shouldUnregister={false}
                  placeholder='E.g. youtube.com/myprofilename'
                  rules={{ ...URL_VALIDATIONS }}
                />
              </div>
              <div>
                <ZenControlledTextInput<FormData, 'linkedInURL'>
                  control={control}
                  label='LinkedIn Link'
                  name='linkedInURL'
                  shouldUnregister={false}
                  placeholder='E.g. linkedin.com/myprofilename'
                  rules={{ ...URL_VALIDATIONS }}
                />
              </div>
              <div>
                <ZenControlledTextInput<FormData, 'zillowURL'>
                  control={control}
                  label='Zillow Link'
                  name='zillowURL'
                  shouldUnregister={false}
                  placeholder='E.g. zillow.com/myprofilename'
                  rules={{ ...URL_VALIDATIONS }}
                />
              </div>
              <div>
                <ZenControlledTextInput<FormData, 'yelpURL'>
                  control={control}
                  label='Yelp Link'
                  name='yelpURL'
                  shouldUnregister={false}
                  placeholder='E.g. yelp.com/myprofilename'
                  rules={{ ...URL_VALIDATIONS }}
                />
              </div>
              <div>
                <ZenControlledTextInput<FormData, 'googleBusinessProfileURL'>
                  control={control}
                  label='Google Business Profile Link'
                  name='googleBusinessProfileURL'
                  shouldUnregister={false}
                  placeholder='E.g. mybusinessname.business.site'
                  rules={{ ...URL_VALIDATIONS }}
                />
              </div>
            </div>
          </ZenAccordion>

          <ZenAccordion title='Notifications' isOpenDefault>
            <div className='grid grid-cols-2 px-4 py-4 gap-y-4'>
              <div>
                <ZenControlledRadioInput<
                  FormData,
                  'receivesTransactionEmailNotifications'
                >
                  name='receivesTransactionEmailNotifications'
                  control={control}
                  label='Get Transaction Email Notification?'
                  shouldUnregister={false}
                  options={yesNoOptions}
                  inlineOptions
                />
              </div>
              <div>
                <ZenControlledRadioInput<
                  FormData,
                  'receivesTransactionPushNotifications'
                >
                  name='receivesTransactionPushNotifications'
                  control={control}
                  label='Get Transaction Push Notification?'
                  shouldUnregister={false}
                  options={yesNoOptions}
                  inlineOptions
                />
              </div>
              <div>
                <ZenControlledRadioInput<
                  FormData,
                  'receivesWealthPlanEmailNotifications'
                >
                  name='receivesWealthPlanEmailNotifications'
                  control={control}
                  label='Get WealthPlan Email Notification?'
                  shouldUnregister={false}
                  options={yesNoOptions}
                  inlineOptions
                />
              </div>
              <div>
                <ZenControlledRadioInput<
                  FormData,
                  'receivesWealthPlanPushNotifications'
                >
                  name='receivesWealthPlanPushNotifications'
                  control={control}
                  label='Get WealthPlan Push Notification?'
                  shouldUnregister={false}
                  options={yesNoOptions}
                  inlineOptions
                />
              </div>
            </div>
          </ZenAccordion>

          <AdminOnly>
            <ZenAccordion title='Admin Attributes' isOpenDefault>
              <div className='grid grid-cols-2 px-4 py-4 gap-y-4 pb-20'>
                <div>
                  <ZenControlledRadioInput<FormData, 'nonReportable'>
                    name='nonReportable'
                    control={control}
                    label='Non Reportable'
                    shouldUnregister={false}
                    options={yesNoOptions}
                    inlineOptions
                  />
                </div>
                <div>
                  <ZenControlledTextInput<FormData, 'stripeAccountId'>
                    name='stripeAccountId'
                    control={control}
                    label='Stripe Customer ID'
                    shouldUnregister={false}
                    placeholder='Stripe Customer ID'
                  />
                </div>

                <div className='col-span-2 flex items-center justify-between w-full py-3 '>
                  <label
                    htmlFor='urgent'
                    className='font-zen-body font-semibold'
                  >
                    Award Banned?
                  </label>
                  <div>
                    <ZenControlledToggleInput<FormData, 'awardsBanned'>
                      name='awardsBanned'
                      control={control}
                      label=''
                    />
                  </div>
                </div>
                <div className='col-span-2 space-y-5'>
                  <ZenControlledDatePickerInput<FormData, 'revshareBan'>
                    name='revshareBan'
                    control={control}
                    label='Revshare Banned?'
                    shouldUnregister={false}
                    placeholder='Select a date'
                    datePickerConfig={{
                      showTimeSelect: true,
                      dateFormat: 'MM/dd/yyyy h:mm aa',
                    }}
                  />

                  {hasPlanChangeAccess && (
                    <div className='my-2 p-3 rounded-lg bg-grey-100 space-y-4'>
                      <ZenControlledSelectInput<FormData, 'agentPlansConfig'>
                        name='agentPlansConfig'
                        control={control}
                        label='Commission Plan'
                        placeholder='Choose Commission Plan Name'
                        options={[
                          ...values(AgentUpdatePlansRequestPlansConfigEnum).map(
                            (plan) => ({
                              value: plan,
                              label: capitalizeEnum(plan),
                            }),
                          ),
                        ]}
                        rules={{
                          required: 'Please choose Commission Plan',
                        }}
                        styles={{
                          menuList(base) {
                            return {
                              ...base,
                              maxHeight: '160px',
                            };
                          },
                        }}
                      />
                      <ZenControlledRadioInput<
                        FormData,
                        'hasTemporaryCommissionPlan'
                      >
                        name='hasTemporaryCommissionPlan'
                        rules={{
                          required: 'Please choose one',
                        }}
                        options={[
                          {
                            label: 'Permanent',
                            value: YesNoType.NO,
                          },
                          {
                            label: 'Temporary',
                            value: YesNoType.YES,
                          },
                        ]}
                        control={control}
                        inlineOptions
                        disabled={!isOnboardingRoleOnly}
                      />
                      {hasTemporaryCommissionPlan && (
                        <>
                          <ZenControlledSelectInput<
                            FormData,
                            'futureAgentPlan.agentPlansConfig'
                          >
                            name='futureAgentPlan.agentPlansConfig'
                            control={control}
                            placeholder='Select Future Plan Membership'
                            shouldUnregister={false}
                            options={[
                              {
                                label: 'Select Future Plan Membership',
                                value: '',
                                disabled: true,
                              },
                              ...values(
                                FutureAgentPlanAgentPlansConfigEnum,
                              ).map((plan) => ({
                                value: plan,
                                label: capitalizeEnum(plan),
                              })),
                            ]}
                            rules={{
                              required:
                                'Please choose future plan membership name',
                            }}
                            styles={{
                              menuList(base) {
                                return {
                                  ...base,
                                  maxHeight: '200px',
                                };
                              },
                            }}
                            disabled={!isOnboardingRoleOnly}
                          />

                          <ZenControlledDatePickerInput
                            control={control}
                            label='Select Temporary Plan End Date'
                            name='futureAgentPlan.agentPlansConfigStartDate'
                            icon={
                              <FontAwesomeIcon
                                className='text-primary-blue'
                                icon={faCalendar}
                              />
                            }
                            datePickerConfig={{
                              minDate: DateTime.local()
                                .plus({ day: 1 })
                                .toJSDate(),
                            }}
                            placeholder='MM/DD/YYYY'
                            shouldUnregister={false}
                            readOnly={!isOnboardingRoleOnly}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>

                <div className='col-span-2 flex items-center justify-between w-full py-3 '>
                  <label
                    htmlFor='needsMentorship'
                    className='font-zen-body font-semibold'
                  >
                    Mentorship Needed?
                  </label>
                  <div>
                    <ZenControlledToggleInput<FormData, 'needsMentorship'>
                      name='needsMentorship'
                      control={control}
                      label=''
                    />
                  </div>
                </div>
              </div>
            </ZenAccordion>
          </AdminOnly>
        </div>

        <ZenSidebarModalActionFooter
          isSubmitting={isSubmitting}
          onClose={onClose}
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenProfileEditForm;
