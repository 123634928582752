import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { withAsyncPaginate } from 'react-select-async-paginate';
import Creatable from 'react-select/creatable';
import { DEFAULT_PAGE_NUM } from '../constants/AsyncSelectPaginationConstants';
import { AsyncSelectAdditional, AsyncSelectOption } from '../types';
import FormErrorMessage from './FormErrorMessage';
import { ASYNC_MULTI_SELECT_DEBOUNCE } from './Zen/Input/constants';

interface ControlledAsyncSelectCreatableInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  createLabelPrefix: string;
  fetchData(search: string, page?: number): Promise<Array<AsyncSelectOption>>;
}
const CreatableAsyncPaginate = withAsyncPaginate(Creatable);
const ControlledAsyncSelectCreatableInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  placeholder = 'Select',
  disabled = false,
  readOnly,
  fetchData,
  shouldUnregister = true,
  createLabelPrefix = 'Create label',
  ...rest
}: ControlledAsyncSelectCreatableInputV7Props<TFieldValues, TName>) => {
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <div className='space-y-1 w-full'>
          {label && <label htmlFor={name}>{label}</label>}
          <CreatableAsyncPaginate
            formatCreateLabel={(label: string) =>
              `${label} (${createLabelPrefix})`
            }
            placeholder={placeholder}
            defaultValue={value}
            name={name}
            isMulti={false}
            // @ts-ignore
            loadOptions={async (
              search,
              _additionalOptions,
              { page }: AsyncSelectAdditional,
            ) => {
              const data = await fetchData(search, page);
              return {
                options: data,
                hasMore: !!data.length,
                additional: {
                  page: page + 1,
                },
              };
            }}
            value={value}
            closeMenuOnSelect={false}
            additional={{
              page: DEFAULT_PAGE_NUM,
            }}
            debounceTimeout={ASYNC_MULTI_SELECT_DEBOUNCE}
            onChange={onChange}
            onBlur={onBlur}
            className='react-select-container'
            classNamePrefix='react-select'
            inputId={name}
            aria-label={label}
          />
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};
export default ControlledAsyncSelectCreatableInputV7;
