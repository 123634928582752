import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { z } from 'zod';
import { TransactionCoordinatorAccessInfo } from '../../constants/TransactionConstants';
import { TransactionCoordinatorAccessEnum } from '../../types';
import { cn } from '../../utils/classUtils';
import { HookFormDatePickerInput } from '../commons/hookFormInputs/HookFormDatePickerInput';

const ACCESS_OPTION_TYPES: Array<TransactionCoordinatorAccessEnum> = [
  TransactionCoordinatorAccessEnum.ALL_OPEN_LISTINGS_AND_TRANSACTIONS,
  TransactionCoordinatorAccessEnum.CREATED_AFTER,
  TransactionCoordinatorAccessEnum.CREATED_BY_THEM_OR_MANUALLY_ADDED,
];

export interface TransactionCoordinatorFromData {
  date: string;
  accessType: TransactionCoordinatorAccessEnum;
}

export const schema = z
  .object({
    date: z.date().optional(),
    accessType: z.enum([
      'ALL_OPEN_LISTINGS_AND_TRANSACTIONS',
      'CREATED_AFTER',
      'CREATED_BY_THEM_OR_MANUALLY_ADDED',
    ]),
  })
  .refine(
    ({ accessType, date }) => {
      if (accessType === 'CREATED_AFTER' && !date) {
        return false;
      }
      return true;
    },
    {
      path: ['date'],
      message: 'Date is required',
    },
  );

interface TCTransactionsAccessCardProps {
  control: Control<TransactionCoordinatorFromData, object>;
  setValue: UseFormSetValue<TransactionCoordinatorFromData>;
  selectedAccessType: TransactionCoordinatorAccessEnum;
}

const TCTransactionsAccessCard: FC<TCTransactionsAccessCardProps> = ({
  control,
  setValue,
  selectedAccessType,
}) => {
  return (
    <div className='flex flex-col gap-1 bg-regent-200 p-4 sm:p-6 rounded-lg font-inter'>
      <p className='text-sm sm:text-base font-medium text-primary-dark'>
        Which transactions/listings should they have access to?
      </p>
      {ACCESS_OPTION_TYPES.map((type) => {
        const accessType = TransactionCoordinatorAccessEnum[type];
        const { title, description, icon } = TransactionCoordinatorAccessInfo[
          accessType
        ];
        return (
          <div
            key={type}
            className={cn('flex gap-3 sm:gap-6 cursor-pointer p-4 rounded-lg', {
              'bg-white border border-rezen-blue-600':
                selectedAccessType === type,
            })}
            onClick={() => setValue('accessType', accessType)}
          >
            <FontAwesomeIcon
              icon={icon}
              size={type === 'CREATED_BY_THEM_OR_MANUALLY_ADDED' ? 'lg' : 'xl'}
              className={cn('mt-2', {
                'text-rezen-blue-600': selectedAccessType === type,
              })}
            />
            <div
              className={cn({
                '-ml-1': type === 'CREATED_BY_THEM_OR_MANUALLY_ADDED',
              })}
            >
              <p
                className={cn(
                  'text-sm sm:text-base font-normal text-primary-dark',
                  {
                    'text-rezen-blue-600': selectedAccessType === type,
                  },
                )}
              >
                {title}
              </p>
              <p className='text-xs sm:text-sm text-regent-600 font-light'>
                {description}
              </p>
              {type === 'CREATED_AFTER' && selectedAccessType === type && (
                <div className='w-fit mt-3'>
                  <HookFormDatePickerInput
                    control={control}
                    name='date'
                    placeholder='Select Date'
                    type='default'
                    required
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TCTransactionsAccessCard;
