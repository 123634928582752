import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthenticatedAdminRoute from '../components/AuthenticatedAdminRoute';
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import AuthenticatedSidebarRoute from '../components/AuthenticatedSidebarRoute';
import BrokerQueueEmptyRoute from '../components/BrokerQueueEmptyRoute';
import DesignCenterRedirect from '../components/DesignCenter/DesignCenterRedirect';
import GuestRoute from '../components/GuestRoute';
import CreateJointVentureSteps from '../components/JointVenture/CreateJointVentureSteps';
import LearnMore from '../components/JointVenture/LearnMore';
import JointVentureSignUpSteps from '../components/JointVenture/SignUp/JointVentureSignUpSteps';
import LearnworldsSsoRedirect from '../components/Learnworlds/LearnworldsSsoRedirect';
import CommissionDepositReceiptRoute from '../components/transactions/CommissionDeposits/CommissionDepositReceiptRoute';
import ZenTransactionCommissionDocumentReview from '../components/transactions/CommissionDocumentOnboarding/ZenTransactionCommissionDocumentReview';
import TransactionCommissionReview from '../components/transactions/TransactionCommissionReview';
import ZenCreateListingSteps from '../components/transactions/ZenCreateListing/ZenCreateListingSteps';
import WalletApplicationSteps from '../components/Wallet/Application/WalletApplicationSteps';
import WalletMyCreditRoute from '../components/Wallet/WalletCreditDashboard/WalletMyCreditRoute';
import WalletDashboardRoute from '../components/Wallet/WalletDashboard/WalletDashboardRoute';
import WalletDebitDashboardRoute from '../components/Wallet/WalletDebitDashboard/WalletDebitDashboardRoute';
import WalletOfferings from '../components/Wallet/WalletOfferings';
import ZenTransactionConveyanceDocumentPreviewRoute from '../components/Zen/ConveyanceDocuments/ZenTransactionConveyanceDocumentPreviewRoute';
import ZenAgreementDetailsRoute from '../components/Zen/Engineering/ZenAgreementDetailsRoute';
import ZenAmendmentsDetailsRoute from '../components/Zen/Engineering/ZenAmendmentsDetailsRoute';
import ZenCreateTransactionSteps from '../components/Zen/Transaction/CreateTransaction/ZenCreateTransactionSteps';
import ZenReferralTransactionSteps from '../components/Zen/Transaction/ReferralTransaction/ZenReferralTransactionSteps';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { FeatureFlagTypeEnum, RootState, UserType } from '../types';
import { isCanadianUser } from '../utils/AgentHelper';
import { isGeminiUIEnabled } from '../utils/switchToGeminiUtils';
import AboutRoute from './AboutRoute';
import AdminMortgagesRoute from './AdminMortgagesRoute';
import AgentEventsRoute from './AgentEventsRoute';
import AgentMortgagesRoute from './AgentMortgagesRoute';
import ApiDocsRoute from './ApiDocsRoute';
import ApiGenerationTermsOfServices from './ApiGenerationTermsOfServices';
import BankAccountsRoute from './BankAccountsRoute';
import BrokerDashboardDecider from './BrokerDashboardDecider';
import BrokerPowerAuditRoute from './BrokerPowerAuditRoute';
import CalendarsRoute from './CalendarsRoute';
import CDAFormRoute from './CDAFormRoute';
import ChangePasswordRoute from './ChangePassword/ChangePasswordRoute';
import ConversationDetailsRoute from './ConversationDetailsRoute';
import CreateWebhookRoute from './CreateWebhookRoute';
import DecideProfileDetailRoute from './DecideProfileDetailRoute';
import EnableTwoFactorAuthenticationRoute from './EnableTwoFactorAuthenticationRoute';
import ErrorRoutes from './ErrorRoutes';
import EventsRoute from './EventsRoute';
import FeatureFlagsRoute from './FeatureFlagsRoute';
import FinanceRoute from './FinanceRoute';
import GeminiAdminOrBrokerListingsIndexRoute from './GeminiAdminOrBrokerListingsIndexRoute';
import GeminiAdminOrBrokerTransactionsIndexRoute from './GeminiAdminOrBrokerTransactionsIndexRoute';
import GeminiAgentListingsIndexRoute from './GeminiAgentListingsIndexRoute';
import GeminiAgentTransactionsIndexRoute from './GeminiAgentTransactionsIndexRoute';
import GeminiOfficeDetailRoute from './GeminiOfficeDetailRoute';
import InstantPaymentsRoute from './InstantPaymentsRoute';
import InvoiceFormRoute from './InvoiceFormRoute';
import KafkaDashboardRoute from './KafkaDashboardRoute';
import LeoQuestionManagerRoute from './LeoQuestionManagerRoute';
import MortgageDetailsRoute from './MortgageDetailsRoute';
import NewBrokerPowerAuditRoute from './NewBrokerPowerAuditRoute';
import OneRealMortgageRouteV2 from './OneRealMortgageRouteV2';
import OperationsIndexRoute from './OperationsIndexRoute';
import RealClosingServicesRoute from './RealClosingServicesRoute';
import RealTitleIndexRoute from './RealTitleIndexRoute';
import RealTitleRoute from './RealTitleRoute';
import ReconcileTransactionsRoute from './ReconcileTransactionsRoute';
import ReferralApplicants from './referral/ReferralApplicantsRoute';
import ReferralRoute from './referral/ReferralRoute';
import ReportsRoute from './ReportsRoute';
import ResetPasswordRoute from './ResetPasswordRoute';
import Route404 from './Route404';
import ErrorIndex from './secret/ErrorsRoute';
import FlagsIndex from './secret/FlagsIndexRoute';
import SplitAndAttachPDFToChecklistRoute from './SplitAndAttachPDFToChecklistRoute';
import SuperAdminPeopleSearchRoute from './SuperAdminPeopleSearchRoute';
import SupportIndexRoute from './SupportIndexRoute';
import TransactionSetup from './transaction/setup/TransactionSetup';
import TransactionDetailsByCodeRoute from './TransactionDetailsByCodeRoute';
import TransactionTradeRecordSheetRoute from './TransactionTradeRecordSheetRoute';
import TrustDepositReceiptRoute from './TrustDepositReceiptRoute';
import WalletOutgoingPaymentsRoute from './WalletOutgoingPaymentsRoute';
import WalletRoute from './WalletRoute';
import WealthManagementRouteV2 from './WealthManagementRouteV2';
import WealthPlanCRMDashboard from './WealthPlanCRMDashboard';
import WealthPlanReferralRouteV2 from './WealthPlanReferralRouteV2';
import ZenAgreementIndexRoute from './ZenAgreementIndexRoute';
import ZenAnnouncementRoute from './ZenAnnouncementRoute';
import ZenApplicationFormDetailRoute from './ZenApplicationFormDetailRoute';
import ZenApplicationFormIndex from './ZenApplicationFormIndex';
import ZenBoardDetailRoute from './ZenBoardDetailRoute';
import ZenBoardSearchRoute from './ZenBoardSearchRoute';
import ZenBrokerListingsRoute from './ZenBrokerListingsRoute';
import ZenBrokerPeopleRoute from './ZenBrokerPeopleRoute';
import ZenBrokerTransactionsRoute from './ZenBrokerTransactionsRoute';
import ZenCompaniesIndexRoute from './ZenCompaniesIndexRoute';
import ZenCronJobsHistoryRoute from './ZenCronJobsHistoryRoute';
import ZenCronJobsRoute from './ZenCronJobsRoute';
import ZenDirectoryRoute from './ZenDirectoryRoute';
import ZenEngineeringIndexRoute from './ZenEngineeringIndexRoute';
import ZenExperimentDetailRoute from './ZenExperimentDetailRoute';
import ZenExternalTrustDepositCheckUploadRoute from './ZenExternalTrustDepositCheckUploadRoute';
import ZenInvoicesIndexRoute from './ZenInvoicesIndexRoute';
import ZenLegalIndexRoute from './ZenLegalIndexRoute';
import ZenListingsDetailRoute from './ZenListingsDetailRoute';
import ZenListingsRoute from './ZenListingsRoute';
import ZenLoginRoute from './ZenLoginRoute';
import ZenLoginTwoFactorAuthenticationRoute from './ZenLoginTwoFactorAuthenticationRoute';
import ZenMarketingCenterRoute from './ZenMarketingCenterRoute';
import ZenMLSDetailRoute from './ZenMLSDetailRoute';
import ZenMLSSearchRoute from './ZenMLSSearchRoute';
import ZenMyListingsIndexRoute from './ZenMyListingsIndexRoute';
import ZenMyOfficesIndexRoute from './ZenMyOfficesIndexRoute';
import ZenMyTeamsIndexRoute from './ZenMyTeamsIndexRoute';
import ZenMyTransactionsIndexRoute from './ZenMyTransactionsIndexRoute';
import ZenOfficeDetailRoute from './ZenOfficeDetailRoute';
import ZenOfficeIndexRoute from './ZenOfficeIndexRoute';
import ZenOutgoingPaymentsIndexRoute from './ZenOutgoingPaymentsIndexRoute';
import ZenPeopleSearchRoute from './ZenPeopleSearchRoute';
import ZenRealTitleOfficerTransactionDetailRoute from './ZenRealTitleOfficerTransactionDetailRoute';
import ZenRegisterRoute from './ZenRegisterRoute';
import ZenResourcesLinksRoute from './ZenResourcesLinksRoute';
import ZenResourcesTemplateItemsRoute from './ZenResourcesTemplateItemsRoute';
import ZenResourcesTemplatesRoute from './ZenResourcesTemplatesRoute';
import ZenSuperAdminIndexRoute from './ZenSuperAdminIndexRoute';
import ZenTeamDecideRoute from './ZenTeamDecideRoute';
import ZenTeamsSearchRoute from './ZenTeamsSearchRoute';
import ZenTransactionDepositCheckRoute from './ZenTransactionDepositCheckRoute';
import ZenTransactionDetailRoute from './ZenTransactionDetailRoute';
import ZenTransactionsRoute from './ZenTransactionsRoute';

interface AuthRoutesProps {}

const MainRoutes: React.FC<AuthRoutesProps> = () => {
  const {
    auth: {
      userDetail,
      isAdmin,
      isBroker,
      isSuperAdmin,
      isAnnouncer,
      isLeoExpert,
      isMortgageUserRole,
    },
  } = useSelector((state: RootState) => state);

  let homeRedirectPath = '';
  if (isSuperAdmin || isAdmin || isBroker) {
    homeRedirectPath = '/people';
  } else if (isMortgageUserRole) {
    homeRedirectPath = '/mortgages';
  } else {
    homeRedirectPath = '/transactions';
  }

  const isNewPowerAuditEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.NEW_POWER_AUDIT_IMPROVEMENTS,
  );

  const isLeoQuestionManagerEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.LEO_QUESTION_MANAGER,
  );

  const isWebhooksFeatureEnabled = useFeatureFlag(FeatureFlagTypeEnum.WEBHOOKS);
  const isWalletFeatureEnabled = useFeatureFlag(FeatureFlagTypeEnum.WALLET);

  const isGeminiFeatureFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.GEMINI_REDESIGN,
  );
  const geminiRedesignFlagEnabled =
    isGeminiFeatureFlagEnabled && isGeminiUIEnabled();

  const isRealTitleImprovementsEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.REAL_TITLE_PAGE_IMPROVEMENTS,
  );
  const isReferralCentralFeatureEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.REFERRAL_CENTRAL,
  );

  const renderRealTitleRoute = () => {
    if (isCanadianUser(userDetail?.accountCountry!)) {
      return Route404;
    }
    if (isRealTitleImprovementsEnabled) {
      return RealClosingServicesRoute;
    }
    return RealTitleRoute;
  };

  return (
    <Switch>
      <Redirect
        path='/'
        from='/'
        to={!!userDetail ? homeRedirectPath : '/login'}
        exact
      />
      <GuestRoute
        path='/login/2fa'
        component={ZenLoginTwoFactorAuthenticationRoute}
        exact
      />
      <Route
        path='/enable-2fa'
        component={EnableTwoFactorAuthenticationRoute}
        exact
      />
      <GuestRoute path='/login' component={ZenLoginRoute} exact />
      <GuestRoute path='/register' component={ZenRegisterRoute} exact />
      <GuestRoute path='/reset-password' component={ResetPasswordRoute} exact />
      <Route
        path='/transactions/token/:id/check-deposits'
        component={ZenExternalTrustDepositCheckUploadRoute}
        exact
      />
      <GuestRoute
        path='/change-password'
        component={ChangePasswordRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/people'
        config={{
          [UserType.SUPER_ADMIN]: SuperAdminPeopleSearchRoute,
          [UserType.ADMIN]: ZenPeopleSearchRoute,
          [UserType.BROKER]: ZenBrokerPeopleRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/people/:id'
        component={DecideProfileDetailRoute}
      />
      <AuthenticatedSidebarRoute
        path='/offices'
        config={{
          [UserType.ADMIN]: ZenOfficeIndexRoute,
          [UserType.BROKER]: ZenMyOfficesIndexRoute,
          [UserType.AGENT]: ZenMyOfficesIndexRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/offices/:id/:type(documents)?'
        component={
          isGeminiFeatureFlagEnabled
            ? GeminiOfficeDetailRoute
            : ZenOfficeDetailRoute
        }
        exact
      />
      <AuthenticatedAdminRoute
        path='/outgoing-payments'
        component={ZenOutgoingPaymentsIndexRoute}
      />
      <AuthenticatedAdminRoute
        path='/invoices'
        component={ZenInvoicesIndexRoute}
      />
      <AuthenticatedSidebarRoute
        path='/teams'
        config={{
          [UserType.ADMIN]: ZenTeamsSearchRoute,
          [UserType.AGENT]: ZenMyTeamsIndexRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/instant-payments'
        component={InstantPaymentsRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/resources/links'
        component={ZenResourcesLinksRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/resources/marketing-center'
        component={ZenMarketingCenterRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/resources/templates'
        component={ZenResourcesTemplatesRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/agreement/:id'
        component={ZenAgreementDetailsRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/agreement/:id/amendment/:amendmentId'
        component={ZenAmendmentsDetailsRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/resources/templates/:templateItemId'
        component={ZenResourcesTemplateItemsRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/teams/:id'
        component={ZenTeamDecideRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/mls'
        config={{
          [UserType.ADMIN]: ZenMLSSearchRoute,
          [UserType.BROKER]: ZenMLSSearchRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/mls/:id'
        component={ZenMLSDetailRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/boards'
        config={{
          [UserType.ADMIN]: ZenBoardSearchRoute,
          [UserType.BROKER]: ZenBoardSearchRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/boards/:id'
        component={ZenBoardDetailRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/transactions/:type(all|sale|lease|referrals)?/:lifecycle(active|closed|terminated|draft)?'
        config={{
          [UserType.ADMIN]: geminiRedesignFlagEnabled
            ? GeminiAdminOrBrokerTransactionsIndexRoute
            : ZenTransactionsRoute,
          [UserType.REAL_TITLE]: geminiRedesignFlagEnabled
            ? GeminiAdminOrBrokerTransactionsIndexRoute
            : ZenTransactionsRoute,
          [UserType.AGENT]: geminiRedesignFlagEnabled
            ? GeminiAgentTransactionsIndexRoute
            : ZenMyTransactionsIndexRoute,
          [UserType.BROKER]: geminiRedesignFlagEnabled
            ? GeminiAdminOrBrokerTransactionsIndexRoute
            : ZenBrokerTransactionsRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/listings/:type(all|sale|lease|referrals)?/:lifecycle(active|closed|terminated|draft)?'
        config={{
          [UserType.ADMIN]: geminiRedesignFlagEnabled
            ? GeminiAdminOrBrokerListingsIndexRoute
            : ZenListingsRoute,
          [UserType.AGENT]: geminiRedesignFlagEnabled
            ? GeminiAgentListingsIndexRoute
            : ZenMyListingsIndexRoute,
          [UserType.BROKER]: geminiRedesignFlagEnabled
            ? GeminiAdminOrBrokerListingsIndexRoute
            : ZenBrokerListingsRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/mortgages'
        config={{
          [UserType.ADMIN]: AdminMortgagesRoute,
          [UserType.MORTGAGE_ADMIN]: AdminMortgagesRoute,
          [UserType.LOAN_OFFICER]: AdminMortgagesRoute,
          [UserType.LOAN_OFFICER_ASSISTANT]: AdminMortgagesRoute,
          [UserType.LOAN_PROCESSOR]: AdminMortgagesRoute,
          [UserType.REAL_ORIGINATE_LOAN_OFFICER]: AdminMortgagesRoute,
          [UserType.AGENT]: AgentMortgagesRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/mortgages/:id/:type(borrower)?/:borrowerId?'
        component={MortgageDetailsRoute}
      />
      <AuthenticatedSidebarRoute
        path='/dashboard'
        config={{
          [UserType.BROKER]: BrokerDashboardDecider,
        }}
        exact
      />

      <AuthenticatedSidebarRoute
        path='/transactions/code/:code'
        component={TransactionDetailsByCodeRoute}
      />
      <AuthenticatedSidebarRoute
        path='/transactions/:id/setup'
        component={TransactionSetup}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/transactions/:id/commission-validate'
        component={TransactionCommissionReview}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/transaction/create/:transactionBuilderId'
        component={ZenCreateTransactionSteps}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/listing/create/:transactionBuilderId'
        component={ZenCreateListingSteps}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/transaction/referral'
        component={ZenReferralTransactionSteps}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/webhook/create/:webhookId?'
        component={isWebhooksFeatureEnabled ? CreateWebhookRoute : Route404}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wallet'
        component={isWalletFeatureEnabled ? WalletRoute : Route404}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wallet/offerings'
        component={
          isWalletFeatureEnabled && !isCanadianUser(userDetail?.accountCountry!)
            ? WalletOfferings
            : Route404
        }
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wallet/signup/:type(credit|debit)/:offeringId'
        component={
          isWalletFeatureEnabled && !isCanadianUser(userDetail?.accountCountry!)
            ? WalletApplicationSteps
            : Route404
        }
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wallet/dashboard'
        component={
          isWalletFeatureEnabled ? WalletDebitDashboardRoute : Route404
        }
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wallet/dashboard/debit/:debitAccountId'
        component={isWalletFeatureEnabled ? WalletDashboardRoute : Route404}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wallet/dashboard/credit/:creditAccountId'
        component={isWalletFeatureEnabled ? WalletMyCreditRoute : Route404}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/finance/wallet-outgoing-payments'
        component={
          isWalletFeatureEnabled ? WalletOutgoingPaymentsRoute : Route404
        }
        exact
      />
      <Route
        path='/transactions/:id/approve-commission-document'
        component={ZenTransactionCommissionDocumentReview}
        exact
      />
      <Route
        path='/transactions/:id/deposit-check'
        component={ZenTransactionDepositCheckRoute}
        exact
      />
      <Route
        path='/transactions/:id/deposit-check/escrows/:escrowId'
        component={ZenTransactionDepositCheckRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/conversations/:id'
        component={ConversationDetailsRoute}
        hideLeo
      />
      <AuthenticatedSidebarRoute
        path='/transactions/:transactionId/trust-deposit-receipt/:id'
        component={TrustDepositReceiptRoute}
      />
      <AuthenticatedSidebarRoute
        path='/transactions/:transactionId/commission-deposit-receipt/:id'
        component={CommissionDepositReceiptRoute}
      />
      <AuthenticatedSidebarRoute
        path='/transactions/:id/conveyance-documents/:revisionId/preview'
        component={ZenTransactionConveyanceDocumentPreviewRoute}
      />
      <AuthenticatedRoute
        path='/:containerType/:containerId/checklist/:checklistId/dropbox/:dropboxId/split-and-attach-pdf'
        component={SplitAndAttachPDFToChecklistRoute}
      />
      <Route path='/learnworlds/sso' component={LearnworldsSsoRedirect} />
      <Route path='/designcenter/sso' component={DesignCenterRedirect} />
      <AuthenticatedSidebarRoute
        path='/transactions/:transactionId/power-audit'
        config={{
          [UserType.ADMIN]: isNewPowerAuditEnabled
            ? NewBrokerPowerAuditRoute
            : BrokerPowerAuditRoute,
          [UserType.AGENT]: Route404,
          [UserType.BROKER]: isNewPowerAuditEnabled
            ? NewBrokerPowerAuditRoute
            : BrokerPowerAuditRoute,
        }}
      />
      <AuthenticatedSidebarRoute
        path='/transactions/:id'
        config={{
          [UserType.ADMIN]: ZenTransactionDetailRoute,
          [UserType.REAL_TITLE]: ZenRealTitleOfficerTransactionDetailRoute,
          [UserType.AGENT]: ZenTransactionDetailRoute,
          [UserType.BROKER]: ZenTransactionDetailRoute,
        }}
      />
      <AuthenticatedSidebarRoute
        path='/listings/:id'
        component={ZenListingsDetailRoute}
      />
      {isReferralCentralFeatureEnabled && (
        <AuthenticatedSidebarRoute
          path='/referrals'
          component={ReferralRoute}
          exact
        />
      )}
      {isReferralCentralFeatureEnabled && (
        <AuthenticatedSidebarRoute
          path='/referrals/:id/applicants'
          component={ReferralApplicants}
        />
      )}

      <AuthenticatedSidebarRoute
        path='/invoice/:id'
        component={InvoiceFormRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/cda/:id'
        component={CDAFormRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/trade-record-sheets/:id'
        component={TransactionTradeRecordSheetRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/trade-record-sheets/:id/participants/:participantId'
        component={TransactionTradeRecordSheetRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/inbox'
        component={BrokerQueueEmptyRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/my-events/:eventId?'
        config={{
          [UserType.ADMIN]: EventsRoute,
          [UserType.AGENT]: AgentEventsRoute,
          [UserType.BROKER]: AgentEventsRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/directory'
        config={{
          [UserType.ADMIN]: ZenDirectoryRoute,
          [UserType.AGENT]: ZenDirectoryRoute,
        }}
        exact
      />
      <AuthenticatedAdminRoute
        path='/applications'
        component={ZenApplicationFormIndex}
        exact
      />
      <AuthenticatedAdminRoute
        path='/applications/:id'
        component={ZenApplicationFormDetailRoute}
      />
      <AuthenticatedAdminRoute path='/secret/flags' component={FlagsIndex} />
      <AuthenticatedAdminRoute path='/secret/errors' component={ErrorIndex} />
      <AuthenticatedAdminRoute
        path='/jobs'
        component={ZenCronJobsRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/jobs/:jobName/history'
        component={ZenCronJobsHistoryRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/engineering'
        component={ZenEngineeringIndexRoute}
      />
      <AuthenticatedAdminRoute path='/legal' component={ZenLegalIndexRoute} />
      <AuthenticatedAdminRoute
        path='/companies'
        component={ZenCompaniesIndexRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/bank-accounts/:id/reconcile-transactions/:type(reconciled)?'
        component={ReconcileTransactionsRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/bank-accounts'
        component={BankAccountsRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/feature-flags/:type(archived)?'
        component={FeatureFlagsRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/kafka-dashboard/:type(consumer|producer)'
        component={KafkaDashboardRoute}
      />
      <AuthenticatedAdminRoute
        path='/feature-flags/:id'
        component={ZenExperimentDetailRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/agreements'
        component={ZenAgreementIndexRoute}
      />
      <AuthenticatedAdminRoute path='/finance' component={FinanceRoute} />
      <AuthenticatedAdminRoute path='/calendars' component={CalendarsRoute} />
      <AuthenticatedAdminRoute
        path='/superadmin'
        component={ZenSuperAdminIndexRoute}
      />
      <AuthenticatedRoute path='/api/docs' component={ApiDocsRoute} />
      <AuthenticatedAdminRoute path='/support' component={SupportIndexRoute} />
      <AuthenticatedAdminRoute
        path='/operations'
        component={OperationsIndexRoute}
      />
      <AuthenticatedRoute
        path='/real-title/:inviteId/signup'
        component={JointVentureSignUpSteps}
      />
      <AuthenticatedRoute
        path='/real-title/:inviteId/learn-more'
        component={LearnMore}
      />
      <AuthenticatedAdminRoute
        path='/real-title/create-jv'
        component={CreateJointVentureSteps}
      />
      <AuthenticatedAdminRoute
        path='/real-title/joint-ventures'
        component={RealTitleIndexRoute}
      />
      <AuthenticatedSidebarRoute
        path='/real-title'
        component={renderRealTitleRoute()}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/onereal-mortgage'
        component={
          !isCanadianUser(userDetail?.accountCountry!)
            ? OneRealMortgageRouteV2
            : Route404
        }
        exact
      />
      {/** This route can be accessed both by authenticated and unauthenticated users */}
      <Route
        path='/wealthplan/referral'
        component={WealthPlanReferralRouteV2}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wealthplan'
        component={WealthManagementRouteV2}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wealthplan/referral/dashboard'
        component={WealthPlanCRMDashboard}
        exact
      />
      <AuthenticatedSidebarRoute path='/about' component={AboutRoute} />
      <AuthenticatedSidebarRoute
        path='/reports'
        component={ReportsRoute}
        exact
      />
      <Route
        path='/terms/api-generation'
        component={ApiGenerationTermsOfServices}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/announcement'
        component={isAnnouncer ? ZenAnnouncementRoute : Route404}
      />
      <AuthenticatedSidebarRoute
        path='/leo-question-manager'
        component={
          isLeoQuestionManagerEnabled && isLeoExpert
            ? LeoQuestionManagerRoute
            : Route404
        }
      />
      <ErrorRoutes />
    </Switch>
  );
};

export default MainRoutes;
