import { isEmpty } from 'lodash';
import {
  useCreateToken,
  useFetchBankingPartnership,
  useWallet,
} from '../../query/wallet/useWallet';
import {
  findAccountWithBankingPartner,
  scopes,
  upgradableScopes,
} from '../../utils/WalletUtils';

const useFetchWalletPartnershipsAndToken = (debitAccountId?: string) => {
  const {
    data: wallet,
    isLoading: walletLoading,
    errorCode: walletErrorCode,
  } = useWallet();

  const {
    data: partnerships,
    isLoading: partnershipsLoading,
    errorCode: partnershipErrorCode,
  } = useFetchBankingPartnership(!isEmpty(wallet?.bankingPartnerships));

  const { account, partner } = findAccountWithBankingPartner(
    debitAccountId,
    partnerships?.bankingPartnerships,
  );

  const {
    data: unitToken,
    isLoading: tokenLoading,
    errorCode: tokenError,
  } = useCreateToken({
    bankingPartnershipId: partner?.id!,
    scopes,
    upgradableScopes,
  });

  if (!debitAccountId) {
    return { wallet, isLoading: walletLoading, errorCode: walletErrorCode };
  }

  return {
    wallet,
    account,
    unitToken,
    partner,
    isLoading: walletLoading || partnershipsLoading || tokenLoading,
    errorCode: walletErrorCode || partnershipErrorCode || tokenError,
  };
};

export default useFetchWalletPartnershipsAndToken;
