import { faArrowDownLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TransactionCoordinatorAccessEnum } from '../../types';
import { cn } from '../../utils/classUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import Accordian from '../commons/Accordian';
import GeminiConfirmationModal from './GeminiConfirmationModal';
import SignAssistandAmendmentModal from './SignAssistandAmendmentModal';
import TCAcceptOrRejectCard from './TCAcceptOrRejectCard';
import TCAccessConfirmationCard from './TCAccessConfirmationCard';
import { TCInfo } from './TCInfoSection';
import {
  schema,
  TransactionCoordinatorFromData,
} from './TCTransactionsAccessCard';

const ReceivedInvites: TCInfo[] = [
  {
    id: '1',
    name: 'John Doe asd  asd as da sda',
    email: 'johnasdasdasdasdasd@email.com',
    avatar:
      'avatar/c6aa7000-a9d9-4e3d-b9a2-cd143fb8dc37_b5808041-8253-4bf1-9847-f1821c2d2cd2.png',
    teamName: 'Team 1',
  },
  {
    id: '2',
    name: 'John Doe 1',
    email: 'johnasdasdasdasd@email.com',
    avatar:
      'avatar/c6aa7000-a9d9-4e3d-b9a2-cd143fb8dc37_b5808041-8253-4bf1-9847-f1821c2d2cd2.png',
  },
  {
    id: '3',
    name: 'John Doe 2',
    email: 'johndasdasdasdasd@email.com',
    avatar:
      'avatar/c6aa7000-a9d9-4e3d-b9a2-cd143fb8dc37_b5808041-8253-4bf1-9847-f1821c2d2cd2.png',
  },
];

const ReceivedInvitesAccordion: FC = () => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
  } = useForm<TransactionCoordinatorFromData>({
    defaultValues: {
      accessType:
        TransactionCoordinatorAccessEnum.ALL_OPEN_LISTINGS_AND_TRANSACTIONS,
    },
    resolver: zodResolver(schema),
  });
  const accessType = watch('accessType');

  const [selectedTC, setSelectedTC] = useState<TCInfo>();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isOpenSignAmendmentModal, setIsOpenSignAmendmentModal] = useState(
    false,
  );

  const handleReject = () => {};

  const handleNext = () => {
    setIsConfirmModalOpen(true);
  };

  const onConfirm = () => {
    setIsConfirmModalOpen(false);
    setIsOpenSignAmendmentModal(true);
  };

  return (
    <div className='flex flex-col gap-3 border border-grey-300 rounded-lg'>
      <Accordian
        title='RECEIVED'
        name='receivedInvitations'
        icon={
          <FontAwesomeIcon icon={faArrowDownLeft} className='text-regent-600' />
        }
      >
        {ReceivedInvites.map((tc, index) => (
          <div
            className={cn({
              'border-regent-300 border-b':
                index !== ReceivedInvites.length - 1,
            })}
            key={tc.id}
          >
            <TCAcceptOrRejectCard
              control={control}
              setValue={setValue}
              selectedAccessType={accessType}
              selectedTC={selectedTC}
              setSelectedTC={setSelectedTC}
              tcInfo={tc}
              handleNext={handleSubmit(handleNext)}
              handleReject={handleReject}
            />
          </div>
        ))}
      </Accordian>
      <GeminiConfirmationModal
        title='Transaction Access Confirmation'
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        size='large'
        onConfirm={onConfirm}
        hideIcon
      >
        <TCAccessConfirmationCard
          tcId=''
          selectedAccessPreference={capitalizeEnum(accessType)}
          tcName={selectedTC?.name!}
          specialInfo='You need to sign the Assistant Amendment to accept the invite'
        />
      </GeminiConfirmationModal>
      <SignAssistandAmendmentModal
        isOpen={isOpenSignAmendmentModal}
        onClose={() => setIsOpenSignAmendmentModal(false)}
        tcId=''
      />
    </div>
  );
};

export default ReceivedInvitesAccordion;
