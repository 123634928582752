import {
  faChevronRight,
  faSignature,
} from '@fortawesome/pro-regular-svg-icons';
import { faEnvelopeOpen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import filledFileDocSvg from '../../assets/icons/filledFileDoc.svg';
import { TCInvitationStatusEnum } from '../../utils/TransactionCoordinatorUtils';
import { Button } from '../commons/Button';
import TCInvitationStatusPill from './TCInvitationStatusPill';

type TCInvitationStatusCardProps = {
  status: TCInvitationStatusEnum;
  ammendmentDocumentLink?: string;
};

const TCInvitationStatusCard: React.FC<TCInvitationStatusCardProps> = ({
  status,
}) => {
  return (
    <div className='flex flex-col gap-y-4 font-inter'>
      <div className='flex justify-between gap-4 items-center px-1.5'>
        <div className='flex gap-2 items-center'>
          <FontAwesomeIcon
            icon={faEnvelopeOpen}
            className='text-primary-dark mb-1'
          />
          <p className='text-primary-dark text-sm sm:text-base font-medium'>
            Invitation Status
          </p>
        </div>
        <div className='flex gap-1 items-center cursor-pointer'>
          <TCInvitationStatusPill status={status} />
          <FontAwesomeIcon
            icon={faChevronRight}
            className='text-primary-dark text-sm'
          />
        </div>
      </div>
      {status === TCInvitationStatusEnum.SIGN_ASSISTANT_AMENDMENT && (
        <div className='bg-primary-light flex justify-between items-center rounded-lg pl-3 pr-2 py-2 border border-regent-300'>
          <div className='flex gap-2 items-center'>
            <img src={filledFileDocSvg} alt='file icon' />
            <p className='text-primary-dark text-sm md:text-base font-medium'>
              Assistant Amendment
            </p>
          </div>
          <div className='w-36'>
            <Button fullWidth leftIcon={<FontAwesomeIcon icon={faSignature} />}>
              Sign Now
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TCInvitationStatusCard;
