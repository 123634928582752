import {
  faArrowUpRightFromSquare,
  faEnvelope,
  faListCheck,
  faPhone,
} from '@fortawesome/pro-light-svg-icons';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import FileDocSvg from '../../assets/icons/fileDoc.svg';
import { TransactionCoordinatorAccessEnum } from '../../types';
import { displayDate } from '../../utils/DateUtils';
import {
  TCAccessStatusEnum,
  TCInvitationStatusEnum,
} from '../../utils/TransactionCoordinatorUtils';
import ZenAvatar from '../Zen/ZenAvatar';
import TCAccessStatusPill from './TCAccessStatusPill';

export type TCInfo = {
  id: string;
  name: string;
  email?: string;
  phone?: string;
  avatar?: string;
  startedOn?: string;
  teamName?: string;
  invitationStatus?: TCInvitationStatusEnum;
  status?: TCAccessStatusEnum;
  accessType?: TransactionCoordinatorAccessEnum;
  removedOn?: string;
};

interface TCInfoSectionProps {
  tcInfo: TCInfo;
  showAdditionalInfo?: boolean;
  showOnboardingJourney?: () => void;
}

const TCInfoSection: FC<TCInfoSectionProps> = ({
  tcInfo,
  showAdditionalInfo = false,
  showOnboardingJourney,
}) => {
  const { name, email, phone, avatar, status, startedOn, teamName } = tcInfo;

  return (
    <div className='font-inter text-primary-dark p-4'>
      <div className='flex justify-between gap-3'>
        <div className='flex items-center gap-3'>
          <ZenAvatar name={name} imageUrl={avatar} size='xl' />
          <div>
            <p
              className='text-lg sm:text-xl font-medium line-clamp-1'
              title={name}
            >
              {name}
            </p>
            <p
              className='text-xs sm:text-sm font-light line-clamp-1'
              title={teamName}
            >
              {teamName}
            </p>
          </div>
        </div>
        {status && (
          <div>
            <TCAccessStatusPill
              status={TCAccessStatusEnum[status]}
              variant='filled'
            />
          </div>
        )}
      </div>
      {showAdditionalInfo && (
        <div className='border-t border-regent-300 text-xs sm:text-sm font-light flex flex-col gap-4 pt-4 mt-4'>
          {(phone || email) && (
            <div className='flex'>
              {phone && (
                <div className='flex gap-3 ml-1 items-center'>
                  <FontAwesomeIcon icon={faPhone} className='text-grey-600' />
                  <p title={phone}>{phone}</p>
                </div>
              )}
              {email && (
                <div className='flex items-center overflow-hidden'>
                  <span className='block w-1 h-1 rounded-full bg-gray-400 mx-3 flex-shrink-0' />
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className='text-grey-600'
                  />
                  <p
                    className='ml-2 overflow-hidden text-ellipsis'
                    title={email}
                  >
                    {email}
                  </p>
                </div>
              )}
            </div>
          )}
          {startedOn && (
            <div className='flex items-center justify-between'>
              <div className='flex gap-3 ml-1 items-center'>
                <FontAwesomeIcon icon={faListCheck} className='text-grey-600' />
                <p>
                  <span>Started on </span>
                  <span>{displayDate(startedOn)}</span>
                </p>
              </div>
              <button onClick={showOnboardingJourney}>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          )}
          {status === TCAccessStatusEnum.ACTIVE && (
            <div className='flex items-center justify-between'>
              <div className='flex gap-1 items-center'>
                <img src={FileDocSvg} alt='file icon' />
                <p>Assistant Amendment Document</p>
              </div>
              <button className='flex items-center gap-2'>
                <p className='font-medium'>View</p>
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TCInfoSection;
