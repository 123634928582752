import {
  faArrowDownToLine,
  faArrowRotateRight,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { GiAnticlockwiseRotation } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CellProps, Column, Row } from 'react-table';
import DropboxEmptyFilesImg from '../../../../assets/img/dropbox/EmptyTray.png';
import ResourceContainer from '../../../../components/ResourceContainer';
import ZenFixedDataTable from '../../../../components/Zen/Table/ZenFixedDataTable';
import ZenFileCabinetFileNameCell from '../../../../components/Zen/Transaction/FileCabinet/ZenFileCabinetFileNameCell';
import ZenUserPill from '../../../../components/Zen/ZenUserPill';
import useAgentsInfo from '../../../../hooks/useAgentsInfo';
import { useFetchAsyncMappingResponse } from '../../../../hooks/useFetchAsyncMappingResponse';
import {
  DropboxApi,
  DropboxResponse,
  FileApi,
  FileMetadataDtoOriginTypeEnum,
  FileResponse,
} from '../../../../openapi/dropbox';
import { ChecklistResponse } from '../../../../openapi/sherlock';
import { RezenObjectTypeEnum } from '../../../../openapi/yada';
import AnalyticsService from '../../../../services/AnalyticsService';
import ErrorService from '../../../../services/ErrorService';
import { fetchCheckLists } from '../../../../slices/CheckListSlice';
import { downloadDropboxFileByVersion } from '../../../../slices/DropboxSlice';
import { showApiErrorModal } from '../../../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../slices/ToastNotificationSlice';
import {
  AnalyticsEventEnum,
  AppDispatch,
  FeatureFlagTypeEnum,
  RootState,
} from '../../../../types';
import { downloadSelectedFiles } from '../../../../utils/FileUtils';
import { getDropboxConfiguration } from '../../../../utils/OpenapiConfigurationUtils';
import DefaultEmpty from '../../../DefaultEmpty';
import FeatureFlagDisabledOnly from '../../../FeatureFlagDisabledOnly';
import FeatureFlagEnabledOnly from '../../../FeatureFlagEnabledOnly';
import ZenConfirmationModal from '../../Modal/ZenConfirmationModal';
import AttachedChecklists from './AttachedChecklists';
import FileCabinetActionCell from './FileCabinetActionCell';
import FileCabinetEditDocument, { FCFormData } from './FileCabinetEditDocument';
import { ZenArchiveFileHeader } from './ZenArchiveFileHeader';
import ZenFileCabinetHeader from './ZenFileCabinetHeader';

interface FormData {
  files: File[];
}

interface FileCabinetTableProps {
  dropboxId: string;
  checklistId?: string;
  getPublicUserInfo?: boolean;
  containerType: RezenObjectTypeEnum;
  containerId?: string;
  backUrl?: string;
  isDesignatedBroker?: boolean;
}

const FileCabinetTable: React.FC<FileCabinetTableProps> = ({
  dropboxId,
  checklistId,
  getPublicUserInfo = false,
  containerType,
  containerId,
  backUrl,
  isDesignatedBroker = false,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { isArchive } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const {
    userIds: { agentById },
    auth: { userDetail, isAdmin },
    checklist: { checklistsById },
  } = useSelector((state: RootState) => state);

  const { control, watch, setValue } = useForm<FormData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [dropbox, setDropbox] = useState<DropboxResponse | undefined>(
    undefined,
  );
  const [fileToEdit, setFileToEdit] = useState<FileResponse | undefined>(
    undefined,
  );
  const [filesToDelete, setFilesToDelete] = useState<
    FileResponse[] | undefined
  >();
  const [filesToRestore, setFilesToRestore] = useState<
    FileResponse[] | undefined
  >();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isActionBarLoading, setIsActionBarLoading] = useState({
    download: false,
    preview: false,
    restore: false,
    delete: false,
  });
  const isTrashFolder = isArchive === 'true';
  const isAdminOrBroker = isAdmin || isDesignatedBroker;
  const isOfficeContainer = containerType === RezenObjectTypeEnum.Office;
  const isOfficeAdmin = isAdminOrBroker && isOfficeContainer;
  const showActionButton = !isOfficeContainer || isOfficeAdmin;

  const files = watch('files');
  const filteredDropboxFiles = dropbox?.files?.filter(
    (file) =>
      file.metadata?.originType !== FileMetadataDtoOriginTypeEnum.VoiceS3,
  );

  useEffect(() => {
    if (isTrashFolder && isOfficeContainer && !isOfficeAdmin) {
      // Redirect to the office file cabinet if the user is not an admin and tries to access the archive folder
      const currentPath = location.pathname;
      history.push(currentPath);
    }
  }, [
    history,
    isOfficeAdmin,
    isOfficeContainer,
    isTrashFolder,
    location.pathname,
  ]);

  const handleFetch = useCallback(async () => {
    if (!dropboxId) {
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await new DropboxApi(
        getDropboxConfiguration(),
      ).getDropboxById(dropboxId);

      setDropbox(data);
    } catch (e) {
      ErrorService.notify('Unable to fetch mortgage dropbox', e, {
        mortgage: { dropboxId },
      });
    } finally {
      setIsLoading(false);
    }
  }, [dropboxId]);

  const handleUpload = useCallback(
    async (filesToUpload: File[], dropbox: DropboxResponse) => {
      setIsUploading(true);

      await Promise.all(
        [...filesToUpload].map(async (fileToUpload) => {
          const { data } = await new DropboxApi(
            getDropboxConfiguration(),
          ).uploadFile(dropboxId, fileToUpload, userDetail?.id!);

          if (data && containerType === RezenObjectTypeEnum.Borrower) {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.MC_DOCUMENT_UPLOADED,
            );
          }

          return data;
        }),
      )
        .then((values) => {
          setDropbox({
            ...dropbox,
            files: [
              ...(dropbox?.files?.filter(
                (file) =>
                  file.metadata?.originType !==
                  FileMetadataDtoOriginTypeEnum.VoiceS3,
              ) || []),
              ...values,
            ],
          });
        })
        .catch((e) => {
          ErrorService.notify('Unable to upload a file', e, {
            mortgage: { dropboxId },
          });
          dispatch(showApiErrorModal(e));
        });

      setIsUploading(false);
      setValue('files', []);
    },

    [dispatch, dropboxId, setValue, userDetail?.id, containerType],
  );

  const handleDelete = useCallback(
    async (selectedFiles: FileResponse[]) => {
      try {
        if (selectedFiles?.[0]?.trash === true) {
          await Promise.all(
            selectedFiles?.map(async (selectedFile) => {
              await new FileApi(getDropboxConfiguration()).deleteFile(
                selectedFile?.id!,
              );
            }),
          );
          await handleFetch();
          dispatch(
            showSuccessToast(
              `${pluralize(
                'Document',
                selectedFiles?.length,
              )} deleted successfully.`,
            ),
          );
        } else {
          await Promise.all(
            selectedFiles?.map(async (selectedFile) => {
              await new FileApi(getDropboxConfiguration()).moveFileToTrash(
                selectedFile?.id!,
              );
            }),
          );
          await handleFetch();
          dispatch(
            showSuccessToast(
              `${pluralize(
                'Document',
                selectedFiles?.length,
              )} moved to archive successfully.`,
            ),
          );
        }
      } catch (e) {
        ErrorService.notify('Unable to delete the files', e, {
          files: selectedFiles,
        });
        dispatch(
          showErrorToast(
            'We had a problem deleting the documents',
            'Please try again in a few moments.',
          ),
        );
      } finally {
        setFilesToDelete(undefined);
      }
    },
    [dispatch, handleFetch],
  );

  const handleRestore = useCallback(
    async (selectedFiles: FileResponse[]) => {
      try {
        await Promise.all(
          selectedFiles?.map(async (selectedFile) => {
            await new FileApi(getDropboxConfiguration()).restoreFileFromTrash(
              selectedFile?.id!,
            );
          }),
        );
        await handleFetch();
        dispatch(
          showSuccessToast(
            `${pluralize(
              'Document',
              selectedFiles?.length,
            )} restored successfully.`,
          ),
        );
      } catch (e) {
        ErrorService.notify('Unable to restore the files', e, {
          files: selectedFiles,
        });
        dispatch(
          showErrorToast(
            'We had a problem restoring the documents',
            'Please try again in a few moments.',
          ),
        );
      } finally {
        setFilesToRestore(undefined);
      }
    },
    [dispatch, handleFetch],
  );

  const handleEdit = useCallback(
    async (formData: FCFormData) => {
      try {
        let data: FileResponse;
        if (formData.docFile) {
          const response = await new FileApi(
            getDropboxConfiguration(),
          ).addFileVersion(
            fileToEdit?.id!,
            formData.docFile[0],
            userDetail?.id!,
            formData.fileName,
            formData.fileDescription,
          );
          data = response?.data;
        } else {
          const response = await new FileApi(
            getDropboxConfiguration(),
          ).updateFile(fileToEdit?.id!, {
            filename: formData.fileName,
            description: formData.fileDescription,
          });
          data = response?.data;
        }

        let updatedDropbox = dropbox!;

        const fileIndex = dropbox!.files!.findIndex(
          (dropboxFile) => dropboxFile.id === fileToEdit?.id,
        );

        if (fileIndex !== -1) {
          updatedDropbox.files![fileIndex] = data;
        }

        setDropbox(updatedDropbox);

        dispatch(showSuccessToast('File updated successfully.'));
      } catch (e) {
        ErrorService.notify('Unable to edit a file', e, {
          mortgage: { fileId: fileToEdit?.id! },
        });
      }
    },
    [dispatch, dropbox, fileToEdit?.id, userDetail?.id],
  );

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  useEffect(() => {
    if (files?.length && dropbox) {
      handleUpload(files, dropbox);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, handleUpload]);

  const agentIds = useMemo(
    () =>
      isTrashFolder
        ? dropbox?.trashFiles?.map((p) => p?.uploadedBy!).filter((p) => !!p) ||
          []
        : filteredDropboxFiles?.map((p) => p?.uploadedBy!).filter((p) => !!p) ||
          [],
    [dropbox?.trashFiles, filteredDropboxFiles, isTrashFolder],
  );

  useAgentsInfo(agentIds, getPublicUserInfo);

  const handleOpenFile = useCallback(
    async (fileId: string, fileVersionId: string) => {
      await dispatch(downloadDropboxFileByVersion(fileId!, fileVersionId!));
    },
    [dispatch],
  );

  const getCheckList = useCallback(() => {
    if (checklistId) {
      dispatch(fetchCheckLists(checklistId));
    }
  }, [checklistId, dispatch]);

  const {
    data: checklistDetails,
  } = useFetchAsyncMappingResponse<ChecklistResponse>({
    name: 'checklist',
    fetch: getCheckList,
    response: checklistsById?.[checklistId!]!,
  });

  const filteredChecklists = useMemo(() => {
    const checklist = (checklistDetails?.items || [])?.filter(
      (item) => !item?.hidden,
    );

    return checklist;
  }, [checklistDetails?.items]);

  const columns = useMemo<Column<FileResponse>[]>(() => {
    const columnArray: Column<FileResponse>[] = [
      {
        Header: 'File Name',
        accessor: 'filename',
        Cell: ({ row: { original } }) => (
          <ZenFileCabinetFileNameCell
            file={original}
            handleOpenFile={() =>
              handleOpenFile(original?.id!, original?.currentVersion?.id!)
            }
          />
        ),
        disableFilters: true,
      },

      {
        Header: 'Date Added',
        accessor: 'createdAt',
        Cell: ({ row: { original } }) => {
          if (!original?.createdAt) {
            return (
              <span className='font-zen-body font-normal text-sm text-zen-dark-5'>
                N/A
              </span>
            );
          }

          const date = DateTime.fromMillis(original?.createdAt!);

          return (
            <div className='font-zen-body text-sm'>
              <div className='font-normal text-zen-dark-9'>
                {date?.toFormat('LLL d, yyyy')}
              </div>
              <div className='font-normal text-zen-dark-6'>
                {date?.toFormat('hh:mm a')?.toLocaleLowerCase()}
              </div>
            </div>
          );
        },
        disableFilters: true,
      },

      {
        Header: 'Uploaded By',
        accessor: 'uploadedBy',
        Cell: ({ row: { original } }) => {
          const agent = agentById[original?.uploadedBy!];
          const agentName =
            agent?.firstName && agent?.lastName
              ? `${agent?.firstName} ${agent?.lastName}`
              : 'Agent Name';

          return (
            <div className='w-max'>
              <ZenUserPill
                name={agentName}
                imageUrl={agent?.avatar}
                backgroundVariant='background'
                noWrap
              />
            </div>
          );
        },
        disableFilters: true,
      },

      {
        Header: 'Actions',
        id: 'id',
        accessor: 'id',
        Cell: ({ row: { original, isSelected } }) => (
          <div
            className={classNames({
              'pointer-events-none opacity-30 cursor-not-allowed': isSelected,
            })}
          >
            <FileCabinetActionCell
              file={original}
              handleOpenFile={() =>
                handleOpenFile(original?.id!, original?.currentVersion?.id!)
              }
              handleEditFile={() => setFileToEdit(original)}
              handleDelete={handleDelete}
              handleRestore={handleRestore}
              containerType={containerType}
              containerId={containerId}
              checklistId={checklistId}
              dropboxId={dropboxId}
              backUrl={backUrl}
              isTrashFolder={isTrashFolder}
              isDesignatedBroker={isDesignatedBroker}
            />
          </div>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
    ];

    if (!isOfficeContainer) {
      columnArray.splice(3, 0, {
        Header: 'Checklist',
        Cell: ({ row }: { row: Row<FileResponse> }) => {
          const {
            original,
          }: {
            original: FileResponse;
          } = row;
          return (
            <AttachedChecklists
              checklists={filteredChecklists}
              fileId={original.id!}
              isTrashFolder={isTrashFolder}
            />
          );
        },
      });
    }

    if (isOfficeAdmin) {
      columnArray.splice(3, 0, {
        Header: 'Updated On',
        accessor: (d) => d['currentVersion']?.createdAt,
        id: 'currentVersion.createdAt',
        Cell: ({ row: { original } }: CellProps<FileResponse, string>) => {
          if (!original?.currentVersion?.createdAt) {
            return (
              <span className='font-zen-body font-normal text-sm text-zen-dark-5'>
                N/A
              </span>
            );
          }

          const date = DateTime.fromMillis(
            original?.currentVersion?.createdAt!,
          );

          return (
            <div className='font-zen-body text-sm'>
              <div className='font-normal text-zen-dark-9'>
                {date?.toFormat('LLL d, yyyy')}
              </div>
              <div className='font-normal text-zen-dark-6'>
                {date?.toFormat('hh:mm a')?.toLocaleLowerCase()}
              </div>
            </div>
          );
        },
        disableFilters: true,
      });

      columnArray.splice(4, 0, {
        Header: 'Version',
        accessor: (d) => d['currentVersion']?.version,
        id: 'currentVersion.version',
        Cell: ({ row: { original } }: CellProps<FileResponse, string>) => {
          if (!original?.currentVersion?.version) {
            return (
              <span className='font-zen-body font-normal text-sm text-zen-dark-5'>
                N/A
              </span>
            );
          }

          return (
            <div className='flex items-center md:justify-center gap-1'>
              <GiAnticlockwiseRotation />
              {original?.currentVersion?.version}
            </div>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      });
    }

    return columnArray;
  }, [
    handleOpenFile,
    agentById,
    handleDelete,
    handleRestore,
    containerType,
    containerId,
    checklistId,
    dropboxId,
    backUrl,
    filteredChecklists,
    isTrashFolder,
    isOfficeContainer,
    isOfficeAdmin,
    isDesignatedBroker,
  ]);

  const handleActionBarDownloadDocuments = useCallback(
    async (files: FileResponse[]) => {
      try {
        await downloadSelectedFiles(files, 'file-cabinet-documents');
      } catch (e) {
        ErrorService.notify('Unable to download the files', e, {
          files: files,
        });
        dispatch(
          showErrorToast(
            'We had a problem downloading the documents',
            'Please try again in a few moments.',
          ),
        );
      }
    },
    [dispatch],
  );

  const tableActionBar = [
    {
      hoverText: 'Download All Docs',
      icon: (
        <FontAwesomeIcon
          icon={faArrowDownToLine}
          className='text-lg text-zen-dark-9'
          aria-label='download-all-selected-documents'
        />
      ),
      onClick: async (files: FileResponse[]) => {
        setIsActionBarLoading((prevState) => {
          return { ...prevState, download: true };
        });
        await handleActionBarDownloadDocuments(files);
        setIsActionBarLoading((prevState) => {
          return { ...prevState, download: false };
        });
      },
      isLoading: isActionBarLoading?.download,
    },
  ];

  if (isTrashFolder) {
    tableActionBar.push({
      hoverText: 'Restore All Docs',
      icon: (
        <FontAwesomeIcon
          icon={faArrowRotateRight}
          aria-label='restore-all-selected-documents'
        />
      ),
      onClick: async (files: FileResponse[]) => {
        setIsActionBarLoading((prevState) => {
          return { ...prevState, restore: true };
        });
        setFilesToRestore(files);
        setIsActionBarLoading((prevState) => {
          return { ...prevState, restore: false };
        });
      },
      isLoading: isActionBarLoading?.restore,
    });
  }
  if (isTrashFolder && (isAdmin || isOfficeAdmin) && showActionButton) {
    tableActionBar.push({
      hoverText: 'Delete All Docs',
      icon: (
        <FontAwesomeIcon
          icon={faTrashCan}
          aria-label='delete-all-selected-documents'
        />
      ),
      onClick: async (files: FileResponse[]) => {
        setIsActionBarLoading((prevState) => {
          return { ...prevState, delete: true };
        });
        setFilesToDelete(files);
        setIsActionBarLoading((prevState) => {
          return { ...prevState, delete: false };
        });
      },
      isLoading: isActionBarLoading?.delete,
    });
  }

  const emptyComponent = () => {
    if (search) {
      return (
        <DefaultEmpty
          icon={
            <img src={DropboxEmptyFilesImg} className='my-3' alt='not found' />
          }
          message='Could not find matching files'
        />
      );
    }

    return (
      <div className='mt-4 flex flex-col items-center justify-center'>
        <p className='text-zen-dark-5 text-base text-center font-zen-body'>
          {isTrashFolder
            ? 'You have no documents in your archive folder.'
            : 'You have no documents in your file cabinet.'}
        </p>
        <img
          src={DropboxEmptyFilesImg}
          className='my-3 w-auto h-20 object-contain'
          alt='empty-dropbox'
        />
        {!isOfficeContainer && (
          <p className='text-zen-dark-9 text-base text-center font-zen-body font-thin w-full max-w-xl'>
            {isTrashFolder
              ? 'Once you archive documents from your file cabinet or checklist, you will be able to restore them from your archive folder.'
              : 'Once you upload documents to your file cabinet, you will be able to easily attach them to any checklist item.'}
          </p>
        )}
      </div>
    );
  };

  return (
    <div className='py-6 pr-3 font-zen-body'>
      <FeatureFlagDisabledOnly
        flag={FeatureFlagTypeEnum.ARCHIVE_CHECKLIST_DROPBOX_FILES}
      >
        <ZenFileCabinetHeader<FormData, 'files'>
          control={control}
          name='files'
          isTrashFolder={isTrashFolder}
          search={search}
          setSearch={setSearch}
          showActionButton={showActionButton}
          isUploading={isUploading}
        />
      </FeatureFlagDisabledOnly>
      <FeatureFlagEnabledOnly
        flag={FeatureFlagTypeEnum.ARCHIVE_CHECKLIST_DROPBOX_FILES}
      >
        <ZenArchiveFileHeader<FormData, 'files'>
          control={control}
          name='files'
          isTrashFolder={isTrashFolder}
          isUploading={isUploading}
          search={search}
          setSearch={setSearch}
          showActionButton={showActionButton}
        />
      </FeatureFlagEnabledOnly>

      {isTrashFolder ? (
        <ResourceContainer
          loading={isLoading}
          isEmpty={false}
          resourceName='File'
        >
          <ZenFixedDataTable<FileResponse>
            key={dropbox?.trashFiles?.length}
            customEmptyComponent={emptyComponent()}
            columns={columns}
            resourceName='File'
            data={dropbox?.trashFiles || []}
            search={search}
            standalone={false}
            hideFilters
            hidePagination
            groupByProperty='email'
            allowSelection
            showSelectionActionBar
            actionBarButtons={tableActionBar}
          />
        </ResourceContainer>
      ) : (
        <ResourceContainer
          loading={isLoading}
          isEmpty={false}
          resourceName='File'
        >
          <ZenFixedDataTable<FileResponse>
            key={filteredDropboxFiles?.length}
            customEmptyComponent={emptyComponent()}
            columns={columns}
            hiddenColumns={
              isOfficeContainer && !isAdminOrBroker
                ? ['currentVersion.version']
                : []
            }
            resourceName='File'
            data={filteredDropboxFiles || []}
            search={search}
            standalone={false}
            hideFilters
            hidePagination
            groupByProperty='email'
            allowSelection
            showSelectionActionBar
            actionBarButtons={tableActionBar}
          />
        </ResourceContainer>
      )}

      {!!fileToEdit && (
        <FileCabinetEditDocument
          isOpen
          onClose={() => setFileToEdit(undefined)}
          file={fileToEdit!}
          handleEdit={handleEdit}
          handleOpenFile={handleOpenFile}
        />
      )}

      {!!filesToDelete?.length && (
        <ZenConfirmationModal
          isOpen
          variant='danger'
          title={
            isTrashFolder
              ? 'Are you sure you want to delete this files?'
              : 'Are you sure you want to archive this files?'
          }
          confirmButtonText={isTrashFolder ? 'Delete' : 'Archive'}
          hideIcon
          subtitle={
            isTrashFolder
              ? 'Deleting this file will remove all previous versions as well.'
              : 'Archiving this file will archive all previous versions as well.'
          }
          onClose={() => setFilesToDelete(undefined)}
          onConfirm={async () => {
            setIsSubmitting(true);
            await handleDelete(filesToDelete);
            setIsSubmitting(false);
          }}
          isSubmitting={isSubmitting}
          isDisabled={isSubmitting}
        />
      )}
      {!!filesToRestore?.length && (
        <ZenConfirmationModal
          isOpen
          variant='primary'
          title='Are you sure you want to restore this files?'
          confirmButtonText='Restore'
          hideIcon
          subtitle='Restoring this file will restore all previous versions as well.'
          onClose={() => setFilesToRestore(undefined)}
          onConfirm={async () => {
            setIsSubmitting(true);
            await handleRestore(filesToRestore);
            setIsSubmitting(false);
          }}
          isSubmitting={isSubmitting}
          isDisabled={isSubmitting}
        />
      )}
    </div>
  );
};

export default React.memo(FileCabinetTable);
