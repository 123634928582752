import { useInfiniteQuery } from '@tanstack/react-query';
import { flatten } from 'lodash';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgentControllerApi as ArrakisAgentControllerApi } from '../../openapi/arrakis';
import {
  AgentControllerApi,
  AgentResponse,
  UpdatePersonalAccountRequest,
  UserControllerApi,
  UserPreviewResponse,
} from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { AppDispatch, RootState } from '../../types';
import {
  getArrakisConfiguration,
  getYentaConfiguration,
} from '../../utils/OpenapiConfigurationUtils';
import { DEFAULT_CACHE_TIME, DEFAULT_STALE_TIME } from '../base/config';
import { queryKeys } from '../base/queryKeys';
import { useBaseMutation } from '../base/useBaseMutation';
import { QueryOptions, useSimpleQuery } from '../base/useSimpleQuery';

export const useFetchPersonalAccount = (
  agentId?: string,
  enabled: boolean = false,
) => {
  const getPersonalAccount = async () => {
    const { data } = await new UserControllerApi(
      getYentaConfiguration(),
    ).getPersonalAccount(agentId!);
    return data?.personalAccount!;
  };

  const queryResult = useSimpleQuery<UserPreviewResponse>({
    queryKey: queryKeys.agent.keymaker(agentId!).queryKey,
    queryFn: () => getPersonalAccount(),
    options: {
      toastErrorMessage: 'Unable to fetch personal account',
      logErrorMessage: 'Unable to fetch personal account',
      enabled: !!agentId && enabled,
      staleTime: 0,
    },
  });

  return queryResult;
};

export const useUpdatePersonalAccount = () => {
  const dispatch = useDispatch<AppDispatch>();
  const updatePersonalAccount = async (req: UpdatePersonalAccountRequest) => {
    await new UserControllerApi(getYentaConfiguration()).updatePersonalAccount(
      req,
    );
  };

  return useBaseMutation({
    queryKey: queryKeys.agent._def,
    mutationFn: updatePersonalAccount,
    successMessage: 'Personal account added successfully',
    onError: (e, variables) => {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to add personal account', e, {
        request: variables,
      });
      dispatch(
        showErrorToast(
          'We had a problem adding personal account.',
          'Please try again in a few moments.',
        ),
      );
    },
  });
};

export const useInfiniteSearchAgents = (filters: Record<string, any>) => {
  const dispatch = useDispatch<AppDispatch>();

  const queryResult = useInfiniteQuery({
    queryKey: queryKeys.agent.search(filters).queryKey,
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await new AgentControllerApi(
        getYentaConfiguration(),
      ).searchAgents(
        pageParam, // pageNumber
        20, // pageSize
        filters.sortDirection, // sortDirection
        filters.sortBy, // sortBy
        filters.searchText, // search
        filters.firstName, // firstName
        filters.lastName, // lastName
        filters.email, // email
        filters.phoneNumber, // phoneNumber
        undefined, // nonReportable
        filters.id, // id
        filters.status, // status
        filters.country, // country
        filters.stateOrProvince, // stateOrProvince
        filters.officeId, // officeId
        filters.mlsId, // mlsId
        filters.boardId, // boardId
        filters.divisionIds, // divisionIds
      );
      return data;
    },
    cacheTime: DEFAULT_CACHE_TIME,
    staleTime: DEFAULT_STALE_TIME,
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext ? lastPage.pageNumber! + 1 : undefined;
    },
    onError: (error: Error) => {
      dispatch(showErrorToast('Error fetching agent list.'));
      ErrorService.notify('Error fetching agent list.', error);
    },
  });

  const flattenedData = useMemo(() => {
    return flatten(queryResult.data?.pages.map((page) => page.results));
  }, [queryResult.data?.pages]);

  return {
    data: flattenedData,
    isFetching: queryResult.isFetching,
    isInitialLoading: queryResult.isInitialLoading,
    isLoading: queryResult.isLoading,
    hasNextPage: queryResult.hasNextPage,
    fetchNextPage: queryResult.fetchNextPage,
    isFetchingNextPage: queryResult.isFetchingNextPage,
  };
};

export const useFetchGenericUserById = (userId: string) => {
  return useSimpleQuery({
    // TODO: confirm if this is the correct queryKey
    queryKey: queryKeys.user.detail(userId).queryKey,
    queryFn: async () => {
      const { data } = await new UserControllerApi(
        getYentaConfiguration(),
      ).getGenericUserById(userId);
      return data;
    },
    options: {
      enabled: !!userId,
      logErrorMessage: 'Failed to fetch generic user',
      toastErrorMessage: 'Failed to fetch generic user',
    },
  });
};

export const useFetchArrakisAgentById = (userId: string) => {
  return useSimpleQuery({
    queryKey: queryKeys.agent.arrakis(userId).queryKey,
    queryFn: async () => {
      const { data } = await new ArrakisAgentControllerApi(
        getArrakisConfiguration(),
      ).getAgentByYentaId(userId);
      return data;
    },
    options: {
      enabled: !!userId,
      logErrorMessage: 'Failed to fetch arrakis agent information',
      toastErrorMessage: 'Failed to fetch user information',
    },
  });
};

export const useFetchCurrentUser = (options: QueryOptions<AgentResponse>) => {
  const userId = useSelector((state: RootState) => state.auth.userDetail?.id)!;

  return useSimpleQuery<AgentResponse>({
    queryKey: queryKeys.agent.detail(userId).queryKey,
    queryFn: async () => {
      const { data } = await new UserControllerApi(
        getYentaConfiguration(),
      ).getCurrentUser();
      return data;
    },
    options: {
      toastErrorMessage: 'Unable to fetch user information',
      logErrorMessage: 'Unable to fetch current user information',
      ...options,
    },
  });
};
