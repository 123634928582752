import { faMessageSmile } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BrokerOnly from '../components/auth/BrokerOnly';
import ExcludeBroker from '../components/auth/ExcludeBroker';
import ConversationGroupDetailSection from '../components/Conversation/ConversationGroupDetailSection';
import ConversationGroupDetailSectionBrokerView from '../components/Conversation/ConversationGroupDetailSectionBrokerView';
import ResourceContainer from '../components/ResourceContainer';
import ZenCommentSection from '../components/transactions/Comments/ZenCommentSection';
import { LOAD_MORE_COMMENT_COUNT } from '../components/Zen/Table/Cell/ZenChecklistItemCommentsCell';
import { CommentOnSubmitParams } from '../components/ZenCommentWidget/ZenCommentWidget';
import { SSE_URL } from '../constants/CommonConstants';
import useAgentsInfo from '../hooks/useAgentsInfo';
import {
  CommentControllerApi,
  CommentDto,
  MemberIdTypeEnum,
  PostCommentBodyReaderRoleEnum,
  QueryCommentsRs,
  RezenObjectTypeEnum,
  UpdateCommentBodyReaderRoleEnum,
} from '../openapi/yada';
import { useFetchConversationById } from '../query/roar/useRoar';
import { RootState } from '../types';
import { getFullName } from '../utils/AgentHelper';
import { performFetch } from '../utils/FetchUtil';
import { getYadaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { getFileNameFromUrl } from '../utils/StringUtils';

export type ConversationMatch = {
  id: string;
};

const ConversationDetailsRoute: React.FC = () => {
  const { id } = useParams<ConversationMatch>();
  const scrollableRef = useRef<HTMLDivElement>(null);

  const authUserId = useSelector(
    (state: RootState) => state.auth.userDetail?.id,
  );

  const { data, isLoading } = useFetchConversationById(id);

  const officeGroup = data?.members.find(
    (member) => member.memberId.type === MemberIdTypeEnum.Group,
  );

  const user = data?.members.find((member) => {
    const memberId = member.memberId;
    return (
      memberId.value !== authUserId && memberId.type === MemberIdTypeEnum.User
    );
  });

  const userDetail = useSelector(
    (state: RootState) => state.userIds.agentById[user?.memberId.value!],
  );

  const onDeleteComment = async (comment: CommentDto) => {
    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).deleteComment(
      comment?.container?.type!,
      comment?.container?.id!,
      comment?.id! as string,
    );

    return data;
  };

  const fetchComments = async (
    pageStart: string | undefined,
  ): Promise<QueryCommentsRs> => {
    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).getCommentsByContainer(
      RezenObjectTypeEnum.Conversation,
      id,
      LOAD_MORE_COMMENT_COUNT,
      pageStart,
    );

    return data;
  };

  const handleSubmit = async (values: CommentOnSubmitParams) => {
    if (values.mediaType) {
      const _file = await performFetch(values.filePath!);
      const fileBlob = await _file.blob();
      const fileName = getFileNameFromUrl(values.filePath!);
      const file = new File([fileBlob], fileName! + '.m4a', {
        type: values.fileType,
      });

      await new CommentControllerApi(getYadaConfiguration()).uploadMedia(
        RezenObjectTypeEnum.Conversation, // containerType
        id, // containerId
        'USER', // authorType
        values.mediaType, // mediaType
        // @ts-expect-error
        JSON.stringify(values.comment.blocks!), // richBlocks
        file, // file
        undefined, // refersToType
        undefined, // refersToId
        undefined, // refersToName
        undefined, // callerGroupId
        undefined, // triggerActivity
        undefined, // readerRole
        undefined, // fileDescription
        undefined, // axios config
      );

      return;
    }

    await new CommentControllerApi(getYadaConfiguration()).postComment(
      RezenObjectTypeEnum.Conversation,
      id,
      'USER',
      {
        richContent: values.comment,
        readerRole: values.role as PostCommentBodyReaderRoleEnum,
      },
      undefined,
      undefined,
      undefined,
      undefined,
    );
  };

  const onEditComment = async (
    values: CommentOnSubmitParams,
    commentId: string,
  ): Promise<CommentDto> => {
    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).updateComment(RezenObjectTypeEnum.Conversation, id, commentId, {
      richContent: values.comment,
      readerRole: values.role as UpdateCommentBodyReaderRoleEnum,
    });

    return data;
  };

  const agentIds = useMemo(() => [user?.memberId.value!], [
    user?.memberId.value,
  ]);

  useAgentsInfo(agentIds);

  return (
    <div className='min-h-[inherit] flex flex-col md:flex-row'>
      <ResourceContainer
        loading={isLoading}
        isEmpty={false}
        resourceName='conversation'
        withFragment
      >
        <div className='flex-1 md:max-h-[calc(100vh_-_50px)] flex flex-col'>
          <header className='py-2 px-4.5 font-inter text-primary-dark text-base bg-regent-200 p-4'>
            <BrokerOnly>Agent: {getFullName(userDetail)}</BrokerOnly>
            <ExcludeBroker>Message</ExcludeBroker>
          </header>
          <div
            className='pt-4 px-4 flex-1 [&>div]:h-full [&>div]:!mt-0 [&>div>div:first-child]:flex-1 md:overflow-auto scrollbar'
            id='message-container'
            ref={scrollableRef}
          >
            <ZenCommentSection
              // TODO: figure out why this is required
              key={'conversation' + id}
              hideMentions
              fetchComments={fetchComments}
              onDelete={onDeleteComment}
              onEdit={onEditComment}
              onSubmit={handleSubmit}
              htmlElementScrollableParent={scrollableRef.current!}
              containerType={RezenObjectTypeEnum.Conversation}
              containerId={id}
              sseURL={`${SSE_URL}?containerType=${RezenObjectTypeEnum.Conversation}&containerId=${id}`}
              EmptyComponent={
                <div className='flex flex-col items-center justify-center space-y-4'>
                  <FontAwesomeIcon
                    icon={faMessageSmile}
                    fontSize={52}
                    className='text-regent-600'
                  />
                  <p className='font-inter font-medium text-primary-dark text-xl text-center w-full max-w-md'>
                    <ExcludeBroker>
                      This is simply the beginning of your conversation
                    </ExcludeBroker>
                    <BrokerOnly>
                      This is simply the beginning of your conversation with{' '}
                      {getFullName(userDetail)}
                    </BrokerOnly>
                  </p>
                </div>
              }
            />
          </div>
        </div>
        <div className='w-full md:w-72 border-l md:max-h-[calc(100vh_-_50px)] overflow-auto scrollbar'>
          <ExcludeBroker>
            <ConversationGroupDetailSection
              conversationId={id}
              groupId={officeGroup?.memberId.value!}
            />
          </ExcludeBroker>
          <BrokerOnly>
            <ConversationGroupDetailSectionBrokerView
              conversationId={id}
              agentId={user?.memberId.value!}
              groupId={officeGroup?.memberId.value!}
            />
          </BrokerOnly>
        </div>
      </ResourceContainer>
    </div>
  );
};

export default ConversationDetailsRoute;
