import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  AgentWealthManagementApi,
  ProspectiveAgentInfoCountryEnum,
  SubmitProspectiveAgentReferralRequest,
} from '../../../../openapi/plutus';
import ErrorService from '../../../../services/ErrorService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../slices/ToastNotificationSlice';
import {
  failedToFetchWealthManagementApi,
  fetchDefaultWealthGoals,
} from '../../../../slices/WealthManagementSlice';
import { FeatureFlagTypeEnum, RootState } from '../../../../types';
import { cn } from '../../../../utils/classUtils';
import { getWealthManagementConfiguration } from '../../../../utils/OpenapiConfigurationUtils';
import ResourceContainer from '../../../ResourceContainer';
import StepByStepContainer, {
  StepConfig,
} from '../../../StepByStep/StepByStepContainer';
import ZenSidebarModal from '../../../Zen/ZenSidebarModal';
import { GoalsFormInput } from '../../V2/SetGoalsSideBarV2';
import {
  formDataToGoalDetails,
  getDefaultFormData,
} from '../../WealthPlanReferral/V2/wealthPlanReferralUtilsV2';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import AccountInfoPlanV2 from './AccountInfoPlanV2';
import AgentInfoPlanV2 from './AgentInfoPlanV2';
import SetGoalsPlanV2 from './SetGoalsPlanV2';

export enum WealthPlanFormStepName {
  ACCOUNT_INFO = 'ACCOUNT_INFO',
  SETUP_GOALS = 'SETUP_GOALS',
  CONTACT_INFO = 'CONTACT_INFO',
}

export interface SendWealthPlanStepByStepFormData extends GoalsFormInput {
  country: {
    label: string;
    value: ProspectiveAgentInfoCountryEnum;
  };
  firstName: string;
  lastName: string;
  email: string;
  emailSubject: string;
  emailMessage: string;
  phoneNumber: string;
  brokerageName: string;
}

export interface StepContentProps {
  form: UseFormReturn<SendWealthPlanStepByStepFormData>;
}

interface SendWealthPlanStepByStepFormProps {
  isOpen: boolean;
  close: () => void;
}

type StepType = StepConfig<
  SendWealthPlanStepByStepFormData,
  WealthPlanFormStepName
>;

export const steps: StepType[] = [
  {
    name: WealthPlanFormStepName.ACCOUNT_INFO,
    Component: AccountInfoPlanV2,
    hidePagination: true,
  },
  {
    name: WealthPlanFormStepName.SETUP_GOALS,
    Component: SetGoalsPlanV2,
    hidePagination: true,
  },
  {
    name: WealthPlanFormStepName.CONTACT_INFO,
    Component: AgentInfoPlanV2,
    hidePagination: true,
  },
];

const SendWealthPlanStepByStepFormV2: React.FC<SendWealthPlanStepByStepFormProps> = ({
  isOpen,
  close,
}) => {
  const {
    auth: { userDetail },
    wealthManagement: { defaultWealthGoalsResponse },
  } = useSelector((state: RootState) => state);
  const isRevenueShareProjectionsFeatureFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.REVENUE_SHARE_PROJECTIONS,
  );
  const [currentStep, setCurrentStep] = useState<StepType>(steps[0]);
  const [stepCount, setStepCount] = useState<number>(0);

  const onStepChange = (step: StepType) => {
    setCurrentStep(step);
    const index = steps.findIndex((s) => s.name === step.name);
    setStepCount(index);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!defaultWealthGoalsResponse.data) {
      dispatch(fetchDefaultWealthGoals());
    }
  }, [defaultWealthGoalsResponse.data, dispatch]);

  const onSubmit = async (data: SendWealthPlanStepByStepFormData) => {
    let request: SubmitProspectiveAgentReferralRequest = {
      goalDetails: formDataToGoalDetails(
        data,
        false,
        isRevenueShareProjectionsFeatureFlagEnabled,
      ),
      firstName: data?.firstName,
      lastName: data?.lastName,
      phoneNumber: data?.phoneNumber,
      brokerageName: data?.brokerageName,
      emailAddress: data?.email,
      emailSubject: data?.emailSubject,
      emailMessage: data?.emailMessage,
    };

    try {
      await new AgentWealthManagementApi(
        await getWealthManagementConfiguration(),
      ).submitAgentReferral(userDetail?.id!, request);
      dispatch(showSuccessToast('Successfully sent referral'));
    } catch (error) {
      dispatch(
        failedToFetchWealthManagementApi({
          apiName: 'setWealthGoalsResponse',
          setWealthGoalsResponse: { error },
        }),
      );
      dispatch(showErrorToast('Unable to send referral'));
      ErrorService.notify(
        `Unable to send referral [userId: ${userDetail?.id}]`,
        error,
      );
    } finally {
      close();
    }
  };

  const isLoading =
    defaultWealthGoalsResponse.loading && !defaultWealthGoalsResponse.data;

  const defaultGoals = defaultWealthGoalsResponse.data?.goalDetails;

  return (
    <ResourceContainer
      loading={isLoading}
      isEmpty={false}
      resourceName='WealthPlan Referral Form'
    >
      <ZenSidebarModal
        title='Send a WealthPlan'
        isOpen={isOpen}
        onClose={close}
      >
        <div className='flex flex-col w-full h-full overflow-hidden'>
          <div className='px-4 py-4 bg-white'>
            <div>
              {`Step ${stepCount + 1} of ${steps.length}:`}{' '}
              {capitalize(currentStep.name).replace(/_/g, ' ')}
            </div>
            <div className='flex flex-row gap-2 mt-2'>
              {steps.map((step) => (
                <div
                  className={cn('h-2', 'w-56', 'rounded', 'bg-grey-100', {
                    '!bg-primary-blue':
                      stepCount >= steps.findIndex((s) => s.name === step.name),
                  })}
                  key={step.Component.name}
                />
              ))}
            </div>
          </div>
          <StepByStepContainer<
            SendWealthPlanStepByStepFormData,
            WealthPlanFormStepName
          >
            steps={steps}
            defaultValues={getDefaultFormData(
              defaultGoals,
              isRevenueShareProjectionsFeatureFlagEnabled,
            )}
            onSubmit={onSubmit}
            reValidateMode='onChange'
            mode='onChange'
            onChangeStep={onStepChange}
          />
        </div>
      </ZenSidebarModal>
    </ResourceContainer>
  );
};

export default SendWealthPlanStepByStepFormV2;
