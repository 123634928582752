import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import { getFullName } from '../../../../utils/AgentHelper';

interface GeminiMentionAgentProps {
  id: string;
}

const GeminiMentionAgent: React.FC<GeminiMentionAgentProps> = ({ id }) => {
  const {
    userIds: { agentById },
    transaction: { commentsMentionUsers },
  } = useSelector((state: RootState) => state);
  const isGroup = (commentsMentionUsers?.mentionableGroups || [])?.find(
    (group) => group?.groupId === id,
  );
  const agent = agentById[id];
  const fullName = !!isGroup
    ? isGroup?.groupName
    : commentsMentionUsers?.mentionableUsers?.find(
        (user) => user?.yentaId === id,
      )?.displayName || getFullName(agent);

  const role = !!isGroup
    ? isGroup?.groupName
    : commentsMentionUsers?.mentionableUsers?.find(
        (user) => user?.yentaId === id,
      )?.role;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href={!isGroup ? `/people/${agent?.id!}` : undefined}
      target='_blank'
      rel='noreferrer'
      className='inline-block'
    >
      <p className='flex text-rezen-blue-600 font-inter font-medium'>
        @{fullName} {role && `(${role})`}
      </p>
    </a>
  );
};

export default GeminiMentionAgent;
