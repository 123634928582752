import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faMessage, faPhone } from '@fortawesome/pro-light-svg-icons';
import { faRotate, faTableColumns } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDisclosure } from '@mantine/hooks';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useGemini } from '../../hooks/useGemini';
import { MemberIdTypeEnum } from '../../openapi/yada';
import {
  AddGroupMemberRequestRoleEnum,
  AdministrativeAreaResponseStateOrProvinceEnum,
  GroupResponse,
} from '../../openapi/yenta';
import { useCreateConversation } from '../../query/roar/useRoar';
import { addOfficeGroupMember } from '../../slices/OfficeSlice';
import {
  AppDispatch,
  AsyncSelectOption,
  FeatureFlagTypeEnum,
  RootState,
} from '../../types';
import { cn } from '../../utils/classUtils';
import { getConversationLink } from '../../utils/ConversationUtils';
import AdminOnly from '../auth/AdminOnly';
import ExcludeBroker from '../auth/ExcludeBroker';
import { Button } from '../commons/Button';
import CallModalStepper from '../Conversation/CallModalStepper';
import FeatureFlagDisabledOnly from '../FeatureFlagDisabledOnly';
import FeatureFlagEnabledOnly from '../FeatureFlagEnabledOnly';
import IconButton from '../IconButton';
import ResourceContainer from '../ResourceContainer';
import OfficeGroupCardHeader from './OfficeGroupCardHeader';
import OfficeGroupCardRow from './OfficeGroupCardRow';
import OfficeGroupMenu from './OfficeGroupMenu';
import SetCallPriorityModal from './SetCallPriorityModal';

interface OfficeGroupCardProps {
  group: GroupResponse;
  officeId: string;
  officeState: AdministrativeAreaResponseStateOrProvinceEnum;
}

export interface GroupMemberFormData {
  userId?: AsyncSelectOption;
  role?: AddGroupMemberRequestRoleEnum;
}

const OfficeGroupCard: React.FC<OfficeGroupCardProps> = ({
  group,
  officeId,
  officeState,
}) => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const [showSetCallPriorityModal, callPriorityModalAction] = useDisclosure(
    false,
  );
  const [callModalOpen, callModalActions] = useDisclosure(false);

  const dispatch = useDispatch<AppDispatch>();
  const isBrokerTeam = group.groupName === 'Broker Team';
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<GroupMemberFormData>({
    defaultValues: {
      role: undefined,
      userId: undefined,
    },
  });

  const isGeminiFlagEnabled = useGemini();

  const onSubmit = async (formData: GroupMemberFormData) => {
    await dispatch(
      addOfficeGroupMember(officeId, group?.id!, {
        role: formData?.role!,
        userId: formData?.userId?.value,
      }),
    );
    setValue('userId', {
      label: 'Search',
      value: '',
    });
    setValue('role', '' as AddGroupMemberRequestRoleEnum);
  };

  const brokerTeamOptions = [
    {
      label: 'Add Member',
      onClick: () => setIsHeaderVisible(!isHeaderVisible),
    },
    {
      label: 'Set Call Priority',
      onClick: callPriorityModalAction.open,
    },
  ];

  const groupActions = useMemo(() => {
    return (
      <IconButton
        leftIcon={
          isHeaderVisible ? (
            <FontAwesomeIcon
              icon={regular('xmark')}
              className={cn(
                isGeminiFlagEnabled
                  ? 'font-light text-[13px] leading-[23px] text-regent-600'
                  : 'text-zen-dark-12',
              )}
              size='sm'
            />
          ) : (
            <FontAwesomeIcon
              icon={regular('pencil')}
              className={cn(
                isGeminiFlagEnabled
                  ? 'font-light text-[13px] leading-[23px] text-rezen-blue-600'
                  : 'text-primary-blue',
              )}
              size='sm'
            />
          )
        }
        onClick={() => setIsHeaderVisible(!isHeaderVisible)}
        variant='none'
      />
    );
  }, [isGeminiFlagEnabled, isHeaderVisible]);

  const userDetails = useSelector((state: RootState) => state.auth.userDetail);

  const { mutate, isLoading } = useCreateConversation();

  const handleGetConversation = (cb: (id: string) => void) => {
    mutate(
      {
        members: [
          {
            value: userDetails?.id!,
            type: MemberIdTypeEnum.User,
          },
          {
            value: group.id!,
            type: MemberIdTypeEnum.Group,
          },
        ],
      },
      {
        onSuccess: (data) => {
          cb(data.id);
        },
      },
    );
  };

  return (
    <>
      <div className='rounded-[10px] border border-zen-dark-4 overflow-hidden'>
        <div
          className={cn(
            'flex flex-row justify-between items-center bg-grey-100 h-[45px] px-3 space-x-1',
            isGeminiFlagEnabled
              ? 'font-inter text-base leading-[22px] font-medium text-primary-dark'
              : 'text-lg font-zen-title text-zen-dark-9',
          )}
        >
          <span className='flex flex-grow flex-shrink'>{group?.groupName}</span>

          <FeatureFlagEnabledOnly
            flag={FeatureFlagTypeEnum.ROAR_FULL_RELEASE_BOLT}
          >
            {isBrokerTeam && (
              <ExcludeBroker>
                <div className='flex items-center space-x-0.5'>
                  <Button
                    variant='link'
                    leftIcon={
                      <FontAwesomeIcon
                        icon={faPhone}
                        fontSize={13}
                        className='md:mr-1'
                      />
                    }
                    disabled={isLoading}
                    onClick={callModalActions.open}
                  >
                    <span className='hidden md:inline'>Call</span>
                  </Button>
                  <Button
                    variant='link'
                    leftIcon={
                      <FontAwesomeIcon
                        icon={faMessage}
                        fontSize={13}
                        className='md:mr-1'
                      />
                    }
                    disabled={isLoading}
                    onClick={() => {
                      handleGetConversation((id) => {
                        window.open(getConversationLink(id), '_blank');
                      });
                    }}
                  >
                    <span className='hidden md:inline'>Message</span>
                  </Button>
                </div>
              </ExcludeBroker>
            )}
          </FeatureFlagEnabledOnly>

          <AdminOnly>
            <div className='flex flex-shrink-0 flex-row items-center space-x-2'>
              {isHeaderVisible && (
                <button
                  className='flex items-center space-x-1 rounded-lg py-1 px-4 font-zen-title text-sm font-normal disabled:bg-zen-dark-4 disabled:text-white disabled:border-zen-dark-4 justify-center border border-primary-blue bg-primary-blue text-white transition ease-in-out duration-600'
                  type='submit'
                  onClick={handleSubmit(onSubmit)}
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <FontAwesomeIcon
                      icon={faRotate}
                      className='animate-spin mr-1'
                      fontSize='small'
                    />
                  )}
                  <span>Add</span>
                </button>
              )}
              <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.ROAR}>
                {isBrokerTeam && !isHeaderVisible ? (
                  <OfficeGroupMenu options={brokerTeamOptions} />
                ) : (
                  <div className='flex justify-center items-center bg-transparent hover:bg-gray-300 rounded-full w-7 h-7'>
                    {groupActions}
                  </div>
                )}
              </FeatureFlagEnabledOnly>
              {/* start - remove when roar is enabled */}
              <FeatureFlagDisabledOnly flag={FeatureFlagTypeEnum.ROAR}>
                <div className='flex justify-center items-center bg-transparent hover:bg-gray-300 rounded-full w-7 h-7'>
                  {groupActions}
                </div>
              </FeatureFlagDisabledOnly>
              {/* end - remove when roar is enabled */}
            </div>
          </AdminOnly>
        </div>
        <OfficeGroupCardHeader
          isVisible={isHeaderVisible}
          groupMembers={group?.groupMembers}
          control={control}
        />
        <ResourceContainer
          isEmpty={!group?.groupMembers?.length}
          loading={false}
          resourceName='Member'
          errorCode={null}
          EmptyComponent={
            <div className='flex flex-col items-center p-5'>
              <FontAwesomeIcon icon={faTableColumns} size='2xl' />
              <p className='text-center font-primary text-gray-400'>
                There are no members to display.
              </p>
            </div>
          }
        >
          <div className='flex flex-col space-y-1'>
            {group?.groupMembers?.map((member) => {
              return (
                <OfficeGroupCardRow
                  key={member?.user?.id}
                  member={member}
                  isHeaderVisible={isHeaderVisible}
                  groupId={group?.id!}
                  officeId={officeId}
                />
              );
            })}
          </div>
        </ResourceContainer>
      </div>
      <SetCallPriorityModal
        officeId={officeId}
        groups={isBrokerTeam ? group.groupMembers : undefined}
        onClose={callPriorityModalAction.close}
        opened={showSetCallPriorityModal}
      />
      <CallModalStepper
        opened={callModalOpen}
        onClose={callModalActions.close}
        groupId={group.id!}
        officeState={officeState}
        getConversation={handleGetConversation}
        isFetchingConversation={isLoading}
      />
    </>
  );
};

export default OfficeGroupCard;
