import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './assets/css/zen-index.css';
import TipaltiRegister from './components/Agent/TipaltiRegister';
import VerifyLicensesOnboarding from './components/Agent/VerifyLicensesOnboarding';
import AnalyticsPageViewEvent from './components/Analytics/AnalyticsPageViewEvent';
import ApiErrorModal from './components/ApiErrorModal';
import BrokerOnly from './components/auth/BrokerOnly';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import CookiesDisabledModal from './components/CookiesDisabledModal';
import useDatadogEvaluateFeatureFlags from './components/datadog/hooks/DatadogEvaluateFeatureFlags';
import DefaultLoader from './components/DefaultLoader';
import EmailBannedPopupModal from './components/EmailBannedPopupModal';
import FeatureFlagEnabledOnly from './components/FeatureFlagEnabledOnly';
import MaintenanceRoute from './components/MaintenanceRoute';
import TaxAndNationalIDOnboardingForm from './components/onboarding/taxAndNationalIdOnboarding/TaxAndNationalIdForm';
import UnidentifiedSpeakersModal from './components/Roar/UnidentifiedSpeakersModal';
import VoiceCallConnectedModal from './components/Roar/VoiceCallConnectedModal';
import ApplicationAgreementsSigning from './components/Zen/Legal/ApplicationAgreementsSigning';
import ZenBrowserPopupModal from './components/Zen/Modal/ZenBrowserPopupModal';
import ZenIdlePopupModal from './components/Zen/Modal/ZenIdlePopupModal';
import ZenTransactionErrorModal from './components/ZenTransactionErrorModal';
import ToastNotificationContainer from './containers/ToastNotificationContainer';
import useBrowserCheck from './hooks/useBrowserCheck';
import { useFeatureFlag } from './hooks/useFeatureFlag';
import useRegisterWindowHeightEffect from './hooks/useRegisterWindowHeightEffect';
import { EmailControllerApi } from './openapi/hermes';
import { AgentResponseAgentStatusEnum } from './openapi/yenta';
import AuthRedirectRoute from './routes/AuthRedirectRoute';
import CDAFormPdfRoute from './routes/CDAFormPdfRoute';
import CDAFormRoute from './routes/CDAFormRoute';
import CommissionDepositReceiptPdfRoute from './routes/CommissionDepositReceiptPdfRoute';
import ConveyanceDocumentsPdfRoute from './routes/ConveyanceDocumentsPdfRoute';
import ICASignedPdfRoute from './routes/ICASignedPdfRoute';
import InvoiceFormPdfRoute from './routes/InvoiceFormPdfRoute';
import InvoiceFormRoute from './routes/InvoiceFormRoute';
import JoinByInvitationRoute from './routes/JoinByInvitationRoute';
import LOISignedPdfRoute from './routes/LOISignedPdfRoute';
import MainRoutes from './routes/MainRoutes';
import MsDynamicsOnboardingRoute from './routes/MSDynamicsOnboardingRoute';
import OnboardingRoutes from './routes/OnboardingRoutes';
import RedirectToRealSignatureRoute from './routes/RedirectToRealSignatureRoute';
import TaxDocumentPdfRoute from './routes/TaxDocumentPdfRoute';
import TransactionTradeRecordSheetPdfRoute from './routes/TransactionTradeRecordSheetPdfRoute';
import TrustDepositReceiptPdfRoute from './routes/TrustDepositReceiptPdfRoute';
import ContinueOnMobileRoute from './routes/WelcomeToRezen/ContinueOnMobileRoute';
import RezenICASigningV2Route from './routes/WelcomeToRezen/RezenICASigningV2Route';
import RezenRoute from './routes/WelcomeToRezen/RezenRoute';
import ErrorService from './services/ErrorService';
import {
  fetchAllLatestAgreementsNotSigned,
  fetchLatestICAAgreement,
} from './slices/AgentSlice';
import { fetchAuthUserDetail, getAvailableCountries } from './slices/AuthSlice';
import {
  fetchFutureMaintenancePeriod,
  fetchMaintenancePeriod,
} from './slices/MaintenanceSlice';
import { fetchPaymentMethods } from './slices/PaymentMethodSlice';
import { getTaxForms } from './slices/TaxInformationSlice';
import { FeatureFlagTypeEnum, RootState } from './types';
import { getAuthCookie } from './utils/AuthUtils';
import { cookiesEnabled } from './utils/CookieHelper';
import { getHermesConfiguration } from './utils/OpenapiConfigurationUtils';
import TransactionSummaryPdfRoute from './components/transactions/Summary/TransactionSummaryPdfRoute';
import { RoarDisclaimerModal } from './components/RoarDisclaimerModal';

export interface AppProps {}

const App: React.FC<AppProps> = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [showEmailBannedModal, setShowEmailBannedModal] = useState(false);
  const {
    loadingUserDetail,
    userDetailId,
    agentStatus,
    maintenance,
  } = useSelector((state: RootState) => ({
    loadingUserDetail: state.auth.loadingUserDetail,
    userDetailId: state.auth.userDetail?.id,
    maintenance: state.maintenanceDetail.maintenance,
    agentStatus: state.auth.userDetail?.agentStatus,
  }));

  const { isSupported: isSupportedBrowser, browserName } = useBrowserCheck();

  const isMSDynamicsOnboardingEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.MS_DYNAMICS_ONBOARDING,
  );

  const isIcaAgreementFeatureFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.ICA_AGREEMENTS,
  );

  const fetchAuth = useCallback(async () => {
    const requests = [
      dispatch(fetchMaintenancePeriod()),
      dispatch(fetchFutureMaintenancePeriod()),
    ];
    if (getAuthCookie()) {
      requests.push(dispatch(fetchAuthUserDetail()));
    }
    await Promise.all(requests);
    setLoading(false);
  }, [dispatch]);

  const checkEmailStatus = useCallback(async () => {
    try {
      const {
        data: { banned },
      } = await new EmailControllerApi(
        getHermesConfiguration(),
      ).checkMyEmailStatus();
      if (banned) setShowEmailBannedModal(true);
    } catch (e) {
      ErrorService.notify('Error in checking user email status', e);
    }
  }, []);

  useEffect(() => {
    fetchAuth();
  }, [fetchAuth]);

  useEffect(() => {
    if (isMSDynamicsOnboardingEnabled && userDetailId) {
      dispatch(getAvailableCountries(userDetailId));
      dispatch(fetchPaymentMethods(userDetailId));
      dispatch(getTaxForms(userDetailId));
    }
  }, [dispatch, isMSDynamicsOnboardingEnabled, userDetailId]);

  useEffect(() => {
    if (userDetailId) {
      checkEmailStatus();
      if (isIcaAgreementFeatureFlagEnabled) {
        dispatch(fetchAllLatestAgreementsNotSigned());
      } else if (agentStatus === AgentResponseAgentStatusEnum.Active) {
        dispatch(fetchLatestICAAgreement(userDetailId));
      }
    }
  }, [
    dispatch,
    userDetailId,
    agentStatus,
    checkEmailStatus,
    isIcaAgreementFeatureFlagEnabled,
  ]);

  useRegisterWindowHeightEffect();
  useDatadogEvaluateFeatureFlags();

  if (!cookiesEnabled()) {
    return <CookiesDisabledModal />;
  }

  if (loading || loadingUserDetail) {
    return (
      <div className='h-screen w-screen'>
        <DefaultLoader />
      </div>
    );
  }

  if (maintenance.inMaintenancePeriod) {
    return <MaintenanceRoute />;
  }

  if (!isSupportedBrowser) {
    return <ZenBrowserPopupModal browserName={browserName} />;
  }

  return (
    <div>
      <RoarDisclaimerModal />
      <BrowserRouter>
        <Switch>
          <Route path='/pdf'>
            <AuthenticatedRoute
              path='/pdf/invoice/:id'
              component={InvoiceFormPdfRoute}
              hideFutureMaintenanceAlert
            />
            <AuthenticatedRoute
              path='/pdf/cda/:id'
              component={CDAFormPdfRoute}
              hideFutureMaintenanceAlert
            />
            <AuthenticatedRoute
              path='/pdf/trade-record-sheets/:id'
              component={TransactionTradeRecordSheetPdfRoute}
              hideFutureMaintenanceAlert
              exact
            />
            <AuthenticatedRoute
              path='/pdf/trade-record-sheets/:id/participants/:participantId'
              component={TransactionTradeRecordSheetPdfRoute}
              hideFutureMaintenanceAlert
              exact
            />
            <AuthenticatedRoute
              path='/pdf/applications/:id/ica'
              component={ICASignedPdfRoute}
              hideFutureMaintenanceAlert
            />
            <AuthenticatedRoute
              path='/pdf/applications/:id/loi'
              component={LOISignedPdfRoute}
              hideFutureMaintenanceAlert
            />
            <AuthenticatedRoute
              path='/pdf/tax-documents/:id'
              component={TaxDocumentPdfRoute}
              hideFutureMaintenanceAlert
            />
            <AuthenticatedRoute
              path='/pdf/transactions/:transactionId/trust-deposit-receipt/:id'
              component={TrustDepositReceiptPdfRoute}
              hideFutureMaintenanceAlert
            />
            <AuthenticatedRoute
              path='/pdf/transactions/:transactionId/commission-deposit-receipt/:id'
              component={CommissionDepositReceiptPdfRoute}
              hideFutureMaintenanceAlert
            />
            <AuthenticatedRoute
              path='/pdf/conveyance-documents/:conveyanceDocumentId'
              component={ConveyanceDocumentsPdfRoute}
              hideFutureMaintenanceAlert
            />
            <AuthenticatedRoute
              path='/pdf/transactions/:id/transaction-summary'
              component={TransactionSummaryPdfRoute}
              hideFutureMaintenanceAlert
            />
          </Route>
          <Route path='/app'>
            <AuthenticatedRoute path='/app/cda/:id' component={CDAFormRoute} />
            <AuthenticatedRoute
              path='/app/invoice/:id'
              component={InvoiceFormRoute}
            />
          </Route>
          <Route path='/redirect' component={AuthRedirectRoute} exact />

          {/* Keeping this as Route and not AuthenticatedRoute to keep proper 40{1,4} routes */}
          <Route path='/onboarding' component={OnboardingRoutes} />

          <Route path='/welcome-to-rezen' component={RezenRoute} exact />
          <AuthenticatedRoute
            path='/welcome-to-rezen/ica-signing-v2'
            component={RezenICASigningV2Route}
            exact
          />
          <Route
            path='/welcome-to-rezen/mobile-app'
            component={ContinueOnMobileRoute}
            exact
          />
          <AuthenticatedRoute
            path='/welcome-to-rezen/agreements-signing'
            component={ApplicationAgreementsSigning}
          />
          <Route path='/register-tipalti' component={TipaltiRegister} exact />
          <AuthenticatedRoute
            path='/verify-license'
            component={VerifyLicensesOnboarding}
          />
          <AuthenticatedRoute
            path='/tax-national-ids'
            component={TaxAndNationalIDOnboardingForm}
            exact
          />
          <AuthenticatedRoute
            path='/payment-onboarding'
            component={MsDynamicsOnboardingRoute}
            exact
          />
          <AuthenticatedRoute
            path='/real-signature'
            component={RedirectToRealSignatureRoute}
          />
          <Route
            path='/join/:type(link|email)/:id'
            component={JoinByInvitationRoute}
          />
          <MainRoutes />
        </Switch>
        {/* Trigger page view event on every page */}
        <Route path='*' component={AnalyticsPageViewEvent} />
        <ZenTransactionErrorModal />
        <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.ROAR}>
          <BrokerOnly>
            <VoiceCallConnectedModal />
            <UnidentifiedSpeakersModal />
          </BrokerOnly>
        </FeatureFlagEnabledOnly>
      </BrowserRouter>
      <ToastNotificationContainer />
      <ApiErrorModal />
      {!!userDetailId && <ZenIdlePopupModal />}
      {showEmailBannedModal && (
        <EmailBannedPopupModal onClose={() => setShowEmailBannedModal(false)} />
      )}
    </div>
  );
};

export default App;
