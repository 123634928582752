import SwitchNullableColumnFilter, {
  SwitchNullableColumnFilterProps,
} from './Base/SwitchNullableColumnFilter';

export interface WalletLastStatementToggleColumnFilterProps<D extends object>
  extends SwitchNullableColumnFilterProps<D> {}

const WalletLastStatementToggleColumnFilter = <D extends object>(
  props: WalletLastStatementToggleColumnFilterProps<D>,
) => {
  return <SwitchNullableColumnFilter {...props} label='Last Statement' />;
};

export default WalletLastStatementToggleColumnFilter;
