import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocketLaunch } from '@fortawesome/pro-light-svg-icons';
import { faSparkle } from '@fortawesome/pro-solid-svg-icons';
import { noop } from 'lodash';
import { FeatureFlagTypeEnum, RootState } from '../types';
import {
  AdministrativeAreaResponseCountryEnum,
  AdministrativeAreaResponseStateOrProvinceEnum,
  UserControllerApi,
} from '../openapi/yenta';
import { useBaseMutation } from '../query/base/useBaseMutation';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { getAuthCookie } from '../utils/AuthUtils';
import { fetchAuthUserDetail } from '../slices/AuthSlice';
import DisclaimerImage from '../assets/img/roar/disclaimerImage.png';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { Modal } from './commons/Modal';
import { Pill } from './commons/Pill';
import { Button } from './commons/Button';

const LEARN_MORE_URL =
  'https://support.therealbrokerage.com/hc/en-us/articles/26673029816983/';

const REQUIRED_STATES = [
  AdministrativeAreaResponseStateOrProvinceEnum.Washington,
  AdministrativeAreaResponseStateOrProvinceEnum.Illinois,
  AdministrativeAreaResponseStateOrProvinceEnum.Texas,
];

export const RoarDisclaimerModal = () => {
  const userDetail = useSelector((state: RootState) => state.auth.userDetail);
  const hasSignedVoiceVectorDisclaimer = !!userDetail?.voiceVectorDisclaimerAcceptedAt;
  const isRoarLegalEnabled = useFeatureFlag(FeatureFlagTypeEnum.ROAR_LEGAL);
  const dispatch = useDispatch();

  // This modal is just temporary and will be removed once ICA is updated, so keeping changes to a minimum
  // eslint-disable-next-line bolt/no-react-query-outside-src-query
  const { mutate: acceptTermsAndConditions, isLoading } = useBaseMutation({
    queryKey: ['patchUserVoiceVectorDisclaimer'],
    mutationFn: async () => {
      await new UserControllerApi(getYentaConfiguration()).patchUser(
        userDetail?.id!,
        {
          voiceVectorDisclaimerAccepted: true,
        },
      );
      if (getAuthCookie()) {
        dispatch(fetchAuthUserDetail());
      }
    },
  });

  if (!isRoarLegalEnabled) {
    return null;
  }

  const userOfficeStates: AdministrativeAreaResponseStateOrProvinceEnum[] =
    userDetail?.offices
      ?.map((office) => office.administrativeArea?.stateOrProvince!)
      ?.filter(Boolean) ?? [];

  const isCanadian = userDetail?.offices?.some(
    (office) =>
      office.administrativeArea?.country ===
      AdministrativeAreaResponseCountryEnum.Canada,
  );

  if (hasSignedVoiceVectorDisclaimer) {
    return null;
  }

  if (
    !userOfficeStates.some((state) => REQUIRED_STATES.includes(state)) &&
    !isCanadian
  ) {
    return null;
  }

  return (
    <Modal size='730px' opened onClose={noop}>
      <Modal.Header hideIcon>
        <FontAwesomeIcon icon={faRocketLaunch} className='mr-2' />
        What&apos;s New
      </Modal.Header>
      <Modal.Content className='font-inter flex flex-col overflow-auto h-full max-h-[calc(100vh-35dvh)] scrollbar'>
        <div className='mb-6'>
          <div className='text-lg font-medium'>
            Real Operator Assisted Routing (ROAR) is now available in your
            state!
          </div>
          <div className='text-base font-normal text-grey-500'>
            At Real, we&apos;re all about making things simpler. That&apos;s why
            we&apos;re rolling out Real Operator Assisted Routing (ROAR), a
            game-changing tool that lets Agents and Brokers call each other
            directly through reZEN.
          </div>
        </div>
        <Pill color='green' className='flex-none'>
          ROAR Offers
        </Pill>
        <div className='flex gap-2.5 pl-2 mt-4 mb-4'>
          <FontAwesomeIcon
            icon={faSparkle}
            className='text-midnight-green-300 h-6 w-5'
          />
          <div>
            <div className='text-[15px] font-medium text-primary-navy'>
              Seamless Communication
            </div>
            <div className='text-sm text-regent-600'>
              Easily call brokers directly from a transaction without needing to
              manage separate phone numbers or platforms.
            </div>
          </div>
        </div>
        <div className='flex gap-2.5 pl-2 mb-7'>
          <FontAwesomeIcon
            icon={faSparkle}
            className='text-midnight-green-300 h-6 w-5'
          />
          <div>
            <div className='text-[15px] font-medium text-primary-navy'>
              Reduced Manual Tasks
            </div>
            <div className='text-sm text-regent-600'>
              Automated speaker identification and summary generation simplify
              post-call tasks, helping you focus on closing deals.
            </div>
          </div>
        </div>
        <div className='w-full mb-6'>
          <img src={DisclaimerImage} className='w-full' alt='ROAR' />
        </div>
        <div className='mb-3'>
          ROAR analyzes your voice to identify you in these transcripts and
          summaries.{` `}
          <div>
            <a
              href={LEARN_MORE_URL}
              target='_blank'
              className='text-rezen-blue-600 underline'
              rel='noreferrer'
            >
              Learn more about your data
            </a>
            . Please click &quot;I Agree&quot; to proceed.
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Button
          className='px-16'
          disabled={isLoading}
          onClick={() => acceptTermsAndConditions(undefined)}
        >
          I Agree
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
