import { values } from 'lodash';
import { ChecklistDefinitionDtoTargetTypeEnum } from '../../openapi/yenta';
import { EnumMap } from '../../types';
import { safeEnumMapGet } from '../EnumHelper';

const VALID_TARGET_TYPE_FOR_OFFICE_MAP: EnumMap<
  ChecklistDefinitionDtoTargetTypeEnum,
  boolean
> = {
  MLS: false,
  BOARD: false,
  CHECKLIST: false,
  CHECKLIST_ITEM: false,
  LISTING: true,
  REFERRAL: true,
  TRANSACTION: true,
  OFFICE: true,
  DROPBOX: false,
  LOAN: false,
  AGENT: false,
  APPLICATION: false,
  BORROWER: false,
  REAL: false,
  COMMENT: false,
  EMAIL: false,
  MORTGAGE_LEAD: false,
  TEAM: false,
  INTERNAL_REFERRAL: false,
  OUTGOING_PAYMENT: false,
  WALLET: false,
  VOICE: false,
  CONVERSATION: false,
  REFERRAL_CENTRAL: false,
  SIGNATURE_DOCUMENT: false,
  SIGNATURE_PACKET: false,
  USER_AGREEMENT: false,
  USER: false,
};

export const getValidChecklistTargetTypesForOffice = (): ChecklistDefinitionDtoTargetTypeEnum[] => {
  return values(ChecklistDefinitionDtoTargetTypeEnum).filter((targetType) =>
    safeEnumMapGet(VALID_TARGET_TYPE_FOR_OFFICE_MAP, targetType, false),
  );
};
