import { faCreditCard } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  BankAccountDtoTypeEnum,
  RealLendingAccountLiteDtoTypeEnum,
} from '../../../openapi/wallet';
import {
  useFetchBankingPartnership,
  useFetchEligibleOfferings,
  useWallet,
} from '../../../query/wallet/useWallet';
import { cn } from '../../../utils/classUtils';
import ZenPageLayout from '../../Zen/ZenPageLayout';
import ZenResourceContainer from '../../Zen/ZenResourceContainer';
import WalletAccountDetails from './WalletAccountDetails';
import WalletActivityTable from './WalletActivityTable';
import WalletCreditOffer from './WalletCreditOffer';
import WalletDebitCard from './WalletDebitCard';
import WalletMyCreditDetailsCard from './WalletMyCreditDetailsCard';
import WalletTotalValue from './WalletTotalValue';

interface WalletDebitDashboardRouteProps {}

const WalletDebitDashboardRoute: React.FC<WalletDebitDashboardRouteProps> = () => {
  const {
    data: wallet,
    isLoading: walletLoading,
    errorCode: walletError,
  } = useWallet();

  const { data: offerings } = useFetchEligibleOfferings();

  const { data: partnerships } = useFetchBankingPartnership(
    !isEmpty(wallet?.bankingPartnerships),
  );

  const debitAccountId = partnerships?.bankingPartnerships
    ?.flatMap((partner) => partner.accounts || [])
    .find((acc) => acc?.id && acc?.type === BankAccountDtoTypeEnum.Debit)?.id;

  const hasLendingAccount = useMemo(() => {
    return wallet?.realLendingAccounts?.some(
      (acc) => acc.type === RealLendingAccountLiteDtoTypeEnum.RealLending,
    );
  }, [wallet?.realLendingAccounts]);

  const splitIntoColumns = !!(
    debitAccountId || !isEmpty(offerings?.eligibleCreditOfferings)
  );

  return (
    <ZenPageLayout
      path={[
        { title: 'Home', url: '/' },
        { title: 'Real Wallet', url: '/wallet' },
      ]}
      textClassNames='font-inter'
    >
      <ZenResourceContainer
        loading={walletLoading}
        isEmpty={isEmpty(wallet)}
        resourceName='wallet dashboard'
        errorCode={walletError}
      >
        <div className='py-4.5 px-10 min-h-[calc(100vh_-_115px)] bg-regent-100'>
          <div className='flex justify-between'>
            <p className='mb-6 font-medium text-lg font-poppins'>Real Wallet</p>

            {debitAccountId && (
              <Link to={`/wallet/dashboard/debit/${debitAccountId}`}>
                <FontAwesomeIcon icon={faCreditCard} className='text-lg mr-1' />
              </Link>
            )}
          </div>

          <div className='grid grid-cols-6 gap-7'>
            {/* Left Column */}
            <div
              className={cn('col-span-6', {
                'md:col-span-4': splitIntoColumns,
              })}
            >
              <WalletTotalValue debitAccountId={debitAccountId} />
              <WalletActivityTable />
            </div>

            {/* Right Column */}
            <div className='col-span-6 md:col-span-2'>
              {!hasLendingAccount &&
                (offerings?.eligibleCreditOfferings || []).map((offer) => (
                  <WalletCreditOffer
                    key={offer.creditOffering?.id}
                    offer={offer}
                  />
                ))}

              {hasLendingAccount && (
                <WalletMyCreditDetailsCard showViewDetails />
              )}

              {debitAccountId && (
                <WalletAccountDetails debitAccountId={debitAccountId} />
              )}

              {debitAccountId && (
                <WalletDebitCard debitAccountId={debitAccountId} />
              )}
            </div>
          </div>
        </div>
      </ZenResourceContainer>
    </ZenPageLayout>
  );
};

export default WalletDebitDashboardRoute;
