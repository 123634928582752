import {
  faArrowLeftLong,
  faPencil,
  faSquareList,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTCInfo } from '../../slices/TransactionCoordinatorSlice';
import { RootState } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import { getFormatedTCAccessName } from '../../utils/TransactionCoordinatorUtils';
import { Button } from '../commons/Button';
import GeminiSidebarModalActionFooter from '../Gemini/Modal/GeminiSidebarModalActionFooter';
import IconButton from '../IconButton';
import GeminiConfirmationModal from './GeminiConfirmationModal';
import RemoveTC from './RemoveTC';
import RemoveTCConfirmationModal from './RemoveTCConfirmationModal';
import TCAccessConfirmationCard from './TCAccessConfirmationCard';
import TCInfoSection from './TCInfoSection';
import TCTransactionsAccessCard, {
  schema,
  TransactionCoordinatorFromData,
} from './TCTransactionsAccessCard';
import SidebarModalActionFooter from './SidebarModalActionFooter';

interface ActiveTCDetailsViewProps {
  tcId: string;
  onBackClick?(): void;
}

const ActiveTCDetailsView: FC<ActiveTCDetailsViewProps> = ({
  tcId,
  onBackClick,
}) => {
  const dispatch = useDispatch();
  const { tcDataById } = useSelector(
    (state: RootState) => state.transactionCoordinator,
  );
  const tcInfo = tcDataById[tcId];

  const {
    control,
    setValue,
    watch,
    handleSubmit,
  } = useForm<TransactionCoordinatorFromData>({
    defaultValues: {
      accessType: tcInfo?.accessType,
    },
    resolver: zodResolver(schema),
  });
  const { accessType, date } = watch();

  const [isEditingTC, setIsEditingTC] = useState(false);
  const [isRemovingTC, setIsRemovingTC] = useState(false);
  const [isEditConfirmModalOpen, setIsEditConfirmModalOpen] = useState(false);
  const [isRemoveConfirmModalOpen, setisRemoveConfirmModalOpen] = useState(
    false,
  );

  const handleTCAccessChange = async () => {
    toggleEditConfirmModal();
    setIsEditingTC(false);
    dispatch(fetchTCInfo(tcId));
  };

  const toggleEditConfirmModal = () =>
    setIsEditConfirmModalOpen((prev) => !prev);
  const toggleRemoveConfirmModal = () =>
    setisRemoveConfirmModalOpen((prev) => !prev);

  return (
    <div className='font-inter'>
      <div className='mb-24'>
        <div className='p-4 bg-regent-100 flex items-center gap-4'>
          <IconButton
            leftIcon={<FontAwesomeIcon icon={faArrowLeftLong} />}
            variant='grey-outline'
            buttonStyle='w-8 h-8'
            onClick={onBackClick}
          />
          <p className='text-primary-dark text-xs sm:text-sm font-medium'>
            {tcInfo?.name}
          </p>
        </div>
        {tcInfo && (
          <div className='border-y border-regent-300'>
            <TCInfoSection tcInfo={tcInfo} showAdditionalInfo={!isRemovingTC} />
          </div>
        )}
        <div className='p-4'>
          {isEditingTC || isRemovingTC ? (
            <>
              {isEditingTC && (
                <form onSubmit={handleSubmit(toggleEditConfirmModal)}>
                  <p className='text-gray-500 text-xs sm:text-sm mb-2'>
                    Transaction Access
                  </p>
                  <TCTransactionsAccessCard
                    control={control}
                    setValue={setValue}
                    selectedAccessType={accessType}
                  />
                  <GeminiSidebarModalActionFooter
                    submitButtonText='Select'
                    isSubmitting={false}
                    onClose={() => setIsEditingTC(false)}
                    isDisabled={!accessType}
                    alignRight
                  />
                </form>
              )}
              {isRemovingTC && (
                <form onSubmit={handleSubmit(toggleRemoveConfirmModal)}>
                  <RemoveTC
                    control={control}
                    setValue={setValue}
                    selectedAccessType={accessType}
                  />
                  <SidebarModalActionFooter
                    submitButtonText='Remove'
                    submitButtonColor='red'
                    isSubmitting={false}
                    onCancel={() => setIsRemovingTC(false)}
                    isDisabled={!accessType}
                    alignRight
                  />
                </form>
              )}
            </>
          ) : (
            <div className='flex justify-between items-center border border-grey-300 rounded-lg px-6 py-4'>
              <div className='flex gap-5 items-center'>
                <FontAwesomeIcon icon={faSquareList} size='xl' />
                <div>
                  <p className='text-sm sm:text-base'>Transaction Access</p>
                  <p className='text-xs sm:text-sm font-semibold text-rezen-blue-600'>
                    {capitalizeEnum(tcInfo?.accessType!)}
                  </p>
                </div>
              </div>
              <IconButton
                leftIcon={<FontAwesomeIcon icon={faPencil} size='sm' />}
                onClick={() => setIsEditingTC(true)}
                variant='none'
                buttonStyle='bg-grey-200 rounded-full p-2.5'
              />
              <div className='py-6 px-5 bg-white border-t border-gray-200 w-full absolute bottom-0 left-0'>
                <Button
                  variant='link'
                  color='red'
                  leftIcon={<FontAwesomeIcon icon={faTrashCan} />}
                  onClick={() => setIsRemovingTC(true)}
                >
                  Remove Transaction Coordinator
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <GeminiConfirmationModal
        title='Transaction Access Confirmation'
        isOpen={isEditConfirmModalOpen}
        onClose={toggleEditConfirmModal}
        onConfirm={handleTCAccessChange}
        size='large'
      >
        <TCAccessConfirmationCard
          tcName={tcInfo?.name!}
          tcId={tcId}
          selectedAccessPreference={getFormatedTCAccessName({
            accessType,
            date,
          })}
        />
      </GeminiConfirmationModal>
      <RemoveTCConfirmationModal
        isOpen={isRemoveConfirmModalOpen}
        onClose={toggleRemoveConfirmModal}
        tcName={tcInfo?.name!}
        accessType={accessType}
      />
    </div>
  );
};

export default ActiveTCDetailsView;
