import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import WalletRepaymentSideBarModal from '../../../forms/WalletRepaymentSideBarModal';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';

interface WalletRepaymentProps {}

const WalletRepayment: React.FC<WalletRepaymentProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <ZenResourceLinkCard
        title='Wallet Repayment'
        onClick={() => setIsOpen(true)}
        icon={light('wallet')}
      />
      <WalletRepaymentSideBarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default WalletRepayment;
