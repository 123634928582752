import React from 'react';
import { cn } from '../../utils/classUtils';
import { Button, Color } from '../commons/Button';

interface SidebarModalActionFooterProps {
  submitButtonText?: string;
  submitButtonColor?: Color;
  cancelButtonText?: string;
  onCancel?(): void;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  alignRight?: boolean;
}

const SidebarModalActionFooter: React.FC<SidebarModalActionFooterProps> = ({
  submitButtonText = 'Save',
  submitButtonColor = 'blue',
  cancelButtonText = 'Cancel',
  onCancel,
  isSubmitting,
  isDisabled,
  alignRight = false,
}) => {
  return (
    <div className='py-6 px-5 bg-white border-t border-gray-200 flex flex-row items-center justify-end w-full gap-4 absolute left-0 bottom-0'>
      <div
        className={cn('w-full', {
          'w-[174px]': alignRight,
        })}
      >
        <Button variant='outline' onClick={onCancel} fullWidth>
          {cancelButtonText}
        </Button>
      </div>
      <div
        className={cn('w-full', {
          'w-[174px]': alignRight,
        })}
      >
        <Button
          variant='filled'
          color={submitButtonColor}
          disabled={isSubmitting || isDisabled}
          loading={isSubmitting}
          type='submit'
          fullWidth
        >
          {submitButtonText}
        </Button>
      </div>
    </div>
  );
};

export default SidebarModalActionFooter;
