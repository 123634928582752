import {
  faCalendar,
  faTrashCan,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { faTag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compact, keys } from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as NewFile } from '../../../assets/img/file-new.svg';
import {
  CHECKLIST_ALLOWED_FILE_TYPES,
  MAX_DOC_SIZE_100MB,
} from '../../../constants/FilesConstants';
import { FileResponse } from '../../../openapi/dropbox';
import {
  FileReferenceInfo,
  ItemDtoRequiredForEnum,
  ItemDtoStatusEnum,
  ItemRequest,
  ItemResponse,
  ItemResponseStatusEnum,
  LabelDto,
} from '../../../openapi/sherlock';
import { RezenObjectTypeEnum } from '../../../openapi/yada';
import {
  addFileReferencesToChecklistItem,
  createChecklistItem,
  deleteChecklistItem,
  fetchChecklistItemById,
  updateChecklistItem,
  uploadChecklistDocument,
  uploadToDropboxAndAddFileReferences,
} from '../../../slices/CheckListSlice';
import {
  downloadAndUploadFilesToDropbox,
  downloadDropboxFileAndUploadToChecklistItem,
} from '../../../slices/DropboxSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { AppDispatch, ISelectOption, RootState } from '../../../types';
import {
  checkListStatusToDisplayName,
  getValidChecklistStatusesForRole,
  isV2Checklist,
  uploadDocumentsOneByOne,
} from '../../../utils/ChecklistUtils';
import {
  isDocSizeInvalid,
  validDocs,
  validFormatDocs,
} from '../../../utils/FileUtils';
import { getYentaImageUrl } from '../../../utils/ImgUtils';
import { removeObjectProperties } from '../../../utils/ObjectUtils';
import { cn } from '../../../utils/classUtils';
import ChecklistItemCommentSection from '../../Checklist/ChecklistItemCommentSection';
import ProfileImage from '../../ProfileImage';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ExcludeAdmin from '../../auth/ExcludeAdmin';
import ExcludeAgent from '../../auth/ExcludeAgent';
import ZenFromFileCabinetButton from '../../dropbox/ZenFromFileCabinetButton';
import { MentionSource } from '../../transactions/Comments/ZenCommentSection';
import ZenControlledDatePickerInput from '../Input/ZenControlledDatePickerInput';
import ZenControlledDraggableDocumentUploadInput from '../Input/ZenControlledDraggableDocumentUploadInput';
import ZenControlledMultiSelectCreatableInput from '../Input/ZenControlledMultiSelectCreatableInput';
import ZenControlledSelectInput from '../Input/ZenControlledSelectInput';
import ZenControlledTextAreaInput from '../Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenControlledToggleInput from '../Input/ZenControlledToggleInput';
import ZenButton from '../ZenButton';
import ZenSidebarModal from '../ZenSidebarModal';
import ZenChecklistItemDocumentRow from './ZenChecklistItemDocumentsList';
import ZenChecklistItemDropboxDocumentsList from './ZenChecklistItemDropboxDocumentsList';
import ZenChecklistItemEmbeddedDocumentsList from './ZenChecklistItemEmbeddedDocumentsList';
import ZenChecklistLabelDropdown from './ZenChecklistStatusLabelDropdown';

interface ZenAddCheckListItemFormProps {
  isOpen: boolean;
  onClose(): void;
  checklistId: string;
  checklistItem?: ItemResponse | null;
  nextChecklistItemPosition: number;
  labelOptions: ISelectOption[];
  dropboxId?: string;
  secondaryDropboxId?: string;
  containerType: RezenObjectTypeEnum;
  containerId: string;
  assigneeList?: ISelectOption[];
  getMentions?(searchTerm?: string): MentionSource[];
  callerGroupId?: string;
}

export const NOT_ASSIGNED_VALUE = 'not-assigned';

interface FormData
  extends Omit<
    ItemResponse,
    'documents' | 'comments' | 'labels' | 'required' | 'status'
  > {
  required: boolean;
  labels: ISelectOption[];
  docFiles: File[];
  status: ItemDtoStatusEnum;
}

const ZenAddCheckListItemForm: React.FC<ZenAddCheckListItemFormProps> = ({
  checklistId,
  checklistItem,
  isOpen,
  onClose,
  nextChecklistItemPosition,
  labelOptions,
  dropboxId,
  secondaryDropboxId,
  containerType,
  containerId,
  assigneeList,
  getMentions,
  callerGroupId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [
    isChecklistItemDeleting,
    setIsChecklistItemDeleting,
  ] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const {
    auth: { userDetail, isAdmin, isBroker, hasMortgagePermission },
    checklist: { documentUploadLoading, checklistsById },
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, dirtyFields },
  } = useForm<FormData>({
    defaultValues: {
      name: checklistItem?.name,
      status:
        ((checklistItem?.status! as unknown) as ItemDtoStatusEnum)! ||
        ItemDtoStatusEnum.NotStarted,
      urgent: checklistItem?.urgent,
      // @ts-ignore
      assignee:
        assigneeList?.find((o) => o.value === checklistItem?.assignee) || null,
      dueDate: checklistItem?.dueDate,
      description: checklistItem?.description,
      required: checklistItem?.required,
      labels: (checklistItem?.labels || []).map((label) => ({
        label: label.text,
        value: label.text,
      })),
    },
  });

  const status = watch('status');
  const assignee: any = watch('assignee');
  const docs = watch('docFiles');
  const changeFields = removeObjectProperties(dirtyFields, ['docFiles']);
  const isRequired = watch('required');
  const isDocumentUploadLoading =
    documentUploadLoading[checklistItem?.id!] || isUploading;

  const isChecklistV2 = isV2Checklist(checklistsById[checklistId]?.data);

  const onSubmit = async (formData: FormData) => {
    const labels: LabelDto[] = formData.labels.map((label) => ({
      text: label.label,
    }));

    const checklistItemReq: ItemRequest = {
      item: {
        ...(checklistItem || {}),
        position:
          typeof checklistItem?.position !== 'undefined'
            ? checklistItem?.position
            : nextChecklistItemPosition - 1,
        name: formData.name!,
        status: formData.status,
        urgent: formData.urgent,
        // @ts-ignore
        assignee: formData.assignee?.value || undefined,
        required: formData.required,
        dueDate: formData.dueDate,
        description: formData.description,
        labels,
        requiredFor:
          ((checklistItem?.requiredFor as unknown) as ItemDtoRequiredForEnum) ||
          undefined,
      },
    };

    if (checklistItem) {
      await dispatch(
        updateChecklistItem(checklistId, checklistItem.id!, checklistItemReq),
      );
    } else {
      await dispatch(createChecklistItem(checklistId, checklistItemReq));
    }

    onClose();
  };

  const handleDeleteChecklistItem = async () => {
    setIsChecklistItemDeleting(true);
    await dispatch(deleteChecklistItem(checklistId, checklistItem?.id!));
    setIsChecklistItemDeleting(false);
    onClose();
  };

  const handleOnAttach = async (
    files: FileResponse[],
    uploadAndAttach?: boolean,
  ) => {
    if (isChecklistV2) {
      if (!!uploadAndAttach) {
        const data = await dispatch(
          downloadAndUploadFilesToDropbox(files, dropboxId!, userDetail?.id!),
        );
        files = compact(data);
      }
      const dropboxFileIds: FileReferenceInfo[] = files.map((file) => ({
        fileId: file.id!,
        filename: file.filename!,
      }));
      const data = await dispatch(
        addFileReferencesToChecklistItem(
          checklistId,
          checklistItem?.id!,
          dropboxFileIds,
        ),
      );

      if (data) {
        await dispatch(fetchChecklistItemById(checklistId, checklistItem?.id!));
      }

      return data;
    } else {
      const uploadedStatuses = await Promise.all(
        files.map((file) =>
          dispatch(
            downloadDropboxFileAndUploadToChecklistItem(
              file,
              checklistItem?.id!,
              containerId,
              userDetail?.id!,
            ),
          ),
        ),
      );

      const isAllFilesUploaded = uploadedStatuses.every((status) => status);

      if (isAllFilesUploaded) {
        await dispatch(fetchChecklistItemById(checklistId, checklistItem?.id!));
      }

      return isAllFilesUploaded;
    }
  };

  const uploadDocument = useCallback(async (doc: File) => {
    await dispatch(
      uploadChecklistDocument(
        checklistItem?.id!,
        doc.name,
        '',
        userDetail?.id!,
        doc,
        containerId,
        checklistId,
      ),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const uploadDocs = async () => {
      setIsUploading(true);
      if (validFormatDocs(docs, CHECKLIST_ALLOWED_FILE_TYPES)) {
        if (isChecklistV2) {
          const validFiles = validDocs(docs, MAX_DOC_SIZE_100MB);
          await dispatch(
            uploadToDropboxAndAddFileReferences(
              checklistId,
              checklistItem?.id!,
              dropboxId!,
              userDetail?.id!,
              validFiles,
            ),
          );
        } else {
          await uploadDocumentsOneByOne(docs, uploadDocument);
          const count = docs?.length;
          dispatch(
            showSuccessToast(
              `${pluralize('Document', count)} uploaded successfully`,
            ),
          );
        }
        await dispatch(fetchChecklistItemById(checklistId, checklistItem?.id!));
        if (isDocSizeInvalid(docs, MAX_DOC_SIZE_100MB)) {
          dispatch(
            showErrorToast('File size exceeds maximum limit of 100 MB.'),
          );
        }
      } else {
        dispatch(
          showErrorToast(
            `Allowed file types (${CHECKLIST_ALLOWED_FILE_TYPES.join(',')}).`,
          ),
        );
      }
      setIsUploading(false);
    };

    if (docs?.length) {
      uploadDocs();
    }
  }, [
    checklistId,
    checklistItem?.id,
    dispatch,
    docs,
    uploadDocument,
    isChecklistV2,
    dropboxId,
    userDetail?.id,
  ]);

  const isAgent = !isAdmin && !isBroker && !hasMortgagePermission;

  const isAssigned =
    !!assignee?.value && assignee?.value !== NOT_ASSIGNED_VALUE;

  const isBorrowerContainer = containerType === RezenObjectTypeEnum.Borrower;

  const isStatusReadOnly =
    isAgent && checklistItem?.status === ItemResponseStatusEnum.Accepted;

  const handleStatusChange = (value: ItemDtoStatusEnum) => {
    setValue('status', value);
  };

  useEffect(() => {
    if (checklistItem) {
      setValue(
        'status',
        (checklistItem?.status! as unknown) as ItemDtoStatusEnum,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistItem?.status, setValue]);

  return (
    <ZenSidebarModal
      title={checklistItem ? checklistItem.name! : 'New Checklist Item'}
      isOpen={isOpen}
      onClose={() => {
        if (!!keys(changeFields)?.length) {
          setOpenCancelModal(true);
        } else {
          onClose();
        }
      }}
    >
      <div className={checklistItem ? 'h-min' : 'h-full'}>
        <form
          className='flex flex-col h-full'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='p-4'>
            <div>
              <ExcludeAgent>
                <div>
                  <div className='flex items-center space-x-4 justify-start mt-2 mb-4'>
                    <ExcludeAdmin>
                      {!!status && isRequired && !isBorrowerContainer && (
                        <span className='font-zen-body text-sm font-normal text-zen-danger'>
                          Required
                        </span>
                      )}
                    </ExcludeAdmin>
                    <ZenChecklistLabelDropdown
                      readOnly={isStatusReadOnly}
                      data-testid='statusDropdown'
                      options={getValidChecklistStatusesForRole(isAgent).map(
                        (role) => ({
                          value: role,
                          label: checkListStatusToDisplayName(
                            role,
                            isRequired,
                            containerType,
                            isAssigned,
                          ),
                        }),
                      )}
                      status={(status as unknown) as ItemResponseStatusEnum}
                      isRequired={isRequired}
                      onChange={(value) =>
                        handleStatusChange(
                          (value! as unknown) as ItemDtoStatusEnum,
                        )
                      }
                      containerType={containerType}
                      isAssigned={isAssigned}
                    />
                  </div>
                </div>
              </ExcludeAgent>
              {!checklistItem?.systemGenerated && (
                <div>
                  <label
                    htmlFor='name'
                    className='text-base font-zen-body font-semibold text-zen-dark-9'
                  >
                    Item Name <span className='text-zen-danger'>*</span>
                  </label>
                  <div className='pt-1'>
                    <ZenControlledTextInput<FormData, 'name'>
                      control={control}
                      name='name'
                      isRequired
                      placeholder='Item Name'
                      rules={{ required: 'Please enter item name.' }}
                    />
                  </div>
                </div>
              )}
              <div className='mt-5 flex md:flex-row flex-col md:items-center items-end'>
                {!!assigneeList?.length && (
                  <div className='md:w-5/12 w-full'>
                    <ZenControlledSelectInput<FormData, 'assignee'>
                      control={control}
                      name='assignee'
                      label='Assigned To'
                      startAdornment={
                        !!assignee && !!assignee.value ? (
                          <ProfileImage
                            imageUrl={getYentaImageUrl(
                              agentById[assignee.value]?.avatar,
                            )}
                            width={20}
                            variant='circle'
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faUser}
                            className='text-primary-blue text-base'
                          />
                        )
                      }
                      placeholder='Add Role'
                      options={[...assigneeList]}
                    />
                  </div>
                )}
                <ExcludeAgent>
                  <div className='ml-7 mt-7 flex items-start'>
                    <label
                      htmlFor='name'
                      className='text-base font-zen-body font-semibold text-zen-dark-9 mt-1'
                    >
                      Required?
                    </label>
                    <div className='flex w-min'>
                      <ZenControlledToggleInput<FormData, 'required'>
                        name='required'
                        label=''
                        control={control}
                        defaultValue={false}
                      />
                    </div>
                  </div>
                </ExcludeAgent>
              </div>
              <div className='mt-5 flex md:flex-row flex-col md:items-center items-end'>
                <div className='md:w-5/12 w-full'>
                  <ZenControlledDatePickerInput<FormData, 'dueDate'>
                    name='dueDate'
                    label='Due Date'
                    control={control}
                    placeholder='Assign Due Date'
                    icon={
                      <FontAwesomeIcon
                        icon={faCalendar}
                        className='text-primary-blue text-lg -mb-0.5'
                      />
                    }
                  />
                </div>
                <div className='ml-7 mt-7 flex items-start'>
                  <label
                    htmlFor='name'
                    className='text-base font-zen-body font-semibold text-zen-dark-9 mt-1'
                  >
                    Urgent?
                  </label>
                  <div className='flex w-min'>
                    <ZenControlledToggleInput<FormData, 'urgent'>
                      name='urgent'
                      label=''
                      control={control}
                      defaultValue={false}
                    />
                  </div>
                </div>
              </div>
              <div className='mt-5'>
                <ZenControlledMultiSelectCreatableInput<FormData, 'labels'>
                  name='labels'
                  control={control}
                  label='Add Label(s)'
                  placeholder='Type Label'
                  options={labelOptions}
                  customOptionIcon={
                    <FontAwesomeIcon icon={faTag} className='mr-2' />
                  }
                  variant='default'
                />
              </div>
              <div className='mt-5'>
                <ZenControlledTextAreaInput<FormData, 'description'>
                  name='description'
                  control={control}
                  label='Description'
                  placeholder='Add Description'
                  className='font-zen-body text-base text-dark font-normal'
                  rows={5}
                  noResize
                />
              </div>
            </div>
            {isChecklistV2 &&
              !!checklistItem?.templateReferences?.references?.length && (
                <ZenChecklistItemEmbeddedDocumentsList
                  templateReferences={
                    checklistItem?.templateReferences?.references
                  }
                />
              )}
            {checklistItem && (
              <div className='mt-5 flex flex-col h-full'>
                <div className='flex flex-row items-center space-x-4'>
                  <div className='mt-0.5 flex-grow'>
                    <ZenControlledDraggableDocumentUploadInput<
                      FormData,
                      'docFiles'
                    >
                      name='docFiles'
                      label='Add Documents'
                      control={control}
                      isUploading={isDocumentUploadLoading}
                      height='small'
                      customPlaceHolder={
                        <div className='flex md:flex-row flex-col justify-center items-center'>
                          <NewFile className='w-8 h-8' />
                          <span className='font-zen-body font-semibold text-sm text-primary-blue ml-2'>
                            Browse Files
                          </span>
                          <p className='font-zen-body text-sm font-semibold text-zen-dark-7 ml-1'>
                            or drag & drop new file
                          </p>
                        </div>
                      }
                      accept={CHECKLIST_ALLOWED_FILE_TYPES.join(',')}
                      maxUploadSize={MAX_DOC_SIZE_100MB}
                    />
                  </div>
                  {!!dropboxId && (
                    <div className='pt-8'>
                      <ZenFromFileCabinetButton
                        checklistId={checklistId}
                        dropboxId={dropboxId}
                        secondaryDropboxId={secondaryDropboxId}
                        onAttach={handleOnAttach}
                        isMultiple
                      />
                    </div>
                  )}
                </div>

                <div>
                  {isChecklistV2 ? (
                    <>
                      {!!checklistItem?.fileReferences?.references?.length && (
                        <ZenChecklistItemDropboxDocumentsList
                          checklistId={checklistId}
                          checklistItem={checklistItem}
                          dropboxId={dropboxId!}
                          secondaryDropboxId={secondaryDropboxId}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {!!checklistItem?.documents?.length && (
                        <ZenChecklistItemDocumentRow
                          checklistId={checklistId}
                          checklistItem={checklistItem}
                        />
                      )}
                    </>
                  )}
                </div>
                {!checklistItem.systemGenerated && (
                  <div className='flex'>
                    <button
                      onClick={() => setOpenDeleteModal(true)}
                      type='button'
                      className='appearance-none flex flex-row items-center py-2 my-2 space-x-2 text-zen-danger rounded cursor-pointer font-zen-body-medium focus:outline-none'
                    >
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        className='text-zen-danger'
                      />
                      <span className='inline text-base mt-1'>
                        Delete Checklist Item
                      </span>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>

          <div
            className={cn(
              'mt-5 pt-4 px-3 md:pr-3 w-full space-y-5 bottom-0 left-0 right-0 h-full',
              checklistItem ? 'border-t border-zen-dark-6' : 'border-t-0',
            )}
          >
            {checklistItem && (
              <ChecklistItemCommentSection
                checklistItem={checklistItem}
                containerType={containerType}
                containerId={containerId}
                getMentions={getMentions}
                callerGroupId={callerGroupId}
              />
            )}
          </div>

          <div
            className={cn(
              'px-3 md:pr-3 sticky w-full bg-regent-100 space-y-4 bottom-0 left-0 right-0 py-4 z-40',
            )}
          >
            <div className='md:pl-[40%] w-full space-x-5 flex flex-row justify-start items-center'>
              <ZenButton
                type='button'
                onClick={() => {
                  if (!!keys(changeFields)?.length) {
                    setOpenCancelModal(true);
                  } else {
                    onClose();
                  }
                }}
                label='Cancel'
                variant='primary-outline'
                isFullWidth
              />
              <ZenButton
                isDisabled={isSubmitting}
                isSubmitting={isSubmitting}
                type='submit'
                label='Save'
                isFullWidth
              />
            </div>
          </div>
        </form>
      </div>

      <ZenConfirmationModal
        variant='danger'
        subtitle={
          <span className='text-zen-dark-9'>
            This will delete <b>{checklistItem?.name}</b> and associated
            information permanently
          </span>
        }
        title='Delete checklist item'
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        onConfirm={() => {
          handleDeleteChecklistItem();
        }}
        isOpen={openDeleteModal}
        isSubmitting={isChecklistItemDeleting}
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        confirmButtonLeftIcon={
          <FontAwesomeIcon icon={faTrashCan} className='mr-1' />
        }
        size='default'
      />
      <ZenConfirmationModal
        variant='danger'
        subtitle={
          <span className='text-zen-dark-9'>
            You have unsaved changes that haven&apos;t been saved. Are you sure
            you want to leave this page?
          </span>
        }
        title='Are you sure you want to leave this page?'
        onClose={() => {
          setOpenCancelModal(false);
        }}
        onConfirm={() => {
          setOpenCancelModal(false);
          onClose();
        }}
        isOpen={openCancelModal}
        cancelButtonText='Stay on this page'
        confirmButtonText='Discard Changes'
        size='large'
      />
    </ZenSidebarModal>
  );
};

export default ZenAddCheckListItemForm;
