import Fuse from 'fuse.js';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { SearchApi } from '../../../openapi/leo';
import ErrorService from '../../../services/ErrorService';
import { getLeoConfiguration } from '../../../utils/OpenapiConfigurationUtils';

export enum NeoLeoSearchCategoryEnum {
  ExternalLinks = 'EXTERNAL_LINKS',
  InternalPages = 'INTERNAL_PAGES',
  PromptSuggestions = 'PROMPT_SUGGESTIONS',
  Deposits = 'DEPOSITS',
}

export interface NeoLeoSearchResult {
  category: NeoLeoSearchCategoryEnum;
  category_title: string;
  items: NeoLeoSearchItem[];
}

export interface NeoLeoSearchItem {
  title?: string;
  description?: string;
  url?: string;
  prompt?: string;
  action_type?: string;
}

const useNeoLeoKBarActions = (askLeoText?: string): NeoLeoSearchResult[] => {
  const ACTION_ITEMS_LIMIT = 5;
  const [actions, setActions] = useState<NeoLeoSearchResult[]>([]);
  const [filteredActions, setFilteredActions] = useState<NeoLeoSearchResult[]>(
    [],
  );

  const fetchSearchActions = useCallback(async () => {
    try {
      const { data } = await new SearchApi(
        getLeoConfiguration(),
      ).searchSearchGet(undefined, 1, 100);

      setActions(data?.results || []);
    } catch (error) {
      ErrorService.notifyIgnoreAuthErrors(
        'Error fetching search results',
        error,
      );
    }
  }, []);

  const getFilteredActions = useCallback(
    (searchTerm?: string) => {
      if (!actions.length) return;

      const fuseOptions = {
        keys: ['title'], // Searching based on 'title' field
        threshold: 0.3, // Adjust this value for fuzziness (lower is stricter)
        distance: 100, // Adjust this value for fuzziness (higher is stricter)
      };

      const newFilteredActions = actions
        .map((category) => {
          const fuse = new Fuse(category.items, fuseOptions);

          const filteredItems = fuse
            .search(searchTerm || '')
            .map((result) => result.item);

          if (filteredItems.length > 0) {
            return {
              ...category,
              items: filteredItems.slice(0, ACTION_ITEMS_LIMIT),
            };
          }

          return null;
        })
        .filter(Boolean) as NeoLeoSearchResult[];

      if (!isEqual(newFilteredActions, filteredActions)) {
        setFilteredActions(newFilteredActions);
      }
    },
    [actions, filteredActions],
  );

  useEffect(() => {
    fetchSearchActions();
  }, [fetchSearchActions]);

  useEffect(() => {
    if (askLeoText) {
      getFilteredActions(askLeoText);
    }
  }, [askLeoText, getFilteredActions]);

  return filteredActions;
};

export default useNeoLeoKBarActions;
