import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { TransactionCoordinatorAccessEnum } from '../../types';
import { cn } from '../../utils/classUtils';
import { HookFormDatePickerInput } from '../commons/hookFormInputs/HookFormDatePickerInput';
import { TransactionCoordinatorFromData } from './TCTransactionsAccessCard';

const activeTabClassName = 'bg-white rounded-lg border border-regent-600';
const tabs: {
  label: string;
  value: TransactionCoordinatorAccessEnum;
}[] = [
  {
    label: 'All',
    value: TransactionCoordinatorAccessEnum.ALL_OPEN_LISTINGS_AND_TRANSACTIONS,
  },
  {
    label: 'Created after',
    value: TransactionCoordinatorAccessEnum.CREATED_AFTER,
  },
];

interface RemoveTCProps {
  control: Control<TransactionCoordinatorFromData, object>;
  setValue: UseFormSetValue<TransactionCoordinatorFromData>;
  selectedAccessType: TransactionCoordinatorAccessEnum;
}

const RemoveTC: React.FC<RemoveTCProps> = ({
  control,
  setValue,
  selectedAccessType,
}) => {
  return (
    <div className='p-4 font-inter'>
      <div className='flex gap-3 '>
        <FontAwesomeIcon
          icon={faTrashCan}
          className='text-coral-red text-2xl bg-red-100 p-2.5 rounded-lg'
        />
        <div className='flex gap-4 flex-col w-full'>
          <div className='flex flex-col gap-1'>
            <h5 className='font-medium text-base text-primary-dark'>
              Remove Transaction Coordinator
            </h5>
            <p className='text-sm font-normal text-grey-600'>
              Which transactions and listings should they be removed from?
            </p>
          </div>
          <div className='flex justify-between items-center gap-6 flex-wrap'>
            <div className='flex bg-regent-300 py-1.5 px-2 gap-3 rounded-lg items-center'>
              {tabs.map((tab) => (
                <span
                  key={tab.value}
                  className={cn(
                    'px-4 py-1 min-w-[115px] text-center cursor-pointer',
                    {
                      [activeTabClassName]: selectedAccessType === tab.value,
                    },
                  )}
                  onClick={() => {
                    setValue('accessType', tab.value);
                  }}
                >
                  {tab.label}
                </span>
              ))}
            </div>
            {selectedAccessType === 'CREATED_AFTER' && (
              <HookFormDatePickerInput
                control={control}
                name='date'
                placeholder='Select Date'
                allowDeselect
                icon={faCalendar}
                classNames={{
                  icon: 'text-coral-red',
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveTC;
