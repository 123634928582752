import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface WalletOfferAcceptedModalProps {
  onClose(): void;
}

const WalletOfferAcceptedModal: React.FC<WalletOfferAcceptedModalProps> = ({
  onClose,
}) => {
  return (
    <div className='fixed inset-0 z-50 flex justify-center items-center overflow-y-auto'>
      <div
        className='absolute inset-0 bg-primary-dark bg-opacity-40'
        role='button'
        onClick={onClose}
      />
      <div className='relative flex flex-col w-11/12 max-w-lg bg-white rounded-lg overflow-y-auto max-h-[90%]'>
        {/* Header */}
        <div className='w-full px-6 py-4 flex justify-between items-center bg-rezen-blue-100'>
          <p className='font-inter font-medium text-base text-primary-dark'>
            Offer Details
          </p>
          <FontAwesomeIcon
            icon={faCircleXmark}
            className='text-xl text-regent-400 cursor-pointer'
            onClick={onClose}
          />
        </div>

        {/* Content */}
        <div className='flex flex-col items-center gap-2.5 pt-6 pb-10 px-6'>
          <FontAwesomeIcon
            icon={faCircleCheck}
            className='text-5xl text-green-600'
            title='offer-accepted'
          />
          <p className='mt-4 font-inter font-medium text-2xl text-center text-primary-dark'>
            Offer Accepted
          </p>
          <p className='mt-1 w-3/4 font-inter font-light text-sm text-regent-600 text-center'>
            Congratulations! Your credit limit is now available. You can request
            funds from your limit to your debit card anytime.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WalletOfferAcceptedModal;
