import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal as MModal, ModalProps as MModalProps } from '@mantine/core';
import React, { createContext, useContext, useRef } from 'react';
import { cn } from '../../utils/classUtils';
import {
  applyImportant,
  combineDefaultClassnamesWithCustomClassnames,
} from './utils';

type TContext = { close: () => void };

const Context = createContext<TContext>((null as unknown) as TContext);

const defaultStyles: MModalProps['classNames'] = {
  content: 'overflow-y-visible',
  body: 'p-0',
};

interface ModalProps
  extends Omit<MModalProps, 'radius' | 'withCloseButton' | 'title'> {}

const _Modal: React.FC<ModalProps> = ({ children, ...props }) => {
  const value = useRef({ close: props.onClose });

  return (
    <Context.Provider value={value.current}>
      <MModal
        centered
        trapFocus={false}
        {...props}
        withCloseButton={false}
        className='!overflow-y-visible'
        radius='12px'
        classNames={applyImportant(
          combineDefaultClassnamesWithCustomClassnames(
            defaultStyles,
            props.classNames,
          ),
        )}
      >
        {children}
      </MModal>
    </Context.Provider>
  );
};

type HeaderColor = 'blue' | 'white';

type HeaderProps = {
  className?: string;
  color?: HeaderColor;
  hideIcon?: boolean;
};

const headerColorMap: Record<HeaderColor, string> = {
  blue: 'bg-regent-200',
  white: 'bg-white',
};

const Header: React.FC<HeaderProps> = ({
  className,
  color,
  hideIcon,
  children,
}) => {
  const { close } = useContext(Context);
  return (
    <div
      className={cn(
        'flex items-start justify-between text-primary-dark font-semibold py-4 px-6 font-inter rounded-t-xl',
        headerColorMap[color ?? 'blue'],
        className,
      )}
    >
      <div className='flex-1'>{children}</div>
      {!hideIcon && (
        <div className='w-6 h-6 rounded-full bg-grey-400/70 cursor-pointer relative'>
          <FontAwesomeIcon
            icon={faXmark}
            onClick={close}
            className='text-primary-light absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3/5 h-3/5'
          />
        </div>
      )}
    </div>
  );
};

type ContentProps = { className?: string };

const Content: React.FC<ContentProps> = ({ className, children }) => {
  return (
    <div className={cn('py-4 px-6 font-inter text-primary-dark', className)}>
      {children}
    </div>
  );
};

type FooterProps = { className?: string };

const Footer: React.FC<FooterProps> = ({ className, children }) => {
  return (
    <div
      className={cn(
        'flex justify-center space-x-3 py-4 px-6 font-inter border-t border-grey-300',
        className,
      )}
    >
      {children}
    </div>
  );
};

export const Modal = _Modal as typeof _Modal & {
  Header: typeof Header;
  Footer: typeof Footer;
  Content: typeof Content;
};

Modal.Header = Header;
Modal.Footer = Footer;
Modal.Content = Content;
