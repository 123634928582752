import { faCalendar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { values } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import GeminiButton from '../components/Gemini/GeminiButton';

import IcaSimpleModal from '../components/Zen/Engineering/IcaSimpleModal';
import ZenControlledAsyncSelectInput from '../components/Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledCheckboxV2 from '../components/Zen/Input/ZenControlledCheckboxV2';
import ZenControlledDatePickerInput from '../components/Zen/Input/ZenControlledDatePickerInput';
import ZenControlledSelectInput from '../components/Zen/Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import { TemplatesSearchControllerApi } from '../openapi/signature-api';
import {
  AgentPlanControllerApi,
  BasicAgreementDefinitionCreationCommandAgreementTypeEnum,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { createAgreementDefinitions } from '../slices/AgentSlice';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import { AppDispatch, AsyncSelectOption, ISelectOption } from '../types';
import {
  getSignatureApiConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../utils/StringUtils';

interface AgreementDefinitionCreationFormData {
  name: string;
  requiredAt: string;
  agreementType: ISelectOption<BasicAgreementDefinitionCreationCommandAgreementTypeEnum>;
  description: string;
  commissionPlanId: ISelectOption;
  documentTemplateId: ISelectOption;
  forceSign: boolean;
}

interface ZenAddAgreementModalProps {
  isOpen: boolean;
  onClose(): void;
  getAgreementDefinitions(): void;
}

const ZenAddAgreementModal: React.FC<ZenAddAgreementModalProps> = ({
  getAgreementDefinitions,
  isOpen,
  onClose,
}) => {
  const [commissionPlans, setCommissionPlans] = useState<ISelectOption[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<AgreementDefinitionCreationFormData>();

  const getAgentDocumentTemplates = async (search: string, page: number) => {
    try {
      const { data } = await new TemplatesSearchControllerApi(
        getSignatureApiConfiguration(),
      ).searchTemplates(
        'DOCUMENT_TEMPLATE',
        'PUBLISHED',
        undefined,
        search,
        undefined,
        undefined,
        undefined,
        undefined,
        page,
        10,
        undefined,
        undefined,
        undefined,
      );

      const options: AsyncSelectOption[] =
        data.data?.map((resp) => ({
          value: `${resp.id}`,
          label: `${resp.name}`,
        })) ?? [];

      return options;
    } catch (e) {
      ErrorService.notify('Unable to search for agents document templates', e, {
        search: {
          term: search,
          target: 'Document Template',
          page,
        },
      });
      dispatch(
        showErrorToast(
          'An unexpected error occurred.',
          'We were unable to search for documents. Please try again in a few moments or contact support.',
        ),
      );
    }

    return [];
  };

  const agreementType = watch('agreementType');
  const isICA =
    agreementType?.value ===
    BasicAgreementDefinitionCreationCommandAgreementTypeEnum.Ica;

  useEffect(() => {
    if (commissionPlans.length === 0) {
      getAllCommissionPlansBasicInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data: AgreementDefinitionCreationFormData) => {
    console.log('Data', data);
    await dispatch(
      createAgreementDefinitions({
        req: {
          name: data.name,
          requiredAt: DateTime.fromISO(data.requiredAt).toMillis(),
          agreementType: data.agreementType.value,
          description: data.description,
          commissionPlanId: data.commissionPlanId.value,
          documentTemplateId: data.documentTemplateId.value,
          forceSign: data.forceSign,
        },
      }),
    );
    getAgreementDefinitions();
    onClose();
  };

  const getAllCommissionPlansBasicInfo = async (): Promise<void> => {
    try {
      const { data } = await new AgentPlanControllerApi(
        getYentaConfiguration(),
      ).getAllCommissionPlansBasicInfo();

      const options: ISelectOption[] =
        data?.commissionPlans?.map((resp) => ({
          value: `${resp.commissionPlanId}`,
          label: `${capitalizeEnum(resp.name!)}`,
        })) ?? [];

      setCommissionPlans(options);
    } catch (e) {
      ErrorService.notify('Unable to get for commission plans', e, {
        search: {
          target: 'CommissionPlanId',
        },
      });
      dispatch(
        showErrorToast(
          'An unexpected error occurred.',
          'We were unable to search for commission plans. Please try again in a few moments or contact support.',
        ),
      );
    }
  };

  const agreementTypeOptions = useMemo(
    () =>
      values(BasicAgreementDefinitionCreationCommandAgreementTypeEnum)
        .filter((c) =>
          [
            BasicAgreementDefinitionCreationCommandAgreementTypeEnum.Ica,
            BasicAgreementDefinitionCreationCommandAgreementTypeEnum.WalletDebit,
            BasicAgreementDefinitionCreationCommandAgreementTypeEnum.WalletCredit,
            BasicAgreementDefinitionCreationCommandAgreementTypeEnum.WalletLending,
          ].includes(c),
        )
        .map((c) => ({ value: c, label: capitalizeEnum(c) })),
    [],
  );
  return (
    <IcaSimpleModal
      size='xl'
      title='Create Agreement'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
        <div className='space-y-4 px-4 py-6'>
          <div className='flex space-x-4'>
            <ZenControlledTextInput<AgreementDefinitionCreationFormData, 'name'>
              name='name'
              control={control}
              label='Agreement Name'
              placeholder='Enter agreement name'
              isRequired
              rules={{ required: 'Agreement name is required' }}
            />

            <ZenControlledDatePickerInput<
              AgreementDefinitionCreationFormData,
              'requiredAt'
            >
              name='requiredAt'
              control={control}
              label='Required By'
              placeholder='Enter date'
              icon={
                <FontAwesomeIcon
                  icon={faCalendar}
                  className='text-rezen-blue-600'
                />
              }
              rules={{
                required: isICA ? 'Please select a date' : undefined,
              }}
              datePickerConfig={{
                minDate: DateTime.local().plus({ day: 1 }).toJSDate(),
              }}
              isRequired={isICA}
            />
          </div>
          <div>
            <ZenControlledSelectInput
              control={control}
              label='Agreement Type'
              name='agreementType'
              placeholder='Select agreement type'
              options={agreementTypeOptions}
              rules={{ required: 'Please select a type' }}
              isRequired
            />
          </div>
          <div>
            <ZenControlledTextInput<
              AgreementDefinitionCreationFormData,
              'description'
            >
              name='description'
              control={control}
              label='Description'
              placeholder='Enter Description'
            />
          </div>
          <div>
            <ZenControlledSelectInput<
              AgreementDefinitionCreationFormData,
              'commissionPlanId'
            >
              name='commissionPlanId'
              control={control}
              label='Select Commission Plan'
              placeholder='Select commission plan'
              options={commissionPlans}
              rules={{
                required: isICA ? 'Please choose commission plan' : undefined,
              }}
              isRequired={isICA}
            />
          </div>
          <div>
            <ZenControlledAsyncSelectInput<
              AgreementDefinitionCreationFormData,
              'documentTemplateId'
            >
              control={control}
              name='documentTemplateId'
              placeholder='Search document Template'
              label='Search Document Template'
              isRequired
              fetchData={getAgentDocumentTemplates}
              rules={{
                required: 'Document Template is required',
              }}
            />
          </div>
          <div className='mt-3'>
            <ZenControlledCheckboxV2<
              AgreementDefinitionCreationFormData,
              'forceSign'
            >
              name='forceSign'
              label='Force Sign'
              control={control}
              defaultValue={false}
              labelStyles='font-zen-body-2 text-base text-primary-dark'
            />
          </div>
        </div>

        <div className='space-x-4 py-[18px] flex justify-center border-t border-grey-300'>
          <div className='w-40'>
            <GeminiButton
              onClick={() => onClose()}
              variant='primary-outline'
              label='Cancel'
              isFullWidth
            />
          </div>
          <div className='w-40'>
            <GeminiButton
              type='submit'
              variant='primary'
              label='Create'
              isDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              isFullWidth
            />
          </div>
        </div>
      </form>
    </IcaSimpleModal>
  );
};

export default ZenAddAgreementModal;
