import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { faEnvelope, faGavel } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { groupBy, values } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useGemini } from '../../../hooks/useGemini';
import { AgentResponse, MlsPreviewResponse } from '../../../openapi/yenta';
import { AsyncResponse, EmailSupportType } from '../../../types';
import { getDefaultStateOrProvince } from '../../../utils/BoardUtils';
import { cn } from '../../../utils/classUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { openEmailClientForSupportEmail } from '../../../utils/SupportUtils';
import AdminOnly from '../../auth/AdminOnly';
import AuthorizationContainer from '../../auth/AuthorizationContainer';
import AuthorizedOnly from '../../auth/AuthorizedOnly';
import ExcludeAdmin from '../../auth/ExcludeAdmin';
import SelfOnly from '../../auth/SelfOnly';
import DefaultEmpty from '../../DefaultEmpty';
import ZenAction from '../../Zen/ZenAction';
import ZenCard from '../../Zen/ZenCard';
import ZenTabs from '../../Zen/ZenTabs';
import ManageMLSForm from './ManageMLSForm';

interface ZenMlsCardProps {
  agentResponse: AsyncResponse<AgentResponse>;
}

const ZenMlsCard: React.FC<ZenMlsCardProps> = ({ agentResponse }) => {
  const agent = agentResponse.data;
  const stateMls = groupBy(
    agent?.mls,
    'administrativeAreas[0].stateOrProvince',
  );
  const isGeminiEnabled = useGemini();

  const tabs = values(stateMls)
    ?.filter((mls) => !!mls?.[0]?.administrativeAreas?.[0]?.stateOrProvince)
    ?.map((mls: MlsPreviewResponse[]) => {
      return {
        name: capitalizeEnum(
          mls?.[0]?.administrativeAreas?.[0]?.stateOrProvince!,
        ),
        TabComponent: (
          <div className='flex flex-col space-y-2 p-3'>
            {mls.map((item) => {
              return (
                <p className='font-zen-body text-base' key={item.code}>
                  {item.name}
                </p>
              );
            })}
          </div>
        ),
      };
    });
  const tabName = tabs[0]?.name;
  const [mlsTab, setMlsTab] = useState<string>(tabName);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setMlsTab(tabName);
  }, [tabName]);

  return (
    <ZenCard
      title='MLS'
      RightComponent={
        <AuthorizedOnly asyncResponse={agentResponse}>
          <AdminOnly>
            <ZenAction
              text='Edit'
              onClick={() => setIsOpen(true)}
              icon={
                <FontAwesomeIcon
                  icon={faPen}
                  className={cn(
                    isGeminiEnabled
                      ? 'font-light text-[13px] leading-[23px] text-center text-rezen-blue-600'
                      : 'text-primary-blue text-sm',
                  )}
                />
              }
            />
          </AdminOnly>
          <ExcludeAdmin>
            <SelfOnly agentId={agent?.id}>
              <ZenAction
                icon={
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className='text-primary-blue'
                  />
                }
                text='Contact Support for Updates'
                onClick={() =>
                  openEmailClientForSupportEmail(
                    EmailSupportType.MY_MLS,
                    agent!,
                  )
                }
              />
            </SelfOnly>
          </ExcludeAdmin>
        </AuthorizedOnly>
      }
    >
      <AuthorizationContainer asyncResponse={agentResponse}>
        {!values(stateMls)?.length ? (
          <DefaultEmpty
            message="There are no MLS's to display."
            icon={
              <FontAwesomeIcon
                icon={faGavel}
                size='xl'
                className='text-grey-600 -rotate-90 mb-2'
              />
            }
          />
        ) : (
          <ZenTabs
            tabs={tabs}
            selected={mlsTab}
            onChange={setMlsTab}
            size='sm'
          />
        )}
        {isOpen && (
          <ManageMLSForm
            isOpen
            onClose={() => setIsOpen(false)}
            defaultStateOrProvince={getDefaultStateOrProvince(mlsTab)}
          />
        )}
      </AuthorizationContainer>
    </ZenCard>
  );
};

export default ZenMlsCard;
