import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface GeminiCircleProps {}

const GeminiCircle: React.FC<GeminiCircleProps> = () => {
  return (
    <FontAwesomeIcon icon={faCircleSmall} className='w-1 h-1 text-regent-600' />
  );
};

export default GeminiCircle;
