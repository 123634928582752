import { faArrowLeftLong } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer } from '@mantine/core';
import React from 'react';
import BrokerOnly from '../../auth/BrokerOnly';
import ExcludeBroker from '../../auth/ExcludeBroker';
import { applyImportant } from '../../commons/utils';
import CreateConversationSidebarAgentView from './CreateConversationSidebarAgentView';
import CreateConversationSidebarBrokerView from './CreateConversationSidebarBrokerView';

type CreateConversationSidebarProps = {
  opened: boolean;
  onClose(): void;
  onCreate(id: string): void;
};

const CreateConversationSidebar: React.FC<CreateConversationSidebarProps> = ({
  opened,
  onClose,
  onCreate,
}) => {
  return (
    <Drawer
      position='left'
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      size='350px'
      className='!overflow-hidden'
      classNames={applyImportant({
        body: 'p-0',
        content: 'overflow-hidden',
      })}
    >
      <div className='py-3 px-4.5 flex items-center space-x-4 text-primary-dark border border-grey-300 bg-[#F9F9F9]'>
        <button onClick={onClose}>
          <FontAwesomeIcon icon={faArrowLeftLong} fontSize={14} />
        </button>
        <p className='font-inter text-base'>New Message</p>
      </div>
      <div className='p-4 space-y-7'>
        <BrokerOnly>
          <CreateConversationSidebarBrokerView
            onClose={onClose}
            onCreate={onCreate}
          />
        </BrokerOnly>

        <ExcludeBroker>
          <CreateConversationSidebarAgentView
            onClose={onClose}
            onCreate={onCreate}
          />
        </ExcludeBroker>
      </div>
    </Drawer>
  );
};

export default CreateConversationSidebar;
