import {
  faArrowRotateRight,
  faBadgeCheck,
  faCalendar,
  faCheck,
  faClock,
  faEnvelopeCircleCheck,
  faEnvelopeOpen,
  faFile,
  faFileCircleCheck,
  faFileCircleExclamation,
  faFileCircleXmark,
  faHourglassStart,
  faLink,
  faSignature,
  faUserCheck,
  faUserXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TCInfo } from '../components/TransactionCoordinator/TCInfoSection';
import { TransactionCoordinatorAccessEnum } from '../types';
import { displayDate } from './DateUtils';

export type TCStatusVariant = 'filled' | 'noFill';

export enum TCAccessStatusEnum {
  ACTIVE = 'ACTIVE',
  ACCESS_STARTING = 'ACCESS_STARTING',
  ACCESS_REVOKED = 'ACCESS_REVOKED',
  ACCESS_ENDING = 'ACCESS_ENDING',
}

export enum TCInvitationStatusEnum {
  EMAILED = 'EMAILED',
  INVITATION_SENT = 'INVITATION_SENT',
  INVITATION_RESENT = 'INVITATION_RESENT',
  LINK_CLICKED = 'LINK_CLICKED',
  APPLICATION_STARTED = 'APPLICATION_STARTED',
  APPLICATION_SUBMITTED = 'APPLICATION_SUBMITTED',
  ACCOUNT_APPROVED = 'ACCOUNT_APPROVED',
  SIGN_ASSISTANT_AMENDMENT = 'SIGN_ASSISTANT_AMENDMENT',
  INVITATION_ACCEPTED = 'INVITATION_ACCEPTED',
  INVITATION_DECLINED = 'INVITATION_DECLINED',
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
  APPLICATION_REJECTED = 'APPLICATION_REJECTED',
}

export type TCAccessStatusPillInfoType = {
  icon: {
    filled?: JSX.Element;
    noFill: JSX.Element;
  };
  textClassName: {
    filled?: string;
    noFill: string;
  };
  bgClassName?: string;
};

export type TCInvitationStatusPillInfoType = {
  icon: JSX.Element;
  textClassName: string;
};

export const TCAccessStatusPillInfo: Record<
  TCAccessStatusEnum,
  TCAccessStatusPillInfoType
> = {
  ACTIVE: {
    icon: {
      filled: <FontAwesomeIcon icon={faCheck} />,
      noFill: <FontAwesomeIcon icon={faUserCheck} />,
    },
    textClassName: {
      filled: 'text-midnight-green-900',
      noFill: 'text-green-600',
    },
    bgClassName: 'bg-green-100',
  },
  ACCESS_STARTING: {
    icon: {
      noFill: <FontAwesomeIcon icon={faHourglassStart} />,
    },
    textClassName: {
      noFill: 'text-rezen-blue-600',
    },
    bgClassName: 'bg-rezen-light-blue-100',
  },
  ACCESS_REVOKED: {
    icon: {
      noFill: <FontAwesomeIcon icon={faFileCircleExclamation} />,
    },
    textClassName: {
      noFill: 'text-coral-red',
    },
    bgClassName: 'bg-red-100',
  },
  ACCESS_ENDING: {
    icon: {
      noFill: <FontAwesomeIcon icon={faCalendar} />,
    },
    textClassName: {
      noFill: 'text-orange-600',
    },
    bgClassName: 'bg-orange-100',
  },
};

export const TCInvitationStatusPillInfo: Record<
  TCInvitationStatusEnum,
  TCInvitationStatusPillInfoType
> = {
  [TCInvitationStatusEnum.EMAILED]: {
    icon: <FontAwesomeIcon icon={faEnvelopeOpen} />,
    textClassName: 'text-green-600',
  },
  [TCInvitationStatusEnum.INVITATION_SENT]: {
    icon: <FontAwesomeIcon icon={faEnvelopeCircleCheck} />,
    textClassName: 'text-green-600',
  },
  [TCInvitationStatusEnum.INVITATION_RESENT]: {
    icon: <FontAwesomeIcon icon={faArrowRotateRight} />,
    textClassName: 'text-rezen-blue-600',
  },
  [TCInvitationStatusEnum.LINK_CLICKED]: {
    icon: <FontAwesomeIcon icon={faLink} />,
    textClassName: 'text-orange-600',
  },
  [TCInvitationStatusEnum.APPLICATION_STARTED]: {
    icon: <FontAwesomeIcon icon={faFile} />,
    textClassName: 'text-yellow-600',
  },
  [TCInvitationStatusEnum.APPLICATION_SUBMITTED]: {
    icon: <FontAwesomeIcon icon={faFileCircleCheck} />,
    textClassName: 'text-yellow-600',
  },
  [TCInvitationStatusEnum.ACCOUNT_APPROVED]: {
    icon: <FontAwesomeIcon icon={faBadgeCheck} />,
    textClassName: 'text-green-600',
  },
  [TCInvitationStatusEnum.SIGN_ASSISTANT_AMENDMENT]: {
    icon: <FontAwesomeIcon icon={faSignature} />,
    textClassName: 'text-yellow-600',
  },
  [TCInvitationStatusEnum.INVITATION_ACCEPTED]: {
    icon: <FontAwesomeIcon icon={faUserCheck} />,
    textClassName: 'text-green-600',
  },
  [TCInvitationStatusEnum.INVITATION_DECLINED]: {
    icon: <FontAwesomeIcon icon={faUserXmark} />,
    textClassName: 'text-coral-red',
  },
  [TCInvitationStatusEnum.INVITATION_EXPIRED]: {
    icon: <FontAwesomeIcon icon={faClock} />,
    textClassName: 'text-coral-red',
  },
  [TCInvitationStatusEnum.APPLICATION_REJECTED]: {
    icon: <FontAwesomeIcon icon={faFileCircleXmark} />,
    textClassName: 'text-coral-red',
  },
};

export const getFormatedTCAccessName = ({
  accessType,
  date,
}: {
  accessType: TransactionCoordinatorAccessEnum;
  date: string;
}) => {
  switch (accessType) {
    case TransactionCoordinatorAccessEnum.CREATED_AFTER:
      if (!date) return 'Transactions Created After';
      return `Transactions Created After ${displayDate(
        new Date(date).toISOString(),
      )}`;
    case TransactionCoordinatorAccessEnum.ALL_OPEN_LISTINGS_AND_TRANSACTIONS:
      return 'All Open Listings / Transactions';
    case TransactionCoordinatorAccessEnum.CREATED_BY_THEM_OR_MANUALLY_ADDED:
      return 'Created by Them or Manually Added';
  }
};

export enum TCActivityLogEnum {
  INVITATION_SENT = 'INVITATION_SENT',
  INVITATION_REVOKED = 'INVITATION_REVOKED',
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
  INVITATION_ACCEPTED = 'INVITATION_ACCEPTED',
  INVITATION_REJECTED = 'INVITATION_REJECTED',
  TRANSACTION_ACCESS_UPDATED = 'TRANSACTION_ACCESS_UPDATED',
  TRANSACTION_COORDINATOR_REMOVED = 'TRANSACTION_COORDINATOR_REMOVED',
  SIGN_ASSISTANT_AMENDMENT = 'SIGN_ASSISTANT_AMENDMENT',
}

export interface TCActivityLogResponse {
  id?: string;
  tcInfo?: TCInfo;
  activityType?: TCActivityLogEnum;
  afterDate?: string;
  createdAt?: string;
}

export const getActivityTypeInfo = (_row: TCActivityLogResponse) => {
  //TODO: UPDATE THIS USING FONT AWESOME ICONS AND REMOVE FA CLASS USAGES
  // switch (row.activityType) {
  //   case TCActivityLogEnum.INVITATION_SENT:
  //     return {
  //       icon: (
  //         <i className='fa-kit fa-regular-envelope-circle-arrow-right fa-2xs' />
  //       ),
  //       message: `Invitation sent to ${row.tcInfo?.name}`,
  //     };
  //   case TCActivityLogEnum.INVITATION_REVOKED:
  //     return {
  //       icon: <i className='fa-kit fa-regular-envelope-circle-xmark fa-2xs' />,
  //       message: `Invitation to ${row.tcInfo?.name} revoked`,
  //     };

  //   case TCActivityLogEnum.INVITATION_EXPIRED:
  //     return {
  //       icon: <i className='fa-kit fa-regular-envelope-clock fa-2xs' />,
  //       message: `Invitation to ${row.tcInfo?.name} expired`,
  //     };
  //   case TCActivityLogEnum.INVITATION_ACCEPTED:
  //     return {
  //       icon: <i className='fa-kit fa-regular-user-circle-check fa-2xs' />,
  //       message: `${row.tcInfo?.name} accepted your invitation`,
  //     };
  //   case TCActivityLogEnum.INVITATION_REJECTED:
  //     return {
  //       icon: <i className='fa-kit fa-regular-user-circle-xmark fa-2xs' />,
  //       message: `${row.tcInfo?.name} rejected your invitation`,
  //     };
  //   case TCActivityLogEnum.TRANSACTION_ACCESS_UPDATED:
  //     return {
  //       icon: <i className='fa-kit fa-regular-clipboard-circle-check fa-2xs' />,
  //       message: `${
  //         row.tcInfo?.name
  //       }  transaction access changed from 'all transactions and listings' to 'transactions created after ${displayDate(
  //         row.afterDate!,
  //       )}'`,
  //     };
  //   case TCActivityLogEnum.TRANSACTION_COORDINATOR_REMOVED:
  //     return {
  //       icon: <i className='fa-kit fa-regular-user-circle-minus fa-2xs' />,
  //       message: `${
  //         row.tcInfo?.name
  //       } will not be added to transactions after ${displayDate(
  //         row.afterDate!,
  //       )}`,
  //     };
  //   case TCActivityLogEnum.SIGN_ASSISTANT_AMENDMENT:
  //     return {
  //       icon: <i className='fa-regular fa-signature fa-2xs' />,
  //       message: 'You signed the Assistant Amendment',
  //     };
  //   default:
  //     return '';
  // }
  return {
    icon: <FontAwesomeIcon icon={faCheck} />,
    message: 'Invitation sent to Jacob Jones',
  };
};

export const TransactionCoordinators: TCInfo[] = [
  {
    id: '1',
    name: 'Jacob Jones',
    email: 'tomcook@therealbrokerage.com',
    avatar:
      'avatar/c6aa7000-a9d9-4e3d-b9a2-cd143fb8dc37_b5808041-8253-4bf1-9847-f1821c2d2cd2.png',
    teamName: 'Team 1',
    invitationStatus: TCInvitationStatusEnum.INVITATION_SENT,
    status: TCAccessStatusEnum.ACTIVE,
    removedOn: '2024-05-24T00:00:00Z',
  },
  {
    id: '2',
    name: 'Guy Hawkins',
    email: 'hawkins@therealbrokerage.com',
    avatar:
      'avatar/8fc5f855-1286-420d-8b93-f60d20068c5a_2f48fa8a-e39d-4289-813a-40a06d976d36.png',
    invitationStatus: TCInvitationStatusEnum.SIGN_ASSISTANT_AMENDMENT,
    status: TCAccessStatusEnum.ACCESS_STARTING,
    removedOn: '2024-05-24T00:00:00Z',
  },
  {
    id: '3',
    name: 'Wade Cooper',
    email: 'wadecooper@therealbrokerage.com',
    avatar:
      'avatar/e8d137f1-2fb6-4122-a05d-1b5f00caf520_a6393f91-eae2-4dd0-86a8-8da56d684707.png',
    invitationStatus: TCInvitationStatusEnum.ACCOUNT_APPROVED,
    status: TCAccessStatusEnum.ACCESS_ENDING,
    removedOn: '2024-05-24T00:00:00Z',
  },
  {
    id: '4',
    name: 'Dianne Russell',
    email: 'Dianneussell@therealbrokerage.com',
    avatar:
      'avatar/c6aa7000-a9d9-4e3d-b9a2-cd143fb8dc37_b5808041-8253-4bf1-9847-f1821c2d2cd2.png',
    teamName: 'Play Wrights',
    invitationStatus: TCInvitationStatusEnum.INVITATION_SENT,
    status: TCAccessStatusEnum.ACCESS_REVOKED,
    removedOn: '2024-08-29T07:24:28.614552',
  },
];
