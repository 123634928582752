import { faMessage, faPhone } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDisclosure } from '@mantine/hooks';
import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { MemberIdTypeEnum } from '../../../openapi/yada';
import { AgentResponse } from '../../../openapi/yenta';
import { useFetchMultipleOfficeVisibleGroupsByIds } from '../../../query/office/useOffice';
import { useCreateConversation } from '../../../query/roar/useRoar';
import { RootState } from '../../../types';
import { getConversationLink } from '../../../utils/ConversationUtils';
import { isBrokerTeam } from '../../../utils/OfficeUtils';
import CallModalStepper from '../../Conversation/CallModalStepper';
import ConversationSelectOfficeModal from '../../Menu/BrokerQueue/ConversationSelectOfficeModal';

type CallAgentCellProps = {
  agentDetail: AgentResponse;
};

const CallAgentCell: React.FC<CallAgentCellProps> = ({ agentDetail }) => {
  const [callModalOpened, callModalActions] = useDisclosure();
  const [officeModalOpened, officeModalActions] = useDisclosure();
  const [openConversation, setOpenConversation] = useState(false);
  const officeIdRef = useRef<string>('');

  const userDetails = useSelector((state: RootState) => state.auth.userDetail);

  const { mutate, isLoading } = useCreateConversation();

  const officeIds = useMemo(
    () => userDetails?.offices?.map((office) => office.id!) ?? [],
    [userDetails?.offices],
  );

  const {
    isLoading: isLoadingOfficeGroups,
    data: officeGroups,
  } = useFetchMultipleOfficeVisibleGroupsByIds(officeIds);

  const groupId = officeGroups[officeIdRef.current]?.groups?.find(isBrokerTeam)
    ?.id;

  const handleGetConversation = (cb: (id: string) => void) => {
    const _groupId = officeGroups?.[officeIdRef.current]?.groups?.find(
      isBrokerTeam,
    )?.id;

    mutate(
      {
        members: [
          {
            value: agentDetail?.id!,
            type: MemberIdTypeEnum.User,
          },
          {
            value: _groupId!,
            type: MemberIdTypeEnum.Group,
          },
        ],
      },
      {
        onSuccess: (data) => {
          cb(data.id);
        },
      },
    );
  };

  return (
    <>
      <div className='flex items-center space-x-2'>
        <button
          className='w-9 h-9 flex items-center justify-center bg-regent-300 rounded-full text-primary-dark disabled:opacity-50'
          disabled={isLoading || isLoadingOfficeGroups}
          onClick={() => {
            setOpenConversation(false);
            officeModalActions.open();
          }}
        >
          <FontAwesomeIcon icon={faPhone} fontSize={14} />
        </button>
        <button
          className='w-9 h-9 flex items-center justify-center bg-regent-300 rounded-full text-primary-dark disabled:opacity-50'
          disabled={isLoading || isLoadingOfficeGroups}
          onClick={() => {
            setOpenConversation(true);
            officeModalActions.open();
          }}
        >
          <FontAwesomeIcon icon={faMessage} fontSize={14} />
        </button>
      </div>
      <ConversationSelectOfficeModal
        opened={officeModalOpened}
        onClose={officeModalActions.close}
        offices={userDetails?.offices}
        onChange={(id) => {
          officeIdRef.current = id;
        }}
        isLoading={isLoading || isLoadingOfficeGroups}
        onConfirm={() => {
          if (openConversation) {
            handleGetConversation((id) => {
              window.open(getConversationLink(id), '_blank');
            });
          } else {
            callModalActions.open();
          }
          officeModalActions.close();
        }}
      />
      <CallModalStepper
        opened={callModalOpened}
        onClose={callModalActions.close}
        groupId={groupId!}
        callAgent
        agentInfo={agentDetail}
        officeIds={officeIds}
        isFetchingConversation={isLoading}
        getConversation={handleGetConversation}
      />
    </>
  );
};

export default CallAgentCell;
