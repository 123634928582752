import { faPercent, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Control } from 'react-hook-form-v7';
import { PERCENT_VALIDATION } from '../../../utils/Validations';
import ZenControlledNumberInput from '../../Zen/Input/ZenControlledNumberInput';
import ZenControlledPercentageInput from '../../Zen/Input/ZenControlledPercentageInput';
import { GoalsFormInput } from './SetGoalsSideBarV2';

interface RevenueShareIncomeTierTabComponentProps {
  control: Control<GoalsFormInput, object>;
  activeIndex: number;
  tier: number;
}

const RevenueShareIncomeTierTabComponent: React.FC<RevenueShareIncomeTierTabComponentProps> = ({
  control,
  activeIndex,
  tier,
}) => {
  return (
    <div className='flex flex-col space-y-4 py-4'>
      <div className='flex flex-row justify-between items-center'>
        <div className='flex flex-grow flex-shrink'>
          <p className='font-zen-body font-medium text-base text-zen-dark-8'>{`Current count of Tier ${tier} agents in your network`}</p>
        </div>
        <div className='w-1/5'>
          <ZenControlledNumberInput<
            GoalsFormInput,
            `agentCountsInTiers.${number}.currentNetworkCount`
          >
            control={control}
            name={`agentCountsInTiers.${activeIndex}.currentNetworkCount`}
            allowDecimals={false}
            placeholder={`Enter current agent count in tier ${tier}`}
            shouldUnregister={false}
            rules={{
              required: 'Please enter current agent count',
              min: {
                value: 0,
                message: 'Number cannot be less than 0',
              },
            }}
            startAdornment={
              <div className='flex items-center justify-center w-8 h-full'>
                <FontAwesomeIcon
                  icon={faUsers}
                  className='text-sm text-primary-blue'
                />
              </div>
            }
          />
        </div>
      </div>

      <div className='border-b border-zen-dark-4' />

      <div className='flex flex-row justify-between items-center'>
        <div className='flex flex-grow flex-shrink'>
          <p className='font-zen-body font-medium text-base text-zen-dark-8'>{`Annual number of new agents joining Tier ${tier}`}</p>
        </div>
        <div className='w-1/5'>
          <ZenControlledNumberInput<
            GoalsFormInput,
            `agentCountsInTiers.${number}.yearlyJoiningAgentCount`
          >
            control={control}
            name={`agentCountsInTiers.${activeIndex}.yearlyJoiningAgentCount`}
            allowDecimals={false}
            placeholder={`Enter yearly joining agent count in tier ${tier}`}
            rules={{
              required:
                'Please enter annual number of new agents joining count',
              min: {
                value: 0,
                message: 'Number cannot be less than 0',
              },
            }}
            shouldUnregister={false}
            startAdornment={
              <div className='flex items-center justify-center w-8 h-full'>
                <FontAwesomeIcon
                  icon={faUsers}
                  className='text-sm text-primary-blue'
                />
              </div>
            }
          />
        </div>
      </div>

      <div className='flex flex-row justify-between items-center bg-regent-200 border rounded-md border-grey-200 p-[18px] !mt-10'>
        <div className='flex flex-grow flex-shrink'>
          <p className='font-zen-body font-medium text-base text-zen-dark-8'>
            Expected annual capping percentage of your network
          </p>
        </div>
        <div className='w-1/5 bg-white'>
          <ZenControlledPercentageInput<
            GoalsFormInput,
            `percentageOfCappingNetworkAgents`
          >
            control={control}
            name='percentageOfCappingNetworkAgents'
            placeholder='Percentage'
            shouldUnregister={false}
            rules={{
              required: 'Please enter capping percentage',
              min: {
                value: 0,
                message: 'Number cannot be less than 0',
              },
              max: {
                value: 100,
                message: 'Number cannot be greater than 100',
              },
              ...PERCENT_VALIDATION,
            }}
            startAdornment={
              <div className='flex items-center justify-center w-8 h-full'>
                <FontAwesomeIcon
                  icon={faPercent}
                  className='text-sm text-primary-blue'
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default RevenueShareIncomeTierTabComponent;
