import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { ImageContent } from '../../../../types';
import { cn } from '../../../../utils/classUtils';
import DefaultLoader from '../../../DefaultLoader';

interface ZenRenderPDFItemProps {
  pageIndex: number;
  pdfImages: ImageContent[];
  width: number;
  selectedPages?: ImageContent[];
  setSelectedPages?(pages: ImageContent[]): void;
}

const ZenRenderPDFItem: React.FC<ZenRenderPDFItemProps> = ({
  pageIndex,
  pdfImages,
  width,
  selectedPages,
  setSelectedPages,
}) => {
  const [hoverImage, setHoverImage] = useState<string | undefined>();
  const [loadingImage, setLoadingImage] = useState<boolean>(false);

  const handlePageSelect = (image: ImageContent) => {
    setSelectedPages?.(
      [...(selectedPages || []), image].sort(
        (a, b) => a.pageNumber - b.pageNumber,
      ),
    );
  };

  const handlePageDeselect = (image: ImageContent) => {
    setSelectedPages?.(
      (selectedPages || []).filter(
        (index) => index.pageNumber !== image.pageNumber,
      ),
    );
  };

  const isSelected = !!selectedPages?.find(
    (item) => item.pageNumber === pageIndex + 1,
  );

  return (
    <div className='flex flex-col items-center justify-center'>
      <div
        key={pageIndex}
        aria-label={`page-${pageIndex}`}
        className={cn('relative border rounded p-1 mb-1', {
          'cursor-pointer': !!setSelectedPages,
        })}
        onClick={() => {
          if (isSelected) {
            handlePageDeselect(pdfImages[pageIndex]);
          } else {
            handlePageSelect(pdfImages[pageIndex]);
          }
        }}
      >
        <img
          src={pdfImages[pageIndex].path}
          onMouseEnter={() => {
            if (!!setSelectedPages) {
              setLoadingImage(true);
              setHoverImage(pdfImages[pageIndex].path);
            }
          }}
          onMouseLeave={() => {
            if (!!setSelectedPages) {
              setLoadingImage(false);
              setHoverImage(undefined);
            }
          }}
          alt='split-pdf-thumbnail'
          width={width}
        />
        {!!setSelectedPages && (
          <>
            <div className='flex items-center justify-center text-xs'>
              {pageIndex + 1}
            </div>
            {isSelected && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                aria-label='checked'
                className='text-xl text-success absolute right-0 top-0'
              />
            )}
          </>
        )}
      </div>

      {hoverImage && (
        <div className='fixed top-0 left-[144px] bottom-0 right-0 z-50 w-full h-full-window'>
          <div className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40' />
          <div className='flex items-center justify-center w-full h-full'>
            <div className='w-[40vw] h-[98vh] z-10 mx-auto bg-white'>
              {loadingImage && <DefaultLoader iconSize='default' noPadding />}
              <img
                src={hoverImage}
                alt='split-pdf-preview'
                className='w-full h-full object-contain'
                onLoad={() => setLoadingImage(false)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ZenRenderPDFItem;
