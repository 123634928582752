import { isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import WalletLandingPage from '../components/Wallet/WalletLandingPage';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenResourceContainer from '../components/Zen/ZenResourceContainer';
import ZenRoute from '../components/Zen/ZenRoute';
import {
  BankingPartnershipDtoPartnerEnum,
  RealLendingAccountLiteDtoTypeEnum,
} from '../openapi/wallet';
import {
  useFetchBankingPartnership,
  useWallet,
} from '../query/wallet/useWallet';

interface WalletRouteProps {}

const WalletRoute: React.FC<WalletRouteProps> = () => {
  const history = useHistory();

  const {
    data: wallet,
    isLoading: isWalletLoading,
    errorCode: walletErrorCode,
  } = useWallet();

  const {
    data: partnerships,
    isLoading: isPartnershipLoading,
    errorCode: partnershipErrorCode,
  } = useFetchBankingPartnership(!isEmpty(wallet?.bankingPartnerships));

  const isPartneredWithUnit = useMemo(() => {
    return partnerships?.bankingPartnerships?.some(
      (banks) =>
        banks.partner === BankingPartnershipDtoPartnerEnum.Unit &&
        banks.accounts?.some((account) => !isEmpty(account.cards)),
    );
  }, [partnerships]);

  const hasLendingAccount = useMemo(() => {
    return wallet?.realLendingAccounts?.some(
      (acc) => acc.type === RealLendingAccountLiteDtoTypeEnum.RealLending,
    );
  }, [wallet?.realLendingAccounts]);

  useEffect(() => {
    if (isPartneredWithUnit || hasLendingAccount) {
      history.push('/wallet/dashboard');
    }
  }, [history, isPartneredWithUnit, hasLendingAccount]);

  // use case - render landing page when user doesn't have any partnerships
  // and show valid loading state when user has a partnership
  const bankingPartnershipLoading = !!(
    !isEmpty(wallet?.bankingPartnerships) && isPartnershipLoading
  );

  return (
    <ZenRoute title='Wallet'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          {
            title: 'Real Wallet',
            url: '/wallet',
          },
        ]}
        textClassNames='font-inter'
      >
        <ZenResourceContainer
          loading={isWalletLoading || bankingPartnershipLoading}
          isEmpty={!!isPartneredWithUnit}
          resourceName='wallet'
          errorCode={walletErrorCode || partnershipErrorCode}
        >
          <WalletLandingPage />
        </ZenResourceContainer>
      </ZenPageLayout>
    </ZenRoute>
  );
};

export default WalletRoute;
