import {
  faEnvelope,
  faLocationDot,
  faPhone as faPhoneLight,
} from '@fortawesome/pro-light-svg-icons';
import { faEye, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDisclosure } from '@mantine/hooks';
import { flatten, isEmpty, values } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFetchMultipleOfficeVisibleGroupsByIds } from '../../query/office/useOffice';
import { RootState } from '../../types';
import { getFullName } from '../../utils/AgentHelper';
import { capitalizeEnum, formatPhoneNumber } from '../../utils/StringUtils';
import { Button } from '../commons/Button';
import ResourceContainer from '../ResourceContainer';
import StateIcon from '../StateIcon';
import ZenAvatar from '../Zen/ZenAvatar';
import CallModalStepper from './CallModalStepper';

type ConversationGroupDetailSectionProps = {
  conversationId: string;
  groupId: string;
};

const ConversationGroupDetailSection: React.FC<ConversationGroupDetailSectionProps> = ({
  conversationId,
  groupId,
}) => {
  const [opened, modalActions] = useDisclosure();

  const offices = useSelector(
    (state: RootState) => state.auth.userDetail?.offices,
  );

  const officeIds = useMemo(() => offices?.map((office) => office.id!) ?? [], [
    offices,
  ]);

  const { isLoading, data } = useFetchMultipleOfficeVisibleGroupsByIds(
    officeIds,
  );

  const groups = useMemo(() => {
    return flatten(
      values(data ?? {}).map((el) =>
        el.groups?.map((g) => ({ ...g, officeId: el.officeId })),
      ),
    );
  }, [data]);

  const currentGroup = useMemo(
    () => groups.find((group) => group?.id === groupId),
    [groupId, groups],
  );

  const currentOffice = useMemo(
    () => offices?.find((office) => office.id === currentGroup?.officeId),
    [currentGroup, offices],
  );

  return (
    <ResourceContainer
      loading={isLoading}
      isEmpty={false}
      resourceName='office'
    >
      <div className='p-4 pt-6'>
        <div className='flex items-center justify-center'>
          <StateIcon
            state={currentOffice?.administrativeArea?.stateOrProvince!}
            width={254}
            variant='rounded'
            className='rounded-lg'
          />
        </div>
        <div className='mt-4 space-y-1'>
          <p className='mt-4 font-poppins font-medium text-primary-dark text-xl'>
            {currentOffice?.name}
          </p>
          <p className='font-inter font-medium text-sm text-regent-600'>
            {currentGroup?.groupMembers?.length ?? 0} Brokers
          </p>
        </div>
        <div className='mt-4 flex items-center space-x-2'>
          <div className='flex-1'>
            <Button
              variant='outline'
              color='grey'
              fullWidth
              className='border-regent-400 !border !text-primary-dark'
              leftIcon={
                <FontAwesomeIcon
                  icon={faPhone}
                  fontSize={12}
                  className='mr-0.5'
                />
              }
              onClick={modalActions.open}
            >
              Call
            </Button>
          </div>
          <Link
            to={`/offices/${currentOffice?.id}`}
            target='_blank'
            className='flex-1'
          >
            <Button
              variant='outline'
              color='grey'
              fullWidth
              className='border-regent-400 !border !text-primary-dark'
              leftIcon={
                <FontAwesomeIcon
                  icon={faEye}
                  fontSize={12}
                  className='mr-0.5'
                />
              }
            >
              Office
            </Button>
          </Link>
        </div>
      </div>

      <div className='border-t border-grey-300 p-4 pb-6'>
        <p className='font-inter font-semibold text-primary-dark text-sm'>
          Contact Information
        </p>
        <div className='mt-3 space-y-3'>
          <div className='flex items-start space-x-2 font-inter text-sm text-primary-dark font-light'>
            <FontAwesomeIcon
              icon={faPhoneLight}
              fontSize={14}
              className='mt-1'
            />
            <p>{formatPhoneNumber(currentOffice?.phoneNumber) ?? 'N/A'}</p>
          </div>
          <div className='flex items-start space-x-2 font-inter text-sm text-primary-dark font-light'>
            <FontAwesomeIcon icon={faEnvelope} fontSize={14} className='mt-1' />
            <p>{currentOffice?.emailAddress ?? 'N/A'}</p>
          </div>
          <div className='flex items-start space-x-2 font-inter text-sm text-primary-dark font-light'>
            <FontAwesomeIcon
              icon={faLocationDot}
              fontSize={14}
              className='mt-1'
            />
            <p>{currentOffice?.address?.oneLine ?? 'N/A'}</p>
          </div>
        </div>
      </div>

      <div className='border-t border-grey-300 p-5'>
        <p className='font-inter font-semibold text-primary-dark text-sm'>
          Members
        </p>
        <ResourceContainer
          loading={false}
          isEmpty={isEmpty(currentGroup?.groupMembers)}
          resourceName='member'
        >
          <div className='mt-1.5 space-y-3'>
            {currentGroup?.groupMembers?.map((member) => {
              const user = member.user;

              return (
                <div key={user?.id} className='flex items-center space-x-2'>
                  <ZenAvatar
                    name={user?.fullName ?? getFullName(user)!}
                    avatar={user?.avatar}
                    size='md'
                  />
                  <div className='flex-1'>
                    <p className='text-primary-dark font-inter line-clamp-1'>
                      {user?.fullName ?? 'N/A'}
                    </p>
                    <p className='text-regent-600 font-inter text-sm line-clamp-1'>
                      {capitalizeEnum(member.role!) ?? 'N/A'}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </ResourceContainer>
      </div>
      <CallModalStepper
        opened={opened}
        onClose={modalActions.close}
        groupId={groupId}
        officeState={currentOffice?.administrativeArea?.stateOrProvince!}
        isFetchingConversation={false}
        getConversation={(cb) => cb(conversationId)}
      />
    </ResourceContainer>
  );
};

export default ConversationGroupDetailSection;
