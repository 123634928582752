import { faArrowRightLong, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TransactionCoordinators } from '../../utils/TransactionCoordinatorUtils';
import ZenAction from '../Zen/ZenAction';
import ZenAvatar from '../Zen/ZenAvatar';
import ZenCard from '../Zen/ZenCard';
import { Button } from '../commons/Button';
import InviteTCSidebar from './InviteTCSidebar';
import ManageTCSidebar from './ManageTCSidebar';
import TCAccessStatusPill from './TCAccessStatusPill';

type AgentTCsCardProps = {};

const AgentTCsCard: React.FC<AgentTCsCardProps> = () => {
  const [openInviteTC, setOpenInviteTC] = React.useState(false);
  const [openManageTC, setOpenManageTC] = React.useState(false);

  const handleInviteTC = () => {
    setOpenInviteTC(true);
    setOpenManageTC(false);
  };

  return (
    <ZenCard
      title={`Transaction Coordinators (${TransactionCoordinators.length})`}
      RightComponent={
        <ZenAction
          text='Invite'
          icon={
            <FontAwesomeIcon icon={faPlus} className='text-rezen-blue-600' />
          }
          onClick={() => setOpenInviteTC(true)}
        />
      }
      BottomComponent={
        <div className='flex flex-col justify-end flex-grow'>
          <div className='py-2 px-3 w-full bg-white border-t border-regent-300 rounded-b-lg'>
            <Button
              rightIcon={
                <FontAwesomeIcon
                  icon={faArrowRightLong}
                  className='text-primary-blue'
                />
              }
              variant='link'
              onClick={() => setOpenManageTC(true)}
            >
              Manage
            </Button>
          </div>
        </div>
      }
    >
      <div className='rounded-b-lg font-inter overflow-y-scroll h-[348px] scrollbar-hide'>
        {TransactionCoordinators.map((tc) => (
          <div
            className='flex justify-between items-center py-3 px-4 border-b border-grey-200 '
            key={tc.id}
          >
            <div className='bg-rezen-blue-100 py-1.5 px-2 rounded-full flex gap-1 items-center'>
              <ZenAvatar imageUrl={tc.avatar} name={tc.name} size='xs' />
              <p className='line-clamp-1 text-primary-dark font-inter text-xs sm:text-sm font-normal'>
                {tc.name}
              </p>
            </div>
            <div>
              <TCAccessStatusPill status={tc.status!} />
            </div>
          </div>
        ))}
      </div>
      {openInviteTC && (
        <InviteTCSidebar
          isOpen={openInviteTC}
          onClose={() => setOpenInviteTC(false)}
        />
      )}
      {openManageTC && (
        <ManageTCSidebar
          isOpen={openManageTC}
          onClose={() => setOpenManageTC(false)}
          handleInviteClick={handleInviteTC}
        />
      )}
    </ZenCard>
  );
};

export default AgentTCsCard;
