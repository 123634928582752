import { useMemo, useState } from 'react';
import { Control, FieldArrayWithId } from 'react-hook-form-v7';
import GeminiTabsV2, {
  GeminiTabsV2Tab,
} from '../../../Gemini/Tabs/GeminiTabsV2';
import RevenueShareIncomeTierTabComponent from '../RevenueShareIncomeTierTabComponent';
import { GoalsFormInput } from '../SetGoalsSideBarV2';

interface RevenueShareIncomeTierFormProps {
  tabs: FieldArrayWithId<GoalsFormInput, 'agentCountsInTiers', 'id'>[];
  title?: string;
  control: Control<GoalsFormInput, object>;
}

const RevenueShareIncomeTierForm: React.FC<RevenueShareIncomeTierFormProps> = ({
  tabs,
  title,
  control,
}) => {
  const [activeTab, setActiveTab] = useState<string>('Tier 1');

  const TierTabs = useMemo(() => {
    const updatedTabs: GeminiTabsV2Tab[] = tabs?.map((_, index) => ({
      name: `Tier ${index + 1}`,
      TabComponent: (
        <RevenueShareIncomeTierTabComponent
          control={control}
          tier={index + 1}
          activeIndex={index}
        />
      ),
    }));

    return updatedTabs;
  }, [control, tabs]);

  return (
    <div className='mt-8'>
      {title && (
        <div className='font-zen-body font-normal text-lg text-zen-dark-9'>
          {title}
        </div>
      )}

      <GeminiTabsV2
        tabs={TierTabs}
        onChange={(name) => setActiveTab(name)}
        selected={activeTab}
      />
    </div>
  );
};

export default RevenueShareIncomeTierForm;
