import React from 'react';
import LeoOrb from '../../../assets/img/leo/leoOrbAnimated.gif';
import { cn } from '../../../utils/classUtils';
import EllipseSvg from '../../../assets/img/roar/ellipse.svg';

type OrbSize = 'sm' | 'md';

export interface LeoAnimatedOrbProps {
  size?: OrbSize;
}

const LeoAnimatedOrb: React.FC<LeoAnimatedOrbProps> = ({ size = 'md' }) => {
  const backgroundSizeToClassNameMap: { [type in OrbSize]: string } = {
    sm: 'h-5 w-5',
    md: 'h-8 w-8',
  };

  const orbSizeToClassNameMap: { [type in OrbSize]: string } = {
    sm: 'h-3 w-3',
    md: 'h-5 w-5',
  };

  return (
    <div className='relative'>
      <img
        src={EllipseSvg}
        alt='ellipse'
        className={cn(backgroundSizeToClassNameMap[size])}
      />
      <div
        className={cn(
          'absolute mt-px top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ',
          orbSizeToClassNameMap[size],
        )}
      >
        <img src={LeoOrb} alt='circle-plexus' className='w-full h-auto' />
      </div>
    </div>
  );
};

export default LeoAnimatedOrb;
