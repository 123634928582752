import { faSparkles, faXmark } from '@fortawesome/pro-light-svg-icons';
import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsService from '../../../services/AnalyticsService';
import { chatReply, saveShowIntro } from '../../../slices/LeoSlice';
import { AnalyticsEventEnum, RootState } from '../../../types';
import { ContextualPrompt } from '../../../utils/LeoUtils';
import { ChatFormData } from '../../Zen/Leo/Chat/LeoChatIndex';
import useNeoLeoKBarActions from '../Hooks/useNeoLeoKBarActions';
import NeoLeoKBarActions from '../NeoLeoKBarActions';
import NeoLeoControlledInput from './NeoLeoControlledInput';

interface NeoLeoChatInputProps {
  setShowPrompts?(value: React.SetStateAction<boolean>): void;
  showPrompts?: boolean;
  isIntro?: boolean;
  askLeoText?: string;
}

const NeoLeoChatInput: React.FC<NeoLeoChatInputProps> = ({
  setShowPrompts,
  showPrompts,
  isIntro,
  askLeoText,
}) => {
  const dispatch = useDispatch();
  const { control, reset, setFocus } = useFormContext<ChatFormData>();
  const {
    leo: { session, isLeoThinking, currentChat, selectedItems, msgContext },
  } = useSelector((state: RootState) => state);
  const isDisabled = !session?.id || isLeoThinking;
  const actions = useNeoLeoKBarActions(askLeoText);

  const preSendChatReply = () => {
    reset({ askLeoText: '' });
    dispatch(saveShowIntro(false));
  };

  const sendChatReply = async (prompt: ContextualPrompt) => {
    if (!session?.id) {
      return;
    }
    const updateSessionTitle =
      currentChat.length === 0 ||
      (currentChat.length === 1 &&
        currentChat[0].message === session?.disclaimer);
    await dispatch(
      chatReply(
        session?.id,
        prompt,
        updateSessionTitle,
        selectedItems.length
          ? [
              {
                type: msgContext?.details[0]?.type || '',
                items: selectedItems,
              },
            ]
          : undefined,
      ),
    );
    AnalyticsService.instance().logEvent(
      AnalyticsEventEnum.LEO_MESSAGE_SEND_CLICK,
    );
  };

  const onSubmit = async (text: string) => {
    if (session?.id && !!text?.trim()) {
      preSendChatReply();
      await sendChatReply({ title: text, prompt: text, category: '' });
    }
  };

  const onActionClick = async (prompt: ContextualPrompt) => {
    if (session?.id) {
      preSendChatReply();
      await sendChatReply(prompt);
    }
  };

  useEffect(() => {
    if (isDisabled) {
      return;
    }
    setFocus('askLeoText');
  }, [isDisabled, setFocus]);

  return (
    <div className='mt-2 flex items-center gap-2 relative'>
      <div className='flex-1'>
        <NeoLeoControlledInput<ChatFormData, 'askLeoText'>
          control={control}
          name='askLeoText'
          placeholder='Ask Leo'
          onSubmit={() => onSubmit(askLeoText!)}
          disabled={isDisabled}
          rightIcon={
            <button
              type='button'
              aria-label='leo-send'
              onClick={() => onSubmit(askLeoText!)}
              disabled={isDisabled || !askLeoText?.trim()}
            >
              <FontAwesomeIcon
                icon={faPaperPlaneTop}
                className={classNames(
                  'mt-1.5',
                  askLeoText ? 'text-aqua' : 'text-regent-600',
                )}
              />
            </button>
          }
        />
      </div>
      {!isIntro && !askLeoText && (
        <button
          className='h-10 bg-white bg-opacity-10 aspect-square flex items-center justify-center rounded-full'
          type='button'
          onClick={() => setShowPrompts?.((prev) => !prev)}
        >
          {showPrompts ? (
            <FontAwesomeIcon icon={faXmark} className='text-aqua text-base' />
          ) : (
            <FontAwesomeIcon
              icon={faSparkles}
              className='text-aqua text-base'
            />
          )}
        </button>
      )}
      <NeoLeoKBarActions
        isOpen={!!askLeoText && !!actions.length}
        actions={actions}
        onActionClick={onActionClick}
      />
    </div>
  );
};

export default NeoLeoChatInput;
