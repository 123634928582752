import { faCheck, faL } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface DocumentTitleProps {
  title: string;
}

const DocumentTitle: React.FC<DocumentTitleProps> = ({ title }) => {
  return (
    <div className='bg-mineshaft flex w-full justify-center'>
      <FontAwesomeIcon
        icon={faL}
        className='text-xl mt-1 mr-2 md:ml-0 text-lightBlue-400 transform -scale-y-100'
      />
      <p className='text-white font-primary-regular md:text-2xl print:text-lg pr-2 md:pr-4 md:pl-2 my-1 self-center'>
        {title}
      </p>
      <FontAwesomeIcon
        icon={faCheck}
        className='text-xl mt-4 text-lightBlue-400 -rotate-45'
      />
    </div>
  );
};

export default DocumentTitle;
