import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';
import { RevShareTierInputsDto } from '../openapi/plutus';
import { parseAgentCountsInTiers } from '../utils/WealthPlanUtils';

export const useDetectChangeInAgentCountsInTier = (
  agentCountsInTier: RevShareTierInputsDto[] | undefined,
  agentCountsInTierOriginal: RevShareTierInputsDto[] | undefined,
) => {
  const parsedAgentCountsInTiers = parseAgentCountsInTiers(
    agentCountsInTier || [],
  );

  const ref = useRef<boolean>(false);

  useEffect(() => {
    const hasChanged = !isEqual(
      parsedAgentCountsInTiers,
      parseAgentCountsInTiers(agentCountsInTierOriginal || []),
    );
    ref.current = hasChanged;
  }, [agentCountsInTierOriginal, parsedAgentCountsInTiers]);

  return ref;
};
