import { useSelector } from 'react-redux';
import { useGemini } from '../../hooks/useGemini';
import { RootState } from '../../types';
import { cn } from '../../utils/classUtils';

interface AgentRoleProps {}

const AgentRole: React.FC<AgentRoleProps> = () => {
  const {
    isAdmin,
    isBroker,
    isSuperAdmin,
    isRealTitleOfficer,
    isLoanOfficer,
    isLoanOfficerAssistant,
    isMortgageAdmin,
    isLoanProcessor,
  } = useSelector((state: RootState) => state.auth);

  const isGeminiEnabled = useGemini();

  let role = 'Agent';
  if (isSuperAdmin) {
    role = 'Super Admin';
  } else if (isAdmin) {
    role = 'Admin';
  } else if (isBroker) {
    role = 'Broker';
  } else if (isRealTitleOfficer) {
    role = 'Real Title Officer';
  } else if (isMortgageAdmin) {
    role = 'Mortgage Admin';
  } else if (isLoanOfficer) {
    role = 'Loan Officer';
  } else if (isLoanOfficerAssistant) {
    role = 'Loan Officer Assistant';
  } else if (isLoanProcessor) {
    role = 'Loan Processor';
  }

  return (
    <div>
      <span
        className={cn(
          'text-zen-gray-4 text-xs font-normal',
          isGeminiEnabled && 'font-zen-body-2',
        )}
      >
        {role}
      </span>
    </div>
  );
};

export default AgentRole;
