import {
  get,
  isArray,
  isEqual,
  isObject,
  last,
  merge,
  mergeWith,
  set,
  size,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm, UseFormSetValue } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  AdministrativeAreaRequest,
  AdministrativeAreaRequestCountryEnum,
  ApplicationControllerApi,
  ApplicationRequest,
  ApplicationRequestTeamRoleEnum,
  LicenseRequestLicenseTypeEnum,
  UpdateApplicationRequest,
  UpdateApplicationRequestTeamRoleEnum,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { saveApplication } from '../../../slices/AuthSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  ApplicationFormFieldName,
  getFlattenedObjectWithDotNotation,
} from '../../../testUtils/OnboardingUtils';
import {
  AppDispatch,
  FormFieldConfig,
  InputFieldType,
  RootState,
  YesNoType,
} from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ApplicationProgressBarCard from '../../ApplicationProgressBarCard';
import Button from '../../Button';
import ConfirmationModal, { ModalVariantType } from '../../ConfirmationModal';
import ControlledAsyncSelectCreatableInputV7 from '../../ControlledAsyncSelectCreatableInputV7';
import ZenControlledCheckboxInput from '../../Zen/Input/ZenControlledCheckboxInput';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledImageUpload from '../../Zen/Input/ZenControlledImageUploadInput';
import ZenControlledMultiSelectInput from '../../Zen/Input/ZenControlledMultiSelectInput';
import ZenControlledPhoneNumberInput from '../../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledRadioInput from '../../Zen/Input/ZenControlledRadioInput';
import ZenControlledSelectInput from '../../Zen/Input/ZenControlledSelectInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import { FormDataType } from '../ApplicationForm';
import StepPagination from '../StepPagination';

interface ExtraFieldProps {
  field: FormFieldConfig;
  renderField(currentField: FormFieldConfig): JSX.Element;
  formData: Partial<FormDataType>;
  setValue: UseFormSetValue<FormDataType>;
}

const ExtraField: React.FC<ExtraFieldProps> = ({
  field,
  renderField,
  formData,
  setValue,
}: any) => {
  useEffect(() => {
    setValue(field.name, get(formData, field.name) || field.defaultValue || '');
  }, [field.defaultValue, field.name, formData, setValue]);

  return (
    <div key={field.name} className='mt-5'>
      {field.label && (
        <p className='text-xl font-primary-medium mb-2'>
          {field.label}{' '}
          {field.isRequired && <span className='text-error'>*</span>}
        </p>
      )}
      {field.secondaryLabel && <p className='mb-3'>{field.secondaryLabel}</p>}
      {field.helperText && (
        <p className='text-gray-500 text-sm mb-1'>
          {field.helperText}
          {!!field.rules?.required ? '*' : ' (optional)'}:
        </p>
      )}
      {renderField(field!)}
    </div>
  );
};

export interface StepQuestionFormProps {
  questions: FormFieldConfig[];
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  setIsReviewing: (isReviewing: boolean) => void;
  formData: Partial<FormDataType>;
  setFormData(formData: Partial<FormDataType>): void;
}

const StepQuestionForm: React.FC<StepQuestionFormProps> = ({
  currentIndex,
  questions,
  setCurrentIndex,
  setIsReviewing,
  formData,
  setFormData,
}) => {
  const { control, handleSubmit, setValue, watch } = useForm<FormDataType>();
  const [completedSteps, setCompletedSteps] = useState(
    size(getFlattenedObjectWithDotNotation(formData)),
  );
  const dispatch: AppDispatch = useDispatch();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const isFormFilled = completedSteps === questions.length;
  const activeField = questions[currentIndex];
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const userCountry = userDetail?.accountCountry!;

  const getConvertedFormData = (
    newFormData: Partial<FormDataType>,
  ): ApplicationRequest => {
    const allFlattenedQuestions: FormFieldConfig[] = [];
    questions.forEach((question) => {
      allFlattenedQuestions.push(question);
      if (question.extraFields?.length) {
        allFlattenedQuestions.push(...question.extraFields);
      }
    });

    const request: ApplicationRequest = merge(
      {},
      ...allFlattenedQuestions.map((question) =>
        set(
          {},
          question.name,
          get(newFormData, question.name)
            ? question.convertValueBeforeSend
              ? question.convertValueBeforeSend(
                  get(newFormData, question.name),
                  newFormData,
                )
              : get(newFormData, question.name)
            : undefined,
        ),
      ),
    );
    request.doesBusinessInExtended?.forEach((license, index) => {
      license.licenseRequest!.administrativeAreaRequest = {
        country: (userCountry as unknown) as AdministrativeAreaRequestCountryEnum,
        stateOrProvince: newFormData.selectedProvinces[index]?.value,
      };
      //@ts-ignore
      license!.mlses! = !!license?.mls ? [license?.mls] : [];
      //@ts-ignore
      license!.boards! = !!license?.board ? [license?.board] : [];
      //@ts-ignore
      license!.board = undefined;
      //@ts-ignore
      license!.mls! = undefined;
    });
    request.teamRole =
      //@ts-ignore
      !request?.individualAgent
        ? //@ts-ignore
          request?.teamLeader
          ? ApplicationRequestTeamRoleEnum.Leader
          : ApplicationRequestTeamRoleEnum.Member
        : undefined;

    return request;
  };

  const onSubmit = async (values: Partial<FormDataType>) => {
    setIsSubmitting(true);

    const newFormData = mergeWith(formData, values, (obj, src, key) => {
      if (
        ((isObject(obj) && !isArray(obj)) ||
          key === ApplicationFormFieldName.DOES_BUSINESS_IN) &&
        ![
          ApplicationFormFieldName.MLS_NAME,
          ApplicationFormFieldName.BOARD_NAME,
          ApplicationFormFieldName.PHOTO_UPLOAD,
        ].includes(key as ApplicationFormFieldName)
      ) {
        return merge(obj, src);
      }

      if (
        key === ApplicationFormFieldName.PHOTO_UPLOAD &&
        !values?.photoUpload
      ) {
        return null;
      }

      return src;
    });

    const convertedData = getConvertedFormData(newFormData);

    const applicationStaged: UpdateApplicationRequest = {
      ...convertedData,
      emailAddress: userDetail?.emailAddress!,
      firstName: userDetail?.firstName!,
      lastName: userDetail?.lastName!,
      teamLeaderName: convertedData?.teamLeaderName
        ? convertedData?.teamLeaderName
        : ((null as unknown) as undefined),
      teamName: convertedData?.teamName
        ? convertedData?.teamName
        : ((null as unknown) as undefined),
      teamRole: convertedData?.teamName
        ? ((convertedData?.teamRole as unknown) as UpdateApplicationRequestTeamRoleEnum)
        : ((null as unknown) as undefined),
      commercialAgent: false,
      // @ts-ignore
      individualAgentFlag: convertedData?.individualAgent,
      // @ts-ignore
      preferredTitleVendorFlag: convertedData?.isPreferredTitleVendor,
      // @ts-ignore
      preferredTitleVendor: convertedData?.isPreferredTitleVendor
        ? convertedData?.preferredTitleVendor
        : null,
    };

    if (values.selectedProvinces) {
      applicationStaged.doesBusinessInExtended =
        // @ts-ignore
        convertedData.selectedProvinces?.map(
          (ele: AdministrativeAreaRequest) => ({
            licenseRequest: {
              administrativeAreaRequest: ele,
              active: (null as unknown) as boolean,
              licenseType: (null as unknown) as LicenseRequestLicenseTypeEnum,
              expirationDate: (null as unknown) as string,
              knownComplaints: (null as unknown) as boolean,
              number: (null as unknown) as string,
            },
            mlses: [],
            boards: [],
          }),
        );
    }

    let skipUpdate: boolean = false;
    if (values.photoUpload) {
      skipUpdate = true;
    }

    try {
      const applicationDetails = last(userDetail?.applications) || {};

      if (!skipUpdate) {
        await new ApplicationControllerApi(
          getYentaConfiguration(),
        ).updateApplication(applicationDetails?.id!, applicationStaged);
      }

      if (values.photoUpload instanceof File) {
        const { data } = await new ApplicationControllerApi(
          getYentaConfiguration(),
        ).updateApplicationDriverLicenseImage(
          applicationDetails?.id!,
          values?.photoUpload,
        );

        dispatch(saveApplication(data));
      }

      setFormData(newFormData);

      const isModalVisible =
        activeField?.showConfirmNext &&
        !!activeField?.getConfirmationModalOpenStatus &&
        !!activeField?.getConfirmationModalOpenStatus(values);

      if (currentIndex + 1 === questions.length || isFormFilled) {
        setIsReviewing(true);
      } else if (isModalVisible) {
        setIsModalOpen(true);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to save application form', e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderField = (currentField: FormFieldConfig) => {
    const readOnly: boolean =
      !!currentField.naMessage &&
      watch(currentField.name as keyof FormDataType) === currentField.naMessage;

    if (currentField.type === InputFieldType.SELECT) {
      return (
        <ZenControlledSelectInput
          options={currentField.options || []}
          name={currentField.name as keyof FormDataType} // Update the type of the name prop
          control={control}
          rules={currentField.rules}
          shouldUnregister
        />
      );
    }

    if (currentField.type === InputFieldType.CHECKBOX) {
      return (
        <ZenControlledCheckboxInput
          options={currentField.options || []}
          name={currentField.name as keyof FormDataType}
          defaultValue={currentField.defaultValue}
          control={control}
          rules={currentField.rules}
          inlineOptions
          shouldUnregister
        />
      );
    }

    if (currentField.type === InputFieldType.RADIO) {
      return (
        <ZenControlledRadioInput
          options={currentField.options || []}
          name={currentField.name as keyof FormDataType}
          defaultValue={currentField.defaultValue}
          control={control}
          rules={currentField.rules}
          inlineOptions
          shouldUnregister
        />
      );
    }

    if (currentField.type === InputFieldType.DATE) {
      return (
        <ZenControlledDatePickerInput
          datePickerConfig={currentField.datePickerConfig}
          name={currentField.name as keyof FormDataType}
          defaultValue={currentField.defaultValue}
          control={control}
          rules={currentField.rules}
          shouldUnregister
        />
      );
    }

    if (currentField.type === InputFieldType.MULTISELECT) {
      return (
        <ZenControlledMultiSelectInput
          control={control}
          options={currentField.options!}
          placeholder={currentField.placeholder}
          name={currentField.name as keyof FormDataType}
          rules={currentField.rules}
          closeMenuOnSelect={false}
          shouldUnregister
        />
      );
    }

    if (currentField.type === InputFieldType.ASYNC_CREATABLE_SELECT) {
      return (
        <ControlledAsyncSelectCreatableInputV7
          fetchData={currentField.fetchData!}
          createLabelPrefix='Add New'
          placeholder={currentField.placeholder}
          name={currentField.name as keyof FormDataType}
          rules={currentField.rules}
          defaultValue={currentField.defaultValue}
          control={control}
        />
      );
    }

    if (currentField.type === InputFieldType.IMAGE_SELECT) {
      return (
        <ZenControlledImageUpload
          control={control}
          name={currentField.name as keyof FormDataType}
          defaultValue={currentField.defaultValue}
          rules={currentField.rules}
          uploadText='Upload Image'
          shouldUnregister
        />
      );
    }

    if (currentField.type === InputFieldType.PHONE) {
      return (
        <ZenControlledPhoneNumberInput
          name={currentField.name as keyof FormDataType}
          defaultValue={currentField.defaultValue}
          control={control}
          rules={currentField.rules}
          placeholder={currentField.placeholder}
          shouldUnregister
        />
      );
    }

    return (
      <ZenControlledTextInput
        name={currentField.name as keyof FormDataType}
        defaultValue={
          get(formData, currentField.name) || currentField.defaultValue
        }
        control={control}
        rules={currentField.rules}
        placeholder={currentField.placeholder}
        readOnly={readOnly}
        shouldUnregister
      />
    );
  };

  useEffect(() => {
    setValue(
      activeField.name as keyof FormDataType,
      get(formData, activeField.name) || activeField.defaultValue || '',
    );
  }, [activeField.defaultValue, activeField.name, formData, setValue]);

  // if stateOrProvince is changed, the state is reset
  const stateOrProvince = watch(ApplicationFormFieldName.SELECTED_PROVINCES);
  useEffect(() => {
    const oldStateOrProvince = get(
      formData,
      ApplicationFormFieldName.SELECTED_PROVINCES,
    );
    if (
      Array.isArray(stateOrProvince) &&
      Array.isArray(oldStateOrProvince) &&
      !isEqual(stateOrProvince, oldStateOrProvince)
    ) {
      setFormData(
        set(formData, ApplicationFormFieldName.DOES_BUSINESS_IN, undefined),
      );
    }
  }, [stateOrProvince, formData, setFormData]);

  const isIndividualAgent = watch(ApplicationFormFieldName.INDIVIDUAL_AGENT);
  const isTeamLeader = watch(ApplicationFormFieldName.TEAM_LEADER);

  useEffect(() => {
    if (isIndividualAgent === YesNoType.YES) {
      setFormData(set(formData, ApplicationFormFieldName.TEAM_LEADER_NAME, ''));
      setFormData(set(formData, ApplicationFormFieldName.TEAM_NAME, ''));
    }
    if (isTeamLeader === YesNoType.YES) {
      setFormData(set(formData, ApplicationFormFieldName.TEAM_LEADER_NAME, ''));
    }
  }, [formData, isIndividualAgent, isTeamLeader, setFormData]);

  useEffect(() => {
    setCompletedSteps(size(getFlattenedObjectWithDotNotation(formData)));
  }, [formData, setCompletedSteps, stateOrProvince]);

  return (
    <React.Fragment key={activeField.name}>
      <form
        className='w-full px-4 lg:max-w-xl mx-auto mt-10 lg:mt-32'
        onSubmit={handleSubmit(onSubmit)}
      >
        {activeField.label && (
          <p className='text-xl font-primary-medium mb-2'>
            {activeField.label}
            {activeField.isRequired && <span className='text-error'>*</span>}
          </p>
        )}
        {activeField.secondaryLabel && (
          <p className='mb-3'>{activeField.secondaryLabel}</p>
        )}
        {activeField.helperText && (
          <p className='text-gray-500 text-sm mb-1'>
            {activeField.helperText}
            {!!activeField.rules?.required ? '*' : ' (optional)'}:
          </p>
        )}
        {renderField(activeField)}
        {activeField.naMessage && (
          <label className='flex items-center justify-start space-x-2 mt-3'>
            <input
              type='checkbox'
              className='rounded-full border-none ring-1 ring-gray-200 focus:outline-none focus:ring-0 h-4 w-4 text-primary'
              value={activeField.naMessage}
              onChange={({ target: { checked, value } }) => {
                setValue(
                  activeField.name as keyof FormDataType,
                  checked ? value : '',
                );
              }}
              checked={
                watch(activeField.name as keyof FormDataType) ===
                activeField.naMessage
              }
            />
            <p>{activeField.naMessage}</p>
          </label>
        )}
        {activeField.extraFields
          ?.filter(
            (extraField) =>
              !extraField?.shouldDisplayField ||
              extraField.shouldDisplayField(watch()),
          )
          .map((extraField) => (
            <ExtraField
              key={extraField.name}
              field={extraField}
              renderField={renderField}
              formData={formData}
              setValue={setValue}
            />
          ))}

        <div className='mt-5'>
          <StepPagination
            previousText={!isFormFilled && currentIndex !== 0 ? 'Previous' : ''}
            onPrevious={() => setCurrentIndex(currentIndex - 1)}
            nextText={
              currentIndex + 1 >= questions.length || isFormFilled
                ? 'Review'
                : 'Next'
            }
            onSkip={() => onSubmit({ [activeField.name]: undefined })}
            showSkip={!isFormFilled && !activeField?.rules?.required}
            isLoadingNext={isSubmitting}
          />
        </div>
        <div className='fixed p-5 w-full lg:max-w-xs md:bottom-14 lg:bottom-20 right-0'>
          <ApplicationProgressBarCard
            onStep={currentIndex + 1}
            total={questions.length}
          />
        </div>
      </form>
      <ConfirmationModal
        variant={
          (activeField?.confirmationVariant as ModalVariantType) || 'info'
        }
        title={activeField?.confirmationTitle}
        subtitle={activeField?.confirmationSubtitle}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <div className='flex flex-row mt-3 space-x-3'>
          <Button
            label='No'
            type='outline'
            onClick={() => setIsModalOpen(false)}
            fullWidth
          />
          <Button
            buttonType='submit'
            label='Yes'
            type='primary'
            onClick={() => {
              setIsModalOpen(false);
              setCurrentIndex(currentIndex + 1);
            }}
            fullWidth
          />
        </div>
      </ConfirmationModal>
    </React.Fragment>
  );
};

export default StepQuestionForm;
