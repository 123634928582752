import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import {
  CommentControllerApi,
  CommentDto,
  ReactionTypeEnum,
} from '../../../openapi/yada';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { getYadaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ZenAvatar from '../../Zen/ZenAvatar';

interface ZenCommentReactionsProps {
  comment: CommentDto;
  isHovered: boolean;
  isReactionsPopperVisible: boolean;
}

const ZenCommentReactions: React.FC<ZenCommentReactionsProps> = ({
  comment,
  isHovered,
  isReactionsPopperVisible,
}) => {
  const dispatch = useDispatch();

  const {
    auth: { userDetail },
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  const { thumbsUpCount, hasUserReacted } = useMemo(() => {
    const thumbsUpReactions = (comment.reactions || []).filter(
      (reaction) => reaction.type === ReactionTypeEnum.ThumbsUp,
    );

    return {
      thumbsUpCount: thumbsUpReactions.length,
      hasUserReacted: thumbsUpReactions.some(
        (reaction) => reaction.by === userDetail?.id,
      ),
    };
  }, [comment.reactions, userDetail?.id]);

  const sortedReactions = useMemo(() => {
    return sortBy(comment.reactions || [], (reaction) =>
      reaction.by === userDetail?.id ? 0 : 1,
    );
  }, [comment.reactions, userDetail?.id]);

  const onAddReaction = async () => {
    try {
      await new CommentControllerApi(getYadaConfiguration()).addReaction(
        comment.container?.type!,
        comment.container?.id!,
        comment.id!,
        ReactionTypeEnum.ThumbsUp,
      );
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to add reaction', e);
    }
  };

  const onRemoveReaction = async () => {
    try {
      await new CommentControllerApi(getYadaConfiguration()).removeReaction(
        comment.container?.type!,
        comment.container?.id!,
        comment.id!,
        ReactionTypeEnum.ThumbsUp,
      );
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to remove reaction', e);
    }
  };

  if (!isHovered && thumbsUpCount === 0) {
    return null;
  }

  return (
    <Popover
      isOpen={isReactionsPopperVisible && thumbsUpCount > 0}
      positions={['right', 'top']}
      content={({ position, childRect, popoverRect }) => {
        return (
          <ArrowContainer
            position={position}
            popoverRect={popoverRect}
            childRect={childRect}
            arrowColor='white'
            arrowSize={10}
            arrowStyle={{ left: 2 }}
            className='popover-arrow-container w-full'
          >
            <div className='p-4 border bg-white rounded-2xl shadow-2xl text-primary-dark'>
              <div className='font-inter font-medium text-base mb-4'>
                Reactions
              </div>
              <div className='w-[380px] max-h-[260px] overflow-y-auto gap-y-2'>
                {sortedReactions.map((reaction, index) => {
                  const user = agentById[reaction?.by];

                  const isReactionByCurrentUser =
                    reaction?.by === userDetail?.id;

                  const isLastItem = thumbsUpCount - 1 === index;

                  return (
                    <div
                      key={reaction.by}
                      className={cn(
                        'flex flex-row justify-between items-center py-2.5',
                        isReactionByCurrentUser && 'cursor-pointer',
                        !isLastItem && 'border-b border-b-regent-300',
                      )}
                      onClick={() => {
                        if (isReactionByCurrentUser) {
                          onRemoveReaction();
                        }
                      }}
                    >
                      <div className='flex items-center justify-center'>
                        <div className='h-8 w-8'>
                          <ZenAvatar
                            name={user?.firstName!}
                            imageUrl={user?.avatar}
                            size='sm'
                          />
                        </div>
                        <div className='font-inter font-medium text-sm text-primary-dark ml-2'>
                          <div>{`${user?.firstName} ${user?.lastName}`}</div>
                          {isReactionByCurrentUser && (
                            <div className='mt-0.5 font-inter text-sm font-light text-grey-500'>
                              Click to Remove
                            </div>
                          )}
                        </div>
                      </div>
                      <div>👍</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </ArrowContainer>
        );
      }}
    >
      <button
        onClick={hasUserReacted ? onRemoveReaction : onAddReaction}
        className={cn(
          'bg-white border rounded-[50px] cursor-pointer px-2 py-1 transition-transform',
          hasUserReacted ? 'border-rezen-blue-600' : 'border-regent-300',
        )}
      >
        <p
          className={cn(
            'font-inter-regular font-sm text-regent-600',
            hasUserReacted ? 'text-rezen-blue-600' : 'text-regent-600',
          )}
        >
          👍 {thumbsUpCount > 0 && thumbsUpCount}
          {isReactionsPopperVisible && thumbsUpCount === 0 && 'Like'}
        </p>
      </button>
    </Popover>
  );
};

export default ZenCommentReactions;
