import { faArrowToBottom, faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface GeminiPreviousLatestPillProps {
  pillType: 'previous' | 'latest';
  onClick: () => void;
}

interface PillTypeProps {
  label: string;
  icon: React.ReactNode;
}

const pillTypeMap: Record<string, PillTypeProps> = {
  previous: {
    label: 'previous comments',
    icon: <FontAwesomeIcon icon={faArrowUp} size='sm' />,
  },
  latest: {
    label: 'latest comments',
    icon: <FontAwesomeIcon icon={faArrowToBottom} size='sm' />,
  },
};

const GeminiPreviousLatestPill: React.FC<GeminiPreviousLatestPillProps> = ({
  pillType,
  onClick,
}) => {
  return (
    <div
      className='px-4 py-1.5 md:py-[5px] inline-flex justify-center items-center gap-2 rounded-full bg-avatar-blue-600 font-inter text-white font-medium text-xs md:text-sm cursor-pointer'
      onClick={onClick}
    >
      {pillTypeMap[pillType].icon}
      <p>{pillTypeMap[pillType].label}</p>
    </div>
  );
};

export default GeminiPreviousLatestPill;
