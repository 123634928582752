import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sum } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import {
  PaymentParticipantValue,
  PayoutExplanationResponse,
  TransactionControllerApi,
  TransactionResponse,
} from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { FeatureFlagTypeEnum } from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import DefaultLoader from '../../DefaultLoader';
import { PaidAtLabel } from '../../PaidAtLabel';
import ZenSimpleModal from '../Modal/ZenSimpleModal';
import ZenCollapsibleTableRow from '../ZenCollapsibleTableRow';
import ZenLedgerItemRow from '../ZenLedgerItemRow';
import ZenSignedNumber from '../ZenSignedNumber';
import ZenParticipantCard from './ZenParticipantCard';

export interface ZenPaymentParticipantCardProps {
  transaction?: TransactionResponse;
  participant: PaymentParticipantValue;
  isAdmin: boolean;
  onEdit(): void;
  onDelete(): void;
  isReadonly?: boolean;
  transactionOwner: boolean;
  isSingleCheck: boolean;
  defaultCollapsed?: boolean;
  hideView?: boolean;
  isPaymentDetailsHidden?: boolean;
}

const ZenPaymentParticipantCard: React.FC<ZenPaymentParticipantCardProps> = ({
  transaction,
  participant,
  onEdit,
  onDelete,
  isReadonly,
  transactionOwner,
  isAdmin,
  hideView,
  defaultCollapsed = false,
  isPaymentDetailsHidden = false,
}) => {
  const dispatch = useDispatch();
  const isNoPaymentDetailsToShow =
    isPaymentDetailsHidden &&
    !(
      participant.grossPayment ||
      participant.netPayout ||
      participant.payoutSetting ||
      participant.commissionTotal
    );

  const payoutExplanationEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.PAYOUT_EXPLANATION,
  );

  const [isPayoutLoading, setIsPayoutLoading] = useState<boolean>(true);
  const [
    explanationText,
    setExplanationText,
  ] = useState<PayoutExplanationResponse>();

  const [isOpen, setIsOpen] = useState(false);

  const fetchPayoutExplanation = async (
    transactionId: string,
    participantId: string,
  ) => {
    setIsPayoutLoading(true);
    try {
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).explainParticipantPayout(transactionId, participantId);
      setExplanationText(data);
    } catch (e) {
      dispatch(
        showErrorToast(
          'We had a problem fetching payout explanation',
          'Please try again in a few moments.',
        ),
      );
      ErrorService.notifyIgnoreAuthErrors(
        'unable to fetch payout explanation',
        e,
        {
          data: { transactionId, participantId },
        },
      );
    } finally {
      setIsPayoutLoading(false);
    }
  };

  return (
    <div className='w-full h-full'>
      <ZenParticipantCard
        transaction={transaction}
        participant={participant}
        onDelete={onDelete}
        onEdit={onEdit}
        isReadonly={isReadonly}
        transactionOwner={transactionOwner}
        isAdmin={isAdmin}
        hideView={hideView}
        isPaymentDetailsHidden={isPaymentDetailsHidden}
      >
        <div className='pb-10'>
          {isNoPaymentDetailsToShow ? (
            <div className='flex flex-col flex-grow justify-center items-center py-10'>
              <p className='text-gray-400 text-center'>No view permission</p>
            </div>
          ) : (
            <div className='m-3 py-1 bg-grey-100 rounded-[10px]'>
              <ZenCollapsibleTableRow
                name='Commission Split'
                value={
                  !participant.paid
                    ? participant.payoutSetting?.percentage?.string
                    : undefined
                }
                ValueComponent={
                  participant.paid ? (
                    <PaidAtLabel paidAt={participant.paidAt!} />
                  ) : undefined
                }
                showBorder={false}
                defaultCollapsed={defaultCollapsed}
                payoutExplanation={
                  !!payoutExplanationEnabled &&
                  !!transaction?.id &&
                  !!participant.id ? (
                    <div>
                      <div
                        onClick={() => {
                          setIsOpen(true);
                          fetchPayoutExplanation(
                            transaction?.id!,
                            participant.id!,
                          );
                        }}
                        className='cursor-pointer'
                        data-testid='payout-explanation'
                      >
                        <FontAwesomeIcon
                          icon={faCircleQuestion}
                          className='text-md text-primary-dark mx-3'
                        />
                      </div>
                    </div>
                  ) : undefined
                }
              />
              <ZenCollapsibleTableRow
                name='Total'
                value={displayAmount(participant.commissionTotal)}
                defaultCollapsed={defaultCollapsed}
              />
              <ZenCollapsibleTableRow
                name='Real'
                ValueComponent={
                  <ZenSignedNumber
                    value={{
                      amount: sum(
                        participant?.realItems?.map(
                          (item) => item.amount?.amount,
                        ),
                      ),
                      currency: participant.commissionTotal?.currency!,
                    }}
                  />
                }
                defaultCollapsed={defaultCollapsed}
              >
                {participant?.realItems?.length !== 0 &&
                  participant?.realItems?.map((item, index) => (
                    <ZenLedgerItemRow
                      key={item?.id}
                      item={item}
                      participant={participant}
                      showBorder={index > 0}
                    />
                  ))}
              </ZenCollapsibleTableRow>
              <ZenCollapsibleTableRow
                name='Fees And Rebates'
                ValueComponent={
                  <ZenSignedNumber
                    value={{
                      amount: sum(
                        participant.feeAndRebateItems?.map(
                          (item) => item.amount?.amount,
                        ),
                      ),
                      currency: participant.commissionTotal?.currency!,
                    }}
                  />
                }
                defaultCollapsed={defaultCollapsed}
              >
                {participant?.feeAndRebateItems?.length !== 0 &&
                  participant?.feeAndRebateItems?.map((item, i) => (
                    <ZenLedgerItemRow
                      key={item.id}
                      item={item}
                      participant={participant}
                      showBorder={i > 0}
                    />
                  ))}
              </ZenCollapsibleTableRow>
              <ZenCollapsibleTableRow
                name='Net Payout'
                ValueComponent={
                  <ZenSignedNumber value={participant.netPayout!} />
                }
                defaultCollapsed={defaultCollapsed}
              />
            </div>
          )}
        </div>
      </ZenParticipantCard>
      <ZenSimpleModal
        title={`Payout for ${participant?.fullName}`}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size='large'
      >
        <div className='m-6 flex flex-col'>
          {!explanationText?.explanation && isPayoutLoading ? (
            <DefaultLoader />
          ) : (
            <p className='font-zen-body text-base text-zen-dark-9'>
              {explanationText?.explanation || 'N/A'}
            </p>
          )}
        </div>
      </ZenSimpleModal>
    </div>
  );
};

export default ZenPaymentParticipantCard;
