import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CellProps, Column, Row } from 'react-table';
import {
  AddressStateEnum,
  TransactionControllerApi,
  TransactionLifecycleStateValueStateEnum,
  TransactionLiteResponse,
  TransactionResponseCountryEnum,
  TransactionResponseTransactionTypeEnum,
} from '../../../openapi/arrakis';
import { EnumMap, RootState } from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { getTransactionSystemActualClosingDate } from '../../../utils/TransactionHelper';
import NullableTextCell from '../../table/Cells/NullableTextCell';
import ZenViewActionButtonCell from '../../table/Cells/ZenViewActionButtonCell';
import ZenResourceIndexContainer from '../Containers/ZenResourceIndexContainer';
import ZenRouterTabs, { ZenTab } from '../Tab/ZenRouterTabs';
import ZenAddressCell from '../Table/Cell/ZenAddressCell';
import ZenDateCell from '../Table/Cell/ZenDateCell';
import ZenTransactionTypeCell from '../Table/Cell/ZenTransactionTypeCell';
import ZenRoute from '../ZenRoute';
import ZenTransactionCodeCell from './ZenTransactionCodeCell';
import ZenTransactionStatus from './ZenTransactionStatus';

export const columns: Array<Column<TransactionLiteResponse>> = [
  {
    Header: 'Transaction Code',
    accessor: 'code',
    Cell: ({
      row: { original },
    }: CellProps<TransactionLiteResponse, string>) => (
      <ZenTransactionCodeCell
        linkTo={`/transactions/${original.id}`}
        transaction={original}
      />
    ),
    disableFilters: true,
    cardColSize: 7,
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'transactionType',
    Cell: ({ value }) => <ZenTransactionTypeCell dealType={value!} />,
    disableFilters: true,
    cardColSize: 5,
    disableSortBy: true,
  },
  {
    Header: 'Invoice Number',
    accessor: 'commissionInvoiceNumber',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Address',
    accessor: (d) => d['address']?.street,
    Cell: ({
      row: { original },
    }: CellProps<TransactionLiteResponse, string>) => (
      <div className='w-56'>
        <ZenAddressCell address={original.address} />
      </div>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Price',
    accessor: (d) => d['price']?.amount,
    id: 'price',
    Cell: ({ row: { original } }: CellProps<TransactionLiteResponse, number>) =>
      displayAmount(original.price),
    disableFilters: true,
    cardColSize: 6,
  },
  {
    Header: 'My Net Payout',
    accessor: (d) => d['myNetPayout']?.amount,
    Cell: ({ row: { original } }: CellProps<TransactionLiteResponse>) => (
      <div>{displayAmount(original.myNetPayout!)}</div>
    ),
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Representing',
    accessor: (d) => d['transactionOwner']?.represents,
    Cell: ({ value }: CellProps<TransactionLiteResponse, string>) => (
      <NullableTextCell text={value} />
    ),
    disableFilters: true,
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: 'Status',
    accessor: ({ lifecycleState }) => lifecycleState?.state,
    id: 'lifecycleState',
    Cell: ({
      value,
      row: { original },
    }: React.PropsWithChildren<
      CellProps<
        TransactionLiteResponse,
        TransactionLifecycleStateValueStateEnum
      >
    >) => (
      <ZenTransactionStatus
        status={value}
        country={
          (original.country as unknown) as TransactionResponseCountryEnum
        }
        transactionType={
          (original.transactionType as unknown) as TransactionResponseTransactionTypeEnum
        }
      />
    ),
    disableFilters: true,
    cardColSize: 6,
    disableSortBy: true,
  },
  {
    Header: 'Escrow Closing Date',
    accessor: 'skySlopeEscrowClosingDate',
    Cell: ({ value }) => <ZenDateCell date={value!} />,
    disableFilters: true,
    cardColSize: 6,
  },
  {
    Header: 'System Closing Date',
    accessor: 'rezenClosedAt',
    Cell: ({ row: { original } }: CellProps<TransactionLiteResponse>) => (
      <NullableTextCell
        text={getTransactionSystemActualClosingDate(original)}
      />
    ),
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Gross Commission',
    accessor: (d) => d['grossCommission']?.amount,
    Cell: ({
      row: { original },
    }: CellProps<TransactionLiteResponse, number>) => (
      <div>
        {displayAmount(original.grossCommission!, { hideCurrency: true })}
        <div className='text-sm	text-zen-gray-4'>
          {original.grossCommission?.currency}
        </div>
      </div>
    ),
    disableFilters: true,
    cardColSize: 6,
    disableSortBy: true,
  },
  // {
  //   Header: 'Participants Split',
  //   accessor: 'participantsSplit',
  //   Cell: ({ value, row }) =>
  //     `${displayAmount(value)} (${row.original.participantsSplitPercentage}%)`,
  //   disableFilters: true,
  // },
  // {
  //   Header: 'Total Real Split',
  //   accessor: 'commissionSplits',
  //   Cell: ({ value, row }) =>
  //     `${displayAmount(value)} (${row.original.totalRealSplitPercentage}%)`,
  //   disableFilters: true,
  // },
  {
    Header: 'State / Province',
    accessor: (d) => d['address']?.state,
    Cell: ({ value }: CellProps<TransactionLiteResponse, AddressStateEnum>) =>
      capitalizeEnum(value),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    accessor: 'closed',
    id: 'closed',
    disableSortBy: true,
    disableFilters: true,
  },
];

const columnsWithActions: Array<Column<TransactionLiteResponse>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<TransactionLiteResponse> }) => (
      <Link to={`/transactions/${row.original.id}`}>
        <ZenViewActionButtonCell />
      </Link>
    ),
  },
  ...columns,
];

const TransactionSortByTypeEnum: EnumMap<
  string,
  'ESCROW_CLOSING_DATE' | 'ACTUAL_CLOSING_DATE' | 'PRICE'
> = {
  skySlopeEscrowClosingDate: 'ESCROW_CLOSING_DATE',
  rezenClosedAt: 'ACTUAL_CLOSING_DATE',
  price: 'PRICE',
};

const TransactionSortDirectionTypeEnum: EnumMap<string, 'ASC' | 'DESC'> = {
  asc: 'ASC',
  desc: 'DESC',
};

interface ZenAgentTransactionsProps {
  agentId: string;
  search?: string;
}

const ZenAgentTransactions: React.FC<ZenAgentTransactionsProps> = ({
  agentId,
  search,
}) => {
  const { detailResponse } = useSelector(
    (state: RootState) => state.agentDetail,
  );

  const tabs: ZenTab[] = useMemo(() => {
    const array = [
      {
        label: 'Active',
        path: `/people/${agentId}/transactions`,
        exact: true,
        TabComponent: () => (
          <ZenRoute
            title={`Active Transactions - ${detailResponse.data?.fullName}`}
          >
            <div className='mt-4'>
              <ZenResourceIndexContainer<TransactionLiteResponse>
                columns={columnsWithActions}
                resourceName='transaction'
                hideFilters
                search={search}
                pageSize={10}
                initialSort={{ skySlopeEscrowClosingDate: 'asc' }}
                hidePageSize
                fetchData={async (req, cancelToken) => {
                  const sortKey = Object.keys(req.sortBy || {})[0];
                  const sortType = Object.values(req.sortBy || {})[0];
                  const { data } = await new TransactionControllerApi(
                    getArrakisConfiguration(),
                  ).getTransactionsByStateGroupPaginated(
                    agentId,
                    'OPEN',
                    req.page,
                    req.pageSize,
                    req.search,
                    TransactionSortByTypeEnum[sortKey],
                    TransactionSortDirectionTypeEnum[sortType!],
                    undefined,
                    { cancelToken },
                  );

                  return {
                    data: data.transactions!,
                    total: data.totalCount!,
                  };
                }}
              />
            </div>
          </ZenRoute>
        ),
      },
      {
        label: 'Closed',
        path: `/people/${agentId}/transactions/closed`,
        exact: true,
        TabComponent: () => (
          <ZenRoute
            title={`Closed Transactions - ${detailResponse.data?.fullName}`}
          >
            <div className='mt-4'>
              <ZenResourceIndexContainer<TransactionLiteResponse>
                columns={columnsWithActions}
                resourceName='transaction'
                search={search}
                hideFilters
                hidePageSize
                pageSize={10}
                initialSort={{ skySlopeEscrowClosingDate: 'asc' }}
                fetchData={async (req, cancelToken) => {
                  const sortKey = Object.keys(req.sortBy || {})[0];
                  const sortType = Object.values(req.sortBy || {})[0];
                  const { data } = await new TransactionControllerApi(
                    getArrakisConfiguration(),
                  ).getTransactionsByStateGroupPaginated(
                    agentId,
                    'CLOSED',
                    req.page,
                    req.pageSize,
                    req.search,
                    TransactionSortByTypeEnum[sortKey],
                    TransactionSortDirectionTypeEnum[sortType!],
                    undefined,
                    { cancelToken },
                  );

                  return {
                    data: data.transactions!,
                    total: data.totalCount!,
                  };
                }}
              />
            </div>
          </ZenRoute>
        ),
      },
      {
        label: 'Terminated',
        path: `/people/${agentId}/transactions/terminated`,
        exact: true,
        TabComponent: () => (
          <ZenRoute
            title={`Terminated Transactions - ${detailResponse.data?.fullName}`}
          >
            <div className='mt-4'>
              <ZenResourceIndexContainer<TransactionLiteResponse>
                columns={columnsWithActions}
                resourceName='transaction'
                search={search}
                hideFilters
                hidePageSize
                pageSize={10}
                initialSort={{ skySlopeEscrowClosingDate: 'asc' }}
                fetchData={async (req, cancelToken) => {
                  const sortKey = Object.keys(req.sortBy || {})[0];
                  const sortType = Object.values(req.sortBy || {})[0];
                  const { data } = await new TransactionControllerApi(
                    getArrakisConfiguration(),
                  ).getTransactionsByStateGroupPaginated(
                    agentId,
                    'TERMINATED',
                    req.page,
                    req.pageSize,
                    req.search,
                    TransactionSortByTypeEnum[sortKey],
                    TransactionSortDirectionTypeEnum[sortType!],
                    undefined,
                    { cancelToken },
                  );

                  return {
                    data: data.transactions!,
                    total: data.totalCount!,
                  };
                }}
              />
            </div>
          </ZenRoute>
        ),
      },
    ];
    return array;
  }, [agentId, search]);

  return <ZenRouterTabs tabs={tabs} />;
};

export default ZenAgentTransactions;
