import classNames from 'classnames';
import { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { FormFieldTooltipIndexProps } from '../../../types';
import ZenFormErrorMessage from './ZenFormErrorMessage';

type DisplayVariant = 'block' | 'inline';

export interface ZenControlledNumberInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  autoFocus?: boolean;
  label?: string;
  subLabel?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  isRequired?: boolean;
  helperText?: string;
  maxLength?: number;
  onChangeSpy?: (value: number) => void;
  allowDecimals?: boolean;
  displayVariant?: DisplayVariant;
}

const ZenControlledNumberInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  autoFocus = false,
  label,
  subLabel,
  readOnly,
  noBorder,
  placeholder,
  hideErrorMessage = false,
  tooltipIndex,
  shouldUnregister = true,
  startAdornment,
  endAdornment,
  helperText,
  isRequired = false,
  maxLength,
  onChangeSpy,
  displayVariant = 'block',
  allowDecimals = true,
  ...rest
}: ZenControlledNumberInputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);

  const DisplayVariantEnumMap: Record<DisplayVariant, string> = {
    block: 'space-y-1',
    inline: 'flex items-start justify-between space-x-2',
  };

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error, invalid },
      }) => {
        return (
          <div
            className={classNames(
              'w-full',
              DisplayVariantEnumMap[displayVariant],
            )}
          >
            {label && (
              <label className='inline-block' htmlFor={name}>
                <span
                  className={classNames(
                    'font-zen-body font-semibold',
                    invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                  )}
                >
                  {label}
                </span>
                {!!subLabel && (
                  <span className='font-zen-body text-sm text-zen-dark-6 ml-1'>
                    {subLabel}
                  </span>
                )}
                {isRequired && <span className='text-zen-danger'>*</span>}
              </label>
            )}
            <div>
              <div
                className={classNames(
                  'flex items-stretch border rounded-lg overflow-hidden bg-white',
                  focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
                  value && 'text-zen-dark-9',
                  invalid && '!border-zen-danger',
                  {
                    'border-none': noBorder,
                  },
                )}
              >
                {startAdornment && <div>{startAdornment}</div>}
                <input
                  autoFocus={autoFocus}
                  id={name}
                  value={value}
                  onChange={({ target: { value } }) => {
                    const inputValue = allowDecimals
                      ? parseFloat(value ?? '')
                      : parseInt(value ?? '', 10);
                    onChange(isNaN(inputValue) ? null : inputValue);
                    onChangeSpy?.(inputValue);
                  }}
                  onFocus={() => setFocus(true)}
                  onBlur={(e) => {
                    onChange(e.target.value?.trim());
                    setFocus(false);
                    onBlur();
                  }}
                  type='number'
                  inputMode={allowDecimals ? 'decimal' : 'numeric'}
                  name={name}
                  readOnly={readOnly}
                  placeholder={placeholder}
                  className={classNames(
                    'appearance-none p-2 w-full border-none focus:outline-none focus:ring-0 font-zen-body font-normal bg-white',
                    {
                      'bg-gray-50': readOnly,
                    },
                  )}
                  maxLength={maxLength}
                  data-tooltip-index={tooltipIndex}
                  onWheel={(e) => {
                    if (e?.currentTarget) {
                      e?.currentTarget?.blur();
                    }
                  }}
                />
                {endAdornment && <div>{endAdornment}</div>}
              </div>
              <div className='flex'>
                {!!error && !hideErrorMessage && (
                  <ZenFormErrorMessage message={error.message} />
                )}
                {helperText && (
                  <div className='ml-auto font-zen-body text-base font-normal text-zen-dark-6'>
                    {helperText}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};
export default ZenControlledNumberInput;
