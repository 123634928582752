import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty, isNil } from 'lodash';
import {
  FeatureFlagTypeEnum,
  RootState,
  SessionStorageKeyEnum,
  YesNoType,
} from '../types';
import { isRegisteredWithTipalti } from '../utils/TipaltiHelper';
import { checkTipaltiStatus } from '../slices/AuthSlice';
import { AgentResponseAccountCountryEnum } from '../openapi/yenta';
import {
  hasCrossedRequiredDate,
  hasPastAgreementRequiredDate,
} from '../utils/AgreementUtils';
import SessionStorageService from '../services/SessionStorageService';
import { useFeatureFlag } from './useFeatureFlag';

const useAgentRedirect = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    auth: { userDetail, tipaltiStatus, isAdmin, isBroker, tipaltiLoading },
    agentDetail: {
      signAgentAgreement,
      agentNonSignedAgreementsResponse,
      agentAgreementLoading,
      allLatestAgreementsNotSigned,
    },
    paymentMethod: { paymentMethodsResponseByAgentId },
    taxInformation: { taxFormResponseByAgentId, taxFormAcknowledgementById },
    announcement: { topAnnouncement, topAnnouncementLoading },
  } = useSelector((state: RootState) => state);
  const isMSDynamicsOnboardingEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.MS_DYNAMICS_ONBOARDING,
  );
  const isIcaAgreementFeatureFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.ICA_AGREEMENTS,
  );

  const isCanadaTaxAcknowledgementEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.TAX_ACKNOWLEDGEMENT,
  );

  const isAgent = !(isAdmin || isBroker);
  const taxForms = taxFormResponseByAgentId[userDetail?.id!];
  const paymentMethods = paymentMethodsResponseByAgentId[userDetail?.id!];
  const isTaxFormNotAcknowledged = !!taxFormAcknowledgementById[
    taxForms?.data?.taxForms?.[0]?.id!
  ]?.data?.pending;
  const showCanadaTaxAcknowledgement =
    isCanadaTaxAcknowledgementEnabled && isTaxFormNotAcknowledged;
  const showMSDynamicsOnboarding =
    isMSDynamicsOnboardingEnabled &&
    isAgent &&
    ((!isNil(taxForms?.data) &&
      !isNil(paymentMethods?.data) &&
      (isEmpty(taxForms?.data?.taxForms) ||
        isEmpty(paymentMethods?.data?.paymentMethods))) ||
      showCanadaTaxAcknowledgement);
  const hasPastRequiredDate = hasPastAgreementRequiredDate(
    agentNonSignedAgreementsResponse?.requiredAt!,
  );

  const hasPastAgreementRequired = hasCrossedRequiredDate(
    allLatestAgreementsNotSigned!,
  );

  const isTaxOrPaymentMethodApiLoading =
    taxFormResponseByAgentId?.loading ||
    paymentMethodsResponseByAgentId?.loading;

  useEffect(() => {
    if (userDetail?.id) {
      dispatch(checkTipaltiStatus());
    }
  }, [dispatch, userDetail]);

  useEffect(() => {
    if (
      userDetail &&
      !topAnnouncementLoading &&
      isEmpty(topAnnouncement) &&
      !(
        tipaltiLoading ||
        isTaxOrPaymentMethodApiLoading ||
        agentAgreementLoading
      )
    ) {
      SessionStorageService.setItem(
        SessionStorageKeyEnum.STOP_PAGE_REDIRECTS,
        YesNoType.YES,
      );
      if (userDetail.needOnBoarding) {
        history.push('/onboarding/welcome-onboard');
      } else if (
        signAgentAgreement &&
        (hasPastRequiredDate || hasPastAgreementRequired)
      ) {
        if (isIcaAgreementFeatureFlagEnabled && hasPastAgreementRequired) {
          history.push('/welcome-to-rezen/agreements-signing');
        } else if (hasPastRequiredDate) {
          history.push('/welcome-to-rezen/ica-signing-v2');
        }
      } else if (tipaltiStatus && !isRegisteredWithTipalti(tipaltiStatus)) {
        history.push('/register-tipalti');
      } else if (!userDetail.licenseVerified) {
        history.push('/verify-license');
      } else if (userDetail.needsTaxIdOnboarding) {
        history.push('/tax-national-ids');
      } else if (showMSDynamicsOnboarding) {
        return history.push('/payment-onboarding');
      } else if (
        userDetail.needsWebsiteOnboarding &&
        userDetail.accountCountry !== AgentResponseAccountCountryEnum.Canada
      ) {
        history.push(`/people/${userDetail.id}/agent-website-onboarding`);
      } else {
        SessionStorageService.removeItem(
          SessionStorageKeyEnum.STOP_PAGE_REDIRECTS,
        );
      }
    }
  }, [
    userDetail,
    history,
    tipaltiStatus,
    showMSDynamicsOnboarding,
    topAnnouncement,
    topAnnouncementLoading,
    isTaxOrPaymentMethodApiLoading,
    tipaltiLoading,
    signAgentAgreement,
    hasPastRequiredDate,
    agentAgreementLoading,
    isIcaAgreementFeatureFlagEnabled,
    hasPastAgreementRequired,
  ]);
};

export default useAgentRedirect;
