import React from 'react';
import {
  FileBlock,
  HashtagBlock,
  HyperlinkBlock,
  ImageBlock,
  MediaAudioBlock,
  MentionBlock,
  ReferenceBlock,
  TextBlock,
  UnknownBlock,
  VoiceCallBlock,
} from '../../../../openapi/yada';
import {
  isFileBlock,
  isHashtagBlock,
  isHyperlinkBlock,
  isImageBlock,
  isMediaAudioBlock,
  isMentionBlock,
  isReferenceBlock,
  isTextBlock,
} from '../../../../utils/TypeUtils';
import { cn } from '../../../../utils/classUtils';
import AttachedFile from '../../../transactions/Comments/AttachedFile';
import VoiceMemo from '../../../transactions/Comments/Roar/VoiceMemo';
import GeminiMentionAgent from './GeminiMentionAgent';

interface GeminiBlockPreviewProps {
  blocks?: Array<
    | FileBlock
    | HashtagBlock
    | HyperlinkBlock
    | ImageBlock
    | MentionBlock
    | ReferenceBlock
    | TextBlock
    | VoiceCallBlock
    | MediaAudioBlock
    | UnknownBlock
  >;
  side?: 'left' | 'right';
}

const GeminiBlockPreview: React.FC<GeminiBlockPreviewProps> = ({
  blocks,
  side,
}) => {
  return (
    <div className='text-sm font-light w-full'>
      {blocks?.map((block) => {
        if (isTextBlock(block)) {
          return (
            <span className='word-break whitespace-pre-wrap'>{block.text}</span>
          );
        }

        if (isReferenceBlock(block)) {
          return <p className='word-break'>{block.preview}</p>;
        }

        if (isImageBlock(block)) {
          return (
            <div className='w-full'>
              <img src={block.url} alt={block.altText} />
            </div>
          );
        }

        if (isHyperlinkBlock(block)) {
          return (
            <a
              href={block.url}
              target='_blank'
              className={cn(
                'px-2',
                'py-0.5',
                side === 'right' ? 'bg-zen-primary-7' : 'bg-zen-gray-2',
                'flex-row',
                side === 'right' ? 'text-white' : 'text-zen-dark-9',
                'underline',
                'font-zen-body-regular',
                'text-sm',
                'rounded-full',
              )}
              rel='noreferrer'
            >
              {block.text}
            </a>
          );
        }

        if (isFileBlock(block)) {
          return <AttachedFile altText={block.altText!} url={block.url!} />;
        }

        if (isMentionBlock(block)) {
          return <GeminiMentionAgent id={block.userId!} />;
        }

        if (isHashtagBlock(block)) {
          return (
            <p className='text-blue-700 font-primary-regular word-break'>
              {block.hashtag}
            </p>
          );
        }

        if (isMediaAudioBlock(block)) {
          return (
            <div className='mt-4'>
              <VoiceMemo audioBlock={block} />
            </div>
          );
        }

        return block.preview;
      })}
    </div>
  );
};

export default GeminiBlockPreview;
