import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import {
  CommissionDocumentControllerApi,
  MoneyValue,
  TransactionControllerApi,
  TransactionExplanationResponse,
  TransactionResponse,
  TransactionResponseCountryEnum,
} from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import { toggleShowChangeFirmDateModal } from '../../../slices/QuickActionSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  CardItem,
  FeatureFlagTypeEnum,
  RootState,
  YesNoType,
} from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { getTransactionRealTitleName } from '../../../utils/RealTitleUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import {
  canHaveApprovedCDAs,
  getTransactionPriceLabel,
  isCanadaTransaction,
  isSaleTransaction,
} from '../../../utils/TransactionHelper';
import DefaultLoader from '../../DefaultLoader';
import Hover from '../../Hover';
import ZenToggleRow from '../Input/ZenToggleRow';
import ZenSimpleModal from '../Modal/ZenSimpleModal';
import ZenCardWithItems from '../ZenCardWithItems';
import ZenPill from '../ZenPill';
import ZenPropertyTypePill from '../ZenPropertyTypePill';
import ZenUserPill from '../ZenUserPill';
import ZenChangeFirmDateSidebarModal from './ZenChangeFirmDateSidebarModal';
import ZenDoubleEnderSidebarModal from './ZenDoubleEnderSidebarModal';
import ZenTransactionDealTypeItem from './ZenTransactionDealTypeItem';
import ZenTransactionStatus from './ZenTransactionStatus';

interface ZenTransactionInformationProps {
  transaction: TransactionResponse;
}

const ZenTransactionInformation: React.FC<ZenTransactionInformationProps> = ({
  transaction,
}) => {
  const dispatch = useDispatch();
  const {
    auth: { isAdmin },
    quickAction: { showChangeFirmDateModal },
    userIds: { agentById },
  } = useSelector((state: RootState) => state);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isDoubleEnderTx = !isEmpty(transaction.doubleEnderInfo);

  const isDoubleEnderEnabled = useFeatureFlag(FeatureFlagTypeEnum.DOUBLE_ENDER);

  const [
    expectedPaymentToReal,
    setExpectedPaymentToReal,
  ] = useState<MoneyValue>();

  const [isPayoutLoading, setIsPayoutLoading] = useState<boolean>(true);

  const [isOpen, setIsOpen] = useState(false);

  const [
    transactionExplanationText,
    setIsTransactionExplanationText,
  ] = useState<TransactionExplanationResponse>();

  const fetchTransactionExplanation = async (transactionId: string) => {
    setIsPayoutLoading(true);
    try {
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).explainTransaction(transactionId);
      setIsTransactionExplanationText(data);
    } catch (e) {
      dispatch(
        showErrorToast(
          'We had a problem fetching transaction explanation',
          'Please try again in a few moments.',
        ),
      );
      ErrorService.notifyIgnoreAuthErrors(
        'unable to fetch transaction explanation',
        e,
        {
          data: { transactionId },
        },
      );
    } finally {
      setIsPayoutLoading(false);
    }
  };

  const fetchCommissionDocumentByTransactionID = useCallback(async () => {
    try {
      const { data } = await new CommissionDocumentControllerApi(
        getArrakisConfiguration(),
      ).getCommissionDocumentByTransactionId(transaction.id!);
      setExpectedPaymentToReal(data.expectedPaymentToReal!);
    } catch (e) {
      ErrorService.notify('Error fetching commission document', e, {
        transaction: { id: transaction.id },
      });
    }
  }, [transaction.id]);

  useEffect(() => {
    if (canHaveApprovedCDAs(transaction.lifecycleState?.state!)) {
      fetchCommissionDocumentByTransactionID();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchCommissionDocumentByTransactionID,
    transaction.lifecycleState?.state,
    transaction?.attachedFees,
  ]);

  const handleToggleChange = () => {
    // Update the state based on the toggle value
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isChecklistDynamicsPropertyTypesEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.CHECKLIST_DYNAMICS_PROPERTY_TYPES,
  );

  const payoutExplanationEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.PAYOUT_EXPLANATION,
  );

  const isFMLSFeeApplied = transaction.fmlsInfo?.propertyListedOnFmls;
  const items: CardItem[] = [
    {
      name: getTransactionPriceLabel(transaction.transactionType!),
      value: displayAmount(transaction.price),
    },
    {
      name: 'Gross Commission',
      value: transaction.grossCommission
        ? displayAmount(transaction.grossCommission)
        : 'N/A',
    },
    {
      name: 'Status',
      value: (
        <div className='mr-[-12px]'>
          <ZenTransactionStatus
            status={transaction.lifecycleState!.state!}
            country={
              (transaction.country! as unknown) as TransactionResponseCountryEnum
            }
            transactionType={transaction.transactionType}
          />
        </div>
      ),
    },
    {
      name: 'Deal Type',
      value: (
        <ZenTransactionDealTypeItem dealType={transaction.transactionType!} />
      ),
    },
    {
      name: 'Commission Invoice #',
      value: transaction.commissionInvoiceNumber || 'N/A',
    },
    {
      name: 'Transaction Owner',
      value: transaction.transactionOwner?.name ? (
        <Link to={`/people/${transaction.transactionOwner?.id}`}>
          <div className='mr-[-12px]'>
            <ZenUserPill
              name={transaction.transactionOwner?.name}
              backgroundVariant='background'
              imageUrl={
                agentById[transaction.transactionOwner?.id!]?.avatar || ''
              }
            />
          </div>
        </Link>
      ) : (
        'N/A'
      ),
    },
    {
      name: 'Representing',
      value: transaction.transactionOwner?.represents || 'N/A',
    },
    {
      name: 'MLS #',
      rightActionComponent: isFMLSFeeApplied ? (
        <Hover
          hoverComponent={
            <div className='text-zen-dark-12 font-medium text-base font-zen-body px-6 '>
              Property is listed in FMLS
            </div>
          }
          config={{ trigger: 'hover', placement: 'top' }}
        >
          <FontAwesomeIcon
            icon={regular('circle-exclamation')}
            size='xs'
            className='pt-2 text-primary-blue'
          />
        </Hover>
      ) : undefined,
      value: transaction.mlsNum || 'N/A',
    },
    {
      name: 'Personal Deal',
      value: transaction.paymentParticipants?.some((p) => p.personalDeal)
        ? 'Yes'
        : 'No',
    },
  ];
  if (isFMLSFeeApplied) {
    items.splice(-1, 0, {
      name: 'FMLS Fee required',
      value: displayAmount(
        transaction.fmlsInfo?.expectedAmount! || { amount: 0 },
      ),
    });
  }

  if (transaction.listingTransaction?.price) {
    items.splice(1, 0, {
      name: 'List Price',
      value: displayAmount(transaction.listingTransaction.price),
    });
  }

  if (
    transaction.grossCommissionPercentage &&
    isSaleTransaction(transaction.transactionType!)
  ) {
    items.splice(2, 0, {
      name: 'Gross Commission Percentage',
      value: transaction.grossCommissionPercentage,
    });
  }

  if (!!expectedPaymentToReal && isAdmin) {
    items.push({
      name: 'Expected Payment to Real',
      value: displayAmount(expectedPaymentToReal),
    });
  }

  if (isCanadaTransaction(transaction)) {
    items.push({
      name: 'Firm Date',
      value: transaction.firmDate
        ? DateTime.fromISO(transaction.firmDate).toFormat('LL/dd/yy')
        : 'N/A',
    });
  }

  if (transaction.usingTitle) {
    items.push({
      name: `Closing With ${getTransactionRealTitleName(
        transaction.address?.state,
      )}`,
      value: capitalizeEnum(YesNoType.YES),
    });
  }

  if (isChecklistDynamicsPropertyTypesEnabled) {
    items.splice(6, 0, {
      name: 'Property Type',
      value: (
        <div className='mr-[-12px]'>
          <ZenPropertyTypePill
            name={capitalizeEnum(transaction.propertyType!)}
            size='sm'
            fontWeight='font-medium'
          />
        </div>
      ),
    });
  }

  if (isDoubleEnderEnabled) {
    items.push({
      name: 'Office Double Ender',
      value: isAdmin ? (
        <ZenToggleRow value={isDoubleEnderTx} onChange={handleToggleChange} />
      ) : (
        <ZenPill
          title={isDoubleEnderTx ? 'Yes' : 'No'}
          variant={isDoubleEnderTx ? 'purple' : 'primary-nooutline'}
        />
      ),
    });
  }
  return (
    <div className='h-fit'>
      <ZenCardWithItems
        title='Transaction Information'
        items={items}
        variant='regular'
        titleAdjacentComponent={
          !!payoutExplanationEnabled && transaction?.id ? (
            <div
              onClick={() => {
                setIsOpen(true);
                fetchTransactionExplanation(transaction?.id!);
              }}
              className='cursor-pointer'
              data-testid='transaction-explanation'
            >
              <FontAwesomeIcon
                icon={faCircleQuestion}
                className='text-md text-primary-dark mx-3'
              />
            </div>
          ) : undefined
        }
      />

      {isSidebarOpen && (
        <ZenDoubleEnderSidebarModal
          isDoubleEnderTx={isDoubleEnderTx!}
          transaction={transaction}
          isOpen={isSidebarOpen}
          onClose={handleToggleChange}
        />
      )}

      {showChangeFirmDateModal && (
        <ZenChangeFirmDateSidebarModal
          transaction={transaction}
          isOpen={showChangeFirmDateModal}
          onClose={() => dispatch(toggleShowChangeFirmDateModal(false))}
        />
      )}
      <ZenSimpleModal
        title='Transaction Explanation'
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size='large'
      >
        <div className='m-6 flex flex-col'>
          {!transactionExplanationText?.transactionExplanation &&
          isPayoutLoading ? (
            <DefaultLoader />
          ) : (
            <p className='font-zen-body text-base text-zen-dark-9'>
              {transactionExplanationText?.transactionExplanation}
            </p>
          )}
        </div>
      </ZenSimpleModal>
    </div>
  );
};

export default ZenTransactionInformation;
