import {
  faChevronRight,
  faClose,
  faEnvelope,
} from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { debounce, isEmpty } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { showSuccessToast } from '../../slices/ToastNotificationSlice';
import { fetchTCInfo } from '../../slices/TransactionCoordinatorSlice';
import { RootState, TransactionCoordinatorAccessEnum } from '../../types';
import {
  getFormatedTCAccessName,
  TCInvitationStatusEnum,
} from '../../utils/TransactionCoordinatorUtils';
import { Button } from '../commons/Button';
import { TextInput } from '../commons/TextInput';
import GeminiSidebarModalActionFooter from '../Gemini/Modal/GeminiSidebarModalActionFooter';
import ResourceContainer from '../ResourceContainer';
import ZenAvatar from '../Zen/ZenAvatar';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import GeminiConfirmationModal from './GeminiConfirmationModal';
import InviteTCForm from './InviteTCForm';
import SignAssistandAmendmentModal from './SignAssistandAmendmentModal';
import TCAccessConfirmationCard from './TCAccessConfirmationCard';
import { TCInfo } from './TCInfoSection';
import TCInvitationStatusPill from './TCInvitationStatusPill';
import TCSelectCardsContainer from './TCSelectCardsContainer';
import TCTransactionsAccessCard, {
  schema,
  TransactionCoordinatorFromData,
} from './TCTransactionsAccessCard';

const TransactionCoordinators: TCInfo[] = [
  {
    id: '1',
    name: 'John Doe asd  asd as da sda',
    email: 'john1@email.com',
    phone: '1234567890',
  },
  {
    id: '2',
    name: 'Johnny',
    email: 'john2@email.com',
    phone: '1234567890',
  },
  {
    id: '3',
    name: 'John Doe',
    email: 'john3@email.com',
    avatar:
      'avatar/c6aa7000-a9d9-4e3d-b9a2-cd143fb8dc37_b5808041-8253-4bf1-9847-f1821c2d2cd2.png',
    phone: '1234567890',
  },
];

interface InviteTCSidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const InviteTCSidebar: FC<InviteTCSidebarProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state: RootState) => state.transactionCoordinator,
  );

  const {
    control,
    handleSubmit,
    setValue,
    watch,
  } = useForm<TransactionCoordinatorFromData>({
    defaultValues: {
      accessType:
        TransactionCoordinatorAccessEnum.ALL_OPEN_LISTINGS_AND_TRANSACTIONS,
    },
    resolver: zodResolver(schema),
  });

  const [searchText, setSearchText] = useState<string>('');
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState<TCInfo[]>();
  const [selectedTC, setSelectedTC] = useState<TCInfo>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isTcFromTeam, setIsTcFromTeam] = useState<boolean>();
  const [isOpenSignAmendmentModal, setIsOpenSignAmendmentModal] = useState(
    false,
  );
  const [isInviteSent, setIsInviteSent] = useState(false);
  const [showInviteForm, setShowInviteForm] = useState(false);

  const { accessType, date } = watch();
  const isTcSelected = !isEmpty(selectedTC);
  const showInviteButton =
    !isInviteSent && ((isTcSelected && isTcFromTeam) || !isTcSelected);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchTc = useCallback(
    debounce((searchText: string) => {
      if (!searchText) {
        setSearchResults(undefined);
        setShowSearchResults(false);
        return;
      }
      const filtertedTCs = TransactionCoordinators.filter(
        (tc) =>
          tc.name.toLowerCase().includes(searchText.toLowerCase()) ||
          tc.email?.toLowerCase().includes(searchText.toLowerCase()) ||
          tc.phone?.toLowerCase().includes(searchText.toLowerCase()),
      );
      setSearchResults(filtertedTCs);
      setShowSearchResults(true);
    }, 300),
    [],
  );

  const handleSeachTextChange = (text: string) => {
    setSearchText(text);
    debouncedSearchTc(text);
  };

  const handleClearSearch = () => {
    setSearchText('');
    setSearchResults(undefined);
    setShowSearchResults(false);
    setSelectedTC(undefined);
    setIsInviteSent(false);
    setIsTcFromTeam(undefined);
  };

  const handleTcSelectFromSearch = (tc: TCInfo) => {
    setIsTcFromTeam(false);
    setSelectedTC(tc);
    setShowSearchResults(false);
    setIsInviteSent(false);
  };

  const handleNext = handleSubmit(() => {
    setShowConfirmationModal(true);
  });

  const onConfirm = () => {
    setShowConfirmationModal(false);

    if (isTcFromTeam) {
      dispatch(fetchTCInfo(selectedTC?.id!));
      dispatch(
        showSuccessToast(
          'Success!',
          `You have invited ${selectedTC?.name} to be your Transaction Coordinator`,
        ),
      );
      setSelectedTC(undefined);
    } else {
      setIsOpenSignAmendmentModal(true);
    }
  };

  const handleSendInvite = () => {
    setIsInviteSent(true);
    setIsOpenSignAmendmentModal(false);
  };

  useEffect(() => {
    dispatch(fetchTCInfo('1'));
  }, [dispatch]);

  return (
    <ZenSidebarModal title='Invitation' isOpen={isOpen} onClose={onClose}>
      <ResourceContainer
        resourceName='Transaction Coordinator'
        isEmpty={false}
        loading={isLoading}
      >
        {showInviteForm ? (
          <InviteTCForm onCancel={() => setShowInviteForm(false)} />
        ) : (
          <>
            <div className='bg-regent-100 px-5 py-6 font-inter border-b border-regent-300'>
              <p className='text-primary-dark text-sm sm:text-base font-normal'>
                Search for an Existing Transaction Coordinator
              </p>
              <div className='relative'>
                <TextInput
                  icon={
                    <FontAwesomeIcon
                      icon={faSearch}
                      className='text-rezen-blue-600'
                    />
                  }
                  rightSection={
                    searchText && (
                      <FontAwesomeIcon
                        icon={faClose}
                        className='cursor-pointer'
                        onClick={handleClearSearch}
                      />
                    )
                  }
                  placeholder='Start typing a name, email, phone...'
                  className='mt-2'
                  value={searchText}
                  onChange={(e) => handleSeachTextChange(e.currentTarget.value)}
                  onFocus={(e) => {
                    if (e.currentTarget.value) {
                      handleSeachTextChange(e.currentTarget.value);
                    }
                  }}
                  onBlur={() =>
                    setTimeout(() => setShowSearchResults(false), 100)
                  }
                />
                {showSearchResults && (
                  <div className='absolute z-50 top-full bg-white w-full shadow-xl rounded-lg'>
                    {searchResults?.map((tc) => (
                      <div
                        key={tc.name}
                        className='px-4 py-3 border-b border-grey-200 text-sm sm:text-base flex flex-wrap items-center overflow-hidden cursor-pointer'
                        onClick={() => handleTcSelectFromSearch(tc)}
                      >
                        <p className='text-primary-dark font-normal flex-shrink-0'>
                          {tc.name}
                        </p>
                        {tc.email && (
                          <div className='flex items-center'>
                            <span className='block w-1.5 h-1.5 bg-grey-300 rounded-full mx-2 flex-shrink-0' />
                            <p className='text-grey-500 font-light'>
                              {tc.email}
                            </p>
                          </div>
                        )}
                        {tc.phone && (
                          <div className='flex items-center'>
                            <span className='block w-1.5 h-1.5 bg-grey-300 rounded-full mx-2 flex-shrink-0' />
                            <p className='text-grey-500 font-light'>
                              {tc.phone}
                            </p>
                          </div>
                        )}
                      </div>
                    ))}
                    <div className='text-sm sm:text-base flex flex-wrap px-4 py-3 bg-grey-100'>
                      <p className='text-primary-dark font-normal'>
                        Can&apos;t find who you&apos;re looking for?
                      </p>
                      <Button
                        variant='link'
                        color='blue'
                        value='Invite via Email'
                        leftIcon={
                          <FontAwesomeIcon icon={faEnvelope} size='xs' />
                        }
                        className='py-0'
                        onClick={() => setShowInviteForm(true)}
                      >
                        Invite via Email
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {showInviteButton && (
                <Button
                  variant='link'
                  color='blue'
                  className='ml-auto'
                  rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                  disabled={isTcSelected}
                  onClick={() => setShowInviteForm(true)}
                >
                  Are they new to real? E-mail them an invite
                </Button>
              )}
            </div>
            {isTcSelected && isTcFromTeam === false ? (
              <form
                className='font-inter p-4 m-4 border border-regent-300 rounded-lg mb-28'
                onSubmit={handleNext}
              >
                <div className='flex items-center gap-2 justify-between'>
                  <div className='flex items-center gap-2'>
                    <ZenAvatar
                      name={selectedTC?.name!}
                      imageUrl={selectedTC?.avatar}
                      size='lg'
                    />
                    <div className='text-xs sm:text-sm'>
                      <p className='text-primary-dark font-medium line-clamp-1'>
                        {selectedTC?.name}
                      </p>
                      <p className='text-grey-600 font-light line-clamp-1'>
                        {selectedTC?.email}
                      </p>
                    </div>
                  </div>
                  {isInviteSent && (
                    <TCInvitationStatusPill
                      status={TCInvitationStatusEnum.INVITATION_SENT}
                    />
                  )}
                </div>
                {!isInviteSent && (
                  <div className='mt-4'>
                    <TCTransactionsAccessCard
                      control={control}
                      setValue={setValue}
                      selectedAccessType={accessType}
                    />
                    <GeminiSidebarModalActionFooter
                      submitButtonText='Next'
                      onClose={() => setSelectedTC(undefined)}
                      isDisabled={isEmpty(accessType)}
                      isSubmitting={false}
                      alignRight
                    />
                  </div>
                )}
              </form>
            ) : (
              <>
                <div className='px-5 pt-6 font-inter'>
                  <p className='text-primary-dark text-sm sm:text-base font-medium'>
                    Transaction Coordinators working with Adams and Sons
                    playwright team
                  </p>
                  <p className='text-grey-600 text-xs sm:text-sm font-light mt-2'>
                    The following Transaction Coordinator(s) work with your
                    team. Select one from the list below to give them access to
                    your listings and transactions.
                  </p>
                </div>
                <TCSelectCardsContainer
                  transactionCoordinators={TransactionCoordinators}
                  control={control}
                  setValue={setValue}
                  accessType={accessType}
                  onSubmit={handleNext}
                  onCancel={() => setSelectedTC(undefined)}
                  onSelect={(tc) => {
                    setSelectedTC(tc);
                    setIsTcFromTeam(true);
                  }}
                />
              </>
            )}
            <GeminiConfirmationModal
              title='Transaction Access Confirmation'
              isOpen={showConfirmationModal}
              onClose={() => setShowConfirmationModal(false)}
              size='large'
              onConfirm={onConfirm}
            >
              <TCAccessConfirmationCard
                tcId=''
                selectedAccessPreference={getFormatedTCAccessName({
                  accessType: TransactionCoordinatorAccessEnum[accessType],
                  date,
                })}
                tcName={selectedTC?.name!}
                {...(!isTcFromTeam && {
                  specialInfo:
                    'You need to sign the Assistant Amendment to send the invite',
                })}
              />
            </GeminiConfirmationModal>
            <SignAssistandAmendmentModal
              tcId=''
              isOpen={isOpenSignAmendmentModal}
              onClose={() => setIsOpenSignAmendmentModal(false)}
              handleSendInvite={handleSendInvite}
              submitButtonText='Send Invite'
            />
          </>
        )}
      </ResourceContainer>
    </ZenSidebarModal>
  );
};

export default InviteTCSidebar;
