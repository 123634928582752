import { useGemini } from '../hooks/useGemini';
import { cn } from '../utils/classUtils';
import Hover from './Hover';
import useShowLeoTrigger from './Zen/Leo/useShowLeoTrigger';

export interface ZenExternalLinkSideBarItemProps {
  icon: React.ReactElement;
  label: string;
  linkTo?: string;
  collapsed?: boolean;
}

const ZenExternalLinkSideBarItem: React.FC<ZenExternalLinkSideBarItemProps> = ({
  icon,
  label,
  linkTo = '/',
  collapsed = false,
}) => {
  const isGeminiEnabled = useGemini();
  const { showNeoLeoPanel } = useShowLeoTrigger();

  return (
    <Hover
      config={{
        trigger: collapsed ? 'hover' : null,
        placement: 'right',
      }}
      hoverComponent={
        <p
          className={cn(
            'px-2 whitespace-pre text-sm',
            isGeminiEnabled ? 'font-zen-body-2' : 'font-zen-body',
          )}
        >
          {label}
        </p>
      }
      variant='dark'
    >
      <a
        href={linkTo}
        className={cn(
          'flex flex-row items-center px-2 text-white group rounded-xl',
          collapsed
            ? 'justify-center py-1 -my-1'
            : 'justify-start px-2 py-1 my-2',
          isGeminiEnabled
            ? 'font-zen-body-2 hover:bg-regent-900 font-normal'
            : 'font-zen-body hover:bg-zen-dark-22 font-semibold',
          {
            'w-10 h-10 rounded-xl py-2': showNeoLeoPanel && collapsed,
          },
        )}
        target='_blank'
        rel='noreferrer'
      >
        {icon && (
          <span
            className={cn(
              'py-1 flex items-center group-hover:text-zen-primary-6',
              collapsed ? 'pr-0 text-xl' : 'pr-2 text-sm',
              isGeminiEnabled
                ? 'group-hover:text-aqua'
                : 'group-hover:text-zen-primary-6',
            )}
          >
            {icon}
          </span>
        )}
        {!collapsed && (
          <span className='items-center pl-1 text-sm'>{label}</span>
        )}
      </a>
    </Hover>
  );
};

export default ZenExternalLinkSideBarItem;
