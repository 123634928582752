import { Button } from '../commons/Button';
import { Modal } from '../commons/Modal';

interface OptInModalProps {
  onClose(): void;
  onOptIn(): void;
  disabled?: boolean;
  opened: boolean;
}

const OptInModal: React.FC<OptInModalProps> = ({
  onClose,
  onOptIn,
  disabled,
  opened,
}) => {
  return (
    <Modal
      size='670px'
      opened={opened}
      onClose={onClose}
      aria-label='optin-modal'
    >
      <Modal.Header className='bg-white border-b border-[#ccc] text-center'>
        <span className='pl-6 text-lg font-medium'>
          Opt-in to receive text message
        </span>
      </Modal.Header>
      <Modal.Content className='p-6 text-center'>
        To receive transactional text messages from Real or its affiliates at
        the business phone number you provided in your profile, please select
        &quot;Yes&quot;.
      </Modal.Content>
      <Modal.Footer className='border-t-0'>
        <Button
          onClick={onClose}
          className='w-full md:w-auto md:px-20'
          variant='outline'
        >
          No
        </Button>
        <Button
          onClick={onOptIn}
          className='w-full md:w-auto md:px-20'
          disabled={disabled}
          loading={disabled}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OptInModal;
