import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateTaxFormCanada,
  CreateTaxFormW8BEN,
  CreateTaxFormW8ECI,
  CreateTaxFormW9,
  PaymentDetailsControllerApi,
  TaxFormAcknowledgementResponse,
  TaxFormResponse,
  TaxFormsResponse,
  UpdateTaxFormCanada,
  UpdateTaxFormW8BEN,
  UpdateTaxFormW8ECI,
  UpdateTaxFormW9,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import {
  AppDispatch,
  AppThunk,
  AsyncResponse,
  TaxInformationState,
} from '../types';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from './ErrorSlice';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { performAsyncRequest } from './utils/SliceUtil';

interface Options {
  silent: boolean;
}

export const initialState: TaxInformationState = {
  taxFormResponseByAgentId: {},
  taxFormAcknowledgementById: {},
};

const TaxInformationSlice = createSlice({
  name: 'taxInformation',
  initialState,
  reducers: {
    saveTaxForms(
      state,
      action: PayloadAction<
        AsyncResponse<TaxFormsResponse, { agentId: string }>
      >,
    ) {
      const agentId = action.payload.additionalProps?.agentId!;
      state.taxFormResponseByAgentId[agentId] = action.payload;
    },
    addTaxForm(
      state,
      action: PayloadAction<{ agentId: string; data: TaxFormResponse }>,
    ) {
      const agentId = action.payload.agentId;
      const newTaxForms = [
        ...(state.taxFormResponseByAgentId[agentId]?.data?.taxForms || []),
        action.payload.data,
      ];
      state.taxFormResponseByAgentId[agentId] = {
        name: 'tax form',
        loading: false,
        data: { taxForms: newTaxForms },
      };
    },
    updateTaxForm(
      state,
      action: PayloadAction<{ agentId: string; data: TaxFormResponse }>,
    ) {
      const agentId = action.payload.agentId;
      const { data } = state.taxFormResponseByAgentId[agentId] || {};
      const taxFormIndex = data?.taxForms?.findIndex(
        (form) => form.id === action.payload.data.id,
      );

      if (typeof taxFormIndex !== 'undefined' && taxFormIndex !== -1) {
        state.taxFormResponseByAgentId[agentId]!.data!.taxForms![taxFormIndex] =
          action.payload.data;
      }
    },
    deleteTaxForm(
      state,
      action: PayloadAction<{ agentId: string; taxFormId: string }>,
    ) {
      const agentId = action.payload.agentId;
      if (state.taxFormResponseByAgentId[agentId]?.data) {
        state.taxFormResponseByAgentId[
          agentId
        ]!.data!.taxForms = state.taxFormResponseByAgentId[
          agentId
        ]!.data?.taxForms?.filter(
          (form) => form.id !== action.payload.taxFormId,
        );
      }
    },
    saveTaxFormAcknowledgement(
      state,
      action: PayloadAction<
        AsyncResponse<TaxFormAcknowledgementResponse, { taxFormId: string }>
      >,
    ) {
      const taxFormId = action.payload.additionalProps?.taxFormId!;
      state.taxFormAcknowledgementById[taxFormId] = action.payload;
    },
    removeTaxFormAcknowledgementStatus(state) {
      state.taxFormAcknowledgementById = {};
    },
  },
});

export const {
  saveTaxForms,
  addTaxForm,
  updateTaxForm,
  deleteTaxForm,
  saveTaxFormAcknowledgement,
  removeTaxFormAcknowledgementStatus,
} = TaxInformationSlice.actions;

export const getTaxForms = (userId: string): AppThunk => async (dispatch) => {
  const fetch = () =>
    new PaymentDetailsControllerApi(getYentaConfiguration()).getTaxForms(
      userId,
    );

  const res = await performAsyncRequest(
    dispatch as AppDispatch,
    'tax forms',
    saveTaxForms,
    fetch,
    {
      errorMetadata: {
        user: { id: userId },
      },
      skipAuthDatadog: true,
      additionalProps: { agentId: userId },
    },
  );

  if (!!res?.data?.taxForms?.length) {
    // get latest tax form by signed date
    const latestTaxForm = res?.data?.taxForms?.reduce((prev, current) =>
      prev?.signedAt! > current?.signedAt! ? prev : current,
    );
    dispatch(
      getTaxAcknowledgementStatus({
        userId,
        taxFormId: latestTaxForm?.id!,
      }),
    );
  } else {
    dispatch(removeTaxFormAcknowledgementStatus());
  }
};

export const getTaxAcknowledgementStatus = ({
  userId,
  taxFormId,
}: {
  userId: string;
  taxFormId: string;
}): AppThunk => async (dispatch) => {
  const fetch = () =>
    new PaymentDetailsControllerApi(
      getYentaConfiguration(),
    ).getTaxFormAcknowledgement(userId, taxFormId);

  await performAsyncRequest(
    dispatch as AppDispatch,
    'tax forms acknowledgement',
    saveTaxFormAcknowledgement,
    fetch,
    {
      errorMetadata: {
        user: { id: userId },
      },
      skipAuthDatadog: true,
      additionalProps: { agentId: userId, taxFormId: taxFormId },
    },
  );
};

export const createTaxForm = (
  userId: string,
  taxFormCreateRequest:
    | CreateTaxFormCanada
    | CreateTaxFormW8BEN
    | CreateTaxFormW8ECI
    | CreateTaxFormW9,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new PaymentDetailsControllerApi(
      getYentaConfiguration(),
    ).createTaxForm(userId, taxFormCreateRequest);

    await dispatch(addTaxForm({ agentId: userId, data }));
    await dispatch(
      getTaxAcknowledgementStatus({ userId, taxFormId: data.id! }),
    );
    dispatch(showSuccessToast('Tax Form successfully created'));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create tax form', e, {
      taxFormCreateRequest,
    });
    dispatch(
      showErrorToast(
        'We had a problem creating tax form.',
        'Please try again in a few moments.',
      ),
    );
    return false;
  }
};

export const updateTaxFormInfo = (
  userId: string,
  formId: string,
  taxFormUpdateRequest:
    | UpdateTaxFormCanada
    | UpdateTaxFormW8BEN
    | UpdateTaxFormW8ECI
    | UpdateTaxFormW9,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new PaymentDetailsControllerApi(
      getYentaConfiguration(),
    ).patchTaxForm(userId, formId, taxFormUpdateRequest);

    await dispatch(updateTaxForm({ agentId: userId, data }));
    await dispatch(getTaxAcknowledgementStatus({ userId, taxFormId: formId }));
    dispatch(showSuccessToast('Tax Form successfully updated.'));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update tax form', e, {
      user: { userId },
      taxForm: { formId },
      taxFormUpdateRequest,
    });
    dispatch(
      showErrorToast(
        'We had a problem updating tax form.',
        'Please try again in a few moments.',
      ),
    );
    return false;
  }
};

export const archiveTaxForm = (
  userId: string,
  formId: string,
  options: Options = { silent: false },
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new PaymentDetailsControllerApi(
      getYentaConfiguration(),
    ).archiveTaxForm(userId, formId);

    dispatch(deleteTaxForm({ agentId: userId, taxFormId: formId }));
    if (!options.silent) {
      dispatch(showSuccessToast('Tax Form deleted successfully.'));
    }
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to delete tax form', e, {
      taxForm: { id: formId },
      user: { id: userId },
    });
    dispatch(
      showErrorToast(
        'We had a problem deleting the tax form',
        'Please try again in a few moments.',
      ),
    );
    return false;
  }
};

export default TaxInformationSlice.reducer;
