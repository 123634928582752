import { EventSourcePolyfill } from 'event-source-polyfill';
import { flatten, groupBy, map } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScrollDown from '../../../assets/img/scroll-down.png';
import useAgentsInfoFromKeymakerId from '../../../hooks/useAgentInfosFromKeymakerId';
import useAgentsInfo from '../../../hooks/useAgentsInfo';
import UseBrokerQueue from '../../../hooks/useBrokerQueue';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import {
  CommentDto,
  MentionBlock,
  MentionBlockMentionTypeEnum,
  QueryCommentsRs,
  RezenObjectTypeEnum,
} from '../../../openapi/yada';
import { useMarkMessageAsRead } from '../../../query/inbox/useInbox';
import { useFetchAgentsInfoByIds } from '../../../query/transaction/useUser';
import ErrorService from '../../../services/ErrorService';
import { markBrokerQueueMessageAsRead } from '../../../slices/BrokerQueueSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { AppDispatch, FeatureFlagTypeEnum, RootState } from '../../../types';
import { getAuthCookie } from '../../../utils/AuthUtils';
import { cn } from '../../../utils/classUtils';
import { getExcludedAutoTagMentionCommentBlocks } from '../../../utils/CommentUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { isGeminiUIEnabled } from '../../../utils/switchToGeminiUtils';
import Button from '../../Button';
import GeminiPreviousLatestPill from '../../Gemini/GeminiPreviousLatestPill';
import GeminiRichCommentRow from '../../Gemini/Transaction/Comments/GeminiRichCommentRow';
import IconButton from '../../IconButton';
import ResourceContainer from '../../ResourceContainer';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ZenCommentWidget, {
  CommentOnSubmitParams,
} from '../../ZenCommentWidget/ZenCommentWidget';
import ZenRichCommentRow, {
  BlockItem,
} from '../../ZenCommentWidget/ZenRichCommentRow';
import ZenCommentSectionLayout from './ZenCommentSectionLayout';

export interface MentionSource {
  id: string;
  value: string;
  emailAddress?: string;
  mentionType: MentionBlockMentionTypeEnum;
}

interface ZenCommentSectionProps {
  sseURL: string;
  fetchComments(
    pageStart: string | undefined,
  ): QueryCommentsRs | Promise<QueryCommentsRs>;
  onSubmit(data: CommentOnSubmitParams): Promise<CommentDto | void>;
  onEdit(data: CommentOnSubmitParams, commentId: string): Promise<CommentDto>;
  onDelete(comment: CommentDto): void;
  getMentions?(searchTerm?: string): MentionSource[];
  isReferred?: boolean;
  checklist?: boolean;
  htmlElementScrollableParent: HTMLElement;
  getPublicUserInfo?: boolean;
  containerType: RezenObjectTypeEnum;
  containerId: string;
  powerAudit?: boolean;
  hideVoiceMemo?: boolean;
  hideMentions?: boolean;
  EmptyComponent?: React.ReactElement;
}

const ZenCommentSection: React.FC<ZenCommentSectionProps> = ({
  sseURL,
  fetchComments,
  onDelete,
  onEdit,
  onSubmit,
  getMentions,
  isReferred = false,
  checklist = false,
  htmlElementScrollableParent,
  getPublicUserInfo = false,
  containerType,
  containerId,
  powerAudit = false,
  hideVoiceMemo,
  hideMentions,
  EmptyComponent,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    agentByKeymakerId,
    keymakerCurrentUserId,
    mentionableGroups,
  } = useSelector((state: RootState) => ({
    agentByKeymakerId: state.userIds.agentByKeymakerId,
    keymakerCurrentUserId: state.auth.keymakerCurrentUser?.id,
    mentionableGroups:
      state.transaction.commentsMentionUsers?.mentionableGroups,
  }));
  const [editComment, setEditComment] = useState<CommentDto>();
  const [deleteComment, setDeleteComment] = useState<CommentDto>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [comments, setComments] = useState<CommentDto[]>([]);
  const [nextPageCursor, setNextPageCursor] = useState<string | null>();
  const [
    latestCommentsButtonVisible,
    setLatestCommentsButtonVisible,
  ] = useState<boolean>(false);

  const commentsGroupByDate = groupBy(comments, (comment) =>
    DateTime.fromMillis(comment.createdAt!).toFormat('LL/dd/yyyy'),
  );
  const { isBrokerQueueActive } = UseBrokerQueue();

  const handleEdit = async (data: CommentOnSubmitParams, commentId: string) => {
    try {
      await onEdit(data, commentId);
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to edit comment', e, {
        comment: data,
      });
    }
  };

  const handleDelete = async (comment: CommentDto) => {
    setIsDeleting(true);
    try {
      await onDelete(comment);
      const allComments = comments.filter((c) => c.id !== comment.id);
      setComments(allComments);
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to delete a comment', e);
    } finally {
      setIsDeleting(false);
      setDeleteComment(undefined);
    }
  };

  const scrollToBottom = useCallback(async () => {
    htmlElementScrollableParent?.scrollTo(
      0,
      htmlElementScrollableParent.scrollHeight!,
    );
  }, [htmlElementScrollableParent]);

  const scrollToTop = useCallback(async () => {
    htmlElementScrollableParent?.scrollTo(0, 0);
  }, [htmlElementScrollableParent]);

  const handleSubmit = async (data: CommentOnSubmitParams) => {
    try {
      await onSubmit(data);
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to post a comment', e);
    }
  };

  const fetchPreviousComments = async () => {
    const prevScrollTop = htmlElementScrollableParent?.scrollTop!;
    const prevScrollHeight = htmlElementScrollableParent?.scrollHeight!;
    await handleFetch();
    const newScrollHeight = htmlElementScrollableParent?.scrollHeight!;
    htmlElementScrollableParent?.scrollTo({
      top: newScrollHeight - prevScrollHeight + prevScrollTop,
    });
  };

  const handleFetch = useCallback(async () => {
    if (nextPageCursor === null) {
      return;
    }

    setIsLoading(true);
    try {
      const queryCommentRes = await fetchComments(nextPageCursor);
      setComments([
        ...(queryCommentRes.comments?.reverse() || []),
        ...comments,
      ]);
      setNextPageCursor(queryCommentRes.nextPage);
    } catch (e) {
      ErrorService.notify('Unable to fetch comments', e);
    } finally {
      setIsLoading(false);
    }
  }, [comments, fetchComments, nextPageCursor]);

  const { mutate: markAsRead } = useMarkMessageAsRead(
    keymakerCurrentUserId!,
    containerType,
  );

  const isRoarFullRelease = useFeatureFlag(
    FeatureFlagTypeEnum.ROAR_FULL_RELEASE_BOLT,
  );

  useEffect(() => {
    if (isRoarFullRelease && comments?.length) {
      markAsRead({
        userId: keymakerCurrentUserId!,
        targetIds: [containerId],
        targetType: containerType,
      });
    }
  }, [
    comments?.length,
    containerId,
    containerType,
    isRoarFullRelease,
    keymakerCurrentUserId,
    markAsRead,
  ]);

  useEffect(() => {
    if (!!containerId) {
      dispatch(
        markBrokerQueueMessageAsRead(
          keymakerCurrentUserId!,
          containerType,
          containerId,
        ),
      );
    }
  }, [containerId, containerType, dispatch, keymakerCurrentUserId]);

  useEffect(() => {
    handleFetch().then(() => {
      if (isBrokerQueueActive) {
        scrollToTop();
      } else {
        scrollToBottom();
      }
    });
    htmlElementScrollableParent?.addEventListener('scroll', () =>
      showLatestComments(),
    );

    return () => {
      htmlElementScrollableParent?.removeEventListener('scroll', () =>
        showLatestComments(),
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerId]);

  const showLatestComments = () => {
    const scrolled =
      htmlElementScrollableParent.scrollHeight -
      htmlElementScrollableParent.scrollTop;
    if (scrolled >= 1600) {
      setLatestCommentsButtonVisible(true);
    } else {
      setLatestCommentsButtonVisible(false);
    }
    // setScrolledHeight(htmlElementScrollableParent.scrollTop);
  };

  const agentKeyMakerIds = useMemo(() => {
    const keymakerIds: string[] = [];

    comments.forEach((comment) => {
      keymakerIds.push(comment?.createdById!);
      comment.readReceipts?.forEach((receipt) => {
        keymakerIds.push(receipt?.readerId!);
      });
    });

    return keymakerIds;
  }, [comments]);

  const allAgentIds = useMemo(() => {
    const agentIds: string[] = [];

    comments.forEach((comment) => {
      comment.reactions?.forEach((reaction) => {
        agentIds.push(reaction?.by);
      });
    });

    return agentIds;
  }, [comments]);

  useAgentsInfoFromKeymakerId(agentKeyMakerIds, getPublicUserInfo);

  useFetchAgentsInfoByIds(allAgentIds, getPublicUserInfo);

  const allCommentMentionsAgentIds = useMemo(
    () =>
      flatten(
        comments
          .filter(
            (c) =>
              c.richContent?.blocks?.filter((b) => b.type === 'MENTION') || [],
          )
          .map((c) =>
            flatten(c.richContent?.blocks?.map((b: MentionBlock) => b.userId!)),
          ),
      ).filter((a) => !!a),
    [comments],
  );

  useAgentsInfo(allCommentMentionsAgentIds, getPublicUserInfo);

  useEffect(() => {
    const eventSource = new EventSourcePolyfill(sseURL, {
      headers: { Authorization: `Bearer ${getAuthCookie()}` },
      heartbeatTimeout: 300000, // 5 min - keep alive
    });

    eventSource.onmessage = (event) => {
      const element = document.getElementById(
        containerType === RezenObjectTypeEnum.Conversation
          ? 'message-container'
          : 'main-page',
      );
      let scrollable = true;

      // don't auto scroll when scrollbar is not at the bottom
      if (
        element &&
        element.scrollHeight - element.clientHeight - element.scrollTop > 100
      ) {
        scrollable = false;
      }

      if (event.data.length) {
        const newComment = JSON.parse(event.data);
        setComments((prevComments) => {
          // Check if the comment already exists in the array
          const existingCommentIndex = prevComments.findIndex(
            (comment) => comment.id === newComment.id,
          );

          // If the comment exists, update it
          if (existingCommentIndex !== -1) {
            const updatedComments = [...prevComments];
            updatedComments[existingCommentIndex] = newComment;
            return updatedComments;
          }

          // If the comment does not exist, add it to the array
          return [...prevComments, newComment];
        });
      }

      if (scrollable) {
        scrollToBottom();
      }
    };

    eventSource.onerror = () => {
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, [containerId, containerType, scrollToBottom, sseURL]);

  const isGeminiFeatureFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.GEMINI_REDESIGN,
  );

  const geminiRedesignFlagEnabled =
    isGeminiFeatureFlagEnabled && isGeminiUIEnabled();

  const RichCommentRowComponent = geminiRedesignFlagEnabled
    ? GeminiRichCommentRow
    : ZenRichCommentRow;

  const commentCreatedTime = geminiRedesignFlagEnabled
    ? 'cccc, LL/dd/yyyy hh:mm a ZZZZ'
    : 'LL/dd/yyyy hh:mm a ZZZZ';

  return (
    <ZenCommentSectionLayout>
      <div className='flex flex-col mt-3'>
        <ResourceContainer
          isEmpty={!comments?.length && nextPageCursor === null}
          loading={isLoading && !comments.length}
          resourceName='Comment'
          EmptyComponent={EmptyComponent}
        >
          {nextPageCursor && (
            <div className='flex items-center w-full justify-center mb-4'>
              {geminiRedesignFlagEnabled ? (
                <GeminiPreviousLatestPill
                  pillType='previous'
                  onClick={() => fetchPreviousComments()}
                />
              ) : (
                <Button
                  type='outline'
                  label='Show previous comments'
                  className='shadow-xl text-sm hover:bg-blue-500 hover:text-white hover:opacity-100'
                  onClick={() => fetchPreviousComments()}
                  pill
                />
              )}
            </div>
          )}
          {map(commentsGroupByDate, (comments, index) => {
            return (
              <div
                key={[comments[0].createdAt, index].join(',')}
                className='flex flex-col'
              >
                <p
                  className={cn(
                    'text-sm text-center mt-1 mb-5',
                    geminiRedesignFlagEnabled
                      ? 'text-regent-600 font-inter'
                      : 'text-dark',
                  )}
                >
                  {DateTime.fromMillis(comments[0]?.createdAt! || 0).toFormat(
                    commentCreatedTime,
                  )}
                </p>
                {comments.map((comment) => {
                  const agentData = agentByKeymakerId[comment.createdById!];
                  const readReceipts = comment.readReceipts?.filter(
                    (readReceipt) =>
                      readReceipt.readerId !== comment?.createdById &&
                      readReceipt.readerId !== keymakerCurrentUserId,
                  )!;
                  const agentGroup = mentionableGroups?.find((group) =>
                    group.mentionableParticipants?.find(
                      (participant) =>
                        participant?.keymakerId === agentData?.keymakerId,
                    ),
                  );
                  const isEdit = editComment?.id === comment.id;

                  return (
                    <div
                      id={comment.id}
                      key={comment.id}
                      className={cn(isEdit && 'z-10')}
                    >
                      {isEdit ? (
                        <div className='pb-1.5'>
                          <ZenCommentWidget
                            editable
                            comment={
                              getExcludedAutoTagMentionCommentBlocks(
                                comment?.richContent?.blocks,
                              ) as BlockItem[]
                            }
                            onSubmit={(commentData) =>
                              handleEdit(commentData, comment.id!)
                            }
                            onEdit={() => setEditComment(undefined)}
                            getMentions={getMentions}
                            hideVoiceMemo={hideVoiceMemo}
                            hideMentions={hideMentions}
                          />
                        </div>
                      ) : (
                        <RichCommentRowComponent
                          key={comment.id}
                          comment={comment}
                          readReceipts={readReceipts}
                          role={
                            !!agentGroup?.groupName
                              ? agentGroup?.groupName
                              : capitalizeEnum(comment.readerRole!)
                          }
                          timestamp={comment.createdAt!}
                          blocks={
                            getExcludedAutoTagMentionCommentBlocks(
                              comment?.richContent?.blocks,
                            ) as BlockItem[]
                          }
                          refersTo={comment.refersTo}
                          fullName={`${agentData?.firstName} ${agentData?.lastName}`}
                          side={
                            comment.createdById === keymakerCurrentUserId
                              ? 'right'
                              : 'left'
                          }
                          onDelete={() => setDeleteComment(comment)}
                          onEdit={() => setEditComment(comment)}
                          isEdited={comment.edited}
                          avatarUrl={agentData?.avatar}
                          isReferred={isReferred}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </ResourceContainer>
        {latestCommentsButtonVisible && (
          <div className='flex text-center sticky left-0 right-0 bottom-44 w-full justify-center items-center z-10'>
            {geminiRedesignFlagEnabled ? (
              <GeminiPreviousLatestPill
                pillType='latest'
                onClick={() => scrollToBottom()}
              />
            ) : (
              <IconButton
                variant='pill'
                label='Latest comments'
                onClick={() => scrollToBottom()}
                leftIcon={
                  <img
                    src={ScrollDown}
                    className='h-3 w-3'
                    alt='Scroll down icon'
                  />
                }
                buttonStyle='bg-blue-500 rounded-2xl shadow-xl !px-4 !text-sm'
              />
            )}
          </div>
        )}
        <ZenCommentWidget
          onSubmit={handleSubmit}
          getMentions={getMentions}
          checklist={checklist}
          powerAudit={powerAudit}
          hideVoiceMemo={hideVoiceMemo}
          hideMentions={hideMentions}
        />
        <ZenConfirmationModal
          isOpen={!!deleteComment}
          isSubmitting={isDeleting}
          isDisabled={isDeleting}
          onClose={() => setDeleteComment(undefined)}
          variant='danger'
          confirmButtonText='Delete'
          onConfirm={() => handleDelete(deleteComment!)}
          title='Delete Comment?'
          subtitle='This comment will be deleted for everyone. Are you sure you want to delete it?'
        />
      </div>
    </ZenCommentSectionLayout>
  );
};

export default ZenCommentSection;
