import { faMicrophone } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { noop } from 'lodash';
import React from 'react';
import { MediaAudioBlock } from '../../../../openapi/yada';
import VoiceCallPlayer from './VoiceCallPlayer';
import VoiceCallTranscription from './VoiceCallTranscription';

type VoiceMemoProps = {
  audioBlock: MediaAudioBlock;
};

const VoiceMemo: React.FC<VoiceMemoProps> = ({ audioBlock }) => {
  return (
    <div className='bg-white font-inter w-full p-4 border border-grey-200 rounded-lg space-y-4'>
      <div className='flex justify-end'>
        <div className='flex items-center space-x-1.5 bg-regent-200 text-grey-500 rounded-md px-2 py-1.5'>
          <FontAwesomeIcon icon={faMicrophone} fontSize={14} />
          <span className='text-sm'>VOICE MEMO</span>
        </div>
      </div>
      {!!audioBlock.url && (
        <VoiceCallPlayer
          url={audioBlock?.url!}
          duration={audioBlock?.durationSecs!}
          // @ts-expect-error
          id={audioBlock?.id}
        />
      )}
      <div className='-mx-1'>
        <VoiceCallTranscription
          recordingBlock={audioBlock}
          showTranscriptionDetails={noop}
        />
      </div>
    </div>
  );
};

export default React.memo(VoiceMemo);
