import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk, AsyncResponse, CDAFormState } from '../types';
import ErrorService from '../services/ErrorService';
import {
  CommissionDocumentControllerApi,
  CommissionDocumentResponse,
} from '../openapi/arrakis';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import Logger from '../utils/Logger';
import { showApiErrorModal } from './ErrorSlice';
import { performAsyncRequest } from './utils/SliceUtil';

export const initialState: CDAFormState = {
  cdaFormDetailResponse: {
    loading: false,
    name: 'Commission Disbursement Authorization',
  },
  approvedCDADetail: null,
};

const CDAFormSlice = createSlice({
  name: 'cdaForm',
  initialState,
  reducers: {
    saveCDAFormDetailResponse(
      state,
      action: PayloadAction<AsyncResponse<CommissionDocumentResponse>>,
    ) {
      state.cdaFormDetailResponse = action.payload;
    },
    saveApprovedCDADetail(
      state,
      action: PayloadAction<CommissionDocumentResponse | null>,
    ) {
      state.approvedCDADetail = action.payload;
    },
  },
});

export const {
  saveCDAFormDetailResponse,
  saveApprovedCDADetail,
} = CDAFormSlice.actions;

export const fetchCDAFormDetail = (
  id: string,
): AppThunk<Promise<CommissionDocumentResponse | undefined>> => async (
  dispatch,
) => {
  const fetch = () =>
    new CommissionDocumentControllerApi(
      getArrakisConfiguration(),
    ).getCommissionDocumentById(id);

  const res = await performAsyncRequest(
    dispatch as AppDispatch,
    'Commission Disbursement Authorization',
    saveCDAFormDetailResponse,
    fetch,
  );

  // Todo: Remove this console.log
  // Ticket: RV2-32286 Datadog Handled Error: Unable to generate trade record sheet - Request failed with status code 500
  // Sometimes the response is empty but its not getting logged in Arrakis Datadog
  Logger.log(`[CDAFormSlice] fetchCDAFormDetail res=${JSON.stringify(res)}`);

  if (!res) {
    return undefined;
  }

  return res.data;
};

export const fetchApprovedCDAForTransaction = (
  id: string,
): AppThunk<Promise<CommissionDocumentResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new CommissionDocumentControllerApi(
      getArrakisConfiguration(),
    ).getCommissionDocumentByTransactionId(id);
    dispatch(saveApprovedCDADetail(data));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch Commission Document', e);
    return undefined;
  }
};

export default CDAFormSlice.reducer;
