import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, LinkProps } from 'react-router-dom';

interface WalletTotalValueCardProps {
  label: string;
  amount: string;
  children: React.ReactNode;
  linkProps: LinkProps;
}

const WalletTotalValueCard: React.FC<WalletTotalValueCardProps> = ({
  amount,
  linkProps,
  children,
  label,
}) => {
  return (
    <div className='w-full py-3.5 px-4 gap-2.5 flex flex-col items-start bg-regent-900 rounded-xl'>
      <div className='font-inter text-lg font-medium flex items-center justify-center'>
        <span>{label}</span>
        <Link {...linkProps}>
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            className='text-aqua text-sm ml-1.5 mb-0.5'
          />
        </Link>
      </div>
      <p className='w-48 font-poppins font-semibold text-3xl text-avatar-blue-200 truncate'>
        {amount}
      </p>
      {children}
    </div>
  );
};

export default WalletTotalValueCard;
