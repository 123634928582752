import { FieldErrors } from 'react-hook-form-v6/dist/types/errors';
import { Controller, UseControllerProps } from 'react-hook-form-v7';
import { FieldValues } from 'react-hook-form-v6/dist/types';
import classNames from 'classnames';
import FormErrorMessage from './FormErrorMessage';

interface ControlledSwitchInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends UseControllerProps<TFieldValues> {
  label: string;
  errors?: FieldErrors<TFieldValues>;
}

const ControlledSwitchInputV7: React.FC<ControlledSwitchInputProps> = ({
  control,
  name,
  label,
  defaultValue,
}) => {
  return (
    <div className='space-y-1'>
      <Controller
        control={control}
        name={name}
        shouldUnregister={false}
        defaultValue={defaultValue || false}
        render={({
          field: { onChange, value, name },
          fieldState: { error },
        }) => (
          <div
            className='flex flex-row space-x-3 cursor-pointer items-center justify-between'
            data-testid={label}
            onClick={() => onChange(!value)}
          >
            <div className='flex items-center'>
              <label className='mr-2' htmlFor={name}>
                {label}
              </label>
              <button
                type='button'
                className={classNames(
                  'relative inline-flex flex-shrink-0 h-5 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ',
                  value ? 'bg-primary-blue' : 'bg-gray-200',
                )}
              >
                <span
                  className={classNames(
                    'translate-x-0 pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    value ? 'translate-x-6' : 'translate-x-0',
                  )}
                >
                  <span
                    className={classNames(
                      'opacity-100 ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                      value
                        ? 'opacity-0 ease-out duration-100'
                        : 'opacity-100 ease-in duration-200',
                    )}
                  />
                  <span
                    className={classNames(
                      'opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                      value
                        ? 'opacity-100 ease-in duration-200'
                        : 'opacity-0 ease-out duration-100',
                    )}
                  />
                </span>
              </button>
            </div>
            {error && <FormErrorMessage message={error.message} />}
          </div>
        )}
      />
    </div>
  );
};

export default ControlledSwitchInputV7;
