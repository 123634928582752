import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faMessage, faPhone } from '@fortawesome/pro-light-svg-icons';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDisclosure } from '@mantine/hooks';
import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MemberIdTypeEnum } from '../../../openapi/yada';
import {
  AdministrativeAreaResponseStateOrProvinceEnum,
  AgentControllerApi,
  OfficePreviewResponse,
} from '../../../openapi/yenta';
import { useFetchMultipleOfficeVisibleGroupsByIds } from '../../../query/office/useOffice';
import { useCreateConversation } from '../../../query/roar/useRoar';
import ErrorService from '../../../services/ErrorService';
import { fetchAgentDetail } from '../../../slices/AgentSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { AppDispatch, FeatureFlagTypeEnum, RootState } from '../../../types';
import { getConversationLink } from '../../../utils/ConversationUtils';
import { transactionDisbursementTypeToDisplayName } from '../../../utils/DisplayHelpers';
import { getYentaImageUrl } from '../../../utils/ImgUtils';
import { isBrokerTeam } from '../../../utils/OfficeUtils';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { formatPhoneNumber } from '../../../utils/StringUtils';
import AdminOnly from '../../auth/AdminOnly';
import ExcludeBroker from '../../auth/ExcludeBroker';
import CallModalStepper from '../../Conversation/CallModalStepper';
import FeatureFlagEnabledOnly from '../../FeatureFlagEnabledOnly';
import IconButton from '../../IconButton';
import ProfileImage from '../../ProfileImage';
import ZenSimpleConfirmationModal from '../../Zen/Modal/ZenSimpleConfirmationModal';
import ZenIconText from '../../Zen/ZenIconText';

interface Props {
  agentId: string;
  offices: OfficePreviewResponse[];
  officeState: AdministrativeAreaResponseStateOrProvinceEnum;
}
const ZenOfficeBroker: React.FC<Props> = ({
  agentId,
  offices,
  officeState,
}) => {
  const [deletingOffice, setDeletingOffice] = useState<OfficePreviewResponse>();
  const dispatch: AppDispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [callModalOpen, callModalActions] = useDisclosure(false);
  const officeIdRef = useRef<string>('');

  const removeOfficeById = async (id: string) => {
    setIsSubmitting(true);
    try {
      await new AgentControllerApi(getYentaConfiguration()).removeOffice(
        agentId,
        id,
      );
      dispatch(fetchAgentDetail(agentId));
      dispatch(showSuccessToast('Office removed successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to remove the office', e, {
        agent: { id: agentId },
        office: { id },
      });
      dispatch(
        showErrorToast(
          'We had a problem removing the office',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      setDeletingOffice(undefined);
      setIsSubmitting(false);
    }
  };

  const userDetails = useSelector((state: RootState) => state.auth.userDetail);

  const officeIds = useMemo(() => offices.map((office) => office.id!), [
    offices,
  ]);

  const {
    data,
    isLoading: isFetchingGroups,
  } = useFetchMultipleOfficeVisibleGroupsByIds(officeIds);

  const { mutate, isLoading } = useCreateConversation();

  const groupId = data[officeIdRef.current]?.groups?.find(isBrokerTeam)?.id;

  const handleGetConversation = (cb: (id: string) => void) => {
    const _groupId = data[officeIdRef.current]?.groups?.find(isBrokerTeam)?.id!;

    mutate(
      {
        members: [
          {
            value: userDetails?.id!,
            type: MemberIdTypeEnum.User,
          },
          {
            value: _groupId,
            type: MemberIdTypeEnum.Group,
          },
        ],
      },
      {
        onSuccess: (data) => {
          cb(data.id);
        },
      },
    );
  };

  return (
    <div className='divide-y' aria-label='office-card'>
      {offices
        .sort((a, b) => a?.name!.localeCompare(b?.name!))
        .map((office) => (
          <div key={office.id} className='px-4 py-2 flex flex-col'>
            <div className='flex flex-row items-center justify-between w-full space-x-4'>
              <div className='flex flex-row items-center space-x-2'>
                <p className='font-zen-body font-semibold text-base text-zen-dark-9'>
                  {office.name}
                </p>
                <span className='text-primary-blue bg-primary-blue text-sm bg-opacity-5 p-2 whitespace-nowrap rounded-full font-zen-title font-medium'>
                  {transactionDisbursementTypeToDisplayName(
                    office.transactionType!,
                  )}
                </span>
                <FeatureFlagEnabledOnly
                  flag={FeatureFlagTypeEnum.ROAR_FULL_RELEASE_BOLT}
                >
                  <ExcludeBroker>
                    <div className='!ml-3 flex space-x-3.5'>
                      <button
                        disabled={isFetchingGroups || isLoading}
                        onClick={() => {
                          officeIdRef.current = office.id!;
                          callModalActions.open();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPhone}
                          className='text-rezen-blue-600'
                        />
                      </button>
                      <button
                        disabled={isFetchingGroups || isLoading}
                        onClick={() => {
                          officeIdRef.current = office.id!;
                          handleGetConversation((id) => {
                            window.open(getConversationLink(id), '_blank');
                          });
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMessage}
                          className='text-rezen-blue-600'
                        />
                      </button>
                    </div>
                  </ExcludeBroker>
                </FeatureFlagEnabledOnly>
              </div>

              <div>
                <AdminOnly>
                  <IconButton
                    variant='none'
                    buttonStyle='bg-zen-light-gray-2 rounded-full'
                    leftIcon={
                      <FontAwesomeIcon
                        title='trash'
                        icon={faTrashCan}
                        size='sm'
                        className='my-1 mx-0 p-0.5 text-zen-danger'
                      />
                    }
                    onClick={() => {
                      setDeletingOffice(office);
                    }}
                  />
                </AdminOnly>
              </div>
            </div>

            <div className='flex flex-row mt-2'>
              <div className='mr-3'>
                <Link to={`/offices/${office.id}`}>
                  <ProfileImage
                    imageUrl={getYentaImageUrl(
                      office.designatedBroker?.avatar!,
                    )}
                    width={40}
                  />
                </Link>
              </div>
              <div>
                <Link to={`/offices/${office.id}`}>
                  <div className='flex flex-row items-center space-x-2 font-semibold font-zen-body'>
                    <p className='text-zen-dark-9  text-sm'>
                      {office.designatedBroker?.fullName}
                    </p>

                    <p className='text-zen-dark-7'>•</p>
                    <p className=' text-zen-dark-7 text-sm'>
                      Designated Broker
                    </p>
                  </div>
                </Link>
                <div className='flex items-center space-x-3'>
                  {office.designatedBroker?.phoneNumber && (
                    <ZenIconText
                      text={
                        formatPhoneNumber(office.designatedBroker?.phoneNumber)!
                      }
                      icon={
                        <FontAwesomeIcon icon={regular('phone')} size='xs' />
                      }
                      containerClassName='text-zen-dark-8 mt-1'
                      textClassName='text-zen-dark-8 text-xs'
                    />
                  )}
                  {office.designatedBroker?.emailAddress && (
                    <ZenIconText
                      text={office.designatedBroker?.emailAddress}
                      icon={
                        <FontAwesomeIcon icon={regular('envelope')} size='sm' />
                      }
                      containerClassName='text-zen-dark-8 mt-1'
                      textClassName='text-zen-dark-8 text-xs'
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      <ZenSimpleConfirmationModal
        variant='danger'
        title={`Remove ${deletingOffice?.name} Office?`}
        subtitle='Are you sure you would like to remove this office? This may have unintended consequences!'
        isOpen={!!deletingOffice}
        onClose={() => setDeletingOffice(undefined)}
        isSubmitting={isSubmitting}
        onConfirm={() => removeOfficeById(deletingOffice!.id!)}
      />
      <CallModalStepper
        opened={callModalOpen}
        onClose={callModalActions.close}
        groupId={groupId!}
        officeState={officeState}
        isFetchingConversation={isLoading}
        getConversation={handleGetConversation}
      />
    </div>
  );
};

export default ZenOfficeBroker;
