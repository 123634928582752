import { useCallback, useEffect, useState } from 'react';
import {
  canFitLastTab,
  canFitTabAndMoreTab,
  getTabsWithActiveTabFirst,
} from './determineTabOrderUtils';

export type TabWithVisibility<T extends object> = T & { isVisible: boolean };

/**
 * Determines the order of the tabs based on the width of the tabs and the container
 * @param tabs - Array of tabs, must be an object
 * @param containerRef - Ref to the container that holds the tabs
 * @param moreTabRef - Ref to the more tab
 * @param tabWidthRefs - Refs to the tabs
 * @param activeTabIndex - Index of the active tab
 * @returns tabsWithVisibility - Array of tabs with isVisible property
 * @returns showMoreTab - Boolean to determine if the more tab should be shown
 */
export const useDetermineTabOrder = <T extends object>(
  tabs: T[],
  containerRef: React.RefObject<HTMLDivElement>,
  moreTabRef: React.RefObject<HTMLDivElement>,
  tabWidthRefs: React.RefObject<HTMLDivElement>[],
  activeTabIndex: number,
) => {
  const containerWidth = containerRef.current?.offsetWidth ?? 0;
  const [tabsWithVisibility, setTabsVisibility] = useState<
    TabWithVisibility<T>[]
  >(tabs.map((tab) => ({ ...tab, isVisible: true })));

  const updateTabsWithVisibility = useCallback(() => {
    const areRefsEmpty =
      !tabWidthRefs.length || !containerRef.current || !moreTabRef.current;
    if (areRefsEmpty) {
      return;
    }

    const tabElementsCopy = getTabsWithActiveTabFirst(
      tabWidthRefs.map((ref) => ref.current?.offsetWidth ?? 0),
      activeTabIndex,
    );

    let totalWidthOfVisibleTabs = 0;
    let numberOfVisibleTabs = 0;

    for (let i = 0; i < tabElementsCopy.length; i++) {
      const currentTabWidth = tabElementsCopy[i];

      const isLastTab = i === tabElementsCopy.length - 1;

      const moreTabWidth = moreTabRef.current.offsetWidth;

      const canFitCurrentTab =
        canFitLastTab(
          containerWidth,
          totalWidthOfVisibleTabs,
          currentTabWidth,
          isLastTab,
        ) ||
        canFitTabAndMoreTab(
          containerWidth,
          totalWidthOfVisibleTabs,
          currentTabWidth,
          moreTabWidth,
        );

      if (!canFitCurrentTab) {
        break;
      }

      totalWidthOfVisibleTabs += currentTabWidth;
      numberOfVisibleTabs += 1;
    }

    /**
     * This logic is necessary because the active tab is moved to the front of the list
     * to make sure that it is part of calculating the visible tabs.
     *
     * When returning isVisible, it is based on if the index is less than the number of visible
     * tabs or if it is the active tab.
     *
     * So if the active tab index is greater than or equal to the number of visible tabs,
     * we need to subtract 1 from the number of visible tabs so we don't show an extra tab
     * and cause an overflow
     *
     * example: Tabs: [A, B, C, D, E], Active Tab: E
     * E is moved to the front of the list: [E, A, B, C, E]
     * Number of visible tabs: 3
     * Expected visible tabs: [A, B, E, more tab]
     * without this logic, the visible tabs would be [A, B, C, E, more tab]
     */
    const doesActiveTabAppearAfterTabsToShow =
      activeTabIndex >= numberOfVisibleTabs;
    if (doesActiveTabAppearAfterTabsToShow) {
      numberOfVisibleTabs -= 1;
    }

    const newTabsWithVisibility = tabs.map((tab, index) => ({
      ...tab,
      isVisible: index < numberOfVisibleTabs || index === activeTabIndex,
    }));

    setTabsVisibility(newTabsWithVisibility);
  }, [
    tabWidthRefs,
    containerRef,
    moreTabRef,
    activeTabIndex,
    tabs,
    containerWidth,
  ]);

  useEffect(() => {
    updateTabsWithVisibility();
    window.addEventListener('resize', updateTabsWithVisibility);

    return () => window.removeEventListener('resize', updateTabsWithVisibility);
  }, [updateTabsWithVisibility]);

  const showMoreTab = tabsWithVisibility.some((tab) => !tab.isVisible);

  return {
    tabsWithVisibility,
    showMoreTab,
  };
};
