import Cookies from 'js-cookie';
import {
  IdentityRoleResponse,
  IdentitySummaryResponse,
} from '../openapi/keymaker/api';
import { AgentResponse, AgentResponseTypeEnum } from '../openapi/yenta';
import AmplitudeService from '../services/AmplitudeService';
import AnalyticsService from '../services/AnalyticsService';
import ErrorService from '../services/ErrorService';
import { AdminRoles, UserRoles } from '../types';

export const JWT_TOKEN_COOKIE_NAME = 'jwtToken';

export const getAuthCookie = () => Cookies.get(JWT_TOKEN_COOKIE_NAME);

export const setAuthCookie = (value: string) =>
  Cookies.set(JWT_TOKEN_COOKIE_NAME, value);

export const deleteAuthCookie = () => Cookies.remove(JWT_TOKEN_COOKIE_NAME);

export const isBroker = (
  agentResponse: AgentResponse | null | undefined,
): boolean => {
  return agentResponse?.type === AgentResponseTypeEnum.Broker;
};

export const parseRealUsername = (
  str: string | undefined,
): string | undefined => {
  return str?.split('@')[0];
};

export const hasAdminRole = (roles: IdentityRoleResponse[] | undefined) => {
  if (!roles?.length) {
    return false;
  }
  const allRoles = roles.map((agent) => agent.role!);
  return Object.values(AdminRoles).some((r) => allRoles.indexOf(r) >= 0);
};

export const hasAnnouncerRole = (roles: IdentityRoleResponse[] | undefined) => {
  if (!roles?.length) {
    return false;
  }

  return roles?.some((r) => r?.role === UserRoles.ANNOUNCER);
};

export const hasSuperAdminRole = (
  roles: IdentityRoleResponse[] | undefined,
) => {
  if (!roles?.length) {
    return false;
  }

  return !!roles.find((agent) => agent.role === UserRoles.SUPER_ADMIN);
};

export const hasRealTitleOfficerRole = (
  roles: IdentityRoleResponse[] | undefined,
) => {
  if (!roles?.length) {
    return false;
  }

  return !!roles.find((agent) => agent.role === UserRoles.REAL_TITLE);
};

export const hasLoanOfficerRole = (
  roles: IdentityRoleResponse[] | undefined,
) => {
  if (!roles?.length) {
    return false;
  }

  return roles?.some((r) => r?.role === UserRoles.LOAN_OFFICER);
};

export const hasLoanOfficerAssistantRole = (
  roles: IdentityRoleResponse[] | undefined,
) => {
  if (!roles?.length) {
    return false;
  }

  return roles?.some((r) => r?.role === UserRoles.LOAN_OFFICER_ASSISTANT);
};

export const hasMortgageAdminRole = (
  roles: IdentityRoleResponse[] | undefined,
) => {
  if (!roles?.length) {
    return false;
  }

  return roles?.some((r) => r?.role === UserRoles.MORTGAGE_ADMIN);
};

export const hasLoanProcessorRole = (
  roles: IdentityRoleResponse[] | undefined,
) => {
  if (!roles?.length) {
    return false;
  }

  return roles?.some((r) => r?.role === UserRoles.LOAN_PROCESSOR);
};

export const hasROMARole = (roles: IdentityRoleResponse[] | undefined) => {
  if (!roles?.length) {
    return false;
  }

  return roles?.some((r) => r?.role === UserRoles.REAL_ORIGINATE_LOAN_OFFICER);
};

export const hasMortgageUserRole = (
  roles: IdentityRoleResponse[] | undefined,
) => {
  if (!roles?.length) {
    return false;
  }

  return roles?.some((r) =>
    [
      UserRoles.MORTGAGE_ADMIN,
      UserRoles.LOAN_OFFICER,
      UserRoles.LOAN_OFFICER_ASSISTANT,
      UserRoles.LOAN_PROCESSOR,
    ].includes(r?.role as UserRoles),
  );
};

export const hasMortgagePermission = (
  roles: IdentityRoleResponse[] | undefined,
) => {
  if (!roles?.length) {
    return false;
  }

  const isAdmin = hasAdminRole(roles);

  if (isAdmin) {
    return true;
  }

  return roles?.some((r) =>
    [
      UserRoles.MORTGAGE_ADMIN,
      UserRoles.LOAN_OFFICER,
      UserRoles.LOAN_OFFICER_ASSISTANT,
      UserRoles.LOAN_PROCESSOR,
      UserRoles.REAL_ORIGINATE_LOAN_OFFICER,
    ].includes(r?.role as UserRoles),
  );
};

export const hasLeoExpertRole = (roles: IdentityRoleResponse[] | undefined) => {
  if (!roles?.length) {
    return false;
  }

  return roles?.some((r) => r?.role === UserRoles.LEO_EXPERT);
};

export const hasLegalRole = (roles: IdentityRoleResponse[] | undefined) => {
  if (!roles?.length) {
    return false;
  }

  return roles?.some((r) => r?.role === UserRoles.LEGAL);
};

export const getGeneralizedRole = (
  userDetail: AgentResponse,
  keymakerCurrentUser: IdentitySummaryResponse,
): string => {
  if (hasSuperAdminRole(keymakerCurrentUser.activeRoles || [])) {
    return UserRoles.SUPER_ADMIN;
  }

  if (hasAdminRole(keymakerCurrentUser.activeRoles || [])) {
    return 'ADMIN';
  }

  if (userDetail.type === AgentResponseTypeEnum.Broker) {
    return UserRoles.BROKER;
  }

  if (hasRealTitleOfficerRole(keymakerCurrentUser.activeRoles || [])) {
    return UserRoles.REAL_TITLE;
  }

  if (hasMortgageAdminRole(keymakerCurrentUser.activeRoles || [])) {
    return UserRoles.MORTGAGE_ADMIN;
  }

  if (hasLoanOfficerRole(keymakerCurrentUser.activeRoles || [])) {
    return UserRoles.LOAN_OFFICER;
  }

  if (hasLoanOfficerAssistantRole(keymakerCurrentUser.activeRoles || [])) {
    return UserRoles.LOAN_OFFICER_ASSISTANT;
  }

  return UserRoles.AGENT;
};

export const setUserInLogServices = (
  agentResponse: AgentResponse,
  keymakerCurrentUser: IdentitySummaryResponse,
) => {
  ErrorService.setUser(agentResponse, keymakerCurrentUser);
  AmplitudeService.setUser(agentResponse);
  AnalyticsService.instance().setUser(agentResponse, keymakerCurrentUser);
};

export const hasRequiredAdminActiveRole = (
  activeRoles: IdentityRoleResponse[],
  roles: AdminRoles[],
): boolean => {
  const rolesToCheck = new Set<AdminRoles>(roles);

  return (
    activeRoles?.some(
      (role) => role?.role && rolesToCheck.has(role.role as AdminRoles),
    ) ?? false
  );
};
