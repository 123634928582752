import { faChevronRight, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs } from '@mantine/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTCInfo } from '../../slices/TransactionCoordinatorSlice';
import {
  ManageTCConnectionTabsType,
  ManageTCMainTabsType,
  RootState,
} from '../../types';
import { displayDate } from '../../utils/DateUtils';
import { TransactionCoordinators } from '../../utils/TransactionCoordinatorUtils';
import { cn } from '../../utils/classUtils';
import ResourceContainer from '../ResourceContainer';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import { Button } from '../commons/Button';
import { SegmentedControl } from '../commons/SegmentedControl';
import ActiveTCDetailsView from './ActiveTCDetailsView';
import ActivityLogTable from './ActivityLogTable';
import InActiveTCDetailsView from './InActiveTCDetailsView';
import ReceivedInvitesAccordion from './ReceivedInvitesAccordion';
import SentInvitesAccordion from './SentInvitesAccordion';
import TCAccessStatusPill from './TCAccessStatusPill';
import TCCard from './TCCard';

const mainTCTabs: {
  label: string;
  value: ManageTCMainTabsType;
}[] = [
  {
    label: 'Connections',
    value: 'CONNECTIONS',
  },
  {
    label: 'Activity Log',
    value: 'ACTIVITY_LOG',
  },
];

const connectionTabs: {
  label: string;
  value: ManageTCConnectionTabsType;
}[] = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Past Connections',
    value: 'PAST_CONNECTIONS',
  },
];

interface ManageTCSidebarProps {
  isOpen: boolean;
  onClose(): void;
  handleInviteClick?(): void;
}

const ManageTCSidebar: React.FC<ManageTCSidebarProps> = ({
  isOpen,
  onClose,
  handleInviteClick,
}) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state: RootState) => state.transactionCoordinator,
  );
  const [showActiveTC, setShowActiveTC] = useState(false);
  const [showInActiveTC, setShowInActiveTC] = useState(false);
  const [tcId, setTcId] = useState<string | null>(null);
  const [mainActiveTab, setMainActiveTab] = useState<string | null>(
    'CONNECTIONS',
  );
  const [connectionsActiveTab, setConnectionsActiveTab] = useState('ACTIVE');

  const handleActiveTab = (value: string) => {
    setMainActiveTab(value);
  };

  const handleconnectionsActiveTab = (value: string) => {
    setConnectionsActiveTab(value);
  };

  return (
    <ZenSidebarModal isOpen={isOpen} onClose={onClose} title='Manage'>
      <ResourceContainer
        resourceName='Transaction Coordinator'
        isEmpty={false}
        loading={isLoading}
      >
        {!showActiveTC && !showInActiveTC && (
          <div className='relative'>
            <div className='absolute right-2 top-2'>
              <Button
                variant='link'
                leftIcon={<FontAwesomeIcon icon={faPlus} />}
                onClick={handleInviteClick}
              >
                Invite
              </Button>
            </div>
            <div className='w-full'>
              <Tabs defaultValue={mainActiveTab} onTabChange={handleActiveTab}>
                <Tabs.List className='px-4'>
                  {mainTCTabs.map((tab) => (
                    <Tabs.Tab value={tab.value} key={tab.value}>
                      <p
                        className={cn(
                          'font-inter  text-sm sm:text-base py-1',
                          mainActiveTab === tab.value
                            ? 'text-primary-dark font-medium'
                            : 'text-gray-500 font-normal',
                        )}
                      >
                        {tab.label}
                      </p>
                    </Tabs.Tab>
                  ))}
                </Tabs.List>

                <Tabs.Panel value='CONNECTIONS' className='px-4'>
                  <div className='my-4'>
                    <SegmentedControl
                      size='md'
                      data={connectionTabs}
                      fullWidth
                      value={connectionsActiveTab}
                      onChange={handleconnectionsActiveTab}
                    />
                  </div>
                  {connectionsActiveTab === 'ACTIVE' && (
                    <div className='flex flex-col gap-2'>
                      {TransactionCoordinators.map((tc) => (
                        <TCCard
                          name={tc.name}
                          avatar={tc.avatar}
                          email={tc.email}
                          key={tc.id}
                          rightSection={
                            <div
                              className='flex gap-2 items-center cursor-pointer'
                              onClick={() => {
                                setTcId(tc.id);
                                dispatch(setTCInfo(tc));
                                setShowActiveTC(true);
                              }}
                            >
                              <TCAccessStatusPill status={tc.status!} />
                              <FontAwesomeIcon
                                icon={faChevronRight}
                                className='text-primary-dark'
                                size='xs'
                              />
                            </div>
                          }
                        />
                      ))}
                    </div>
                  )}
                  {connectionsActiveTab === 'PENDING' && (
                    <div className='flex flex-col bg-regent-100 gap-4'>
                      <ReceivedInvitesAccordion />
                      <SentInvitesAccordion
                        transactionCoordinators={TransactionCoordinators}
                        onSelect={(tc) => {
                          setTcId(tc.id);
                          dispatch(setTCInfo(tc));
                          setShowInActiveTC(true);
                        }}
                      />
                    </div>
                  )}
                  {connectionsActiveTab === 'PAST_CONNECTIONS' && (
                    <div className='flex flex-col gap-2'>
                      {TransactionCoordinators.map((tc) => (
                        <TCCard
                          name={tc.name}
                          avatar={tc.avatar}
                          key={tc.id}
                          email={tc.email}
                          rightSection={
                            <p className='font-inter text-coral-red font-medium text-xs sm:text-sm'>
                              Removed on {displayDate(tc.removedOn!)}
                            </p>
                          }
                        />
                      ))}
                    </div>
                  )}
                </Tabs.Panel>

                <Tabs.Panel value='ACTIVITY_LOG'>
                  <ActivityLogTable />
                </Tabs.Panel>
              </Tabs>
            </div>
          </div>
        )}
        {showActiveTC && (
          <ActiveTCDetailsView
            tcId={tcId!}
            onBackClick={() => {
              setShowActiveTC(false);
              setTcId(null);
            }}
          />
        )}
        {showInActiveTC && (
          <InActiveTCDetailsView
            tcId={tcId!}
            onBackClick={() => {
              setShowInActiveTC(false);
              setTcId(null);
            }}
          />
        )}
      </ResourceContainer>
    </ZenSidebarModal>
  );
};

export default ManageTCSidebar;
