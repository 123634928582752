import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullseyeArrow,
  faClock,
  faFileInvoiceDollar,
} from '@fortawesome/pro-light-svg-icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Earning } from '../WealthManagementTypes';
import { cn } from '../../../utils/classUtils';
import CoSponsoredTree from '../../../assets/img/wealthManagement/revShare/sponsor_graph.svg';
import YouTree from '../../../assets/img/wealthManagement/revShare/zen-you-tree.svg';
import Tier1Tree from '../../../assets/img/wealthManagement/revShare/zen-tier-1-tree.svg';
import Tier2Tree from '../../../assets/img/wealthManagement/revShare/zen-tier-2-tree.svg';
import Tier3Tree from '../../../assets/img/wealthManagement/revShare/zen-tier-3-tree.svg';
import Tier4Tree from '../../../assets/img/wealthManagement/revShare/zen-tier-4-tree.svg';
import Tier5Tree from '../../../assets/img/wealthManagement/revShare/zen-tier-5-tree.svg';
import RevenueGraph from '../../../assets/img/wealthManagement/revShare/revenue_graph.svg';
import { AnalyticsEventEnum, RootState } from '../../../types';
import AnalyticsService from '../../../services/AnalyticsService';
import Tier1AgentCard from '../../../assets/img/wealthManagement/revShare/agent-card-tier-1.svg';
import Tier2AgentCard from '../../../assets/img/wealthManagement/revShare/agent-card-tier-2.svg';
import Tier3AgentCard from '../../../assets/img/wealthManagement/revShare/agent-card-tier-3.svg';
import Tier4AgentCard from '../../../assets/img/wealthManagement/revShare/agent-card-tier-4.svg';
import Tier5AgentCard from '../../../assets/img/wealthManagement/revShare/agent-card-tier-5.svg';
import TierYouAgentCard from '../../../assets/img/wealthManagement/revShare/agent-card-tier-you.svg';
import { getOfficialPlan } from '../../../slices/WealthManagementSlice';
import { displayFormattedFixedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import AttractAgents from '../../../assets/img/wealthManagement/attract-agents.svg';
import GeminiAccordian from '../../Gemini/GeminiAccordian';
import { ZenPrimaryGradientText } from './ZenPrimaryGradientText';
import { LearnMoreCarousel } from './LearnMoreCarousel';
import ZenLandingPageIntro from './ZenLandingPageIntro';

type Tier = 0 | 1 | 2 | 3 | 4 | 5;
const TIERS: Tier[] = [0, 1, 2, 3, 4, 5];

const TierCard: Record<Tier, string> = {
  0: TierYouAgentCard,
  1: Tier1AgentCard,
  2: Tier2AgentCard,
  3: Tier3AgentCard,
  4: Tier4AgentCard,
  5: Tier5AgentCard,
};

const Tree: Record<Tier, string> = {
  0: YouTree,
  1: Tier1Tree,
  2: Tier2Tree,
  3: Tier3Tree,
  4: Tier4Tree,
  5: Tier5Tree,
};

const TierClickToEvent: Record<Tier, AnalyticsEventEnum> = {
  0: AnalyticsEventEnum.WEALTH_PLAN_LP_REVENUE_SHARE_CLICK_TIER_0,
  1: AnalyticsEventEnum.WEALTH_PLAN_LP_REVENUE_SHARE_CLICK_TIER_1,
  2: AnalyticsEventEnum.WEALTH_PLAN_LP_REVENUE_SHARE_CLICK_TIER_2,
  3: AnalyticsEventEnum.WEALTH_PLAN_LP_REVENUE_SHARE_CLICK_TIER_3,
  4: AnalyticsEventEnum.WEALTH_PLAN_LP_REVENUE_SHARE_CLICK_TIER_4,
  5: AnalyticsEventEnum.WEALTH_PLAN_LP_REVENUE_SHARE_CLICK_TIER_5,
};

const HowItWorks = () => {
  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );

  const [selected, setSelected] = useState<Tier>();

  const getKey = (tier: Tier) => {
    return `tier-${tier}`;
  };

  const showChain = (tier: Tier) => {
    return tier !== 0 && selected !== undefined && tier <= selected;
  };

  const getAgentLabel = (tier: Tier) => {
    if (selected === undefined || tier > selected) {
      return `Tier ${tier + 1}`;
    }
    return `$${500 - (selected - (tier + 1)) * 100}`;
  };

  const commissionSplit =
    officialPlanAndAwardData?.commissionPlan?.preCapRealCommissionSplit ?? 15;

  return (
    <div
      className='pb-10 pt-32 px-2 mt-5 relative flex flex-col justify-center items-center gap-4'
      style={{
        backgroundColor: '#F8F2FF',
      }}
    >
      <div className='flex flex-col justify-center items-center'>
        <div className='text-4xl font-bold font-zen-primary-regular text-black w-2/3 text-center my-5'>
          How it <ZenPrimaryGradientText>Works</ZenPrimaryGradientText>
        </div>
        <div className='lg:w-3/4 text-center text-2xl font-light font-zen-body-2'>
          The diagram below illustrates how our Revenue Share program works,
          using an example commission amount.
        </div>
      </div>
      <div className='lg:w-1/2 text-center font-zen-body-2 font-light text-2xl'>
        Click on an agent who sold the home to see how revenue share flows!
      </div>
      <div className='flex flex-col'>
        <div className='flex items-start justify-center cursor-pointer lg:px-20 gap-1 lg:gap-3'>
          {TIERS.map((agent: Tier) => {
            return (
              <div
                key={getKey(agent)}
                onClick={() => {
                  if (agent === selected) {
                    setSelected(undefined);
                  } else {
                    AnalyticsService.instance().logEvent(
                      TierClickToEvent[agent],
                    );
                    setSelected(agent);
                  }
                }}
                className='relative w-full h-full'
              >
                <div
                  className={cn(
                    'relative w-16 h-30 lg:w-28 lg:h-44 flex flex-col items-center text-xs lg:text-xl uppercase',
                    selected === agent && 'mt-[2rem]',
                  )}
                >
                  <img
                    src={TierCard[agent]}
                    alt={`tier-${agent}`}
                    className={cn(
                      'bg-white rounded lg:rounded-xl overflow-hidden',
                      selected === agent && 'bg-[#E7F3FF]',
                    )}
                  />

                  <div className='absolute bottom-[7%] left-0 w-full flex items-center justify-center text-2xs lg:text-base'>
                    {selected !== undefined && selected >= agent ? (
                      'makes'
                    ) : (
                      <div>
                        attracts{' '}
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className='w-2 lg:w-4'
                        />
                      </div>
                    )}
                  </div>
                </div>
                {selected === agent && (
                  <>
                    <div className='absolute -bottom-16 left-1/2 -translate-x-1/2 border-l-4 border-l-black h-16 z-0' />
                    {selected <= 1 && (
                      <div className='absolute -bottom-[66px] left-1/2 -translate-y-1/2 border-t-4 border-t-black w-40 lg:w-60 z-0' />
                    )}
                    {selected >= 4 && (
                      <div className='absolute -bottom-[66px] right-1/2 -translate-y-1/2 border-t-4 border-t-black w-40 lg:w-60 z-0' />
                    )}
                  </>
                )}
                {selected !== agent && (
                  <>
                    <div className='absolute bottom-0 left-1/2 -translate-x-[45%] translate-y-[60%] text-white px-2 rounded bg-black w-14 h-6' />
                    <div
                      className={cn(
                        'absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-[50%] text-white rounded w-14 text-center justify-center items-center flex h-6',
                        selected && agent < selected
                          ? 'bg-primary-blue'
                          : 'bg-gray-300',
                      )}
                    >
                      {getAgentLabel(agent)}
                    </div>
                  </>
                )}
                {showChain(agent) && (
                  <div className='absolute left-0 top-1/2 bg-white border rounded-full h-5 w-5 text-sm -translate-x-1/2 flex items-center justify-center text-black border-black'>
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className='flex flex-col items-center justify-center overflow-hidden px-2 w-full lg:pl-20'>
          {selected !== undefined && (
            <div className='mt-10 w-full lg:w-2/4 z-50'>
              <img
                src={Tree[selected] || ''}
                alt={`tier-${selected}-tree`}
                className='w-full'
              />
            </div>
          )}
        </div>
      </div>
      <div className='lg:w-3/5 text-center lg:text-2xl italic text-black mt-5'>
        *Note that {commissionSplit}% of commission is paid to Real until agents
        reach their cap. Once an agent is capped, they keep 100% of their
        commission.
      </div>
    </div>
  );
};

const RevenueShareTierRow = ({
  tier,
  agents,
  cap,
  maxAmount,
}: {
  tier?: number;
  agents: string;
  cap: string;
  maxAmount: string;
}) => {
  return (
    <div className='bg-gray-900 rounded-xl font-zen-body-2 flex flex-row gap-2 w-3/4 py-2 px-2 justify-between mb-5 items-center lg:text-xl'>
      <div className='flex gap-1 ml-2 flex-col w-3/4'>
        <div>
          <div className='text-white'>
            Tier {tier} ({agents} AGENTS)
          </div>
        </div>
        <div className='text-white opacity-60'>{cap} CAP/YR</div>
      </div>
      <div className='w-1/4'>
        <div className='text-blue-200'>UP TO</div>
        <div className='text-blue-300 lg:text-4xl'>{maxAmount}</div>
      </div>
    </div>
  );
};

const CappingBreakdown = () => {
  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );

  const tiersCopy = [...(officialPlanAndAwardData?.revSharePlan?.tiers || [])];
  const tiers = tiersCopy.sort((a, b) => (a.tier ?? 0) - (b.tier ?? 0));

  return (
    <div className='flex flex-col justify-center items-center bg-black pt-5'>
      <div className='text-4xl font-bold text-white lg:w-2/3 text-center mb-5 px-3'>
        <ZenPrimaryGradientText>Revenue Share Model</ZenPrimaryGradientText>
      </div>
      <div className='text-white lg:w-2/3 text-center text-2xl mb-5 font-zen-body-2 px-3'>
        Different tiers of revenue share get unlocked as you bring on more
        agents (or direct referrals) to Real.
      </div>
      <img src={RevenueGraph} alt='revenue-graph' className='w-3/4' />
      <div className='flex flex-row gap-2 font-zen-body-2 w-3/4 py-1 font-normal mt-6 px-4 lg:text-lg uppercase justify-between mb-3 text-white'>
        <div className='text-left'>Revenue Share Tiers</div>
        <div className='text-right'>MAX Revenue Share</div>
      </div>
      {tiers.map(
        (
          {
            tier,
            maxAnnualAmountPerAgent,
            numberOfTierOneAgentsToUnlockThisTier,
          },
          index,
        ) => {
          const agents =
            index < tiers.length - 1
              ? `${numberOfTierOneAgentsToUnlockThisTier} - ${
                  tiers[index + 1].numberOfTierOneAgentsToUnlockThisTier! - 1
                }`
              : `${numberOfTierOneAgentsToUnlockThisTier}+`;
          return (
            <RevenueShareTierRow
              key={tier}
              tier={tier}
              agents={agents}
              cap='$12K'
              maxAmount={displayFormattedFixedAmountWithCurrency(
                maxAnnualAmountPerAgent,
                false,
                0,
              )}
            />
          );
        },
      )}
    </div>
  );
};

const RealJourney = () => {
  return (
    <div className='relative my-10 px-3'>
      <div className='flex flex-col justify-between items-center'>
        <div className='text-3xl lg:text-5xl font-extrabold text-black mb-3 font-zen-primary-regular text-center'>
          <ZenPrimaryGradientText>Co-Sponsored</ZenPrimaryGradientText> Revenue
          Share
        </div>
        <div className='mb-10 lg:text-2xl font-light font-zen-body-2 lg:w-2/3 text-center'>
          New agents joining Real can name up to two sponsors who can both
          benefit from an equal portion of Real’s split of the commission. Real
          maintains 10% for processing.
        </div>
        <div className='flex items-center justify-center'>
          <img src={CoSponsoredTree} alt='Co spopnsored tree' />
        </div>
      </div>
    </div>
  );
};

const AgentPolicy = () => {
  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );

  const producingAgentSpan =
    officialPlanAndAwardData?.revSharePlan?.producingSpanInMonths;
  const producingAgentAmount =
    officialPlanAndAwardData?.revSharePlan?.commissionsForProducingStatus;
  const amount = displayFormattedFixedAmountWithCurrency(
    producingAgentAmount,
    false,
    0,
  );

  return (
    <div className='mb-10 px-5 lg:px-10'>
      <div className='flex justify-between'>
        <div>
          <div className='text-4xl font-extrabold font-zen-primary-regular text-black mt-10 mb-3'>
            Producing{' '}
            <ZenPrimaryGradientText>Agent Policy</ZenPrimaryGradientText>
          </div>
          <div className='text-left font-zen-body-2 font-light lg:text-2xl'>
            Agents must be active and close {amount} in splits and/or post-cap
            fees to Real every {producingAgentSpan ?? 6} months to be considered
            producing and eligible to receive revenue share.
          </div>
        </div>
      </div>
    </div>
  );
};

const AgreementDetails = () => {
  return (
    <div className='mt-5 mb-10 px-10'>
      <div className='flex flex-col justify-center items-center gap-2'>
        <GeminiAccordian
          title='Grace Period'
          leftComponentIcon={faClock}
          varient='primary-outline'
          onChange={() => {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.WEALTH_PLAN_LP_REVENUE_SHARE_EXPAND_GRACE_PERIOD,
            );
          }}
        >
          <div className='text-gray-800'>
            A new agent has a{' '}
            <span className='font-bold'>6-month grace period</span> from their
            join date where the agent is considered producing without meeting
            the $450 split and/or post-cap fee requirement. If the agent does
            not meet the $450 requirement before the end of the grace period,
            they become non-producing at the 6-month mark. This grace period is
            granted only once when the agent first joins; it does not repeat
            after the anniversary date.
          </div>
        </GeminiAccordian>
        <GeminiAccordian
          onChange={() => {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.WEALTH_PLAN_LP_REVENUE_SHARE_EXPAND_AGENT_CAP,
            );
          }}
          title='Agent Cap'
          leftComponentIcon={faBullseyeArrow}
          varient='primary-outline'
        >
          <div className='text-gray-800'>
            When an agent reaches their annual cap, they are considered
            automatically producing until their next Anniversary, independent of
            how much they&rsquo;ve made in the last six months. The $450 in six
            months split and/or post-cap fee requirement only applies to
            non-capped agents. However, if a capped agent has not paid the $450
            in splits and/or fees within the six months prior to their
            Anniversary reset date, they will roll into a non-producing status
            on their Anniversary reset date.
          </div>
        </GeminiAccordian>
        <GeminiAccordian
          onChange={() => {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.WEALTH_PLAN_LP_REVENUE_SHARE_EXPAND_PARTICIPATION_FEE,
            );
          }}
          title='Participation Fee'
          leftComponentIcon={faFileInvoiceDollar}
          varient='primary-outline'
        >
          <div className='text-gray-800'>
            An annual $175 revenue share program participation fee will be
            deducted from the first revenue share payment on the participating
            agent&rsquo;s anniversary year, and each subsequent revenue share
            payment will be assessed with a 1.2% processing fee.
          </div>
        </GeminiAccordian>
      </div>
    </div>
  );
};
export const ZenRevenueShareIncomeKnowledgePage = () => {
  const { data: officialPlanAndAwardData } = useSelector(
    (state: RootState) => state.wealthManagement.officialPlanAndAwardResponse,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (officialPlanAndAwardData === undefined) {
      dispatch(getOfficialPlan());
    }
  }, [dispatch, officialPlanAndAwardData]);

  return (
    <>
      <ZenLandingPageIntro
        earningType={Earning.REVENUE_SHARE_INCOME}
        sloganImage={AttractAgents}
        showAddToMyPlan
        buttonProps={{
          earningType: Earning.REVENUE_SHARE_INCOME,
        }}
        subtitle='Let Real help you build an income stream for life'
      />
      <HowItWorks />
      <CappingBreakdown />
      <RealJourney />
      <AgentPolicy />
      <AgreementDetails />
      <LearnMoreCarousel />
    </>
  );
};
