import React, { useEffect } from 'react';
import pluralize from 'pluralize';
import { Modal } from '../commons/Modal';
import { Select } from '../commons/Select';
import { capitalizeEnum } from '../../utils/StringUtils';
import { Button } from '../commons/Button';

import { useBulkStatusUpdate } from '../../query/kafkaDashboard/useKafkaDashboard';
import {
  KafkaController,
  KafkaUpdateMessageStatus,
  KafkaUpdateMessageStatusEnum,
} from './KafkaControllerTypes';

type KafkaBulkMessageUpdateModalProps = {
  onClose: (refresh?: boolean) => void;
  showBulkEditModal: boolean;
  selectedMessageIds: string[];
  selectedController: KafkaController;
};

export const KafkaBulkMessageUpdateModal: React.FC<KafkaBulkMessageUpdateModalProps> = ({
  showBulkEditModal,
  selectedMessageIds,
  selectedController,
  onClose,
}) => {
  const [status, setStatus] = React.useState<KafkaUpdateMessageStatusEnum>();

  useEffect(() => {
    setStatus(undefined);
  }, [showBulkEditModal]);

  const { mutate: updateStatuses, isLoading } = useBulkStatusUpdate({
    controller: selectedController,
    status,
  });

  return (
    <Modal opened={showBulkEditModal} onClose={onClose}>
      <Modal.Header>Bulk Update Status</Modal.Header>
      <Modal.Content>
        <div className='font-inter font-light py-4'>
          Updating {selectedMessageIds.length}{' '}
          {pluralize('message', selectedMessageIds.length)}
        </div>
        <Select
          placeholder='Select Status'
          data={Object.values(KafkaUpdateMessageStatus)
            .sort()
            .map((value) => ({
              label: capitalizeEnum(value),
              value,
            }))}
          value={status}
          onChange={(value) => setStatus(value as KafkaUpdateMessageStatusEnum)}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button
          classNames={{
            label: 'px-6',
          }}
          variant='outline'
          onClick={() => onClose()}
        >
          Close
        </Button>
        <Button
          disabled={!status || isLoading}
          loading={isLoading}
          classNames={{
            label: 'px-6',
          }}
          onClick={() =>
            updateStatuses(selectedMessageIds, {
              onSuccess: () => onClose(true),
            })
          }
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
