import { FC, useState } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { TransactionCoordinatorAccessEnum } from '../../types';
import { TCInfo } from './TCInfoSection';
import TCSelectCard from './TCSelectCard';
import { TransactionCoordinatorFromData } from './TCTransactionsAccessCard';

interface TCSelectCardsContainerProps {
  transactionCoordinators: TCInfo[];
  control: Control<TransactionCoordinatorFromData>;
  setValue: UseFormSetValue<TransactionCoordinatorFromData>;
  accessType: TransactionCoordinatorAccessEnum;
  onSelect?: (data: TCInfo) => void;
  onCancel?: () => void;
  onSubmit?: () => void;
}

const TCSelectCardsContainer: FC<TCSelectCardsContainerProps> = ({
  transactionCoordinators,
  control,
  setValue,
  accessType,
  onSubmit,
  onCancel,
  onSelect,
}) => {
  const [isTcSelected, setIsTcSelected] = useState(false);

  return (
    <div className='p-5 flex flex-col gap-3'>
      {transactionCoordinators.map((tc) => (
        <TCSelectCard
          key={tc.name}
          tcInfo={tc}
          control={control}
          setValue={setValue}
          selectedAccessType={accessType}
          setIsTcSelected={setIsTcSelected}
          isTcSelected={isTcSelected}
          onSelect={() => onSelect?.(tc)}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      ))}
    </div>
  );
};

export default TCSelectCardsContainer;
