import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-light-svg-icons';
import {
  faThumbsUp as faThumbsUpLiked,
  faThumbsDown as faThumbsUpDisliked,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDisclosure } from '@mantine/hooks';
import React, { useState } from 'react';
import { VoiceFeedbackDtoRatingEnum } from '../../../../openapi/yada';
import { useFetchTranscriptionFeedback } from '../../../../query/roar/useRoar';
import { cn } from '../../../../utils/classUtils';
import VoiceCallFeedbackModal from './VoiceCallFeedbackModal';
import VoiceCallFeedbackSubmitted from './VoiceCallFeedbackSubmittedModal';

interface VoiceCallFeedbackProps {
  voiceCallId: string;
}

const VoiceCallFeedback: React.FC<VoiceCallFeedbackProps> = ({
  voiceCallId,
}) => {
  const [opened, feedbackModalAction] = useDisclosure(false);
  const [reviewed, reviewModalActions] = useDisclosure(false);
  const [liked, setLiked] = useState<boolean>();

  const { isLoading, data } = useFetchTranscriptionFeedback(voiceCallId);

  if (isLoading) {
    return null;
  }

  const isLiked = data?.rating === VoiceFeedbackDtoRatingEnum.Liked;
  const isDisliked = data?.rating === VoiceFeedbackDtoRatingEnum.Disliked;

  return (
    <>
      <div className='flex items-center space-x-3'>
        <p className='text-rezen-blue-600 font-normal font-inter whitespace-nowrap'>
          Is This Transcript Accurate?
        </p>
        <div className='flex space-x-4'>
          <FontAwesomeIcon
            icon={isLiked ? faThumbsUpLiked : faThumbsUp}
            onClick={() => {
              setLiked(true);
              feedbackModalAction.open();
            }}
            aria-label='like'
            fontSize={16}
            className={cn(
              'cursor-pointer text-primary-dark',
              isLiked && 'text-rezen-blue-600',
            )}
          />
          <FontAwesomeIcon
            icon={isDisliked ? faThumbsUpDisliked : faThumbsDown}
            onClick={() => {
              setLiked(false);
              feedbackModalAction.open();
            }}
            aria-label='dislike'
            fontSize={16}
            className={cn(
              'cursor-pointer text-primary-dark',
              isDisliked && 'text-rezen-blue-600',
            )}
          />
        </div>
      </div>
      <VoiceCallFeedbackModal
        voiceCallId={voiceCallId}
        opened={opened}
        onChange={setLiked}
        onClose={feedbackModalAction.close}
        openReviewModal={reviewModalActions.open}
        liked={liked}
        feedbackText={data?.feedback}
      />
      <VoiceCallFeedbackSubmitted
        opened={reviewed}
        onClose={reviewModalActions.close}
        liked={liked}
      />
    </>
  );
};

export default VoiceCallFeedback;
