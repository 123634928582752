import { faPen, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import {
  CommentDto,
  FileBlock,
  HashtagBlock,
  HyperlinkBlock,
  ImageBlock,
  MediaAudioBlock,
  MentionBlock,
  ReadReceiptDto,
  ReferenceBlock,
  RezenObject,
  RezenObjectTypeEnum,
  TextBlock,
  UnknownBlock,
  VoiceCallBlock,
} from '../../../../openapi/yada';
import { FeatureFlagTypeEnum, RootState } from '../../../../types';
import { cn } from '../../../../utils/classUtils';
import { stringToHexCode } from '../../../../utils/StringUtils';
import {
  isMediaAudioBlock,
  isMentionBlock,
  isVoiceCallBlock,
} from '../../../../utils/TypeUtils';
import ExcludeMortgagerUserRole from '../../../auth/ExcludeMortgagerUserRole';
import FeatureFlagEnabledOnly from '../../../FeatureFlagEnabledOnly';
import ZenCommentReactions from '../../../transactions/Comments/ZenCommentReactions';
import GeminiAvatar from '../../GeminiAvatar';
import GeminiBlockPreview from './GeminiBlockPreview';
import GeminiChecklistTag from './GeminiChecklistTag';
import GeminiCircle from './GeminiCircle';
import GeminiReadReceiptsList from './GeminiReadReceiptsList';
import GeminiVoiceCallCard from './GeminiVoiceCallCard';

interface GeminiRichCommentRowProps {
  side?: 'left' | 'right';
  fullName?: string;
  role?: string;
  avatarUrl?: string;
  timestamp?: number;
  visibleTo?: string;
  readReceipts?: ReadReceiptDto[];
  blocks?: Array<
    | FileBlock
    | HashtagBlock
    | HyperlinkBlock
    | ImageBlock
    | MentionBlock
    | ReferenceBlock
    | TextBlock
    | VoiceCallBlock
    | MediaAudioBlock
    | UnknownBlock
  >;
  onDelete?(): void;
  onEdit?(): void;
  refersTo?: RezenObject;
  isReferred?: boolean;
  isEdited?: boolean;
  comment: CommentDto;
}

const GeminiRichCommentRow: React.FC<GeminiRichCommentRowProps> = ({
  side = 'left',
  fullName,
  role,
  avatarUrl,
  timestamp,
  visibleTo,
  readReceipts,
  blocks,
  onDelete,
  onEdit,
  refersTo,
  isReferred = false,
  isEdited,
  comment,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [
    isReactionsPopperVisible,
    setIsReactionsPopperVisible,
  ] = useState<boolean>(false);

  const {
    auth: { isAdmin, hasMortgagePermission },
  } = useSelector((state: RootState) => state);

  const blockContainsGiphy = blocks?.find((el) => el.type === 'IMAGE');
  const voiceRecordingBlock = blocks?.find(isVoiceCallBlock);
  const mediaAudioBlock = blocks?.find(isMediaAudioBlock);

  const footerElement = useMemo(() => {
    return (
      <div
        className={cn(
          'flex flex-row items-center font-inter font-normal text-sm pl-10 pr-4 gap-x-2 text-regent-600',
          {
            'flex-row-reverse pl-4 pr-10': side === 'right',
          },
        )}
      >
        <div>
          {DateTime.fromMillis(timestamp || 0)
            .toFormat('h:mma')
            .toLowerCase()}
        </div>
        {readReceipts?.length! > 0 && (
          <div
            className={cn('flex flex-row items-center space-x-2', {
              'flex-row-reverse space-x-reverse': side === 'right',
            })}
          >
            <GeminiCircle />
            <GeminiReadReceiptsList readReceipts={readReceipts} />
          </div>
        )}
        {!!visibleTo && (
          <div
            className={cn('flex flex-row items-center space-x-2', {
              'flex-row-reverse space-x-reverse': side === 'right',
            })}
          >
            <GeminiCircle />
            <span className='font-inter font-normal text-sm text-regent-600'>
              {visibleTo}
            </span>
          </div>
        )}
        {!voiceRecordingBlock && !mediaAudioBlock && isEdited && (
          <div
            className={cn('flex flex-row items-center space-x-2', {
              'flex-row-reverse space-x-reverse': side === 'right',
            })}
          >
            <GeminiCircle />
            <span className='font-inter font-normal text-xs text-regent-600'>
              Edited
            </span>
          </div>
        )}
      </div>
    );
  }, [
    isEdited,
    mediaAudioBlock,
    readReceipts,
    side,
    timestamp,
    visibleTo,
    voiceRecordingBlock,
  ]);

  const isRoarFullRelease = useFeatureFlag(
    FeatureFlagTypeEnum.ROAR_FULL_RELEASE_BOLT,
  );

  if (voiceRecordingBlock) {
    if (!voiceRecordingBlock.recordingAccessible) {
      return null;
    }

    const mentionBlock = blocks?.find(isMentionBlock);

    return (
      <GeminiVoiceCallCard
        comment={comment}
        fullName={fullName!}
        avatarUrl={avatarUrl}
        recordingBlock={voiceRecordingBlock}
        mentionBlock={mentionBlock!}
        footerElement={footerElement}
        onDeleteOptionClick={onDelete}
      />
    );
  }

  return (
    <div
      className='flex flex-col gap-y-1 mb-4'
      onMouseEnter={() => isRoarFullRelease && setIsHovered(true)}
      onMouseLeave={() => isRoarFullRelease && setIsHovered(false)}
    >
      <div
        className={classNames('flex flex-row items-end space-x-2', {
          'flex-row-reverse space-x-reverse': side === 'right',
        })}
      >
        <div
          className={classNames(side === 'left' ? '-mt-1' : 'mt-1', {
            'ml-2': side === 'right',
          })}
        >
          <GeminiAvatar name={fullName!} size='xs' imageUrl={avatarUrl} />
        </div>
        <div
          className={classNames(
            'relative p-3 pt-2.5 flex flex-col items-start gap-y-1.5 rounded-xl border-grey-300 border bg-white min-w-[295px] max-w-[600px] xl:max-w-[700px] text-primary-dark font-inter',
            {
              'rounded-bl-none': side === 'left',
              'rounded-br-none bg-seaglass': side === 'right',
              'w-full max-w-xl xl:max-w-xl': mediaAudioBlock,
            },
          )}
        >
          <div className='flex gap-3 items-center mb-1'>
            <div
              className='text-xs font-medium'
              style={{
                color: stringToHexCode(fullName || ''),
              }}
            >
              {fullName} ({role})
            </div>
            {(isAdmin || hasMortgagePermission) &&
              side === 'right' &&
              !blockContainsGiphy && (
                <div className='flex gap-2 items-center'>
                  <FontAwesomeIcon
                    icon={faPen}
                    size='xs'
                    className='cursor-pointer'
                    data-testid='editIcon'
                    onClick={onEdit}
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    size='xs'
                    className='cursor-pointer'
                    data-testid='deleteIcon'
                    onClick={onDelete}
                  />
                </div>
              )}
          </div>
          {!isReferred && refersTo?.type === RezenObjectTypeEnum.ChecklistItem && (
            // @ts-expect-error
            <GeminiChecklistTag refersTo={refersTo} />
          )}
          <GeminiBlockPreview blocks={blocks} side={side} />
          <ExcludeMortgagerUserRole>
            <FeatureFlagEnabledOnly
              flag={FeatureFlagTypeEnum.ROAR_FULL_RELEASE_BOLT}
            >
              <div>
                <div
                  className={cn(
                    'w-fit absolute right-0 -bottom-5',
                    side === 'left' ? 'right-5' : 'left-5',
                  )}
                >
                  <span
                    onMouseEnter={() => setIsReactionsPopperVisible(true)}
                    onMouseLeave={() => setIsReactionsPopperVisible(false)}
                  >
                    <ZenCommentReactions
                      comment={comment}
                      isHovered={isHovered}
                      isReactionsPopperVisible={isReactionsPopperVisible}
                    />
                  </span>
                </div>
              </div>
            </FeatureFlagEnabledOnly>
          </ExcludeMortgagerUserRole>
        </div>
      </div>
      {footerElement}
    </div>
  );
};

export default GeminiRichCommentRow;
