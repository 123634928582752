import { faCheck, faL } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';

export interface SlimDocumentTitleProps {
  title: string;
  revision?: number;
  createdAt?: number;
  isPdf?: boolean;
}

const SlimDocumentTitle: React.FC<SlimDocumentTitleProps> = ({
  title,
  createdAt,
  revision,
  isPdf,
}) => {
  const styledTitle = title.replace(/ /, '&').split('&').join('\n');
  return (
    <div className='bg-mineshaft flex w-full flex-col px-8'>
      <div className='flex flex-row justify-center'>
        <FontAwesomeIcon
          icon={faL}
          className='text-xl mt-2 md:ml-0 mr-3 text-lightBlue-400 transform -scale-y-100'
        />
        <pre className='text-white font-primary-regular text-center md:text-2xl print:text-lg my-6 whitespace-nowrap self-center'>
          {styledTitle}
        </pre>
        <FontAwesomeIcon
          icon={faCheck}
          className='text-xl mt-11 ml-3 text-lightBlue-400 -rotate-45'
        />
      </div>
      {revision && createdAt && (
        <p className='text-center mb-2 print:text-xs text-white'>
          Revision {revision} |{' '}
          {isPdf
            ? DateTime.fromMillis(createdAt!)
                .setZone('America/New_York')
                .toFormat('MMM d, yyyy | hh:mma ZZZZ')
            : DateTime.fromMillis(createdAt).toFormat('MMM d, yyyy - HH:mm ')}
        </p>
      )}
    </div>
  );
};

export default SlimDocumentTitle;
