import { faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionProps } from '@mantine/core';
import { FC, useState } from 'react';
import { cn } from '../../utils/classUtils';

interface Props extends AccordionProps {
  icon?: JSX.Element;
  title?: string;
  titleComponent?: JSX.Element;
  name: string;
  opened?: boolean;
}

const Accordian: FC<Props> = ({
  icon,
  title,
  titleComponent,
  name,
  children,
  opened = true,
  ...props
}) => {
  const [isAccordianOpen, setIsAccordianOpen] = useState(opened);

  const toggleAccordian = () => setIsAccordianOpen((prev) => !prev);

  return (
    <Accordion
      unstyled
      classNames={{
        control: 'w-full flex justify-between items-center px-4 py-2',
      }}
      chevron={
        <FontAwesomeIcon
          icon={faChevronUp}
          className={cn('text-primary-navy transition', {
            'rotate-180': !isAccordianOpen,
            'rotate-0': isAccordianOpen,
          })}
        />
      }
      defaultValue={opened ? name : ''}
      {...props}
    >
      <Accordion.Item value={name}>
        <Accordion.Control
          className='flex flex-row-reverse'
          onClick={toggleAccordian}
        >
          <div className='flex items-center gap-2.5'>
            {icon}
            {title ? (
              <p className='font-semibold text-xs font-inter text-regent-600'>
                {title}
              </p>
            ) : (
              titleComponent
            )}
          </div>
        </Accordion.Control>
        <Accordion.Panel>{children}</Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default Accordian;
