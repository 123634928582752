import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExclamation } from '@fortawesome/pro-thin-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { Earning, EarningType } from '../WealthManagementTypes';
import { AnalyticsEventEnum } from '../../../types';
import AnalyticsService from '../../../services/AnalyticsService';
import GeminiAccordian from '../../Gemini/GeminiAccordian'; // Assuming correct import path

const EarningEventMap: Partial<Record<EarningType, AnalyticsEventEnum>> = {
  [Earning.PRE_CAP_SPP]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_PRE_CAP_SPP_EXPAND_TERMS_AND_CONDITIONS,
  [Earning.STOCK_AWARD_FOR_CAPPING]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_CAPPING_AWARD_EXPAND_TERMS_AND_CONDITIONS,
  [Earning.POST_CAP_SPP]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_POST_CAP_SPP_EXPAND_TERMS_AND_CONDITIONS,
  [Earning.ELITE_PRODUCTION_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_ELITE_PRODUCTION_AWARD_EXPAND_TERMS_AND_CONDITIONS,
  [Earning.ELITE_CULTURAL_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_ELITE_CULTURAL_AWARD_EXPAND_TERMS_AND_CONDITIONS,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]:
    AnalyticsEventEnum.WEALTH_PLAN_LP_STOCK_AWARD_ATTRACTING_EXPAND_TERMS_AND_CONDITIONS,
};

interface TermsAndConditionsAccordionProps {
  earningType: EarningType;
}

export const ZenTermsAndConditionsAccordion: React.FC<TermsAndConditionsAccordionProps> = ({
  earningType,
}) => {
  return (
    <GeminiAccordian
      title='Terms & Conditions'
      leftComponentIcon={faFileExclamation}
      onChange={() => {
        const event = EarningEventMap[earningType];
        if (event) {
          AnalyticsService.instance().logEvent(event);
        }
      }}
    >
      <div>
        <div className='flex gap-2'>
          <div>
            <FontAwesomeIcon
              icon={faCheck}
              size='sm'
              className='text-green-600'
            />
          </div>
          Shares are purchased based on the closing fair market value, on the
          last trading day of the month in which the award is distributed.
        </div>
        <div className='flex gap-2'>
          <div>
            <FontAwesomeIcon
              icon={faCheck}
              size='sm'
              className='text-green-600'
            />
          </div>{' '}
          All shares in this program are Restricted Stock Units (RSUs) and have
          a 3-year vesting period. Agents must remain in good standing
          throughout the vesting period.
        </div>
        <div className='flex gap-2'>
          <div>
            <FontAwesomeIcon
              icon={faCheck}
              size='sm'
              className='text-green-600'
            />
          </div>
          Agents can opt in and out of the program at any time, but they can
          only opt in once per anniversary year.
        </div>
      </div>
    </GeminiAccordian>
  );
};
