import { faTableColumns } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ZenDefaultEmptyProps {
  message: string;
  icon?: React.ReactElement;
}

const ZenDefaultEmpty: React.FC<ZenDefaultEmptyProps> = ({
  message,
  icon = <FontAwesomeIcon icon={faTableColumns} className='text-3xl' />,
}) => {
  return (
    <div className='w-full h-full p-5 flex flex-col items-center justify-center'>
      {icon}
      <p className='font-zen-body font-medium text-zen-dark-6'>{message}</p>
    </div>
  );
};

export default ZenDefaultEmpty;
