import {
  CommissionSplitParticipantValue,
  ParticipantResponse,
  ParticipantValueTypeEnum,
  PaymentParticipantValue,
  PaymentParticipantValueTypeEnum,
} from '../openapi/arrakis';
import { AgentResponse } from '../openapi/yenta';
import { EnumMap } from '../types';
import { isStringPresent } from './StringUtils';

export const isAgentParticipant = (
  participantType: PaymentParticipantValueTypeEnum | ParticipantValueTypeEnum,
): boolean => {
  const map: EnumMap<PaymentParticipantValueTypeEnum, boolean> = {
    AGENT: true,
    EXTERNAL_ENTITY: false,
  };

  return map[participantType];
};

export const getParticipantName = (
  participant:
    | PaymentParticipantValue
    | ParticipantResponse
    | CommissionSplitParticipantValue
    | AgentResponse
    | undefined,
) => {
  let name = 'N/A';

  if (
    isStringPresent(participant?.firstName) ||
    isStringPresent(participant?.lastName)
  ) {
    name = [participant!.firstName, participant!.lastName]
      .filter((n) => !!n)
      .join(' ');
  } else if (isStringPresent(participant?.paidViaBusinessEntity?.name)) {
    name = participant!.paidViaBusinessEntity?.name!;
  } else if (
    // company name is not present for BuilderParticipant Type in swagger UI.
    // @ts-ignore
    isStringPresent(participant?.companyName)
  ) {
    // @ts-ignore
    name = participant?.companyName;
  }

  return name;
};
