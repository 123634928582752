import qs from 'qs';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ZenAgentResourceTable from '../components/Zen/Agent/ZenAgentResourceTable';
import ZenPageLayoutWithSearch from '../components/Zen/ZenPageLayoutWithSearch';
import ZenRoute from '../components/Zen/ZenRoute';
import { RootState } from '../types';

const ZenBrokerPeopleRoute: React.FC = () => {
  const location = useLocation();
  const { search: searchFromQS } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [search, setSearch] = useState<string | undefined>(
    typeof searchFromQS === 'string' ? searchFromQS : undefined,
  );
  const { userDetail } = useSelector((state: RootState) => state.auth);

  return (
    <ZenRoute title='People'>
      <ZenPageLayoutWithSearch
        path={[
          { title: 'Home', url: '/' },
          { title: 'People', url: '/people' },
        ]}
        search={search}
        onSearchChange={setSearch}
      >
        <ZenAgentResourceTable
          search={search}
          officeId={(userDetail?.offices || []).map((office) => office.id!)}
          showConnectColumn
        />
      </ZenPageLayoutWithSearch>
    </ZenRoute>
  );
};

export default ZenBrokerPeopleRoute;
