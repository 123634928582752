import {
  faThumbsDown as faThumbsDownLite,
  faThumbsUp as faThumbsUpLite,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '../../../commons/Modal';

interface VoiceCallFeedbackSubmittedProps {
  opened: boolean;
  onClose(): void;
  liked?: boolean;
}

const VoiceCallFeedbackSubmitted: React.FC<VoiceCallFeedbackSubmittedProps> = ({
  opened,
  onClose,
  liked,
}) => {
  return (
    <Modal opened={opened} onClose={onClose} size='524px'>
      <Modal.Header
        color='white'
        className='border-b border-regent-300 text-center'
        hideIcon
      >
        <span className='font-semibold font-inter text-lg'>Give Feedback</span>
      </Modal.Header>
      <Modal.Content className='py-10 flex flex-col justify-center items-center'>
        <FontAwesomeIcon
          icon={liked ? faThumbsUpLite : faThumbsDownLite}
          className='text-rezen-blue-600'
          fontSize={40}
        />
        <div className='mt-6 font-inter text-lg font-semibold text-primary-dark'>
          Thanks for the feedback!
        </div>
        <div className='mt-2 font-inter font-light text-primary-dark'>
          We&apos;ll work on improving this for the future
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default VoiceCallFeedbackSubmitted;
