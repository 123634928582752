import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faUserClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { ItemResponseStatusEnum } from '../../../openapi/sherlock';
import { RezenObjectTypeEnum } from '../../../openapi/yada';
import { ISelectOption, PartialEnumMap } from '../../../types';
import { safePartialEnumMapGet } from '../../../utils/EnumHelper';
import ZenChecklistLabel from '../Transaction/ZenChecklistLabel';

interface ZenChecklistLabelDropdownProps {
  options: Array<ISelectOption>;
  readOnly?: boolean;
  isRequired?: boolean;
  status: ItemResponseStatusEnum;
  onChange(status: ItemResponseStatusEnum): void;
  containerType?: RezenObjectTypeEnum;
  isAssigned?: boolean;
}

const ZenChecklistLabelDropdown: React.FC<ZenChecklistLabelDropdownProps> = ({
  options,
  readOnly = false,
  isRequired = false,
  status,
  onChange,
  containerType,
  isAssigned,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isBorrowerContainer = containerType === RezenObjectTypeEnum.Borrower;

  const statusToIconMap: PartialEnumMap<
    ItemResponseStatusEnum,
    React.ReactElement
  > = {
    [ItemResponseStatusEnum.NotStarted]:
      isBorrowerContainer && isAssigned && isRequired ? (
        <FontAwesomeIcon
          icon={faUserClock}
          className='px-0.5 text-primary-blue group-hover:text-white'
        />
      ) : !isBorrowerContainer && isRequired ? (
        <FontAwesomeIcon
          icon={regular('bolt-lightning')}
          className='px-0.5 text-primary-blue group-hover:text-white'
        />
      ) : (
        <FontAwesomeIcon
          icon={solid('lightbulb')}
          className='px-0.5 text-zen-dark-12 group-hover:text-white'
        />
      ),
    [ItemResponseStatusEnum.Pending]: (
      <FontAwesomeIcon
        icon={regular('hourglass-half')}
        className='px-0.5 text-zen-warning-dark group-hover:text-white'
      />
    ),
    [ItemResponseStatusEnum.Accepted]: (
      <FontAwesomeIcon
        icon={regular('thumbs-up')}
        className='px-0.5 text-green-600 group-hover:text-white'
      />
    ),
    [ItemResponseStatusEnum.RevisionRequested]: (
      <FontAwesomeIcon
        icon={regular('clock-rotate-left')}
        className='px-0.5 text-zen-danger group-hover:text-white'
      />
    ),
  };

  return (
    <Popover
      isOpen={!readOnly && isOpen}
      positions={['bottom']}
      align='start'
      onClickOutside={() => setIsOpen(false)}
      parentElement={document.getElementById('main-page')!}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor='white'
          arrowSize={10}
          arrowStyle={{
            bottom: 1,
            zIndex: 1,
          }}
          className='popover-arrow-container'
          arrowClassName='popover-arrow'
        >
          <div className='border border-zen-dark-4 rounded-lg bg-white py-5 -ml-2.5'>
            {options.map(({ value, label }) => (
              <div
                key={label}
                className='group px-6 py-1.5 font-zen-body text-sm flex items-center w-full cursor-pointer hover:bg-primary-blue text-zen-dark hover:text-white'
                onClick={() => {
                  onChange((value! as unknown) as ItemResponseStatusEnum);
                  setIsOpen(false);
                }}
              >
                {safePartialEnumMapGet(
                  statusToIconMap,
                  value as ItemResponseStatusEnum,
                  <FontAwesomeIcon
                    icon={regular('bolt-lightning')}
                    className='px-0.5 text-primary-blue group-hover:text-white'
                  />,
                )}
                <span className='ml-1 text-base font-normal font-zen-body group-hover:text-white w-full'>
                  {label}
                </span>
              </div>
            ))}
          </div>
        </ArrowContainer>
      )}
    >
      <div
        className='cursor-pointer'
        onClick={() => !readOnly && setIsOpen(true)}
      >
        <ZenChecklistLabel
          status={status}
          isRequired={isRequired}
          readOnly={readOnly}
          containerType={containerType}
          isAssigned={isAssigned}
        />
      </div>
    </Popover>
  );
};

export default ZenChecklistLabelDropdown;
