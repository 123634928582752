import { findLast, sortBy, upperCase, values } from 'lodash';
import { DateTime } from 'luxon';
import { ChangeEvent, DragEvent } from 'react';
import { PillVariantType } from '../components/Pill';
import { MAX_DOC_SIZE, MAX_FILE_SIZE } from '../constants/FilesConstants';
import {
  AgentDebtCreateRequestRecoveryTypeEnum,
  AgentDebtResponseRecoveryTypeEnum,
  CapStatusValue,
  DebtRepaymentItemResponse,
  DebtRepaymentItemResponsePaymentSourceTypeEnum,
  FrontLineAgentResponse,
  FrontLineAgentResponseStatusEnum,
  IncomeOverviewResponse,
  IncomeTotalsResponse,
  NationalIdentificationValueTypeEnum as ArrakisNationalIdentificationValueTypeEnum,
  ParticipantResponseParticipantRoleEnum,
  ParticipantValue,
  PaymentParticipantValue,
  RevShareOverviewResponse,
  TaxDocumentResponseTaxDocumentTypeEnum,
  TransactionPreviewResponseCountryEnum,
  TransactionResponse,
} from '../openapi/arrakis';
import {
  AddressResponse,
  AgentControllerApi,
  AgentInfo,
  AgentResponse,
  AgentResponseAccountCountryEnum,
  AgentResponseAgentStatusEnum,
  NationalBusinessIdentificationTypeEnum,
  NationalIdentificationTypeEnum,
  NationalIdentificationValueTypeEnum,
  OfficeGroupResponse,
} from '../openapi/yenta';
import {
  AgentAddressTypeEnum,
  EnumMap,
  ISelectOption,
  ListingCountsByLifecycleGroupType,
  Mapping,
  TransactionsCountByLifecycleGroupType,
} from '../types';
import { STATE_OR_PROVINCE_ABBREVIATIONS } from './AnnouncementUtils';
import { displayAmount, DisplayAmountOptions } from './CurrencyUtils';
import { getYesterdayDate } from './DateUtils';
import { safeEnumMapGet } from './EnumHelper';
import { getYentaConfiguration } from './OpenapiConfigurationUtils';
import { getParticipantName } from './ParticipantHelper';
import { capitalizeEnum, isStringPresent } from './StringUtils';

export enum AdditionalIdentifications {
  Tin = 'TIN',
}

export type IdentificationType =
  | NationalIdentificationValueTypeEnum
  | ArrakisNationalIdentificationValueTypeEnum
  | NationalIdentificationTypeEnum
  | AdditionalIdentifications
  | NationalBusinessIdentificationTypeEnum;

export const hasSocialMediaLinks = (agent: AgentResponse): boolean => {
  return (
    !!agent.facebookURL ||
    !!agent.twitterURL ||
    !!agent.clubhouseURL ||
    !!agent.instagramURL ||
    !!agent.youtubeURL ||
    !!agent.personalWebsiteURL ||
    !!agent.clubhouseURL ||
    !!agent.googleBusinessProfileURL ||
    !!agent.linkedInURL ||
    !!agent.yelpURL ||
    !!agent.zillowURL
  );
};

export const getIdentificationType = (
  country: AgentResponseAccountCountryEnum,
): ISelectOption[] => {
  if (country === AgentResponseAccountCountryEnum.Canada)
    return values(NationalIdentificationValueTypeEnum)
      .filter(
        (item) =>
          item !== NationalIdentificationValueTypeEnum.Ssn &&
          item !== NationalIdentificationValueTypeEnum.Bn &&
          item !== NationalIdentificationValueTypeEnum.Ein,
      )
      .map((type) => ({
        value: type,
        label: capitalizeEnum(type),
      }));
  else
    return [
      {
        value: NationalIdentificationValueTypeEnum.Ssn,
        label: upperCase(NationalIdentificationValueTypeEnum.Ssn),
      },
    ];
};

export const getIdentificationTypeForPaidViaBusinessEntity = (
  country: AgentResponseAccountCountryEnum,
): ISelectOption[] => {
  if (country === AgentResponseAccountCountryEnum.Canada)
    return values(NationalIdentificationValueTypeEnum)
      .filter(
        (item) =>
          item !== NationalIdentificationValueTypeEnum.Ssn &&
          item !== NationalIdentificationValueTypeEnum.Ein,
      )
      .map((type) => ({
        value: type,
        label: capitalizeEnum(type),
      }));
  else
    return [
      {
        value: NationalIdentificationValueTypeEnum.Ein,
        label: upperCase(NationalIdentificationValueTypeEnum.Ein),
      },
    ];
};

export const getTaxIdentificationType = (
  country: TransactionPreviewResponseCountryEnum,
): ISelectOption[] => {
  if (country === TransactionPreviewResponseCountryEnum.Canada)
    return [
      {
        value: NationalIdentificationValueTypeEnum.Bn,
        label: 'Business Number',
      },
    ];
  else
    return [
      {
        value: NationalIdentificationValueTypeEnum.Ein,
        label: upperCase(NationalIdentificationValueTypeEnum.Ein),
      },
    ];
};

export const getPatternForIdentificationType = (
  type: IdentificationType,
): RegExp | undefined => {
  const map: EnumMap<IdentificationType, RegExp | undefined> = {
    BN: undefined, // new RegExp('^[0-9]{9}$'),
    EIN: new RegExp('^[0-9]{2}-[0-9]{7}$'),
    GST_ID: undefined, // new RegExp('^[0-9]{9} (RT) [0-9]{4}$'),
    HST_ID: undefined, // new RegExp('^[0-9]{9} (RT) [0-9]{4}$'),
    PST_ID: undefined, // new RegExp('^(PST)-[0-9]{4}-[0-9]{4}$'),
    QST_ID: undefined, // new RegExp('^[0-9]{10} (TQ) [0-9]{4}$'),
    SIN: undefined, // new RegExp('^[0-9]{3}-[0-9]{3}-[0-9]{4}$'),
    FNIN: undefined,
    SSN: new RegExp(
      '^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$',
    ),
    TIN: new RegExp('^[0-9]{3}-[0-9]{2}-[0-9]{4}$'),
    B_AND_O_ID: undefined,
    CBJ_ID: undefined,
    GET_ID: undefined,
    GRT_ID: undefined,
    LA_CBT_ID: undefined,
    ITIN: undefined,
  };

  return map[type];
};

export const getPatternErrorMessageForIdentificationType = (
  type: IdentificationType,
): string | undefined => {
  const map: EnumMap<IdentificationType, string | undefined> = {
    BN: undefined, // 'Must have format XXXXXXXXX',
    EIN: 'Must have format XX-XXXXXXX',
    GST_ID: undefined, // 'Must have format XXXXXXXXX RT XXXX',
    HST_ID: undefined, // 'Must have format XXXXXXXXX RT XXXX',
    PST_ID: undefined, // 'Must have format PST-XXXX-XXXX',
    QST_ID: undefined, // 'Must have format XXXXXXXXXX TQ XXXX',
    SIN: undefined, // 'Must have format XXX-XXX-XXXX',
    FNIN: undefined,
    SSN: 'Please enter a valid SSN in XXX-XX-XXXX format',
    TIN: 'Must have format XXX-XX-XXXX',
    B_AND_O_ID: undefined,
    CBJ_ID: undefined,
    GET_ID: undefined,
    GRT_ID: undefined,
    LA_CBT_ID: undefined,
    ITIN: undefined,
  };

  return map[type];
};

export const getIdValidations = (id: IdentificationType) => {
  const value = getPatternForIdentificationType(id)!;
  const message = getPatternErrorMessageForIdentificationType(id)!;

  if (!value || !message) {
    return {};
  }

  return {
    pattern: {
      value,
      message,
    },
  };
};

export const getMaskTypeForIdentificationType = (id: IdentificationType) => {
  const map: EnumMap<IdentificationType, string | undefined> = {
    BN: undefined, // '999999999',
    EIN: '99-9999999',
    GST_ID: undefined, // '999999999 RT 9999',
    HST_ID: undefined, // '999999999 RT 9999',
    PST_ID: undefined, // 'PST-9999-9999',
    QST_ID: undefined, // '9999999999 TQ 9999',
    SIN: undefined, // '999-999-999',
    FNIN: undefined,
    SSN: '999-99-9999',
    TIN: '999-99-9999',
    B_AND_O_ID: undefined,
    CBJ_ID: undefined,
    GET_ID: undefined,
    GRT_ID: undefined,
    LA_CBT_ID: undefined,
    ITIN: undefined,
  };

  return map[id] || '';
};

export const filterByNameOrEmail = (
  agentsToFilterFrom: AgentInfo[],
  filter: string,
): AgentInfo[] => {
  return agentsToFilterFrom?.filter(
    (agent) =>
      agent?.firstName?.toLowerCase().includes(filter.toLowerCase()) ||
      agent?.lastName?.toLowerCase().includes(filter.toLowerCase()) ||
      agent?.middleName?.toLowerCase().includes(filter.toLowerCase()) ||
      agent?.emailAddress?.toLowerCase().includes(filter.toLowerCase()),
  );
};

export const isImageValid = (
  e: ChangeEvent<HTMLInputElement>,
  maxSize: number = MAX_FILE_SIZE,
) => {
  return e.target!.files![0] && e.target!.files![0]?.size < maxSize;
};

export const isDraggableImageValid = (e: DragEvent<HTMLDivElement>) => {
  return (
    e.dataTransfer!.files![0] && e.dataTransfer!.files![0]?.size < MAX_DOC_SIZE
  );
};

export const isImageMimeTypeValid = (e: ChangeEvent<HTMLInputElement>) => {
  return (
    (e.target!.files![0] && e.target!.files![0]?.type === 'image/png') ||
    e.target!.files![0]?.type === 'image/jpeg'
  );
};

export const isDraggableImageMimeTypeValid = (e: DragEvent<HTMLDivElement>) => {
  return (
    (e.dataTransfer!.files![0] &&
      e.dataTransfer!.files![0]?.type === 'image/png') ||
    e.dataTransfer!.files![0]?.type === 'image/jpeg'
  );
};

export const taxStatusToVariant: EnumMap<string, PillVariantType> = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
};

export const getTaxType = (
  type: TaxDocumentResponseTaxDocumentTypeEnum,
): string | undefined => {
  const taxTypeToString: EnumMap<
    TaxDocumentResponseTaxDocumentTypeEnum,
    string
  > = {
    TEN_99: '1099-NEC',
    T4A: 'T4A',
  };

  return taxTypeToString[type];
};

export const isAgentActive = (
  status: AgentResponseAgentStatusEnum = AgentResponseAgentStatusEnum.Active,
) => {
  const agentStatusMapp: EnumMap<AgentResponseAgentStatusEnum, boolean> = {
    ACTIVE: true,
    INACTIVE: false,
    CANDIDATE: false,
    REJECTED: false,
  };

  return agentStatusMapp[status];
};

export const isSlugAvailable = async (slug: string) => {
  const { data } = await new AgentControllerApi(
    getYentaConfiguration(),
  ).isSlugAvailable(slug);

  return !data.available ? 'Slug is already taken' : undefined;
};

export const validateSlug = async (slug: string) => {
  if (/^[-_].*$/.test(slug)) {
    return 'Slug should not starts with underscore or hyphen';
  } else if (/^[a-zA-Z0-9]*[-_]+$/.test(slug)) {
    return 'Slug should not ends with underscore or hyphen';
  } else if (/^[a-zA-Z0-9]*[_-][_-].*$/.test(slug)) {
    return 'Hyphen and underscore should not appear consecutively';
  } else if (!/^[a-zA-Z0-9-_]+$/.test(slug)) {
    return 'Special characters and spaces are not allowed';
  } else {
    return isSlugAvailable(slug);
  }
};

export const isCanadianUser = (
  country: AgentResponseAccountCountryEnum,
): boolean => {
  return country === AgentResponseAccountCountryEnum.Canada;
};

export const hasError = (errors: any, fields: string[]): boolean => {
  return Object.keys(errors)
    .filter((key) => fields.includes(key))
    .some((key) => !!errors[key]);
};

export const isAgentLicenceExpired = (agent: AgentResponse): boolean => {
  if (agent.licenses?.length) {
    for (const license of agent.licenses) {
      const expirationDate = DateTime.fromISO(license.expirationDate!);
      const yesterday = getYesterdayDate();

      if (expirationDate < yesterday) {
        return true;
      }
    }
  }

  return false;
};

export const createLoginAsToken = (token: string, value: string): string => {
  return `${token}~~~!!!${value}`;
};

export const getAllParticipants = (
  transaction: TransactionResponse,
): Array<PaymentParticipantValue | ParticipantValue> => {
  return [
    ...(transaction?.paymentParticipants || []),
    ...(transaction?.otherParticipants || []),
  ];
};

export const getAllRealParticipantsFromTransaction = (
  transaction: TransactionResponse,
): ISelectOption[] => {
  const allParticipants = getAllParticipants(transaction);

  const realParticipants = allParticipants.filter(
    (participant) => !!participant.yentaId,
  );

  return realParticipants.map((val) => ({
    label: `${getParticipantName(val)} - (${capitalizeEnum(val.role!)})`,
    value: val.yentaId!,
  }));
};

export const findParticipant = (
  transaction: TransactionResponse,
  participantId: string,
) => {
  const allParticipant = getAllParticipants(transaction);

  return allParticipant.find((participant) => participant.id === participantId);
};

export const findParticipantByYentaId = (
  transaction: TransactionResponse,
  yentaId: string,
) => {
  const allParticipant = getAllParticipants(transaction);

  return allParticipant.find((participant) => participant.yentaId === yentaId);
};

export const getFullName = <
  T extends { firstName?: string; lastName?: string }
>(
  agent: T | undefined,
) => {
  if (!agent || !(agent.firstName || agent.lastName)) {
    return null;
  }

  return [agent.firstName, agent.lastName].join(' ');
};

export const getInformationAsOfDate = (
  incomeOverview: IncomeOverviewResponse | undefined,
  incomeTotals: IncomeTotalsResponse | undefined,
  revShareOverview: RevShareOverviewResponse | undefined,
) => {
  const earlierDate = Math.min(
    incomeOverview?.asOf!,
    incomeTotals?.asOf!,
    revShareOverview?.asOf!,
  );

  return DateTime.fromMillis(earlierDate)
    .toFormat('LL/dd/yy hh:mma')
    .toLowerCase();
};

export const getAllBuyersFromTransaction = (
  transaction: TransactionResponse,
): string | undefined => {
  const buyersList = transaction.participants?.filter(
    (buyers) =>
      buyers.participantRole === ParticipantResponseParticipantRoleEnum.Buyer,
  );

  return buyersList?.map((buyer) => getParticipantName(buyer)).join(', ');
};

export const isAgentClientApiKeyExpired = (date: string): boolean => {
  const expirationDate = DateTime.fromISO(date);
  const yesterday = getYesterdayDate();
  if (expirationDate < yesterday) {
    return true;
  }
  return false;
};

export function getAddressBasedOnPriority(
  addresses: AddressResponse[],
): AddressResponse | undefined {
  const officeAddress = findLast(
    addresses,
    (addr) => addr.type === AgentAddressTypeEnum.OFFICE,
  );
  if (!!officeAddress) {
    return officeAddress;
  }
  const homeAddress = findLast(
    addresses,
    (addr) => addr.type === AgentAddressTypeEnum.HOME,
  );
  if (!!homeAddress) {
    return homeAddress;
  }
  return findLast(
    addresses,
    (addr) => addr.type === AgentAddressTypeEnum.MAILING,
  );
}

export const isAutoAgentDebt = (type: AgentDebtResponseRecoveryTypeEnum) => {
  return [
    AgentDebtResponseRecoveryTypeEnum.AllPayments,
    AgentDebtResponseRecoveryTypeEnum.Commission,
    AgentDebtResponseRecoveryTypeEnum.Revshare,
  ].includes(type);
};

export const getRecoveryTypeToDisplayName = (
  type:
    | AgentDebtCreateRequestRecoveryTypeEnum
    | AgentDebtResponseRecoveryTypeEnum,
) => {
  const typeToNameMap: EnumMap<
    AgentDebtCreateRequestRecoveryTypeEnum | AgentDebtResponseRecoveryTypeEnum,
    string
  > = {
    ALL_PAYMENTS: 'All Payments (Auto)',
    COMMISSION: 'Commission (Auto)',
    REVSHARE: 'Revenue Share (Auto)',
    INVOICE: 'Invoice (Stripe)',
  };

  return safeEnumMapGet(typeToNameMap, type, 'All Payments (Auto)');
};

export const getRepaymentItemURL = (
  item: DebtRepaymentItemResponse,
  agentId: string,
) => {
  const paymentSourceTypeToURLMap: EnumMap<
    DebtRepaymentItemResponsePaymentSourceTypeEnum,
    string
  > = {
    COMMISSION: `/transactions/${item?.paymentSourceId}`,
    REVSHARE: `/people/${agentId}/revshare/payments/${item?.paymentSourceId}/debt-repayment`,
    INVOICE: item?.invoiceDownloadUrl!,
  };

  return safeEnumMapGet(
    paymentSourceTypeToURLMap,
    item?.paymentSourceType!,
    `/transactions/${item?.paymentSourceId}`,
  );
};

export const getSortedNetworks = (agents: FrontLineAgentResponse[]) => {
  return sortBy(agents, [
    (agent) => {
      return !agent.unlocking;
    },
    (agent) => {
      return (
        agent.status === FrontLineAgentResponseStatusEnum.Inactive ||
        agent.status === FrontLineAgentResponseStatusEnum.Candidate ||
        agent.status === FrontLineAgentResponseStatusEnum.Rejected
      );
    },
    (agent) => {
      return (
        agent.status === FrontLineAgentResponseStatusEnum.Active ||
        agent.status === FrontLineAgentResponseStatusEnum.Candidate ||
        agent.status === FrontLineAgentResponseStatusEnum.Rejected
      );
    },
    'lastName',
  ]);
};

export const getCommissionAmount = (
  country: AgentResponseAccountCountryEnum,
) => {
  const countryToAmountMap: EnumMap<AgentResponseAccountCountryEnum, string> = {
    UNITED_STATES: '$450',
    CANADA: '$650',
  };

  const commissionAmount = safeEnumMapGet(countryToAmountMap, country, '$450');

  return commissionAmount;
};

export const getAgentFullName = (agent: AgentResponse) => {
  let name = 'N/A';

  if (isStringPresent(agent?.firstName) || isStringPresent(agent?.lastName)) {
    name = [agent?.firstName, agent?.lastName].filter((n) => !!n).join(' ');
  } else if (isStringPresent(agent?.company)) {
    name = agent?.company;
  }

  return name;
};

export const isAgentRejectedOrCandidate = (
  agentStatus: AgentResponseAgentStatusEnum,
) => {
  const rejectedCandidateRoles = [
    AgentResponseAgentStatusEnum.Candidate,
    AgentResponseAgentStatusEnum.Rejected,
  ];
  return rejectedCandidateRoles.includes(agentStatus);
};

export const administrativeAreaList = (agent: AgentResponse) => {
  const list: any[] = [];
  agent?.applications?.forEach((e) => {
    const areasList =
      e.doesBusinessInExtended?.map((evt) => {
        if (!evt.licenseResponse?.administrativeArea?.stateOrProvince) {
          return 'N/A';
        }
        return STATE_OR_PROVINCE_ABBREVIATIONS[
          evt.licenseResponse?.administrativeArea?.stateOrProvince
        ];
      }) || [];
    list.push(...areasList);
  });
  return list;
};

export const getCapCycleRemainingBalance = (
  capStatus: CapStatusValue | undefined,
  displayAmountOptions: DisplayAmountOptions,
): string => {
  const { capAmount, capAmountPaid } = capStatus || {};
  const amountPaid = capAmountPaid?.amount || 0;
  const total = capAmount?.amount || 0;
  const value = {
    amount: total - amountPaid,
    currency: capAmountPaid?.currency,
  };
  return 'Balance = ' + displayAmount(value, displayAmountOptions);
};

export const getForceMFAStatusMessage = (
  isForceMFAEnabled: boolean,
  fullname: string,
) => {
  return isForceMFAEnabled
    ? `${fullname} is already having force MFA enabled.`
    : `${fullname} is not having force MFA enabled.`;
};

export const isDesignatedBrokerOfOffice = (
  userDetail: AgentResponse,
  brokerOfficeGroupsById: Mapping<OfficeGroupResponse>,
  officeId: string,
): boolean => {
  const brokerOffices = (userDetail?.offices || [])?.filter((office) => {
    const isMemberOfBrokerTeam = brokerOfficeGroupsById?.[
      office?.id!
    ]?.groups?.some(
      (group) =>
        group.groupName === 'Broker Team' &&
        group?.groupMembers?.some(
          (member) => member?.userInfo?.agentId === userDetail.id,
        ),
    );

    return (
      office?.designatedBroker?.id === userDetail.id ||
      office?.managingBroker?.id === userDetail.id ||
      isMemberOfBrokerTeam
    );
  });

  return brokerOffices?.some((office) => office?.id === officeId) || false;
};

export const getTransactionGraphDataItems = (
  transactionsCountByLifecycleGroup:
    | TransactionsCountByLifecycleGroupType
    | ListingCountsByLifecycleGroupType,
) => {
  const graphDataItems = [];

  const zeroTransactions =
    transactionsCountByLifecycleGroup.OPEN === 0 &&
    transactionsCountByLifecycleGroup.DRAFT === 0 &&
    transactionsCountByLifecycleGroup.CLOSED === 0;

  const openItemsFillColor = '#304676';
  const closedItemsFillColor = '#3B82F6';
  const draftItemsFillColor = '#54C5EB';
  const noItemsFillColor = '#BFC3CA';

  if (zeroTransactions) {
    graphDataItems?.push({
      name: 'Zero',
      value: 1,
      fill: noItemsFillColor,
    });
  } else {
    graphDataItems?.push({
      name: 'Active',
      value: transactionsCountByLifecycleGroup.OPEN,
      fill: openItemsFillColor,
    });
    graphDataItems?.push({
      name: 'Closed',
      value: transactionsCountByLifecycleGroup.CLOSED,
      fill: closedItemsFillColor,
    });
    graphDataItems?.push({
      name: 'Draft',
      value: transactionsCountByLifecycleGroup.DRAFT,
      fill: draftItemsFillColor,
    });
  }

  return graphDataItems;
};

export const getAgentCapReachedDate = (
  incomeOverview?: IncomeOverviewResponse,
) => {
  const cappedAt = incomeOverview?.capStatus?.cappedAt;

  if (!!incomeOverview?.capStatus?.capped && !!cappedAt) {
    return cappedAt;
  }

  return undefined;
};
