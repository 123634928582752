import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PulseGif from '../../../../assets/img/roar/pulse-gif.gif';
import useAgentsInfo from '../../../../hooks/useAgentsInfo';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import {
  CommentDto,
  MentionBlock,
  VoiceCallBlock,
} from '../../../../openapi/yada';
import { setRecordingDataById } from '../../../../slices/TransactionSlice';
import { AppDispatch, FeatureFlagTypeEnum, RootState } from '../../../../types';
import { getFullName } from '../../../../utils/AgentHelper';
import { cn } from '../../../../utils/classUtils';
import {
  canDeleteVoiceRecording,
  isCallCancelled,
  isCallerSameAsUser,
  isTranscriptAvailable,
  isVoiceCall,
} from '../../../../utils/RoarUtils';
import ExcludeMortgagerUserRole from '../../../auth/ExcludeMortgagerUserRole';
import FeatureFlagEnabledOnly from '../../../FeatureFlagEnabledOnly';
import Hover from '../../../Hover';
import ZenCommentReactions from '../ZenCommentReactions';
import { ZenCommentSectionContext } from '../ZenCommentSectionLayout';
import VoiceCallDisclaimer from './VoiceCallDisclaimer';
import VoiceCallHeader from './VoiceCallHeader';
import VoiceCallPlayer from './VoiceCallPlayer';
import VoiceCallTranscription from './VoiceCallTranscription';

type VoiceCallCardProps = {
  comment: CommentDto;
  recordingBlock: VoiceCallBlock;
  mentionBlock: MentionBlock;
  headerElement: React.ReactElement;
  footerElement: React.ReactElement;
  onDeleteOptionClick?: () => void;
  avatarComponent?: React.ReactElement;
};

const VoiceCallCard: React.FC<VoiceCallCardProps> = ({
  comment,
  recordingBlock,
  mentionBlock,
  headerElement,
  footerElement,
  onDeleteOptionClick,
  avatarComponent,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [
    isReactionsPopperVisible,
    setIsReactionsPopperVisible,
  ] = useState<boolean>(false);

  const agentById = useSelector((state: RootState) => state.userIds.agentById);
  const isAdmin = useSelector((state: RootState) => state.auth.isAdmin);
  const isBroker = useSelector((state: RootState) => state.auth.isBroker);
  const userDetails = useSelector((state: RootState) => state.auth.userDetail);

  // @ts-expect-error voiceCallId has incorrect type
  const recordingId: string = recordingBlock.voiceCallId;

  const speakerIds = useMemo(() => {
    const speakers = recordingBlock.recordingTranscription?.speakers || [];
    const arr = [];
    for (const speaker of speakers) {
      if (speaker.userId) {
        arr.push(speaker.userId);
      }
    }
    return arr;
  }, [recordingBlock?.recordingTranscription?.speakers]);

  const agentIds = useMemo(() => {
    const arr: string[] = [];
    if (recordingBlock.callerId) {
      arr.push(recordingBlock.callerId);
    }
    if (recordingBlock.calleeId) {
      arr.push(recordingBlock.calleeId);
    }
    if (arr.length < 2) {
      arr.push(mentionBlock.userId!);
    }
    return arr;
  }, [mentionBlock.userId, recordingBlock.calleeId, recordingBlock.callerId]);

  const userIds = useMemo(() => [...agentIds, ...speakerIds], [
    agentIds,
    speakerIds,
  ]);

  const agents = useMemo(() => {
    return agentIds.map((agentId) => {
      const agentInfo = agentById[agentId];
      const mentioned = agentId === mentionBlock?.userId;
      const name = mentioned ? mentionBlock?.name : getFullName(agentInfo);

      return {
        name: name || '',
        firstName: agentInfo?.firstName,
        lastName: agentInfo?.lastName,
        avatar: agentInfo?.avatar,
        showPlaceHolder: !name,
      };
    });
  }, [agentById, agentIds, mentionBlock.name, mentionBlock?.userId]);

  const { loading } = useAgentsInfo(userIds);

  const callCancelled = isCallCancelled(recordingBlock);

  const {
    setVoiceCallTranscriptionData,
    setEditVoiceCallSpeakers,
  } = useContext(ZenCommentSectionContext);

  const showTranscriptionDetails = useCallback(() => {
    setVoiceCallTranscriptionData({
      recordingId,
    });
  }, [recordingId, setVoiceCallTranscriptionData]);

  useEffect(() => {
    const transcription = recordingBlock.recordingTranscription;

    dispatch(
      setRecordingDataById({
        id: recordingId,
        recordingBlock,
        mentionBlock,
      }),
    );

    if (
      isBroker &&
      !isEmpty(transcription?.transcript?.segments) &&
      isEmpty(transcription?.speakers)
    ) {
      setEditVoiceCallSpeakers({ recordingId });
    }
  }, [
    dispatch,
    isBroker,
    mentionBlock,
    recordingBlock,
    recordingId,
    setEditVoiceCallSpeakers,
  ]);

  const allowActions =
    canDeleteVoiceRecording(recordingBlock, userDetails) || isAdmin;

  const isAdminOrBroker = isAdmin || isBroker;

  const callerSameAsUser = isCallerSameAsUser(recordingBlock, userDetails);

  const isRoarFullRelease = useFeatureFlag(
    FeatureFlagTypeEnum.ROAR_FULL_RELEASE_BOLT,
  );

  return (
    <div
      className={cn(
        'mb-4 flex flex-col items-start space-y-1',
        callerSameAsUser && 'items-end',
      )}
      onMouseEnter={() => isRoarFullRelease && setIsHovered(true)}
      onMouseLeave={() => isRoarFullRelease && setIsHovered(false)}
    >
      {!callerSameAsUser &&
        (callCancelled ? (
          headerElement
        ) : (
          <img src={PulseGif} width={35} height={35} alt='pulse' />
        ))}
      <div className='flex items-start w-full max-w-xl relative'>
        <div className='flex-1'>
          {!callCancelled && !isAdminOrBroker && <VoiceCallDisclaimer />}
          <div
            className={cn(
              'bg-white font-inter py-4 px-3 border border-regent-300 rounded-xl space-y-4',
              allowActions && 'pb-2',
              callCancelled || isAdminOrBroker ? 'mt-0.5' : '-mt-2',
            )}
          >
            <VoiceCallHeader
              recordingBlock={recordingBlock}
              mentionBlock={mentionBlock}
              isLoading={loading}
              agents={agents}
            />
            {!callCancelled && (
              <>
                {!!recordingBlock.recordingUrl && (
                  <VoiceCallPlayer
                    id={recordingBlock.voiceCallId!}
                    url={recordingBlock.recordingUrl!}
                    duration={recordingBlock.recordingDurationSecs!}
                  />
                )}
                <VoiceCallTranscription
                  recordingBlock={recordingBlock}
                  showTranscriptionDetails={showTranscriptionDetails}
                />
              </>
            )}
            <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.ROAR}>
              {allowActions && (
                <div
                  className='!mt-1 flex justify-end'
                  aria-label='comment-options'
                >
                  <Hover
                    hoverComponent={
                      <p className='text-base font-semibold font-zen-body text-zen-dark-12 px-2'>
                        Delete
                      </p>
                    }
                    config={{ trigger: 'hover', placement: 'top' }}
                  >
                    <button
                      data-testid='deleteIcon'
                      className='cursor-pointer hover:bg-grey-200 rounded-full p-1'
                      onClick={onDeleteOptionClick}
                    >
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        className='text-coral-red'
                        fontSize={16}
                        width={24}
                        height={24}
                      />
                    </button>
                  </Hover>
                </div>
              )}
            </FeatureFlagEnabledOnly>
          </div>
          <div className='w-full'>{footerElement}</div>
        </div>
        {callerSameAsUser &&
          (callCancelled ? (
            <div className='ml-2 mt-1'>{avatarComponent}</div>
          ) : (
            <img src={PulseGif} width={35} height={35} alt='pulse' />
          ))}
        <ExcludeMortgagerUserRole>
          <FeatureFlagEnabledOnly
            flag={FeatureFlagTypeEnum.ROAR_FULL_RELEASE_BOLT}
          >
            <div
              onMouseEnter={() => setIsReactionsPopperVisible(true)}
              onMouseLeave={() => setIsReactionsPopperVisible(false)}
            >
              <div
                className={cn(
                  'absolute bottom-5',
                  callerSameAsUser ? 'left-5' : 'right-5',
                  !callerSameAsUser &&
                    isVoiceCall(recordingBlock) &&
                    isTranscriptAvailable(recordingBlock) &&
                    'bottom-2',
                )}
              >
                <ZenCommentReactions
                  comment={comment}
                  isHovered={isHovered}
                  isReactionsPopperVisible={isReactionsPopperVisible}
                />
              </div>
            </div>
          </FeatureFlagEnabledOnly>
        </ExcludeMortgagerUserRole>
      </div>
    </div>
  );
};

export default VoiceCallCard;
