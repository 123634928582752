import { faClock, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  OutgoingPaymentResponseStatusEnum,
  SearchOutgoingPaymentResponseStatusEnum,
} from '../../../openapi/arrakis';
import { PartialEnumMap } from '../../../types';
import { safePartialEnumMapGet } from '../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenStatusPill, { PillVariantType } from '../../Zen/ZenStatusPill';

export interface ZenOutgoingPaymentStatusCellProps {
  type:
    | OutgoingPaymentResponseStatusEnum
    | SearchOutgoingPaymentResponseStatusEnum;
}

const typeToVariant: PartialEnumMap<
  OutgoingPaymentResponseStatusEnum | SearchOutgoingPaymentResponseStatusEnum,
  PillVariantType
> = {
  [OutgoingPaymentResponseStatusEnum.Deleted]: 'danger',
  [OutgoingPaymentResponseStatusEnum.Disputed]: 'danger',
  [OutgoingPaymentResponseStatusEnum.Pending]: 'warning',
  [OutgoingPaymentResponseStatusEnum.ScheduledForPayment]: 'success',
  [OutgoingPaymentResponseStatusEnum.SentToPaymentSystem]: 'primary',
  [OutgoingPaymentResponseStatusEnum.GeneralError]: 'danger',
  [OutgoingPaymentResponseStatusEnum.SchedulingError]: 'danger',
  [OutgoingPaymentResponseStatusEnum.Open]: 'primary',
  [OutgoingPaymentResponseStatusEnum.Paid]: 'success',
  [OutgoingPaymentResponseStatusEnum.Rejected]: 'danger',
  [OutgoingPaymentResponseStatusEnum.Cancelled]: 'danger',
  [OutgoingPaymentResponseStatusEnum.Initiated]: 'default',
  [OutgoingPaymentResponseStatusEnum.InvalidPaymentDetails]: 'danger',
};

const typeToIcon: PartialEnumMap<
  OutgoingPaymentResponseStatusEnum | SearchOutgoingPaymentResponseStatusEnum,
  React.ReactElement
> = {
  [OutgoingPaymentResponseStatusEnum.Deleted]: (
    <FontAwesomeIcon icon={faXmark} />
  ),
  [OutgoingPaymentResponseStatusEnum.Disputed]: (
    <FontAwesomeIcon icon={faXmark} />
  ),
  [OutgoingPaymentResponseStatusEnum.Pending]: (
    <FontAwesomeIcon icon={faCircleInfo} />
  ),
  [OutgoingPaymentResponseStatusEnum.ScheduledForPayment]: (
    <FontAwesomeIcon icon={faClock} />
  ),
  [OutgoingPaymentResponseStatusEnum.SentToPaymentSystem]: (
    <FontAwesomeIcon icon={faClock} />
  ),
  [OutgoingPaymentResponseStatusEnum.GeneralError]: (
    <FontAwesomeIcon icon={faXmark} />
  ),
  [OutgoingPaymentResponseStatusEnum.SchedulingError]: (
    <FontAwesomeIcon icon={faXmark} />
  ),
  [OutgoingPaymentResponseStatusEnum.Open]: (
    <FontAwesomeIcon icon={faCircleInfo} />
  ),
  [OutgoingPaymentResponseStatusEnum.Paid]: (
    <FontAwesomeIcon icon={faCheckCircle} />
  ),
  [OutgoingPaymentResponseStatusEnum.Rejected]: (
    <FontAwesomeIcon icon={faXmark} />
  ),
  [OutgoingPaymentResponseStatusEnum.Cancelled]: (
    <FontAwesomeIcon icon={faXmark} />
  ),
  [OutgoingPaymentResponseStatusEnum.Initiated]: (
    <FontAwesomeIcon icon={faCircleInfo} />
  ),
  [OutgoingPaymentResponseStatusEnum.InvalidPaymentDetails]: (
    <FontAwesomeIcon icon={faXmark} />
  ),
};

const ZenOutgoingPaymentStatusCell: React.FC<ZenOutgoingPaymentStatusCellProps> = ({
  type,
}) => {
  const variant: PillVariantType = safePartialEnumMapGet(
    typeToVariant,
    type,
    'warning',
  );
  const icon: React.ReactElement = safePartialEnumMapGet(
    typeToIcon,
    type,
    <FontAwesomeIcon icon={faCircleInfo} />,
  );

  return (
    <ZenStatusPill
      text={capitalizeEnum(type)}
      variant={variant!}
      icon={icon!}
    />
  );
};

export default ZenOutgoingPaymentStatusCell;
