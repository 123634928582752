import { PaymentSourceTypeEnum } from '../../../openapi/wallet';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumMultiSelectColumnGenerator from './EnumMultiSelectColumnGenerator';

const WalletPaymentSourceColumnFilter = <D extends object>(
  props: SelectColumnFilterProps<D>,
) => {
  return EnumMultiSelectColumnGenerator<D>(PaymentSourceTypeEnum)(props);
};

export default WalletPaymentSourceColumnFilter;
