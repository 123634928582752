import {
  OutgoingPaymentResponseStatusEnum,
  OutgoingPaymentWithBankInfoResponseStatusEnum,
  SearchOutgoingPaymentResponseStatusEnum,
} from '../openapi/arrakis';
import { safeEnumMapGet } from './EnumHelper';

export const isPaymentInErrorState = (
  status:
    | OutgoingPaymentResponseStatusEnum
    | OutgoingPaymentWithBankInfoResponseStatusEnum
    | SearchOutgoingPaymentResponseStatusEnum,
): boolean => {
  const statusMap: Record<OutgoingPaymentResponseStatusEnum, boolean> = {
    CANCELLED: false,
    DELETED: false,
    DISPUTED: false,
    GENERAL_ERROR: true,
    INITIATED: false,
    OPEN: false,
    PAID: false,
    PENDING: false,
    REJECTED: false,
    SCHEDULED_FOR_PAYMENT: false,
    SCHEDULING_ERROR: true,
    SENT_TO_PAYMENT_SYSTEM: false,
    INVALID_PAYMENT_DETAILS: true,
    PENDING_APPROVAL: false,
    WALLET_SCHEDULING_ERROR: false,
  };

  return safeEnumMapGet(statusMap, status, false);
};
