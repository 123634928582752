import { cn } from '../../utils/classUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import {
  TCInvitationStatusEnum,
  TCInvitationStatusPillInfo,
} from '../../utils/TransactionCoordinatorUtils';

export interface TCInvitationStatusPillProps {
  status: TCInvitationStatusEnum;
}

const TCInvitationStatusPill: React.FC<TCInvitationStatusPillProps> = ({
  status,
}) => {
  const { icon, textClassName } = TCInvitationStatusPillInfo[status];

  return (
    <div className={cn('flex items-center gap-1 w-fit', textClassName)}>
      {icon}
      <p className='font-inter text-sm font-medium'>
        {capitalizeEnum(TCInvitationStatusEnum[status])}
      </p>
    </div>
  );
};

export default TCInvitationStatusPill;
