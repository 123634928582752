import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faAddressCard, faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { AgentResponse } from '../../openapi/yenta';
import { useLogout } from '../../hooks/useLogout';
import { FeatureFlagTypeEnum } from '../../types';
import { useGemini } from '../../hooks/useGemini';
import { cn } from '../../utils/classUtils';
import ExcludeMortgagerUserRole from '../auth/ExcludeMortgagerUserRole';
import ZenSideBarMenuItem from '../Menu/ZenMenu/ZenSideBarMenuItem';
import FeatureFlagEnabledOnly from '../FeatureFlagEnabledOnly';
import BrokerOnly from '../auth/BrokerOnly';
import ZenDoNotDisturbMenuItem from '../Roar/OfficeHours/menu/ZenDoNotDisturbMenuItem';
import { showSingleCheckPreference } from '../../utils/SingleCheckUtils';
import { ZenDemoModeMenuItem } from '../Menu/ZenMenu/ZenDemoModeMenuItem';
import ZenAvatar from '../Zen/ZenAvatar';

interface Props {
  userDetail: AgentResponse;
}
const NeoSideBarUserControl: React.FC<Props> = ({ userDetail }) => {
  const logout = useLogout();

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const isMsDynamicsFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.MS_DYNAMICS_ONBOARDING,
  );
  const isGeminiEnabled = useGemini();

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['top']}
      align='end'
      onClickOutside={() => setIsPopoverOpen(false)}
      containerClassName='fixed z-50'
      content={({ position, childRect, popoverRect }) => {
        return (
          <ArrowContainer
            position={position}
            popoverRect={popoverRect}
            childRect={childRect}
            arrowColor='#262626'
            arrowSize={10}
            arrowStyle={{ bottom: 2 }}
            className='popover-arrow-container'
          >
            <div
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              className={cn(
                '-ml-1 rounded-lg w-[184px] py-1',
                isGeminiEnabled ? 'bg-regent-900' : 'bg-zen-dark-22',
              )}
            >
              <ExcludeMortgagerUserRole>
                <Link
                  to={`/people/${userDetail?.id}`}
                  onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                  <ZenSideBarMenuItem
                    icon={<FontAwesomeIcon icon={faAddressCard} />}
                    label='Agent Profile'
                  />
                </Link>

                <Link
                  to='/about'
                  onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                  <ZenSideBarMenuItem
                    icon={
                      <FontAwesomeIcon
                        icon={regular('user-magnifying-glass')}
                      />
                    }
                    label='About'
                  />
                </Link>
                <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.ROAR}>
                  <BrokerOnly>
                    <Link
                      to={`/people/${userDetail?.id}/office-hours`}
                      onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                    >
                      <ZenSideBarMenuItem
                        icon={<FontAwesomeIcon icon={faBuilding} />}
                        label='Office Hours'
                      />
                    </Link>
                    <ZenDoNotDisturbMenuItem />
                  </BrokerOnly>
                </FeatureFlagEnabledOnly>

                {(isMsDynamicsFlagEnabled ||
                  showSingleCheckPreference(userDetail)) && (
                  <Link
                    to={`/people/${userDetail?.id}/payment-settings`}
                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                  >
                    <ZenSideBarMenuItem
                      icon={
                        <FontAwesomeIcon
                          icon={regular('money-bill-wave')}
                          className='mr-1'
                        />
                      }
                      label='Payment Settings'
                    />
                  </Link>
                )}

                <Link
                  to={`/people/${userDetail?.id}/security-settings`}
                  onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                  <ZenSideBarMenuItem
                    icon={
                      <FontAwesomeIcon
                        icon={regular('lock')}
                        className='mr-1'
                      />
                    }
                    label='Security Settings'
                  />
                </Link>

                <ZenDemoModeMenuItem />
              </ExcludeMortgagerUserRole>

              <button
                className='w-full -mt-1 font-zen-body-2'
                type='button'
                onClick={async () => await logout()}
              >
                <ZenSideBarMenuItem
                  icon={
                    <FontAwesomeIcon
                      icon={regular('arrow-right-from-bracket')}
                      className='ml-0.5'
                    />
                  }
                  label='Log out'
                />
              </button>
            </div>
          </ArrowContainer>
        );
      }}
    >
      <div
        aria-label='user_options'
        onClick={(e) => {
          e.stopPropagation();
          setIsPopoverOpen(!isPopoverOpen);
        }}
      >
        <div title='profile-avatar'>
          <ZenAvatar
            imageUrl={userDetail?.avatar}
            name={userDetail?.fullName!}
            size='sm'
          />
        </div>
      </div>
    </Popover>
  );
};

export default NeoSideBarUserControl;
