import { useEffect, useState } from 'react';
import { get, isEmpty, last, mergeWith } from 'lodash';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  FormFieldConfig,
  InputFieldType,
  RootState,
  YesNoType,
} from '../../../types';
import StepPagination from '../StepPagination';
import {
  AgentControllerApi,
  AgentResponseAccountCountryEnum,
  NationalIdentificationValue,
  NationalIdentificationValueTypeEnum,
} from '../../../openapi/yenta';
import {
  taxAndNationalIdCanadaFormQuestions,
  taxAndNationalIdUsaFormQuestions,
} from '../../../testUtils/OnboardingUtils';
import WelcomeField from '../welcomeOnboarding/WelcomeField';
import {
  updateNationalIdentifications,
  updateBusinessEntity,
  uploadDriverLicense,
} from '../../../slices/AgentSlice';
import { saveUserDetail } from '../../../slices/AuthSlice';
import { showSuccessToast } from '../../../slices/ToastNotificationSlice';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { delay } from '../../../utils/TableUtils';
import ZenControlledRadioInput from '../../Zen/Input/ZenControlledRadioInput';
import ZenControlledImageUpload from '../../Zen/Input/ZenControlledImageUploadInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import { TaxAndNationalIDFormDataType } from './TaxAndNationalIdForm';

export interface TaxAndNationalIDOnboardingFormStepsProps {
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  formData: Partial<TaxAndNationalIDFormDataType>;
  setFormData(formData: Partial<TaxAndNationalIDFormDataType>): void;
}

const TaxAndNationalIDOnboardingFormSteps: React.FC<TaxAndNationalIDOnboardingFormStepsProps> = ({
  currentIndex,
  setCurrentIndex,
  formData,
  setFormData,
}) => {
  const { control, handleSubmit, setValue, watch } = useForm();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const application = last(userDetail?.applications || []);
  const stateOrProvince = last(application?.doesBusinessInExtended || [])
    ?.licenseResponse?.administrativeArea?.stateOrProvince;

  const questions =
    userDetail?.accountCountry === AgentResponseAccountCountryEnum.Canada
      ? taxAndNationalIdCanadaFormQuestions(
          stateOrProvince,
          !!userDetail?.driverLicenseImagePath,
        )
      : taxAndNationalIdUsaFormQuestions(!!userDetail?.driverLicenseImagePath);

  const getSelectedValue = (name: string) => {
    return get(formData, name) || null;
  };

  const activeField = questions[currentIndex];
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(
    () => {
      if (!isEmpty(activeField)) {
        setValue(
          activeField?.name,
          get(formData, activeField?.name) || activeField?.defaultValue || '',
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeField?.defaultValue, activeField?.name, formData, setValue],
  );

  const paidToEntity = watch('paidEntity', '');

  const onSubmit = async (values: Partial<TaxAndNationalIDFormDataType>) => {
    setFormData(mergeWith(formData, values));
    if (
      currentIndex === questions.length - 1 ||
      paidToEntity === YesNoType.NO
    ) {
      setIsLoadingNext(true);
      const isCanada =
        userDetail?.accountCountry === AgentResponseAccountCountryEnum.Canada;

      const identifications: NationalIdentificationValue[] = isCanada
        ? [
            { id: formData.sin, type: NationalIdentificationValueTypeEnum.Sin },
            {
              id: formData.gstId,
              type: NationalIdentificationValueTypeEnum.GstId,
            },
            {
              id: formData.pstId,
              type: NationalIdentificationValueTypeEnum.PstId,
            },
            {
              id: formData.hstId,
              type: NationalIdentificationValueTypeEnum.HstId,
            },
            {
              id: formData.qstId,
              type: NationalIdentificationValueTypeEnum.QstId,
            },
          ].filter((item) => !!item.id)
        : [
            {
              id: formData.ssn,
              type: NationalIdentificationValueTypeEnum.Ssn,
            },
          ];

      if (formData?.driverLicense) {
        await dispatch(
          uploadDriverLicense(userDetail?.id!, formData?.driverLicense),
        );
      }

      await dispatch(
        updateNationalIdentifications(userDetail?.id!, {
          identifications: identifications,
        }),
      );

      if (formData.paidEntity === YesNoType.YES) {
        const nationalIds = isCanada
          ? [
              {
                id: formData.bnId,
                type: NationalIdentificationValueTypeEnum.Bn,
              },
              {
                id: formData.entityGstId,
                type: NationalIdentificationValueTypeEnum.GstId,
              },
              {
                id: formData.entityPstId,
                type: NationalIdentificationValueTypeEnum.PstId,
              },
              {
                id: formData.entityHstId,
                type: NationalIdentificationValueTypeEnum.HstId,
              },
              {
                id: formData.entityQstId,
                type: NationalIdentificationValueTypeEnum.QstId,
              },
            ].filter((item) => !!item.id)
          : [
              {
                id: formData.ein,
                type: NationalIdentificationValueTypeEnum.Ein,
              },
            ];

        await delay(500);

        await dispatch(
          updateBusinessEntity(userDetail?.id!, {
            name: formData.name,
            nationalIds: nationalIds,
          }),
        );
      }

      await delay(500);

      const { data } = await new AgentControllerApi(
        getYentaConfiguration(),
      ).updateNeedsTaxOnboarding(userDetail?.id!, {
        needsTaxOnboarding: false,
      });

      await dispatch(saveUserDetail(data));
      setIsLoadingNext(false);
      dispatch(showSuccessToast('Tax Information updated successfully.'));
      history.push('/');
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const renderField = (currentField: FormFieldConfig) => {
    if (currentField.type === InputFieldType.RADIO) {
      return (
        <ZenControlledRadioInput
          options={currentField.options || []}
          name={currentField.name}
          control={control}
          rules={currentField.rules}
          defaultValue=''
          inlineOptions
        />
      );
    }

    if (currentField.type === InputFieldType.IMAGE_UPLOAD) {
      return (
        <ZenControlledImageUpload
          name={currentField.name}
          control={control}
          uploadText='Upload License'
          rules={currentField?.rules}
        />
      );
    }

    return (
      <ZenControlledTextInput
        name={currentField.name}
        control={control}
        defaultValue={getSelectedValue(currentField.name!)}
        rules={currentField.rules!}
        placeholder={currentField.placeholder!}
      />
    );
  };

  const renderQuestions = () => {
    return (
      <div>
        <WelcomeField
          type={activeField.type}
          fields={renderField(activeField)}
          name={activeField.name}
          label={activeField.label!}
          naMessage={activeField.naMessage!}
          secondaryLabel={activeField.secondaryLabel!}
          helperText={activeField.helperText!}
          rules={activeField.rules!}
        />
        {activeField.extraFields
          ?.filter(
            (field) =>
              !field.shouldDisplayField || field.shouldDisplayField(watch()),
          )
          ?.map((field) => {
            return (
              <WelcomeField
                key={field.name}
                type={field.type}
                fields={renderField(field)}
                name={field.name}
                label={field.label!}
                naMessage={field.naMessage!}
                secondaryLabel={field.secondaryLabel!}
                helperText={field.helperText!}
                rules={field.rules!}
              />
            );
          })}
      </div>
    );
  };

  return (
    <div className='w-full h-full px-4 pt-5 flex flex-col justify-center items-center'>
      <div className='max-w-2xl w-full'>
        {currentIndex < questions.length && renderQuestions()}
        <div className='mt-5'>
          <StepPagination
            previousText={currentIndex !== 0 ? 'Previous' : ''}
            onPrevious={() => setCurrentIndex(currentIndex - 1)}
            nextText={
              currentIndex >= questions.length - 1 ||
              paidToEntity === YesNoType.NO
                ? 'Submit'
                : 'Next'
            }
            onNext={handleSubmit(onSubmit)}
            onSkip={() => onSubmit({ [activeField.name]: undefined })}
            showSkip={false}
            isLoadingNext={isLoadingNext}
          />
        </div>
      </div>
    </div>
  );
};

export default TaxAndNationalIDOnboardingFormSteps;
