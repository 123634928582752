import { groupBy, values } from 'lodash';
import { useEffect, useState } from 'react';
import { OfficePreviewResponse } from '../../../openapi/yenta';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenTabs from '../../Zen/ZenTabs';
import ZenOfficeBroker from './ZenOfficeBroker';

interface Props {
  agentId: string;
  offices: OfficePreviewResponse[];
}

const ZenOfficeTabs: React.FC<Props> = ({ agentId, offices }) => {
  const stateOffices = groupBy(offices, 'administrativeArea.stateOrProvince');
  const tabs = values(stateOffices).map((offices: OfficePreviewResponse[]) => {
    const state = offices[0].administrativeArea?.stateOrProvince!;
    return {
      name: capitalizeEnum(state),
      TabComponent: (
        <ZenOfficeBroker
          offices={offices!}
          agentId={agentId}
          officeState={state}
        />
      ),
    };
  });

  const tabName = tabs[0].name.toString();
  const [officeTab, setOfficeTab] = useState(tabName);

  useEffect(() => {
    // set active tab after removing/adding the office
    setOfficeTab(tabName);
  }, [offices, tabName]);

  return (
    <div>
      <ZenTabs
        tabs={tabs}
        selected={officeTab}
        onChange={setOfficeTab}
        size='sm'
      />
    </div>
  );
};

export default ZenOfficeTabs;
