import React from 'react';
import { cn } from '../../../utils/classUtils';
import LeoAnimatedOrb from './LeoAnimatedOrb';

export interface LeoAnimatedOrbLoaderProps {}

const LeoAnimatedOrbLoader: React.FC<LeoAnimatedOrbLoaderProps> = () => {
  return (
    <div className={cn('relative h-7 w-7')}>
      <div
        className='absolute animate-spin inset-0 rounded-full'
        style={{
          background: 'linear-gradient(180deg, #FFFFFF 0%, #4967FD 100%)',
        }}
      />
      <div className='absolute inset-0.5 bg-white rounded-full' />
      <div className='absolute ml-1 mt-1'>
        <LeoAnimatedOrb size='sm' />
      </div>
    </div>
  );
};

export default LeoAnimatedOrbLoader;
