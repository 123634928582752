import { faCircleInfo, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { SignatureWidgetModeEnum } from '../../types';
import { Button } from '../commons/Button';
import SignatureWidget from '../SignatureWidget/SignatureWidget';

export interface SignAssistandAmendmentModalProps {
  tcName?: string;
  tcId: string;
  selectedAccessPerference?: string;
  isOpen: boolean;
  onClose(): void;
  submitButtonText?: string;
  handleSendInvite?(): void;
}

const SignAssistandAmendmentModal: React.FC<SignAssistandAmendmentModalProps> = ({
  isOpen,
  onClose,
  submitButtonText = 'Submit',
  handleSendInvite,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'
      title='signing-modal'
    >
      <div className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40' />
      <div className='flex items-center justify-center font-inter w-full h-full lg:p-0 scrollbar overflow-scroll relative pl-1.5'>
        <div
          className='w-full md:w-[900px] relative shadow-zen-web bg-white rounded-xl z-10'
          aria-label='signing-modal'
        >
          <div className='flex flex-row items-center justify-between bg-regent-200 rounded-t-xl px-6 py-4'>
            <p className='text-primary-dark text-base md:text-lg font-semibold'>
              Sign Assistant Amendment
            </p>
            <div>
              <FontAwesomeIcon
                icon={faCircleXmark}
                className='text-grey-400 text-2xl cursor-pointer'
                onClick={() => onClose()}
              />
            </div>
          </div>
          <div className='flex flex-row gap-2.5 items-center bg-rezen-light-blue-100 px-6 md:px-6 py-3'>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className='text-regent-900 text-base'
            />
            <p className='font-inter text-sm md:text-base font-medium text-primary-navy'>
              The invitation will be sent after all necessary documents are
              signed by you. If the agent does not accept your invitation, it
              will become null and void.
            </p>
          </div>
          <div
            className='w-full flex flex-col h-96 md:h-[550px] overflow-y-hidden rounded-b-xl'
            aria-label='sign-amenment'
          >
            {/* DO Neccessary actions / create functions when API is ready */}
            <SignatureWidget
              token='1234grugktjgk12345'
              mode={SignatureWidgetModeEnum.SIGN}
              // onDocumentSigned={onDocumentSigned}
              // onDocumentAlreadySigned={onDocumentSigned}
              // showDownload
              // showWebHelperButtons
              isLoading={false}
            />
          </div>
          <div className='absolute bottom-0 w-full bg-grey-100 py-4 px-6 rounded-b-xl flex justify-end'>
            <div className='w-40'>
              <Button fullWidth color='blue' onClick={handleSendInvite}>
                {submitButtonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignAssistandAmendmentModal;
