import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowDownToLine,
  faArrowRotateLeft,
  faArrowUpRightFromSquare,
} from '@fortawesome/pro-regular-svg-icons';
import pluralize from 'pluralize';
import { ReactComponent as ZenEye } from '../../../assets/img/eye.svg';
import useAgentsInfo from '../../../hooks/useAgentsInfo';
import { FileApi, FileResponse } from '../../../openapi/dropbox';
import {
  FileReferenceInfo,
  ItemApi,
  ItemResponse,
} from '../../../openapi/sherlock';
import { downloadDropboxFileByVersion } from '../../../slices/DropboxSlice';
import { AppDispatch, FeatureFlagTypeEnum, RootState } from '../../../types';
import { getFullName } from '../../../utils/AgentHelper';
import { getDropboxFilesFromFileReferences } from '../../../utils/DropboxUtil';
import { getYentaImageUrl } from '../../../utils/ImgUtils';
import ZenDocumentDateCell from '../../dropbox/cell/ZenDocumentDateCell';
import ProfileImage from '../../ProfileImage';
import ZenFixedDataTable from '../ZenFixedDataTable';
import IconButton from '../../IconButton';
import {
  getDropboxConfiguration,
  getSherlockConfiguration,
} from '../../../utils/OpenapiConfigurationUtils';
import { ActionBarButtonsProps } from '../../TableRowActionBar';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';
import ErrorService from '../../../services/ErrorService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { getChecklistsWithFileReferences } from '../../../utils/ChecklistUtils';
import { fetchChecklistItemById } from '../../../slices/CheckListSlice';
import { downloadSelectedFiles } from '../../../utils/FileUtils';
import ZenCheckListDropboxDocumentVersions from './ZenChecklistDropboxDocumentVersions';
import ZenChecklistItemDocumentDropdown from './ZenChecklistDocumentDropdown';
import ChecklistDocumentErrorDetectionCell from './ChecklistDocumentErrorDetectionCell';

export interface ZenChecklistItemDropboxDocumentsListProps {
  checklistItem: ItemResponse;
  checklistId: string;
  dropboxId: string;
  secondaryDropboxId?: string;
}

const ZenChecklistItemDropboxDocumentsList: React.FC<ZenChecklistItemDropboxDocumentsListProps> = ({
  checklistItem,
  checklistId,
  dropboxId,
  secondaryDropboxId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    userIds: { agentById },
    dropbox: { dropboxById },
    checklist: { checklistsById },
  } = useSelector((state: RootState) => state);
  const [
    isShowingDocumentVersion,
    setIsShowingDocumentVersion,
  ] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<FileResponse>({});
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [checklistItemDropboxFiles, setChecklistItemDropboxFiles] = useState<
    FileResponse[]
  >([]);
  const [filesToBeDeleted, setFilesToBeDeleted] = useState<FileResponse[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isActionBarLoading, setIsActionBarLoading] = useState({
    download: false,
    preview: false,
    archive: false,
  });

  const isDropboxFilesTrashingEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.ARCHIVE_CHECKLIST_DROPBOX_FILES,
  );

  const automatedBrokerReviewEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.AUTOMATED_BROKER_REVIEW,
  );

  useAgentsInfo([
    ...checklistItemDropboxFiles.map(
      (file) => file.currentVersion?.uploadedBy!,
    ),
  ]);

  useEffect(() => {
    const dropboxFiles = getDropboxFilesFromFileReferences(
      checklistItem?.fileReferences?.references!,
      dropboxById!,
      dropboxId!,
    );
    setChecklistItemDropboxFiles(dropboxFiles);
  }, [checklistItem.fileReferences?.references, dropboxById, dropboxId]);

  const handleOpenFile = async (dropboxFile: FileResponse) => {
    await dispatch(
      downloadDropboxFileByVersion(
        dropboxFile?.id!,
        dropboxFile?.currentVersion?.id!,
      ),
    );
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const fileReferences: FileReferenceInfo[] = [];
      await Promise.all(
        filesToBeDeleted.map(async (file) => {
          fileReferences.push({
            fileId: file?.id!,
            filename: file?.filename!,
          });

          const checklistItems =
            checklistsById![checklistId]?.data?.items || [];
          const attachedChecklists = getChecklistsWithFileReferences(
            checklistItems,
            file.id!,
          );

          if (attachedChecklists.length < 2) {
            await new FileApi(getDropboxConfiguration()).moveFileToTrash(
              file.id!,
            );
          }
        }),
      );

      await new ItemApi(getSherlockConfiguration()).removeFileReferences(
        checklistItem?.id!,
        {
          references: fileReferences,
        },
      );

      await dispatch(fetchChecklistItemById(checklistId, checklistItem?.id!));

      dispatch(
        showSuccessToast(
          `${pluralize(
            'Document',
            filesToBeDeleted?.length,
          )} moved to archive successfully.`,
        ),
      );
    } catch (error) {
      ErrorService.notify('Unable to archive the files', error, {
        files: filesToBeDeleted,
      });
      dispatch(
        showErrorToast(
          'We had a problem archiving the documents',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      setIsDeleting(false);
      setIsDeleteModalOpen(false);
      setFilesToBeDeleted([]);
    }
  };

  const handleActionBarDownloadDocuments = useCallback(
    async (files: FileResponse[]) => {
      try {
        await downloadSelectedFiles(files, 'checklist-item-documents');
      } catch (error) {
        ErrorService.notify('Unable to download the files', error, {
          files,
        });
        dispatch(
          showErrorToast(
            'We had a problem downloading the documents',
            'Please try again in a few moments.',
          ),
        );
      }
    },
    [dispatch],
  );

  const columns: Array<Column<FileResponse>> = [
    {
      Header: 'Name / Description',
      accessor: 'filename',
      Cell: ({ value, row: { original } }) => (
        <div className='flex items-start gap-2 w-[200px]'>
          <div className='mt-1'>
            <div className='border border-zen-dark-5 rounded p-1'>
              <FontAwesomeIcon icon={faFile} className='text-zen-dark-5' />
            </div>
          </div>
          <div className='flex flex-col items-start'>
            <div className='flex items-start gap-2'>
              <div className='text-sm font-zen-body font-normal text-zen-dark-9 break-all line-clamp-2'>
                {value}
              </div>
              <button
                type='button'
                className='appearance-none mt-1'
                onClick={() => {
                  handleOpenFile(original);
                }}
                aria-label='file-open'
              >
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className='text-primary-blue text-base'
                />
              </button>
            </div>
            <div className='text-xs text-zen-dark-6 leading-tight contents'>
              {original.description}
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: 'Uploaded By',
      accessor: 'currentVersion',
      Cell: ({ value }) =>
        agentById[value?.uploadedBy!] ? (
          <div className='font-zen-body text-sm items-center w-max gap-2 bg-zen-light-blue px-2 py-1.5 rounded-full inline-flex'>
            <ProfileImage
              imageUrl={getYentaImageUrl(agentById[value?.uploadedBy!]?.avatar)}
              width={17}
              variant='circle'
            />
            {getFullName(agentById[value?.uploadedBy!])}
          </div>
        ) : (
          ''
        ),
    },
    {
      Header: 'Upload Date',
      accessor: 'createdAt',
      Cell: ({ row: { original } }) => (
        <ZenDocumentDateCell date={original.currentVersion?.createdAt!} />
      ),
    },
    {
      Header: '',
      accessor: 'metadata',
      Cell: ({ row: { original } }) => (
        <ChecklistDocumentErrorDetectionCell
          checklistItem={checklistItem}
          documentId={original?.id}
          dropboxId={dropboxId}
          isDocumentTable
        />
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: 'trash',
      disableSortBy: true,
      Cell: ({ row: { original, index } }) => (
        <ZenChecklistItemDocumentDropdown
          checklistDocument={original}
          checklistItem={checklistItem}
          checklistId={checklistId}
          setIsShowingDocumentVersion={() => {
            setSelectedDocument(original);
            setSelectedIndex(index);
            setIsShowingDocumentVersion(true);
          }}
        />
      ),
    },
  ];

  if (isDropboxFilesTrashingEnabled) {
    columns.unshift({
      Header: '',
      accessor: 'id',
      Cell: ({ row: { original } }) => (
        <div
          onClick={() => {
            handleOpenFile(original);
          }}
          className='py-6'
          data-testid={`view-button-${original.id!}`}
        >
          <IconButton
            buttonStyle='border border-primary-blue bg-primary-blue rounded-lg px-1'
            variant='none'
            leftIcon={
              <ZenEye className='p-0.5 h-5 w-5 text-white' aria-label='view' />
            }
          />
        </div>
      ),
      disableSortBy: true,
    });
  } else {
    columns.splice(1, 0, {
      Header: 'Version',
      accessor: 'fileVersions',
      Cell: ({ value }) => (
        <div className='flex items-center md:justify-center gap-1'>
          <FontAwesomeIcon icon={faArrowRotateLeft} size='sm' />
          {value![value?.length! - 1]?.version}
        </div>
      ),
    });
  }

  const actionBarButtons: Array<ActionBarButtonsProps<FileResponse>> = [
    {
      icon: (
        <FontAwesomeIcon
          icon={faArrowDownToLine}
          className='text-lg text-zen-dark-9'
          aria-label='download-all-selected-documents'
        />
      ),
      hoverText: 'Download All Docs',
      onClick: async (files: FileResponse[]) => {
        setIsActionBarLoading((prevState) => {
          return { ...prevState, download: true };
        });
        await handleActionBarDownloadDocuments(files);
        setIsActionBarLoading((prevState) => {
          return { ...prevState, download: false };
        });
      },
      isLoading: isActionBarLoading?.download,
    },
  ];

  return (
    <div className='mt-5'>
      <ZenFixedDataTable<FileResponse>
        key={checklistItemDropboxFiles?.length}
        columns={columns}
        resourceName='Uploaded Documents'
        data={checklistItemDropboxFiles}
        hiddenColumns={!automatedBrokerReviewEnabled ? ['metadata'] : []}
        standalone={false}
        hideFilters
        hidePagination
        showSelectionActionBar={isDropboxFilesTrashingEnabled}
        allowSelection={isDropboxFilesTrashingEnabled}
        actionBarButtons={actionBarButtons}
        compact
      />
      {isShowingDocumentVersion && (
        <ZenCheckListDropboxDocumentVersions
          isOpen
          onClose={() => setIsShowingDocumentVersion(false)}
          checklistDocument={selectedDocument}
          checklistItem={checklistItem}
          checklistId={checklistId}
          index={selectedIndex}
          dropboxId={dropboxId}
          secondaryDropboxId={secondaryDropboxId}
        />
      )}
      <ZenConfirmationModal
        isOpen={isDeleteModalOpen}
        variant='danger'
        title='Are you sure you want to archive this files?'
        confirmButtonText='Archive'
        hideIcon
        subtitle='Archiving this files will remove all previous versions as well.'
        onClose={() => {
          setFilesToBeDeleted([]);
          setIsDeleteModalOpen(false);
        }}
        onConfirm={() => {
          handleDelete();
        }}
        isSubmitting={isDeleting}
        isDisabled={isDeleting}
      />
    </div>
  );
};

export default ZenChecklistItemDropboxDocumentsList;
