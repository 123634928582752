import React from 'react';
import { getYentaImageUrl } from '../../utils/ImgUtils';
import { parseInitials, stringToHexCode } from '../../utils/StringUtils';

type ProfileImageProps = {
  avatar?: string;
  name?: string | null;
};

const ProfileImage: React.FC<ProfileImageProps> = ({ avatar, name }) => {
  if (!avatar) {
    return (
      <div
        className='w-[254px] h-[254px] border border-gray-100 aspect-square rounded-lg flex items-center justify-center text-white font-inter text-7xl'
        style={{ backgroundColor: name ? stringToHexCode(name) : 'auto' }}
      >
        {parseInitials(name)}
      </div>
    );
  }

  return (
    <img
      src={getYentaImageUrl(avatar)}
      width={254}
      alt='user-profile-img'
      className='border border-gray-100 fill-white aspect-square rounded-lg'
    />
  );
};

export default ProfileImage;
