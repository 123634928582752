import illustration from '../../../assets/img/wallet/unit_redirection_illustration.svg';
import ZenConfirmationModal from '../../Zen/Modal/ZenConfirmationModal';
import ZenButton from '../../Zen/ZenButton';
import AnalyticsService from '../../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../../types';

interface WalletRedirectionModalProps {
  formUrl?: string;
}

const WalletRedirectionModal: React.FC<WalletRedirectionModalProps> = ({
  formUrl,
}) => {
  if (!formUrl) {
    return null;
  }

  return (
    <ZenConfirmationModal
      isOpen={!!formUrl}
      variant='primary'
      size='large'
      hideIcon
      hideFooterButtons
    >
      <div className='flex flex-col items-center'>
        <img
          src={illustration}
          alt='wallet-illustration'
          className='w-28 h-28'
        />

        <p className='mt-3 font-inter font-medium text-xl text-center text-primary-dark'>
          You are being redirected outside of reZEN.
        </p>

        <p className='font-inter text-center font-normal text-sm text-primary-dark mt-3 mb-4 sm:w-[396px]'>
          We&apos;re about to securely send your information to{' '}
          <a
            href='https://www.unit.co/about-us'
            target='_blank'
            rel='noreferrer'
            className='text-rezen-blue-600 underline font-bold'
          >
            Unit
          </a>
          , our embedded finance platform, for final verification. Rest assured,
          your personal details are protected and kept private.
        </p>

        <div className='w-full flex flex-row justify-center my-3 space-x-3 px-4 pb-4'>
          <ZenButton
            label='Continue'
            variant='primary'
            className='w-44 font-inter bg-rezen-blue-600 border-rezen-blue-600'
            onClick={() => {
              if (formUrl) {
                window.location.href = formUrl;
                AnalyticsService.instance().logEvent(
                  AnalyticsEventEnum.WALLET_CONTINUE_TO_UNIT_FORM_CLICKED,
                );
              }
            }}
          />
        </div>
      </div>
    </ZenConfirmationModal>
  );
};

export default WalletRedirectionModal;
