import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';

type OfficeGroupMenuProps = {
  options: Array<{ label: string; onClick: () => void }>;
};

const OfficeGroupMenu: React.FC<OfficeGroupMenuProps> = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      isOpen={isOpen}
      align='end'
      positions={['bottom']}
      onClickOutside={() => setIsOpen(false)}
      containerClassName='z-50'
      content={() => (
        <div className='bg-white border border-gray-300 text-primary-dark font-inter rounded-md shadow-sm mt-1'>
          <p className='text-gray-400 px-4 text-sm font-semibold py-1.5'>
            More Actions
          </p>
          {options.map((option) => (
            <button
              key={option.label}
              onClick={() => {
                option.onClick();
                setIsOpen(false);
              }}
              className='py-1.5 px-4 hover:bg-gray-100 w-full text-left font-light'
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    >
      <button
        className='appearance-none p-1 rounded-full flex justify-center items-center bg-transparent hover:bg-gray-300'
        onClick={() => setIsOpen(!isOpen)}
        aria-label='group-card-options'
      >
        <FontAwesomeIcon
          icon={faEllipsisVertical}
          className='text-rezen-blue-600'
          width={20}
          height={20}
        />
      </button>
    </Popover>
  );
};

export default OfficeGroupMenu;
