import {
  faClipboardCheck,
  faLoader,
  faXmark,
} from '@fortawesome/pro-light-svg-icons';
import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { cn } from '../../../utils/classUtils';
import { LeoNotification } from '../../../utils/LeoUtils';
import { Button } from '../../commons/Button';

export interface NeoLeoNotificationType {
  notification: LeoNotification;
  primaryActionText?: string;
  showPrimaryAction?: boolean;
  primaryActionHandler?: () => void;
  secondaryActionText?: string;
  showSecondaryAction?: boolean;
  secondaryActionHandler?: () => void;
  showActionButtons?: boolean;
  isUrgent?: boolean;
  onNotificationCardPress(): void;
  onDismissNotification(): void;
  dismissingNotificationIds?: string[];
}

const NeoLeoNotification: FC<NeoLeoNotificationType> = ({
  notification,
  showActionButtons,
  isUrgent,
  primaryActionText = 'Accept',
  showPrimaryAction = false,
  secondaryActionText = 'Decline',
  showSecondaryAction = false,
  primaryActionHandler,
  secondaryActionHandler,
  onNotificationCardPress,
  onDismissNotification,
  dismissingNotificationIds,
}) => {
  const isDismissingNotification = dismissingNotificationIds?.includes(
    notification.id,
  );

  return (
    <div
      className='font-inter px-4 py-3 bg-white bg-opacity-[0.08] rounded-lg border border-regent-600 hover:border-aqua relative group'
      onClick={onNotificationCardPress}
    >
      <div className='flex gap-3 pr-8'>
        {isUrgent ? (
          <FontAwesomeIcon icon={faWarning} className='text-coral-red' />
        ) : (
          <FontAwesomeIcon icon={faClipboardCheck} className='text-white' />
        )}
        <div>
          {isUrgent && (
            <p className='font-semibold text-sm text-coral-red mb-0.5'>
              Urgent:
            </p>
          )}
          <p
            className={cn('font-normal text-sm text-white', {
              'group-hover:mr-3': !isUrgent && showActionButtons,
            })}
          >
            {notification.title}
          </p>
        </div>
      </div>
      {showActionButtons && (
        <div className='flex items-center gap-3 mt-2.5'>
          {showPrimaryAction && (
            <Button
              color='aqua'
              className='py-1.5 !border-0'
              onClick={(e) => {
                e.stopPropagation();
                primaryActionHandler?.();
              }}
              fullWidth
            >
              <p className='text-sm text-primary-navy font-medium'>
                {primaryActionText}
              </p>
            </Button>
          )}
          {showSecondaryAction && (
            <Button
              variant='outline'
              className='bg-transparent !border !border-white py-[5px]'
              onClick={(e) => {
                e.stopPropagation();
                secondaryActionHandler?.();
              }}
              fullWidth
            >
              <p className='text-sm text-white font-medium'>
                {secondaryActionText}
              </p>
            </Button>
          )}
        </div>
      )}
      <div
        className={cn(
          'bg-[#4A5172] h-6 w-6 flex justify-center items-center rounded-[4px] absolute top-2 right-2 cursor-pointer',
          {
            'invisible group-hover:visible': !isDismissingNotification,
          },
        )}
        onClick={(e) => {
          e.stopPropagation();
          onDismissNotification?.();
        }}
      >
        <FontAwesomeIcon
          icon={isDismissingNotification ? faLoader : faXmark}
          className='text-regent-300'
          spin={isDismissingNotification}
        />
      </div>
    </div>
  );
};

export default NeoLeoNotification;
