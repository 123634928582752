import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import AvatarLabelComponent from '../components/AgentReports/AvatarLabelComponent';
import ZenSidebarModalActionFooter from '../components/SidebarModal/ZenSideBarModalActionFooter';
import ZenControlledAsyncSelectInput from '../components/Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledFormattedMoneyInput from '../components/Zen/Input/ZenControlledFormattedMoneyInput';
import ZenControlledTextInput from '../components/Zen/Input/ZenControlledTextInput';
import ZenSidebarModal from '../components/Zen/ZenSidebarModal';
import {
  CreateInternalRepaymentSourceTypeEnum,
  MoneyValue,
  MoneyValueCurrencyEnum,
  RealLendingAccountLiteDtoTypeEnum,
} from '../openapi/wallet';
import {
  useCreateInternalRepayment,
  useFetchWalletByUserId,
} from '../query/wallet/useWallet';
import ErrorService from '../services/ErrorService';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import {
  AppDispatch,
  AsyncSelectOptionReactElement,
  ISelectOption,
} from '../types';
import { defaultMoneyValue } from '../utils/CurrencyUtils';
import { searchActiveAgents } from '../utils/TableUtils';
import { MONEY_NON_ZERO_VALUE_VALIDATIONS } from '../utils/Validations';

interface FormData {
  agent: ISelectOption;
  amount: MoneyValue;
  description: string;
}

interface WalletRepaymentSideBarModalProps {
  isOpen: boolean;
  onClose(): void;
}

const WalletRepaymentSideBarModal: React.FC<WalletRepaymentSideBarModalProps> = ({
  onClose,
  isOpen,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const defaultValues = useMemo(
    () => ({ amount: defaultMoneyValue(MoneyValueCurrencyEnum.Usd) }),
    [],
  );

  const { control, handleSubmit, watch } = useForm<FormData>({ defaultValues });

  const userId = watch('agent')?.value;

  const { data: wallet } = useFetchWalletByUserId(userId);

  const {
    mutate: createInternalRepayment,
    isLoading,
  } = useCreateInternalRepayment();

  const realLendingAccountId = useMemo(
    () =>
      wallet?.realLendingAccounts?.find(
        (account) =>
          account.type === RealLendingAccountLiteDtoTypeEnum.RealLending,
      )?.id!,
    [wallet?.realLendingAccounts],
  );

  const isSubmitButtonEnabled = useMemo(() => {
    return realLendingAccountId && wallet?.userId === userId;
  }, [realLendingAccountId, userId, wallet?.userId]);

  const onSubmit = async (formValues: FormData) => {
    createInternalRepayment({
      userId: userId,
      accountId: realLendingAccountId,
      amount: formValues.amount,
      description: formValues.description,
      sourceType: CreateInternalRepaymentSourceTypeEnum.Check,
    });
  };

  return (
    <ZenSidebarModal title='Wallet Repayment' isOpen={isOpen} onClose={onClose}>
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-4 space-y-5'>
          <ZenControlledAsyncSelectInput<FormData, 'agent'>
            control={control}
            name='agent'
            placeholder='Search'
            label='Name'
            shouldUnregister={false}
            startAdornment={
              <div className='flex w-full h-full items-center justify-center pl-2'>
                <FontAwesomeIcon
                  className='text-primary-blue'
                  icon={regular('magnifying-glass')}
                />
              </div>
            }
            fetchData={async (search, page) => {
              try {
                const { data } = await searchActiveAgents(
                  page,
                  undefined,
                  undefined,
                  search,
                  undefined,
                );

                const options: AsyncSelectOptionReactElement[] = data.map(
                  (resp) => ({
                    value: `${resp.id}`,
                    label: (
                      <AvatarLabelComponent
                        avatar={resp.avatar ?? ''}
                        firstName={resp?.firstName!}
                        lastName={resp?.lastName!}
                      />
                    ),
                  }),
                );

                return options;
              } catch (e) {
                ErrorService.notify('Unable to search for active agents', e, {
                  search: { term: search, page },
                });
                dispatch(
                  showErrorToast(
                    'An unexpected error occurred.',
                    'We were unable to search for an agent. Please try again in a few moments or contact support.',
                  ),
                );
              }

              return [];
            }}
            rules={{ required: 'Required' }}
            isRequired
          />

          <ZenControlledFormattedMoneyInput<FormData, 'amount'>
            control={control}
            name='amount'
            label='Amount'
            placeholder='Amount'
            rules={MONEY_NON_ZERO_VALUE_VALIDATIONS}
            isRequired
          />

          <ZenControlledTextInput<FormData, 'description'>
            label='Description'
            name='description'
            control={control}
            placeholder='Description'
            rules={{ required: 'Required' }}
            isRequired
          />
        </div>

        <ZenSidebarModalActionFooter
          onClose={onClose}
          isSubmitting={isLoading}
          submitButtonDisabled={isLoading || !isSubmitButtonEnabled}
          submitButtonText='Send'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default WalletRepaymentSideBarModal;
