import { isEqual } from 'lodash';
import qs from 'qs';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import DetailPageLoader from '../components/DetailPageLoader';
import GeminiListingHeader from '../components/Gemini/listings/GeminiListingHeader';
import ZenListingHeader from '../components/listings/ZenListingHeader';
import PageLayout from '../components/PageLayout';
import ResourceContainer from '../components/ResourceContainer';
import HappyBrokerFeedbackModal from '../components/transactions/HappyFeature/HappyBrokerFeedbackModal';
import SilenceNotification from '../components/transactions/SilenceNotification';
import TransactionDetailErrors from '../components/transactions/TransactionDetailErrors';
import TransactionServerError from '../components/transactions/TransactionServerError';
import ZenListingDetailSecondaryHeader from '../components/transactions/ZenListingDetailSecondaryHeader';
import ZenListingDetail from '../components/Zen/listings/ZenListingDetail';
import ZenJourney from '../components/Zen/RoadToSuccess/ZenJourney';
import ZenRouterTabs, { ZenTab } from '../components/Zen/Tab/ZenRouterTabs';
import ZenRoute from '../components/Zen/ZenRoute';
import UseBrokerQueue from '../hooks/useBrokerQueue';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import useRedirectAwayListingEffect from '../hooks/useRedirectAwayListingEffect';
import {
  CreateSentimentRequestContextTypeEnum,
  RezenObjectTypeEnum,
  SentimentDisplayRequestContextTypeEnum,
  SentimentDisplayResponseContextTypeEnum,
} from '../openapi/arrakis';
import { fetchOfficeDetailById } from '../slices/OfficeSlice';
import {
  fetchTransactionDetails,
  getSentimentDisplayStatus,
  getTransactionPermission,
  saveBrokerSentimentDetails,
} from '../slices/TransactionSlice';
import {
  BreadcrumbMatcherType,
  FeatureFlagTypeEnum,
  PublisherTypeEnum,
  RootState,
} from '../types';
import {
  getBreadcrumbMatched,
  shouldRedirectToDetailsTab,
  showHappyBrokerFeedbackModal,
} from '../utils/TransactionHelper';
import ZenTransactionChecklist from '../components/Zen/Checklist/ZenTransactionChecklist';
import Route404 from './Route404';
import TransactionCommentRoute from './TransactionCommentRoute';
import ZenTransactionActivity from './ZenTransactionActivity';
import ZenTransactionFileCabinetRoute from './ZenTransactionFileCabinetRoute';

type Match = {
  id: string;
};

const ZenListingsDetailRoute: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { id: transactionId } = useParams() as Match;
  const { isBrokerQueueActive } = UseBrokerQueue();
  const {
    transactionDetailResponse: { data: transactionDetail, loading, error },
    brokerSentimentDetails,
    isAdmin,
    isBroker,
  } = useSelector((state: RootState) => ({
    transactionDetailResponse: state.transaction.transactionDetailResponse,
    brokerSentimentDetails: state.transaction.brokerSentimentDetails,
    isAdmin: state.auth.isAdmin,
    isBroker: state.auth.isBroker,
  }));
  const { showStatusOnboardings } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const isAgent = !(isAdmin || isBroker);

  const willTransition = useRedirectAwayListingEffect(
    transactionId,
    transactionDetail,
  );

  useEffect(() => {
    dispatch(fetchTransactionDetails(transactionId));
    dispatch(getTransactionPermission(transactionId));
  }, [dispatch, transactionId]);

  useEffect(() => {
    if (transactionDetail?.office?.id) {
      dispatch(fetchOfficeDetailById(transactionDetail.office.id));
    }
  }, [dispatch, transactionDetail]);

  useEffect(
    () => {
      if (
        transactionDetail?.id &&
        isEqual(transactionDetail?.id, transactionId) &&
        isAgent
      ) {
        dispatch(
          getSentimentDisplayStatus(
            {
              contextId: transactionDetail?.id!,
              contextType: SentimentDisplayRequestContextTypeEnum.Broker,
              containingContext: {
                id: transactionDetail?.id!,
                type: RezenObjectTypeEnum.Listing,
              },
              contextState: transactionDetail?.lifecycleState?.state,
            },
            true,
          ),
        );
      }

      return () => {
        dispatch(saveBrokerSentimentDetails(undefined));
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, transactionDetail?.id, transactionDetail?.lifecycleState?.state],
  );

  useEffect(() => {
    if (
      transactionDetail?.id === transactionId &&
      !willTransition &&
      shouldRedirectToDetailsTab(
        transactionDetail,
        location.pathname,
        true,
        'listings',
      )
    ) {
      history.replace(
        `/listings/${transactionDetail.id}/detail?${qs.stringify({
          showStatusOnboardings,
        })}`,
      );
    }
  }, [
    history,
    location.pathname,
    showStatusOnboardings,
    transactionDetail,
    transactionId,
    willTransition,
  ]);

  const path = [
    { title: 'Home', url: '/' },
    { title: 'Listings', url: '/listings' },
  ];

  if (transactionDetail) {
    path.push({
      title: `${transactionDetail?.address?.oneLine}`,
      url: `/listings/${transactionDetail?.id}`,
    });

    const breadcrumbsToMatch: BreadcrumbMatcherType[] = [
      {
        title: 'Details',
        url: `/listings/${transactionDetail?.id}/detail`,
      },
      {
        title: 'File Cabinet',
        url: `/listings/${transactionDetail?.id}/file-cabinet`,
      },
      {
        title: 'Checklist',
        url: `/listings/${transactionDetail?.id}/checklist`,
      },
      {
        title: 'Comments',
        url: `/listings/${transactionDetail?.id}/comments`,
      },
      {
        title: 'Activity',
        url: `/listings/${transactionDetail?.id}/activity`,
      },
      {
        title: 'Road To Success',
        url: `/listings/${transactionDetail?.id}`,
      },
    ];

    const breadcrumbMatched = getBreadcrumbMatched(
      location.pathname,
      breadcrumbsToMatch,
    );

    if (breadcrumbMatched) {
      path.push(breadcrumbMatched);
    }
  }

  const transactionTabs = useMemo(() => {
    const zenTabs: ZenTab[] = [
      {
        label: 'Road To Success',
        TabComponent: ZenJourney,
        path: `/listings/${transactionDetail?.id}`,
        exact: true,
      },
      {
        label: 'Checklist',
        TabComponent: ZenTransactionChecklist,
        path: `/listings/${transactionDetail?.id}/checklist`,
        exact: true,
      },
      {
        label: 'Details',
        TabComponent: ZenListingDetail,
        path: `/listings/${transactionDetail?.id}/detail`,
        exact: true,
      },
      {
        label: 'Comments',
        TabComponent: TransactionCommentRoute,
        path: `/listings/${transactionDetail?.id}/comments`,
        exact: true,
        // messageCount: 3,
      },
      {
        label: 'File Cabinet',
        TabComponent: ZenTransactionFileCabinetRoute,
        path: `/listings/${transactionDetail?.id}/file-cabinet`,
        exact: true,
      },
      {
        label: 'Activity',
        TabComponent: ZenTransactionActivity,
        path: `/listings/${transactionDetail?.id}/activity`,
        exact: true,
      },
    ];

    return zenTabs.filter((tab) => {
      const label = tab.label;
      if (label === 'Checklist') {
        return transactionDetail?.checklistId;
      }
      if (label === 'Road To Success') {
        return transactionDetail?.journeyId;
      }
      if (label === 'File Cabinet') {
        return transactionDetail?.dropboxId;
      }
      return true;
    });
  }, [transactionDetail]);

  const tabsPathRegex = useMemo(
    () => `(${transactionTabs.map((tab) => tab.path).join('|')})`,
    [transactionTabs],
  );

  const geminiFeatureFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.GEMINI_REDESIGN,
  );

  const HeaderComponent = geminiFeatureFlagEnabled
    ? GeminiListingHeader
    : ZenListingHeader;

  return (
    <ZenRoute title='Listing Details'>
      <ResourceContainer
        loading={loading}
        LoaderComponent={<DetailPageLoader />}
        isEmpty={!transactionDetail}
        errorCode={error}
        ServerErrorComponent={
          <TransactionServerError
            type='LISTING'
            transactionId={transactionId}
          />
        }
        resourceName='listing'
      >
        <PageLayout
          path={path}
          hideLayoutPaths={isBrokerQueueActive}
          RightComponent={
            <SilenceNotification publisherType={PublisherTypeEnum.LISTING} />
          }
        >
          {!!transactionDetail && (
            <>
              <TransactionDetailErrors errors={transactionDetail.errors!} />
              <HeaderComponent transaction={transactionDetail} />

              {showHappyBrokerFeedbackModal(
                brokerSentimentDetails,
                SentimentDisplayResponseContextTypeEnum.Broker,
              ) && (
                <HappyBrokerFeedbackModal
                  transaction={transactionDetail}
                  contextType={CreateSentimentRequestContextTypeEnum.Broker}
                />
              )}

              <Switch>
                <Route path={match.path}>
                  <ZenListingDetailSecondaryHeader
                    transaction={transactionDetail}
                  />
                  <Switch>
                    <Route path={tabsPathRegex} exact>
                      <ZenRouterTabs tabs={transactionTabs} stickyTabs />
                    </Route>
                    <Route path={`${match.path}/*`} component={Route404} />
                  </Switch>
                </Route>
              </Switch>
            </>
          )}
        </PageLayout>
      </ResourceContainer>
    </ZenRoute>
  );
};

export default ZenListingsDetailRoute;
