import {
  faArrowUpRight,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '../../utils/classUtils';
import ZenAvatar from '../Zen/ZenAvatar';
import Accordian from '../commons/Accordian';
import { TCInfo } from './TCInfoSection';
import TCInvitationStatusPill from './TCInvitationStatusPill';

interface SentInvitesAccordionProps {
  transactionCoordinators: TCInfo[];
  onSelect?(tc: TCInfo): void;
}

const SentInvitesAccordion: React.FC<SentInvitesAccordionProps> = ({
  transactionCoordinators,
  onSelect,
}) => {
  return (
    <div className='flex flex-col gap-3 border border-grey-300 rounded-lg font-inter'>
      <Accordian
        title={`SENT (${transactionCoordinators.length})`}
        name='sentInvitations'
        icon={
          <FontAwesomeIcon icon={faArrowUpRight} className='text-regent-600' />
        }
      >
        {transactionCoordinators.map((tc, index) => (
          <div
            className={cn({
              'border-regent-300 border-b':
                index !== transactionCoordinators.length - 1,
            })}
            key={tc.name}
          >
            <div className='flex flex-col sm:flex-row sm:items-center justify-between gap-x-12 gap-y-6 w-full p-4'>
              <div className='flex items-center gap-2 flex-1'>
                <ZenAvatar name={tc?.name!} imageUrl={tc?.avatar} size='lg' />
                <div className='w-2/3'>
                  <p className='text-primary-dark font-normal line-clamp-1 text-sm sm:text-base'>
                    {tc?.name}
                  </p>
                  <p className='text-grey-600 font-light text-xs sm:text-sm overflow-hidden text-ellipsis'>
                    {tc?.email}
                  </p>
                </div>
              </div>
              <div
                className='flex items-center gap-1 cursor-pointer flex-shrink-0 ml-auto'
                onClick={() => onSelect?.(tc)}
              >
                <TCInvitationStatusPill status={tc.invitationStatus!} />
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className='text-primary-dark'
                  size='sm'
                />
              </div>
            </div>
          </div>
        ))}
      </Accordian>
    </div>
  );
};

export default SentInvitesAccordion;
