// https://stackoverflow.com/a/2901298
import { isEmpty } from 'lodash';
import { MonetaryAmount, MoneyValueCurrencyEnum } from '../openapi/arrakis';
import { AgentResponseDefaultCurrencyEnum, MoneyValue } from '../openapi/yenta';
import { EnumMap } from '../types';

const currencyToSymbol: EnumMap<MoneyValueCurrencyEnum, string> = {
  CAD: '$',
  USD: '$',
};

export const numberWithCommas = (num: string): string => {
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export interface DisplayAmountOptions {
  hideZeroCents?: boolean;
  hideCurrency?: boolean;
  abs?: boolean;
}

export const displayAmount = (
  amount?: MoneyValue,
  options: DisplayAmountOptions = {
    hideZeroCents: false,
    hideCurrency: false,
    abs: false,
  },
): string => {
  if (amount?.amount === null || amount?.amount === undefined) {
    return 'N/A';
  }

  let formattedAmount = numberWithCommas(
    (options.abs ? Math.abs(amount.amount) : amount.amount).toFixed(2),
  );

  if (options.hideZeroCents) {
    formattedAmount = formattedAmount.replace(/\.00$/, '');
  }

  if (options.hideCurrency) {
    return `${currencyToSymbol[amount.currency!]}${formattedAmount}`;
  }

  return `${amount.currency} ${formattedAmount}`;
};

export const defaultMoneyValue = (
  currency: MoneyValueCurrencyEnum | AgentResponseDefaultCurrencyEnum,
): MoneyValue => {
  return {
    currency: (currency as unknown) as MoneyValueCurrencyEnum,
  };
};

export const zeroMoneyValue = (
  currency: MoneyValueCurrencyEnum | AgentResponseDefaultCurrencyEnum,
): MoneyValue => {
  return { ...defaultMoneyValue(currency), amount: 0 };
};

export const parseMoney = (amount: number): number => {
  return parseFloat(amount.toFixed(2));
};

export interface NumberToMoneyOptions {
  includeCurrencySymbol?: boolean;
}

export const numberToMoney = (
  amount: number,
  options: NumberToMoneyOptions = {
    includeCurrencySymbol: true,
  },
): string => {
  const money = numberWithCommas(Math.abs(amount).toFixed(2)).replace(
    /\.00$/,
    '',
  );

  const currencySymbol = options.includeCurrencySymbol ? '$' : '';

  if (amount < 0) {
    return `-${currencySymbol}${money}`;
  }
  return `${currencySymbol}${money}`;
};

export const displayFormattedAmountWithCurrency = (
  amount?: MoneyValue | MonetaryAmount,
  currency = true,
): string => {
  if (amount?.amount === null || amount?.amount === undefined) {
    return 'N/A';
  }

  let formattedAmount = numberWithCommas(amount.amount.toFixed(2));

  return currency
    ? `$${formattedAmount} ${amount.currency}`
    : `$${formattedAmount}`;
};

export const displayFormattedFixedAmountWithCurrency = (
  amount?: MoneyValue | MonetaryAmount,
  currency = true,
  precision = 2,
): string => {
  if (amount?.amount === null || amount?.amount === undefined) {
    return precision === 0 ? '0' : '0.00';
  }

  let formattedAmount = numberWithCommas(
    parseFloat(amount.amount.toString()).toFixed(precision),
  );

  return currency
    ? `$${formattedAmount} ${amount.currency}`
    : `$${formattedAmount}`;
};

export const getFixedDecimalMoneyValue = (
  value: MoneyValue,
  precision = 2,
): MoneyValue => {
  return value?.amount
    ? {
        ...value,
        amount: (parseFloat(value.amount.toString()).toFixed(
          precision,
        ) as unknown) as number,
      }
    : value;
};

export function displayShortAmount(value: MonetaryAmount) {
  let newValue = value.amount?.toString()!;

  if (value.amount! >= 1000) {
    let suffixes = ['', 'K', 'M', 'B', 'T'];
    let suffixNum = Math.floor(('' + value.amount!).length / 3);
    let shortValue = '';

    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value.amount! / Math.pow(1000, suffixNum)
          : value.amount!
        ).toPrecision(precision),
      ).toString();

      let dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');

      if (dotLessShortValue.length <= 2) {
        break;
      }
    }

    if (parseInt(shortValue) % 1 !== 0) {
      shortValue = parseInt(shortValue).toFixed(1);
    }

    newValue = shortValue + suffixes[suffixNum];
  }

  return newValue;
}

export const displayPercentage = (percentage: number): string => {
  if (percentage === Infinity) {
    return 'N/A';
  }

  return `${percentage}%`;
};

export const isAmountValid = (value: number = 0, maxValue: number = 0) => {
  if (
    value &&
    maxValue &&
    parseFloat(value?.toString()) <= parseFloat(maxValue?.toString())
  ) {
    return true;
  }

  return false;
};

export const amountWithPrecision = (val: string, precision: number) => {
  const replaceEmptyZerosRegex = new RegExp(`\\.0{${precision}}$`);
  return parseFloat(val).toFixed(precision).replace(replaceEmptyZerosRegex, '');
};

export const formatMoneyValue = (
  moneyValue?: MoneyValue,
  precision: 0 | 1 | 2 = 0,
  includeCurrency = false,
): string => {
  if (isEmpty(moneyValue) || !moneyValue?.amount) {
    return precision === 0 ? '$0' : '$0.00';
  }

  const { amount, currency } = moneyValue;

  const prefix = amount < 0 ? '-' : '';

  const formattedAmount = numberWithCommas(
    parseFloat(Math.abs(amount).toString()).toFixed(precision),
  );

  return includeCurrency
    ? `${prefix}$${formattedAmount} ${currency}`
    : `${prefix}$${formattedAmount}`;
};
