import {
  faArrowRightFromBracket,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TransactionResponse } from '../../../openapi/arrakis';
import { AgentResponseAccountCountryEnum } from '../../../openapi/yenta';
import AnalyticsService from '../../../services/AnalyticsService';
import ErrorService from '../../../services/ErrorService';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  DOUBLE_ENDER_TRANSACTION_NAME,
  getTransactionByCode,
  linkDoubleEnderTransaction,
  saveDoubleEnderTransaction,
  unlinkDoubleEnderTransaction,
} from '../../../slices/TransactionSlice';
import {
  AnalyticsEventEnum,
  AppDispatch,
  AsyncSelectOptionReactElement,
  CardItem,
  RootState,
} from '../../../types';
import { STATE_OR_PROVINCE_ABBREVIATIONS } from '../../../utils/AnnouncementUtils';
import { searchActiveAgents } from '../../../utils/TableUtils';
import AvatarLabelComponent from '../../AgentReports/AvatarLabelComponent';
import IconButton from '../../IconButton';
import ResourceContainer from '../../ResourceContainer';
import ZenControlledAsyncSelectInput from '../Input/ZenControlledAsyncSelectInput';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';
import ZenButton from '../ZenButton';
import ZenCardWithItems from '../ZenCardWithItems';
import ZenSidebarModalForm from '../ZenSidebarModalForm';
import ZenTransactionStatus from './ZenTransactionStatus';

interface ZenEditOfficeDoubleEnderSidebarProps {
  isOpen: boolean;
  onClose(): void;
  transaction: TransactionResponse;
}

interface FormData {
  realAgent?: AsyncSelectOptionReactElement | null;
  transactionCode?: string;
}

const ZenEditOfficeDoubleEnderSidebar: React.FC<ZenEditOfficeDoubleEnderSidebarProps> = ({
  isOpen,
  onClose,
  transaction,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    auth: { userDetail },
    transaction: { doubleEnderTransaction, doubleEnderAgent },
  } = useSelector((state: RootState) => state);
  const agentDetail = doubleEnderAgent.data;

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      realAgent: agentDetail
        ? {
            value: agentDetail?.id!,
            label: (
              <AvatarLabelComponent
                avatar={agentDetail?.avatar!}
                firstName={agentDetail?.firstName!}
                lastName={agentDetail?.lastName!}
                emailAddress={agentDetail?.emailAddress!}
                administrativeAreas={agentDetail?.offices?.map((e) => {
                  if (!e.administrativeArea?.stateOrProvince) {
                    return 'N/A';
                  }
                  return STATE_OR_PROVINCE_ABBREVIATIONS[
                    e.administrativeArea?.stateOrProvince
                  ];
                })}
              />
            ),
          }
        : undefined,
      transactionCode: transaction.doubleEnderInfo?.otherTxCode,
    },
  });
  const [
    isUnlinkDoubleEnderTransaction,
    setIsUnlinkDoubleEnderTransaction,
  ] = useState<boolean>(false);
  const [isUnlinking, setIsUnlinking] = useState(false);
  const previousTxCode = useRef(transaction.doubleEnderInfo?.otherTxCode);

  const items: CardItem[] = useMemo(() => {
    const doubleEnderTx = doubleEnderTransaction.data;
    if (isEmpty(doubleEnderTx)) {
      return [];
    }
    const arr = [
      {
        name: 'Code',
        value: (
          <Link
            to={`/transactions/${doubleEnderTx?.id!}?showStatusOnboardings=true`}
          >
            <IconButton
              leftIcon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
              variant='outline'
              buttonStyle='border-gray-100 rounded-lg'
              label={doubleEnderTx?.code!}
            />
          </Link>
        ),
      },
      {
        name: 'Address',
        value: doubleEnderTx?.address?.oneLine,
      },
      {
        name: 'Status',
        value: (
          <div className='mr-[-12px]'>
            <ZenTransactionStatus
              status={doubleEnderTx?.lifecycleState?.state!}
              country={doubleEnderTx?.country!}
            />
          </div>
        ),
      },
    ];

    if (transaction.doubleEnderInfo?.otherTxCode) {
      arr.push({
        name: '',
        value: (
          <div className='pt-2 mr-[-18px]'>
            <ZenButton
              variant='danger-link'
              label='Remove'
              onClick={() => setIsUnlinkDoubleEnderTransaction(true)}
              LeftIconComponent={
                <FontAwesomeIcon icon={faTrashCan} className='mr-0.5' />
              }
            />
          </div>
        ),
      });
    }

    return arr;
  }, [doubleEnderTransaction.data, transaction.doubleEnderInfo?.otherTxCode]);

  useEffect(() => {
    if (transaction.doubleEnderInfo?.otherTxCode) {
      dispatch(getTransactionByCode(transaction.doubleEnderInfo.otherTxCode!));
    }
    return () => {
      dispatch(
        saveDoubleEnderTransaction({
          loading: false,
          name: DOUBLE_ENDER_TRANSACTION_NAME,
        }),
      );
    };
  }, [dispatch, transaction.doubleEnderInfo?.otherTxCode]);

  useEffect(() => {
    if (isEmpty(transaction.doubleEnderInfo)) {
      setValue('realAgent', null);
      setValue('transactionCode', '');
    }
  }, [setValue, transaction.doubleEnderInfo]);

  const onSubmit = async (values: FormData) => {
    const res = await dispatch(
      linkDoubleEnderTransaction(transaction.id!, {
        transactionCode: values.transactionCode!,
      }),
    );
    if (res) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.DOUBLE_ENDER_LINKED_TRANSACTION,
        {
          transactionId: transaction.id,
          linkedTransactionId: transaction.doubleEnderInfo?.otherTxId,
        },
      );
      onClose();
    }
  };

  const handleUnlinkDoubleEnderTransaction = async () => {
    setIsUnlinking(true);
    const res = await dispatch(
      unlinkDoubleEnderTransaction(
        doubleEnderTransaction.data?.id!,
        transaction.id!,
        { refreshTx: true },
      ),
    );
    if (res) {
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.DOUBLE_ENDER_UNLINKED_TRANSACTION,
        {
          transactionId: transaction.id,
          linkedTransactionId: transaction.doubleEnderInfo?.otherTxId,
        },
      );
    }
    setIsUnlinking(false);
    setIsUnlinkDoubleEnderTransaction(false);
    onClose();
  };

  const handleOnBlur = async () => {
    const isValid = await trigger('transactionCode');
    const txCode = getValues().transactionCode;
    if (isValid && previousTxCode.current !== txCode) {
      dispatch(getTransactionByCode(txCode!));
      previousTxCode.current = txCode;
    }
  };

  return (
    <div>
      <ZenSidebarModalForm
        title='Edit Office Double Ender'
        isOpen={isOpen}
        onClose={onClose}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit((v) => onSubmit(v))}
        actionTitle='Save'
      >
        <p className='text-lg font-zen-title font-medium text-zen-dark-9 mb-6'>
          Real Agent Representing the Other Side
        </p>
        <ZenControlledAsyncSelectInput<FormData, 'realAgent'>
          control={control}
          label='Real Agent Name'
          name='realAgent'
          placeholder='Search'
          readOnly
          fetchData={async (search, page) => {
            try {
              const searchResponse = await searchActiveAgents(
                page,
                [
                  (userDetail?.accountCountry as unknown) as AgentResponseAccountCountryEnum,
                ],
                undefined,
                search,
                undefined,
              );

              const options: AsyncSelectOptionReactElement[] = searchResponse.data!.map(
                (resp) => ({
                  value: resp.id!,
                  label: (
                    <AvatarLabelComponent
                      avatar={agentDetail?.avatar!}
                      firstName={agentDetail?.firstName!}
                      lastName={agentDetail?.lastName!}
                      emailAddress={agentDetail?.emailAddress!}
                      administrativeAreas={agentDetail?.offices?.map((e) => {
                        if (!e.administrativeArea?.stateOrProvince) {
                          return 'N/A';
                        }
                        return STATE_OR_PROVINCE_ABBREVIATIONS[
                          e.administrativeArea?.stateOrProvince
                        ];
                      })}
                    />
                  ),
                }),
              );

              return options;
            } catch (e) {
              ErrorService.notify(
                'Unable to search for registered agents in edit double ender info',
                e,
                {
                  search: {
                    term: search,
                    page,
                    country: userDetail?.accountCountry!,
                  },
                },
              );
              dispatch(
                showErrorToast(
                  'An unexpected error occurred.',
                  'We were unable to search for an agent. Please try again in a few moments or contact support.',
                ),
              );
            }

            return [];
          }}
        />
        <div className='pt-4 mt-8'>
          <p className='text-lg font-zen-title font-medium text-zen-dark-9 mb-2'>
            Other Side Transaction
          </p>
          <div onBlur={handleOnBlur}>
            <ZenControlledTextInput<FormData, 'transactionCode'>
              control={control}
              name='transactionCode'
              label='Enter Code'
              placeholder='Enter Code'
              rules={{ required: 'Required' }}
              isRequired
            />
          </div>
        </div>
        <ResourceContainer
          loading={doubleEnderTransaction.loading}
          isEmpty={isEmpty(doubleEnderTransaction.data)}
          resourceName={DOUBLE_ENDER_TRANSACTION_NAME}
          errorCode={doubleEnderTransaction.error}
          EmptyComponent={<div />}
        >
          <div className='mt-9'>
            <ZenCardWithItems items={items} variant='regular' />
          </div>
        </ResourceContainer>
      </ZenSidebarModalForm>
      <ZenConfirmationModal
        variant='danger'
        isOpen={isUnlinkDoubleEnderTransaction}
        onClose={() => setIsUnlinkDoubleEnderTransaction(false)}
        isDisabled={isUnlinking}
        isSubmitting={isUnlinking}
        title='Are you sure you want to remove?'
        subtitle='This action will disconnect the transactions'
        onConfirm={handleUnlinkDoubleEnderTransaction}
      />
    </div>
  );
};

export default ZenEditOfficeDoubleEnderSidebar;
