import {
  faArrowsRepeat,
  faCircleExclamation,
  faFileCertificate,
  faThumbsUp,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommissionAdvanceResponseAdvanceStatusEnum } from '../../../openapi/arrakis';
import { EnumMap } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenStatusPill, { PillVariantType } from '../../Zen/ZenStatusPill';

interface ZenAgentCommissionAdvancesStatusCellProps {
  status: CommissionAdvanceResponseAdvanceStatusEnum;
}

const statusToVariantClassnameMap: EnumMap<
  CommissionAdvanceResponseAdvanceStatusEnum,
  PillVariantType
> = {
  [CommissionAdvanceResponseAdvanceStatusEnum.Approved]: 'primary',
  [CommissionAdvanceResponseAdvanceStatusEnum.Repaid]: 'success',
  [CommissionAdvanceResponseAdvanceStatusEnum.AgreementReceived]: 'warning',
  [CommissionAdvanceResponseAdvanceStatusEnum.Rejected]: 'danger',
};

export const CommissionStatusToLabelMap: EnumMap<
  CommissionAdvanceResponseAdvanceStatusEnum,
  string
> = {
  [CommissionAdvanceResponseAdvanceStatusEnum.Approved]: capitalizeEnum(
    CommissionAdvanceResponseAdvanceStatusEnum.Approved,
  ),
  [CommissionAdvanceResponseAdvanceStatusEnum.Repaid]: capitalizeEnum(
    CommissionAdvanceResponseAdvanceStatusEnum.Repaid,
  ),
  [CommissionAdvanceResponseAdvanceStatusEnum.AgreementReceived]: 'Submitted',
  [CommissionAdvanceResponseAdvanceStatusEnum.Rejected]: 'Voided',
};

const statusToIcon: EnumMap<
  CommissionAdvanceResponseAdvanceStatusEnum,
  React.ReactElement
> = {
  [CommissionAdvanceResponseAdvanceStatusEnum.Approved]: (
    <FontAwesomeIcon icon={faThumbsUp} />
  ),
  [CommissionAdvanceResponseAdvanceStatusEnum.Repaid]: (
    <FontAwesomeIcon
      icon={faArrowsRepeat}
      className='text-green-600 text-base'
    />
  ),
  [CommissionAdvanceResponseAdvanceStatusEnum.AgreementReceived]: (
    <FontAwesomeIcon icon={faFileCertificate} />
  ),
  [CommissionAdvanceResponseAdvanceStatusEnum.Rejected]: (
    <FontAwesomeIcon icon={faCircleExclamation} />
  ),
};

export const ZenAgentCommissionAdvancesStatusCell: React.FC<ZenAgentCommissionAdvancesStatusCellProps> = ({
  status,
}) => {
  return (
    <ZenStatusPill
      text={CommissionStatusToLabelMap[status]}
      variant={statusToVariantClassnameMap[status]}
      icon={statusToIcon[status]}
    />
  );
};

export default ZenAgentCommissionAdvancesStatusCell;
