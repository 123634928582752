import { regular, thin } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faMessage, faPhone } from '@fortawesome/pro-light-svg-icons';
import { faPencil, faPlus, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDisclosure } from '@mantine/hooks';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { useGemini } from '../../hooks/useGemini';
import { MemberIdTypeEnum } from '../../openapi/yada';
import {
  AddGroupMemberRequestRoleEnum,
  AdministrativeAreaResponseStateOrProvinceEnum,
  GroupResponse,
} from '../../openapi/yenta';
import { useCreateConversation } from '../../query/roar/useRoar';
import { addOfficeGroupMember } from '../../slices/OfficeSlice';
import {
  AsyncSelectOption,
  FeatureFlagTypeEnum,
  ISelectOption,
  RootState,
} from '../../types';
import { cn } from '../../utils/classUtils';
import { getConversationLink } from '../../utils/ConversationUtils';
import AdminOnly from '../auth/AdminOnly';
import ExcludeBroker from '../auth/ExcludeBroker';
import { Button } from '../commons/Button';
import CallModalStepper from '../Conversation/CallModalStepper';
import FeatureFlagDisabledOnly from '../FeatureFlagDisabledOnly';
import FeatureFlagEnabledOnly from '../FeatureFlagEnabledOnly';
import GeminiButton from '../Gemini/GeminiButton';
import IconButton from '../IconButton';
import ResourceContainer from '../ResourceContainer';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import GeminiOfficeGroupCardHeader from './GeminiOfficeGroupCardHeader';
import GeminiOfficeGroupCardRow from './GeminiOfficeGroupCardRow';
import OfficeGroupMenu from './OfficeGroupMenu';
import SetCallPriorityModal from './SetCallPriorityModal';

interface GeminiOfficeGroupCardProps {
  group: GroupResponse;
  officeId: string;
  officeState: AdministrativeAreaResponseStateOrProvinceEnum;
}

export interface GeminiGroupMemberFormData {
  userId?: AsyncSelectOption;
  role?: ISelectOption<AddGroupMemberRequestRoleEnum>;
}
interface SearchForm {
  search: string;
}

const GeminiOfficeGroupCard: React.FC<GeminiOfficeGroupCardProps> = ({
  group,
  officeId,
  officeState,
}) => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const [showSetCallPriorityModal, callPriorityModalAction] = useDisclosure(
    false,
  );
  const dispatch = useDispatch();
  const { isAdmin, userDetail } = useSelector((state: RootState) => state.auth);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<GeminiGroupMemberFormData>({
    defaultValues: {
      role: undefined,
      userId: undefined,
    },
  });

  const { control: searchControl, watch } = useForm<SearchForm>({
    defaultValues: {
      search: '',
    },
  });

  const search = watch('search');

  const onSubmit = async (formData: GeminiGroupMemberFormData) => {
    await dispatch(
      addOfficeGroupMember(officeId, group?.id!, {
        role: formData?.role?.value!,
        userId: formData?.userId?.value,
      }),
    );
    setValue('userId', {
      label: 'Search',
      value: '',
    });
    setValue('role', { label: '', value: '' as AddGroupMemberRequestRoleEnum });
    setIsHeaderVisible(false);
  };

  const handleAddMemberClicked = (): void => {
    handleSubmit(onSubmit)();
  };

  const isBrokerTeam = group.groupName === 'Broker Team';
  const { mutate, isLoading } = useCreateConversation();
  const [callModalOpen, callModalActions] = useDisclosure(false);
  const handleGetConversation = (cb: (id: string) => void) => {
    mutate(
      {
        members: [
          {
            value: userDetail?.id!,
            type: MemberIdTypeEnum.User,
          },
          {
            value: group.id!,
            type: MemberIdTypeEnum.Group,
          },
        ],
      },
      {
        onSuccess: (data) => {
          cb(data.id);
        },
      },
    );
  };
  const brokerTeamOptions = [
    {
      label: 'Add Member',
      onClick: () => setIsHeaderVisible(!isHeaderVisible),
    },
    {
      label: 'Set Call Priority',
      onClick: callPriorityModalAction.open,
    },
  ];

  const isGeminiFlagEnabled = useGemini();

  const groupActions = useMemo(() => {
    return (
      <IconButton
        leftIcon={
          isHeaderVisible ? (
            <FontAwesomeIcon
              icon={faXmark}
              className={cn(
                isGeminiFlagEnabled
                  ? 'font-light text-[13px] leading-[23px] text-regent-600'
                  : 'text-zen-dark-12',
              )}
              size='sm'
            />
          ) : (
            <FontAwesomeIcon
              icon={faPencil}
              className={cn(
                isGeminiFlagEnabled
                  ? 'font-light text-[13px] leading-[23px] text-rezen-blue-600'
                  : 'text-primary-blue',
              )}
              size='sm'
            />
          )
        }
        onClick={() => setIsHeaderVisible(!isHeaderVisible)}
        variant='none'
      />
    );
  }, [isGeminiFlagEnabled, isHeaderVisible]);

  return (
    <div className='rounded-[10px] border border-grey-200 bg-white'>
      <div className='flex flex-row justify-between items-center bg-grey-100 h-[45px] px-3 text-lg font-inter text-primary-dark space-x-1 rounded-t-[10px]'>
        <span className='flex flex-grow flex-shrink font-medium text-sm md:text-base font-inter'>
          {group?.groupName}
        </span>
        <FeatureFlagEnabledOnly
          flag={FeatureFlagTypeEnum.ROAR_FULL_RELEASE_BOLT}
        >
          {isBrokerTeam && (
            <ExcludeBroker>
              <div className='flex items-center space-x-0.5'>
                <Button
                  variant='link'
                  leftIcon={
                    <FontAwesomeIcon
                      icon={faPhone}
                      fontSize={13}
                      className='md:mr-1'
                    />
                  }
                  disabled={isLoading}
                  onClick={callModalActions.open}
                >
                  <span className='hidden md:inline'>Call</span>
                </Button>
                <Button
                  variant='link'
                  leftIcon={
                    <FontAwesomeIcon
                      icon={faMessage}
                      fontSize={13}
                      className='md:mr-1'
                    />
                  }
                  disabled={isLoading}
                  onClick={() => {
                    handleGetConversation((id) => {
                      window.open(getConversationLink(id), '_blank');
                    });
                  }}
                >
                  <span className='hidden md:inline'>Message</span>
                </Button>
              </div>
            </ExcludeBroker>
          )}
        </FeatureFlagEnabledOnly>
        {group?.groupMembers?.length !== 0 && isAdmin && !isHeaderVisible && (
          <div className='flex flex-shrink-0 flex-row items-center space-x-2'>
            <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.ROAR}>
              {isBrokerTeam && !isHeaderVisible ? (
                <OfficeGroupMenu options={brokerTeamOptions} />
              ) : (
                <div className='flex justify-center items-center bg-transparent hover:bg-gray-300 rounded-full w-7 h-7'>
                  {groupActions}
                </div>
              )}
            </FeatureFlagEnabledOnly>
            <FeatureFlagDisabledOnly flag={FeatureFlagTypeEnum.ROAR}>
              <div className='flex justify-center items-center'>
                <GeminiButton
                  label='Add Member'
                  variant='primary-link'
                  leftIcon={faPlus}
                  onClick={() => setIsHeaderVisible(!isHeaderVisible)}
                  size='sm'
                />
              </div>
            </FeatureFlagDisabledOnly>
          </div>
        )}
      </div>
      <GeminiOfficeGroupCardHeader
        isVisible={isHeaderVisible}
        groupMembers={group?.groupMembers}
        control={control}
        onSubmit={handleAddMemberClicked}
        onCancel={() => setIsHeaderVisible(!isHeaderVisible)}
        isSubmitting={isSubmitting}
      />
      <ResourceContainer
        isEmpty={!isHeaderVisible && !group?.groupMembers?.length}
        loading={false}
        resourceName='Member'
        errorCode={null}
        EmptyComponent={
          <div className='flex flex-col justify-center items-center p-5 min-h-[434px]'>
            <FontAwesomeIcon
              icon={thin('user-xmark')}
              className='text-regent-600 text-5xl font-extralight mb-3'
              size='sm'
            />
            <p className='text-center text-regent-600 mb-4 font-inter'>
              No Member Added Yet.
            </p>
            <AdminOnly>
              <GeminiButton
                label='Add Member'
                leftIcon={faPlus}
                onClick={() => setIsHeaderVisible(!isHeaderVisible)}
                size='sm'
              />
            </AdminOnly>
          </div>
        }
      >
        <div className='flex flex-col px-2'>
          {!isHeaderVisible && (
            <>
              <div className='px-2 mt-4'>
                <ZenControlledTextInput
                  name='search'
                  control={searchControl}
                  type='search'
                  placeholder='Search for Broker by name or email...'
                  startAdornment={
                    <div className='flex w-full h-full items-center justify-center pl-2'>
                      <FontAwesomeIcon
                        className='text-primary-blue'
                        icon={regular('search')}
                      />
                    </div>
                  }
                />
              </div>
              <div className='flex flex-col min-h-[380px] max-h-[380px] overflow-y-auto'>
                {group?.groupMembers
                  ?.filter((member) => {
                    const fullName = member.user?.fullName || '';
                    const emailAddress = member.user?.emailAddress || '';
                    const searchString = (search || '').toLowerCase();
                    return (
                      fullName.toLowerCase().includes(searchString) ||
                      emailAddress.toLowerCase().includes(searchString)
                    );
                  })
                  .map((member) => {
                    return (
                      <GeminiOfficeGroupCardRow
                        key={member?.user?.id}
                        member={member}
                        groupId={group?.id!}
                        officeId={officeId}
                        isVisible={!isHeaderVisible}
                        length={group?.groupMembers?.length}
                      />
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </ResourceContainer>
      <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.ROAR_FULL_RELEASE_BOLT}>
        <CallModalStepper
          opened={callModalOpen}
          onClose={callModalActions.close}
          groupId={group.id!}
          officeState={officeState}
          getConversation={handleGetConversation}
          isFetchingConversation={isLoading}
        />
      </FeatureFlagEnabledOnly>
      <SetCallPriorityModal
        officeId={officeId}
        groups={isBrokerTeam ? group.groupMembers : undefined}
        onClose={callPriorityModalAction.close}
        opened={showSetCallPriorityModal}
      />
    </div>
  );
};

export default GeminiOfficeGroupCard;
