import { faPhone } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import {
  ParticipantValue,
  PaymentParticipantValue,
} from '../../openapi/arrakis';
import { RootState } from '../../types';
import { getFullName } from '../../utils/AgentHelper';
import { capitalizeEnum } from '../../utils/StringUtils';
import ZenUserPill from '../Zen/ZenUserPill';
import CallModal from './CallModal';

interface Props {
  participants: PaymentParticipantValue[] | ParticipantValue[];
}

const ParticipantResourceTable: React.FC<Props> = ({ participants }) => {
  const {
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  const [currentParticipant, setCurrentParticipant] = useState<
    ParticipantValue | PaymentParticipantValue
  >();
  const [opened, callModalAction] = useDisclosure(false);

  useEffect(() => {
    if (!!currentParticipant) {
      callModalAction.open();
    } else {
      callModalAction.close();
    }
  }, [currentParticipant]);
  return (
    <div className='font-inter'>
      <div className='grid text-sm text-grey-600  sticky top-0 grid-cols-12 m-2 p-2 bg-regent-100'>
        <div className='col-span-6'>Name</div>
        <div className='col-span-5'>Role</div>
        <div className='col-span-1 text-right'>Actions</div>
      </div>
      {participants.map((participant, index) => {
        const isLast = participants?.length! - 1 === index ? '' : 'border-b';
        return (
          <div
            className={`${isLast} border-regent-300 px-2 py-4 grid grid-cols-12`}
            key={participant.id}
          >
            <div className='col-span-6 text-sm flex items-center'>
              <ZenUserPill
                name={getFullName(participant)!}
                imageUrl={agentById[participant.yentaId!]?.avatar}
              />
            </div>
            <div className='col-span-5 text-sm flex items-center text-grey-600 font-thin'>
              {capitalizeEnum(participant.role!)}
            </div>
            <button
              onClick={() => setCurrentParticipant(participant)}
              title='Call'
              className='col-span-1 text-right mr-8 flex justify-center items-center min-w-8'
            >
              <div className='bg-regent-300 flex justify-center items-center rounded-full min-w-[2rem] w-8 h-8'>
                <FontAwesomeIcon icon={faPhone} fontSize={14} />
              </div>
            </button>
          </div>
        );
      })}
      <CallModal
        participant={currentParticipant}
        onClose={() => setCurrentParticipant(undefined)}
        opened={opened}
      />
    </div>
  );
};

export default ParticipantResourceTable;
