import { useCallback, useEffect, useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import {
  AuthorizedPersonDtoTypeEnum,
  LoanDto,
} from '../../../openapi/atlantis';
import {
  assignAuthorizedPersonToLoan,
  removeAuthorizedPersonFromLoan,
} from '../../../slices/MortgageSlice';
import { AppDispatch, ISelectOption } from '../../../types';
import { getAuthorizedPerson } from '../../../utils/MortgageUtils';

const useAssignAuthorizedPersons = (
  loan: LoanDto,
  control: Control<any, object>,
) => {
  const dispatch: AppDispatch = useDispatch();
  const [
    loanOfficer,
    loanOfficerAssistant,
    processor,
    realOriginateLoanOfficer,
  ] = useWatch({
    control,
    name: [
      'loanOfficer',
      'loanOfficerAssistant',
      'processor',
      'realOriginateLoanOfficer',
    ],
  });

  const getAssignAuthorizedPerson = useCallback(
    (
      type: AuthorizedPersonDtoTypeEnum,
      watchPerson: ISelectOption,
    ): string | undefined => {
      const person = getAuthorizedPerson(loan.authorizedPersons, type);

      if (person?.value === watchPerson?.value) {
        return undefined;
      }

      return watchPerson?.value;
    },
    [loan.authorizedPersons],
  );

  const getUnAssignAuthorizedPerson = useCallback(
    (
      type: AuthorizedPersonDtoTypeEnum,
      watchPerson: ISelectOption,
    ): string | undefined => {
      const person = getAuthorizedPerson(loan.authorizedPersons, type);

      if (person?.value && watchPerson?.value === undefined) {
        return person.value;
      }

      return undefined;
    },
    [loan.authorizedPersons],
  );

  const selectedAssignAuthorizedPersons = useMemo(
    () =>
      [
        getAssignAuthorizedPerson(
          AuthorizedPersonDtoTypeEnum.LoanOfficer,
          loanOfficer,
        ),
        getAssignAuthorizedPerson(
          AuthorizedPersonDtoTypeEnum.LoanOfficerAssistant,
          loanOfficerAssistant,
        ),
        getAssignAuthorizedPerson(
          AuthorizedPersonDtoTypeEnum.LoanProcessor,
          processor,
        ),
        getAssignAuthorizedPerson(
          AuthorizedPersonDtoTypeEnum.RealOriginateLoanOfficer,
          realOriginateLoanOfficer,
        ),
      ].filter(Boolean) as string[],
    [
      loanOfficer,
      loanOfficerAssistant,
      processor,
      realOriginateLoanOfficer,
      getAssignAuthorizedPerson,
    ],
  );

  const removeAuthorizedPerson = useCallback(() => {
    const authorizedPersonIds = [
      getUnAssignAuthorizedPerson(
        AuthorizedPersonDtoTypeEnum.LoanOfficerAssistant,
        loanOfficerAssistant,
      ),
      getUnAssignAuthorizedPerson(
        AuthorizedPersonDtoTypeEnum.LoanProcessor,
        processor,
      ),
      getUnAssignAuthorizedPerson(
        AuthorizedPersonDtoTypeEnum.RealOriginateLoanOfficer,
        realOriginateLoanOfficer,
      ),
    ].filter(Boolean) as string[];

    authorizedPersonIds.forEach((authorizedPersonId) => {
      dispatch(removeAuthorizedPersonFromLoan(loan.id!, authorizedPersonId));
    });
  }, [
    dispatch,
    getUnAssignAuthorizedPerson,
    loan.id,
    processor,
    realOriginateLoanOfficer,
    loanOfficerAssistant,
  ]);

  useEffect(() => {
    removeAuthorizedPerson();
  }, [removeAuthorizedPerson]);

  useEffect(() => {
    if (selectedAssignAuthorizedPersons.length > 0) {
      dispatch(
        assignAuthorizedPersonToLoan(loan.id!, selectedAssignAuthorizedPersons),
      );
    }
  }, [dispatch, loan.id, selectedAssignAuthorizedPersons]);
};

export default useAssignAuthorizedPersons;
