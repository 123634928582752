import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ZenNavRealLogo from '../assets/img/new-rezen-white-logo.svg';
import { useGemini } from '../hooks/useGemini';
import { RootState } from '../types';
import { cn } from '../utils/classUtils';
import ZenLoanOfficerMenu from './Menu/BrokerQueue/ZenLoanOfficerMenu';
import NeoAdminMenu from './Menu/NeoMenu/NeoAdminMenu';
import NeoUserMenu from './Menu/NeoMenu/NeoUserMenu';
import ZenBrokerMenu from './Menu/ZenMenu/ZenBrokerMenu';
import ZenRealTitleOfficerMenu from './Menu/ZenMenu/ZenRealTitleOfficerMenu';
import ZenSuperAdminMenu from './Menu/ZenMenu/ZenSuperAdminMenu';
import NeoLeoIndex from './NeoLeo/NeoLeoIndex';
import NeoLeoTrigger from './NeoLeo/NeoLeoTrigger';
import NeoSideBarUserControl from './NeoLeo/NeoSideBarUserControl';
import { ZenMainMenuDemoModeBadge } from './ZenMainMenuDemoModeBadge';
import ZenSidebarItem from './ZenSideBarItem';

interface SideBarProps {}

const ANIMATION_DURATION = 200;

const NeoMainMenu: React.FC<SideBarProps> = () => {
  const {
    userDetail,
    isAdmin,
    isBroker,
    isSuperAdmin,
    isRealTitleOfficer,
    isAnnouncer,
    isMortgageUserRole,
  } = useSelector((state: RootState) => state.auth);

  const isGeminiEnabled = useGemini();

  const [openNeoLeoPanel, setOpenNeoLeoPanel] = React.useState<boolean>(false);
  const [leoEntrance, setLeoEntrance] = React.useState(true);

  useEffect(() => {
    if (!leoEntrance) {
      const closeTimer = setTimeout(() => {
        setOpenNeoLeoPanel(false);
      }, ANIMATION_DURATION * 0.9);
      return () => {
        clearTimeout(closeTimer);
      };
    } else {
      setOpenNeoLeoPanel(true);
      return () => {};
    }
  }, [leoEntrance]);

  const handleNeoLeoToggle = () => {
    const entering = !leoEntrance;
    if (entering) {
      setLeoEntrance(true);
    } else {
      setLeoEntrance(false);
    }
  };

  return (
    <div aria-label='mainMenu'>
      <div className={cn('left-0 top-0 bottom-0 static right-0 z-50')}>
        <div
          className={cn(
            'h-full-window flex flex-row justify-between overflow-y-auto',
          )}
        >
          <div
            className={cn(
              'scrollbar-hide overflow-y-auto z-10',
              isGeminiEnabled ? 'bg-primary-navy' : 'bg-zen-dark-21',
              'w-[50px]',
            )}
          >
            <div className='py-4 flex flex-col justify-between items-center flex-grow'>
              <div className='px-1'>
                <Link to='/'>
                  <img
                    src={ZenNavRealLogo}
                    alt='real-logo'
                    className='h-4 ml-1.5 mb-4 w-9'
                  />
                </Link>
                <NeoLeoTrigger neoLeoToggle={handleNeoLeoToggle} />
                <div className='px-1'>
                  <ZenMainMenuDemoModeBadge isCollapsed />
                  {isSuperAdmin ? (
                    <ZenSuperAdminMenu isCollapsed />
                  ) : isAdmin ? (
                    <NeoAdminMenu isCollapsed />
                  ) : isBroker ? (
                    <ZenBrokerMenu isCollapsed />
                  ) : isMortgageUserRole ? (
                    <ZenLoanOfficerMenu isCollapsed />
                  ) : isRealTitleOfficer ? (
                    <ZenRealTitleOfficerMenu isCollapsed />
                  ) : (
                    <NeoUserMenu isCollapsed />
                  )}

                  {isAnnouncer && (
                    <ZenSidebarItem
                      icon={<FontAwesomeIcon icon={regular('bullhorn')} />}
                      label='Announcement'
                      linkTo='/announcement'
                      collapsed
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={cn(
                'flex flex-col gap-2 items-center justify-center bottom-0 sticky  w-full',
                isGeminiEnabled ? 'bg-primary-navy' : 'bg-zen-dark-21',
              )}
            >
              <div className='border-t border-zen-gray-6 py-2 w-full px-2 flex items-center justify-center'>
                <NeoSideBarUserControl userDetail={userDetail!} />
              </div>
            </div>
          </div>
          {openNeoLeoPanel && (
            <div
              className={cn(
                leoEntrance
                  ? 'animate-neo-leo-container-entrance'
                  : 'animate-neo-leo-container-exit',
              )}
            >
              <div
                className={cn(
                  'h-full-window relative w-[357px] overflow-hidden',
                  'p-4 pr-0',
                  leoEntrance
                    ? 'animate-neo-leo-model-entrance'
                    : 'animate-neo-leo-model-exit',
                )}
              >
                <div
                  className={cn(
                    'flex flex-col justify-between bg-primary-navy rounded-[20px] h-full overflow-hidden',
                  )}
                >
                  <NeoLeoIndex />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NeoMainMenu;
