import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DebitOfferingDto } from '../../openapi/wallet';
import {
  useFetchEligibleOfferings,
  useWallet,
} from '../../query/wallet/useWallet';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';
import ZenPageLayout from '../Zen/ZenPageLayout';
import ZenResourceContainer from '../Zen/ZenResourceContainer';
import WalletAgreementModal from './WalletAgreementModal';
import WalletDebitOfferingCard from './WalletDebitOfferingCard';

interface WalletOfferingsProps {}

const WalletOfferings: React.FC<WalletOfferingsProps> = () => {
  const history = useHistory();
  const [offer, setOffer] = useState<DebitOfferingDto>();

  const { data, isLoading, errorCode } = useFetchEligibleOfferings();
  const { data: wallet } = useWallet();

  // 2nd !isEmpty --> if this component is rendered and banking partnership is not empty,
  // then either card or account is not created yet.
  const isApplicationPending =
    !isEmpty(wallet?.pendingApplications) ||
    !isEmpty(wallet?.bankingPartnerships);

  if (data && isEmpty(data?.eligibleDebitOfferings)) {
    return <p className='m-4 font-inter'>No Offers</p>;
  }

  return (
    <div>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          {
            title: 'Real Wallet',
            url: '/wallet',
          },
          {
            title: 'Apply for Business Checking Account',
            url: '/wallet/offerings',
          },
        ]}
        textClassNames='font-inter'
      >
        <ZenResourceContainer
          loading={isLoading}
          isEmpty={isEmpty(data)}
          resourceName='offering'
          errorCode={errorCode}
        >
          <div className='flex flex-col items-center p-10 xl:pt-20'>
            <div className='flex flex-col xl:flex-row justify-center gap-6'>
              {/* Pending Applications */}
              {isApplicationPending &&
                wallet?.pendingApplications?.map((application) => {
                  return (
                    <WalletDebitOfferingCard
                      key={application.accountApplicationId}
                      application={application}
                    />
                  );
                })}

              {/* Debit Card */}
              {!isApplicationPending &&
                data?.eligibleDebitOfferings?.map(({ debitOffering }) => {
                  return (
                    <WalletDebitOfferingCard
                      key={debitOffering?.id}
                      onSignUpClick={() => {
                        setOffer(debitOffering);
                        AnalyticsService.instance().logEvent(
                          AnalyticsEventEnum.WALLET_SIGN_UP_FOR_DEBIT_CLICKED,
                        );
                      }}
                    />
                  );
                })}
            </div>
          </div>
        </ZenResourceContainer>

        {offer && (
          <WalletAgreementModal
            offer={offer}
            onClose={() => setOffer(undefined)}
            onEverythingSigned={() =>
              history.push(`/wallet/signup/debit/${offer?.id}`)
            }
          />
        )}
      </ZenPageLayout>
    </div>
  );
};

export default WalletOfferings;
