import { FieldPath, FieldValues, useController } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/pro-light-svg-icons';
import { MaskedInput, MaskedInputProps } from '../MaskedInput';
import { HookFormControllerProps } from './types';
import { getUnmaskValue } from './utils';

type HookFormMaskedInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = HookFormControllerProps<TFieldValues, TName> &
  Omit<MaskedInputProps, 'mask'>;

const PHONE_NUMBER_MASK = '+1 (999)-999-9999';

export const HookFormPhoneInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  onChange,
  ...props
}: HookFormMaskedInputProps<TFieldValues, TName>) => {
  const {
    formState: { defaultValues },
    field,
    fieldState,
  } = useController({
    name: props.name,
    control: props.control,
    rules: props.rules,
  });

  return (
    <MaskedInput
      icon={<FontAwesomeIcon icon={faPhone} />}
      {...props}
      mask={PHONE_NUMBER_MASK}
      defaultValue={defaultValues?.[props.name]}
      onChange={(e) => {
        const val = e.target.value;
        let unmaskedValue = getUnmaskValue(
          val,
          PHONE_NUMBER_MASK,
          props.maskChar ?? ' ',
        );
        if (unmaskedValue) {
          unmaskedValue = '1' + unmaskedValue;
        }
        field.onChange(unmaskedValue);
        onChange?.(unmaskedValue);
      }}
      value={field.value}
      onBlur={field.onBlur}
      error={fieldState.error?.message}
    />
  );
};
