import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  ChecklistApi,
  ChecklistProgressResponse,
  ChecklistResponse,
} from '../../openapi/sherlock';
import { saveChecklistProgressByIds } from '../../slices/CheckListSlice';
import { getSherlockConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { queryKeys } from '../base/queryKeys';
import { useSimpleQuery } from '../base/useSimpleQuery';

export const useFetchChecklistProgressByIds = (
  checklistIds: string[],
  enabled: boolean = false,
) => {
  const dispatch = useDispatch();
  const getChecklistProgressByIds = async (ids: string[]) => {
    const { data } = await new ChecklistApi(
      getSherlockConfiguration(),
    ).getChecklistProgressByIds(ids);
    return data;
  };

  const queryResult = useSimpleQuery<ChecklistProgressResponse[]>({
    queryKey: queryKeys.checklist.getChecklistProgressByIds(checklistIds)
      .queryKey,
    queryFn: () => getChecklistProgressByIds(checklistIds),
    options: {
      toastErrorMessage: 'Error retrieving checklist progress',
      logErrorMessage: 'Error retrieving checklist progress',
      enabled,
    },
  });

  useEffect(() => {
    dispatch(
      saveChecklistProgressByIds(queryResult.data?.filter(Boolean) || []),
    );
  }, [dispatch, queryResult.data]);

  return {
    data: queryResult.data,
  };
};

export const useFetchChecklistById = (
  checklistId?: string,
  enabled: boolean = false,
) => {
  const getChecklistById = async (id: string) => {
    const { data } = await new ChecklistApi(
      getSherlockConfiguration(),
    ).getChecklistById(id);
    return data;
  };

  const queryResult = useSimpleQuery<ChecklistResponse>({
    queryKey: queryKeys.checklist.detail(checklistId!).queryKey,
    queryFn: () => getChecklistById(checklistId!),
    options: {
      toastErrorMessage: 'Error retrieving checklist detail',
      logErrorMessage: 'Error retrieving checklist detail',
      enabled: !!checklistId && enabled,
    },
  });

  return {
    isLoading: queryResult.isLoading,
    data: queryResult.data,
  };
};
