import { DateTime, Duration } from 'luxon';

export const MAX_YEAR_ALLOWED = 2200;

export const getYesterdayDate = (): DateTime => {
  return DateTime.now().minus({ day: 1 });
};

export const getFormattedDateISOString = (date: string) => {
  return DateTime.fromISO(date).toFormat('LL/dd/yyyy');
};

export const displayDate = (date: string, format: string = 'MMM dd, yyyy') => {
  return DateTime.fromISO(date).toFormat(format);
};

export const displayTime = (date: string) => {
  return DateTime.fromISO(date).toFormat('hh:mm a');
};

export const displayTimeAndDate = (date: string) => {
  return DateTime.fromISO(date).toFormat('hh:mm a, LL-dd-yyyy');
};

export const isToday = (date: string) =>
  DateTime.now().toISODate() === DateTime.fromISO(date).toISODate();

export const getFormattedDate = (date: number) => {
  return DateTime.fromMillis(date).toFormat('LL/dd/yyyy');
};

export const getFormattedDateTimeWithTimezone = (date: number) => {
  return DateTime.fromMillis(date).toFormat('LL/dd/yyyy hh:mm a ZZZZ');
};

export const getFormattedDateWithTimezoneForISOString = (date: string) => {
  return DateTime.fromISO(date).toFormat('LL/dd/yyyy hh:mm a ZZZZ');
};

export const getRelativeTimeShort = (dateTime: number) => {
  const timeAgo = DateTime.fromMillis(dateTime).toRelative({
    style: 'narrow',
  });

  const timeString = timeAgo?.split(' ').slice(0, 2)!;

  timeString[1] = timeString[1].substring(0, timeString[1] === 'mo' ? 2 : 1);

  return `${timeString.join('')}`;
};

export const getFormattedExpiryTime = (date: string): string => {
  const expirationDate = DateTime.fromISO(date).toFormat('MMMM dd, yyyy');
  return expirationDate;
};

export const getFormattedRevokeTime = (date: number): string => {
  const expirationDate = DateTime.fromMillis(date).toFormat('MMMM dd, yyyy');
  return expirationDate;
};

export const getCurrentYear = (): number => {
  return DateTime.now().year;
};

export const getLastYear = (): number => {
  return DateTime.now().minus({ year: 1 }).year;
};

// converts milliseconds to human readable string - 12 days, 10 hours ago, etc.
export const convertMillisToHuman = (millis: number) => {
  const units: Intl.RelativeTimeFormatUnit[] = [
    'days',
    'hours',
    'minutes',
    'seconds',
  ];

  const diff = Duration.fromMillis(millis!).shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';
  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  });

  return relativeFormatter
    .format(Math.trunc(diff.as(unit)), unit)
    .replace('in', '')
    .trim();
};

export const getAutoFormattedDate = (date: string | undefined) => {
  const validFormat = [
    'L/d/yy',
    'L/d/yyyy',
    'L/dd/yy',
    'L/dd/yyyy',
    'LL/d/yy',
    'LL/d/yyyy',
    'LL/dd/yy',
    'LL/dd/yyyy',
  ];
  if (date) {
    const isValidDate = validFormat.some(
      (format) => DateTime.fromFormat(date, format).isValid,
    );
    if (isValidDate) {
      const format = validFormat?.find(
        (format) => DateTime.fromFormat(date, format).isValid,
      )!;
      return DateTime.fromFormat(date, format).toFormat('LL/dd/yyyy');
    }

    return date;
  }

  return '';
};

export const formatTime = (time: number) => {
  if (time) {
    return [
      Math.floor((time % 3600) / 60),
      ('00' + Math.floor(time % 60)).slice(-2),
    ].join(':');
  }

  return '0:00';
};

export const getCurrentUserTimeZone = (date?: number) => {
  const userDate = date ? DateTime.fromMillis(date) : DateTime.local();

  return userDate?.offsetNameLong
    ?.match(new RegExp('[A-Z](?!.*[(])', 'g'))
    ?.join('');
};

export const generateTimeSlots = (interval: number): DateTime[] => {
  const arr = [];
  let start = DateTime.now().set({ hour: 0, minute: 0, second: 0 });
  let end = DateTime.now().set({ hour: 24, minute: 0, second: 0 });

  while (start <= end) {
    arr.push(start);
    start = start.set({ minute: start.get('minute') + interval });
  }
  arr.pop(); // remove duplicate

  return arr;
};
