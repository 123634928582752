import { DateTime } from 'luxon';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import GeminiAvatar from '../../GeminiAvatar';

export interface GeminiReadReceiptProps {
  imageUrl?: string;
  firstName?: string;
  lastName?: string;
  id?: string;
  keymakerId?: string;
  readAt?: number;
}

interface GeminiAgentInfoRowProps {
  user: GeminiReadReceiptProps;
}

const GeminiAgentInfoRow: React.FC<GeminiAgentInfoRowProps> = ({ user }) => {
  const { agentById } = useSelector((state: RootState) => state.userIds);
  const agent = agentById[user?.id!];

  return (
    <div className='flex justify-between items-center py-4 border-b-[1px] border-grey-200'>
      <div className='flex gap-2 mr-2'>
        <GeminiAvatar
          name={`${user.firstName} ${user.lastName}`}
          imageUrl={user?.imageUrl}
        />
        <div>
          <div className='flex text-primary-dark font-inter text-sm font-normal'>
            {`${user.firstName} ${user.lastName}`}
          </div>
          <div className='text-regent-600 font-light text-sm font-inter'>
            {agent?.emailAddress || 'N/A'}
          </div>
        </div>
      </div>
      <div className='flex gap-2 ml-2'>
        <div className='text-primary-dark font-inter text-sm font-normal'>
          {user.readAt &&
            DateTime.fromMillis(user.readAt).toFormat('MM/dd/yyyy')}
        </div>
        <div className='text-regent-600 font-inter text-sm font-light'>
          {user.readAt &&
            DateTime.fromMillis(user.readAt).toFormat('h:mma').toLowerCase()}
        </div>
      </div>
    </div>
  );
};

export default GeminiAgentInfoRow;
