import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export interface ZenApplicationModalProps {
  title: string;
  subtitle?: string;
  isOpen: boolean;
  onClose?(): void;
  isSubmitting?: boolean;
  handleSubmit: (event: React.FormEvent) => void;
}

const ZenApplicationModal: React.FC<ZenApplicationModalProps> = ({
  isOpen,
  onClose,
  title,
  subtitle,
  children,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-60'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full overflow-y-auto'>
        <div className='flex flex-col justify-center w-full bg-white border rounded-xl z-10 md:w-full max-w-xl shadow-sm shadow-gray-300 lg:p-6 p-2'>
          <div className='pb-4'>
            <div className='flex items-center justify-center bg-rezen-blue-100 p-[18px] rounded-full shrink-0 max-w-min'>
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className='text-2xl text-rezen-blue-600'
              />
            </div>
            {!!title && (
              <h2 className='font-inter text-xl font-medium text-primary-dark mt-4'>
                {title}
              </h2>
            )}
            {!!subtitle && (
              <p className='font-inter text-base font-light text-primary-dark mt-1'>
                {subtitle}
              </p>
            )}
          </div>
          <div className='relative w-full'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ZenApplicationModal;
