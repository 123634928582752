import { FC, useState } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { TransactionCoordinatorAccessEnum } from '../../types';
import { Button } from '../commons/Button';
import ZenAvatar from '../Zen/ZenAvatar';
import { TCInfo } from './TCInfoSection';
import TCTransactionsAccessCard, {
  TransactionCoordinatorFromData,
} from './TCTransactionsAccessCard';

interface TCSelectCardProps {
  control: Control<TransactionCoordinatorFromData, object>;
  setValue: UseFormSetValue<TransactionCoordinatorFromData>;
  selectedAccessType: TransactionCoordinatorAccessEnum;
  setIsTcSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  isTcSelected?: boolean;
  tcInfo?: TCInfo;
  onSelect?: () => void;
  onSubmit?: () => void;
  onCancel?: () => void;
}

const TCSelectCard: FC<TCSelectCardProps> = ({
  control,
  setValue,
  tcInfo,
  selectedAccessType,
  isTcSelected,
  setIsTcSelected,
  onSelect,
  onSubmit,
  onCancel,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen((prev) => {
      setIsTcSelected?.(!prev);
      return !prev;
    });
  };

  return (
    <div className='w-full font-inter border-gray-200 border p-4 rounded-lg'>
      <div className='flex items-center gap-12 justify-between'>
        <div className='flex items-center gap-2'>
          <ZenAvatar name={tcInfo?.name!} imageUrl={tcInfo?.avatar} size='lg' />
          <div className='text-xs sm:text-sm'>
            <p className='text-primary-dark font-medium line-clamp-1'>
              {tcInfo?.name}
            </p>
            <p className='text-grey-600 font-light line-clamp-1'>
              {tcInfo?.email}
            </p>
          </div>
        </div>
        {!isOpen &&
          (isTcSelected ? (
            <p className='text-regent-600 text-sm sm:text-base font-medium'>
              Select
            </p>
          ) : (
            <Button
              variant='outline'
              color='black'
              onClick={() => {
                toggle();
                onSelect?.();
              }}
            >
              Select
            </Button>
          ))}
      </div>
      {isOpen && (
        <div className='mt-4'>
          <TCTransactionsAccessCard
            control={control}
            setValue={setValue}
            selectedAccessType={selectedAccessType}
          />
          <div className='flex justify-end gap-5 mt-3'>
            <button
              onClick={() => {
                toggle();
                onCancel?.();
              }}
              className='font-medium text-primary-dark'
            >
              Cancel
            </button>
            <div className='w-[164px]'>
              <Button
                onClick={onSubmit}
                disabled={!selectedAccessType}
                fullWidth
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TCSelectCard;
