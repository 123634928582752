import {
  faArrowLeftLong,
  faPhoneArrowUpRight,
} from '@fortawesome/pro-light-svg-icons';
import { faUsers, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import { TransactionControllerApi } from '../../openapi/arrakis';
import {
  CreateVoiceCallRequestContainerTypeEnum,
  CreateVoiceCallRequestTargetTypeEnum,
} from '../../openapi/yada';
import {
  AdministrativeAreaResponseStateOrProvinceEnum,
  AgentResponse,
  UserResponse,
} from '../../openapi/yenta';
import { useFetchOfficeVisibleGroupsById } from '../../query/office/useOffice';
import { useFetchTransactionById } from '../../query/transaction/useTransaction';
import ErrorService from '../../services/ErrorService';
import { ISelectOption, RootState } from '../../types';
import { cn } from '../../utils/classUtils';
import { isBrokerTeam } from '../../utils/OfficeUtils';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { STATE_OR_PROVINCE_ABBREVIATIONS } from '../../utils/StateUtils';
import Avatar from '../Avatar';
import { Button } from '../commons/Button';
import { Modal } from '../commons/Modal';
import CallModalForm from '../Roar/CallModalForm';
import ZenControlledAsyncSelectInput from '../Zen/Input/ZenControlledAsyncSelectInput';

type CallModalStepperProps = {
  opened: boolean;
  onClose: () => void;
  groupId: string;
  officeState?: AdministrativeAreaResponseStateOrProvinceEnum;
  callAgent?: boolean;
  agentInfo?: UserResponse | AgentResponse;
  officeIds?: string[];
  isFetchingConversation: boolean;
  getConversation: (cb: (id: string) => void) => void;
};

type CallModalStepperContentProps = Omit<CallModalStepperProps, 'opened'>;

const CallModalStepperContent: React.FC<CallModalStepperContentProps> = ({
  groupId,
  onClose,
  officeState,
  agentInfo,
  callAgent,
  // officeIds,
  isFetchingConversation,
  getConversation,
}) => {
  const [step, setStep] = useState(0);
  const [isTransaction, setIsTransaction] = useState(false);
  const [conversationId, setConversationId] = useState<string>('');

  const userDetail = useSelector((state: RootState) => state.auth.userDetail);

  const { control, watch } = useForm<{ transactionId?: ISelectOption }>();

  const transactionId = watch('transactionId');

  const onNextStep = (skip?: boolean) => {
    const count = skip ? 2 : 1;
    setStep((prevStep) => Math.min(2, prevStep + count));
  };

  const onPreviousStep = (skip?: boolean) => {
    const count = skip ? 2 : 1;
    setStep((prevStep) => Math.max(0, prevStep - count));
  };

  const fetchTransactions = useCallback(
    async (search: string, page?: number | undefined) => {
      let options: ISelectOption[] = [];

      try {
        const { data } = await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).getTransactionsByStateGroupPaginated(
          callAgent ? agentInfo?.id! : userDetail?.id!,
          'OPEN',
          page || 0,
          20,
          search,
          'ESCROW_CLOSING_DATE',
          'ASC',
        );

        options =
          data.transactions?.map((transaction) => ({
            label: `${transaction?.address?.oneLine} - ${transaction?.code}`,
            value: transaction?.id!,
          })) ?? [];
      } catch (e) {
        ErrorService.notify('Unable to fetch transactions', e, {
          data: { search, page },
        });
      }

      return options;
    },
    [agentInfo?.id, callAgent, userDetail?.id],
  );

  const { isLoading: isLoadingTx, data: txResponse } = useFetchTransactionById(
    transactionId?.value!,
  );

  const officeId = txResponse?.office?.id;

  const {
    isLoading: isLoadingGroups,
    data: officeVisibleGroups,
  } = useFetchOfficeVisibleGroupsById(officeId!);

  const txOfficeGroupId = officeVisibleGroups?.groups?.find(isBrokerTeam)?.id!;

  return (
    <div className='relative'>
      {step ? (
        <Modal.Header color='white' className='pb-0'>
          <button
            className='px-2 py-1.5 rounded-md space-x-2 font-inter text-primary-dark border border-regent-400'
            onClick={() => {
              onPreviousStep(!isTransaction);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeftLong} fontSize={14} />
            <span className='text-sm font-medium'>Back</span>
          </button>
        </Modal.Header>
      ) : (
        <div className='absolute top-3.5 right-0'>
          <div className='ml-auto mr-6 w-6 h-6 rounded-full bg-grey-400/70 cursor-pointer relative'>
            <FontAwesomeIcon
              icon={faXmark}
              onClick={onClose}
              fontSize={24}
              className='text-primary-light absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3/5 h-3/5'
            />
          </div>
        </div>
      )}
      <div
        className={cn(
          'pb-4 px-8',
          step ? 'pt-1' : 'pt-3.5',
          step === 2 && callAgent && 'pb-0',
        )}
      >
        <div className='flex flex-col items-center space-y-1'>
          {callAgent ? (
            <>
              <div className='relative'>
                <FontAwesomeIcon
                  icon={faPhoneArrowUpRight}
                  className='p-1 border absolute -right-1 -top-1 bg-white text-rezen-blue-600 rounded-full'
                  fontSize={9}
                />
                <Avatar
                  name={agentInfo?.fullName!}
                  size='xs'
                  imageUrl={agentInfo?.avatar}
                />
              </div>
              <p className='text-primary-dark font-inter text-base font-normal text-center mx-5'>
                {agentInfo?.fullName ?? 'N/A'}
              </p>
            </>
          ) : (
            <>
              <div className='relative bg-[#DBE9FF] w-10 h-10 rounded-full flex items-center justify-center'>
                <FontAwesomeIcon
                  icon={faPhoneArrowUpRight}
                  className='p-1 border absolute -right-1 -top-1 bg-white text-rezen-blue-600 rounded-full'
                  fontSize={9}
                />
                <FontAwesomeIcon
                  icon={faUsers}
                  size='sm'
                  className='text-rezen-blue-600'
                />
              </div>
              <p className='text-primary-dark font-inter text-base font-normal'>
                Broker Team
              </p>
              <p className='font-inter text-gray-500 text-sm font-normal'>
                Office -{' '}
                {STATE_OR_PROVINCE_ABBREVIATIONS[officeState!] ?? 'N/A'}
              </p>
            </>
          )}
        </div>
        {step === 0 && (
          <div className='mt-5 space-y-3'>
            <p className='font-inter text-primary-dark text-[15px] font-semibold text-center'>
              Is this call about a specific transaction?
            </p>
            <button
              className='w-full p-4 flex flex-col justify-center items-center space-y-0.5 border-[0.8px] border-grey-300 hover:bg-regent-200 rounded-lg font-inter text-primary-dark text-center font-medium text-base disabled:opacity-50'
              disabled={isFetchingConversation}
              onClick={() => {
                setIsTransaction(true);
                onNextStep();
              }}
            >
              Yes
            </button>
            <button
              className='w-full p-4 flex flex-col justify-center items-center space-y-0.5 border-[0.8px] border-grey-300 hover:bg-regent-200 rounded-lg font-inter text-primary-dark text-center font-medium text-base disabled:opacity-50'
              disabled={isFetchingConversation}
              onClick={() => {
                setIsTransaction(false);
                getConversation((id) => {
                  setConversationId(id);
                  onNextStep(true);
                });
              }}
            >
              No
            </button>
          </div>
        )}
        {step === 1 && (
          <>
            <div className='mt-5 space-y-3'>
              <p className='font-inter text-primary-dark text-[15px] font-semibold text-center'>
                Which transaction is this related to?
              </p>
              <ZenControlledAsyncSelectInput
                control={control}
                name='transactionId'
                shouldUnregister={false}
                placeholder='Select Transaction'
                fetchData={fetchTransactions}
              />
            </div>
            <div className='!mt-8 flex justify-end'>
              <Button
                className='w-36 text-center'
                onClick={() => onNextStep()}
                disabled={
                  !transactionId ||
                  !txOfficeGroupId ||
                  isLoadingGroups ||
                  isLoadingTx
                }
              >
                Next
              </Button>
            </div>
          </>
        )}
        {step === 2 && (
          <CallModalForm
            phoneNumber={agentInfo?.phoneNumber}
            voiceCallPayload={{
              containerId: isTransaction
                ? transactionId?.value
                : conversationId,
              targetId: callAgent
                ? agentInfo?.id!
                : isTransaction
                ? txOfficeGroupId
                : groupId,
              containerType: isTransaction
                ? CreateVoiceCallRequestContainerTypeEnum.Transaction
                : CreateVoiceCallRequestContainerTypeEnum.Conversation,
              targetType: callAgent
                ? CreateVoiceCallRequestTargetTypeEnum.User
                : CreateVoiceCallRequestTargetTypeEnum.Group,
            }}
          />
        )}
      </div>
    </div>
  );
};

const CallModalStepper: React.FC<CallModalStepperProps> = ({
  opened,
  ...rest
}) => {
  return (
    <Modal opened={opened} onClose={rest.onClose} size='585px'>
      <CallModalStepperContent {...rest} />
    </Modal>
  );
};

export default CallModalStepper;
