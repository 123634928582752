import { omit } from 'lodash';
import {
  GoalDetails,
  MoneyValueCurrencyEnum,
  ProspectiveAgentInfoCountryEnum,
  RevShareTierInputsDto,
} from '../../../../openapi/plutus';
import {
  getAverageFixedCommissionEndOfDeal,
  getTeamDefaultValues,
  getTeamInfo,
  parseAgentCountsInTiers,
} from '../../../../utils/WealthPlanUtils';
import { SendWealthPlanStepByStepFormData } from '../../SendWealthPlan/V2/SendWealthPlanStepByStepFormV2';
import { GoalsFormInput } from './EditReferralGoalsSidebarV2';

const PROJECTION_YEARS = 5;

interface AgentName {
  firstname: string;
  lastname: string;
}

export const wealthPlanReferralGoalsToQueryParams = (
  goals: GoalDetails,
  accountCountry: ProspectiveAgentInfoCountryEnum,
  referringAgentName: AgentName,
  realAgentName: AgentName,
  isRevenueShareProjectionsFeatureFlagEnabled: boolean = false,
) => {
  const queryGoals = {
    ...goals,
    earningsGoalValue: 0,
    realStockFuturePrice: goals.realStockFuturePrice?.amount ?? 0,
    volumePerYear: goals.volumePerYear?.amount ?? 0,
    accountCountry,
    referringAgentFirstName: referringAgentName.firstname,
    referringAgentLastName: referringAgentName.lastname,
    realAgentFirstName: realAgentName.firstname,
    realAgentLastName: realAgentName.lastname,
    averageHomeSalePrice: goals.averageHomeSalePrice?.amount ?? 0,
    teamAverageSalesPrice: goals.teamAverageSalesPrice?.amount ?? 0,
    teamAverageFixedCommission: goals.teamAverageFixedCommission?.amount ?? 0,
    averageFixedCommission: goals.averageFixedCommission?.amount ?? 0,
    ...getRevenueShareQueryParams(
      goals,
      isRevenueShareProjectionsFeatureFlagEnabled,
    ),
  };

  const updatedQueryGoals = omit(
    queryGoals,
    omitRevenueShareQueryParams(
      goals,
      isRevenueShareProjectionsFeatureFlagEnabled,
    ),
  );

  return Object.keys(updatedQueryGoals)
    .map((key) => `${key}=${queryGoals[key as keyof GoalDetails]}`)
    .join('&');
};
export const queryParamsToWealthPlanReferralGoals = (
  params: URLSearchParams,
  isRevenueShareProjectionsFeatureFlagEnabled: boolean = false,
): GoalDetails | undefined => {
  const useAverageFixedCommission =
    params.get('useAverageFixedCommission') === 'true';
  const teamUseAverageFixedCommission =
    params.get('teamUseAverageFixedCommission') === 'true';
  const accountCountry = params.get('accountCountry');
  const isRevShareParticipation =
    params.get('revShareParticipation') === 'true';
  let agentCountsInTiers: RevShareTierInputsDto[] = [];
  if (isRevenueShareProjectionsFeatureFlagEnabled && isRevShareParticipation) {
    agentCountsInTiers = Array.from({ length: 5 }, (_, index) => {
      const [currentNetworkCount, yearlyJoiningAgentCount] = (
        params.get(`tier${index + 1}`) || ''
      )?.split(',');

      return {
        currentNetworkCount: +currentNetworkCount,
        yearlyJoiningAgentCount: +yearlyJoiningAgentCount,
      };
    });
  }

  if (!accountCountry) {
    return undefined;
  }

  const currency =
    params.get('accountCountry') === ProspectiveAgentInfoCountryEnum.Canada
      ? MoneyValueCurrencyEnum.Cad
      : MoneyValueCurrencyEnum.Usd;

  return {
    agentReferredPerYear: Number(params.get('agentReferredPerYear')),
    agentsAgentReferredPerYear: Number(
      params.get('agentsAgentReferredPerYear'),
    ),
    additionalRealShares: Number(params.get('additionalRealShares')),
    averageFixedCommission: useAverageFixedCommission
      ? {
          amount: Number(params.get('averageFixedCommission')),
          currency,
        }
      : undefined,
    averageHomeSalePrice: {
      amount: Number(params.get('averageHomeSalePrice')),
      currency,
    },
    averageTransactionCommissionPercentage: !useAverageFixedCommission
      ? Number(params.get('averageTransactionCommissionPercentage'))
      : undefined,
    earningsGoalValue: {
      amount: 0,
      currency,
    },
    eliteAgentCulturalAwardParticipation:
      params.get('eliteAgentCulturalAwardParticipation') === 'true',
    eliteAgentProductionAwardParticipation:
      params.get('eliteAgentProductionAwardParticipation') === 'true',
    planId: '',
    planStartDate: new Date().toISOString(),
    projectionYears: PROJECTION_YEARS,
    realStockFuturePrice: {
      amount: Number(params.get('realStockFuturePrice')),
      currency: MoneyValueCurrencyEnum.Usd,
    },
    realTitleJVParticipation: false,
    realTitleJVShares: 0,
    revShareParticipation: isRevShareParticipation,
    seenEliteCulturalPage: false,
    seenEliteProductionPage: false,
    sppParticipation: params.get('sppParticipation') === 'true',
    teamAverageFixedCommission: teamUseAverageFixedCommission
      ? {
          amount: Number(params.get('teamAverageFixedCommission')),
          currency,
        }
      : undefined,
    teamAverageSalesPrice: {
      amount: Number(params.get('teamAverageSalesPrice')),
      currency,
    },
    teamAverageTransactionCommissionPercentage: !teamUseAverageFixedCommission
      ? Number(params.get('teamAverageTransactionCommissionPercentage'))
      : undefined,
    teamLeaderCommissionPercentagePerTransaction: Number(
      params.get('teamLeaderCommissionPercentagePerTransaction'),
    ),
    teamLead: params.get('teamLead') === 'true',
    teamNumberCount: Number(params.get('teamNumberCount')),
    teamTransactionsPerMember: Number(params.get('teamTransactionsPerMember')),
    teamUseAverageFixedCommission,
    transactionsPerYear: Number(params.get('transactionsPerYear')),
    useAverageFixedCommission,
    volumePerYear: {
      amount: Number(params.get('volumePerYear')),
      currency,
    },
    ...(isRevenueShareProjectionsFeatureFlagEnabled && {
      agentCountsInTiers: isRevShareParticipation
        ? agentCountsInTiers
        : undefined,
      percentageOfCappingNetworkAgents: isRevShareParticipation
        ? Number(params.get('percentageOfCappingNetworkAgents'))
        : undefined,
      alwaysRefreshCurrentNetworkTierCounts: isRevShareParticipation
        ? params.get('alwaysRefreshCurrentNetworkTierCounts') === 'true'
        : undefined,
    }),
  };
};

export const wealthGoalsInputToGoalDetails = (
  formData: GoalsFormInput,
  currency: MoneyValueCurrencyEnum,
  isRevenueShareProjectionsFeatureFlagEnabled: boolean = false,
): GoalDetails => ({
  ...getAverageFixedCommissionEndOfDeal(formData, currency),
  ...getTeamInfo(formData, currency),
  additionalRealShares: formData.additionalRealShares,
  agentReferredPerYear: formData.agentReferredPerYear,
  agentsAgentReferredPerYear: formData.agentsAgentReferredPerYear,
  averageHomeSalePrice: {
    amount: formData.averageHomeSalePrice,
    currency,
  },
  earningsGoalValue: {
    amount: formData.earningsGoalValue,
    currency: currency,
  },
  eliteAgentCulturalAwardParticipation:
    formData.eliteAgentCulturalAwardParticipation,
  eliteAgentProductionAwardParticipation:
    formData.eliteAgentProductionAwardParticipation,
  planId: '',
  planStartDate: new Date().toISOString(),
  projectionYears: PROJECTION_YEARS,
  realStockFuturePrice: {
    amount: formData.realStockFuturePrice,
    currency: MoneyValueCurrencyEnum.Usd,
  },
  realTitleJVParticipation: false,
  realTitleJVShares: 0,
  revShareParticipation: !isRevenueShareProjectionsFeatureFlagEnabled
    ? formData.agentReferredPerYear > 0 ||
      formData.agentsAgentReferredPerYear > 0
    : formData?.revShareParticipation,
  seenEliteCulturalPage: false,
  seenEliteProductionPage: false,
  sppParticipation: formData.sppParticipation,
  transactionsPerYear: formData.transactionsPerYear,
  volumePerYear: {
    amount: formData.volumePerYear,
    currency,
  },
  ...(isRevenueShareProjectionsFeatureFlagEnabled && {
    agentCountsInTiers: !!formData?.revShareParticipation
      ? parseAgentCountsInTiers(formData?.agentCountsInTiers)
      : undefined,
    percentageOfCappingNetworkAgents: !!formData?.revShareParticipation
      ? +formData?.percentageOfCappingNetworkAgents!
      : undefined,
    alwaysRefreshCurrentNetworkTierCounts: !!formData?.revShareParticipation
      ? formData?.alwaysRefreshCurrentNetworkTierCounts
      : undefined,
  }),
});

export const formDataToGoalDetails = (
  data: SendWealthPlanStepByStepFormData,
  forPreview: boolean = false,
  isRevenueShareProjectionsFeatureFlagEnabled: boolean = false,
): GoalDetails => {
  const isUsAgent =
    data?.country.value === ProspectiveAgentInfoCountryEnum.UnitedStates;
  const currency = isUsAgent
    ? MoneyValueCurrencyEnum.Usd
    : MoneyValueCurrencyEnum.Cad;

  return {
    ...getAverageFixedCommissionEndOfDeal(data, currency),
    ...getTeamInfo(data, currency, forPreview),
    additionalRealShares: 0,
    agentReferredPerYear: data?.agentReferredPerYear,
    agentsAgentReferredPerYear: data?.agentsAgentReferredPerYear,
    averageHomeSalePrice: {
      amount: data.averageHomeSalePrice,
      currency,
    },
    earningsGoalValue: {
      amount: data?.earningsGoalValue,
      currency,
    },
    eliteAgentCulturalAwardParticipation: true,
    eliteAgentProductionAwardParticipation: true,
    planId: '',
    planStartDate: new Date().toISOString(),
    projectionYears: 5,
    realStockFuturePrice: {
      amount: data?.realStockFuturePrice,
      currency: MoneyValueCurrencyEnum.Usd,
    },
    realTitleJVParticipation: false,
    realTitleJVShares: 0,
    revShareParticipation: !isRevenueShareProjectionsFeatureFlagEnabled
      ? data?.agentReferredPerYear > 0 || data?.agentsAgentReferredPerYear > 0
      : data?.revShareParticipation,
    seenEliteCulturalPage: false,
    seenEliteProductionPage: false,
    sppParticipation: data?.sppParticipation,
    transactionsPerYear: data?.transactionsPerYear,
    volumePerYear: {
      amount: data?.volumePerYear,
      currency,
    },
    ...(isRevenueShareProjectionsFeatureFlagEnabled && {
      agentCountsInTiers: !!data?.revShareParticipation
        ? parseAgentCountsInTiers(data?.agentCountsInTiers)
        : undefined,
      percentageOfCappingNetworkAgents: !!data?.revShareParticipation
        ? +data?.percentageOfCappingNetworkAgents!
        : undefined,
      alwaysRefreshCurrentNetworkTierCounts: !!data?.revShareParticipation
        ? data?.alwaysRefreshCurrentNetworkTierCounts
        : undefined,
    }),
  };
};

export const getDefaultFormData = (
  defaultGoals: GoalDetails | undefined,
  isRevenueShareProjectionsFeatureFlagEnabled: boolean = false,
): SendWealthPlanStepByStepFormData => ({
  additionalRealShares: defaultGoals?.additionalRealShares || 10,
  agentReferredPerYear: defaultGoals?.agentReferredPerYear || 4,
  agentsAgentReferredPerYear: defaultGoals?.agentsAgentReferredPerYear || 2,
  averageFixedCommission: defaultGoals?.averageFixedCommission?.amount || 0,
  averageHomeSalePrice: defaultGoals?.averageHomeSalePrice?.amount || 300000,
  averageTransactionCommissionPercentage:
    defaultGoals?.averageTransactionCommissionPercentage ?? 0.1,
  brokerageName: '',
  country: {
    label: 'USA',
    value: ProspectiveAgentInfoCountryEnum.UnitedStates,
  },
  email: '',
  emailMessage: '',
  emailSubject: '',
  earningsGoalValue: 0,
  eliteAgentCulturalAwardParticipation:
    defaultGoals?.eliteAgentProductionAwardParticipation ?? false,
  eliteAgentProductionAwardParticipation:
    defaultGoals?.eliteAgentProductionAwardParticipation ?? false,
  firstName: '',
  lastName: '',
  ownsAdditionalRealStockShares: !!defaultGoals?.additionalRealShares,
  phoneNumber: '',
  realStockFuturePrice: defaultGoals?.realStockFuturePrice.amount || 10,
  revShareParticipation: defaultGoals?.revShareParticipation ?? false,
  volumePerYear: defaultGoals?.volumePerYear?.amount ?? 1000000,
  sppParticipation: defaultGoals?.sppParticipation ?? true,
  transactionsPerYear: defaultGoals?.transactionsPerYear || 12,
  useAverageFixedCommission: defaultGoals?.useAverageFixedCommission || false,
  ...getTeamDefaultValues(defaultGoals),
  ...(isRevenueShareProjectionsFeatureFlagEnabled && {
    agentCountsInTiers: parseAgentCountsInTiers(
      defaultGoals?.agentCountsInTiers || [],
    ),
    percentageOfCappingNetworkAgents:
      +defaultGoals?.percentageOfCappingNetworkAgents! || 100,
    alwaysRefreshCurrentNetworkTierCounts:
      defaultGoals?.alwaysRefreshCurrentNetworkTierCounts,
  }),
});

export const getRevenueShareQueryParams = (
  goals: GoalDetails,
  isRevenueShareProjectionsFeatureFlagEnabled: boolean,
) => {
  if (
    isRevenueShareProjectionsFeatureFlagEnabled &&
    !!goals?.revShareParticipation
  ) {
    return {
      agentCountsInTiers: undefined,
      tier1: getAgentCountsInTier(goals?.agentCountsInTiers?.[0]),
      tier2: getAgentCountsInTier(goals?.agentCountsInTiers?.[1]),
      tier3: getAgentCountsInTier(goals?.agentCountsInTiers?.[2]),
      tier4: getAgentCountsInTier(goals?.agentCountsInTiers?.[3]),
      tier5: getAgentCountsInTier(goals?.agentCountsInTiers?.[4]),
      percentageOfCappingNetworkAgents:
        +goals?.percentageOfCappingNetworkAgents! ?? 0,
      alwaysRefreshCurrentNetworkTierCounts:
        goals?.alwaysRefreshCurrentNetworkTierCounts ?? false,
    };
  }

  return {
    agentCountsInTiers: undefined,
    percentageOfCappingNetworkAgents: undefined,
    alwaysRefreshCurrentNetworkTierCounts: undefined,
  };
};

export const getAgentCountsInTier = (
  agentCountsInTier: RevShareTierInputsDto | undefined,
) => {
  if (!agentCountsInTier) {
    return undefined;
  }

  return `${agentCountsInTier?.currentNetworkCount},${agentCountsInTier?.yearlyJoiningAgentCount}`;
};

export const omitRevenueShareQueryParams = (
  goals: GoalDetails,
  isRevenueShareProjectionsFeatureFlagEnabled: boolean,
) => {
  if (
    isRevenueShareProjectionsFeatureFlagEnabled &&
    !!goals?.revShareParticipation
  ) {
    return ['agentCountsInTiers'];
  }

  return [
    'agentCountsInTiers',
    'percentageOfCappingNetworkAgents',
    'alwaysRefreshCurrentNetworkTierCounts',
  ];
};
