import {
  faEnvelopeDot,
  faEnvelopeOpen,
  faPenToSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDisclosure } from '@mantine/hooks';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { RezenObjectTypeEnum } from '../../../openapi/yada';
import {
  useFetchInboxMessages,
  useMarkMessageAsRead,
  useMarkMessageAsUnread,
} from '../../../query/inbox/useInbox';
import { ConversationMatch } from '../../../routes/ConversationDetailsRoute';
import { RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { getConversationLink } from '../../../utils/ConversationUtils';
import BrokerOnly from '../../auth/BrokerOnly';
import ExcludeBroker from '../../auth/ExcludeBroker';
import { Button } from '../../commons/Button';
import { Checkbox } from '../../commons/Checkbox';
import DefaultLoader from '../../DefaultLoader';
import Hover from '../../Hover';
import ResourceContainer from '../../ResourceContainer';
import { BrokerQueueFilterEnum } from './BrokerQueueFilterV2';
import BrokerQueueTransactionOptions from './BrokerQueueTransactionOptions';
import CreateConversationSidebar from './CreateConversationSidebar';

enum SELECTION_TYPE_ENUM {
  ALL = 'ALL',
  NONE = 'NONE',
  READ = 'READ',
  UNREAD = 'UNREAD',
  MIXED = 'MIXED',
}

const getEnvelopeTooltipText = (type: SELECTION_TYPE_ENUM): string => {
  switch (type) {
    case SELECTION_TYPE_ENUM.NONE:
    case SELECTION_TYPE_ENUM.ALL:
      return 'Mark all as Read';
    case SELECTION_TYPE_ENUM.READ:
      return 'Mark as Unread';
    case SELECTION_TYPE_ENUM.UNREAD:
    case SELECTION_TYPE_ENUM.MIXED:
    default:
      return 'Mark as Read';
  }
};

type ConversationTabProps = {
  search?: string;
  filter?: BrokerQueueFilterEnum;
};

const ConversationTab: React.FC<ConversationTabProps> = ({
  search,
  filter,
}) => {
  const { id } = useParams<ConversationMatch>();

  const keymakerUserId = useSelector(
    (state: RootState) => state.auth.keymakerCurrentUser?.id,
  )!;

  // const userDetail = useSelector((state: RootState) => state.auth.userDetail);
  // const agentById = useSelector((state: RootState) => state.userIds.agentById);

  const [selectedConversation, setSelectedConversation] = useState<string>(id);
  const [opened, modalActions] = useDisclosure();
  const [checkedConversationMap, setCheckedConversationMap] = useState<
    Record<string, boolean>
  >({});

  // TODO: move this to a hook
  // const {
  //   data: results,
  //   isLoading,
  //   hasNextPage,
  //   isFetchingNextPage,
  //   fetchNextPage,
  // } = useInfiniteQuery({
  //   queryKey: queryKeys.roar.conversation._def,
  //   queryFn: async ({ pageParam }) => {
  //     const { data } = await new ConversationsApi(
  //       getYadaConfiguration(),
  //     ).getConversations(pageParam ?? 0, 20);
  //     return data;
  //   },
  //   getNextPageParam: (lastPage) => {
  //     if (lastPage.hasNext) {
  //       return (lastPage.pageNumber ?? 0) + 1;
  //     }
  //     return;
  //   },
  //   staleTime: DEFAULT_STALE_TIME,
  //   cacheTime: DEFAULT_CACHE_TIME,
  // });

  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useFetchInboxMessages(
    keymakerUserId,
    RezenObjectTypeEnum.Conversation,
    {
      filter: filter,
      searchText: search,
      targetType: ['CONVERSATION'],
    },
    { refetchOnWindowFocus: 'always', staleTime: 0 },
  );

  // const agentIds = useMemo(
  //   () =>
  //     data?.results?.map(
  //       (a) =>
  //         a.members.find((m) => m.memberId.type === MemberIdTypeEnum.User)
  //           ?.memberId.value!,
  //     ) ?? [],
  //   [data?.results],
  // );

  // useAgentsInfo(agentIds);

  // const officeIds = useMemo(() => {
  //   return userDetail?.offices?.map((office) => office.id!) ?? [];
  // }, [userDetail?.offices]);

  // const { data: officeGroupsById } = useFetchMultipleOfficeVisibleGroupsByIds(
  //   officeIds,
  // );

  // const brokerTeamGroups = useMemo(
  //   () =>
  //     values(officeGroupsById).map((group) => ({
  //       ...group.groups?.find(isBrokerTeam),
  //       officeId: group.officeId,
  //     })),
  //   [officeGroupsById],
  // );

  const loadMore = () => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  };

  const allSelectedConversations = useMemo(
    () => Object.values(checkedConversationMap),
    [checkedConversationMap],
  );

  const selectionType = useMemo(() => {
    if (!allSelectedConversations.length) {
      return SELECTION_TYPE_ENUM.NONE;
    }
    if (
      allSelectedConversations.length === data.length &&
      allSelectedConversations.every((value) => value)
    ) {
      return SELECTION_TYPE_ENUM.ALL;
    }
    if (allSelectedConversations.every((value) => value)) {
      return SELECTION_TYPE_ENUM.READ;
    }
    if (allSelectedConversations.every((value) => !value)) {
      return SELECTION_TYPE_ENUM.UNREAD;
    }
    return SELECTION_TYPE_ENUM.MIXED;
  }, [allSelectedConversations, data.length]);

  const changeSelectedConversations = (checked: boolean) => {
    if (!checked) {
      return setCheckedConversationMap({});
    }
    const values = data?.reduce((acc, curr) => {
      if (curr?.target?.id) {
        acc[curr?.target?.id] = checked;
      }
      return acc;
    }, {} as Record<string, boolean>);
    setCheckedConversationMap(values);
  };

  const {
    mutate: markMessageAsRead,
    isLoading: isLoadingMarkingRead,
  } = useMarkMessageAsRead(keymakerUserId, RezenObjectTypeEnum.Conversation);

  const {
    mutate: markMessageAsUnread,
    isLoading: isLoadingMarkingUnread,
  } = useMarkMessageAsUnread(keymakerUserId, RezenObjectTypeEnum.Conversation);

  const disableActions = isLoadingMarkingRead || isLoadingMarkingUnread;

  const handleMarkingSelectedMessage = (read: boolean) => {
    const ids = Object.keys(checkedConversationMap);

    if (read) {
      markMessageAsRead(
        {
          userId: keymakerUserId,
          targetType: RezenObjectTypeEnum.Conversation,
          targetIds: ids,
        },
        {
          onSuccess: () => {
            setCheckedConversationMap({});
          },
        },
      );
    } else {
      markMessageAsUnread(
        {
          userId: keymakerUserId,
          targetType: RezenObjectTypeEnum.Conversation,
          targetIds: ids,
        },
        {
          onSuccess: () => {
            setCheckedConversationMap({});
          },
        },
      );
    }
  };

  const handleMarkingAllMesssage = (read: boolean) => {
    if (read) {
      markMessageAsRead(
        { userId: keymakerUserId },
        {
          onSuccess: () => {
            setCheckedConversationMap({});
          },
        },
      );
    } else {
      markMessageAsUnread(
        { userId: keymakerUserId },
        {
          onSuccess: () => {
            setCheckedConversationMap({});
          },
        },
      );
    }
  };

  const handleEnvelopeClick = (read: boolean) => {
    if (
      selectionType === SELECTION_TYPE_ENUM.ALL ||
      selectionType === SELECTION_TYPE_ENUM.NONE
    ) {
      handleMarkingAllMesssage(read);
    } else {
      handleMarkingSelectedMessage(read);
    }
  };

  const handleClick = (conversationId: string) => {
    setSelectedConversation(conversationId);
    setCheckedConversationMap({});
  };

  return (
    <>
      <div
        id='scrollableDiv'
        className='h-[calc(100vh-187px)] md:h-[calc(100vh-236px)] scrollbar overflow-y-auto pb-2'
      >
        <div className='flex items-center justify-between space-x-2 px-4 border-b border-grey-300 pb-1 mb-2'>
          <Checkbox
            size='sm'
            checked={selectionType === SELECTION_TYPE_ENUM.ALL}
            onChange={(event) =>
              changeSelectedConversations(event.target.checked)
            }
          />
          <div className='flex items-center space-x-3'>
            <Hover
              hoverComponent={
                <p className='p-1 text-sm text-grey-500 font-zen-body'>
                  {getEnvelopeTooltipText(selectionType)}
                </p>
              }
              config={{ placement: 'top' }}
            >
              <button
                onClick={() =>
                  handleEnvelopeClick(
                    selectionType !== SELECTION_TYPE_ENUM.READ,
                  )
                }
                aria-label='smart-envelope'
                className='text-primary-dark disabled:opacity-20'
                disabled={disableActions}
              >
                <FontAwesomeIcon
                  icon={
                    selectionType === SELECTION_TYPE_ENUM.READ
                      ? faEnvelopeDot
                      : faEnvelopeOpen
                  }
                />
              </button>
            </Hover>
            <BrokerQueueTransactionOptions
              onMark={handleEnvelopeClick}
              allOrNoneSelected={
                allSelectedConversations.length === data.length
              }
              disabled={disableActions}
            />
          </div>
        </div>
        <ResourceContainer
          loading={isLoading}
          isEmpty={isEmpty(data)}
          resourceName='conversation'
        >
          <InfiniteScroll
            hasMore={hasNextPage ?? false}
            loader={<DefaultLoader />}
            dataLength={data?.length ?? 0}
            scrollableTarget='scrollableDiv'
            next={loadMore}
            className='flex flex-col space-y-1.5 px-1'
          >
            {data?.map((message) => {
              const conversationId = message?.target?.id!;
              const isChecked = checkedConversationMap[conversationId];
              // const agentId = message.members.find(
              //   (m) =>
              //     m.memberId.value !== userDetail?.id &&
              //     m.memberId.type === MemberIdTypeEnum.User,
              // )?.memberId.value!;
              // const groupId = message.members.find(
              //   (member) => member.memberId.type === MemberIdTypeEnum.Group,
              // )?.memberId.value!;
              // const agentInfo = agentById[agentId];
              // const officeId = brokerTeamGroups.find(
              //   (group) => group.id === groupId,
              // )?.officeId;
              // const office = userDetail?.offices?.find(
              //   (office) => office.id === officeId,
              // );

              return (
                <Link
                  to={getConversationLink(conversationId)}
                  onClick={() => handleClick(conversationId)}
                  key={conversationId}
                  className={cn(
                    'border-b border-grey-300 pt-3.5 pb-4 px-2.5',
                    !message?.read && 'border-b-0 bg-regent-200 rounded-lg',
                    selectedConversation === conversationId &&
                      'border border-primary-dark bg-rezen-light-blue-100 rounded-lg',
                  )}
                >
                  <div className='flex items-start justify-between'>
                    <div className='flex items-start'>
                      <div className='mt-0.5 mr-0.5'>
                        <Checkbox
                          size='sm'
                          checked={isChecked}
                          onClick={(event) => event.stopPropagation()}
                          onChange={(event) =>
                            setCheckedConversationMap((prev) => ({
                              ...prev,
                              [conversationId]: event.target.checked,
                            }))
                          }
                        />
                      </div>
                      <div className='space-y-1'>
                        <p className='font-inter text-base text-primary-dark line-clamp-1'>
                          <ExcludeBroker>
                            {message?.target?.metadata?.name ?? 'N/A'}
                            {/* Broker Team -{' '}
                    {STATE_OR_PROVINCE_ABBREVIATIONS[
                      office?.administrativeArea?.stateOrProvince!
                    ] ?? 'N/A'} */}
                          </ExcludeBroker>
                          <BrokerOnly>
                            {message?.target?.metadata?.name ?? 'N/A'}
                            {/* {getFullName(agentInfo) ?? 'Unknown'} -{' '} */}
                          </BrokerOnly>
                        </p>
                        <p className='font-inter text-grey-600 text-sm'>
                          {DateTime.fromMillis(
                            message?.createdAt!,
                          ).toFormat('t', { locale: 'en-US' })}{' '}
                          {DateTime.fromMillis(
                            message?.createdAt!,
                          ).toFormat('ZZZZ', { locale: 'en-US' })}
                        </p>
                      </div>
                    </div>
                    {!!message?.unreadComments && (
                      <span className='bg-coral-red px-1.5 rounded-full font-inter text-white text-[13px] font-medium leading-5'>
                        {message?.unreadComments}
                      </span>
                    )}
                  </div>
                </Link>
              );
            })}
          </InfiniteScroll>
        </ResourceContainer>
      </div>
      <div className='px-2.5 py-3 bg-white'>
        <Button
          leftIcon={
            <FontAwesomeIcon
              icon={faPenToSquare}
              fontSize={14}
              className='mr-1'
            />
          }
          fullWidth
          onClick={modalActions.open}
        >
          New Message
        </Button>
      </div>
      <CreateConversationSidebar
        opened={opened}
        onClose={modalActions.close}
        onCreate={setSelectedConversation}
      />
    </>
  );
};

export default ConversationTab;
