import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import config from '../config';
import { useGemini } from '../hooks/useGemini';
import { UserControllerApi } from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { RootState } from '../types';
import { cn } from '../utils/classUtils';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';

interface ZenLaunchNotesProps {
  isCollapsed: boolean;
}

const ZenLaunchNotes: React.FC<ZenLaunchNotesProps> = ({ isCollapsed }) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [categories, setCategories] = useState<string>();
  const isGeminiEnabled = useGemini();

  const fetchCategories = useCallback(async () => {
    try {
      const { data } = await new UserControllerApi(
        getYentaConfiguration(),
      ).getAnnounceKitTypesUserById(userDetail?.id!);

      const categorySlugs = data
        ?.map((item) => `"${item.toLowerCase()}"`)
        .join(',');

      setCategories(categorySlugs);
    } catch (e) {
      ErrorService.notifyIgnoreAuthErrors(
        'Something went wrong while fetching categories for launch notes',
        e,
        { data: { id: userDetail?.id! } },
      );
    }
  }, [userDetail?.id]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const loadScript = useCallback(() => {
    const scriptModule = document.createElement('script');
    scriptModule.async = false;
    scriptModule.type = 'module';
    scriptModule.src =
      'https://embed.launchnotes.io/latest/dist/esm/launchnotes-embed.js';
    const scriptNoModule = document.createElement('script');
    scriptNoModule.async = false;
    scriptNoModule.noModule = true;
    scriptNoModule.src =
      'https://embed.launchnotes.io/latest/dist/esm/launchnotes-embed.js';
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(scriptNoModule);
    head.appendChild(scriptModule);
  }, []);

  const html = `<launchnotes-embed
  token="${config.launchNotes.projectToken}"
  project="${config.launchNotes.projectId}"
  toggle-selector="#whats-new-link"
  categories='[${categories}]'
  heading="🚀 Product updates"
  subheading="The latest from the REAL team"
  heading-color="#FFF"
  subheading-color="#FFF"
  primary-color="linear-gradient(135deg, rgb(58, 126, 228) 0%, rgb(22, 78, 162) 100%)"
  widget-placement="right"
  widget-offset-skidding="0"
  widget-offset-distance="25"
  show-unread="true"
  unread-placement="right-start"
  unread-offset-skidding="3"
  unread-offset-distance="-200"
  unread-background-color="#FF0000"
  unread-text-color="#FFF"
  anonymous-user-id="${userDetail?.id!}"
/>`;

  useEffect(() => {
    loadScript();
  }, [loadScript]);

  return (
    <div>
      <div
        className={cn(
          'flex items-center cursor-pointer text-white',
          isGeminiEnabled
            ? 'font-zen-body-2 hover:text-aqua'
            : 'font-zen-body font-bold hover:text-zen-primary-6',
        )}
        id='whats-new-link'
      >
        <FontAwesomeIcon
          icon={faSparkles}
          className={cn('text-base', {
            'mr-2': !isCollapsed,
          })}
        />
        {!isCollapsed && <p className='text-xs mt-0.5'>What&apos;s New?</p>}
      </div>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  );
};

export default ZenLaunchNotes;
