import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../types';
import { MentionSource } from '../components/transactions/Comments/ZenCommentSection';
import { filterMentionParticipants } from '../utils/TransactionUtils';
import { fetchOfficeDetailById } from '../slices/OfficeSlice';
import { fetchTransactionCommentParticipants } from '../slices/TransactionSlice';

const useTransactionMentions = () => {
  const dispatch = useDispatch();
  const {
    userDetailId,
    officeId,
    transactionDetailId,
    commentsMentionUsers,
    officeDetailById,
    callerGroupId,
  } = useSelector((state: RootState) => ({
    userDetailId: state.auth.userDetail?.id,
    officeId: state.transaction.transactionDetailResponse?.data?.office?.id,
    transactionDetailId: state.transaction.transactionDetailResponse?.data?.id,
    commentsMentionUsers: state.transaction.commentsMentionUsers,
    officeDetailById: state.office.officeDetailById,
    callerGroupId: state.transaction.commentsMentionUsers?.participantGroupId,
  }));

  const getMentions = useCallback(
    (searchTerm: string): MentionSource[] => {
      return filterMentionParticipants(searchTerm, commentsMentionUsers);
    },
    [commentsMentionUsers],
  );

  useEffect(() => {
    if (officeId && !officeDetailById[officeId]) {
      dispatch(fetchOfficeDetailById(officeId));
    }
  }, [dispatch, officeDetailById, officeId]);

  useEffect(() => {
    if (transactionDetailId && userDetailId) {
      dispatch(
        fetchTransactionCommentParticipants(
          transactionDetailId!,
          userDetailId!,
        ),
      );
    }
  }, [dispatch, transactionDetailId, userDetailId]);

  return { getMentions, callerGroupId };
};

export default useTransactionMentions;
