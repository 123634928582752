import { useBaseMutation } from '../base/useBaseMutation';
import {
  KafkaController,
  KafkaFailedMessage,
  KafkaUpdateMessageStatusEnum,
} from '../../components/KafkaDashboard/KafkaControllerTypes';
import { queryKeys } from '../base/queryKeys';

type UseBulkStatusUpdateProps = {
  controller: KafkaController;
  status?: KafkaUpdateMessageStatusEnum;
};

export const useBulkStatusUpdate = ({
  controller,
  status,
}: UseBulkStatusUpdateProps) => {
  return useBaseMutation({
    queryKey: queryKeys.kafkaDashboard.updateFailedMessageStatus.queryKey,
    mutationFn: async (messageIds: string[]) => {
      if (!status) return;
      await controller.batchUpdateFailedMessageStatus({
        ids: messageIds,
        status,
      });
    },
    successMessage: 'Successfully updated messages',
    errorMessage: 'Failed to update kafka message',
  });
};

type UseFailedMessagesProps = {
  controller: KafkaController;
};

export const useFailedMessages = ({ controller }: UseFailedMessagesProps) => {
  return useBaseMutation({
    queryKey: queryKeys.kafkaDashboard.fetchFailedMessages.queryKey,
    mutationFn: async (failedMessage: KafkaFailedMessage) => {
      await controller.retryFailedMessage({
        id: failedMessage.id!,
      });
    },
    successMessage: 'Message has been retried successfully',
    errorMessage: 'Failed to retry message',
  });
};
