import {
  faAtomSimple,
  faEye,
  faEyeSlash,
  faMessageExclamation,
  faPen,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, values } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as TaxIcon } from '../../../assets/icons/tax_form.svg';
import { ReactComponent as IncognitoIcon } from '../../../assets/img/incognito.svg';
import { ReactComponent as OfficeIcon } from '../../../assets/img/office.svg';
import { previousYear } from '../../../constants/DateTimeConstants';
import { DemoModeContainer } from '../../../containers/DemoModeContainer';
import ZenAddAgentCreditFormSidebarModal from '../../../forms/ZenAddAgentCreditFormSidebarModal';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useGemini } from '../../../hooks/useGemini';
import {
  TaxDocumentResponse,
  TaxDocumentResponseTaxDocumentStatusEnum,
  TaxDocumentResponseTaxDocumentTypeEnum,
} from '../../../openapi/arrakis';
import {
  AgentNationalIdsResponse,
  AgentResponse,
  AgentResponseAccountCountryEnum,
  NationalIdentificationValueTypeEnum,
} from '../../../openapi/yenta';
import {
  getTaxDocuments,
  getYearlyTaxDocumentDetailsV2,
  regenerateTaxDocument,
  saveTaxDocumentDetails,
} from '../../../slices/TaxSlice';
import {
  AsyncResponse,
  FeatureFlagTypeEnum,
  KBarActionEnum,
  RootState,
} from '../../../types';
import { getTaxType } from '../../../utils/AgentHelper';
import { cn } from '../../../utils/classUtils';
import { getLastYear } from '../../../utils/DateUtils';
import {
  isVisibleTaxIds,
  showEncryptedTextForNationIDs,
} from '../../../utils/DisplayHelpers';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { isTaxDocumentNew } from '../../../utils/TaxDocumentHelper';
import AuthorizationContainer from '../../auth/AuthorizationContainer';
import AuthorizedOnly from '../../auth/AuthorizedOnly';
import GeminiFeatureFlagButton from '../../GeminiFeatureFlagButton';
import ResourceContainer from '../../ResourceContainer';
import ZenControlledHTMLSelectInput from '../../Zen/Input/ZenControlledHTMLSelectInput';
import ZenSimpleConfirmationModal from '../../Zen/Modal/ZenSimpleConfirmationModal';
import ZenAction from '../../Zen/ZenAction';
import ZenCard from '../../Zen/ZenCard';
import ZenOutlineActionButton from '../../Zen/ZenOutlineButton';
import ZenBusinessEntityForm from '../ZenBusinessEntityForm';
import ZenNationalIDForm from '../ZenNationalIDForm';
import ZenTaxForm1099 from '../ZenTaxForm1099';
import ZenTaxReportIssue from '../ZenTaxReportIssue';

interface TaxInformationCardProps {
  detailResponse: AsyncResponse<AgentResponse>;
  nationalIdentificationsResponse: AsyncResponse<AgentNationalIdsResponse>;
}

export interface showType {
  type: NationalIdentificationValueTypeEnum;
  show: boolean;
}

const ZenTaxInformation: React.FC<TaxInformationCardProps> = ({
  detailResponse,
  nationalIdentificationsResponse,
}) => {
  const isGeminiEnabled = useGemini();
  const dispatch = useDispatch();
  const {
    auth: { isAdmin },
    taxDoc: {
      taxDocumentsDetailResponseV2,
      taxDocumentsResponse,
      taxDocumentsDetailResponse,
    },
  } = useSelector((state: RootState) => state);
  const [
    openIdentificationSidebar,
    setOpenIdentificationSidebar,
  ] = useState<boolean>(false);
  const [generateTaxDoc, setGenerateTaxDoc] = useState<boolean>(false);
  const { control, watch } = useForm();
  const years =
    taxDocumentsResponse.data
      ?.taxDocuments!.map((item) => item.year!)
      .filter((year) => year !== getLastYear())
      .filter((year, index, self) => {
        return self.indexOf(year) === index;
      })
      .sort()
      .reverse() || [];
  const selectedYear = watch('year');
  const [
    openBusinessEntityInfoSidebar,
    setOpenBusinessEntityInfoSidebar,
  ] = useState<boolean>(false);
  const [reviewTaxDocument, setReviewTaxDocument] = useState<
    TaxDocumentResponse | undefined
  >();
  const [reportTaxDocument, setReportTaxDocument] = useState<
    TaxDocumentResponse | undefined
  >();
  const [showTaxIds, setShowTaxIds] = useState<showType[]>(
    values(NationalIdentificationValueTypeEnum).map((type) => ({
      type: type,
      show: false,
    })),
  );
  const [openAddAgentForm, setAddAgentForm] = useState<boolean>(false);
  const [showBusinessEnityIds, setShowBusinessEnityIds] = useState<showType[]>(
    values(NationalIdentificationValueTypeEnum).map((type) => ({
      type: type,
      show: false,
    })),
  );
  const isLoading =
    taxDocumentsResponse.loading || taxDocumentsDetailResponseV2.loading;
  const isMissingTaxDocuments =
    taxDocumentsResponse.data?.taxDocuments?.length === 0 ||
    !taxDocumentsDetailResponseV2.data?.length;
  const detail = detailResponse.data;
  const nationalIdentifications = nationalIdentificationsResponse.data;
  const isCanadaTaxAcknowledgementEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.TAX_ACKNOWLEDGEMENT,
  );
  const isCanadaAgent =
    detailResponse?.data?.accountCountry ===
    AgentResponseAccountCountryEnum.Canada;
  const hideTaxInformation = isCanadaTaxAcknowledgementEnabled && isCanadaAgent;

  const viewOrReviewTaxDocument = (taxDocument: TaxDocumentResponse) => {
    if (
      taxDocument?.taxDocumentStatus ===
      TaxDocumentResponseTaxDocumentStatusEnum.Active
    ) {
      setReviewTaxDocument(taxDocument);
    } else {
      window.open(`/pdf/tax-documents/${taxDocument?.id!}`, '_blank');
    }
  };

  useEffect(() => {
    dispatch(saveTaxDocumentDetails({ loading: false, name: 'tax document' }));
  }, [dispatch]);

  useEffect(() => {
    if (selectedYear) {
      dispatch(getYearlyTaxDocumentDetailsV2(detail!.id!, selectedYear));
    }
  }, [detail, dispatch, selectedYear]);

  return (
    <>
      {!hideTaxInformation && (
        <ZenCard
          title='Personal Tax IDs'
          RightComponent={
            <AuthorizedOnly asyncResponse={nationalIdentificationsResponse}>
              <DemoModeContainer hide>
                <ZenAction
                  icon={
                    !!nationalIdentifications?.identifications ? (
                      <FontAwesomeIcon
                        icon={faPen}
                        className={cn(
                          isGeminiEnabled
                            ? 'font-light text-[13px] leading-[23px] text-center text-rezen-blue-600'
                            : 'text-primary-blue text-sm',
                        )}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={cn(
                          isGeminiEnabled
                            ? 'font-light text-[13px] leading-[23px] text-center text-rezen-blue-600'
                            : 'text-primary-blue text-sm',
                        )}
                      />
                    )
                  }
                  onClick={() => setOpenIdentificationSidebar(true)}
                  text={
                    !!nationalIdentifications?.identifications ? 'Edit' : 'Add'
                  }
                />
              </DemoModeContainer>
            </AuthorizedOnly>
          }
        >
          <AuthorizationContainer
            asyncResponse={nationalIdentificationsResponse}
          >
            <ResourceContainer
              loading={nationalIdentificationsResponse.loading}
              isEmpty={
                !nationalIdentifications?.identifications ||
                nationalIdentifications.identifications.length === 0
              }
              resourceName='personalTaxIds'
              EmptyComponent={
                <div className='flex flex-col justify-center items-center'>
                  <IncognitoIcon />
                  <p className='font-primary-regular text-xs text-gray-400 py-2'>
                    You have not provided any personal tax IDs.
                  </p>
                </div>
              }
            >
              <div className='flex flex-col space-y-3 px-4 py-6'>
                {nationalIdentifications?.identifications.map((id) => (
                  <div key={id.type} className='flex flex-col'>
                    <p className='font-zen-body font-semibold text-sm text-zen-dark-6'>
                      {capitalizeEnum(id.type!).toUpperCase()}
                    </p>
                    <p className='flex flex-row font-semibold font-zen-body items-center space-x-2'>
                      <span className='font-primary-regular text-sm text-zen-dark-9'>
                        {isVisibleTaxIds(
                          showTaxIds,
                          id.type as NationalIdentificationValueTypeEnum,
                        )
                          ? id.id
                          : showEncryptedTextForNationIDs(id)}
                      </span>
                      <DemoModeContainer hide>
                        {!isVisibleTaxIds(
                          showTaxIds,
                          id.type as NationalIdentificationValueTypeEnum,
                        ) ? (
                          <span
                            className='font-semibold font-zen-body text-sm text-primary-blue cursor-pointer flex flex-row items-center'
                            onClick={() => {
                              const ids = [...showTaxIds];
                              const updatedIds = ids.map((i: showType) =>
                                i.type === id.type
                                  ? { ...i, show: true }
                                  : { ...i },
                              );
                              setShowTaxIds(updatedIds);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faEyeSlash}
                              size='sm'
                              className='mr-1'
                            />
                            Show
                          </span>
                        ) : (
                          <span
                            className='font-semibold font-zen-body text-sm text-primary-blue cursor-pointer flex flex-row items-center'
                            onClick={() => {
                              const ids = [...showTaxIds];
                              const updatedIds = ids.map((i: showType) =>
                                i.type === id.type
                                  ? { ...i, show: false }
                                  : { ...i },
                              );
                              setShowTaxIds(updatedIds);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              size='sm'
                              className='mr-1'
                            />{' '}
                            Hide
                          </span>
                        )}
                      </DemoModeContainer>
                    </p>
                  </div>
                ))}
              </div>
            </ResourceContainer>
          </AuthorizationContainer>
        </ZenCard>
      )}

      {!hideTaxInformation && (
        <ZenCard
          title='Business Entity Tax IDs'
          RightComponent={
            <AuthorizedOnly asyncResponse={nationalIdentificationsResponse}>
              <DemoModeContainer hide>
                <ZenAction
                  onClick={() => setOpenBusinessEntityInfoSidebar(true)}
                  text={!!detail?.paidViaBusinessEntity ? 'Edit' : 'Add'}
                  icon={
                    !!detail?.paidViaBusinessEntity ? (
                      <FontAwesomeIcon
                        icon={faPen}
                        className={cn(
                          isGeminiEnabled
                            ? 'font-light text-[13px] leading-[23px] text-center text-rezen-blue-600'
                            : 'text-primary-blue text-sm',
                        )}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={cn(
                          isGeminiEnabled
                            ? 'font-light text-[13px] leading-[23px] text-center text-rezen-blue-600'
                            : 'text-primary-blue text-sm',
                        )}
                      />
                    )
                  }
                />
              </DemoModeContainer>
            </AuthorizedOnly>
          }
        >
          <AuthorizationContainer asyncResponse={detailResponse}>
            <ResourceContainer
              loading={detailResponse.loading}
              isEmpty={
                !detail?.paidViaBusinessEntity ||
                isEmpty(detail.paidViaBusinessEntity)
              }
              resourceName='paidViaBussinessEntity'
              EmptyComponent={
                <div className='flex flex-col justify-center items-center'>
                  <OfficeIcon className='text-lg' />
                  <p
                    className={cn(
                      'py-2',
                      isGeminiEnabled
                        ? 'font-zen-body-2 font-light text-grey-600 text-sm'
                        : 'font-primary-regular text-xs text-gray-400',
                    )}
                  >
                    You have not provided any business entity tax information.
                  </p>
                </div>
              }
            >
              <div className='flex flex-col space-y-3 px-4 py-6'>
                <div>
                  <h6 className='font-zen-body font-semibold text-sm text-zen-dark-6'>
                    Entity Name
                  </h6>
                  <p className='text-base text-dark font-zen-body'>
                    {detail?.paidViaBusinessEntity?.name}
                  </p>
                </div>
                {detail?.paidViaBusinessEntity?.nationalIds &&
                  detail?.paidViaBusinessEntity?.nationalIds.map((id) => (
                    <div key={id.type} className='flex flex-col'>
                      <p className='font-zen-body font-semibold  text-sm text-zen-dark-6'>
                        {id.type}
                      </p>
                      <p className='flex font-semibold font-zen-body flex-row items-center space-x-2'>
                        <span className='font-primary-regular text-sm text-dark'>
                          {isVisibleTaxIds(
                            showBusinessEnityIds,
                            id.type as NationalIdentificationValueTypeEnum,
                          )
                            ? id.id
                            : showEncryptedTextForNationIDs(id)}
                        </span>
                        <DemoModeContainer hide>
                          {!isVisibleTaxIds(
                            showBusinessEnityIds,
                            id.type as NationalIdentificationValueTypeEnum,
                          ) ? (
                            <span
                              className='font-semibold font-zen-body text-sm text-primary-blue cursor-pointer flex flex-row items-center'
                              onClick={() => {
                                const ids = [...showBusinessEnityIds];
                                const updatedIds = ids.map((i: showType) =>
                                  i.type === id.type
                                    ? { ...i, show: true }
                                    : { ...i },
                                );
                                setShowBusinessEnityIds(updatedIds);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                size='sm'
                                className='mr-1'
                              />
                              Show
                            </span>
                          ) : (
                            <span
                              className='font-semibold font-zen-body text-sm text-primary-blue cursor-pointer flex flex-row items-center'
                              onClick={() => {
                                const ids = [...showBusinessEnityIds];
                                const updatedIds = ids.map((i: showType) =>
                                  i.type === id.type
                                    ? { ...i, show: false }
                                    : { ...i },
                                );
                                setShowBusinessEnityIds(updatedIds);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEye}
                                size='sm'
                                className='mr-1'
                              />
                              Hide
                            </span>
                          )}
                        </DemoModeContainer>
                      </p>
                    </div>
                  ))}
              </div>
            </ResourceContainer>
          </AuthorizationContainer>
        </ZenCard>
      )}

      <ZenCard
        title='Tax Forms'
        RightComponent={
          <AuthorizedOnly asyncResponse={taxDocumentsResponse}>
            <DemoModeContainer hide>
              <button
                onClick={isAdmin ? () => setGenerateTaxDoc(true) : undefined}
                className='flex flex-row items-center justify-center space-x-1'
              >
                {isAdmin && !isLoading && isMissingTaxDocuments ? (
                  <>
                    <FontAwesomeIcon
                      icon={faAtomSimple}
                      className={cn(
                        isGeminiEnabled
                          ? 'font-light text-[13px] leading-[23px] text-center text-rezen-blue-600'
                          : 'text-primary-blue',
                      )}
                    />
                    <p
                      className={cn(
                        isGeminiEnabled
                          ? 'font-inter font-medium text-base leading-5 text-rezen-blue-600'
                          : 'text-primary-blue',
                      )}
                    >
                      Generate
                    </p>
                  </>
                ) : undefined}
              </button>
            </DemoModeContainer>
          </AuthorizedOnly>
        }
        focusId={KBarActionEnum.TAX_FORM}
      >
        <div className='px-4 py-2'>
          <AuthorizationContainer asyncResponse={taxDocumentsResponse}>
            <ResourceContainer
              isEmpty={!taxDocumentsResponse.data?.taxDocuments?.length}
              loading={isLoading}
              errorCode={
                taxDocumentsResponse.error || taxDocumentsDetailResponseV2.error
              }
              resourceName='Tax Documents'
              EmptyComponent={
                <div className='flex flex-col justify-center items-center'>
                  <TaxIcon />
                  <p
                    className={cn(
                      'py-2',
                      isGeminiEnabled
                        ? 'font-zen-body-2 font-light text-grey-600 text-sm'
                        : 'font-primary-regular text-xs text-gray-400',
                    )}
                  >
                    You do not have any tax documents yet.
                  </p>
                </div>
              }
            >
              <ZenControlledHTMLSelectInput<any, `year`>
                control={control}
                options={[
                  {
                    label: `${getLastYear()} (Latest)`,
                    value: `${getLastYear()}`,
                  },
                  ...years.map((type) => ({
                    value: `${type}`,
                    label: `${type}`,
                  })),
                ]}
                name='year'
                defaultValue={`${getLastYear()}`}
                shouldUnregister={false}
              />
              {taxDocumentsDetailResponseV2.data?.length ? (
                taxDocumentsDetailResponseV2.data?.map((taxDocument, index) => (
                  <div
                    key={taxDocument.id}
                    className={cn(
                      'pt-6 ',
                      index !==
                        taxDocumentsDetailResponseV2.data?.length! - 1 &&
                        'border-b',
                    )}
                  >
                    <div className='flex flex-row items-center justify-between'>
                      <div className='flex flex-col space-y-2 font-semibold font-zen-body'>
                        <p className='text-zen-dark-9'>
                          {isTaxDocumentNew(taxDocument) && (
                            <span className='bg-red-500 text-white uppercase font-primary-regular p-1 items-center mr-1'>
                              NEW
                            </span>
                          )}
                          {getTaxType(taxDocument?.taxDocumentType!)} for{' '}
                          {taxDocument?.year}{' '}
                          {taxDocument.stateOrProvince &&
                            `- ${capitalizeEnum(taxDocument.stateOrProvince)}`}
                        </p>
                        <p className='text-zen-dark-9 text-sm'>
                          Generated on{' '}
                          {DateTime.fromMillis(
                            taxDocument?.createdAt! || 0,
                          ).toFormat('LL/dd/yy')}
                        </p>
                      </div>
                      <DemoModeContainer hide>
                        <GeminiFeatureFlagButton
                          label={
                            taxDocument?.taxDocumentStatus ===
                            TaxDocumentResponseTaxDocumentStatusEnum.Active
                              ? 'Review'
                              : 'View'
                          }
                          onClick={() => viewOrReviewTaxDocument(taxDocument!)}
                        />
                      </DemoModeContainer>
                    </div>
                    <div>
                      {taxDocument?.taxDocumentStatus ===
                        TaxDocumentResponseTaxDocumentStatusEnum.Active && (
                        <div className='py-3'>
                          <ZenOutlineActionButton
                            text={`Report a ${getTaxType(
                              taxDocument?.taxDocumentType!,
                            )} issue`}
                            icon={
                              <FontAwesomeIcon
                                icon={faMessageExclamation}
                                className='mr-1 -ml-2'
                              />
                            }
                            onClick={() => setReportTaxDocument(taxDocument!)}
                          />
                        </div>
                      )}
                      {taxDocument?.taxDocumentStatus ===
                      TaxDocumentResponseTaxDocumentStatusEnum.Sent ? (
                        taxDocument?.taxDocumentType ===
                        TaxDocumentResponseTaxDocumentTypeEnum.Ten99 ? (
                          <p className='text-gray-500 text-xs'>
                            All 1099 forms have been processed and submitted to
                            the IRS. If you need to dispute and have an IRS
                            extension, please contact support for help.
                          </p>
                        ) : (
                          <p className='text-gray-500 text-xs'>
                            All T4A forms have been processed and submitted to
                            the CRA. If you need to dispute and have a CRA
                            extension, please contact support for help.
                          </p>
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className='flex flex-row items-center justify-center py-6'>
                  <div className='flex flex-col space-y-2 font-normal font-zen-body'>
                    <p className='text-gray-400 px-4 whitespace-normal text-center'>
                      Tax documents are not available for the selected tax year.
                    </p>
                  </div>
                </div>
              )}
            </ResourceContainer>
          </AuthorizationContainer>
        </div>
      </ZenCard>

      {openIdentificationSidebar && (
        <ZenNationalIDForm
          isOpen={openIdentificationSidebar}
          nationIDs={nationalIdentifications?.identifications!}
          onClose={() => setOpenIdentificationSidebar(false)}
        />
      )}
      {openBusinessEntityInfoSidebar && (
        <ZenBusinessEntityForm
          isOpen={openBusinessEntityInfoSidebar}
          onClose={() => setOpenBusinessEntityInfoSidebar(false)}
        />
      )}
      {!!reviewTaxDocument && (
        <ZenTaxForm1099
          isOpen={!!reviewTaxDocument}
          onClose={() => setReviewTaxDocument(undefined)}
          year={selectedYear}
          taxDocument={reviewTaxDocument}
        />
      )}
      {!!reportTaxDocument && (
        <ZenTaxReportIssue
          isOpen={!!reportTaxDocument}
          onClose={() => setReportTaxDocument(undefined)}
          taxDocumentDetails={reportTaxDocument!}
        />
      )}
      {openAddAgentForm && (
        <ZenAddAgentCreditFormSidebarModal
          isOpen={openAddAgentForm}
          onClose={() => setAddAgentForm(false)}
          agent={detail!}
        />
      )}
      {generateTaxDoc && (
        <ZenSimpleConfirmationModal
          isOpen={generateTaxDoc}
          onClose={() => setGenerateTaxDoc(false)}
          isSubmitting={taxDocumentsDetailResponse.loading}
          title={`Generate Tax Document for Tax Year ${previousYear.toFormat(
            'yyyy',
          )}`}
          subtitle={`Are you sure you would like to generate a tax document for this user for the year ${previousYear.toFormat(
            'yyyy',
          )}?`}
          variant='info'
          onConfirm={async () => {
            await dispatch(
              regenerateTaxDocument(
                detail!.id!,
                parseInt(previousYear.toFormat('yyyy')),
              ),
            );
            await dispatch(getTaxDocuments(detail!.id!));
            setGenerateTaxDoc(false);
          }}
        />
      )}
    </>
  );
};

export default ZenTaxInformation;
