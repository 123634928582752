import { cn } from '../../utils/classUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import {
  TCAccessStatusEnum,
  TCAccessStatusPillInfo,
  TCStatusVariant,
} from '../../utils/TransactionCoordinatorUtils';

export interface TCAccessStatusPillProps {
  status: TCAccessStatusEnum;
  variant?: TCStatusVariant;
  statusDate?: string;
}

const TCAccessStatusPill: React.FC<TCAccessStatusPillProps> = ({
  status,
  variant = 'noFill',
  statusDate,
}) => {
  const { icon, textClassName, bgClassName } = TCAccessStatusPillInfo[status];

  return (
    <div
      className={cn('flex items-center gap-1 w-fit', textClassName.noFill, {
        [`${textClassName.filled} ${bgClassName} py-1.5 pr-3 pl-2.5 rounded-full`]:
          variant === 'filled',
      })}
    >
      {variant === 'filled' && status === 'ACTIVE' ? icon.filled : icon.noFill}
      <p className='font-inter text-sm font-medium'>
        {capitalizeEnum(status)}
        {statusDate && variant === 'noFill' && (
          <span>&nbsp;on {statusDate}</span>
        )}
      </p>
    </div>
  );
};

export default TCAccessStatusPill;
