import { faArrowLeft, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import config from '../../../config';
import ErrorService from '../../../services/ErrorService';
import {
  callNotificationAction,
  dismissNotification,
  fetchNotifications,
  openLeo,
} from '../../../slices/LeoSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { RootState } from '../../../types';
import {
  LeoNotification,
  LeoNotificationAction,
  LeoNotificationActionTypeEnum,
} from '../../../utils/LeoUtils';
import { WEBSITE_REGEX } from '../../../utils/StringUtils';
import NeoLeoNotification from './NeoLeoNotification';

interface NeoLeoNotificationsContainerProps {
  showAllNotifications: boolean;
  setShowAllNotifications: (show: boolean) => void;
}

const NeoLeoNotificationsContainer: FC<NeoLeoNotificationsContainerProps> = ({
  setShowAllNotifications,
  showAllNotifications,
}) => {
  const dispatch = useDispatch();
  const INTRO_NOTIFICATIONS_LENGTH = 3;
  const { notifications } = useSelector((state: RootState) => state.leo);
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const [dismissingNotificationIds, setDismissingNotificationIds] = useState<
    string[]
  >([]);

  const notificationsLength = notifications.length;
  const history = useHistory();

  const handleNotificationCardPress = (notification: LeoNotification) => {
    // [TO DO] Remove this condition when notifications are ready for session creation
    if (true) {
      return;
    }
    dispatch(
      openLeo(
        userDetail?.id!,
        {
          title: notification.title,
          prompt: notification.title,
          category: notification.type,
        },
        [
          {
            type: '',
            items: [
              {
                id: notification?.id!,
              },
            ],
          },
        ],
      ),
    );
  };

  const handleNotificationUrl = async (url: string) => {
    if (url.startsWith(config.reactAppHostUrl)) {
      const path = url.replace(config.reactAppHostUrl, '');
      history.push(path);
    } else if (url.match(WEBSITE_REGEX)) {
      window.open(url, '_blank');
    } else {
      dispatch(
        showErrorToast(
          'Cannot open URL at the moment. Please try again later.',
        ),
      );
      ErrorService.notify('Cannot open URL', new Error('Cannot open URL'), {
        data: { url },
      });
    }
  };

  const notificationActionHandler = (
    notification: LeoNotification,
    action: LeoNotificationAction,
  ) => {
    if (action?.url) {
      handleNotificationUrl(action.url);
      return;
    }
    dispatch(callNotificationAction(notification.id, action.text));
  };

  const onDismissNotification = async (notificationId: string) => {
    setDismissingNotificationIds([
      ...dismissingNotificationIds,
      notificationId,
    ]);
    await dispatch(dismissNotification(notificationId));
    setDismissingNotificationIds(
      dismissingNotificationIds.filter((id) => id !== notificationId),
    );
  };

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  if (!notificationsLength && !showAllNotifications) {
    return null;
  }

  return (
    <div className='h-full overflow-y-scroll scrollbar-hide flex flex-col'>
      {showAllNotifications && (
        <>
          <div
            className='flex items-center gap-2 my-6 cursor-pointer'
            onClick={() => setShowAllNotifications(false)}
          >
            <FontAwesomeIcon icon={faArrowLeft} className='text-aqua' />
            <p className='text-aqua'>Go Back</p>
          </div>
          {notificationsLength > 0 && (
            <p className='font-medium text-sm text-regent-600 mb-2'>
              Attention Required (3/{notificationsLength})
            </p>
          )}
        </>
      )}
      {notificationsLength === 0 && (
        <div className='flex items-center justify-center gap-2 h-full'>
          <FontAwesomeIcon icon={faCheck} className='text-regent-600' />
          <p className='font-medium text-regent-600'>
            You&apos;ve addressed all notifications.
          </p>
        </div>
      )}
      <div className='flex flex-col gap-2 pb-4'>
        {notifications
          .slice(
            0,
            showAllNotifications ? undefined : INTRO_NOTIFICATIONS_LENGTH,
          )
          .map((notification) => {
            const primaryAction = notification.actions.find(
              (el) => el.type === LeoNotificationActionTypeEnum.PRIMARY,
            );
            const secondaryAction = notification.actions.find(
              (el) => el.type === LeoNotificationActionTypeEnum.SECONDARY,
            );
            return (
              <NeoLeoNotification
                key={notification.id}
                showActionButtons={!!notification.actions.length}
                showPrimaryAction={!!primaryAction}
                showSecondaryAction={!!secondaryAction}
                primaryActionText={primaryAction?.text}
                primaryActionHandler={() =>
                  notificationActionHandler(notification, primaryAction!)
                }
                secondaryActionHandler={() =>
                  notificationActionHandler(notification, secondaryAction!)
                }
                onNotificationCardPress={() => {
                  handleNotificationCardPress(notification);
                }}
                onDismissNotification={() =>
                  onDismissNotification(notification.id)
                }
                isUrgent={notification.priority === 1}
                notification={notification}
                dismissingNotificationIds={dismissingNotificationIds}
              />
            );
          })}
        {!showAllNotifications && notificationsLength > 3 && (
          <p
            className='text-aqua text-sm font-medium mt-3.5 cursor-pointer w-fit ml-auto'
            onClick={() => setShowAllNotifications(true)}
          >
            See All{' '}
            {INTRO_NOTIFICATIONS_LENGTH > notificationsLength
              ? ''
              : `(${INTRO_NOTIFICATIONS_LENGTH}/${notificationsLength})`}
          </p>
        )}
      </div>
    </div>
  );
};

export default NeoLeoNotificationsContainer;
