import React, { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { faRotate } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setWealthKnowledgeLandingPage } from '../../../../slices/WealthManagementSlice';
import { AnalyticsEventEnum, RootState } from '../../../../types';
import ZenControlledSquareToggleInput from '../../../Zen/Input/ZenControlledSquareToggleInput';
import { Earning } from '../../WealthManagementTypes';
import { GoalsFormInput } from '../SetGoalsSideBarV2';
import WealthManagementCardV2 from '../WealthManagementCardV2';
import { cn } from '../../../../utils/classUtils';
import { AgentWealthManagementApi } from '../../../../openapi/plutus';
import { getWealthManagementConfiguration } from '../../../../utils/OpenapiConfigurationUtils';
import ErrorService from '../../../../services/ErrorService';
import { showErrorToast } from '../../../../slices/ToastNotificationSlice';
import RevenueShareIncomeTierForm from './RevenueShareIncomeTierForm';

const ContainerWrapper: React.FC<{
  noContainer: boolean;
  logAnalyticsEvent: (event: string) => void;
}> = ({ noContainer, logAnalyticsEvent, children }) => {
  const dispatch = useDispatch();

  if (noContainer) {
    return <>{children}</>;
  }

  return (
    <WealthManagementCardV2
      title='Revenue Share Income'
      onClickText='Learn More'
      onClick={() => {
        logAnalyticsEvent(
          AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_LEARN_MORE_REV_SHARE,
        );
        dispatch(setWealthKnowledgeLandingPage(Earning.REVENUE_SHARE_INCOME));
      }}
      className='md:!p-4 !p-3'
    >
      {children}
    </WealthManagementCardV2>
  );
};

interface RevenueShareIncomeFormInputsV2Props {
  logAnalyticsEvent: (event: string) => void;
  showOnlyAttractionInputs?: boolean;
  isSendWealthPlan?: boolean;
  noContainer?: boolean;
}

const RevenueShareIncomeFormInputsV2: React.FC<RevenueShareIncomeFormInputsV2Props> = ({
  logAnalyticsEvent,
  showOnlyAttractionInputs = false,
  isSendWealthPlan = false,
  noContainer = false,
}) => {
  const { control, watch, setValue } = useFormContext<GoalsFormInput>();
  const dispatch = useDispatch();
  const revShareParticipation = watch('revShareParticipation');

  const { fields: tierTabs } = useFieldArray({
    name: 'agentCountsInTiers',
    control,
  });

  const { userDetail } = useSelector((state: RootState) => state.auth);

  const [fetchTierCountsLoading, setFectchTierCountsLoading] = useState(false);

  const setTierCountsFromNetwork = async () => {
    try {
      setFectchTierCountsLoading(true);
      const { data } = await new AgentWealthManagementApi(
        await getWealthManagementConfiguration(),
      ).getNetworkSizeByTier(userDetail?.id!);
      (data?.tierNetworkSizes || []).forEach((tierCount, index) => {
        setValue(
          `agentCountsInTiers.${index}.currentNetworkCount`,
          tierCount?.networkSize,
        );
      });
    } catch (e) {
      dispatch(
        showErrorToast(
          'We had a problem fetching network agent counts.',
          'Please try again in a few moments.',
        ),
      );
      ErrorService.notify('Unable to fetch network agent counts', e, {
        user: { id: userDetail?.id },
      });
    } finally {
      setTimeout(() => {
        setFectchTierCountsLoading(false);
      }, 500);
    }
  };

  return (
    <ContainerWrapper
      noContainer={noContainer}
      logAnalyticsEvent={logAnalyticsEvent}
    >
      {!showOnlyAttractionInputs && (
        <ZenControlledSquareToggleInput<GoalsFormInput, 'revShareParticipation'>
          control={control}
          name='revShareParticipation'
          label='Will you attract agents to Real?'
          shouldUnregister={false}
          onChangeSpy={(value) => {
            logAnalyticsEvent(
              value
                ? AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_REVSHARE_TO_YES
                : AnalyticsEventEnum.WEALTH_PLAN_GOAL_SCREEN_REVSHARE_TO_NO,
            );
          }}
        />
      )}
      {revShareParticipation && (
        <div className='w-full'>
          <div className='border-b border-gray-200 my-4' />

          {!isSendWealthPlan && (
            <div className='flex justify-between items-center'>
              <div>
                <p className='font-zen-body font-semibold text-zen-dark-9'>
                  Update agent count from My Network
                </p>
                <p className='font-zen-body font-light text-base text-grey-600'>
                  Fill in current agent counts from your network
                </p>
              </div>
              <div
                className='flex flex-row items-center gap-1 text-rezen-blue-600 cursor-pointer'
                onClick={setTierCountsFromNetwork}
              >
                <p className='font-zen-body font-medium text-base'>Refresh</p>
                <FontAwesomeIcon
                  icon={faRotate}
                  className={cn('mr-1', {
                    'animate-spin': fetchTierCountsLoading,
                  })}
                />
              </div>
            </div>
          )}
          <RevenueShareIncomeTierForm
            tabs={tierTabs}
            title='Add Agent Count in each tier (below)'
            control={control}
          />
        </div>
      )}
    </ContainerWrapper>
  );
};

export default RevenueShareIncomeFormInputsV2;
