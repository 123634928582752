import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faUserClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { ItemResponseStatusEnum } from '../../../openapi/sherlock';
import { RezenObjectTypeEnum } from '../../../openapi/yada';
import { EnumMap } from '../../../types';
import { checkListStatusToDisplayName } from '../../../utils/ChecklistUtils';
import { safeEnumMapGet } from '../../../utils/EnumHelper';

export interface ZenChecklistLabelProps {
  status: ItemResponseStatusEnum;
  isRequired: boolean;
  readOnly?: boolean;
  showCloseIcon?: boolean;
  onClick?(): void;
  containerType?: RezenObjectTypeEnum;
  isAssigned?: boolean;
}

const ZenChecklistLabel: React.FC<ZenChecklistLabelProps> = ({
  status,
  isRequired,
  readOnly = true,
  showCloseIcon = false,
  onClick,
  containerType,
  isAssigned,
}) => {
  const isBorrowerContainer = containerType === RezenObjectTypeEnum.Borrower;

  const iconVariantClassNameMap: EnumMap<ItemResponseStatusEnum, string> = {
    [ItemResponseStatusEnum.Done]: 'bg-green-100 text-green-600 font-semibold',
    [ItemResponseStatusEnum.NotStarted]:
      ((isBorrowerContainer && isAssigned) || !isBorrowerContainer) &&
      isRequired
        ? 'bg-zen-light-blue text-primary-blue font-semibold'
        : 'bg-grey-100 text-zen-dark-12 font-semibold',
    [ItemResponseStatusEnum.Pending]:
      'bg-zen-warning-light text-zen-warning-dark font-semibold',
    [ItemResponseStatusEnum.Accepted]:
      'bg-green-100 text-green-600 font-semibold',
    [ItemResponseStatusEnum.InReview]:
      'bg-zen-warning-light text-zen-warning-dark font-semibold',
    [ItemResponseStatusEnum.WaitingForReview]:
      'bg-zen-warning-light text-zen-warning-dark font-semibold',
    [ItemResponseStatusEnum.RevisionRequested]:
      'bg-zen-danger-light text-zen-danger font-semibold',
  };

  const statusToIconMap: EnumMap<ItemResponseStatusEnum, React.ReactElement> = {
    [ItemResponseStatusEnum.NotStarted]:
      isBorrowerContainer && isAssigned && isRequired ? (
        <FontAwesomeIcon
          icon={faUserClock}
          className='px-0.5 text-primary-blue group-hover:text-white'
        />
      ) : !isBorrowerContainer && isRequired ? (
        <FontAwesomeIcon icon={regular('bolt-lightning')} className='px-0.5' />
      ) : (
        <FontAwesomeIcon icon={solid('lightbulb')} className='px-0.5' />
      ),
    [ItemResponseStatusEnum.Pending]: (
      <FontAwesomeIcon icon={regular('hourglass-half')} className='px-0.5' />
    ),
    [ItemResponseStatusEnum.WaitingForReview]: (
      <FontAwesomeIcon icon={regular('hourglass-half')} className='px-0.5' />
    ),
    [ItemResponseStatusEnum.InReview]: (
      <FontAwesomeIcon icon={regular('hourglass-half')} className='px-0.5' />
    ),
    [ItemResponseStatusEnum.Accepted]: (
      <FontAwesomeIcon icon={regular('thumbs-up')} className='px-0.5' />
    ),
    [ItemResponseStatusEnum.Done]: (
      <FontAwesomeIcon icon={regular('thumbs-up')} className='px-0.5' />
    ),
    [ItemResponseStatusEnum.RevisionRequested]: (
      <FontAwesomeIcon icon={regular('clock-rotate-left')} className='px-0.5' />
    ),
  };

  return (
    <span
      className={classNames(
        'px-2 py-1.5 font-zen-body text-sm rounded-full inline-flex items-center min-w-fit',
        safeEnumMapGet(
          iconVariantClassNameMap,
          status,
          'bg-zen-light-blue text-primary-blue font-semibold',
        ),
      )}
    >
      {safeEnumMapGet(
        statusToIconMap,
        status,
        <FontAwesomeIcon icon={regular('bolt-lightning')} className='px-0.5' />,
      )}
      <span className='ml-1'>
        {checkListStatusToDisplayName(
          status,
          isRequired,
          containerType,
          isAssigned,
        )}
      </span>
      {!readOnly && (
        <FontAwesomeIcon
          icon={solid('caret-down')}
          className='ml-1 px-0.5 text-base'
        />
      )}
      {showCloseIcon && (
        <button type='button' onClick={onClick}>
          <FontAwesomeIcon
            icon={regular('xmark')}
            className='ml-1 px-0.5 cursor-pointer text-base'
          />
        </button>
      )}
    </span>
  );
};

export default ZenChecklistLabel;
