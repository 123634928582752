import { faSlidersSimple } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Radio } from '@mantine/core';
import { values } from 'lodash';
import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { cn } from '../../../utils/classUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';

export enum BrokerQueueFilterEnum {
  ALL = 'ALL',
  UNREAD = 'UNREAD',
  WITH_UPLOADS = 'WITH_UPLOADS',
}

type BrokerQueueFilterV2Props = {
  filter: BrokerQueueFilterEnum;
  onFilterChange(filter: BrokerQueueFilterEnum): void;
};

const BrokerQueueFilterV2: React.FC<BrokerQueueFilterV2Props> = ({
  filter,
  onFilterChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      isOpen={isOpen}
      align='end'
      positions={['bottom']}
      containerClassName='z-50'
      onClickOutside={() => setIsOpen(false)}
      content={() => (
        <div className='bg-primary-light py-3 px-4 border border-grey-200 rounded shadow-sm space-y-2'>
          {values(BrokerQueueFilterEnum).map((option) => (
            <Radio
              key={option}
              label={capitalizeEnum(option)}
              name='filter'
              checked={filter === option}
              onChange={() => {
                onFilterChange(option);
                setIsOpen(false);
              }}
              color='#4967FD'
            />
          ))}
        </div>
      )}
    >
      <button
        className={cn(
          'border p-1 w-12 flex items-center justify-center rounded-lg cursor-pointer hover:bg-rezen-light-blue-100',
          { 'bg-rezen-light-blue-100': isOpen },
        )}
        onClick={() => setIsOpen(!isOpen)}
        aria-label='filter-message'
      >
        <FontAwesomeIcon
          icon={faSlidersSimple}
          className='text-grey-600'
          fontSize={14}
        />
      </button>
    </Popover>
  );
};

export default BrokerQueueFilterV2;
