import {
  faPencil,
  faSquareList,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { faArrowLeftLong } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { showSuccessToast } from '../../slices/ToastNotificationSlice';
import { RootState } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import { getFormatedTCAccessName } from '../../utils/TransactionCoordinatorUtils';
import { Button } from '../commons/Button';
import IconButton from '../IconButton';
import GeminiConfirmationModal from './GeminiConfirmationModal';
import RevokeInviteConfirmationModal from './RevokeInviteConfirmationModal';
import SidebarModalActionFooter from './SidebarModalActionFooter';
import TCAccessConfirmationCard from './TCAccessConfirmationCard';
import TCInfoSection from './TCInfoSection';
import TCInvitationStatusCard from './TCInvitationStatusCard';
import TCTransactionsAccessCard, {
  schema,
  TransactionCoordinatorFromData,
} from './TCTransactionsAccessCard';

interface InActiveTCDetailsViewProps {
  tcId: string;
  onBackClick?(): void;
}

const InActiveTCDetailsView: React.FC<InActiveTCDetailsViewProps> = ({
  tcId,
  onBackClick,
}) => {
  const dispatch = useDispatch();
  const { tcDataById } = useSelector(
    (state: RootState) => state.transactionCoordinator,
  );
  const tcInfo = tcDataById[tcId];

  const {
    control,
    setValue,
    watch,
    handleSubmit,
  } = useForm<TransactionCoordinatorFromData>({
    defaultValues: {
      accessType: tcInfo?.accessType,
    },
    resolver: zodResolver(schema),
  });

  const { accessType, date } = watch();

  const [isEditingTCAccess, setIsEditingTCAccess] = useState<boolean>(false);
  const [isRevokingTC, setIsRevokingTC] = useState<boolean>(false);
  const [isEditConfirmModalOpen, setIsEditConfirmModalOpen] = useState<boolean>(
    false,
  );

  const toggleEditConfirmModal = () =>
    setIsEditConfirmModalOpen((prev) => !prev);

  const revokeInvite = () => {
    setIsRevokingTC(false);
    dispatch(
      showSuccessToast(
        'Success!',
        `You revoked your invitation for ${tcInfo?.name} to be your Transaction Coordinator.`,
      ),
    );
  };

  const handleConfirm = () => {
    toggleEditConfirmModal();
    setIsEditingTCAccess(false);
    dispatch(
      showSuccessToast(
        'Success!',
        `You have updated ${tcInfo?.name} access. They will now have access to Transactions created after ${date}.`,
      ),
    );
  };

  return (
    <div className='font-inter mb-24'>
      <div className='p-4 bg-regent-100 flex flex-col gap-4 border-b border-rezen-light-blue-100'>
        <div className='flex items-center gap-4 pb-2'>
          <IconButton
            leftIcon={<FontAwesomeIcon icon={faArrowLeftLong} />}
            variant='grey-outline'
            buttonStyle='w-8 h-8'
            onClick={onBackClick}
          />
          <p className='text-primary-dark text-xs sm:text-sm font-medium'>
            {tcInfo?.name}
          </p>
        </div>
        {tcInfo?.invitationStatus && (
          <TCInvitationStatusCard status={tcInfo?.invitationStatus} />
        )}
      </div>
      {tcInfo && (
        <div className='border-b border-regent-300'>
          <TCInfoSection
            tcInfo={{
              id: tcInfo.id,
              name: tcInfo.name,
              avatar: tcInfo.avatar,
              email: tcInfo.email,
              phone: tcInfo.phone,
            }}
            showAdditionalInfo={!isEditingTCAccess}
          />
        </div>
      )}
      <div className='p-4'>
        {isEditingTCAccess ? (
          <form onSubmit={handleSubmit(toggleEditConfirmModal)}>
            <p className='text-gray-500 text-xs sm:text-sm mb-2'>
              Transaction Access
            </p>
            <TCTransactionsAccessCard
              control={control}
              setValue={setValue}
              selectedAccessType={accessType}
            />
            <SidebarModalActionFooter
              submitButtonText='Select'
              isSubmitting={false}
              onCancel={() => setIsEditingTCAccess(false)}
              alignRight
            />
          </form>
        ) : (
          <>
            <div className='flex justify-between items-center border border-grey-300 rounded-lg px-6 py-4'>
              <div className='flex gap-5 items-center'>
                <FontAwesomeIcon icon={faSquareList} size='xl' />
                <div>
                  <p className='text-sm sm:text-base'>Transaction Access</p>
                  <p className='text-xs md:text-sm font-light text-grey-500'>
                    {capitalizeEnum(tcInfo?.accessType!)}
                  </p>
                </div>
              </div>
              <IconButton
                leftIcon={<FontAwesomeIcon icon={faPencil} size='sm' />}
                onClick={() => setIsEditingTCAccess(true)}
                variant='none'
                buttonStyle='bg-grey-200 rounded-full p-2.5'
              />
            </div>
            <div className='px-6 py-3 bg-regent-200 rounded-lg mt-2'>
              <div className='flex gap-3 items-start'>
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className='text-rezen-blue-600 mt-1'
                />
                <p className='text-primary-dark font-medium text-sm md:text-base'>
                  Once the Transaction Coordinator finishes setting up their
                  account, you can finish onboarding them.
                </p>
              </div>
            </div>
            <div className='py-6 px-5 bg-white border-t border-gray-200 w-full absolute bottom-0 left-0'>
              <Button
                variant='link'
                color='red'
                leftIcon={<FontAwesomeIcon icon={faTrashCan} />}
                onClick={() => setIsRevokingTC(true)}
              >
                Revoke Invitation
              </Button>
            </div>
          </>
        )}
      </div>
      <GeminiConfirmationModal
        title='Transaction Access Confirmation'
        isOpen={isEditConfirmModalOpen}
        onClose={toggleEditConfirmModal}
        size='large'
        onConfirm={handleConfirm}
        hideIcon
      >
        <TCAccessConfirmationCard
          tcName={tcInfo?.name!}
          tcId={tcId}
          selectedAccessPreference={getFormatedTCAccessName({
            accessType,
            date,
          })}
        />
      </GeminiConfirmationModal>
      <RevokeInviteConfirmationModal
        isOpen={isRevokingTC}
        onClose={() => setIsRevokingTC(false)}
        tcName={tcInfo?.name!}
        revokeInvite={revokeInvite}
      />
    </div>
  );
};

export default InActiveTCDetailsView;
