import { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { EnumMap, FormFieldTooltipIndexProps } from '../../../types';
import { cn } from '../../../utils/classUtils';
import ZenFormErrorMessage from './ZenFormErrorMessage';

type DisplayVariant = 'block' | 'inline';

export type ControlledTextInputType =
  | 'text'
  | 'email'
  | 'number'
  | 'hidden'
  | 'date'
  | 'datetime-local'
  | 'image'
  | 'month'
  | 'password'
  | 'search'
  | 'tel'
  | 'time'
  | 'url'
  | 'week';

export type ControlledTextInputModeProps =
  | 'none'
  | 'text'
  | 'tel'
  | 'url'
  | 'email'
  | 'numeric'
  | 'decimal'
  | 'search';

export type ControlledTextInputDirection = 'rtl' | 'ltr';

export type ControlledTextInputVariant = 'light' | 'transparent';

export interface ZenControlledTextInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  autoFocus?: boolean;
  type?: ControlledTextInputType;
  inputMode?: ControlledTextInputModeProps;
  direction?: ControlledTextInputDirection;
  label?: string;
  subLabel?: string;
  labelClassName?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  isRequired?: boolean;
  helperText?: string;
  maxLength?: number;
  disableCopyPaste?: boolean;
  variant?: ControlledTextInputVariant;
  onChangeSpy?: (value: string) => void;
  onBlurSpy?: () => void;
  displayVariant?: DisplayVariant;
}

const ZenControlledTextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  autoFocus = false,
  label,
  subLabel,
  labelClassName,
  type = 'text',
  inputMode = 'text',
  direction = 'ltr',
  readOnly,
  noBorder,
  placeholder,
  hideErrorMessage = false,
  tooltipIndex,
  shouldUnregister = true,
  startAdornment,
  endAdornment,
  helperText,
  isRequired = false,
  maxLength,
  disableCopyPaste = false,
  variant = 'light',
  onChangeSpy,
  onBlurSpy,
  displayVariant = 'block',
  ...rest
}: ZenControlledTextInputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);

  const DisplayVariantEnumMap: Record<DisplayVariant, string> = {
    block: 'space-y-1',
    inline: 'flex items-start justify-between space-x-2',
  };

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error, invalid },
      }) => {
        const variantToBackgroundStyleMap: EnumMap<
          ControlledTextInputVariant,
          string
        > = {
          light: 'bg-primary-light',
          transparent: 'bg-transparent',
        };

        const variantToTextStyleMap: EnumMap<
          ControlledTextInputVariant,
          string
        > = {
          light: invalid ? 'text-zen-danger' : 'text-zen-dark-9',
          transparent: invalid ? 'text-zen-danger' : 'text-white',
        };

        const variantToBorderStyleMap: EnumMap<
          ControlledTextInputVariant,
          string
        > = {
          light: focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
          transparent: focus ? 'border-zen-dark-7' : 'border-primary-light',
        };

        return (
          <div className={cn('w-full', DisplayVariantEnumMap[displayVariant])}>
            {label && (
              <label className='inline-block' htmlFor={name}>
                <span
                  className={cn(
                    labelClassName ?? 'font-zen-body font-semibold',
                    variantToTextStyleMap[variant],
                  )}
                >
                  {label}
                </span>
                {!!subLabel && (
                  <span className='font-zen-body text-sm text-zen-dark-6 ml-1'>
                    {subLabel}
                  </span>
                )}
                {isRequired && <span className='text-red-600'>*</span>}
              </label>
            )}
            <div>
              <div
                className={cn(
                  'flex items-stretch border rounded-lg overflow-hidden',
                  variantToBackgroundStyleMap[variant],
                  variantToBorderStyleMap[variant],
                  value && 'text-zen-dark-9',
                  invalid && '!border-red-600',
                  {
                    'border-none': noBorder,
                    'border-zen-dark-7 pointer-events-none':
                      variant === 'transparent' && !!readOnly,
                    'bg-gray-50': readOnly,
                  },
                )}
              >
                {startAdornment && <div>{startAdornment}</div>}
                <input
                  autoFocus={autoFocus}
                  id={name}
                  value={value}
                  onChange={(value) => {
                    onChange(value.target.value);
                    onChangeSpy?.(value.target.value);
                  }}
                  onFocus={() => setFocus(true)}
                  onBlur={(e) => {
                    const trimmedValue = e.target.value?.trim();

                    if (value !== trimmedValue) {
                      onChange(trimmedValue);
                    }

                    setFocus(false);
                    onBlur();
                    if (onBlurSpy) {
                      onBlurSpy();
                    }
                  }}
                  type={type}
                  inputMode={inputMode}
                  dir={direction}
                  name={name}
                  readOnly={readOnly}
                  onCopy={(e) => {
                    if (disableCopyPaste) {
                      e.preventDefault();
                    }
                  }}
                  onPaste={(e) => {
                    if (disableCopyPaste) {
                      e.preventDefault();
                    }
                  }}
                  placeholder={placeholder}
                  className={cn(
                    'appearance-none p-2 w-full border-none focus:outline-none focus:ring-0 font-zen-body font-normal',
                    {
                      'bg-gray-50': readOnly,
                      'text-primary-light': variant === 'transparent',
                    },
                    variantToBackgroundStyleMap[variant],
                    {
                      'text-white': variant === 'transparent',
                      'bg-gray-50': readOnly,
                    },
                  )}
                  maxLength={maxLength}
                  data-tooltip-index={tooltipIndex}
                  onWheel={(e) => {
                    if (e?.currentTarget && type === 'number') {
                      e?.currentTarget?.blur();
                    }
                  }}
                />
                {endAdornment && <div>{endAdornment}</div>}
              </div>
              <div className='flex'>
                {!!error && !hideErrorMessage && (
                  <ZenFormErrorMessage message={error.message} />
                )}
                {helperText && (
                  <div className='ml-auto font-zen-body text-base font-normal text-zen-dark-6'>
                    {helperText}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};
export default ZenControlledTextInput;
