import { cn } from '../utils/classUtils';

export interface HighlightedDivProps {
  divClassName?: string;
}

const HighlightedDiv: React.FC<HighlightedDivProps> = ({
  divClassName,
  children,
}) => {
  return (
    <div
      className={cn('p-1 bg-warning bg-opacity-30 rounded-md', divClassName)}
    >
      {children}
    </div>
  );
};

export default HighlightedDiv;
