import React from 'react';
import ZenAvatar from '../Zen/ZenAvatar';

type TCCardProps = {
  rightSection: React.ReactNode;
  name: string;
  email?: string;
  avatar?: string;
};

const TCCard: React.FC<TCCardProps> = ({
  rightSection,
  name,
  email,
  avatar,
}) => {
  return (
    <div className='flex items-center gap-12 justify-between w-full font-inter border-regent-300 border p-4 rounded-lg'>
      <div className='flex items-center gap-2'>
        <ZenAvatar name={name} imageUrl={avatar} size='md' />
        <div className='text-xs sm:text-sm'>
          <p className='text-primary-dark font-medium line-clamp-1'>{name}</p>
          <p className='text-grey-600 font-light line-clamp-1'>{email}</p>
        </div>
      </div>
      <div>{rightSection}</div>
    </div>
  );
};

export default TCCard;
