import { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { cn } from '../../utils/classUtils';

export type GeminiAccordianVariant = 'primary' | 'primary-outline';

type GeminiAccordionProps = {
  title: string;
  children: React.ReactNode;
  initiallyExpanded?: boolean;
  onChange?: () => void;
  leftComponentIcon?: IconProp;
  varient?: GeminiAccordianVariant;
};

const GeminiAccordian: React.FC<GeminiAccordionProps> = ({
  title,
  children,
  initiallyExpanded = false,
  onChange,
  leftComponentIcon,
  varient = 'primary',
}) => {
  const variantContainerClassNamesMap: Record<
    GeminiAccordianVariant,
    string
  > = {
    primary: 'w-4/5 mb-8 border border-zen-dark-13',
    'primary-outline': '!rounded-lg border',
  };

  const variantTitleClassNamesMap: Record<GeminiAccordianVariant, string> = {
    primary: 'text-zen-dark-13 font-semibold font-zen-body text-xl',
    'primary-outline': 'text-sm font-telegraph',
  };

  const variantIconClassNamesMap: Record<GeminiAccordianVariant, string> = {
    primary: '',
    'primary-outline': 'text-base',
  };

  const variantContentClassNamesMap: Record<GeminiAccordianVariant, string> = {
    primary: 'p-4',
    'primary-outline': 'p-3',
  };

  const variantContentContainerClassNamesMap: Record<
    GeminiAccordianVariant,
    string
  > = {
    primary: 'gap-5',
    'primary-outline': 'gap-2',
  };

  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
    if (onChange) onChange();
  };

  useEffect(() => {
    if (isExpanded) {
      const contentHeight = contentRef.current?.scrollHeight || '0';
      setHeight(`${contentHeight}px`);
    } else {
      setHeight('0px');
    }
  }, [isExpanded]);

  return (
    <div
      className={cn(variantContainerClassNamesMap[varient])}
      style={{
        borderRadius: '10px',
      }}
    >
      <div
        className={cn(
          variantContentClassNamesMap[varient],
          'flex justify-between items-center cursor-pointer rounded-lg',
        )}
        onClick={handleToggle}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <div
          className={cn(
            variantContentContainerClassNamesMap[varient],
            'flex items-center justify-start text-zen-dark-13 font-semibold font-zen-body text-xl',
          )}
        >
          {!!leftComponentIcon && (
            <span>
              <FontAwesomeIcon
                icon={leftComponentIcon}
                className={cn(
                  variantIconClassNamesMap[varient],
                  'font-light text-center text-primary-blue',
                )}
              />
            </span>
          )}
          <span className={cn(variantTitleClassNamesMap[varient])}>
            {title}
          </span>
        </div>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={`transition-transform transform text-2xl text-gray-500 ${
            isExpanded ? 'rotate-180' : 'rotate-0'
          }`}
        />
      </div>

      <div
        ref={contentRef}
        style={{
          maxHeight: height,
          overflow: 'hidden',
          transition: 'max-height 0.3s ease, opacity 0.3s ease',
          opacity: isExpanded ? 1 : 0,
        }}
      >
        <div className='p-4'>{children}</div>
      </div>
    </div>
  );
};

export default GeminiAccordian;
