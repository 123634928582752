import { SyntheticEvent } from 'react';
import {
  AddressResponseStateOrProvinceEnum,
  AdministrativeAreaResponseStateOrProvinceEnum,
} from '../openapi/yenta';
import { StateAbbreviation } from '../types';
import { cn } from '../utils/classUtils';

export type StateIconVariant = 'rounded' | 'circle';

// todo: remove StateAbbreviation once the api is ready
type StateType =
  | AddressResponseStateOrProvinceEnum
  | AdministrativeAreaResponseStateOrProvinceEnum
  | StateAbbreviation;

export interface StateIconProps {
  state: StateType;
  width: number;
  variant?: StateIconVariant;
  className?: string;
}

const StateIcon: React.FC<StateIconProps> = ({
  state,
  width,
  variant = 'rounded',
  className,
}) => {
  const variantMapClass: { [x in StateIconVariant]: string } = {
    rounded: 'rounded',
    circle: 'rounded-full',
  };

  const onImageLoadError = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = '/img/states/default-state.png';
  };

  // 'WYOMING' & 'COLORADO' will use the same image
  const stateToDisplay = state === 'WYOMING' ? 'COLORADO' : state;

  return (
    <img
      className={cn(
        'border border-gray-100 fill-white aspect-square',
        variantMapClass[variant],
        className,
      )}
      onError={onImageLoadError}
      src={`/img/states/${stateToDisplay}.svg`}
      width={width}
      alt={stateToDisplay}
    />
  );
};

export default StateIcon;
