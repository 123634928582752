import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PaymentDetailsControllerApi } from '../../openapi/yenta';
import {
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps,
} from '../../routes/MSDynamicsOnboardingRoute';
import { RootState } from '../../types';
import { capitalizeEnum, formatAddress } from '../../utils/StringUtils';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import ZenButton from '../Zen/ZenButton';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { AddressStateEnum } from '../../openapi/arrakis';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import ErrorService from '../../services/ErrorService';
import { getTaxAcknowledgementStatus } from '../../slices/TaxInformationSlice';
import withMSDynamicsProgress from './withMSDynamicsProgress';

const MSDynamicsAcknowledgment: StepByStepComponent<
  MSDynamicOnboardingFormData,
  MSDynamicOnboardingSteps
> = ({ form: { watch, getValues }, onPrevious }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const {
    auth: { userDetail },
    taxInformation: { taxFormResponseByAgentId },
  } = useSelector((state: RootState) => state);

  const handleNext = async () => {
    setLoading(true);
    try {
      await new PaymentDetailsControllerApi(
        getYentaConfiguration(),
      ).acknowledgeTaxForm(
        userDetail?.id!,
        taxFormResponseByAgentId?.[userDetail?.id!]?.data?.taxForms?.[0]?.id!,
      );

      await dispatch(
        getTaxAcknowledgementStatus({
          userId: userDetail?.id!,
          taxFormId: taxFormResponseByAgentId?.[userDetail?.id!]?.data
            ?.taxForms?.[0]?.id!,
        }),
      );

      dispatch(showSuccessToast('Tax form acknowledged successfully'));

      history.push(`/people/${userDetail?.id}/payment-settings`);
    } catch (e: any) {
      dispatch(showErrorToast('Failed to acknowledge tax form'));
      ErrorService.notifyIgnoreAuthErrors('Failed to acknowledge tax form', e, {
        data: {
          userId: userDetail?.id,
          taxFormId:
            taxFormResponseByAgentId?.[userDetail?.id!]?.data?.taxForms?.[0]
              ?.id,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const isIncorporated = watch('canadaTaxes.incorporated');
  const firstName = getValues('canadaTaxes.taxPayerFirstname');
  const lastName = getValues('canadaTaxes.taxPayerSurname');
  const address1 = getValues('canadaTaxes.addressLine1');
  const address2 = getValues('canadaTaxes.addressLine2');
  const city = getValues('canadaTaxes.city');
  const province = getValues('canadaTaxes.provinceOrRegion');
  const zipCode = getValues('canadaTaxes.zipCode');
  const country = getValues('canadaTaxes.country');
  const bn = getValues('canadaTaxes.taxpayerBusinessNumber');
  const taxpayerBusinessName = getValues('canadaTaxes.taxpayerBusinessName');
  const sin = getValues('canadaTaxes.sinId');
  const gst = getValues('canadaTaxes.gstId');
  const hst = getValues('canadaTaxes.hstId');

  const formattedAddress = `${formatAddress(
    address1!,
    address2,
    city!,
    capitalizeEnum(province! || ''),
    zipCode!,
  )}, ${capitalizeEnum(country!)}`;

  const fullname = `${firstName}  ${lastName}`;
  const Alberta = province === AddressStateEnum.Alberta;

  return (
    <div className='w-full mx-auto max-w-2xl mt-10 relative'>
      <div className='h-screen'>
        <div className='flex flex-row justify-center'>
          <p className='font-inter font-medium text-xl text-primary-dark'>
            Confirm Your Information
          </p>
        </div>
        <div className='flex flex-row justify-center mt-2'>
          <p className='font-inter font-normal text-base text-grey-600'>
            The information provided will be used to generate your T4A for this
            tax year
          </p>
        </div>
        <div className='rounded-xl border p-5 gap-4 border-b-regent-300 bg-regent-100 mt-4'>
          <div className='grid grid-cols-12'>
            <div className='col-span-6'>
              <p className='font-inter font-medium text-[15px] text-grey-500'>
                {isIncorporated
                  ? 'Full legal business name'
                  : 'Full legal name'}
              </p>
              <p className='font-inter font-medium text-base text-primary-dark mt-3'>
                {isIncorporated ? taxpayerBusinessName : fullname}
              </p>
            </div>
            <div className='col-span-6'>
              <p className='font-inter font-medium text-[15px] text-grey-500'>
                Address
              </p>
              <p className='font-inter font-medium text-base text-primary-dark mt-3'>
                {formattedAddress}
              </p>
            </div>
          </div>
          <div className='grid grid-cols-12 mt-6'>
            <div className='col-span-6'>
              <p className='font-inter font-medium text-[15px] text-grey-500'>
                {isIncorporated ? 'BN' : 'SIN'}
              </p>
              <p className='font-inter font-medium text-base text-primary-dark mt-3'>
                {isIncorporated ? bn : sin}
              </p>
            </div>
            {hst && (
              <div className='col-span-6'>
                <p className='font-inter font-medium text-[15px] text-grey-500'>
                  HST
                </p>
                <p className='font-inter font-medium text-base text-primary-dark mt-3'>
                  {hst}
                </p>
              </div>
            )}
            {gst && (
              <div className='col-span-6'>
                <p className='font-inter font-medium text-[15px] text-grey-500'>
                  GST
                </p>
                <p className='font-inter font-medium text-base text-primary-dark mt-3'>
                  {gst}
                </p>
              </div>
            )}
            {isIncorporated && Alberta && (
              <div className='col-span-12 mt-6 font-inter font-medium'>
                I declare that I own more than 50% of the voting shares of{' '}
                {taxpayerBusinessName}, the corporation through which I transact
                in the business of real estate.
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-sm'>
          <div className='flex flex-row items-center py-8 shadow-top-sm justify-center space-x-5'>
            <div className='w-28'>
              <ZenButton
                isFullWidth
                variant='primary-outline'
                type='button'
                label='Edit'
                onClick={onPrevious}
              />
            </div>
            <div className='w-56'>
              <ZenButton
                isFullWidth
                type='submit'
                variant='primary'
                label='Confirm'
                isSubmitting={loading}
                isDisabled={loading}
                onClick={handleNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withMSDynamicsProgress(MSDynamicsAcknowledgment);
