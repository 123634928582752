import { faArrowDownToLine } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useFetchTransactionSummaryPdf } from '../../../query/transaction/useTransaction';
import Hover from '../../Hover';
import { TransactionResponse } from '../../../openapi/arrakis';

type DownloadTxSummaryBtDownloadTxSummaryBtnProps = {
  transactionDetail: TransactionResponse;
};

const DownloadTxSummaryBtn: React.FC<DownloadTxSummaryBtDownloadTxSummaryBtnProps> = ({
  transactionDetail,
}) => {
  const { isFetching, refetch } = useFetchTransactionSummaryPdf(
    transactionDetail?.id!,
    {
      enabled: false, // This query should be manually triggered
      onSuccess: (data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute(
          'download',
          `${transactionDetail.address?.oneLine}_transaction-summary.pdf`,
        );
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        URL.revokeObjectURL(url);
      },
      logErrorMessage: 'Unable to fetch transaction summary pdf',
      toastErrorMessage: 'Failed to fetch transaction summary pdf',
    },
  );

  return (
    <Hover
      hoverComponent={
        <span className='text-sm text-primary-dark p-1'>
          Download Transaction Summary
        </span>
      }
    >
      <button
        onClick={() => refetch()}
        className='disabled:opacity-50 text-gray-700 cursor-pointer hover:text-blue-500'
        disabled={isFetching}
      >
        <FontAwesomeIcon
          icon={faArrowDownToLine}
          data-testid='download-summary'
          size='xl'
        />
      </button>
    </Hover>
  );
};

export default DownloadTxSummaryBtn;
