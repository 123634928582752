import { faCheckCircle, faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  OutgoingPaymentResponsePaymentSystemEnum,
  SearchOutgoingPaymentResponsePaymentSystemEnum,
} from '../../../openapi/arrakis';
import { EnumMap } from '../../../types';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import { capitalizeEnum } from '../../../utils/StringUtils';
import ZenStatusPill, { PillVariantType } from '../../Zen/ZenStatusPill';

export interface ZenOutgoingPaymentSystemCellProps {
  type:
    | OutgoingPaymentResponsePaymentSystemEnum
    | SearchOutgoingPaymentResponsePaymentSystemEnum;
}

const typeToVariant: EnumMap<
  | OutgoingPaymentResponsePaymentSystemEnum
  | SearchOutgoingPaymentResponsePaymentSystemEnum,
  PillVariantType
> = {
  [OutgoingPaymentResponsePaymentSystemEnum.Tipalti]: 'success',
  [OutgoingPaymentResponsePaymentSystemEnum.Netsuite]: 'primary',
  [OutgoingPaymentResponsePaymentSystemEnum.MicrosoftDynamics]: 'primary',
  [OutgoingPaymentResponsePaymentSystemEnum.MicrosoftDynamicsPaymentJournal]:
    'primary',
  [OutgoingPaymentResponsePaymentSystemEnum.Wallet]: 'primary',
};

const typeToIcon: EnumMap<
  | OutgoingPaymentResponsePaymentSystemEnum
  | SearchOutgoingPaymentResponsePaymentSystemEnum,
  React.ReactElement
> = {
  [OutgoingPaymentResponsePaymentSystemEnum.Tipalti]: (
    <FontAwesomeIcon icon={faCheckCircle} />
  ),
  [OutgoingPaymentResponsePaymentSystemEnum.Netsuite]: (
    <FontAwesomeIcon icon={faCircleInfo} />
  ),
  [OutgoingPaymentResponsePaymentSystemEnum.MicrosoftDynamics]: (
    <FontAwesomeIcon icon={faCircleInfo} />
  ),
  [OutgoingPaymentResponsePaymentSystemEnum.MicrosoftDynamicsPaymentJournal]: (
    <FontAwesomeIcon icon={faCircleInfo} />
  ),
  [OutgoingPaymentResponsePaymentSystemEnum.Wallet]: (
    <FontAwesomeIcon icon={faCircleInfo} />
  ),
};

const ZenOutgoingPaymentSystemCell: React.FC<ZenOutgoingPaymentSystemCellProps> = ({
  type,
}) => {
  const variant: PillVariantType = safeEnumMapGet(
    typeToVariant,
    type,
    'primary',
  );

  const icon: React.ReactElement = safeEnumMapGet(
    typeToIcon,
    type,
    <FontAwesomeIcon icon={faCircleInfo} />,
  );

  return (
    <ZenStatusPill
      text={capitalizeEnum(type)}
      variant={variant!}
      icon={icon!}
    />
  );
};

export default ZenOutgoingPaymentSystemCell;
