import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useHistory } from 'react-router-dom';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';

interface WalletOutgoingPaymentsProps {}

const WalletOutgoingPayments: React.FC<WalletOutgoingPaymentsProps> = () => {
  const history = useHistory();

  return (
    <ZenResourceLinkCard
      title='Wallet Outgoing Payments'
      onClick={() => history.push('/finance/wallet-outgoing-payments')}
      icon={regular('wallet')}
    />
  );
};

export default WalletOutgoingPayments;
