import { createSelector } from '@reduxjs/toolkit';
import { flatten, isArray, uniqBy } from 'lodash';
import { FilterValue, IdType } from 'react-table';
import {
  ChecklistResponse,
  ChecklistResponseFileApiVersionEnum,
  ItemDtoStatusEnum,
  ItemResponse,
  ItemResponseRequiredForEnum,
  ItemResponseStatusEnum,
} from '../openapi/sherlock';
import { RezenObjectTypeEnum } from '../openapi/yada';
import {
  AgentDocumentResponse,
  AgentResponseAccountCountryEnum,
} from '../openapi/yenta';
import { ISelectOption, RootState } from '../types';
import { capitalizeEnum } from './StringUtils';

export const getUniqueLabelOptionForChecklist = (
  checklistItems: ItemResponse[],
): ISelectOption[] =>
  uniqBy(
    flatten(
      checklistItems
        .map((checklist) => checklist?.labels)
        .filter((tags) => tags && tags.length > 0),
    ),
    'text',
  ).map((label) => ({
    value: label?.text!,
    label: label?.text!,
  }));

export const getValidChecklistStatusesForRole = (
  isAgent: boolean,
): ItemDtoStatusEnum[] => {
  if (isAgent) {
    return [ItemDtoStatusEnum.NotStarted, ItemDtoStatusEnum.Pending];
  }

  return [
    ItemDtoStatusEnum.NotStarted,
    ItemDtoStatusEnum.Pending,
    ItemDtoStatusEnum.RevisionRequested,
    ItemDtoStatusEnum.Accepted,
  ];
};

export const checkListStatusToDisplayName = (
  status: ItemResponseStatusEnum | ItemDtoStatusEnum,
  isRequired: boolean,
  containerType?: RezenObjectTypeEnum,
  isAssigned?: boolean,
) => {
  if (
    status === ItemResponseStatusEnum.NotStarted &&
    (!isRequired || !isAssigned) &&
    containerType === RezenObjectTypeEnum.Borrower
  ) {
    return 'Not Started';
  }
  if (
    status === ItemResponseStatusEnum.NotStarted &&
    isRequired &&
    isAssigned &&
    containerType === RezenObjectTypeEnum.Borrower
  ) {
    return 'Requested';
  }
  if (status === ItemResponseStatusEnum.NotStarted && !isRequired) {
    return 'If Applicable';
  }
  if (status === ItemResponseStatusEnum.NotStarted && isRequired) {
    return 'Required';
  }

  return capitalizeEnum(status);
};

export const getChecklistRequiredForDisplayName = (
  requiredFor: ItemResponseRequiredForEnum,
  required: boolean,
  country: AgentResponseAccountCountryEnum,
): string | undefined => {
  if (!required) {
    return undefined;
  }

  if (
    country === AgentResponseAccountCountryEnum.Canada &&
    requiredFor === ItemResponseRequiredForEnum.CommissionDocGeneration
  ) {
    return 'Conveyancing';
  }

  if (requiredFor === ItemResponseRequiredForEnum.Transactions) {
    return 'Closing';
  }

  return requiredFor ? capitalizeEnum(requiredFor) : undefined;
};

export enum ItemResponseStatusEnumExtra {
  REQUIRED = 'REQUIRED',
  IF_APPLICABLE = 'IF_APPLICABLE',
}

export const getChecklistStatusExtraEnum = (
  status: ItemResponseStatusEnum | ItemDtoStatusEnum,
  isRequired: boolean,
): ItemResponseStatusEnum | ItemDtoStatusEnum | ItemResponseStatusEnumExtra => {
  if (status === ItemResponseStatusEnum.NotStarted && !isRequired) {
    return ItemResponseStatusEnumExtra.IF_APPLICABLE;
  }

  if (status === ItemResponseStatusEnum.NotStarted && isRequired) {
    return ItemResponseStatusEnumExtra.REQUIRED;
  }

  return status;
};

export const getOriginalChecklistStatusEnum = (
  status: ItemResponseStatusEnum | ItemResponseStatusEnumExtra,
): ItemResponseStatusEnum => {
  if (
    status === ItemResponseStatusEnumExtra.REQUIRED ||
    status === ItemResponseStatusEnumExtra.IF_APPLICABLE
  ) {
    return ItemResponseStatusEnum.NotStarted;
  }
  return status;
};

export const checklistMultiSelectFilter = (
  rows: any[],
  [id]: IdType<AgentDocumentResponse>,
  filterValue: FilterValue,
) => {
  if (!filterValue?.length) return rows;
  const extras: any[] = [];
  if (filterValue.includes(ItemResponseStatusEnumExtra.REQUIRED)) {
    rows.forEach((row) => {
      if (
        row.values.required &&
        row.values[id] === ItemResponseStatusEnum.NotStarted
      ) {
        extras.push(row);
      }
    });
  }
  if (filterValue.includes(ItemResponseStatusEnumExtra.IF_APPLICABLE)) {
    rows.forEach((row) => {
      if (
        !row.values.required &&
        row.values[id] === ItemResponseStatusEnum.NotStarted
      ) {
        extras.push(row);
      }
    });
  }
  return rows
    .filter((row) => {
      if (isArray(row.values[id])) {
        return row.values[id]?.some((value: FilterValue) =>
          filterValue?.includes(value),
        );
      }

      return filterValue.includes(row.values[id]);
    })
    .concat(extras);
};

export const uploadDocumentsOneByOne = async (
  docs: File[],
  upload: (doc: File) => Promise<any>,
) => {
  if (!docs?.length) {
    return;
  }

  for (let doc of docs) {
    await upload(doc);
  }
};

export const isV2Checklist = (checklist: ChecklistResponse | undefined) => {
  return checklist?.fileApiVersion === ChecklistResponseFileApiVersionEnum.V2;
};

export const getChecklistsWithFileReferences = (
  checklistItems: ItemResponse[],
  fileId: string,
): ItemResponse[] => {
  let attachedChecklistArray: ItemResponse[] = [];
  checklistItems?.forEach((checklist) => {
    checklist.fileReferences?.references?.forEach((file) => {
      if (file?.fileId === fileId) {
        attachedChecklistArray.push(checklist);
      }
    });
  });

  return attachedChecklistArray;
};

export const selectIsChecklistV2 = createSelector(
  [
    (state: RootState) => state.checklist.checklistsById,
    (_: RootState, id: string) => id,
  ],
  (checklistById, id) => isV2Checklist(checklistById[id]?.data),
);
