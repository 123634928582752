import { useState } from 'react';
import {
  Controller,
  FieldValues,
  UseControllerProps,
  FieldPath,
} from 'react-hook-form-v7';
import { cn } from '../utils/classUtils';
import { FormFieldTooltipIndexProps } from '../types';
import FormErrorMessage from './FormErrorMessage';

export type ControlledTextInputType =
  | 'text'
  | 'email'
  | 'number'
  | 'hidden'
  | 'date'
  | 'datetime-local'
  | 'image'
  | 'month'
  | 'password'
  | 'search'
  | 'tel'
  | 'time'
  | 'url'
  | 'week';

export type ControlledTextInputModeProps =
  | 'none'
  | 'text'
  | 'tel'
  | 'url'
  | 'email'
  | 'numeric'
  | 'decimal'
  | 'search';

export interface ControlledTextV7InputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  type?: ControlledTextInputType;
  inputMode?: ControlledTextInputModeProps;
  label?: string;
  subLabel?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  isRequired?: boolean;
  maxLength?: number;
}

const ControlledTextInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  type = 'text',
  inputMode = 'text',
  readOnly,
  noBorder,
  placeholder,
  hideErrorMessage = false,
  tooltipIndex,
  shouldUnregister = true,
  startAdornment,
  endAdornment,
  isRequired = false,
  maxLength,
  ...rest
}: ControlledTextV7InputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error },
      }) => (
        <div className='w-full space-y-1'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              <span>{label}</span>
              {!!subLabel && (
                <span className='font-primary-regular text-sm text-gray-400 ml-1'>
                  {subLabel}
                </span>
              )}
              {isRequired && <span className='text-error'>*</span>}
            </label>
          )}
          <div
            className={cn(
              'flex items-stretch border rounded overflow-hidden',
              {
                'ring-1 ring-blue-600': focus && !noBorder,
              },
              error && hideErrorMessage
                ? 'border-zen-danger'
                : noBorder
                ? 'border-none'
                : undefined,
            )}
          >
            {startAdornment && <div>{startAdornment}</div>}
            <input
              id={name}
              value={value}
              onFocus={() => setFocus(true)}
              onBlur={(e) => {
                onChange(e.target.value?.trim());
                setFocus(false);
                onBlur();
              }}
              onChange={onChange}
              type={type}
              inputMode={inputMode}
              name={name}
              readOnly={readOnly}
              placeholder={placeholder}
              className={cn(
                'appearance-none p-1.5 w-full border-none focus:outline-none focus:ring-0',
                {
                  'bg-gray-50': readOnly,
                },
              )}
              maxLength={maxLength}
              data-tooltip-index={tooltipIndex}
              onWheel={(e) => {
                if (e?.currentTarget && type === 'number') {
                  e?.currentTarget?.blur();
                }
              }}
            />
            {endAdornment && <div>{endAdornment}</div>}
          </div>
          {error && !hideErrorMessage && (
            <FormErrorMessage message={error.message} />
          )}
        </div>
      )}
    />
  );
};

export default ControlledTextInputV7;
