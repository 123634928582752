import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BrokerQueueFilterEnum } from '../components/Menu/BrokerQueue/BrokerQueueFilterV2';
import { DEFAULT_BROKER_QUEUE_PAGE_SIZE } from '../constants/BrokerQueueConstants';
import {
  InboxControllerApi,
  MessageCountRes,
  MessageQueryResDto,
  RezenObjectTypeEnum,
} from '../openapi/yada';
import { BrokerControllerApi } from '../openapi/yenta';
import { MetabaseDashboardUrlResponse } from '../openapi/yenta/api';
import ErrorService from '../services/ErrorService';
import {
  AppDispatch,
  AppThunk,
  AsyncResponse,
  BrokerQueueState,
} from '../types';
import {
  getYadaConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { performAsyncRequest } from './utils/SliceUtil';

export const initialState: BrokerQueueState = {
  nextPage: undefined,
  messages: [],
  isLoading: true,
  markingReadUnreadLoading: false,
  unreadMessageCount: undefined,
  metabaseUrl: { loading: true, name: 'metabaseUrl' },
};

const BrokerQueueSlice = createSlice({
  name: 'brokerQueue',
  initialState,
  reducers: {
    changeLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    changeMarkingReadUnreadLoading(state, action: PayloadAction<boolean>) {
      state.markingReadUnreadLoading = action.payload;
    },
    saveBrokerQueueMessages(state, action: PayloadAction<MessageQueryResDto>) {
      state.messages = action.payload.messages! || [];
      state.nextPage = action.payload.nextPage;
    },
    saveUnreadMessageCount(state, action: PayloadAction<MessageCountRes>) {
      state.unreadMessageCount = action.payload.unreadMessageCount;
    },
    loadBrokerQueueMessages(state, action: PayloadAction<MessageQueryResDto>) {
      state.messages.push(...(action.payload.messages || []));
      state.nextPage = action.payload.nextPage;
    },
    updateRead(
      state,
      action: PayloadAction<{ read: boolean; targetId?: string }>,
    ) {
      if (!action.payload.targetId) {
        state.messages = state.messages.map((mess) => ({
          ...mess,
          read: action.payload.read,
        }));
        return;
      }
      const messageIndex = state.messages.findIndex(
        (m) => m.target?.id === action.payload.targetId,
      );

      if (messageIndex !== -1) {
        state.messages[messageIndex].read = action.payload.read;
        state.messages[messageIndex].unreadComments = action.payload.read
          ? 0
          : state.messages[messageIndex].unreadComments;
      }
    },
    saveMetabaseUrl(
      state,
      action: PayloadAction<AsyncResponse<MetabaseDashboardUrlResponse>>,
    ) {
      state.metabaseUrl = action.payload;
    },
  },
});

export const {
  changeLoading,
  changeMarkingReadUnreadLoading,
  saveBrokerQueueMessages,
  loadBrokerQueueMessages,
  updateRead,
  saveUnreadMessageCount,
  saveMetabaseUrl,
} = BrokerQueueSlice.actions;

export const getBrokerQueueMessages = (
  refresh: boolean,
  userId: string,
  filter?: BrokerQueueFilterEnum,
  searchText?: string,
  targetTypes?: RezenObjectTypeEnum[],
  pageStart?: string,
  pageSize = DEFAULT_BROKER_QUEUE_PAGE_SIZE,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    if (refresh) {
      dispatch(changeLoading(true));
    }
    const { data } = await new InboxControllerApi(
      getYadaConfiguration(),
    ).getMessagesForUser(
      userId,
      filter,
      searchText,
      targetTypes,
      pageSize,
      pageStart,
    );

    if (refresh) {
      dispatch(saveBrokerQueueMessages(data));
    } else {
      dispatch(loadBrokerQueueMessages(data));
    }
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem fetching inbox messages.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notifyIgnoreAuthErrors('Unable to fetch inbox messages', e, {
      data: { userId, filter, searchText, pageStart, pageSize },
    });
  } finally {
    if (refresh) {
      dispatch(changeLoading(false));
    }
  }
};

export const getUnreadMessageCountForUser = (
  userId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data } = await new InboxControllerApi(
      getYadaConfiguration(),
    ).getUnreadMessageCountForUser(userId);
    dispatch(saveUnreadMessageCount(data));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem fetching unread message count.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to fetch unread message count',
      e,
      {
        user: { userId },
      },
    );
  }
};

export const markBrokerQueueMessageAsRead = (
  userId: string,
  targetType?: RezenObjectTypeEnum,
  targetId?: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(changeMarkingReadUnreadLoading(true));
  try {
    dispatch(updateRead({ targetId, read: true }));
    await new InboxControllerApi(getYadaConfiguration()).markMessageAsRead(
      userId,
      targetType,
      targetId,
    );
    if (!targetId) {
      dispatch(
        showSuccessToast(
          `All Transaction messages marked as read successfully.`,
        ),
      );
    }
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem marking inbox message as read.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to mark inbox message as read', e, {
      data: { userId, targetId, targetType },
    });
  } finally {
    dispatch(changeMarkingReadUnreadLoading(false));
  }
};

export const markBrokerQueueMessageAsUnread = (
  userId: string,
  targetType?: RezenObjectTypeEnum,
  targetId?: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(changeMarkingReadUnreadLoading(true));
  try {
    dispatch(updateRead({ targetId, read: false }));
    await new InboxControllerApi(getYadaConfiguration()).markMessageAsUnread(
      userId,
      targetType,
      targetId,
    );
    if (!targetId) {
      dispatch(
        showSuccessToast(
          `All Transaction messages marked as unread successfully.`,
        ),
      );
    }
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem marking inbox message as unread.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to mark inbox message as unread', e, {
      data: { userId, targetId, targetType },
    });
  } finally {
    dispatch(changeMarkingReadUnreadLoading(false));
  }
};

export const markMultipleBrokerQueueMessageRead = (
  userId: string,
  targetIds: string[] = [],
  targetType: RezenObjectTypeEnum,
): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(changeMarkingReadUnreadLoading(true));
  try {
    const markAsRead = async (tarId: string) => {
      await new InboxControllerApi(getYadaConfiguration()).markMessageAsUnread(
        userId,
        targetType,
        tarId,
      );
      dispatch(updateRead({ targetId: tarId, read: true }));
    };
    const requests = targetIds.map(markAsRead);
    await Promise.all(requests);

    dispatch(
      showSuccessToast(
        `${targetIds.length} Transaction message(s) marked as read successfully`,
      ),
    );
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem marking multiple inbox messages as read. Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to mark inbox message as read', e, {
      data: { userId, targetIds, targetType },
    });
  } finally {
    dispatch(changeMarkingReadUnreadLoading(false));
  }
};

export const markMultipleBrokerQueueMessageUnread = (
  userId: string,
  targetIds: string[],
  targetType: RezenObjectTypeEnum,
): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(changeMarkingReadUnreadLoading(true));
  try {
    const markAsUnread = async (tarId: string) => {
      await new InboxControllerApi(getYadaConfiguration()).markMessageAsUnread(
        userId,
        targetType,
        tarId,
      );
      dispatch(updateRead({ targetId: tarId, read: false }));
    };
    const requests = targetIds.map(markAsUnread);
    await Promise.all(requests);
    dispatch(
      showSuccessToast(
        `${targetIds.length} Transaction message(s) marked as unread successfully`,
      ),
    );
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem marking multiple inbox messages as unread. Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to mark inbox message as unread', e, {
      data: { userId, targetIds, targetType },
    });
  } finally {
    dispatch(changeMarkingReadUnreadLoading(false));
  }
};

export const getMetabaseSignedUrl = (): AppThunk<Promise<void>> => async (
  dispatch,
) => {
  dispatch(changeLoading(true));

  const fetch = () =>
    new BrokerControllerApi(getYentaConfiguration()).getMetabaseDashboardURL();
  performAsyncRequest(
    dispatch as AppDispatch,
    'metabaseUrl',
    saveMetabaseUrl,
    fetch,
    {
      changeLoading: true,
      skipAuthDatadog: false,
    },
  );
  dispatch(changeLoading(false));
};

export default BrokerQueueSlice.reducer;
