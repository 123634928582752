import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ZenSideBarItem from '../ZenSideBarItem';
import useShowLeoTrigger from '../Zen/Leo/useShowLeoTrigger';

interface UserRealAcademySidebarMenuItemProps {
  isCollapsed: boolean;
}

const UserRealAcademySidebarMenuItem: React.FC<UserRealAcademySidebarMenuItemProps> = ({
  isCollapsed,
}) => {
  const { showNeoLeoPanel } = useShowLeoTrigger();

  return (
    <ZenSideBarItem
      icon={
        <FontAwesomeIcon
          icon={
            showNeoLeoPanel
              ? light('graduation-cap')
              : regular('graduation-cap')
          }
        />
      }
      label='Real Academy'
      target='_blank'
      linkTo='/learnworlds/sso'
      collapsed={isCollapsed}
    />
  );
};

export default UserRealAcademySidebarMenuItem;
