import { faArrowLeftLong } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { debounce, isEmpty } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Control, useForm, UseFormSetValue } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { z } from 'zod';
import { showSuccessToast } from '../../slices/ToastNotificationSlice';
import { TransactionCoordinatorAccessEnum } from '../../types';
import { getFullName } from '../../utils/AgentHelper';
import { capitalizeEnum, EMAIL_REGEX } from '../../utils/StringUtils';
import { Button } from '../commons/Button';
import { HookFormTextInput } from '../commons/hookFormInputs/HookFormTextInput';
import IconButton from '../IconButton';
import ZenAvatar from '../Zen/ZenAvatar';
import GeminiConfirmationModal from './GeminiConfirmationModal';
import SidebarModalActionFooter from './SidebarModalActionFooter';
import TCAccessConfirmationCard from './TCAccessConfirmationCard';
import { TCInfo } from './TCInfoSection';
import TCTransactionsAccessCard, {
  TransactionCoordinatorFromData,
} from './TCTransactionsAccessCard';

interface InviteTCFormProps {
  onCancel?(): void;
}

export const inviteTCFormSchema = z.object({
  firstName: z
    .string({ message: 'First Name is required' })
    .trim()
    .min(1, { message: 'First Name is required' }),
  lastName: z
    .string({ message: 'Last Name is required' })
    .trim()
    .min(1, { message: 'Last Name is required' }),
  email: z
    .string({ message: 'Email is required' })
    .regex(EMAIL_REGEX, { message: 'Email is invalid' }),
});

export type InviteTCFormType = z.infer<typeof inviteTCFormSchema> &
  TransactionCoordinatorFromData;

const InviteTCForm: React.FC<InviteTCFormProps> = ({ onCancel }) => {
  const dispatch = useDispatch();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<TCInfo>();
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const { control, handleSubmit, watch, setValue } = useForm<InviteTCFormType>({
    defaultValues: {
      accessType:
        TransactionCoordinatorAccessEnum.ALL_OPEN_LISTINGS_AND_TRANSACTIONS,
    },
    resolver: zodResolver(inviteTCFormSchema),
  });

  const { email, accessType, firstName, lastName } = watch();
  const isUserAlreadyRegistered = !isEmpty(userInfo);

  const tcName = getFullName({
    firstName,
    lastName,
  });

  const getUserInfo = (email: string): TCInfo | undefined => {
    if (email === 'vinodh.reddy@therealbrokerage.com') {
      return {
        id: '123456',
        email: 'vinodh.reddy@therealbrokerage.com',
        name: 'Vinodh Reddy',
      };
    }
    return undefined;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onEmailChange = useCallback(
    debounce((email: string) => {
      const isValidEmail = inviteTCFormSchema.shape.email.safeParse(email)
        .success;
      setIsValidEmail(isValidEmail);
      if (isValidEmail) {
        setUserInfo(getUserInfo(email));
      } else setUserInfo(undefined);
    }, 300),
    [],
  );

  const handleInviteForm = async () => {
    setOpenConfirmModal(true);
  };

  const onConfirm = () => {
    dispatch(
      showSuccessToast(
        'Success',
        `You have invited ${tcName} to be your Transaction Coordinator`,
      ),
    );
    setOpenConfirmModal(false);
  };

  return (
    <>
      <form
        className='flex flex-col justify-between min-h-full mb-24'
        onSubmit={handleSubmit(handleInviteForm)}
      >
        <div>
          <div className='h-16 py-2 px-4 bg-regent-100 border-b border-regent-300 flex gap-4.5 items-center'>
            <IconButton
              leftIcon={<FontAwesomeIcon icon={faArrowLeftLong} />}
              variant='grey-outline'
              buttonStyle='w-8 h-8'
              onClick={onCancel}
            />
            <span className='font-inter text-sm font-medium'>
              Transaction Coordinator
            </span>
          </div>
          <div className='p-4 flex flex-col gap-4'>
            <div className='flex flex-col gap-4'>
              <span className='font-medium text-base text-primary-dark'>
                Invite via Email
              </span>
              <div className='grid md:grid-cols-2 gap-6 w-full'>
                <HookFormTextInput<InviteTCFormType, 'firstName'>
                  control={control}
                  name='firstName'
                  label='First Name'
                  placeholder='Name'
                  withAsterisk
                />
                <HookFormTextInput<InviteTCFormType, 'lastName'>
                  control={control}
                  name='lastName'
                  label='Last Name'
                  placeholder='Last Name'
                  withAsterisk
                />
              </div>
              <div>
                <HookFormTextInput<InviteTCFormType, 'email'>
                  control={control}
                  name='email'
                  label='Email Address'
                  placeholder='Email Address'
                  withAsterisk
                  onChange={(value) => onEmailChange(value as string)}
                />
              </div>
            </div>
            {isValidEmail &&
              (isUserAlreadyRegistered ? (
                <div className='p-4 bg-red-100 rounded-lg flex flex-col gap-2.5'>
                  <p className='font-inter text-base font-light text-primary-dark'>
                    This Transaction Coordinator already has a reZen account
                  </p>
                  <div className='bg-primary-light flex justify-between items-center rounded-lg p-4 border border-regent-300'>
                    <div className='flex items-center gap-2'>
                      <ZenAvatar name={userInfo?.name!} size='lg' />
                      <div className='font-inter flex flex-col'>
                        <p className=' text-base font-normal text-primary-dark'>
                          {userInfo?.name}
                        </p>
                        <p className='text-sm font-light text-grey-600'>
                          {email}
                        </p>
                      </div>
                    </div>
                    <div className='w-40'>
                      <Button
                        fullWidth
                        color='blue'
                        variant='filled'
                        onClick={() => {}}
                      >
                        Select
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <TCTransactionsAccessCard
                  selectedAccessType={accessType}
                  setValue={
                    (setValue as unknown) as UseFormSetValue<TransactionCoordinatorFromData>
                  }
                  control={
                    (control as unknown) as Control<
                      TransactionCoordinatorFromData,
                      object
                    >
                  }
                />
              ))}
          </div>
        </div>
        {!isUserAlreadyRegistered && (
          <SidebarModalActionFooter
            onCancel={onCancel}
            submitButtonText='Send Invite'
            alignRight
          />
        )}
      </form>

      <GeminiConfirmationModal
        isOpen={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        title='Transaction Access Confirmation'
        onConfirm={onConfirm}
        confirmButtonText='Send Invite'
        hideCancelButton={false}
        cancelButtonText='Cancel'
        hideIcon
        size='fixed'
      >
        <TCAccessConfirmationCard
          tcName={tcName!}
          selectedAccessPreference={capitalizeEnum(accessType)}
          tcId='123456'
        />
      </GeminiConfirmationModal>
    </>
  );
};

export default InviteTCForm;
