import React, { useMemo } from 'react';
import { Column } from 'react-table';
import { getFormattedDateWithTimezoneForISOString } from '../../utils/DateUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import {
  getActivityTypeInfo,
  TCActivityLogEnum,
  TCActivityLogResponse,
} from '../../utils/TransactionCoordinatorUtils';
import ZenResourceIndexContainer from '../Zen/Containers/ZenResourceIndexContainer';
import ZenAvatar from '../Zen/ZenAvatar';

type ActivityLogTableProps = {};

const ActivityLogTable: React.FC<ActivityLogTableProps> = () => {
  const data: TCActivityLogResponse[] = [
    {
      id: '1',
      tcInfo: {
        id: '1',
        name: 'Dianne Russell',
        avatar: '',
      },
      activityType: TCActivityLogEnum.INVITATION_SENT,
      createdAt: '2023-06-12T21:28:00+01:00',
    },
    {
      id: '2',
      tcInfo: {
        id: '2',
        name: 'Dianne Russell',
        avatar: '',
      },
      activityType: TCActivityLogEnum.INVITATION_REVOKED,
      createdAt: '2023-06-12T21:28:00+01:00',
    },
    {
      id: '3',
      tcInfo: {
        id: '3',
        name: 'Jenny Willason',
        avatar: '',
      },
      activityType: TCActivityLogEnum.INVITATION_EXPIRED,
      createdAt: '2023-06-12T21:28:00+01:00',
    },
    {
      id: '4',
      tcInfo: {
        id: '4',
        name: 'Dianne Russell Maxwell',
        avatar: '',
      },
      activityType: TCActivityLogEnum.INVITATION_ACCEPTED,
      createdAt: '2023-06-12T21:28:00+01:00',
    },
    {
      id: '5',
      tcInfo: {
        id: '5',
        name: 'Dianne Russell',
        avatar: '',
      },
      activityType: TCActivityLogEnum.INVITATION_REJECTED,
      createdAt: '2023-06-12T21:28:00+01:00',
    },
    {
      id: '6',
      tcInfo: {
        id: '6',
        name: 'Dianne Russell',
        avatar: '',
      },
      activityType: TCActivityLogEnum.TRANSACTION_ACCESS_UPDATED,
      createdAt: '2023-06-12T21:28:00+01:00',
    },
    {
      id: '7',
      tcInfo: {
        id: '7',
        name: 'Dianne Russell',
        avatar: '',
      },
      activityType: TCActivityLogEnum.TRANSACTION_COORDINATOR_REMOVED,
      createdAt: '2023-06-12T21:28:00+01:00',
    },
    {
      id: '8',
      tcInfo: {
        id: '8',
        name: 'Dianne Russell',
        avatar: '',
      },
      activityType: TCActivityLogEnum.SIGN_ASSISTANT_AMENDMENT,
      createdAt: '2023-06-12T21:28:00+01:00',
    },
  ];

  const columns: Column<TCActivityLogResponse>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'tcInfo',
        id: 'name',
        Cell: ({ value }) => (
          <div className='bg-rezen-blue-100 py-1.5 px-2 rounded-full flex gap-1 items-center w-40'>
            <ZenAvatar imageUrl={value?.avatar} name={value?.name!} size='xs' />
            <p className='line-clamp-1 text-primary-dark font-inter text-xs sm:text-sm font-normal'>
              {value?.name}
            </p>
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Activity Type',
        accessor: 'activityType',
        id: 'activityType',
        Cell: ({ row }) => {
          const activityTypeInfo = getActivityTypeInfo(row.original);

          if (typeof activityTypeInfo === 'string') {
            return null;
          }

          const { icon, message } = activityTypeInfo;
          return (
            <div className='font-inter py-4 px-2 flex gap-2 items-start'>
              <div className='w-6 h-6 p-2 border flex-shrink-0 border-grey-300 rounded-full flex justify-center items-center text-xl text-grey-600 font-light'>
                {icon}
              </div>
              <div className='text-xs sm:text-sm'>
                <p className='text-primary-dark font-normal'>
                  {capitalizeEnum(row.original.activityType!)}
                </p>
                <p className='text-grey-600 font-light'>{message}</p>
              </div>
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'DATE & TIME',
        accessor: 'createdAt',
        id: 'createdAt',
        Cell: ({ value }) => (
          <div className='font-zen-body font-normal text-sm text-grey-500'>
            {getFormattedDateWithTimezoneForISOString(value!)}
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <ZenResourceIndexContainer<TCActivityLogResponse>
      resourceName='Activity log'
      columns={columns}
      fetchData={async () => {
        return {
          data,
          total: data.length,
        };
      }}
      hideFilters
      hidePagination
      hideRowBorder
      stickyHeader
    />
  );
};

export default ActivityLogTable;
