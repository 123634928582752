import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface TCAccessConfirmationCardProps {
  tcName: string;
  selectedAccessPreference: string;
  tcId: string;
  specialInfo?: string;
}

const TCAccessConfirmationCard: React.FC<TCAccessConfirmationCardProps> = ({
  tcName,
  selectedAccessPreference,
  specialInfo,
}) => {
  return (
    <div className='flex flex-col gap-2.5 items-center justify-center font-inter my-4 p-4 text-center'>
      <div className='flex flex-col gap-4 items-center'>
        <p className='font-medium text-base'>
          {tcName} &nbsp;
          <span className='font-light'>will have access to:</span>{' '}
        </p>
        <p>
          <span className='rounded-full bg-rezen-light-blue-100 px-4 py-1 text-primary-navy text-sm font-medium'>
            {selectedAccessPreference}
          </span>
        </p>
        <p className='text-grey-500 text-sm font-light'>
          This can be changed later through your profile
        </p>
      </div>
      {specialInfo && (
        <div className='bg-regent-200 rounded-lg p-3 flex items-center justify-center gap-2.5'>
          <FontAwesomeIcon
            icon={faInfoCircle}
            className='text-rezen-blue-600'
          />
          <span className='text-grey-600 text-sm font-light'>
            {specialInfo}
          </span>
        </div>
      )}
    </div>
  );
};

export default TCAccessConfirmationCard;
