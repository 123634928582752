import { values } from 'lodash';
import { RezenPaymentActivityDtoTypeEnum } from '../../../openapi/wallet';
import { capitalizeEnum } from '../../../utils/StringUtils';
import SelectColumnFilter, {
  SelectColumnFilterProps,
} from './Base/SelectColumnFilter';

export interface WalletSelectActivityTypeColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

const WalletSelectActivityTypeColumnFilter = <D extends object>(
  props: WalletSelectActivityTypeColumnFilterProps<D>,
) => {
  return (
    <SelectColumnFilter
      {...props}
      options={values(RezenPaymentActivityDtoTypeEnum).map((type) => ({
        value: type,
        label: capitalizeEnum(type),
      }))}
    />
  );
};

export default WalletSelectActivityTypeColumnFilter;
